import { connect } from 'react-redux'

import ModalConvention from '../../components/ModalConvention'

import { toggleModalConvention } from '../../actions/modal'
import { signConvention } from '../../actions/job'
import { setFullPageLoader } from '../../actions/actions'

const mapStateToProps = (state) => ({
    modalConventionIsOpen: state.modal.modalConventionIsOpen,
    modalConventionEntrepriseId:  state.modal.modalConventionEntrepriseId,
    modalConventionDocType: state.modal.modalConventionDocType,
    sigPad: state.modal.sigPad,
    userRole: state.login.userRole,
    userInfo: state.login.userInfo,
    session: state.login.session
})

const mapDispatchToProps = (dispatch) => ({
    toggleModalConvention: (bool, entrepriseId, docType) => {
        dispatch(toggleModalConvention(bool, entrepriseId, docType));
    },
    signConvention: (entrepriseId, urlImage, fileName) => {
        dispatch(signConvention(entrepriseId, urlImage, fileName));
    },
    setFullPageLoader: (bool) => {
        dispatch(setFullPageLoader(bool));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalConvention)

