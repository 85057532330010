/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { PDFDocument, StandardFonts } from 'pdf-lib'
import { saveAs } from 'file-saver'
import { MdDownloadForOffline } from "react-icons/md"
import { RiBallPenFill } from "react-icons/ri";
import { AiFillPlusCircle } from "react-icons/ai"
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios'
import * as qs from 'qs'

import urlBackEndSave from '../../../assets/js/urlBackEndSave'
import urlBackEnd from '../../../assets/js/urlBackEnd'
import pdfPart from '../../../assets/pdf/certificat-realisation-particulier.pdf'
const URLSITE = urlBackEnd

/**** import des components ****/


/**** Import d'images' ****/


/**** Import des css ****/


const AttestationU = ({ userRole, facture, toggleModalSignature, factureEStatut, facturePStatut, fetchUploadOpcoFile, session }) => {

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  function hasString(obj, searchString) {
    return Object.values(obj).some(val => 
      typeof val.nom_formation === 'string' && val.nom_formation.toLowerCase().includes(searchString.toLowerCase())
    );
  }

  const Download = (arrayBuffer, type, fileName) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const blob = new Blob([arrayBuffer], { type: type });
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async function postFactureCertificat() {
    if(userRole !== 'entreprise') {

    const response = await fetch(pdfPart)
    const arrayBuffer = await response.arrayBuffer();
    
  
    const pdfDoc = await PDFDocument.load(arrayBuffer)
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    const form = pdfDoc.getForm()

    const pages = pdfDoc.getPages();
    const wantedPage = pages[0];
  
    const { width, height } = wantedPage.getSize();

    const currentDate = new Date();

    function formatDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
    
      return `${day}-${month}-${year}`;
    }

    function formatDateFromIso(isoDate) {
      const date = new Date(isoDate);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
    
      return `${day}-${month}-${year}`;
    }

    const userFullName = session.user.nom + ' ' + session.user.prenom

    wantedPage.drawText(userFullName, {
      x: 100,
      y: height - 259,
      font: timesRomanFont,
      size: 11
    });

    wantedPage.drawText(facture.attributes.nom_formation, {
      x: 190 ,
      y: height - 272,
      font: timesRomanFont,
      size: 11
    });

    if (facture.attributes.start_session !== null && facture.attributes.end_session !== null ) {
      wantedPage.drawText(formatDateFromIso(facture.attributes.start_session) + ' au ' + formatDateFromIso(facture.attributes.end_session) , {
        x: 157,
        y: height - 385.5,
        font: timesRomanFont,
        size: 11
      });
    }

    wantedPage.drawText(facture.attributes.formation.data.attributes.duree + 'h', {
      x: 171,
      y: height - 404.2,
      font: timesRomanFont,
      size: 11
    });

    wantedPage.drawText("Le " + formatDate(currentDate), {
      x: 62,
      y: height - 540,
      font: timesRomanFont,
      size: 11
    });
   
    const pdfFile = await pdfDoc.save();

    Download(pdfFile, 'application/pdf', `certificat-realisation-particulier-${facture.id}.pdf`);
    fetchUploadOpcoFile(pdfFile, facture.id, 'opco')
  }
  }

  async function getPdfAsStream(arrayBuffer) {
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const response = await fetch(url);
    const stream = response.body;
    return stream;
  }
  
  return (
    <>
      {hasString(facture, 'dda') && facture.attributes.step === 4 && (userRole === 'entreprise' ? factureEStatut : facture !== false) &&
        <div className="facture-comp-wrapper">
          <div className="info-wrapper pad">
            <div className="top bold">
              n°{facture.id}
            </div>
            <div>
              {moment(facture.attributes.createdAt).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="info-wrapper pad">
            <div className="top bold little">
              {facture.attributes.nom_formation}
            </div>
          </div>
          <div className="info-wrapper">
            <>
              {facture.attributes.OPCO.data !== null ?
              <div className="btn-wrapper">
                <button 
                  className="dl-btn"
                  onClick={() => savePdf(facture.attributes.OPCO.data.attributes.url, facture.attributes.OPCO.data.attributes.name )}
                >
                  <MdDownloadForOffline />
                  Télécharger
                </button>
              </div>
              :
              <div className="btn-wrapper">
                <button 
                  className="dl-btn"
                  onClick={() => {
                    postFactureCertificat()
                  }}
                >
                  <AiFillPlusCircle  />
                  Créer mon attestation
                </button>  
              </div>
              }
            </>
          </div>
        </div>
      }
    </>
  )
}

AttestationU.propTypes = {
}

export default AttestationU