import styled from 'styled-components';

const WeforStyled = styled.div`

.top-illustration {
    width: 100vw;
    height: 700px;
    padding-top: 125px;
    z-index: 10;
    position: relative;

    &::before {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width:100%;
        background: linear-gradient(180deg, rgba(214,231,245,1) 0%, rgba(100,154,206,1) 100%);
        clip-path: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
        shape-outside: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
        z-index: -10;
    }

    .opacity-light-section {
        height: 100%;
        top: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0px 0;
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;

        .left {
            width: calc(50%);
            margin-top: 100px;
            height: 100%;

            h1 {
                font-size: 50px;
                color: #3D3D40;
            }

            p {
                font-size: 25px;
                color: #fff;
                line-height: 30px;
                padding: 30px 0;
            }
        }
        img {
            margin-top: 50px;
            margin-left: -50px;
            box-sizing: border-box;
            width: 50%;
            max-width: 650px;
        }
    }
}

.page-wefor {
    width: 100%;
    background: linear-gradient(180deg, rgba(248, 253, 255, 0) 0%, rgba(241, 252, 255, 0.51782) 48.22%, #F6FDFF 100%);

    .wrapper-valeur {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        max-width: 1300px;
        margin: 0 auto;
        padding: 0px 0 50px;

        .left, .right {
            width: 100%;

            .whites-cards-shadow-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                width: 100%;
                max-width: 1300px;
                margin: 50px auto 0;
    
                .cards-shadow {
                    background: #FFFFFF;
                    box-shadow: 0px 30px 60px rgb(0 19 87 / 4%);
                    border-radius: 15px;
                    padding: 30px 30px 30px 30px;
                    transition: all 0.3s ease-in-out;
                    width: 31%;
                    box-sizing: border-box;
                    position: relative;
                    text-align: left;
    
                    svg {
                        font-size: 45px;
                        color: #649ace;
                    }
    
                    h3 {
                        font-size: 25px;
                        color: #202c3c;
                        margin: 30px 0;
                    }
    
                    p {
                        font-size: 19px;
                        line-height: 22px;
                    }
    
                    .know-more-link {
                        position: absolute;
                        bottom: 20px;
                        right: 40px;
                        a {
                            color: #649ace;
                            text-decoration: underline !important;
                        }
                    }
                }
            }

            h2 {
                font-size: 55px;
            }

            p, ul {
                font-size: 18px;
            }

            li, p {
                padding: 15px 0;
                line-height: 20px;
                text-align: justify;
                margin: 0;
            }

            img {
                width: 100%;
            }
        }
    }

    .first-section-home-de {
        max-width: 1300px;
        width: 96%;
        margin:0px auto 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: #202c3c;
        padding: 50px 0px 0;
        position: relative;
    
        .title-cards {
            text-align: center;
            margin-bottom: 50px;
        }

        .right-cards {
            width: 50%;
            padding-left: 0px;
            box-sizing: border-box;

            .white-card {
                padding: 20px 20px 20px 30px;
                box-shadow:0 20px 30px 0 rgb(0 0 0 / 10%);
                background-color: #fff;
                margin:0 auto 30px;
                border-radius: 30px 0 30px 30px;
                display: flex;
                flex-wrap:wrap;
                align-items: center;
                justify-content: space-between;
                max-width: 600px;
                text-align: justify;
                min-height: 160px;


                div {
                    width: calc(100% - 70px);
                }

                h5 {
                    font-size: 25px !important;
                    padding-bottom: 10px;
                }


                svg {
                    width: 50px;
                    font-size: 40px;

                    &.green {
                        color: #6aaf8b;
                    }
                    &.orange {
                        color: #d47558;
                    }
                    &.pink {
                        color: #D071AB;
                    }
                }

                p {
                    font-size: 18px;
                    padding-left: 0px;
                    line-height: 22px;
                }
            }
        }
    }

    .left {
        width: 50%;
        padding-top: 50px;

        h2 {
            color: #202c3c;
            font-size: 55px;
            margin-bottom: 40px;
            text-align: center;

            & .blue-underline {
                color: #649ace;
            }
        }

        p {
            margin: 0px 0px 20px 0;
            font-size: 18px;
            margin-left: 20px;
            line-height: 22px;
            text-align: justify;
        }

        img {
            width: 100%;
        }
    }

    .history-section {
        margin-top: 100px;

        .right {
            width: 100%;
        }
    }

    .valeur-section {
        padding: 0px 0 50px;
    }

    .organigramme-section {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 50px 0;
        background-color: #fff;

        h2 {
            font-size: 55px;
            display: inline-block;
            margin: 0 auto 50px;
            color: #202D3C;

            span {
                position: relative;
            }

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
            }
        }

        img {
            max-width: 1200px;
            width: 96%;
            margin: 0 auto;
        }
    }
}

@media only screen and (max-width: 1400px) { 

    .top-illustration {

        .opacity-light-section {
            img {
                max-width: 550px;
                margin-left: 0px;
            }

            .left {
                margin-right:0;
                width: 50%;
                padding-right: 20px;
                box-sizing: border-box;

                h1 {
                    text-align: left;
                }
            }
        }
    }

    .history-section, .wrapper-valeur, .organigramme-section {
        width: 96%;
    }
}


@media only screen and (max-width: 1079px) {
        
    .top-illustration {
    
        .opacity-light-section {
            height: auto;
            flex-direction: column-reverse;

            .left {
                padding: 100px 0 0 0;
                width: calc(96%);
                margin: 0 auto;
                height: 226px;

                h1 {
                    font-size: 35px;
                    text-align: center;
                    margin-top: 20px;
                }

                p {
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                }
            }

            img {
                max-width: 400px;
                margin-left: 0px;
                margin: 50px auto;
            }
        }
    }
    .page-wefor {
        .first-section-home-de {
            flex-direction: column-reverse;

            h2 {
                font-size: 40px;
            }

            width: 100%;

            .right-cards {
                margin-top: 50px;
                width: 100%;

                .white-card {
                    width: 100%;
                }
            }

            .left{
                padding: 100px 0 0 0;
                width: calc(96%);
                margin: 50px auto 0;
                height: 226px;

                h1 {
                    font-size: 35px;
                    text-align: center;
                    margin-top: 20px;
                }

                p {
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                }
            }

            img {
                display: none;
            }
        }
        .history-section {
            flex-direction: column;

            .left, .right {
                width: 100%;

                img {
                    max-width: 700px;
                    margin: 0 auto;
                }
            }

            .left {
                text-align: center;
            }
        }

        .valeur-section {
            .wrapper-valeur {
                display:flex;
                flex-direction: column-reverse;;

                .left, .right {
                    width: 100%;

                    .whites-cards-shadow-wrapper {
                        flex-direction: column;

                        .cards-shadow {
                            margin: 0 auto 30px;
                            width: 96%;
                            max-width: 400px;
                        }
                    }
    
                    img {
                        max-width: 700px;
                        margin: 0 auto;
                    }

                    h2 {
                        font-size: 40px;
                    }
                }
    
                .right {
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {
    .top-illustration {

        height: 300px;
        padding-top: 60px;

        &::before {
            clip-path: none;
        }

        .opacity-light-section {
            flex-direction: column-reverse;

            .left {
                margin:0 auto;
                width: 96%;
                box-sizing: border-box;
                height: auto;
                display: flex; 
                justify-content: center;
                flex-direction: column;
                padding-top: 0;

                h1 {
                    font-size: 24px;
                    text-align: center;
                }

                p {
                    font-size: 18px;
                    line-height: 22px;
                    text-align: justify;
                    padding: 20px 0;
                    width: 80%;
                    margin: 0 auto;
                }
            }
            img {
                width: 80%;
                margin: 30px auto 0;
                max-width: 300px;
            }
        }
    }

    .page-wefor {

        .first-section-home-de {

            .left {
                padding: 80px 0 0 0;

                p {
                    margin: 0;
                    font-size: 16px; 
                    text-align: justify;
                }
            }

            h2 {
                font-size: 24px;
                margin-bottom: 20px;
            }

            .left {
                height: auto;
                width: 90%;
            }

            .right-cards {
                margin-top: 0px;
                width: 100%;

                .white-card {
                    width: 90%;
                    margin: 20px auto 0;
                    padding: 15px;
                    min-height: 120px;

                    svg {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        font-size: 30px; 
                    }

                    div {
                        width: calc(100%);

                        h5 {
                            font-size: 20px !important;
                        }

                        p {
                            font-size: 16px;
                            text-align: left;
                        }
                    }
                }
            }
        }
        .history-section {
            margin-top: 150px;
        }

        .valeur-section {

            padding: 0;
            .wrapper-valeur {
                padding: 0;
                width: 90%;

                .left {
                    h2 {
                        font-size: 24px;
                        margin: 0 0 20px;
                    }

                    p {
                        padding: 0;
                        font-size: 16px;
                        text-align: left;
                    }

                    .whites-cards-shadow-wrapper {
                        margin: 20px 0;

                        .cards-shadow {
                            width: 100%;
                            padding: 15px;
                            box-shadow: 0px 30px 60px rgb(0 19 87 / 10%);

                            svg {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                font-size: 30px; 
                            }

                            h3 {
                                font-size: 20px;
                                margin: 0px 0 10px 0;
                                width: calc(100% - 30px);
                            }

                            p {
                                font-size: 16px;
                                line-height: 20px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

export default WeforStyled;