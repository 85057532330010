/**** Import des modules ****/
import React, { useEffect } from 'react'
import { Link, useSearchParams } from "react-router-dom"
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import AOS from 'aos';
import "aos/dist/aos.css"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import BannerAccount from '../../components/Header/BannerAccount'

/**** import des images ****/
import logo from '../../assets/img/illustration/header-homme.png'

/**** Import des css ****/
import NewPasswordStyled from './NewPasswordStyled'

const NewPassword = ({changeValue, isLogged, fetchLostPassword, newPassword, newPasswordConfirmation, fetchPostNewPassword, saveCodeResetPassword}) => {

  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])

  let [searchParams, setSearchParams] = useSearchParams();
  const codeParam = searchParams.get("code");

  saveCodeResetPassword(codeParam)

  const isValidSpe = (str) => {
    return /[~`!#$%\^&*+=\-\[\]\\';,./{}|\\":<>\?]/g.test(str);
  }

  const isValidMaj = (str) => {
    return /(?=.*[A-Z])/.test(str);
  }

  const isValidChi = (str) => {
    return /^(?=.*[0-9])/.test(str);
  }

  const isValidNum = (str) => {
    return /^.{8,10000}$/.test(str);
  }
  return (
    <NewPasswordStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      {isLogged && 
      <Navigate to='/account/home' replace={true} />
      }
      {!isLogged &&
      <>
      <section className="top-illustration">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Nouveau mot de passe</h1>
          </div>
        </div>
      </section>
      <main className="page-connexion">
        <h2 className="bold">Choisissez votre nouveau mot de passe<span className="separator-blue"></span></h2>
        <form 
          className="account-form-comp"
          onSubmit={(event) => {
            event.preventDefault();
            if (newPassword === newPasswordConfirmation && isValidChi(newPassword) && isValidMaj(newPassword) && isValidNum(newPassword) && isValidSpe(newPassword)) {
              fetchPostNewPassword();
            } else {
              toast(`Toutes les conditions du mot de passe doivent correspondre`, {
                position: "bottom-right",
                type: "error",
                theme:"colored",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
            }
          }}
        >
        <label>
              Mot de passe
              <input 
                type="password" 
                name="newPassword" 
                value={newPassword}
                placeholder=""
                required
                onChange={(event) => {
                  changeValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Confirmation du mot de passe
              <input 
                type="password" 
                name="newPasswordConfirmation" 
                value={newPasswordConfirmation}
                required
                placeholder=""
                onChange={(event) => {
                  changeValue(event.target.value, event.target.name);
                }}
              />
          </label>
          <div className="mdp-cond-wrapper">
              <p>Votre mot de passe doit contenir:</p>
              <ul>
                <li>
                {isValidNum(newPassword) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> 8 caractère au minimun</span>
                </li>
                <li>
                {isValidChi(newPassword) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> Un chiffre au minimun</span>
                </li>
                <li>
                {isValidMaj(newPassword) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> Une majuscule au minimun</span>
                </li>
                <li>
                {isValidSpe(newPassword) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> Un caractère spéciale au minimun</span>
                </li>
                <li>
                 {newPassword === newPasswordConfirmation ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>}
                 <span> Les mots de passe doivent correspondre</span>
                </li>
              </ul>
            </div>
            <label>
            <input 
              type="submit" 
              value="Envoyer" 
              className="lightblue-btn"
            />
          </label>
        </form>
      </main>
      </>
      }
      <Footer />
    </NewPasswordStyled>
  )
}

NewPassword.propTypes = {

}

export default NewPassword