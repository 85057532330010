/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import "aos/dist/aos.css"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'


/**** Import des images ****/
import logo from '../../assets/img/illustration/header-homme.png'

/**** Import des css ****/
import PdcStyled from './PdcStyled'

const Pdc = ({}) => {

  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])



  return (
    <PdcStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      <section className="illustration-header">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Protection des données personnelles</h1>
          </div>
        </div>
      </section>
      <section className="full-page">
          <p>En application des dispositions légales et réglementaires en vigueur relatives à la protection des données à caractère personnel, le Groupe WEFOR est responsable des données personnelles récoltées en sa qualité de sous-traitant vis-à-vis du responsable du traitement (donneur d’ordre) et est responsable de traitement vis-à-vis de ses propres consultants et sous-traitants.</p>
          <p>L’accès à l’une des applications du Groupe WEFOR peut requérir la fourniture de données personnelles, tel que les formulaires d’enregistrement. La personne concernée peut choisir de ne pas soumettre les données personnelles demandées, mais cela pourra limiter l’accès à ladite application.</p>
          <p>Les traitements des données personnelles effectués par le Groupe WEFOR s’appuient sur les principes fondamentaux suivants.</p>

          <h4 className="bold">1. Les données personnelles collectées</h4>
          <p>Les données sont collectées pour des finalités déterminées, explicites et légitimes et peuvent concernées le co-contractant en charge de l’achat de la prestation de formation professionnelle pour son besoin propre ou au profit de ses salariés apprenants et lesdits salariées apprenants.</p>
          <p>Ces données peuvent être fournies directement par les personnes morales en charge de l’achat de la prestation de formation professionnelle, la personne concernée bénéficiaire de la prestation de formation, peuvent être fournies par des sources tierces et/ou être fournies également par le biais de l’usage que la personne concernée fait des applications mises à disposition. </p>

          <h4 className="bold">2. Catégories de données traitées</h4>
          <p>Données d’identification des utilisateurs (nom, prénom, identifiant, mot de passe et adresse électronique).</p>
          <p>Données d’utilisation du service (adresse IP ; navigateur utilisé ; provenance ; résolution de l’écran ; système d’exploitation ; dates, horaires et temps de connexion sur les sites ; données d’authentification collectées au moyen d’un cookie de session ; dates, horaires, temps de suivi et progression relatifs aux différents modules (vidéo, unité, évaluation).</p>
          <p>Données nécessaires à la délivrance de l’attestation de suivi (identité de l’utilisateur, date de l’attestation, taux de réussite).</p>

          <h4 className="bold">3. Finalité</h4>
          <p>La collecte des données peut s’avérer nécessaire pour les besoins et la fourniture de la prestation de formation attendue.</p>
          <p>La collecte des données personnelles est réalisée principalement pour les besoins de l’enregistrement et le traitement de la prestation de formation, à savoir :</p>
          <p>• Fournir, activer et gérer les accès aux applications mises à disposition et l’utilisation de celles-ci ;</p>
          <p>• Assurer les inscriptions et l’authentification, l’envoi des confirmations d’inscription et des documents relatifs à la formation professionnelle, l’élaboration des listes d’émargement, des attestations de présence et de formation, l’émargement des apprenants pour les formations en présentiel ;</p>
          <p>• La mise en relation de l’apprenant avec des recruteurs éventuels par le biais d’offres d’emplois directement accessibles sur le site <a href="https://wefor.fr/">www.wefor.fr</a>, auquel cas les informations de type curriculum vitae, lettre de motivation, profil du candidat, test de personnalité du candidat seront transmis à des recruteurs éventuels pour les candidats en ayant fait la demande par le biais du site ;</p>
          <p>• Fournir un support technique, un service ou produit complémentaire et permettre ainsi le bon fonctionnement des applications mises à disposition ;</p>
          <p>• Traiter et répondre à une demande, une commande, un téléchargement, une souscription à un abonnement ou à tout autre transaction ;</p>
          <p>• Offrir un contenu personnalisé et d’autres personnalisations pour rendre les applications mises à disposition plus pertinentes ;</p>
          <p>• Répondre aux éventuelles interrogations soulevées par les personnes concernées ;</p>
          <p>• Notifier les modifications, mises à jour et autres annonces relatives aux applications mises à disposition ;</p>
          <p>• Fournir des publicités ciblées, des messages promotionnels, des notifications et autres informations relatives aux prestations de formation ;</p>
          <p>• Fournir des messages promotionnels et autres informations sur les produits du Groupe WEFOR ;</p>
          <p>• Inviter à participer à des enquêtes ;</p>
          <p>• Identifier les tendances d’utilisation et développer l’analyse de données, y compris pour la recherche, l’audit, le reporting et d’autres opérations commerciales, qui comprennent la détermination de l’efficacité des campagnes promotionnelles du Groupe WEFOR et l’évaluation de la performance commerciale ; et/ou</p>
          <p>• Pour se conformer aux obligations légales, résoudre d’éventuels litiges et faire respecter les engagements contractuels.</p>
          <p>• Pour prévenir la fraude, permettre aux co-contractant du Groupe WEFOR de se conformer à leurs obligations légales ;</p>
          <p>• Permettre la navigation des utilisateurs sur les sites et applications mis à disposition.</p>

          <h4 className="bold">4. Durée de conservation</h4>
          <p>Les données personnelles collectées et traitées par le Groupe WEFOR sont conservées pendant la durée nécessaire à l’organisation et à la dispense de la prestation de formation et des services associés dont le retour potentiel à l’emploi en mettant en relation des candidats avec des entreprises. </p>
          <p>Ces données sont ensuite conservées pour répondre à un intérêt administratif, notamment en cas de contentieux, justifiant de les conserver le temps des règles de prescription/forclusion applicables, notamment en matière commerciale, civile et fiscale.</p>
          <p>Les données collectées à des fins de prospection commerciale sont conservées pendant une durée maximale de trois (3) ans à compter de la fin de la relation commerciale. </p>
          <p>Les données collectées pour naviguer sur le site sont conservées pendant une durée maximale d’un (1) an.</p>

          <h4 className="bold">5. Traitement des données personnelles par un tiers</h4>
          <p>Les données personnelles, récoltées dans le cadre des finalités ci-avant exposé, peuvent être divulguées à un tiers dans les cas suivants :</p>
          <p>• Pour la réalisation de la formation professionnelle, en communiquant par exemple les données aux consultants en charge de la formation;</p>
          <p>• Afin de permettre à une entreprise qui recrute de pouvoir contacter les candidats ayant postulés aux offres d’emploi ;</p>
          <p>• Pour se conformer à toute loi en vigueur, réglementation, procédure juridique ou autre obligation légale ;</p>
          <p>• Pour détecter, enquêter et permettre de prévenir des problèmes de sécurité, de fraude ou des problèmes techniques ;</p>
          <p>• Pour protéger les droits, la propriété ou la sécurité du Groupe WEFOR, ses utilisateurs, employés ou autres ; et</p>
          <p>• Pour réaliser une transaction générale, telle qu’un transfert d’avoirs ou une acquisition par le biais d’une fusion avec une autre société.</p>

          <h4 className="bold">6. Contact </h4>
          <p>Toute personne concernée qui justifie de son identité peut demander au DPO : dpo@wefor.fr l’accès à ses données personnelles, la rectification, l’effacement de celles-ci, limiter le traitement, s’opposer au traitement et/ou demander la portabilité de ses données personnelles.  </p>
          <p>Toute personne concernée a également le droit d’introduire une réclamation auprès d’une autorité de contrôle.</p>
      </section>
      <Footer />
    </PdcStyled>
  )
}

Pdc.propTypes = {
}

export default Pdc