import styled from 'styled-components';

const JobCardStyled = styled.article`
    width: 270px;
    color: #6aaf8b;
    border-radius: 30px; 
    height: 350px;
    max-height: 400px;
    padding: 15px;
    box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    position: relative;
    margin: 50px 17.5px 0 17.5px;
    text-align: right;
    box-sizing: border-box;
    background-color: #fff;

    &.pointer {
        cursor: pointer;
    }

    &.pointer:hover {
        background-color: #e1efe7;
    }

    &.active-job {
        border: 3px solid #6AAF8B;
        background-color: #ECF3EF;
        padding: 12px;

        .distance-wrapper {
            top: 337px !important;
        }
    }

    .black {
        color: #4d4d4d;
    }

    .black-small {
        color: #4d4d4d;
        font-size: 18px;
    }

    .title-wrapper {
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        width: calc(100% - 45px);
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto 0 auto;

        .first-title {
            font-weight: bold;
        }

        .separator-green {
            margin: 5px 0;
            height: 1px;
            background-color: #6aaf8b;
        }
    }

    .job-cat {
        width: 100%;
        font-weight: bold;
        padding: 20px 0 20px;
        margin: 0;
        height: 60px;
        font-size: 16px;
        text-align: center;
        border-top: 1px solid #6aaf8b;
    }

    h3 {
        color: #4d4d4d;
        text-align: left;
        font-weight: bold;
        width: 100%;
        height: 50px;
        font-size: 20px;
        text-align: center;
    }  

    .txt-skeleton {
        font-size: 18px;
    }
    
    .salary-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 19px 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
    }

    .publication-date {
        width: 100%;
        text-align: center;
        height: 35px;
        padding-top: 10px;
        color: #4d4d4d;
    }

    .btn-lightgreen {
        width: 150px;
        margin: 0px auto 3px;
        display: block;
        padding: 5px;
        height: 30px;
        border: 0;
        border-radius: 10px;
        color: #fff;
        background-color: #6aaf8b;
        cursor: pointer;
        font-size: 14px;
        transition: all .3s ease;

        &:hover {
            opacity: .7;
        }
    }

    .small-txt {
        font-size: 14px;

        .black {
            color: #4d4d4d;
            font-size: 22px;
        }
    }

    .btn-green {
        width: 150px;
        margin: 0px auto 0;
        display: block;
        padding: 5px;
        border: 1px solid #6aaf8b;
        border-radius: 10px;
        margin-bottom: 20px;
        color: #fff;
        background-color: #6aaf8b;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            color: #6aaf8b;
            background-color: #fff;
        }
    }

    .btn-darkblue {
        width: 150px;
        margin: 0 auto;
        display: block;
        padding: 5px;
        border: 1px solid #4d4d4d;
        border-radius: 10px;
        color: #fff;
        background-color: #4d4d4d;
        cursor: pointer;
        font-size: 14px;
        height: 30px;

        &:hover {
            color: #4d4d4d;
            background-color: #fff;
        }
    }

    .suitcase-wrapper {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: #fff;
        top: -25px;
        left: -35px;
        box-shadow: 0px 3px 5px #00000029;

        &.green {
            background-color: #6AAF8B;
        }

        img {
            width: 40px;
        }
    }

    .distance-wrapper {
        position: absolute;
        width: calc(60%);
        left: 20%;
        top: 340px;
        height: auto;
        border: 1px solid #6aaf8b;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        font-size: 14px;
        box-sizing: border-box;
        padding: 5px 0;

        .col-wrapper {
            text-align: center;
        }

        .voiture-wrapper, .velo-wrapper, .train-wrapper {
            width: 33%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            svg {
                font-size: 30px;
            }
        }
        
        .svg-small {
            font-size: 22px;
        }

        .svg-normal {
            font-size: 30px;
        }

        .train-wrapper {
            svg {
                font-size: 22px;
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        width: 270px;
        border-radius: 30px; 
        padding: 15px;

        .title-wrapper {
            font-size: 18px;
        }

        .job-cat {
            font-size: 16px;
        }

        h3 {
            height: 50px;
            font-size: 20px;
        } 

        .suitcase-wrapper {
            height: 70px;
            width: 70px;
            border-radius: 50%;
            top: -25px;
            left: -35px;
    
            img {
                width: 40px;
            }
        }
    }

    @media only screen and (min-width: 760px) and (max-width: 1079px) {
        .mobile-none {
            
        }
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        margin: 10px;
        padding: 10px;
        box-shadow: 0px 8px 20px 5px rgba(219,219,219,1);
        height: ${props => props.location === 'job' ? '300px' : '250px'};
        border-radius: 20px;
        
    
        .title-wrapper {
            width: calc(100% - 70px);
            margin: 0 0 0 70px;
            height: 40px;
            border-bottom: 1px solid #6aaf8b;
    
            .first-title {
                font-size: 16px;
            }
        }
    
        .job-cat {
            height: auto;
            font-size: 18px;
            border: 0;
            width: calc(100% - 70px);
            margin: 0 0 0 auto;
            padding: 10px 0;
            font-size: 16px;
        }
    
        h3, .salary-wrapper {
            font-size: 18px;
            height: 36px;

            .small-txt {
                .black {
                    font-size: 20px;
                } 
            }
        }

        .salary-wrapper {
            font-size: 16px;
            .small-txt {
                .black-small {
                    font-size: 16px;
                }
            }
        }

        h3 {
            margin-top: 20px;
        }

        .publication-date {
            padding-top: 20px;
        }

        .btn-lightgreen {
            margin: 20px auto 0;
        }
        
        .suitcase-wrapper {
            top: 5px;
            left: 5px;
        }

        .distance-wrapper {
            display: none;
        }
    }

`;

export default JobCardStyled;