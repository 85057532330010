/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { FaGenderless } from 'react-icons/fa'
import { BsHourglassSplit } from 'react-icons/bs'
import { RiNumber1, RiNumber2, RiNumber3, RiNumber4  } from 'react-icons/ri'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { IoIosCopy } from "react-icons/io"
import { toast } from 'react-toastify'

/**** Import des css ****/
import StepTwoStyled from './StepTwoStyled'

import cryptoAxepta from '../../../assets/js/cryptoAxepta'
import imgCb from '../../../assets/img/illustration/banque-cb.png'
import imgVirement from '../../../assets/img/illustration/virement-bancaire.png'


const StepTwoE = ({ thisFinancement, changeFactureStatut }) => {

  const [hoverCB, setHoverCB] = useState(false)
  const [hoverB, setHoverB] = useState(false)
  const [showSEPA, setShowSEPA] = useState(false)
  const [choice, setChoice] = useState(0)
  const [openBanque, setOpenBanque] = React.useState(false)

  const handleMouseEnterCB = () => {
    setHoverCB(true)
  }

  const handleMouseLeaveCB = () => {
    setHoverCB(false)
  }

  const handleMouseEnterB = () => {
    setHoverB(true)
  }

  const handleMouseLeaveB = () => {
    setHoverB(false)
  }

  const handleClickOpenBanque= () => {
    setOpenBanque(true);
  }
  
  const handleCloseBanque = () => {
    setOpenBanque(false);
  }

  const copyElement = (string) => {
    navigator.clipboard.writeText(string)
    toast(`${string} : copié dans le presse-papier`, {
      position: "bottom-right",
      type: "success",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

    /* STYLING DIALOG */

    const imgWrapper = {
      height: '200px',
      padding: '10px 0',
      boxSizing: 'border-box',
    }
  
    const imgCss = {
      width: '50%',
      transition: 'transform .2s',
      margin: '0 auto',
      display: 'block',
      boxSizing: 'border-box',
      cursor: 'pointer',
      padding: '5px',
    }
  
    const h3img = {
      padding: '10px 0',
      fontSize: '16px',
      fontWeight: 'bold',
      textAlign: 'center',
    }
  
    const sepaWrapper = {
      border: '2px solid #6aaf8b',
      borderRadius: '8px',
      padding: '10px',
      margin: '10px 0',
    }
  
    const sepaPara = {
      padding: '5px 0',
    }
  
    const h4sepa = {
      padding: '0 0 10px 0'
    }
    
    let CB = {
      transform: hoverCB ? 'scale(1.05)' : 'scale(1.0)',
      border: hoverCB ? '2px solid #6aaf8b' : '0',
      borderRadius: '8px'
    }
  
    let Ba = {
      transform: hoverB ? 'scale(1.05)' : 'scale(1.0)',
      border: hoverB ? '2px solid #6aaf8b' : '0',
      borderRadius: '8px',
    }
  
    let activeC = {
      transform: 'scale(1.05)',
      border: '2px solid #6aaf8b',
      borderRadius: '8px',
    }
  
    const copyCSS = {
      color: '#6aaf8b',
      marginLeft: '10px',
      cursor: 'pointer',
      marginBottom: '-2px',
    }


  const calcRestFactureOpco = (facture) => {
    const prixFormation = Number(facture.attributes.Prix * facture.attributes.nombre_particulier)

    const montantOpco = Number(thisFinancement.attributes.opco.data.attributes.montant_financement)

    const difference = prixFormation - montantOpco

    if (difference > 0) {
      return {isPaid: false, difference: difference}
    } else if (difference === 0) {
      return {isPaid: true, difference: difference}
    } else {
      return {isPaid: false, difference: difference}
    }
  }

  const calcRestFactureAuto = (facture) => {
    const alreadyPaid = calcRestFactureOpco(facture).difference
    const montantAuto = facture.attributes.autofinancement.data !== null ? Number(facture.attributes.autofinancement.data.attributes.montant_financement) : 0

    const difference = alreadyPaid - montantAuto
    if (difference > 0) {
      return {isPaid: false, difference: difference}
    } else if (difference === 0) {
      return {isPaid: true, difference: difference}
    } else {
      return {isPaid: false, difference: difference}
    }
  }

  return (
    <StepTwoStyled>
      { thisFinancement.attributes.autofinancement.data && 
      <>
      {thisFinancement.attributes.autofinancement.data.attributes.montant_financement === null &&
        <>
          <h2 className='bold'>Un paiement en cours de validation, nous vous contacterons dans les plus brefs délais.</h2>
        </>
      }
      </>
      }
      { thisFinancement.attributes.opco.data && 
      <>
      {thisFinancement.attributes.opco.data.attributes.montant_financement === null ?
        <>
        {thisFinancement.attributes.opco.data.attributes.preuve_financement.data === null ?
      <></>
      :  
      <>
      <h2 className='bold'>Document OPCO en cours de validation</h2>
      <ul className='info-list'>
        <li><FaGenderless />Titre du document : <span className='bold'> {thisFinancement.attributes.opco.data.attributes.preuve_financement.data !== null && thisFinancement.attributes.opco.data.attributes.preuve_financement.data.attributes.name}</span></li>
        <li><FaGenderless /> Nom de la formation : <span className='bold'> {thisFinancement.attributes.nom_formation}</span></li>
        <li><FaGenderless /> Demande effectuée le :  <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.publishedAt}</Moment></span></li>
        <li><FaGenderless /> Nombre d'inscrits : <span className='bold'>{thisFinancement.attributes.nombre_particulier}</span></li>
        <li><FaGenderless /> Type de formation : <span className='bold'>100% Elearning</span></li>
        <li><FaGenderless /> Prix de la formation : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix))} TTC</span></li>
        <li><FaGenderless /> Total facturé : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix * thisFinancement.attributes.nombre_particulier))} TTC</span></li>
        </ul>
        <div className='msg-txt'><BsHourglassSplit />Vous recevrez un email en cas de validation du document. Nous allons traiter votre demande dans les plus brefs délais.</div>
      </>
      }
        </>
        :
        <>
          {calcRestFactureOpco(thisFinancement).isPaid ? 
            <>
              <h2 className='bold'>Document validé et session créée</h2>
              <ul className='info-list'>
                <li><FaGenderless /> Début de la formation : <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.start_session}</Moment></span></li>
                <li><FaGenderless /> Fin de la formation : <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.end_session}</Moment></span></li>
                <li><FaGenderless /> Nom de la formation : <span className='bold'> {thisFinancement.attributes.nom_formation}</span></li>
                <li><FaGenderless /> Demande effectuée le :  <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.publishedAt}</Moment></span></li>
                <li><FaGenderless /> Nombre d'inscrits : <span className='bold'>{thisFinancement.attributes.nombre_particulier}</span></li>
                <li><FaGenderless /> Type de formation : <span className='bold'>100% Elearning</span></li>
                <li><FaGenderless /> Prix de la formation : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix))} TTC</span></li>
                <li><FaGenderless /> Total facturé : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix * thisFinancement.attributes.nombre_particulier))} TTC</span></li>
              </ul>
              <h2 className='bold'>Accès à la formation</h2>
              <ul className='info-list'>
                <li><RiNumber1 className='number bold'/> <div>Vos collaborateurs vont recevoir, la veille de la formation, un email avec les détails de connexion à leur session de formation.</div></li>
                <li><RiNumber2 className='number bold'/><div>Ils pourront se connecter pendant toute la durée de la formation.</div></li>
                <li><RiNumber3 className='number bold'/><div>Vous pouvez retrouver tous les documents relatifs à la formation dans votre espace "vos documents".</div></li>
                {thisFinancement.attributes.lms_nom !== 'univo' && thisFinancement.attributes.lms_nom !== 'formapro' && 
                <li><RiNumber4 className='number bold'/><div>Suivez l'évolution de vos collaborateurs en direct dans votre espace WEFOR.</div></li>
                }
              </ul>
            </>
          :
          <>
          {calcRestFactureAuto(thisFinancement).isPaid ?
          <>
          <h2 className='bold'>Document validé et session créée</h2>
          <ul className='info-list'>
            <li><FaGenderless /> Début de la formation : <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.start_session}</Moment></span></li>
            <li><FaGenderless /> Fin de la formation : <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.end_session}</Moment></span></li>
            <li><FaGenderless /> Nom de la formation : <span className='bold'> {thisFinancement.attributes.nom_formation}</span></li>
            <li><FaGenderless /> Demande effectuée le :  <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.publishedAt}</Moment></span></li>
            <li><FaGenderless /> Nombre d'inscrits : <span className='bold'>{thisFinancement.attributes.nombre_particulier}</span></li>
            <li><FaGenderless /> Type de formation : <span className='bold'>100% Elearning</span></li>
            <li><FaGenderless /> Prix de la formation : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix))} TTC</span></li>
            <li><FaGenderless /> Total facturé : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix * thisFinancement.attributes.nombre_particulier))} TTC</span></li>
          </ul>
          <h2 className='bold'>Accès à la formation</h2>
              <ul className='info-list'>
                <li><RiNumber1 className='number bold'/> <div>Vos collaborateurs vont recevoir, la veille de la formation, un email avec les détails de connexion à leur session de formation.</div></li>
                <li><RiNumber2 className='number bold'/><div>Ils pourront se connecter pendant toute la durée de la formation.</div></li>
                <li><RiNumber3 className='number bold'/><div>Vous pouvez retrouver tous les documents relatifs à la formation dans votre espace "vos documents".</div></li>
                {thisFinancement.attributes.lms_nom !== 'univo' && thisFinancement.attributes.lms_nom !== 'formapro' && 
                <li><RiNumber4 className='number bold'/><div>Suivez l'évolution de vos collaborateurs en direct dans votre espace WEFOR.</div></li>
                }
              </ul>
        </>
          :
          <>
          <h2>Document validé mais solde insuffisant</h2>
          <ul className='info-list'>
            <li><FaGenderless /> Reste à payer : <span className='bold'>{calcRestFactureAuto(thisFinancement).difference}</span></li>
            <li><FaGenderless /> Nom de la formation : <span className='bold'> {thisFinancement.attributes.nom_formation}</span></li>
            <li><FaGenderless /> Demande effectuée le :  <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.publishedAt}</Moment></span></li>
            <li><FaGenderless /> Nombre d'inscrits : <span className='bold'>{thisFinancement.attributes.nombre_particulier}</span></li>
            <li><FaGenderless /> Type de formation : <span className='bold'>100% Elearning</span></li>
            <li><FaGenderless /> Prix de la formation : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix))} TTC</span></li>
            <li><FaGenderless /> Total facturé : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix * thisFinancement.attributes.nombre_particulier))} TTC</span></li>
          </ul>
          <h2 className='bold'>Merci de procéder au paiement du solde par Carte bleue</h2>
          <button className='paiement-link' onClick={handleClickOpenBanque}>Payer</button>
        {/*<a href={cryptoAxepta()} target='_blank' rel='noreferrer' class='paiment-cb bold'></a>*/}
        <Dialog open={openBanque} onClose={handleCloseBanque}>
          {showSEPA === false ?
          <DialogTitle>Choisissez votre moyen de paiement</DialogTitle>
          :
          <DialogTitle>Paiement par virement bancaire</DialogTitle>
          }
          {showSEPA === false && 
          <DialogContent>
            {/*
            <div style={{...imgWrapper}}>
              <h3 style={{...h3img}}>Payez par carte bleue</h3>
              <img 
                src={imgCb} 
                alt='Cartes bleus disponilbes pour un paiement CB' 
                style={choice === 1 ? {...imgCss, ...CB, ...activeC} : {...imgCss, ...CB}}
                onMouseEnter={handleMouseEnterCB}
                onMouseLeave={handleMouseLeaveCB}
                onClick={() => {
                  setChoice(1)
                }}
              />
            </div>
            */}
            <div style={{...imgWrapper}}>
              <h2 style={{...h3img}} className='bold'>Payez par virement bancaire</h2>
              <img 
                src={imgVirement} 
                alt='Cartes bleus disponilbes pour un paiement CB' 
                style={choice === 2 ? {...imgCss, ...Ba, ...activeC} : {...imgCss, ...Ba}}
                onMouseEnter={handleMouseEnterB}
                onMouseLeave={handleMouseLeaveB}
                onClick={() => {
                  setChoice(2)
                }}
              />
            </div>
          </DialogContent>
          }
          {showSEPA === true && 
          <DialogContent>
            <div style={{...imgWrapper}}>
              <h4
                className='bold' 
                style={{...h4sepa}}
              >
                Merci de renseigner le numéro de la demande dans le virement : {thisFinancement.id} 
                <IoIosCopy 
                  style={{...copyCSS}} 
                  onClick={() => {copyElement(thisFinancement.id)}}
                />
              </h4>
              <h4
                className='bold' 
                style={{...h4sepa}}
              >
                Montant du virement : {euroFormat.format(Number(thisFinancement.attributes.Prix * thisFinancement.attributes.nombre_particulier))} 
                <IoIosCopy 
                  style={{...copyCSS}} 
                  onClick={() => {copyElement(Number(thisFinancement.attributes.Prix * thisFinancement.attributes.nombre_particulier))}}
                />
              </h4>
              <div style={{...sepaWrapper}}>
                <p style={{...sepaPara}}>
                  Référence bancaire : <span className='bold'>BNP ANTONY</span>
                  <IoIosCopy style={{...copyCSS}} onClick={() => {copyElement('BNP ANTONY')}}/>
                </p>
                <p style={{...sepaPara}}>
                  IBAN : <span className='bold'>FR76 3000 4001 8300 0104 2509 368</span>
                  <IoIosCopy style={{...copyCSS}} onClick={() => {copyElement('FR76 3000 4001 8300 0104 2509 368')}}/>
                </p>
                <p style={{...sepaPara}}>
                  BIC : <span className='bold'>BNPAFRPPXXX</span>
                  <IoIosCopy style={{...copyCSS}} onClick={() => {copyElement('BNPAFRPPXXX')}} />
                </p>
              </div>
              <p>Merci de valider seulement si vous avez effectué le virement.</p>
            </div>
          </DialogContent>
          }
          {showSEPA === false ?
          <DialogActions>
            <Button onClick={handleCloseBanque}>Annuler</Button>
            <Button 
              onClick={() => {
                if(choice === 2) {
                  setShowSEPA(true)
                }
                if(choice === 1) {
                  handleCloseBanque()
                }
              }}
              disabled={choice === 0 ? true : false}
            >Valider</Button>
          </DialogActions>
          :
          <DialogActions>
            <Button 
              onClick={() => {
                handleCloseBanque() 
                setShowSEPA(false)
                setChoice(0)
              }}>
                Annuler
            </Button>
            <Button onClick={() => {
              if(choice === 2) {
                changeFactureStatut(2, 'Banque & OPCO', 'autofinancement')
                handleCloseBanque()
                setShowSEPA(false)
              }
              if(choice === 1) {
                handleCloseBanque()
                setShowSEPA(false)
              }
            }}>Virement effectué</Button>
          </DialogActions>
          }
        </Dialog>
          <p className='para-bottom'>Pour plus d'informations, contactez-nous au <a href="tel:+33972575262">09 72 57 52 62</a>.</p>
          </>
          }
          </>

         }
        </>
      }
      </>
      }
    </StepTwoStyled>
  )
}

export default StepTwoE