import * as XLSX from 'xlsx/xlsx.mjs';
import { DataGrid } from '@mui/x-data-grid'

function ExcelFacture() {
  const json = [
      {
        name: "jon",
        surname: "doe",
        s : {
          fill: {
          patternType: "none", // none / solid
          fgColor: {rgb: "FF000000"},
          bgColor: {rgb: "FFFFFFFF"}
          },
          font: {
          name: 'Times New Roman',
          sz: 16,
          color: {rgb: "#FF000000"},
          bold: false,
          italic: false,
          underline: false
          },
          border: {
          top: {style: "thin", color: {auto: 1}},
          right: {style: "thin", color: {auto: 1}},
          bottom: {style: "thin", color: {auto: 1}},
          left: {style: "thin", color: {auto: 1}}
          }
      }
      },
      {
        name: "jon",
        surname: "doe"
      }
  ];

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 90,
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
  ];
  
  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];
  const downloadxls = (e, data) => {
    e.preventDefault();

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "test");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx");
  };
  return (
    <div className="App">
      <h1>Hello CodeSandbox</h1>
      <h2>xlsx download in the browser</h2>
      <button
        onClick={(e) => {
          downloadxls(e, json);
        }}
      >
        Download xlsx
      </button>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      </div>
    </div>
  );
}
export default ExcelFacture