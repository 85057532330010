/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import Skeleton from '@mui/material/Skeleton'
import Moment from 'react-moment'

/**** Import des images ****/

/**** Import des css ****/

const EntreprisesUser = ({
  usersFormAdminE
}) => {

  return (
    <section>
      <div className="pre-list-wrapper">
        {usersFormAdminE === 'loading' &&
          <Skeleton variant="text" width={'100%'} height={20}/>
        }
         {usersFormAdminE !== 'loading' && usersFormAdminE.length > 0 &&
          <>
          <div className='title-wrapper'>
            <div className='row-title bold'>Entreprise</div>
            <div className='row-title bold'>Référent</div>
            <div className='row-title bold'>Email</div>
            <div className='row-title bold'>Créé le</div>
            <div className='row-title bold'>Informations</div>
          </div>
          {usersFormAdminE.map((user, index) => (
            <>
              {user.attributes.user.data !== null && 
                <div key={index} className='info-wrapper'>
                  <div className='row-user modif-height'>{user.attributes.Nom_entreprise}</div>
                  <div className='row-user modif-height'>{user.attributes.user.data.attributes.nom} {user.attributes.user.data.attributes.prenom}</div>
                  <div className='row-user modif-height'>{user.attributes.user.data.attributes.email}</div>
                  <div className='row-user modif-height'><Moment format="DD/MM/YYYY">{user.attributes.user.data.attributes.createdAt}</Moment></div>
                  <div className='row-user modif-height more-link'>En savoir plus</div>
                </div>
              }
            </>
          ))}
          </>
        }
      </div>
    </section>
  )
}

EntreprisesUser.propTypes = {
}

export default EntreprisesUser