import { connect } from 'react-redux'

import AccountJobs from '../../pages/AccountJobs'

import { changeValueNewJob, fetchJobOffer, fetchPostJobOffer, saveJobsEntreprise, setSelectionJobOffer, setFullPageLoaderJobOfferAction, fetchDemandesJobFromJobOffer } from '../../actions/job'
import { setMenuDocument, fetchSecteurs } from '../../actions/menu'
import { fetchGetMyTestsOcean } from '../../actions/ocean'

const mapStateToProps = (state) => ({
  user: state.login.session.user,
  userInfo: state.login.userInfo,
  userRole: state.login.userRole,
  isLogged: state.login.isLogged,
  jobsEntreprise: state.job.jobsEntreprise,
  jobsEntreprisePagination: state.job.jobsEntreprisePagination,
  jobFiches: state.job.jobFiches,
  secteursData: state.menu.secteursData,
  domainesData: state.menu.domainesData,
  secteurJob: state.job.secteurJob,
  domaineJob: state.job.domaineJob,
  ficheJob: state.job.ficheJob,
  myTestsOcean: state.ocean.myTestsOcean,
  remunerationjob: state.job.remunerationjob,
  jobsAccountLoading: state.job.jobsAccountLoading,
  modalJobsAccount: state.job.modalJobsAccount
})

const mapDispatchToProps = (dispatch) => ({
    changeValueNewJob: (value, name) => {
        dispatch(changeValueNewJob(value, name));
      },
    fetchJobOffer: (id, nbOffer, nbPage) => {
      dispatch(fetchJobOffer(id, nbOffer, nbPage));
    },
    fetchPostJobOffer: (nomOfManager, prenomOfManager) => {
      dispatch(fetchPostJobOffer(nomOfManager, prenomOfManager));
    },
    saveJobsEntreprise: () => {
      dispatch(saveJobsEntreprise());
    },
    setSelectionJobOffer: (data) => {
      dispatch(setSelectionJobOffer(data));
    },
    setMenuDocument: (value) => {
      dispatch(setMenuDocument(value));
    },
    setFullPageLoaderJobOfferAction: (bool) => {
      dispatch(setFullPageLoaderJobOfferAction(bool));
    },
    fetchGetMyTestsOcean: (id) => {
      dispatch(fetchGetMyTestsOcean(id));
    },
    fetchDemandesJobFromJobOffer: (id) => {
      dispatch(fetchDemandesJobFromJobOffer(id));
    },
    fetchSecteurs: () => {
      dispatch(fetchSecteurs());
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountJobs)