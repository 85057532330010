import styled from 'styled-components';

const AccountRecrutementStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

    .right-wrapper {
        width: calc(96% - 250px);
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        padding: 50px 0;

        .test-choice { 
            margin-top: 40px;
        }

        .secteur-choice, .test-choice {
            width: 100%;
            padding: 0px 0 0px;
            box-sizing: border-box;
            position: relative;
            border-radius: 15px;
            box-shadow: 0px 1px 10px #00000029;
            border-radius: 10px;

            &:nth-child(1) {
                margin-bottom: 40px;
                font-size: 16px;
            }

            .drop-down-1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                cursor: pointer;
                font-size: 18px;

                h4, .info-dyna {
                    width: calc(50% - 25px);
                    text-align: left;
                }

                .arrow-wrapper {
                    max-height: 20px;
                    .arrow-down {
                        font-size: 50px;
                        color: #D47558;
                        margin-top: -15px;
                        margin-right: -20px;
                    }
                }
            }

            h4 {
                color: #D47558;
                text-align: center;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }

            .formations-wrapper {
                div {
                    color: #4d4d4d;
                    display: flex;
                    flex-wrap: wrap;
                    align-items:center;
                    justify-content: space-between;
                    font-size: 16px;
                    border-top: 1px solid #f8e8e3;
                    cursor: pointer;

                    span {
                        padding-right: 10px;
                    }

                    &:hover {
                        background-color: #f8e8e3;
                    }
                }
            }

            .list-paragraphe {
                text-align: left;
                padding: 10px;
                display: flex;
                align-items: center;
                text-transform: capitalize;

                a {
                    color: #D47558;
                    margin-left: 20px;
                    transition: all .3s ease;

                    &:hover {
                        opacity: .7;
                    }
                }

                svg {
                    color: #D47558;
                    margin:0 10px 0 0;
                }
            }
        }

        .w-score {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 650px;
            justify-content: space-evenly;
        }

        .temps-wrapper {
            width: 180px;
            height: 180px;
            position: relative;
            border-radius: 50%;
            box-shadow: 0px 3px 6px #00000029;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 35px;
            color: #D4755A;
        }

        .circle-wrapper {
            width: 180px;
            height: 180px;
            position: relative;
            border-radius: 50%;
            box-shadow: 0px 3px 6px #00000029;
            
            .score-div {
                position: absolute;
                top: 0;
                text-align: center;
                width: 100%;
                height: 100%;
                color: #6AAF8B;
                font-size: 40px; 
    
                .top {
                    margin-top: 40px;
                    margin-left: -60px;
                    display: block;
                }
                .middle {
                    margin-top: -10px;
                    margin-left: 0px;
                    display: block;
                    font-size: 40px;
                }
                .bottom {
                    margin-top: 0px;
                    margin-left: 60px;
                    display: block;
                }
            }
        }

        .btn-show-test {
            border: 0;
            background-color: #6aaf8b;
            color: #fff;
            border-radius: 5px;
            padding: 15px;
            box-sizing: border-box;
            cursor: pointer;
            transition: all .3s ease;
            display: block;
            margin: 20px auto 0;

            &:hover {
                opacity: .7;
            }
        }

        h1 {
            font-size: 50px;
            display: inline-block;
            margin: 50px auto 100px;
            text-transform: uppercase;
            color: #649ACE;
            text-align: center;

            span {
                position: relative;
            }

            .separator-blue {
                background-color: #202D3C;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
            }
        }
        
        h2 {
            font-size: 30px;
            display: inline-block;
            margin: 0 auto 50px;
            text-transform: uppercase;
            color: #202D3C;

            &.margin-top {
                margin-top: 40px;
            }

            span {
                position: relative;
            }

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
            }
        }

        h3 {
            font-size: 20px;
            margin: 0 0 10px 0;
            color: #202c3c;
            text-align: center;
        }

        .test-cond-wrapper {
            width: 100%;

            p {
                margin: 0 0 40px 0;
                color: #202c3c;
                text-align: center;
            }
        }

        .center {
            text-align: center;
        }

        .condition-wrapper {

            box-shadow: 0px 3px 10px #00000069;
            padding: 20px;
            margin: 40px 0;
            border-radius: 10px;
            
            p {
                margin: 0 0 40px 0;
                color: #202c3c;
            }
    
            h4 {
                font-size: 18px;
                margin: 0 0 25px;
            }
            
            ul {
                li {
                    list-style: inside;
                }
            }
        }
    }

    .modal-wrapper {
        width: 100vw;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, .4);
        z-index: 100000;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .test-wrapper {
            max-width: 750px;
            width: 96%;
            min-width: 350px;
            min-height: 300px;
            max-height: 600px;
            background-color: #fff;
            border-radius: 10px;
            padding: 20px;

            .num-wrapper {

                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                margin: 20px 0 0 0;

                .number-circle {
                    width: 20px;
                    height: 20px;
                    font-size: 12px;
                    border-radius: 50%;
                    border: 1px solid #6aaf8b;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #6aaf8b;

                    &.plain {
                        background-color: #6aaf8b;
                        color: #fff;
                    }
                }
            }

            .question-section-wrapper {
                margin-top: 20px;

                .question-wrapper {
                    width: 100%;

                    label {
                        font-size: 20px;
                        font-weight: bold;
                    }

                    .validate-btn {
                        padding: 10px;
                        border: 0;
                        border-radius: 5px;
                        background-color: #6aaf8b;
                        color: #fff;
                        width: 250px;
                        box-sizing: border-box;
                        cursor: pointer;
                        margin: 0 auto;
                        transition: all .3s ease;

                        &:hover {
                            opacity: .7;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(96% - 250px);

            h1 {
                font-size: 45px;
            }

            h2 {
                font-size: 35px;
            }
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(96% - 250px);

            h1 {
                font-size: 40px;
            }

            h2 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {
        margin: 60px auto 80px;
        
        .right-wrapper {
            width: calc(96%);
            padding: 20px 0;

            h1 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }

            h2 {
                font-size: 25px;
                text-align: center;

                .separator-blue {
                    width: 50px;
                    position: static;
                    margin: 10px auto;
                }
            }
        }
    }
}
  
`;

export default AccountRecrutementStyled;