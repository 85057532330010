import styled from 'styled-components';

const ApprenantCardStyled = styled.article`
	width: 100%;
	box-sizing: border-box;
	box-shadow: 0px 1px 10px #00000029;
	border-radius: 10px;
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
	margin: 10px 0 30px;
	color: #4d4d4d;
	position: relative;

	&.bcg-red {
		background-color: #f8e8e3;
	}

	.days-remain {
		position: absolute;
		width: 80px;
		height: 26px;
		background-color: #d47558;
		color: #fff;
		font-size: 18px;
		left: calc( 50% - 40px);
		top: -13px;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.top-section {
		width: 100%;
		padding: 10px;
		margin: 2px 2px 10px 2px;
		color:  #78b796;
		border-radius: 10px;

		h3 {
			text-align: center;
			padding: 10px 0;
			font-size: 17px;
		}

		.infos-apprenant-wrapper {
			display: flex;
			width: 100%;
			flex-wrap: wrap;

			.info-apprenant {
				width: 25%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				span {
					width: calc(100% - 35px) 
				}
				svg {
					font-size: 25px;
					color: #2a4637;
				}
			}

			.bottom {
				border: 0;
			}
		}
	}

	.bottom-section {
		width: 100%;

		h3 {
			text-align: center;
			padding: 10px 0;
			font-size: 17px;
		}

		.infos-session-wrapper {
			display: flex;
			width: 100%;
			flex-wrap: wrap;

			.top {
				background-color: #fff;
			}

			.bottom {
				div {height: 100%;}

				.btn-action {
					padding: 5px;
					width: 100%;
					min-height: 43px;
					max-height: 43px;
					border: 0;
					background-color: #6aaf8b;
					border-radius : 10px;
					height: 100%;
					color: #fff;
					cursor: pointer;
					transition: all .3s ease;
					margin: 0 0 5px 0;

					&:hover {
						opacity: .7;
					}
				}

				.done-task {
					background-color: #e1efe7;
					border-radius : 10px;
					height: 100%;
					padding: 5px;
					min-height: 43px;
					max-height: 43px;
					margin: 0 0 5px 0;
					

					&.center {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.top, .bottom {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				border-top: 1px solid #4d4d4d;
				border-bottom: 1px solid #4d4d4d;

				div {
					width: 20%;
					text-align: center;
					padding: 5px 2px;

					&.border {
						border-right: 1px solid #4d4d4d;
						border-left: 1px solid #4d4d4d;
						margin: 10px 0;
						padding: 0;
					}

					&:first-child {
						border-left: 0;
					}

					&:last-child {
						border-right: 0;
					}
				}

				.btn-univo {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					background-color: #f8e8e3;
					color: #d47558;
					border-radius: 10px;
					cursor: pointer;
					transition: all .3s ease;
					min-height: 43px;
					max-height: 43px;
					margin: 0 0 5px 0;

					&:hover {
						opacity: .7;
					}
				}

				.btn-360 {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					background-color: #dbe8f4;
					color: #6399ce;
					border-radius: 10px;
					cursor: pointer;
					min-height: 43px;
					max-height: 43px;
					transition: all .3s ease;
					margin: 0 0 5px 0;

					&:hover {
						opacity: .7;
					}
				}
			}

			.bottom {
				border: 0;
			}
		}
	}

	.bottom-section {
		width: 100%;
	}

	.MuiTypography-root{
		margin-bottom: 8px !important;
	}
`;

export default ApprenantCardStyled;