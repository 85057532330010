import { connect } from 'react-redux'

import YouAre from '../../../components/SignIn/YouAre'

import { 
    changeSigninStep,
} from '../../../actions/signin'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    changeSigninStep: (number) => {
        dispatch(changeSigninStep(number));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(YouAre)