import styled from 'styled-components';

const ModalSatisfactionStyled = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    

    .modal-content-container {
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 5px 15px 10px #00000066;
        box-sizing: border-box;
        max-height: 90vh;
        width: 92%;
        max-width: 1000px;
        overflow: visible;
        position: relative;

        .page-wrapper-message {
            max-height: 90vh;
            box-sizing: border-box;
            overflow-y: auto;
        }

        .scroll-zone {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            height: 100%;

            
            .top {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: end;
                width: 100%;
                padding: 25px 50px;
                border-bottom: 1px solid #4d4d4d;


                .title-container {
                    display: flex;
                    flex-direction: column;

                    .dark {
                        color: #4d4d4d;
                        font-size: 20px;
                        padding-bottom: 5px;
                    }

                    .blue {
                        color: #649ACE; 
                        font-size: 20px;
                    }

                    h3 {
                        text-transform: uppercase;
                    }
                }

                .dates {
                    font-size: 20px;
                    color: #4d4d4d;
                }
            }

            h2 {
                font-size: 30px;
                color: #649ACE;
            }

            .form-wrapper {
                font-size: 20px;
                width: 100%;
                padding: 0 50px 20px;
                color: #707070;

                .radio-w {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 30px;

                    .right {
                        min-width: 165px;
                        text-align: right;
                    }

                    input {
                        margin-left: 20px;
                    }

                    label {
                        margin: 0 0px 0 10px;
                    }
                }

                .text-area {
                    margin-bottom: 30px;
                    width: 100%;

                    div {
                        display: flex;
                        justify-content: space-between;
                    }

                    textarea {
                        margin-top: 20px;
                        border-radius: 15px;
                        width: 100%;
                        padding: 20px;

                        &::placeholder {
                            color: #707070;
                            font-size: 18px;
                        }
                    }
                }


                .btn-w {
                    width: 100%;
                    text-align: right;

                    .green-btn {
                        padding: 15px;
                        border: 0;
                        border-radius: 15px;
                        width: 200px;
                        color: #fff;
                        font-size: 20px;
                        cursor: pointer;
                        background-color: #6AAF8B;
                        margin-bottom: 10px;
                        transition: all .3s ease;

                        &:hover {
                            opacity: .7;
                        }
                    }
                }

                .align-center {
                    display: flex;
                    align-items: center;

                    .MuiRating-root {
                        margin-right: 10px;
                    }
                }
            }
        }

        .icon-wrapper {
            position: absolute;
            top: -35px;
            right: -30px;
            width: 40px;
            height: 40px;
            background-color: #649ACE;
            border-radius: 17.5px;
            display:flex;
            align-items: center;
            justify-content: center;

            .close-icon {
                color: #fff;
                font-size: 30px;
                cursor: pointer;
                border-radius: 20px;
            }
        }
    }

    @media only screen and (max-width: 1079px) {
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .modal-content-container {

            .scroll-zone {
                .top {
                    padding: 25px 20px;
                }

                .form-wrapper {
                    padding: 0 20px 20px;
                }
            }
            
            .icon-wrapper {
                position: absolute;
                top: 5px;
                right: 5px;
                width: 40px;
                height: 40px;
                background-color: #649ACE;
                border-radius: 17.5px;
                display:flex;
                align-items: center;
                justify-content: center;
    
                .close-icon {
                    color: #fff;
                    font-size: 30px;
                    cursor: pointer;
                    border-radius: 20px;
                }
            }
        }
    }
`;

export default ModalSatisfactionStyled;