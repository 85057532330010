import { 
    SAVE_QUESTION_OCEAN,
    SAVE_REPONSE_OCEAN,
    SAVE_CATEGORIE_OCEAN,
    CHANGE_VALUE_OCEAN_Q,
    CHANGE_MANAGER_NAME,
    SET_FULL_PAGE_LOADER_OCEAN,
    SAVE_MY_TESTS_OCEAN
  } from "../actions/ocean";
  
  export const initialState = {
    questionOcean: [],
    reponseOcean: [],
    categorieOcean: [],
    managerNom: '',
    managerPrenom: '',
    valueInputs: [
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
      {input: 0, score: 0, categorie: ''},
    ],
    fullPageLoaderOcean: false,
    myTestsOcean: []
  };
  
  // reducer = traducteur d'une intention/action vers une modification du state
  const ocean = (state = initialState, action = {}) => {
    switch (action.type) {
        case SAVE_QUESTION_OCEAN:
            return {
            ...state,
            questionOcean: action.data.data
            };
        case SAVE_REPONSE_OCEAN:
        return {
            ...state,
            reponseOcean: action.data.data
        };
        case SAVE_CATEGORIE_OCEAN:
          return {
              ...state,
              categorieOcean: action.data.data
          };
        case CHANGE_VALUE_OCEAN_Q:
          return {
            ...state,
            valueInputs: state.valueInputs.map(
              (valueInputs, i) => i === action.index ? {...valueInputs, input: action.value, score: action.score, categorie: action.categorie} : valueInputs
            ),
          }
        case CHANGE_MANAGER_NAME:
          return {
            ...state,
            [action.name]: action.value,
          };
        case SET_FULL_PAGE_LOADER_OCEAN:
          return {
            ...state,
            fullPageLoaderOcean: action.bool,
          };
        case SAVE_MY_TESTS_OCEAN:
          return {
            ...state,
            myTestsOcean: action.data,
          };
      default:
        return state;
    }
  };
  
  export default ocean;