/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';
import useScrollPosition from '@react-hook/window-scroll'
import PropTypes from 'prop-types'
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import JobCard from '../../containers/JobCard'
import CourseCard from '../../containers/CourseCard'
import CourseCardLoading from '../../components/CourseCardLoading'
import { Parallax } from 'react-scroll-parallax'
import AOS from 'aos';
import "aos/dist/aos.css"
import { BsCheckLg, BsShieldLockFill } from "react-icons/bs"
import { GiProgression } from "react-icons/gi" 
import { MdGroupAdd } from "react-icons/md"
import { FaArrowUp, FaArrowDown } from "react-icons/fa"
import { Helmet } from "react-helmet"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
/**** Import des images ****/
import logo from '../../assets/img/illustration/header-homme.png'
import cpficon from '../../assets/img/logo/WEFOR_CARRE_COULEUR.png'
import onlineCourse from '../../assets/img/logo/online-course.png'
import jobOffer from '../../assets/img/logo/multiple.png'
import qualiopiLogo from '../../assets/img/logo/logo-carre.png'
import losange from '../../assets/img/illustration/parr-top-right.png'
import mortarboard from '../../assets/img/tochange/mortarboard.png'
import responsive from '../../assets/img/tochange/responsive.png'
import suitcase from '../../assets/img/tochange/suitcase.png'
import testimg from '../../assets/img/illustration/accueil_particulier.png'
import testimg2 from '../../assets/img/illustration/accueil-entreprise.png'
import handicap from '../../assets/img/illustration/handicap.png'
import LogoQualiopi from '../../assets/img/logo/AF.png'
import LogoDD from '../../assets/img/logo/datadocke.png'
import bannierDDA from '../../assets/img/header/Wefor_79.png'
import bannierePdf from '../../assets/pdf/brochure-formation.pdf'
import { saveAs } from 'file-saver'

/**** Import des css ****/
import HomeStyled from './HomebisStyled'
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';

const Home = ({ changeCurrentDomaineClicked, setWindowTop, formations, fetchFormations, loadFormation, jobs, loadJobs, currentProfilUser, changeSigninStep, changeCurrentProfilUser, fetchJobs}) => {
  const hasWindow = typeof window !== 'undefined';
  /*
  Loads last 3 course
  useEffect(() => {
    if (jobs.length === 0) {
      const paramObj = {page: 'home' ,intemNb: 3, pageNb: 1}
      fetchJobs(paramObj)
    }
    if (formations.length === 0) {
      const paramObj = {page: 'home' ,intemNb: 3, pageNb: 1}
      fetchFormations(paramObj)
    }
  }, []);

  */

  const today = new Date() // Create a new date object with the current date and time
	const year = today.getFullYear() // Get the current year
	const month = String(today.getMonth() + 1).padStart(2, '0') // Get the current month (add 1 because month index starts at 0), and pad with a leading zero if necessary
	const day = String(today.getDate() + 1).padStart(2, '0') // Get the current day of the month, and pad with a leading zero if necessary

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

	const formattedDate = `${year}-${month}-${day}`

  const [statutOfPage, setStatutOfPage] = useState('part')
  const [currentService, setCurrentService] = useState(1)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  const serviceArray = [
    {
      id: 1,
      titre: 'Montez en compétences',
      image: cpficon,
      text:'Formez-vous à distance pour développer rapidement des compétences adaptées au marché de l’emploi avec nos formations éligibles au CPF.'
    },
    {
      id: 2,
      titre: 'Retrouvez un emploi',
      image: suitcase,
      text:'A l’issue de votre formation Wefor, vous avez accès à nos offres d’emploi exclusivement réservées à nos diplômés et accessibles pendant 1 an sur notre plateforme.'
    },
    {
      id: 3,
      titre: 'Un espace sécurisé et personnalisé',
      image: responsive,
      text:'Retrouvez votre progression pédagogique pas à pas, déposez vos candidatures et découvrez les réponses des entreprises.'
    },
    {
      id: 4,
      titre: 'Personnes en situation de handicap',
      image: handicap,
      text:'Nos formations en elearning sont accessibles au plus grand nombre. N\'hésitez pas à nous contacter pour en savoir plus.',
      link: 'https://www.agefiph.fr/aides-handicap/aide-ladaptation-des-situations-de-formation'
    },
  ]

  const serviceArrayEnt = [
    {
      id: 1,
      titre: 'Former',
      image: mortarboard,
      text:'Découvrez les formations Wefor et boostez les cométences de vos collaborateurs.'
    },
    {
      id: 2,
      titre: 'Recruter',
      image: suitcase,
      text:'Découvrez les profils des diplômés wefor ayant suivi et validé une de nos formation.'
    },
    {
      id: 3,
      titre: 'Espace professionnel',
      image: responsive,
      text:'Un espace professionnel dédié etc... loremipsum.'
    },
  ] ;

  const serviceToShow = () => {
    let serviceToShow =  (statutOfPage === 'part' ? serviceArray : serviceArrayEnt).filter(serviceItem => serviceItem.id === currentService)
    return serviceToShow
  }
  
  let windowTop = useScrollPosition()

  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])

  const handleChangeStatutOfPage = (statut) => {
    setStatutOfPage(statut)
  }

  const mooveBcg = window.pageYOffset; 

  useEffect(() => {
    
    if (windowTop === 0) {
      setWindowTop(true)
    } else {
      setWindowTop(false)
    }
  }, [windowTop]);

  const arrayCardsCourse = [
    {
      "id": "33",
      "attributes": {
        "nom": "Développeur Web - Javascript (débutant)",
        "duree": "7",
        "prix": 790,
        "cpf_link": "https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/91273345800018_DEW20223/91273345800018_DEW20223?contexteFormation=ACTIVITE_PROFESSIONNELLE",
        "secteurs": {
          "data": [
            {
              "id": "12",
              "attributes": {
                "nom": "Informatique / Télécoms"
              }
            }
          ]
        }
      }
    },
    {
      "id": "21",
      "attributes": {
        "nom": "Gestionnaire comptable et fiscal - niveau débutant",
        "duree": "14",
        "prix": 1350,
        "cpf_link": "https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/91273345800018_GC20221/91273345800018_GC20221?contexteFormation=ACTIVITE_PROFESSIONNELLE",
        "secteurs": {
          "data": [
            {
              "id": "21",
              "attributes": {
                "nom": "Comptabilité"
              }
            }
          ]
        }
      }
    },
    {
      "id": "10",
      "attributes": {
        "nom": "Assistant de gestion souscription (niveau débutant)",
        "duree": "15",
        "prix": 1200,
        "cpf_link": "https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/91273345800018_Assu202361/91273345800018_Assu202361?contexteFormation=ACTIVITE_PROFESSIONNELLE",
        "secteurs": {
          "data": [
            {
              "id": "1",
              "attributes": {
                "nom": "Assurance"
              }
            }
          ]
        }
      }
    },
  ]

  const arrayCardsJobs = [
    {
      "id": "19",
      "attributes": {
        "nom": "Secrétaire en assurances",
        "lieu": "Paris 11",
        "remuneration": 26,
        "publishedAt": "2022-09-22T13:54:08.562Z",
        "createdAt": "2022-09-22T13:54:08.571Z",
        "secteurs": {
          "data": [
            {
              "id": "1",
              "attributes": {
                "nom": "Assurance"
              }
            }
          ]
        },
        "formation": {
          "data": {
            "id": "10"
          }
        }
      }
    },
    {
      "id": "29",
      "attributes": {
        "nom": "Graphiste - Web Designer",
        "lieu": "Massy",
        "remuneration": 26,
        "publishedAt": "2022-10-17T11:00:15.171Z",
        "createdAt": "2022-10-17T11:00:15.183Z",
        "secteurs": {
          "data": [
            {
              "id": "12",
              "attributes": {
                "nom": "Informatique / Télécoms"
              }
            }
          ]
        },
        "formation": {
          "data": {
            "id": "43"
          }
        }
      }
    },
    {
      "id": "32",
      "attributes": {
        "nom": "Responsable comptable et financier",
        "lieu": "Massy",
        "remuneration": 0,
        "publishedAt": "2023-02-13T08:50:13.228Z",
        "createdAt": "2023-02-13T08:50:13.282Z",
        "secteurs": {
          "data": [
            {
              "id": "1",
              "attributes": {
                "nom": "Assurance"
              }
            }
          ]
        },
        "formation": {
          "data": {
            "id": "21"
          }
        }
      }
    }
  ]

  const savePdf = (url, name) => {
    saveAs(
      `${url}`,
      `${name}`
    );
  };
  
  return (
    <HomeStyled mooveBcg={mooveBcg} statutOfPage={statutOfPage}>
      <Header bcgColor='rgba(100,154,206,1)'/>
      <Helmet>
          <title>WEFOR</title>
          <meta name='description' content="Wefor vous propose une multitude de services depuis la formation de vos collaborateurs jusqu'au recrutement de nouveaux talents."/>
          <meta name='keywords' content='Wefor, formation, cabinet de recrutement'/>
      </Helmet>
      <>
      <section className="top-illustration">
        <div className="opacity-light-section notchoose">
          <img src={logo} alt='logo wefor' className="logo-wefor"/>
          <div className="left">
            <h1 className="bold">WEFOR<br></br><span>L'entreprise qui <span className="blue bold">révolutionne</span> le monde de la formation et de l'emploi.</span></h1>
            <Button variant='contained' startIcon={<Download />} onClick={() => savePdf(bannierePdf, 'brochure-formation.pdf')}>Télécharger notre brochure</Button>
            <Link to="/formation" className='display-pc'>
              <img src={bannierDDA} alt='Résultat au test des formations Wefor en 2022.' className='img-bnr'/>
            </Link>
            <div className='display-mobile'>
              <div className="choose-statut">
                  <div className="bold text">Choisissez un statut</div>
                  <FaArrowDown className="bounce-item"/>
              </div>
              <div className='nav-phone-links'>
                <Link to="/formation"
                className="nav-user-link bold"
                onClick={() => {
                  changeCurrentProfilUser("Demandeur d'emploi")
                }}
                >Demandeur d'emploi</Link>
                <Link to="/formation"
                className="nav-user-link bold"
                onClick={() => changeCurrentProfilUser('Salarié')}
                >Salarié</Link>
                <Link to="/formation"
                className="nav-user-link bold" 
                onClick={() => {
                  changeCurrentProfilUser('Entreprise')
                  changeSigninStep(2.2)
                }}
                >Entreprise</Link>
              </div>
            </div>
            
            
            
            {/* 
            <div className="card-header-wrapper">
              <p className="you-are bold">Vous êtes ?</p>
              <ul>
                <li><BsDiamondFill className="color-green"/> Demandeur d'emploi</li>
                <li><BsDiamondFill className="color-orange"/> Salarié</li>
                <li><BsDiamondFill className="color-pink"/> Indépendant ou de profession libérales</li>
                <li><BsDiamondFill className="color-blue"/> Une entreprise</li>
              </ul>
              <p className="txt-par">Nous mettons à votre disposition une mulititude de services liées à la formation et à l'emploi.</p>
            </div>
            */}
          </div>
        </div>
      </section>
     <div className="section-explication">
     <span id="particuliers"></span>
     {/* 
      <ul className="tab-statut-choice">
        <HashLink to='/#particuliers' smooth>
        <li className="part bold hb-fill-left-bg">
          Pour les particuliers
        </li>
        </HashLink>
        <HashLink to='/#entreprises' smooth>
        <li className="entr bold hb-fill-right-bg">
          Pour les entreprises
        </li>
        </HashLink>
      </ul>
      */}
      <div className="logo-top-wrapper">
        <a href="https://certif-icpf.org/comprendre-qualiopi/?utm_source=google&utm_medium=cpc&utm_campaign=campagne_precision_qualiopi&gclid=Cj0KCQjw54iXBhCXARIsADWpsG993foZSvrTeQNPZ3B_Y_Bm_SxNHuOaE46Ubg0GUGumsGW8-ZWDk20aAgCPEALw_wcB" target='_blank' rel='noreferrer'><img src={LogoQualiopi} alt="logo officiel de qualiopi" className='logo-qualiopi'/></a>
        <div>
          <a href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/results?q=%7B%22ou%22:%7B%22modality%22:%22A_DISTANCE%22,%22type%22:%22CP%22,%22ville%22:null%7D,%22sort%22:%22SCORE%22,%22debutPagination%22:1,%22nombreOccurences%22:6,%22quoi%22:null,%22quoiReferentiel%22:%7B%22code%22:%2241036%22,%22libelle%22:%22ASSURANCE%22,%22publics%22:%5B%22GD_PUBLIC%22%5D,%22type%22:%22FORMACODE%22%7D,%22contexteFormation%22:%22ACTIVITE_PROFESSIONNELLE%22,%22durationHours%22:null,%22endDate%22:null,%22evaluation%22:null,%22niveauSortie%22:null,%22nomOrganisme%22:%22WEFOR%22,%22prix%22:null,%22rythme%22:null,%22startDate%22:null,%22conformiteReglementaire%22:null%7D" target='_blank' rel='noreferrer'><img src={cpficon} alt="logo officiel de cpf" className="cpf-logo" /></a>
          <a href="https://www.data-dock.fr/" target='_blank' rel='noreferrer'><img src={LogoDD} alt="logo officiel de datadocke" className="datadock-logo" /></a>
        </div>
      </div>
    </div>
    <div className="bcg-gradient-wrapper">
    <section className="first-section-home-de background-losange section-home-not-choose">
        <div className="right-cards">
          {(statutOfPage === 'part' ? serviceArray : serviceArrayEnt).map((service) => (
            <div className={"white-card "} data-aos="fade-left" key={service.id} onClick={() => setCurrentService(service.id)}>
              <img src={service.image} alt="pictogramme pour les jobs"/>
              <div>
                <h5 className="bold">{service.titre}</h5>
                <p>{service.text}</p>
                {service.link ? <p><a href={service.link} target="_blank" rel="noreferrer">Découvrez les aides additionnelles financières possibles.</a></p> : <></>}
              </div>
            </div>
          ))}
        </div>
        <div className="left left-home-wstatut">
          {/* 
          <img src={serviceToShow()[0].image} alt="pictogramme pour les jobs"/>
          <h3 className="bold">{serviceToShow()[0].titre}</h3>
          <p>{serviceToShow()[0].text}</p>
          <div className="point-wrapper">
          {(statutOfPage === 'part' ? serviceArray : serviceArrayEnt).map((service) => (
            <VscCircleFilled className={(service.id === currentService ? 'current-service' : '')} onClick={() => setCurrentService(service.id)} key={service.id}/>
          ))}
          </div>
          */}
          <h2 className="bold">Pour les <span className="blue-underline bold">particuliers</span></h2>
          <img src={testimg} alt="un test" />
        </div>
    </section>
    
    <section className="info-strong-section">
      <h3>Demandeurs<span className="blue-underline bold"> d'emploi</span></h3>
        <div className="strong-card">
          <p className="bold">Fini le parcours du combattant de la recherche d'emploi !!! <br/>Nous avons qualifié des offres pour vous, il suffit de suivre nos formations.</p>
        </div>
        <span id="entreprises"></span>
    </section>
    </div>
    <section className="first-section-home-de background-losange section-home-not-choose entreprise-section">
        <div className="right-img">
          <img src={testimg2} alt="un test" />
        </div>
        <div className="left left-home-entr">
           <h2 className="bold">Pour les <span className="blue-underline bold">entreprises</span></h2>
           <p className="text-entr bold">Nous vous proposons une multitude de services depuis la formation de vos collaborateurs jusqu'au recrutement de nouveaux talents.</p>
           <ul className="list-entr">
             <li><BsCheckLg /> <span>Une plateforme professionnelle dédiée.</span></li>
             <li><BsCheckLg /> <span>Des formations opérationnelles en lien direct avec vos métiers.</span></li>
             <li><BsCheckLg /> <span>Des candidats ayant suivi une formation WEFOR et donc parfaitement adaptés à vos besoins de recrutement.</span></li>
           </ul>
           <Link to='/signin' className="link-entr bold">Créer votre compte</Link>
        </div>
        <div className="whites-cards-shadow-wrapper">
          <div className="cards-shadow">
            <GiProgression />
            <h3 className="bold">Formez<br/>vos collaborateurs</h3>
            <p>Boostez les compétences de vos collaborateurs et mettez vous en conformité.</p>
            <div className="know-more-link">
            <Link to="/formation">
              <span onClick={() => {
                changeCurrentProfilUser('Entreprise')
                changeSigninStep(2.2)
              }}>En savoir plus</span>
            </Link>
            </div>
          </div>
          <div className="cards-shadow">
            <MdGroupAdd />
            <h3 className="bold">Recrutez <br/>de nouveaux talents</h3>
            <p>Postez vos offres d'emploi gratuitement.</p>
            <p>Découvrez les profils des candidats et suivez leur performance au travers de nos formations.</p>
            <div className="know-more-link"><Link to="/signin" onClick={() => changeSigninStep(2.2)}>En savoir plus</Link></div>
          </div>
          <div className="cards-shadow">
            <BsShieldLockFill />
            <h3 className="bold">Espace professionnel sécurisé et RGPD</h3>
            <p>Pilotez les formations de vos collaborateurs grace à nos indicateurs de performance.</p>
            <p>Visualisez les candidats ayant postulé à vos offres.</p>
            <p>Accédez à notre GED permettant les échanges documentaires et contractuels.</p>
            <div className="know-more-link"><Link to="/signin" onClick={() => changeSigninStep(2.2)}>En savoir plus</Link></div>
          </div>
        </div>
    </section>
    <section className="info-strong-section">
      <h3>Pour vos <span className="blue-underline bold">recrutements</span></h3>
        <div className="strong-card">
          <p className="bold">Tous nos candidats auront suivi une formation courte et opérationnelle WEFOR pour répondre à vos besoins</p>
        </div>
    </section>
    <div className="bcg-gradient-wrapper">
    <section className="course-home-section">
        <div className="domaine-section-title"
        >
          <h2 className="bold">Nos <span className="blue bold">formations</span> les plus demandées</h2>
        </div>
        <div className="card-wrapper">
        {arrayCardsCourse.map((formation) => (
            <CourseCard key={formation.id} courseId={formation.id} {...formation}/>
        ))}
        </div>
        <Swiper
          slidesPerView={Math.round(windowDimensions.width / 450 + 0)}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper slider-mobile"
          watchSlidesProgress={true}
        >
        {arrayCardsCourse.map((formation) => (
          <SwiperSlide key={formation.id}>
            {({ isActive }) => (
              <>
                <CourseCard courseId={formation.id} {...formation} isActive={isActive} test='jobSorted mobile' style={{margin: '0px auto'}}/>
              </>
            )}
          </SwiperSlide>
        ))}
        </Swiper>
        <Link to={`/formation`}>
        <button className="btn-orange-section">Voir toutes nos formations</button>
        </Link>
      </section>
      <section className="job-home-section">
        <div className="domaine-section-title">
          <h2 className="bold"
          >Nos dernières <span className="blue bold">offres d'emploi</span></h2>
        </div>
        <div className="card-wrapper">
        {/*jobs.length === 0 && 
        <>
          <CourseCardLoading typeCard='jobs'/>
          <CourseCardLoading typeCard='jobs'/>
          <CourseCardLoading typeCard='jobs'/>
        </>
        */}
        {arrayCardsJobs.map((job) => (
            <JobCard key={job.id} jobId={job.id} {...job}/>
        ))}
        </div>
        <Swiper
          slidesPerView={Math.round(windowDimensions.width / 450 + 0)}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper slider-mobile"
          watchSlidesProgress={true}
        >
        {arrayCardsJobs.map((job) => (
          <SwiperSlide key={job.id}>
            {({ isActive }) => (
              <>
                <JobCard jobId={job.id} {...job} isActive={isActive} test='jobSorted mobile' style={{margin: '0px auto'}}/>
              </>
            )}
          </SwiperSlide>
        ))}
        </Swiper>
        <Link to={`/job`}>
        <button className="btn-green-section">Toutes nos offres</button>
        </Link>
      </section>
      </div>
      </>
      {/*currentProfilUser === 'Salarié' && 
      <>
      <section className="top-illustration">
        <div className="opacity-light-section padding-top-adjusted-sa">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">
              <div className="bold top-h1">Salarié</div> 
              <div className="bold formplus">Booster vos compétences et votre carrière !</div> 
            </h1>
            <p>Vous visez une promotion, une mobilité interne ou une reconversion ?</p>
          </div>
        </div>
      </section>
      <section 
        className="first-section-home-de background-losange"
      >
        <Parallax translateY={[-5, 5]} className="img-parallax">
          <img src={losange} alt="background losange blanc"  />
        </Parallax>
        <div className="left">
          <p className="one-s">Lâchez le frein des compétences</p>
          <p className="two-s">et donnez un coup d’accélérateur</p>
          <p className="bold three-s"><span className="wefor-txt bold">à votre carrière</span> avec Wefor</p>
          <div className="separator-blue-s"></div>
        </div>
        <div className="right-cards">
          <div className="white-card" data-aos="fade-left">
            <img src={cpficon} alt="icon" className="img-left" />
            <p><span className="bold"></span>Formations <span className="bold">reconnues</span> et éligibles au <span className="bold">CPF</span></p>
          </div>
          <div className="white-card" data-aos="fade-right">
            <img src={onlineCourse} alt="icon" className="img-left" />
            <p>Formations <span className="bold">100% en ligne !</span></p>
          </div>
          <div className="white-card" data-aos="fade-left">
            <img src={jobOffer} alt="icon" className="img-left" />
            <p><span className="bold"></span>Offres d'emplois <span className="bold">directement accessibles</span></p>
          </div>
          <div className="white-card" data-aos="fade-right">
            <img src={qualiopiLogo} alt="icon" className="img-left" />
            <p><span className="bold"></span>Organisme certifié <span className="bold uppercase">Qualiopi</span></p>
          </div>
      </div>
      </section>
      <section className="course-home-section bcg-orange">
        <div className="domaine-section-title"
        >
          <h2>Montez en compétence avec nos formations</h2>
          <div className="separator-blue"></div>
        </div>
        <div className="card-wrapper">
        {!loadFormation && formations.slice(0, 3).map((formation) => (
            <CourseCard key={formation.id} courseId={formation.id} {...formation}/>
        ))}
        </div>
        <Link to={`/formation`}>
        <button className="btn-blue" data-aos="zoom-out-up">Voir toutes nos formations</button>
        </Link>
      </section>
      <section className="job-home-section">
        <div className="domaine-section-title">
          <h2
          >Nos dernières offres d'emploi</h2>
          <div className="separator-blue"></div>
        </div>
        <div className="card-wrapper">
        {!loadJobs && jobs.slice(0, 3).map((job) => (
            <JobCard key={job.id} jobId={job.id} {...job}/>
        ))}
        </div>
        <Link to={`/job`}>
        <button className="btn-blue" data-aos="zoom-out-up">Toutes nos offres</button>
        </Link>
      </section>
      </>
      */}
      {/*currentProfilUser === 'Entreprise' && 
      <>
      <section className="top-illustration">
        <div className="opacity-light-section padding-top-adjusted-en">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">
              <div className="bold top-h1">Entreprise</div> 
              <div className="bold formplus">Recrutez de nouveaux talents et formez vos collaborateurs !</div> 
            </h1>
            <p>Un profil pile poil adapté aux besoins de votre entreprise ? Des formations courtes mais efficaces pour booster les compétences de vos collaborateurs ? </p>
          </div>
        </div>
      </section>
      <section 
        className="first-section-home-en background-losange"
      >
        <Parallax translateY={[-5, 5]} className="img-parallax">
          <img src={losange} alt="background losange blanc"  />
        </Parallax>
        <div className="left">
          <p className="one">Wefor vous apporte</p>
          <p className="two">des solutions </p>
          <p className="bold three"><span className="wefor-txt bold">clés en main !</span></p>
          <div className="separator-blue"></div>
        </div>
        <div className="right-cards">
          <div className="white-card" data-aos="fade-right">
            <img src={cpficon} alt="icon" className="img-left" />
            <p><span className="bold"></span>Formations <span className="bold">reconnues</span> et éligibles au <span className="bold">CPF</span></p>
          </div>
          <div className="white-card" data-aos="fade-left">
            <img src={onlineCourse} alt="icon" className="img-left" />
            <p>Formations <span className="bold">100% en ligne !</span></p>
          </div>
          <div className="white-card" data-aos="fade-right">
            <img src={jobOffer} alt="icon" className="img-left" />
            <p><span className="bold"></span>Offres d'emplois <span className="bold">directement accessibles</span></p>
          </div>
          <div className="white-card" data-aos="fade-left">
            <img src={qualiopiLogo} alt="icon" className="img-left" />
            <p><span className="bold"></span>Organisme certifié <span className="bold uppercase">Qualiopi</span></p>
          </div>
      </div>
      </section>
      </>
      */}
      {/*currentProfilUser === 'Autres' && 
       <>
       <section className="top-illustration">
         <div className="opacity-light-section padding-top-adjusted">
         <img src={logo} alt='logo wefor'/>
           <div className="left-a">
             <h1 className="bold">
               <div className="bold top-h1">Indépendant, fonctionnaire ou sans activité professionnelle</div> 
               <div className="bold formplus">Une formation + un nouvel emploi, ça vous tente ?</div> 
             </h1>
             <p>Vous avez un projet de reconversion ou d’évolution professionnelle ?</p>
           </div>
         </div>
       </section>
       <section 
        className="first-section-home-de background-losange"
      >
        <Parallax translateY={[-5, 5]} className="img-parallax-a">
          <img src={losange} alt="background losange blanc"  />
        </Parallax>
        <div className="left">
          <p className="one-a">Formez-vous rapidement</p>
          <p className="two-a"> pour briguer enfin</p>
          <p className="bold three-a"><span className="wefor-txt bold">le job de</span> vos rêves !</p>
          <div className="separator-blue-a"></div>
        </div>
        <div className="right-cards">
          <div className="white-card" data-aos="fade-right">
            <img src={cpficon} alt="icon" className="img-left" />
            <p><span className="bold"></span>Formations <span className="bold">reconnues</span> et éligibles au <span className="bold">CPF</span></p>
          </div>
          <div className="white-card" data-aos="fade-left">
            <img src={onlineCourse} alt="icon" className="img-left" />
            <p>Formations <span className="bold">100% en ligne !</span></p>
          </div>
          <div className="white-card" data-aos="fade-right">
            <img src={jobOffer} alt="icon" className="img-left" />
            <p><span className="bold"></span>Offres d'emplois <span className="bold">directement accessibles</span></p>
          </div>
          <div className="white-card" data-aos="fade-left">
            <img src={qualiopiLogo} alt="icon" className="img-left" />
            <p><span className="bold"></span>Organisme certifié <span className="bold uppercase">Qualiopi</span></p>
          </div>
      </div>
      </section>
      </>
      */}
      {/*currentProfilUser === 'Demandeur d\'emploi' && 
      <>
        <section className="top-illustration">
          <div className="opacity-light-section padding-top-adjusted-de">
          <img src={logo} alt='logo wefor'/>
            <div className="left">
              <h1 className="bold">
                <div className="bold top-h1">Demandeurs d’emploi</div> 
                <div className="bold formplus">une formation + un emploi</div> 
                <div className="bold uppercase toutca">TOUT ça !</div>
              </h1>
              <p>Avec Wefor, formez-vous rapidement pour briguer enfin le job de vos rêves !</p>
            </div>
          </div>
        </section>
        <section 
          className="first-section-home-de background-losange"
        >
        <Parallax translateY={[-5, 5]} className="img-parallax">
          <img src={losange} alt="background losange blanc"  />
        </Parallax>
        <div className="left">
          <p className="one">Développer des compétences</p>
          <p className="two">adaptées au marché et décrocher</p>
          <p className="bold three"><span className="wefor-txt bold">un emploi,</span> ça vous tente ? </p>
          <div className="separator-blue"></div>
        </div>
        <div className="right-cards">
          <div className="white-card" data-aos="fade-right">
            <img src={cpficon} alt="icon" className="img-left" />
            <p><span className="bold"></span>Formations <span className="bold">reconnues</span> et éligibles au <span className="bold">CPF</span></p>
          </div>
          <div className="white-card" data-aos="fade-left">
            <img src={onlineCourse} alt="icon" className="img-left" />
            <p>Formations <span className="bold">100% en ligne !</span></p>
          </div>
          <div className="white-card" data-aos="fade-right">
            <img src={jobOffer} alt="icon" className="img-left" />
            <p><span className="bold"></span>Offres d'emplois <span className="bold">directement accessibles</span></p>
          </div>
          <div className="white-card" data-aos="fade-left">
            <img src={qualiopiLogo} alt="icon" className="img-left" />
            <p><span className="bold"></span>Organisme certifié <span className="bold uppercase">Qualiopi</span></p>
          </div>
      </div>
      </section>
      </>
      */}
      {/*
      <motion.section 
        className="first-section-home"
      >
        <div className="left">
          <p>Grace à<span className="wefor-txt bold"> WEFOR</span></p>
          <p>soyez la<span className="meilleure-txt bold"> meilleure</span></p>
          <p className="bold">version de vous même</p>
          <div className="separator-blue"></div>
        </div>
        <div className="right">
          <div className="picto-left">
            <motion.img 
            src={seoTraining} 
            className="seo-picto" 
            alt="picto seo" 
            initial={{ opacity: 0, translateY: "-100px"}}
            whileInView={{ opacity: 1, translateY: "0px"}}
            transition={{ duration: .7, delay: 0 }}
            viewport={{ once: true }}
            />
            <p><span className="bold">80%</span> de nos apprenants ont trouvé un travail</p>
          </div>
          <div className="picto-right">
            <div className="picto-top">
              <motion.img 
                src={clientConsultant} 
                className="client-picto" 
                alt="client consultant picto" 
                initial={{ opacity: 0, translateX: "100px"}}
                whileInView={{ opacity: 1, translateX: "0px"}}
                transition={{ duration: 0.7, delay: 0.5 }}
                viewport={{ once: true }}
              />
              <p><span className="bold">300</span> courtiers clients</p>
            </div>
            <div className="picto-bottom">
              <div>
                <motion.img 
                src={setupCompain} className="compain-picto" 
                alt="setup picto" 
                initial={{ opacity: 0, translateY: "100px"}}
                whileInView={{ opacity: 1, translateY: "0px"}}
                transition={{ duration: 0.7, delay: 1}}
                viewport={{ once: true }}
                />
                <p><span className="bold">125</span> instructeurs pour vous accompagner dans vos projets</p>
              </div>
              <div>
                <motion.img 
                src={strategyVictory} 
                className="victory-picto" 
                alt="victory picto" 
                initial={{ opacity: 0, translateX: "100px"}}
                whileInView={{ opacity: 1, translateX: "0px"}}
                transition={{ duration: 0.7, delay: 1.5 }}
                viewport={{ once: true }}
                />
                <p><span className="bold">95%</span> de complétion</p>
              </div>
            </div>
          </div>
        </div>
      </motion.section> */}
      {/* 
      <section className="domaine-section">
        <div className="domaine-section-title">
          <h2
          >Nos trois domaines de formations</h2>
          <div className="separator-blue"></div>
        </div>
        <div className="bottom-domaine-wrapper">
          <div className="domaine-wrapper">
            <div className="opacity-black">
              <div className="domaine-title">
                <h3 className="domaine-title bold">Souscription</h3>
                <div className="separator-white"></div>
              </div>
              <p className="domaine-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla posuere lobortis enim, a ultrices orci. Praesent placerat est vitae lobortis blandit.</p>
            </div>
          </div>
          <div className="domaine-wrapper">
            <div className="opacity-black">
              <div className="domaine-title">
                <h3 className="domaine-title bold">Gestion contrat</h3>
                <div className="separator-white"></div>
              </div>
              <p className="domaine-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla posuere lobortis enim, a ultrices orci. Praesent placerat est vitae lobortis blandit.</p>
            </div>
          </div>
          <div className="domaine-wrapper">
          <div className="opacity-black">
            <div className="domaine-title">
              <h3 className="domaine-title bold">Sinistres</h3>
              <div className="separator-white"></div>
            </div>
            <p className="domaine-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla posuere lobortis enim, a ultrices orci. Praesent placerat est vitae lobortis blandit.</p>
          </div> 
        </div>
      </div>
      <Link to={`/contact`}>
      <div className="btn-blue" data-aos="zoom-out-up">Demande d'informations</div>
      </Link>
      </section>
      */}
      <Footer />
    </HomeStyled>
  )
}

Home.propTypes = {
  changeCurrentDomaineClicked: PropTypes.func.isRequired,
}

export default Home