import styled from 'styled-components';

const ModalSignatureStyled = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    

    .modal-content-container {
        width: 92%;
        max-width: 1000px;
        min-height: 300px;
        max-height: 90vh;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 5px 15px 10px #00000066;
        
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        

        .scroll-zone {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            padding: 20px 0 0 0;
            
            .page-width {
                width: 200px;
            }
        }

        .icon-wrapper {
            position: fixed;
            top: calc(5vh - 35px);
            right: 4%;
            width: 40px;
            height: 40px;
            background-color: #649ACE;
            border-radius: 17.5px;
            overflow: hidden;
            display:flex;
            align-items: center;
            justify-content: center;

            .close-icon {
                color: #fff;
                font-size: 30px;
                cursor: pointer;
                border-radius: 20px;
            }
        }

        .page-wrapper-message {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            height:100%;
            min-height: 300px;
            position: relative;
            

            h3 {
                font-size: 20px;
                margin: 20px;
            }

            .pdf-wrapper {

                box-shadow: 0px 3px 10px #00000069;
                padding: 15px;
                margin-bottom:20px;

                .page-wrapper-txt {
                    position: relative;
                }

                .first-txt {
                    position: absolute;
                    top: 140px;
                    left: 72px;
                    font-size: 10px;
                    max-width: 440px;
                }

                .second-txt {
                    position: absolute;
                    top: 185px;
                    left: 72px;
                    font-size: 10px;
                }

                .third-txt {
                    position: absolute;
                    top: 225px;
                    left: 72px;
                    font-size: 10px;
                }

                .fourth-txt {
                    position: absolute;
                    top: 278px;
                    left: 149px;
                    font-size: 10px;
                }

                .react-pdf__Document {
                    
                    padding: 15px;
                }

                .page-controller {
                    width: 100%;
                    display:flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        margin: 0 10px;
                    }
                }
                
            }

            .textfield-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                .MuiTextField-root {
                    margin: 10px;
                    width: 50%;
                }
            }

            .btn-action-wrapper {
                display: flex;
                justify-content: center;
                align-items: stretch;
                margin: 20px 0;

                .btn-redo {
                    background-color: #D47558;
                    width: 145px;
                    margin-right: 10px;
                    color: #fff;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    transition: all .3s ease;
                    padding: 10px;
                    box-sizing: border-box;
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }

                .btn-send {
                    background-color: #6AAF8B;
                    width: 145px;
                    color: #fff;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    border-radius: 5px;
                    transition: all .3s ease;
                    padding: 10px;
                    box-sizing: border-box;
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }
            }

            .sigCanvas {
                border: 1px solid #000;
                margin: 0 auto;
                cursor: crosshair
            }
        }
    }

    @media only screen and (max-width: 1079px) {
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .modal-content-container .page-wrapper-message .pdf-wrapper {
            padding: 0 0 10px 0;
        }

        .modal-content-container .page-wrapper-message .pdf-wrapper .react-pdf__Document {
            padding: 0 0 10px 0;

            .react-pdf__Page__canvas {
                width: 100% !important;
                height: auto !important;
            }
        }
    }
`;

export default ModalSignatureStyled;