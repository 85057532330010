/**** Import des modules ****/
import React, {useEffect, useState} from 'react'

import urlBackEndSave from '../../assets/js/urlBackEndSave'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import ExcelFacture from '../../containers/ExcelFacture'

/**** Import des images ****/

/**** Import des css ****/
import AccountAdminFactureStyled from './AccountAdminFactureStyled'

const AccountAdminFacture = ({
  fetchGetFactureClient
}) => {

  useEffect(() => {
    fetchGetFactureClient()
  }, []);
  
  return (
    <AccountAdminFactureStyled>
      <Header bcgColor={'#fff'}/>
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
        <ExcelFacture />
      </section>
      </main>
    </AccountAdminFactureStyled>
  )
}

AccountAdminFacture.propTypes = {
}

export default AccountAdminFacture