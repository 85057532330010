import { connect } from 'react-redux'

import { fetchFactureE, fetchFactureP, changeClickedFacture, fetchPutModeFinancement, changeStepValue, fetchUploadEdofFile } from '../../actions/course'
import { toggleModalSignature } from '../../actions/modal'

import ContratCourse from '../../components/ContratCourse'

const mapStateToProps = (state) => ({
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    factureE: state.course.factureE,
    factureP: state.course.factureP,
    userStatut: (state.login.isLogged ? state.login.userInfo.data[0].attributes.statut : ''),
    clickedFacture: state.course.clickedFacture,
    facturePStatut: state.course.facturePStatut,
    factureEStatut: state.course.factureEStatut,
    facturePWithDocs: state.course.facturePWithDocs
})

const mapDispatchToProps = (dispatch) => ({
    fetchFactureE: () => {
        dispatch(fetchFactureE());
    },
    fetchFactureP: () => {
        dispatch(fetchFactureP());
    },
    changeClickedFacture: (factureId) => {
        dispatch(changeClickedFacture(factureId));
    },
    fetchPutModeFinancement: (factureId, statut) => {
        dispatch(fetchPutModeFinancement(factureId, statut))
    },
    changeStepValue: (number) => {
        dispatch(changeStepValue(number));
    },
    fetchUploadEdofFile: (file, id, mode) => {
        dispatch(fetchUploadEdofFile(file, id, mode));
    },
    toggleModalSignature: (bool, docId, docType, docObject) => {
        dispatch(toggleModalSignature(bool, docId, docType, docObject));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ContratCourse)