/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { MdDownloadForOffline } from "react-icons/md"
import { RiBallPenFill } from "react-icons/ri";
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios'
import * as qs from 'qs'

import urlBackEndSave from '../../../assets/js/urlBackEndSave'
import urlBackEnd from '../../../assets/js/urlBackEnd'
const URL = urlBackEnd

/**** import des components ****/


/**** Import d'images' ****/


/**** Import des css ****/


const FactureCourseU = ({ userRole, facture, factureEStatut, facturePStatut }) => {

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  
  return (
    <>
    {facture.attributes.facture.data !== null && (userRole === 'entreprise' ? factureEStatut : facture !== false) &&
    <div className="facture-comp-wrapper" key={facture.id}>
      <div className="info-wrapper">
        <div className="top bold">
          n°{facture.id}
        </div>
        <div>
          {moment(facture.attributes.createdAt).format("DD/MM/YYYY")}
        </div>
      </div>
      <div className="info-wrapper">
        <div className="top bold">
          TTC: {facture.attributes.Prix} €
        </div>
        <div>
          {facture.attributes.nom_formation}
        </div>
      </div>
      <div className="info-wrapper dl">
        <div className="btn-wrapper">
          {facture.attributes.facture.data !== null ?
          <button 
            className="dl-btn"
            onClick={() => savePdf(facture.attributes.facture.data.attributes.url, facture.attributes.facture.data.attributes.name )}
          >
            Télécharger
          </button>  
          :
          <button className="dn-btn">Télécharger</button>}
        </div>
      </div>
      <div className={facture.attributes.step < 3 ? "statut-wrapper orange" : "statut-wrapper green"}>
        <div>
          {facture.attributes.step < 3 ? 
          <div>En cours</div>
          :
          <div>Payé</div>
        }
        </div>
      </div>
    </div>
    }
    </>
  )
}

FactureCourseU.propTypes = {
}

export default FactureCourseU