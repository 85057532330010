import styled from 'styled-components';

const PreInscriptionStyled = styled.main`

    h1 {
        text-align: center;
        font-size: 25px;
        padding: 10px 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 15641651168465;
    }
    .absolute-wrapper {
        display: none;
    }

    .illustration-header {
        margin: 0 auto;
        padding-top: 0px;
        z-index: 10;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .MuiTextField-root {
            width: 100%;
            padding-bottom: 20px;

            input {
                background-color: rgba(255, 255, 255, .2); 
            }
        }

        .btn-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        .button {
            margin-bottom: 0px;
            width: 200px;
            padding: 10px;
            border-radius: 5px;

            &:hover {
                color: #fff;
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        
        .illustration-header {
        }
    }
        
    @media only screen and (max-width: 1079px) {
        .illustration-header {
        }
    }


    @media only screen and (min-width: 0px) and (max-width: 759px) {
        padding: 80px 10px 0 ;
    }
`;

export default PreInscriptionStyled;