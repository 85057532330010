import { connect } from 'react-redux'

import App from '../../components/App'
import { setWindowTop, setLastPagePathname } from '../../actions/actions'
import { fetchSecteurs, fetchDomaines, setIsShowMenu, setIsShowAccountFormMenu, setCurrentUrl } from '../../actions/menu'
import { fetchFormations, changeActivCourseId, fetchGetOneProgram, setFullSlugCourse, saveSlugs } from '../../actions/course'
import { fetchJobs, fetchJobFiches, changeActivJobId } from '../../actions/job'
import { fetchGetCoursesLms } from '../../actions/lms'
import { changeSigninStep } from '../../actions/signin'

const mapStateToProps = (state) => ({
    isHeLogged: state.login.isLogged,
    viewModal: state.modal.viewModal,
    isShowMenu: state.menu.isShowMenu,
    isShowAccountFormMenu: state.menu.isShowAccountFormMenu,
    jobsList: state.job.jobs,
    jobsFiches: state.job.jobsFiches,
    courseList: state.course.formations,
    modalInscLoaderOpen: state.course.modalInscLoaderOpen,
    fullPageLoader: state.actions.fullPageLoader,
    modalMessageIsOpen: state.modal.modalMessageIsOpen,
    fullPageLoaderConnexion: state.actions.fullPageLoaderConnexion,
    fullPageLoaderJobInsc: state.job.fullPageLoaderJobInsc,
    fullPageLoaderContact: state.actions.fullPageLoaderContact,
    fullPageLoaderOcean: state.ocean.fullPageLoaderOcean,
    fullPageLoaderPutAccount: state.login.fullPageLoaderPutAccount,
    fullPageLoaderJobOfferAction: state.job.fullPageLoaderJobOfferAction,
    fullPageLoaderFacturation: state.actions.fullPageLoaderFacturation,
    fullPageLoaderRecrutementObj: state.login.fullPageLoaderRecrutementObj,
    fullPageLoaderNews: state.news.fullPageLoaderNews,
    loadConnexion: state.login.loadConnexion,
    isModalPostulerTutoOpen: state.job.isModalPostulerTutoOpen,
    secteursData: state.menu.secteursData,
    domainesData: state.menu.domainesData,
    maintenance: state.maintenance.maintenance,
    lastPagePathname: state.actions.lastPagePathname,
    userRole: state.login.userRole,
    slugSecteur: state.course.slugSecteur,
    slugKeyword: state.course.slugKeyword,
    slugFormation: state.course.slugFormation,
    redirect: state.login.redirect,
    currentProfilUser: state.menu.currentProfilUser
})



const mapDispatchToProps = (dispatch) => ({
    setWindowTop: (bool) => {
        dispatch(setWindowTop(bool));
    },
    fetchSecteurs: () => {
        dispatch(fetchSecteurs());
    },
    fetchFormations: () => {
        dispatch(fetchFormations());
    },
    fetchJobs: () => {
        dispatch(fetchJobs());
    },
    fetchDomaines: () => {
        dispatch(fetchDomaines());
    },
    fetchJobFiches: () => {
        dispatch(fetchJobFiches());
    },
    setIsShowMenu: (bool) => {
        dispatch(setIsShowMenu(bool));
    },
    setIsShowAccountFormMenu: (bool) => {
        dispatch(setIsShowAccountFormMenu(bool));
    },
    changeActivJobId: (id) => {
        dispatch(changeActivJobId(id));
    },
    changeActivCourseId: (id) => {
        dispatch(changeActivCourseId(id));
    },
    fetchGetOneProgram: () => {
        dispatch(fetchGetOneProgram());
    },
    fetchGetCoursesLms: () => {
        dispatch(fetchGetCoursesLms());
    },
    changeSigninStep: (number) => {
        dispatch(changeSigninStep(number));
    },
    setLastPagePathname: (value) => {
        dispatch(setLastPagePathname(value))
    },
    setCurrentUrl: (string) => {
        dispatch(setCurrentUrl(string))
    },
    setFullSlugCourse: (string) => {
        dispatch(setFullSlugCourse(string))
    },
    saveSlugs: (name, slug) => {
        dispatch(saveSlugs(name, slug))
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(App)