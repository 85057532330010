import styled from 'styled-components';

const FormationsStyled = styled.section`
    padding-top: 0px;

    .top-illustration {
        width: 100vw;
        height: 700px;
        padding-top: 125px;
        z-index: 10;
        position: relative;

        &::before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width:100%;
            background: linear-gradient(180deg, #f7edea 0%, #d47558 100%);
            clip-path: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            shape-outside: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            z-index: -10;
        }


        .opacity-light-section {
            height: 100%;
            top: 100%;
            display: flex;
            flex-wrap: wrap;
            
            justify-content: space-between;
            padding: 0px 0;
            max-width: 1300px;
            margin: 0px auto;

            .left {
                width: calc(50%);
                margin-left: 0%;
                margin-top: 100px;

                h1 {
                    font-size: 50px;
                    color: #4d4d4d;
                    text-align: left;
                }

                p {
                    font-size: 25px;
                    color: #fff;
                    line-height: 30px;
                    padding: 15px 0;
                    text-align: justify;
                }
            }
            img {
            box-sizing: border-box;
                width: 50%;
                max-width: 600px;
                box-sizing: border-box;
                margin-top: 30px;
                height: fit-content;
            }
        }
    }

    .skeleton-page {
        .filter {
            width: 96%;
            max-width: 900px;
            height: 100px;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
        .carre {
            width: 96%;
            max-width: 1300px;
            margin: 0 auto 0px;
            padding-left: 70px;
            display: flex;
            flex-wrap: wrap;
        }
        .page {
            display: flex;
            flex-wrap: wrap;
            width: 96%;
            max-width: 1300px;
            margin: 0 auto 50px;

            .left {
                width: 47%;

                .cards {
                    width: 98%;
                    height: 116px;
                }
            }
            .right {
                width: 53%;
                position: relative;

                .test {
                    width: 80%;
                    height: 45px;
                    top: 10px;
                    left: 5%;
                    position: absolute;
                    border-radius: 27px;
                    margin: 0px 5%;
                }

                .content {
                    width: 90%;
                    border-radius: 43px;
                    margin: 50px auto 50px auto;
                    height: 785px;
                }
            }
        }
    }

    .filter-formation {
        width: 100%;
        

        .wrapper-filter {
            max-width: 900px;
            padding: 30px 0;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;

            .Mui-focused .MuiInputLabel-outlined {
                color: #d47558;
                font-weight: bold;
                z-index: 10;
            }

            .MuiInputLabel-root, .MuiInputBase-input {
                top: -7px;
                color: #d47558 !important;
                font-weight: bold;
                padding-top: 0;
            }

            .MuiInputBase-root {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                height: 40px;
                font-weight: bold;

                &.MuiOutlinedInput-notchedOutline {
                    border-color: #d47558;
                }

                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #d47558;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: #d47558;
                    color: #d47558 !important;
                    font-weight: bold;
                }

                &.Mui-focused .MuiOutlinedInput-notchedOutline {
                    border-color: #d47558;
                }
            }
            
            .btn-dda {
                width: 100px;
                background-color: #d47558;
                padding: 10px 0px;
                border: 1px solid #D47558;
                border-radius: 8px;
                transition: all .2s ease;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 0;

                &:hover {
                    opacity: .7;
                }
            }

            .btn-filter {
                width: 250px;
                background-color: #d47558;
                text-align: center;
                padding: 10px 0px;
                border: 1px solid #D47558;
                border-radius: 8px;
                color: #fff;
                position: relative;
                cursor: pointer;
                z-index: 10;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;


                .filter {
                    color: #fff;
                    text-transform: capitalize;
                }

                &.border-r-off {
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    border-bottom: 0;
                }

                ul {
                    display: none;
                }

                .show-menu {
                    display: block;
                    z-index: 50;
                    position: absolute;
                    top: 37px;
                    left: -1px;
                    width: 250px;
                    background-color: #D47558;
                    text-align: center;
                    padding: 0px 0px;
                    border: 1px solid #D47558;
                    border-radius: 8px;
                    border-top: 0;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;

                    li {
                        padding: 10px 0;

                        &:hover {
                            background-color: #fff;
                            color: #D47558;
                        }

                        &:last-child {
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                    }
                }

                svg {
                    margin-bottom: -2px;
                }
            }
        }
    }

    .user-filter-section {
        width: 96%;
        max-width: 1300px;
        margin: 0 auto 0px;
        padding-left: 70px;
        display: flex;
        flex-wrap: wrap;

        .layout-btn {
            font-size: 25px;
            margin-right: 10px;
            color: #e09e8a;
            cursor: pointer;

            &.active-layout {
                color: #a95d46;
            }
        }

        .filter {
            color: #D47558;
            display: flex;
            align-items: center;
            margin-left: 20px;

            svg {
                color: #dc4a46;
                cursor: pointer;
            }
        }
    }

    .course-main {
        max-width: 1300px;
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 auto;

        .no-result {
            text-align: center;
            padding: 20px 0;
            width: 100%;
        }

        .course-list-left {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: stretch;
            width: 47%;
            padding-bottom: 50px;

            &.column {
                flex-direction: column;
                justify-content: start;
            }

            .fullwidth {
                width: 98%;
            }

            &.mobile {
                display: none;
            }

            .pagination-pc {
                width: 100%;
                margin-top: 30px;

                .MuiPagination-ul {
                    justify-content: center;
                }
            }

            .pagination {
                width: 100%;
                display:flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: end;
                align-items: center;
                margin: 50px 8% 0 0;

                span {
                    margin-right: 30px;
                    padding: 10px;
                    color: #656464;
                    cursor: pointer;
                }

                svg {
                    color: #656464;
                    cursor: pointer;
                }

                .active {
                    box-sizing: border-box;
                    background-color: #649ACE;
                    color: #fff;
                }
            }
        }
        .course-info-right {
            width: 53%;
            padding: 50px 0 50px 50px;
            margin-bottom: 260px;
            
            box-sizing: border-box;
            text-align: center;

            .info-wrapper {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #D47558;
                border-radius: 43px;
                width: 100%;
                margin: 0 auto;
                padding: 30px 0px;
                position: relative;

                h4 {
                    padding-bottom: 10px;
                }

                .card-orange {
                    width: 90%;
                    box-shadow: 0px 3px 6px #00000029;
                    background-color: #fff;
                    border: 1px solid #D47558;
                    border-radius: 27px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 10px;
                    top: -40px;
                    left: 5%;
                    height: auto;
                    position: absolute;
                    
                    h3 {
                        text-align: center;
                        color: #4d4d4d;
                        font-size: 20px;
                    }


                    .row {
                        width: 100px;
                        color: #D47558;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        img {
                            width: 40px;
                        }

                        &:nth-child(2) {
                            width: calc(99% - 100px);
                            border-left: 1px solid #D47558; 
                            
                            box-sizing: border-box;
                        }

                        h6 {
                            color: #707070;
                            text-align: center;
                            padding-top: 5px;
                        }

                        &:nth-child(3) {
                            width: 65%;
                        }
                    }
                }

                .btn-orange-fullwidth {
                    width: 100%;
                    text-align: center;
                    padding: 15px 0;
                    font-size: 20px;
                    color: #fff;
                    background-color: #D47558;
                    border: 0;
                    cursor: pointer;
                }

                .comp-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: start;
                    align-items: center;
                    margin: 20px auto;
                    width: 95%;
                    border-bottom: 1px solid #D47558;
                    padding-bottom: 20px;
                    text-align: left;

                    &:nth-child(2) {
                        margin-top: 50px;
                    }

                    img {
                        width: 100px;
                    }

                    .txt-wrapper {
                        width: calc(100% - 130px);
                        margin-left: 30px;
                        color: #707070;

                        h3 {
                            text-transform: uppercase;
                            font-size: 17px;
                            padding-bottom: 10px;
                        }

                        p {
                            line-height: 24px;
                        }

                        li {
                            font-size: 15px;
                            list-style: inside;
                            line-height: 20px;
                        }
                    }
                }

                .price-time-wrapper {
                    width: 95%;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: stretch;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    .time-wrapper, .price-wrapper {
                        width: 48%;
                    }

                    .time-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-between;

                        img {
                            width: 100px;
                            height: 100px;
                        }

                        .paragraphe-wrapper {
                            width: calc(100% - 100px);

                            .txt {
                                color: #707070;
                                text-transform: uppercase;
                                font-size: 17px;
                            }

                            .time {
                                color: #D47558;
                                font-size: 22px;
                                padding-top: 5px;
                            }
                        }
                    }

                    .separator-orange {
                        width: 1px;
                        background-color: #D47558;
                    }

                    .price-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;

                        a {
                            position: relative;
                            color: #D47558;
                            font-size: 18px;

                            .link-arrow {
                                position: absolute;
                                top: 20px; 
                                left: -100px;
                                display: flex;
                                align-items: center;

                                svg {
                                    font-size: 25px;
                                    margin: 0 0 0 5px;
                                }
                            }
                        }

                        .red {
                            color: #dc4a46;
                        }

                        .price-dotted-cont {
                            position: relative;
                        }

                        .prix-dotted {
                            text-decoration: line-through;
                        }

                        img {
                            width: 100px;
                            height: 100px;
                            transition: all .3s ease;

                            &:hover {
                                transform: scale(1.05)
                            } 
                        }

                        p {
                            width: calc(100%);
                            font-size: 35px;
                            color: #4d4d4d;

                            .net {
                                font-size: 20px;
                            }

                            .prix-dotted {
                                position: absolute;
                                top: -20px;
                                left: 0;
                                color: #6399ce;
                                font-size: 22px;
                            }
                        }

                        .disclaimer {
                            font-size: 12px;
                            font-style: italic;
                            padding: 0;
                            display: inline-block;
                        }
                    }
                }

                .module-title {
                    margin-top: 40px;
                    color: #D47558;
                    line-height: 25px;
                    font-size: 20px;
                    padding-left: 20px;
                    text-align: left;
                }

                .modules-wrapper {
                    padding: 0 20px;
                    box-sizing: border-box;
                    text-align: justify;

                    li {
                        list-style: none !important;

                        &:before {
                            content:"·";
                            font-size: 50px;
                            margin-right: 5px;
                            vertical-align:middle;
                            line-height: 25px;
                        }

                    strong {
                        font-weight: bold;
                    }

                    p {
                        padding-top: 10px;
                    }
                }

                .points {
                    color: #707070;
                    font-size: 40px;
                }

                ul {
                    li {
                        color: #707070;
                        line-height: 25px;
                    }
                }

                .full-width {
                    width: 98%;
                    text-align: center;

                    .btn-blue-orange {
                        width: 500px;
                        margin: 30px auto 10px;
                        padding: 10px;
                        box-sizing: border-box;
                        color: #4d4d4d;
                        font-size: 20px;
                        border: 2px solid #D47558;
                        border-radius: 27px;
                        background-color: #F7EDEA;
                        cursor: pointer;
                        display: none;
                    }
                }

                .shield-wrapper {
                    height: 120px;
                    width: 120px;
                    border-radius: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    background-color: #fff;
                    top: -25px;
                    left: -25px;
                    box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
                    -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
                    -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
            
                    &.orange {
                        background-color: #d47558;
                    }
            
                    img {
                        width: 60px;
                    }
                }
            }

            .programme-disclaimer {
                text-align: justify;
                margin: 30px 20px 0;

                h4 {
                    font-size: 20px;
                }



                .def-markdown {
                    li {
                        color: #707070;
                        margin: 0 0 5px 0px;
                        list-style: auto;
                        line-height: 16px;
                        list-style-position: inside;
                    }

                    h5 {
                        margin: 20px 0 10px;
                        font-size: 18px;
                        font-weight: bold;
                    }

                    h6 {
                        margin: 20px 0 10px;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }

            .button-wrapper {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: stretch;
                margin-top: 50px;

                .btn-bluelight {
                    margin-left: 10px;
                    height: 100%;
                    width: 130px;
                    padding: 10px 0;
                    background-color: #fff;
                    border: 1px solid #6399ce;
                    border-radius: 10px;
                    color: #6399ce;
                    cursor: pointer;
                    display: none;
                }

                .btn-orange {
                    margin-right: 10px;
                    width: 130px;
                    padding: 10px 0;
                    background-color: #D47558;
                    border-radius: 10px;
                    border: 0;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }}

    @media only screen and (max-width: 1400px) {
        .top-illustration {
    
            .opacity-light-section {

                .left {
                    margin-left: 2%;
                    margin-right: 2%;
                    p {
                        font-size: 20px;
                        color: #fff;
                        line-height: 30px;
                        padding: 15px 0;
                        text-align: justify;
                    }

                    h1 {
                        font-size: 45px;
                    }
                }

                img {
                    width: 40%;
                }
            }
        }

        .course-main {
    
            .course-list-left {
                align-items: stretch;
            }

            .course-info-right {
                padding: 50px 20px 50px 40px;
    
                .info-wrapper {

                    img {
                        width: 80px !important;
                        height: 80px !important;
                    }

                    .comp-wrapper {
                        p {
                            font-size: 15px !important;
                            line-height: 20px !important;
                        }
                    }
    
                    .price-wrapper {
                        p{
                            font-size: 30px;
                        }

                        .disclaimer {
                            font-size: 12px;
                            font-style: italic;
                        }
                    }
                    .card-orange {
    
                        h3 {
                            font-size: 18px;
                        }

                        h5 {
                            font-size: 16px !important;
                        }

                        .row {
    
                            &:nth-child(2) {
                                width: calc(99% - 100px);
                                border-left: 1px solid #D47558; 
                                box-sizing: border-box;
                            }
    
                            &:nth-child(3) {
                                width: 60%;
                                
                            }

                            img {
                                width: 30px !important;
                                height: 40px !important;
                            }
                        }
                    }
                }
                .full-width {

                    .btn-blue-orange {
                        width: 400px;
                    }
                }
            }
        }
    }
        
    @media only screen and (max-width: 1079px) {
        .top-illustration {
    
            .opacity-light-section {
                height: auto;
                flex-direction: column-reverse;
    
                .left{
                    padding: 50px 0 0 0;
                    width: calc(96%);
                    margin: 0 auto;
                    height: 300px;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                        margin-top: 20px;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: center;
                    }
                }
                img {
                    width: 90%;
                    max-width: 350px;
                    margin-left: 0px;
                    margin: -50px auto 0;
                }
            }
        }

        .skeleton-page {
            .page {
                display: flex;
                flex-wrap: wrap;
                width: 96%;
                max-width: 1300px;
                margin: 0 auto 50px;
    
                .left {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    margin: 50px 0;

                    .cards {
                        width: 270px;
                        height: 350px;
                        
                        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                            display: none;
                        }
                    }
                }

                .right {
                    width: 100%;

                    .test {
                        width: 80%;
                        height: 45px;
                        top: 10px;
                        left: 5%;
                        position: absolute;
                        border-radius: 27px;
                        margin: 0px 5%;
                    }
    
                    .content {
                        width: 90%;
                        border-radius: 43px;
                        margin: 50px auto 50px auto;
                        height: 785px;
                    }
                }
            }
        }

        .course-main {
            flex-direction: column;

            .course-list-left, .course-info-right {
                width: 100%;
            }

            .course-list-left.computer {
                display: none;
            }

            .course-list-left.mobile {
                display: block;
                padding: 0;

                article {
                    margin: 50px auto 0 auto;
                }
                .swiper {

                    .swiper-wrapper {
                        align-items: stretch;
                    }
                    padding: 50px 0;

                    .swiper-slide {
                        .btn-lightorange {
                            display: none;
                        }
                    }
                }
            }
        }
    }


    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .top-illustration {

            height: 450px;

            &::before {
                clip-path:none;
            }
    
            .opacity-light-section {
                flex-direction: column-reverse;
                    
                .left {
                    width: 96%;
                    margin: 0 auto;
                    height: 250px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding-top: 0;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                    }
    
                    p {
                        font-size: 16px;
                        line-height: 20px;
                        text-align: justify;
                        padding: 0px 0;
                        width: 100%;
                    }
                }
                img {
                    margin: 0px auto;
                    max-width:280px;
                }
            }
        }

        .skeleton-page {
            .filter {
                margin-top: 230px;
                flex-direction: column;
                height: 206px;

                .filter-item {
                    margin: 0 0 30px 0;
                    height: 40px;
                }
            }
        }

        .filter-formation {
            .wrapper-filter {
                margin-top: 200px;
                padding-bottom: 0;
                flex-direction: column;
                align-items:center;
                justify-content: space-between;

                .btn-filter:nth-child(1), .btn-filter:nth-child(2) {
                    margin-bottom: 30px;                    
                }

                .btn-filter {
                    z-index: 0;
                    position: relative;

                    .show-menu {
                        z-index: 50px;
                    }
                }

                .btn-filter:nth-child(1) {
                    z-index: 10;
                }

                .btn-filter:nth-child(2) {
                    z-index: 9;
                }

                .btn-filter:nth-child(3) {
                    z-index: 8;
                }
            }
        }

        .course-main {
            margin-bottom: 250px;

            .course-info-right {
                width: 96%;
                padding: 50px 0;
                margin: 0 auto;

                .info-wrapper {
                    padding: 80px 10px 20px;
                    box-sizing: border-box;

                    .title-wrapper {
                        text-align: center;
                        align-items: center;

                        .left {
                            width: 100%;
                        }
                    }

                    .card-orange {

                        .row {
                            max-width: 100% !important;
                            width: 100% !important;
                            text-align: center;
                            border: 0 !important;
                            height: auto;
                        }
                    }

                    .comp-wrapper {
                        flex-direction: column;

                        .txt-wrapper {
                            width: 100%;
                            margin: 10px 0 0 0;
                        }
                    }

                    .price-time-wrapper {
                        flex-direction: column;

                        .price-wrapper, .time-wrapper {
                            width: 100%;
                        }

                        .time-wrapper {
                            margin-top: 20px;
                        }
                    }

                    .full-width .btn-blue-orange {
                        width: 100%;
                    }
                }
            }
        }
    }
    
`;

export default FormationsStyled;