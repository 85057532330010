import styled from 'styled-components';

const FormLmsStyled = styled.article`
	width: 100vw;
	height: 100vh;
	background-color: #F6FDFF;
	display: flex;
	align-items: center;
	justify-content: center;

	.card-form {
		padding: 20px;
		background-color: #fff;
		border-radius: 3px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 350px;

		h2 {
			color: #202c3c;
			font-size: 22px;
		}

		.input-name {
			padding: 20px 0 10px;
			color: #4d4d4d;
		}

		input {
			border: 0;
			padding: 15px 5px;
			background-color: #F2F2F2;
			width: 100%;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		}

		.submit-input {
			margin: 20px 0 0 0;
			background-color: #202c3c;
			box-shadow: none;
			color: #fff;
		}

		.disclaimer {
			padding: 5px 0 0 0;
			font-style: italic;
			font-size: 14px;
		}
	}
`;

export default FormLmsStyled;