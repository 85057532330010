/**** Import des modules ****/
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/img/illustration/header-homme.png";
import AOS from "aos";
import "aos/dist/aos.css";

/**** Import des components et containers ****/
import Header from "../../containers/Header";
import Footer from "../../containers/Footer";

/**** Import d'images ****/
import logoCarre from "../../assets/img/logo/thumbnail_cpf.png";

/**** Import des css ****/
import ContactStyled from "./ContactStyled";

const Contact = ({
  changeInputValue,
  fetchSendEmailContact,
  setFullPageLoaderContact,
}) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <ContactStyled>
      <Header bcgColor={"rgba(100,154,206,1)"} />
      <section className="header-illustration">
        <div className="opacity-light-section">
          <img src={logo} alt="logo wefor" />
          <div className="left">
            <h1 className="bold">Contact</h1>
            <p>
              Une question subsidiaire sur notre offre de formation ? Une offre
              d’emploi à pourvoir rapidement ? Envie de faire connaissance avec
              l’équipe Wefor ? Contactez-nous en remplissant ce formulaire. Nous
              vous répondrons avec plaisir !
            </p>
          </div>
        </div>
      </section>
      <section className="full-page-wrapper">
        <div className="left-section">
          <div className="top-logo-infos">
            <img
              src={logoCarre}
              alt="logo wefor carré"
              className="logo-contact"
            />
            <div className="contact-info">
              <h4 className="bold">Adresse</h4>
              <p>239 Ter, Avenue de la division leclerc</p>
              <p>92160 Antony</p>
              <h4 className="bold">Téléphone</h4>
              <a href="tel:+33185540178"> 01 85 54 01 78 </a>
              <h4 className="bold">Email</h4>
              <a href="mailto:contact@wefor.fr">contact@wefor.fr</a>
              <h4 className="bold">Horaires d’ouverture</h4>
              <p>Du Lundi au Vendredi de 9h à 18h</p>
            </div>
          </div>
          <div className="map-wrapper">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2631.424663456425!2d2.299654115850264!3d48.73558421729947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e677a81107add9%3A0x800d447cc1ca6805!2s239%20ter%2C%20239%20Av.%20de%20la%20Division%20Leclerc%2C%2092160%20Antony!5e0!3m2!1sfr!2sfr!4v1656581006450!5m2!1sfr!2sfr"
              width="100%"
              height="300px"
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className="right-section">
          <form
            className="contact-form"
            onSubmit={(event) => {
              event.preventDefault();
              setFullPageLoaderContact(true);
              fetchSendEmailContact();
            }}
          >
            <div className="name-wrapper">
              <label>
                <div className="mobile-none">Nom :</div>
                <input
                  type="text"
                  name="nom"
                  placeholder=" Nom*"
                  required
                  onChange={(event) => {
                    changeInputValue(event.target.value, event.target.name);
                  }}
                />
              </label>
              <label>
                <div className="mobile-none">Prénom :</div>
                <input
                  type="text"
                  name="prenom"
                  placeholder=" Prenom*"
                  required
                  onChange={(event) => {
                    changeInputValue(event.target.value, event.target.name);
                  }}
                />
              </label>
            </div>
            <label>
              <div className="mobile-none">Adresse Email :</div>
              <input
                type="email"
                name="email"
                placeholder=" Email*"
                required
                onChange={(event) => {
                  changeInputValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              <div className="mobile-none">Téléphone :</div>
              <input
                type="tel"
                name="tel"
                placeholder=" Téléphone*"
                required
                onChange={(event) => {
                  changeInputValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              <div className="mobile-none">Objet :</div>
              <input
                type="text"
                name="objet"
                placeholder=" Objet*"
                required
                onChange={(event) => {
                  changeInputValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              <div className="mobile-none">Message :</div>
              <textarea
                name="message"
                placeholder=" Message*"
                required
                className="area"
                onChange={(event) => {
                  changeInputValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <input type="submit" value="Envoyer" className="btn-blue" />
          </form>
        </div>
      </section>
      <Footer />
    </ContactStyled>
  );
};

Contact.propTypes = {};

export default Contact;
