import { connect } from 'react-redux'

import Formation from '../../pages/Formation'

import { changeActivCourseId, fetchFormations, fetchGetOneProgram, fetchFormationsGraph, fetchFormationsInfos } from '../../actions/course'
import { setFilter, setFilterId, fetchSecteurs } from '../../actions/menu'
import { openModal } from '../../actions/modal'
import { Menu } from 'semantic-ui-react'
import { fetchGetCoursesLms } from '../../actions/lms'

const mapStateToProps = (state) => ({
  formations: state.course.formations,
  activCourseId: state.course.activCourseId,
  loadFormation: state.course.loadFormation,
  secteurs: state.menu.secteursData,
  domaines: state.menu.domainesData,
  secteurCourseFilter: state.menu.secteurCourseFilter,
  domaineCourseFilter: state.menu.domaineCourseFilter,
  dureeCourseFilter: state.menu.dureeCourseFilter,
  formationCourseFilter: state.menu.formationCourseFilter,
  userRole: state.login.userRole,
  currentProfilUser: state.menu.currentProfilUser,
  templatesLms: state.lms.templatesLms,
  coursesLms: state.lms.coursesLms,
  isLogged: state.login.isLogged,
  jobFiches: state.job.jobFiches,
  formationsPagination: state.course.formationsPagination,
  filterSecteurId: state.menu.filterSecteurId,
  filterDomaineId: state.menu.filterDomaineId,
  cpfCourseFilter: state.menu.cpfCourseFilter,
  filterCpfId: state.menu.filterCpfId,
  secteursData: state.menu.secteursData,
  formationInfo: state.course.formationInfo
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => {
        dispatch(openModal(name));
    },
    fetchFormations: (paramObj) => {
      dispatch(fetchFormations(paramObj));
    },
    setFilter: (name, title) => {
      dispatch(setFilter(name, title));
    },
    setFilterId: (name, title) => {
      dispatch(setFilterId(name, title));
    },
    changeActivCourseId: (id) => {
      dispatch(changeActivCourseId(id));
    },
    fetchGetOneProgram: (id) => {
      dispatch(fetchGetOneProgram(id));
    },
    fetchSecteurs: () => {
      dispatch(fetchSecteurs());
    },
    fetchFormationsGraph: (paramObj) => {
      dispatch(fetchFormationsGraph(paramObj));
    },
    fetchGetCoursesLms: () => {
      dispatch(fetchGetCoursesLms());
    },
    fetchFormationsInfos: (id) => {
      dispatch(fetchFormationsInfos(id))
    }
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Formation)