import styled from 'styled-components';

const AccountAdminUsersStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

    .right-wrapper {
        width: calc(96% - 250px);
        margin: 20px auto;

        h2 {
            text-align: center;
            margin: 20px 0 20px;
            font-size: 22px;
            color: #202D3C;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            svg {
                color: #6399ce; 
                margin-bottom: -3px;
                margin-left: 10px;
                transition: all .2s ease;
                cursor: pointer;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }

        .pre-list-wrapper {
            width: 100%;
            box-sizing: border-box;
            box-shadow: 0px 1px 10px #00000029;
            border-radius: 10px;
            background-color: #fff;
            display: flex;
            flex-wrap: wrap;
            margin: 30px 0 30px;
            position: relative;

            .title-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid #4d4d4d;

                .row-title {
                    width: 20%;
                    text-align: center;
                    font-size: 16px;
                    padding: 10px 0;
                    color: #649ACE;
                }
            }

            .info-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                &:nth-child(odd) {
                    background-color: #E9F1F8;
                }

                .row-user {
                    width: 20%;
                    text-align: center;
                    font-size: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    word-break: break-word;

                    &.modif-height {
                        padding: 10px 0;
                    }

                    &.more-link {
                        cursor: pointer;
                        transition: all .2s ease;

                        &:hover {
                            opacity: .7;
                            transform: scale(1.05);
                        }
                    }
                    span {
                        width: calc(100% - 42px);
                    }

                    &.left {
                        justify-content: start;
                    }

                    .info-svg {
                        margin: 0 0 0 5px;
                    }
                }
            }

            .action-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border-top: 1px solid #4d4d4d;
                padding: 5px 0;

                .row-action {
                    width: 20%;
                    text-align: center;
                    font-size: 16px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    &.left {
                        justify-content: start;
                    }
                    &.center {
                        justify-content: center;
                    }
                    &.right {
                        justify-content: end;
                    }

                    .insc-btn {
                        padding: 10px;
                        border-radius: 5px;

                        &.red {
                            color: #dc4a46;
                        }
                    }
                }
            }
        }

        .dl-btn {
            width: 30px;
            height: 30px;
            background-color: #6AAF8B;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
            transition: all .2s ease;

            &:hover {
                opacity: .7;
            }
        }

    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(96% - 250px);
        margin: 20px auto;

            h1 {
                font-size: 45px;
            }

            h2 {
                font-size: 20px;
            }
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(96% - 250px);
        margin: 20px auto;

            h1 {
                font-size: 40px;
            }

            h2 {
                font-size: 18px;

                .separator-blue {
                    width: 100px;
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {

        .right-wrapper {
            width: calc(96%);
            margin: 20px auto;

            h1 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }

            h2 {
                font-size: 25px;
                text-align: center;

                .separator-blue {
                    width: 50px;
                    position: static;
                    margin: 10px auto;
                }
            }

            .pre-list-wrapper {
    
                .title-wrapper {
    
                    .row-title {
                        font-size: 14px;
                    }
                }
    
                .info-wrapper {
    
                    .row-user {
                        font-size: 12px;
                    }
                }
    
                .action-wrapper {
    
                    .row-action {
                        font-size: 12px;
                    }
                }

                .insc-btn {
                    padding: 10px;
                    border-radius: 5px;
                    font-size: 12px;
                }
            }
        }
    }
}
  
`;

export default AccountAdminUsersStyled;