import axios from 'axios'
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { 
  FETCH_GET_NEWS,
  saveNews,
  toggleFullPageLoaderNews
} from '../actions/news';

//jOm8BurStWxo5xJiBH6B6n1-EiLkSc3HS1xWsG1YxIc
// const URL = "http://localhost:1338/api/";
const URLAPINEWS = "https://api.newscatcherapi.com/v2/search";

const newsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_GET_NEWS: {
      store.dispatch(toggleFullPageLoaderNews(true))
      const state = store.getState();
      const search = action.search

      const goodSearch = search.replaceAll(' ', ' AND '); 

      const options = {
        method: 'GET',
        params: {q: goodSearch, lang: 'fr'},
        headers: { 
          'content-type': 'application/x-www-form-urlencoded',
          "x-api-key": "jOm8BurStWxo5xJiBH6B6n1-EiLkSc3HS1xWsG1YxIc"
        },
        url: `${URLAPINEWS}`,
        };
          axios(options)
          .then((response) => {
            store.dispatch(saveNews(response.data))
            store.dispatch(toggleFullPageLoaderNews(false))
          })
          .catch((error) => {
            console.error(error);
          });
        next(action);
        break;
      }
    default:
      next(action);
  }
};

export default newsMiddleware;