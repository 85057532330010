import styled from 'styled-components';


const HeaderStyled = styled.header`
   position: fixed;
   z-index: 1001;
   width: 100%;
   top: 0;
   background-color: ${props => props.windowTop ? props.bcgColor === '#fff' ? '#fff' : '' : '#fff'};

   .wrapper-header-top {
      width: 100%;
   }

   .header-top {
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      height: 75px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      
      .header-logo {
         height: 75px;

         &.mobile {
            display: none;
         }
      }

      .menu-wrapper {
         width: 80%;
         max-width: 1200px;
         color: #6399ce;

         display: flex;
         flex-wrap: wrap;
         justify-content: end;
         align-items: center;

         .menu-picto {
            height: 45px;
         }

         ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: end;
            width: 85%;
            max-width: 780px;

            li {
               display: flex;
               flex-wrap: wrap;
               align-items: center;
               justify-content: center;
               font-size: 18px;
               min-width: 60px;
               padding: 10px 0px 10px 0px;
               box-sizing: border-box;

               .apporteur-btn {
                  color: #D071AB;
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  font-size: 14px;
                  text-align: center;
                  transition: all .2s ease;
                  display: none;
   
                  &:hover {
                     opacity: .7;
                  }
               }

               .phone {
                  font-size: 20px;
                  text-align; right;
               }

               a {
                  font-size: 20px;
                  color: ${props => (props.comboHeaderColor ? "#6399ce" : "#6399ce")};
                  text-decoration: none;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  transition: all .2s ease;

                  .menu-picto-phone {
                     font-size: 25px;
                     padding-right: 10px;
                  }

                  &:hover {
                     opacity: .7;
                     transform: scale(1.2);
                  }
               }

               .menu-picto {
                  font-size: 25px;
                  padding-right: 10px;
               }

               .menu-picto-contact {
                  font-size: 30px;
                  padding-right: 10px;
               }

               .menu-picto-phone {
                  font-size: 30px;
                  padding-right: 10px;
               }
            }

            .account-li {
               transition: padding-bottom .2s;
               height: 60px;
               padding-bottom: 0px;
               cursor: pointer;
               width: 236px;
               margin: 3px 0 0 0;
               padding: 0 10px 0 0;
               position: relative;
               border-radius: 20px;
               color: ${props => (props.comboHeaderColor ? "#6399ce" : "#6399ce")};
               transition: all .2s ease;

               &.actif-class {
                  background-color: #6399ce;
                  color: #fff;
                  padding-bottom: 0px;
                  border-bottom-left-radius: 0px;
                  border-bottom-right-radius: 0px;
               }
               
               &:hover {
                  background-color: #6399ce;
                  color: #fff;
               }

               div {
                  position: absolute;
                  left: 0;
                  top: 60px;
                  width: 100%;
               }
            }
         }

         div {
            img:nth-child(1) {
               padding-right: 10px;
            }
         }
      }
   }

   @media only screen and (max-width: 1400px) {
      .header-top {

         .header-logo {
            margin-left: 20px;
         }

         justify-content: space-between;
         .menu-wrapper {
            width: 80%;
            justify-content: end;

            ul {
               justify-content: end;
               width: 100%;
            }

            li:nth-child(1), li:nth-child(2) {
               
            }
         }
      }
    }
  
    @media only screen and (max-width: 1079px) {
      .header-top {
         .menu-wrapper {

            width: calc(100% - 170px);
            .menu-picto {
               
            }
   
            ul {
               
             

               .mobile-none {
                  
               }

               .margin-left-20 {
                  
               }

               .account-li {
                  padding: 0;
               }

               li {
                 

                  .menu-picto-contact, .menu-picto-phone {
                     
                  }
               }
            }
         }
      }
   }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
      display: none;
      .header-top {
         .menu-wrapper {
            width: calc(100%);
            position: absolute;
            justify-content: end;
            top: 75px; 
            background-color: ${props => props.windowTop ? props.bcgColor === '#fff' ? '#fff' : '' : '#fff'};

            .menu-picto {
               
            }
   
            ul.pictos-ul {
               justify-content: space-between;
               width: 100%;
               max-width: 500px;

               .mobile-none {
                  width: 35px;
                  min-width: 35px;
               }

               .apporteur-btn {
                  font-size: 12px;
               }

               li {

                  &.margin-left-20 {
                     width: 35px;
                     min-width: 35px;
                  }
                  &.rs-picto {
                     width: 35px;
                     min-width: 35px;
                  }
                  a {
                     .menu-picto-phone {
                        padding: 0;
                        font-size: 17px;
                     }
                  }
                  span.mobile-none {
                     
                     padding: 0;
                  }
               }
            }

            .account-li {
               max-width: 170px;
               font-size: 14px;
               div {
                  position: absolute;
                  
                  top: 60px;
               }
            }
         }

         .header-logo {
            height: 50px;
            z-index: 1002;
            position: relative;

            &.pc {
               display: none;
            }

            &.mobile {
               width: 120px;
               height: auto;
               z-index: 1002;
               position: relative;
               display: inline-block;
               margin-left: 5px;
            }
         }
      }
    }
`;

export default HeaderStyled;