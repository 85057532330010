import { connect } from 'react-redux'

import AccountAdminUsers from '../../pages/AccountAdminUsers'

import { fetchGetPreInscription, fetchPostUserFromPreInscription, fetchDeleteUserFromPreInscription, fetchGetUsersP, fetchGetUsersE } from '../../actions/apprenant'

const mapStateToProps = (state) => ({
	user: state.login.session.user,
    jwt: state.login.session.jwt,
    isLogged: state.login.isLogged,
    preInscription: state.apprenant.preInscription,
    usersFormAdminP: state.apprenant.usersFormAdminP,
    usersFormAdminE: state.apprenant.usersFormAdminE
})

const mapDispatchToProps = (dispatch) => ({
    fetchGetPreInscription: () => {
        dispatch(fetchGetPreInscription());
    },
    fetchPostUserFromPreInscription: (data) => {
        dispatch(fetchPostUserFromPreInscription(data));
    },
    fetchDeleteUserFromPreInscription: (data) => {
        dispatch(fetchDeleteUserFromPreInscription(data));
    },
    fetchGetUsersP: (data) => {
        dispatch(fetchGetUsersP(data));
    },
    fetchGetUsersE: (data) => {
        dispatch(fetchGetUsersE(data));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountAdminUsers)