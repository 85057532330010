import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios'
import * as qs from 'qs'
import { Link, useParams, useNavigate } from "react-router-dom"

import urlBackEnd from '../../assets/js/urlBackEnd'

const URL = urlBackEnd


export default function FreeSoloCreateOption({fetchFormations, setMuiCourseFilterKeyValue, muiCourseFilterKeyValue, secteurCourseFilter, changeActivCourseId, activCourseId, saveSlugs, saveFormationFromParams}) {
  const navigate = useNavigate();
  const [top100Films, setTop100Films] = React.useState([]);

  const handleSearchjobOffers = (value) => {
	setTop100Films([])
  const queryJobObj = () => {
    if (secteurCourseFilter !== '') {
      const queryFilterJob = qs.stringify({
        filters: {
          nom: {
          $containsi: value
          },
          secteurs: {
            id: Number(secteurCourseFilter)
          },
          active: true,
        }
        }, {
          encodeValuesOnly: true,
        });
        return queryFilterJob
    } else {
      const queryFilterJob = qs.stringify({
        filters: {
          nom: {
          $containsi: value
          },
          active: true,
        }
        }, {
          encodeValuesOnly: true,
        });
        return queryFilterJob
    }
  }
	  axios.get(`${URL}formations?${queryJobObj()}`, {})
		.then((response) => {
			const makeArray = response.data.data.map((obj) => {
				const objR = {title: obj.attributes.nom, id: obj.id, createdAt: obj.attributes.createdAt}
				return objR
			})
      makeArray.push({title: value, id: 0, createdAt: 'date'})
			setTop100Films(makeArray)
		})
		.catch((error) => {
		  console.error(error)
		});
  }

  const handleKeyDown = event => {
    switch (event.key) {
      case ",":
      case "Enter": {
        event.preventDefault();
        event.stopPropagation();

        if (event.target.value.length > 0) {
          saveSlugs('slugKeyword', `/${event.target.value}`)
          setMuiCourseFilterKeyValue({title: event.target.value})
          handleSearchjobOffers(event.target.value)
        }
        break;
      }
      default:
    }
  };

  const handleInputBlur = event => {
    setTop100Films([])
  };

  return (
    <Autocomplete
      value={muiCourseFilterKeyValue}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          saveFormationFromParams('loading', [])
          saveSlugs('slugKeyword', `/${newValue}`)
          setMuiCourseFilterKeyValue({
            title: newValue,
          });
        } else if (newValue && newValue.title) {
          saveFormationFromParams('loading', [])
          setMuiCourseFilterKeyValue({
            title: newValue.title,
          });
        }
        if (typeof newValue === 'object' && newValue !== null) {
          saveSlugs('slugKeyword', `/${newValue.title}`)
        }
        if (newValue === null) {
          saveFormationFromParams('loading', [])
          saveSlugs('slugKeyword', ``)
          setMuiCourseFilterKeyValue(null)
        }
      }}
      filterOptions={(options, params) => {
        return top100Films;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={top100Films}
      getOptionLabel={(option) => {
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      sx={{ width: 250, height: 40, zIndex: 40}}
      freeSolo
      renderInput={(params) =>  (
        <TextField
          {...params} 
          label="Recherche par mot clé"
          onChange={(e) =>  {
            setTop100Films([])
            if (e.target.value.length > 2) {
              handleSearchjobOffers(e.target.value)
            }
          }}
          onKeyDown={e => {
            if (e.code === 'Enter' && e.target.value) {
              saveSlugs('slugKeyword', `/${e.target.value}`)
              setMuiCourseFilterKeyValue({title: e.target.value})
              handleSearchjobOffers(e.target.value)
            }
          }}
          onBlur={handleInputBlur}
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top