import { connect } from 'react-redux'

import StepOne from '../../../components/FinancementSteps/StepOne'
import { paiementAxepta } from '../../../actions/actions'

const mapStateToProps = (state) => ({
    user: state.login.session.user,
    axeptaResponse: state.actions.axeptaResponse,
    htmlResponse: state.actions.htmlResponse
})

const mapDispatchToProps = (dispatch) => ({
    paiementAxepta: (string) => {
        dispatch(paiementAxepta(string));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(StepOne)