/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import FormLmsStyled from './FormLmsStyled'

const FormLms = ({}) => {


  return (
    <FormLmsStyled >
        <div className='card-form'>
            <h2 className='bold'>Connexion à votre session de formation</h2>
            <p className='disclaimer'>Merci d'utiliser les identifiants de la session de formation envoyé par Email et non ceux de votre compte Wefor</p>
            <form name="form1" method="post" action="https://cloudelearning.org/php5/manager/" target="_top">
            <input type="hidden" name="urlretour" value="http://wefor.fr/connexion-lms/" />
            <div className='input-name'>Utilisateur : </div>
            <input name="login" type="text" id="login" size="30" />
            <div className='input-name'>Mot de passe :  </div>
            <input name="password" type="password" id="password" size="30" />
            <input type="submit" name="submit" id="submit" value="Se connecter" className='submit-input' />
            </form>
        </div>
    </FormLmsStyled >
  )
}

export default FormLms