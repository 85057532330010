import { connect } from 'react-redux'

import StepThreeE from '../../../components/FinancementSteps/StepThreeE'


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(StepThreeE)