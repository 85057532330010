import styled from 'styled-components';

const ModalPostulerTutoStyled = styled.div`
   position: fixed;
   bottom: 20px;
   left: 20px;
   z-index: 10000;
   background-color: #fff;
   border-radius: 10px;
   padding: 15px;
   box-shadow: 0px 3px 10px #00000069;

   .modal-content-wrapper {
      max-width: 300px;
   }

   transition: all .5s ease;

   .close-icon {
      position: absolute;
      top: 2px;
      right: 2px;
      font-size: 20px;
      color: #dc4a46;
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
         transform: scale(1.1);
      }
   }

   .open-icon {
      font-size: 20px;
      color: #6AAF8B;
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
         transform: scale(1.1);
      }
   }

   &.display-none {
      display: none;
   }

   h4 {
      font-size: 18px;
      color: #4d4d4d;
      margin-bottom: 20px;
   }

   .step-1, .step-2, .step-3 {

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: #4d4d4d;
      transition: all .3s ease;
      cursor:pointer;

      &:hover {
         text-decoration: underline;
      }

      .number {
         height: 20px;
         width: 20px;
         border-radius: 50%;
         display: flex;
         align-items: center;
         justify-content: center;
         color: #fff;
         font-weight: bold;
         background-color: #6AAF8B;
         margin: 0 10px 0 0;
      }

      .txt {
         width: calc(100% - 52px);
         &.done {
            text-decoration:line-through;
         }
      }
   }

   .MuiFormGroup-root {
      flex-direction: row;
   }

   .step-2, .step-3 {
      margin: 10px 0;
   }

   .postuler-grey {
      width: 100%;
      text-align: center;
      border-radius: 10px;
      border: 0;
      padding: 10px;
      box-sizing: border-box;
      margin-top: 20px;
   }

   .postuler-green {
      width: 100%;
      text-align: center;
      border-radius: 10px;
      border: 0;
      padding: 10px;
      box-sizing: border-box;
      background-color: #6AAF8B;
      color: #fff;
      cursor: pointer; 
      transition: all .3s ease;
      margin-top: 20px;

      &:hover {
         opacity: .7;
      }
   }

@media only screen and (min-width: 0px) and (max-width: 759px) {
   bottom: 60px;
}
`;

export default ModalPostulerTutoStyled;