/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { FaGenderless } from 'react-icons/fa'
import { RiNumber1, RiNumber2, RiNumber3 } from 'react-icons/ri'
import { Link } from "react-router-dom"
import PopupAxepta from '../../PopupAxepta'
/**** Import des css ****/
import StepOneStyled from './StepOneStyled'
import cryptoAxepta from '../../../assets/js/cryptoAxepta'


const StepOne = ({ thisFinancement, paiementAxepta, user, axeptaResponse, htmlResponse }) => {

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  return (
    <StepOneStyled>
        <h2 className='bold'>Demande de financement n°{thisFinancement.id}</h2>
        <h3 className='bold'>Informations disponibles pour cette formation</h3>
        <ul className='info-list'>
          <li><FaGenderless /> Nom de la formation : <span className='bold'> {thisFinancement.attributes.nom_formation}</span></li>
          <li><FaGenderless /> Demande effectuée le :  <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.publishedAt}</Moment></span></li>
          <li><FaGenderless /> Formation éligible au CPF : <span className='bold'>{thisFinancement.attributes.formation.data.attributes.cpf_link !== null && thisFinancement.attributes.formation.data.attributes.cpf_link !== '' ? 'Oui' : 'Non'}</span></li>
          <li><FaGenderless /> Type de formation : <span className='bold'>100% Elearning</span></li>
          <li><FaGenderless /> Prix de la formation : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix))} TTC</span></li>
          <li><FaGenderless /> <div>Emplois accessibles après formation : <span className='bold'>{thisFinancement.attributes.formation.data.attributes.secteurs.data[0].attributes.nom}</span></div> </li>
        </ul>
        {thisFinancement.attributes.formation.data.attributes.cpf_link !== null && thisFinancement.attributes.formation.data.attributes.cpf_link !== '' ?
          <section className='step-fin'>
            <h2 className='bold'>Suivez les étapes de financement</h2>
            <ul className='info-list'>
              <li><RiNumber1 className='number bold'/><div>Faites votre demande de financement CPF sur <a href={thisFinancement.attributes.formation.data.attributes.cpf_link} target="_blank" rel='noreferrer'className='bold'>moncompteformation.gouv.fr</a>.</div> </li>
              <li><RiNumber2 className='number bold'/><div>Nous traitons votre demande, les informations relatives à votre formation seront disponibles ici ou dans votre espace personnel EDOF.</div> </li>
              <li><RiNumber3 className='number bold'/> <div>Accédez à votre formation aux dates renseignées lors de l'étape 1.</div> </li>
            </ul>
            <a href={thisFinancement.attributes.formation.data.attributes.cpf_link} target="_blank" rel='noreferrer'className='bold'> 
              <button className='edof-link'>Effectuez votre demande de financement</button>
            </a>
          </section>
        :
        <></>
        }
        {thisFinancement.attributes.formation.data.attributes.cpf_link !== null && thisFinancement.attributes.formation.data.attributes.cpf_link !== '' ?
          <h3 className='bold'>Vous ne disposez pas de droit CPF</h3>
        :
          <h3 className='bold'>Cette formation n'est pas éligible par le CPF - paiement CB ou virement</h3>
        }
        <ul className='info-list'>
          <li><RiNumber1 className='number bold'/><div>Merci de nous contacter pour effectuer votre paiement.</div></li>
          <li><RiNumber2 className='number bold'/><div>Nous traitons votre demande, les informations relatives à votre formation seront disponibles sur cette page.</div></li>
          <li><RiNumber3 className='number bold'/><div>Accédez à votre formation aux dates renseignées lors de l'étape 1.</div></li>
        </ul>
        <Link to="/contact" className='paiement-link bold'>Nous contacter</Link>
        
    </StepOneStyled>
  )
}

export default StepOne