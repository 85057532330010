import styled from 'styled-components';

const ModalCourseProgStyled = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .txt-skeleton {
        font-size: 60px;
        width: 230px;
    }

    .modal-content-container {
        width: 92%;
        max-width: 1000px;
        min-height: 300px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 5px 15px 10px #00000066;
        position: relative;
        box-sizing: border-box;

        .page-wrapper {
            max-width: 1000px;   
            max-height: 90vh;     
            overflow-y: auto;
            overflow-x: hidden;
            border-radius: 20px;
        }

        .article-list-wrapper, .module-formation-list {
            ul {
                li {
                    text-align: left;
                    margin-left: 20px;
                }
            }
        }
    
        .illustration-header {
            width: 100%;
            height: 450px;
            z-index: 10;
            position: relative;
        
            &::before {
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width:100%;
                z-index: -10;
                background: linear-gradient(180deg, #f7edea 0%, #d47558 100%);
            }
        
            .opacity-light-section {
                top: 100%;
                max-width: 1000px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                padding: 0px 0;
                margin: 0 auto;
                height: auto;
                flex-direction: column-reverse;
        
    
                .left {
                    width: 96%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    box-sizing: border-box;
                    height: 100%;
    
                    h1 {
                        font-size: 30px;
                        text-align: center;
                        margin: 10px 0;
                    }
    
                    
                }
                img {
                        width: 80%;
                        max-width: 430px;
                        position: absolute;
                        bottom: -250px;
                    }
                }
    
                .title-wrapper-prog {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: center;

                    .para-pre {
                        color: #fff;
                        padding: 20px 0;
                        margin: 20px 0;
                        font-size: 26px;
                        background-color: #d47558;
                        border-radius: 10px;
                    }
    
                    h2 {
                        color: #fff;
                        padding: 0 0 5px;
                        font-size: 35px;
                        text-transform: uppercase;
                        text-align: center;
    
                        &.border {
                            padding: 0 0 20px;
                            margin: 0 0 20px;
                            border-bottom: 4px solid #6aaf8b;
                        }
                    }
                    h3 {
                        color: #d47558;
                        text-transform: uppercase;
                        font-size: 35px;
                        text-align: center;
    
                        &.top {
                            padding: 10px 0 0 0;
                        }
    
                        &.bottom {
                            padding: 0 0 10px 0;
                        }
                    }
    
                    h1 {
                        display: block;
                        font-size: 50px;
                        color: #fff;
                        text-transform: uppercase;
                        color: #4d4d4d;
                    }
                }
            }
        }

        .section-programme-formation {
            display: flex;
            flex-direction: column;
            max-width: 1200px;
            margin: 0 auto;
            padding: 250px 0 0px 0;
    
            h2 {
                font-size: 35px;
                width: auto;
                display: inline;
                margin: 0 auto;
                position: relative;
                color: #4d4d4d;
                padding-bottom: 10px;
                margin-bottom: 40px;
                text-transform: uppercase;
                text-align: center;
    
                .separator-blue {
                    background-color: #6399ce;
                    height: 6px;
                    width: 100px;
                    right: calc(50% - 50px);
                    margin: 10px 0 0 auto;
                    position: absolute;
                    bottom: 0;
                }
            }
    
            .no-margin {
                margin-bottom: 0;
            }
    
            .tarif-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                margin-bottom: 60px;
    
                .tarif-formation {
                    border: 2px solid #649ACE;
                    padding: 10px 40px; 
                    margin-right: 20px;
                    font-size: 30px; 
                    color: #4d4d4d;
                    border-radius: 12px;
                }

                a {
                    img {
                        width: 150px;
                        transition: all .3s ease;

                        &:hover {
                            transform: scale(1.1)
                        }
                    }
                    
                }
    
                .btn-lightblue {
                    border: 2px solid #639ACD;
                    padding: 10px 80px; 
                    font-size: 30px; 
                    color: #fff;
                    border-radius: 12px;
                    background-color: #639ACD;
                    cursor: pointer;
    
                    &:hover {
                        color: #639ACD;
                        background-color: #fff;
                    }
                }
            }
    
            .article-programme-description {
                color: #707070;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-top: 0px;
    
                img {
                    width: 10%;
                }
    
                .article-list-wrapper {
                    width: 87%;


                    p {
                        text-align: justify;
                        padding-right: 20px;
                        line-height: 20px;
                        box-sizing: border-box;
                    }
    
                    h3 {
                        padding-bottom: 20px;
                        font-size: 22px;
                        text-align: left;
                    }
    
                    ul li {
                        font-size: 16px;
                        line-height: 20px;
                        list-style: inside;
                    }
                }
            }
        }

        .section-module-formation {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: linear-gradient(180deg,rgba(248, 232, 227,0) 0%,rgba(248, 232, 227,0.51782) 48.22%,#f8e8e3 100%);
            padding: 50px 0;
    
            .design-green-top {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
    
                .separator-green {
                    width: 50px;
                    height: 6px;
                    background-color: #C6D458;
                }
    
                img {
                    margin: 0 20px;
                    width: 150px;
                }
            }
    
            .design-blue-bottom {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 20px;
    
                .separator-blue {
                    width: 50px;
                    height: 6px;
                    background-color: #325076;
                }
    
                img {
                    width: 150px;
                    margin: 0 20px;
                }
            }
    
            .module-formation-list {
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                justify-content: center;
                margin-top: 0px;
                color: #707070;
                width: 96%;
                max-width: 1200px;
    
                .left-prog, .right-info {
                    width: 100%;
                    
                    .programme-disclaimer {
                        text-align: left;
                    }

                    ul {
                        list-style: auto;
                    }
    
                    h3 {
                        text-align: center;
                        font-size: 30px;
                        line-height: 35px;
                        margin-bottom: 10px;
                    }
    
                    h4 {
                        padding: 20px 0 5px;
                        text-align: left;
                    }

                    p {
                        text-align: left;
                    }

                    .module-title {
                        font-size: 20px;
                    }
    
                    line-height: 22px;
                }
    
                .separator-vertical-blue {
                    width: 2px;
                    background-color: #639ACD;
                    margin-top: 55px;
                }
            }

            .programme-disclaimer {
                padding-top: 30px;
            }

            .programme-disclaimer {
                text-align: justify;
                margin: 30px 20px 0;

                h4 {
                    font-size: 20px;
                }

                ol {
                    list-style: auto;
                }

                ul {
                    list-style: auto;
                }

                .def-markdown {
                    ul {
                        list-style: auto;
                    }
                    
                    li {
                        color: #707070;
                        margin: 0 0 5px 0px;
                        list-style: auto;
                        line-height: 16px;
                        list-style-position: inside;
                    }

                    h5 {
                        margin: 20px 0 10px;
                        font-size: 18px;
                        font-weight: bold;
                    }

                    h6 {
                        margin: 20px 0 10px;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }
    
            .arrow-bottom {
                font-size: 40px;
                color: #639ACD;
                margin-top: 40px;
            }
    
            .pct-completion {
                color: #4d4d4d;
                font-size: 30px;
                padding-top: 20px;
            }
        }

        .section-modalité-formation {
            display: flex; 
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            padding: 50px 0;
    
            .time-prog-course {
                width: 96%;
                max-width: 800px;
                margin: 0 auto 50px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                flex-direction: column;
    
                img {
                    width: 150px;
                    display: none;
                }
    
                .time-wrapper {
                    border: 1px solid #707070;
                    border-radius: 27px;
                    padding: 20px 30px 50px 30px;
                    text-align: center;
                    max-width: 560px;
                    width: 96%;
                    box-sizing: border-box;

                    li {
                        list-style: inside;
                    }
    
                    h3 {
                        color: #649ACE;
                        text-transform: uppercase;
                        padding-bottom: 20px;
                        font-size: 22px;
                    }
    
                    p.date {
                        color: #649ACE;
                        text-transform: uppercase;
                        padding-bottom: 30px;
                        font-size: 40px;
                    }
    
                    .txt {
                        color: #4d4d4d;
                        line-height: 20px;
                        text-align: justify;
                    }
                }
            }
    
            .modalite-prog-course {
                width: 96%;
                max-width: 800px;
                margin: 50px auto;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                
                img {
                    width: 150px;
                    display: none;
                }
    
                .evaluation-wrapper {
                    padding: 20px;
                    text-align: center;
                    width: 560px;
                    box-sizing: border-box;
    
                    h3 {
                        font-size: 22px;
                        color: #707070;
                        text-align: left;
                    }
    
                    .list-eval {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: column;
                        color: #649ACE;
                        margin-top: 50px;
                    }
                }
            }
    
            .formateur-prog-course {
                width: 96%;
                max-width: 1200px;
                padding: 30px 15px 10px;
                display: flex;
                flex-direction: column;
                background-color: #E1EDF7;
                border-radius: 27px;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
    
                h3 {
                    font-size: 22px;
                    color: #707070;
                }
    
                .formateur-wrapper {
                    display:flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 20px;
    
                    .img-wrapper {
                        background-color: #fff;
                        width: 160px;
                        height: 160px;
                        border-radius: 80px;
                        margin: 20px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        img {
                            width: 120px;
                        }
                    }
        
                    .txt-formateur {
                        max-width: 950px;
                        width: 100%;
                        color: #707070;
                        line-height: 20px;
    
                        h4 {
                            padding-bottom: 10px;
                            font-size: 18px;
                            text-align: left;
                        }
    
                        p {
                            display: inline-block;
                            width: 100%;
                            text-align: left;
                        }
                    }
                }
    
            }
        }

        .icon-wrapper {
            position: absolute;
            top: -35px;
            right: -35px;
            width: 40px;
            height: 40px;
            background-color: #649ACE;
            border-radius: 17.5px;
            overflow: hidden;
            display:flex;
            align-items: center;
            justify-content: center;
            z-index: 10;

            .close-icon {
                color: #fff;
                font-size: 30px;
                cursor: pointer;
                border-radius: 20px;
            }
        }
    }


    @media only screen and (max-width: 1079px) {
        .modal-content-container {
            .icon-wrapper {
                position: absolute;
                top: -20px;
                right: -8px;
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .modal-content-container {
            width: 96%;

            .choices-wrapper {
                width: 100%;
            }

            .icon-wrapper {
                position: absolute;
                top: -20px;
                right: -8px;
            }

            .section-programme-formation {
    
                h2 {
                    font-size: 35px;
                    text-align: center;
    
                    .separator-blue {
                        width: 100px;
                        right: calc(50% - 50px);
                    }
                }

                h3 {
                    text-align: center;
                }
    
                .tarif-wrapper {
                    flex-direction: column;
                    align-items: center;
                    .tarif-formation {
                        font-size: 25px;
                        margin: 0 0 20px;
                    }
    
                    .btn-lightblue {
                        padding: 10px 40px;
                        font-size: 25px;
                    }
                }
    
                .article-programme-description {
                    flex-direction: column;
    
                    img {
                        width: 150px;
                    }
                }
            }

            .section-modalité-formation .time-prog-course .time-wrapper {
                padding: 15px;

                .date {
                    font-size: 30px;
                    padding: 10px;
                }

                h3 {
                    box-sizing: border-box;
                    height: auto;
                }
            }
        }
    }
`;

export default ModalCourseProgStyled;