import { connect } from 'react-redux'

import Financement from '../../pages/Financement'

const mapStateToProps = (state) => ({
    financements: state.financement.list,
    currentProfilUser: state.menu.currentProfilUser,
})

const mapDispatchToProps = (dispatch) => ({
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Financement)