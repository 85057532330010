/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md"
import { FaFileAlt } from "react-icons/fa"
import Moment from 'react-moment'
import Skeleton from '@mui/material/Skeleton'
import { FaGenderless } from 'react-icons/fa'
import Button from '@mui/material/Button'
/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import dayjs from 'dayjs'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import { toast } from 'react-toastify'
import CollabsCardUnivo from '../../containers/CollabsCardUnivo'

import urlBackEndSave from '../../assets/js/urlBackEndSave'
import URL from '../../assets/js/urlBackEnd'

/**** Import des images ****/

/**** Import des css ****/
import AccountAdminCollabsStyled from './AccountAdminCollabsStyled'

const styleWdate = {
  display: 'flex',
  justifyContent: 'space-evenly',
  margin: '15px 0',
}
const AccountAdminCollabs = ({
  changeClickedFactureCollabs,
  factureCollabs,
  clickedFactureCollabs,
  fetchFactureCollabs,
  session,
  fetchGetInscriptionsFromSessions,
  inscriptionsClickedSession,
}) => {

  useEffect(fetchFactureCollabs, [])

  function daysUntil(dateString) {
    function changeFormat(dateString) {
      // Séparer la chaîne de date en trois parties: jour, mois et année
      var parts = dateString.split('/');
      var day = parts[0];
      var month = parts[1];
      var year = parts[2];
    
      // Réassembler les parties dans l'ordre "aaaa/mm/jj"
      return year + '/' + month + '/' + day;
    }

    let newDate = changeFormat(dateString)
    // Remplacer les barres obliques par des tirets dans la chaîne de date
    newDate = newDate.replace(/\//g, '-');
    // Parser la chaîne de date en timestamp
    var targetTimestamp = Date.parse(newDate);
    // Obtenir la date d'aujourd'hui en timestamp
    var currentTimestamp = Date.now();
  
    // Calculer la différence entre les deux timestamps en milisecondes
    var timeDifference = targetTimestamp - currentTimestamp;
  
    // Calculer le nombre de jours restants en divisant la différence par la durée d'un jour en milisecondes
    // et en arrondissant au nombre entier inférieur
    return Math.floor(timeDifference / 86400000);
  }

  const [menuOne, setMenuOne] = useState(false)
  const [sessionChoosed, setSessionChoosed] = useState('')
  const [valueDate, setValueDate] = React.useState(dayjs(new Date()))
  const [valueDateFin, setValueDateFin] = React.useState(dayjs(new Date()))
  const [openDate, setOpenDate] = React.useState(false)
  const [apprenantDaysRemain, setApprenantDaysRemain] = useState(false)

  const handleClickOpenDate= () => {
    setOpenDate(true);
  }
  
  const handleCloseDate = () => {
    setOpenDate(false);
  }

  const handleChangeDate = (newValue) => {
    setValueDate(newValue);
  }

  const handleChangeDateFin = (newValue) => {
    setValueDateFin(newValue);
  }

  const fetchUpdateFacturation = () => {

    const formatDate = date => {
      let d = new Date(date),
          month = `0${d.getMonth() + 1}`.slice(-2),
          day = `0${d.getDate()}`.slice(-2),
          year = d.getFullYear();
    
      return `${year}-${month}-${day}`;
    };

    const debutSession = formatDate(valueDate)
    const finSession = formatDate(valueDateFin)

    axios.put(`${URL}facturation-formations/${clickedFactureCollabs}`, {
      data: {
        start_session: debutSession,
        end_session: finSession,
      },
    },
    {
      headers: {
        Authorization:
          `Bearer ${session.jwt}`,
        },
    })
    .then((response) => {
      fetchFactureCollabs()
      toast(`Dates modifiée`, {
        position: "bottom-right",
        type: "success",
        theme:"colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    })
    .catch((error) => {
      console.error(error);
      toast(`Une erreur s'est produite ! ${error.message}`, {
        position: "bottom-right",
        type: "error",
        theme:"colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    })
  }

  const factureToShow = () => {
    if (factureCollabs.length > 0) {
      const factureFounded = factureCollabs.find(facture => facture.id === clickedFactureCollabs)
      return factureFounded
    } else {
      return false
    }
  }

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  })

  return (
    <AccountAdminCollabsStyled>
      <Header bcgColor={'#fff'}/>
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
      {factureCollabs === 'loading' ? 
        <Skeleton className="skeleton-candidat" />
        :
          <>
            <div className="financements-list">
            <div 
            className='drop-down-1' 
            onClick={() => {
              setMenuOne(!menuOne)
            }}>
            <h4 className="bold">Formations en cours de financement</h4>
            <div className="bold info-dyna">{factureCollabs.length} dossier{factureCollabs.length > 1 ? 's' : ''} disponible{factureCollabs.length > 1 ? 's' : ''}</div>
            <div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
              {menuOne ? 
              <MdArrowDropUp className="arrow-down" />
              :
              <MdArrowDropDown className="arrow-down"/>
              }
            </div>
            </div>
            {factureCollabs.length > 0 && menuOne &&
            <div className='formations-wrapper'>
            {factureCollabs.map((facture, index) => (
              <div 
                key={index} 
                onClick={() => {
                  changeClickedFactureCollabs(facture.id)
                  fetchGetInscriptionsFromSessions(facture.attributes.createdAt, facture.attributes.formation.data.id, facture.attributes.entreprise.data.id)
                  setMenuOne(!menuOne)
                  setApprenantDaysRemain(daysUntil(facture.attributes.start_session))
                }}
                className="links-facture"
              >
                <p className="list-paragraphe"><FaFileAlt />n°{facture.id + " " + facture.attributes.nom_formation}</p>
                {facture.attributes.start_session !== null ?
                  <span className='days-remain-factures bold'>
                    {Math.ceil(daysUntil(facture.attributes.start_session)) <= 0 ?
                    <>
                    J
                    </>
                    :
                    <>
                    J - {Math.ceil(daysUntil(facture.attributes.start_session))}
                    </>
                    }
                  </span>
                  :
                  <span className='days-remain-factures bold'>à déterminer</span>
                }
              </div>
            ))}
            </div>
            }
          </div>
          <div className="choosed-financement">
            {factureToShow() !== undefined && <>
              {factureToShow() === false ?
                <p>Choisissez un financement pour afficher son statut.</p>
                :
                <div className='step-container'>
                  <h2 className='bold'>Demande de financement n°{factureToShow().id}</h2>
                  <h3 className='bold'>Informations disponibles pour cette formation</h3>
                  <ul className='info-list'>
                    <li><FaGenderless /> Entreprise : <span className='bold'> {factureToShow().attributes.entreprise.data.attributes.Nom_entreprise}</span></li>
                    <li><FaGenderless /> Nom de la formation : <span className='bold'> {factureToShow().attributes.nom_formation}</span></li>
                    <li><FaGenderless /> Demande effectuée le :  <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{factureToShow().attributes.publishedAt}</Moment></span></li>
                    <li><FaGenderless /> Prix de la formation : <span className='bold'>{euroFormat.format(Number(factureToShow().attributes.Prix))} TTC</span></li>
                    <li><FaGenderless /> Nombre d'inscrits : <span className='bold'>{Number(factureToShow().attributes.nombre_particulier)}</span></li>
                    <li><FaGenderless /> CA potentiel : <span className='bold'>{euroFormat.format(Number(factureToShow().attributes.Prix) * Number(factureToShow().attributes.nombre_particulier))} TTC</span></li>
                    {factureToShow().attributes.opco.data !== null && factureToShow().attributes.opco.data.attributes.preuve_financement.data !== null ?
                    <li><FaGenderless /> Document de financement : <span className='bold'><a href={`${urlBackEndSave}${factureToShow().attributes.opco.data !== null && factureToShow().attributes.opco.data.attributes.preuve_financement.data.attributes.url}`} target='_blank' rel='noreferrer' className='bold'>Vérifier le document</a></span></li>
                    :
                    <li><FaGenderless /> Financement bancaire : <span className='bold'>Merci de vérifier les virement ou paiement récents</span></li>
                    }
                    {factureToShow().attributes.start_session !== null && factureToShow().attributes.end_session !== null ?
                    <li><FaGenderless /> Date de session : <span className='bold'>Du <Moment format="DD/MM/YYYY" className="bold">{factureToShow().attributes.start_session}</Moment> au <Moment format="DD/MM/YYYY" className="bold">{factureToShow().attributes.end_session}</Moment></span></li>
                    :
                    <li><FaGenderless /> Date de session : <span className='bold btn-date' onClick={handleClickOpenDate}>Choisir les dates</span></li>
                    }
                  </ul>
                  {factureToShow().attributes.start_session !== null && factureToShow().attributes.end_session !== null && factureToShow() !== false &&
                  <div className='section-collab'>
                    {factureToShow() !== false &&
                      <>
                        <div className="wrapper-collabs">
                        <div className={factureToShow().attributes.lms_nom === 'univo' ? 'lms-title orange bold' : 'lms-title blue bold'}>Formation sur : {factureToShow().attributes.lms_nom}</div>
                        {factureToShow().attributes.start_session !== false &&
                          <span className='days-remain bold'>
                            {Math.ceil(daysUntil(factureToShow().attributes.start_session)) <= 0 ?
                            <>
                            J
                            </>
                            :
                            <>
                            J - {Math.ceil(daysUntil(factureToShow().attributes.start_session))}
                            </>
                            }
                          </span>
                        }
                        <section className='section-top'>
                          <div className='bold center'>Collaborateurs :</div>
                          <div className='bold center'>Comptes WEFOR : </div>
                          <div className='bold center'>Comptes LMS : </div>
                        </section>
                          {inscriptionsClickedSession.length > 0 && 
                            <div className="card-wrapper">
                              {factureToShow().attributes.lms_nom === 'univo' ?
                               <CollabsCardUnivo collabsFromState={inscriptionsClickedSession} entreprise={factureToShow().attributes.entreprise} dateF={factureToShow().attributes.start_session}/>
                               : 
                               <>360 learning</>
                               }
                            </div>
                          }
                        </div>
                      </>
                    }
                  </div>
                }
                  <Dialog open={openDate} onClose={handleCloseDate}>
                    <DialogTitle>Merci de choisir les dates de formation en rapport avec la demande de l'entreprise</DialogTitle>
                    <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={styleWdate}>
                      <MobileDatePicker
                        label="Début"
                        inputFormat="MM/DD/YYYY"
                        value={valueDate}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <MobileDatePicker
                        label="Fin"
                        inputFormat="MM/DD/YYYY"
                        value={valueDateFin}
                        onChange={handleChangeDateFin}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      </div>
                    </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDate}>Annuler</Button>
                      <Button onClick={() => {
                        handleCloseDate()
                        setSessionChoosed(factureToShow())
                        fetchUpdateFacturation()
                      }}>Valider</Button>
                    </DialogActions>
                  </Dialog>
                </div>
              }
            </>}
          </div>
        </>
      }
      </section>
      </main>
    </AccountAdminCollabsStyled>
  )
}

AccountAdminCollabs.propTypes = {
}

export default AccountAdminCollabs