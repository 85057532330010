import { connect } from 'react-redux'

import AccountAdminApprenant from '../../pages/AccountAdminApprenant'
import { saveDataApprenantFromCSV } from '../../actions/apprenant' 

const mapStateToProps = (state) => ({
	apprenantFromCSV: state.apprenant.apprenantFromCSV
})

const mapDispatchToProps = (dispatch) => ({
	saveDataApprenantFromCSV : (data) => {
        dispatch(saveDataApprenantFromCSV(data));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountAdminApprenant)