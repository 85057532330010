/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Progress } from 'semantic-ui-react'
import {AiOutlineClose} from "react-icons/ai"
import { AiFillFilePdf } from "react-icons/ai";
import { saveAs } from 'file-saver'
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  LabelList,
  Legend
} from "recharts";
import { FaLongArrowAltDown, FaLongArrowAltUp, FaArrowsAltH, FaAngleDown, FaAngleUp } from "react-icons/fa";
import axios from 'axios'
import * as qs from 'qs'

import urlBackEnd from '../../assets/js/urlBackEnd';
import urlBackEndSave from '../../assets/js/urlBackEndSave';

/**** Import d'images' ****/
import userimg from '../../assets/img/picto/utilisateur.png'

/**** Import des css ****/
import CandidatCardStyled from './CandidatCardStyled'
import { selectClasses } from '@mui/material'
const URL = urlBackEnd

const CandidatCard = ({ 
  candidat, 
  fetchContactCandidat,
  fetchCategorieOcean,
  categorieOcean,
  selectionJobOffer,
  userInfo,
  myScoresFormation,
  testOceanManager
}) => {
  useEffect(() => {
    if (categorieOcean.length === 0) {
      fetchCategorieOcean()
    }
    }, []);
  
  const [showModalValidation, setShowModalValidation] = useState(false)
  const [showModalTest, setShowModalTest] = useState(false)
  const [showDropDown, setShowDropDown] = useState(false)
  const [compareDatas, setCompareDatas] = useState(false)
  const [indexOfManager, setIndexOfManager] = useState(0)
  const [nameOfManager, setNameOfManager] = useState('')
  const [managerFirst, setManagerFirst] = useState('')
  const [cardScore, setCardScore] = useState('')
  const [cardScoreG, setCardScoreG] = useState('')
  const [testOcean, setTestOcean] = useState([])
  const [thisUser, setThisUser] = useState([])

  useEffect(() => {

    if (selectionJobOffer.attributes.formation.data !== null ) {
    const queryFilterPart = qs.stringify({
      filters: {
        particulier: {
          id: candidat.id
        },
        formation: {
          id: selectionJobOffer.attributes.formation.data.id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}score-formations?${queryFilterPart}`, {})
      .then((response) => {
        if(cardScore === '') {
          setCardScore(response.data.data)
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }
  , [])

  useEffect(() => {
    const queryFilterPart = qs.stringify({
      filters: {  
        particulier: {
          id: candidat.id
        },
        session_id: 'recrutement'
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}score-formations?${queryFilterPart}`, {})
      .then((response) => {
        if(cardScoreG === '') {
          setCardScoreG(response.data.data)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  , [])

  useEffect(() => {
    const arrayData = []
    arrayData.push(candidat.attributes.test_ocean.data)
    setTestOcean(arrayData)
  }
  , [setTestOcean, candidat.attributes.test_ocean.data])


  const findFirstManager = () => {
    const nomOfManager = selectionJobOffer.attributes.nom_manager
    const prenomOfManager = selectionJobOffer.attributes.prenom_manager
    const fullNameManager = nomOfManager + ' ' + prenomOfManager

    if(fullNameManager !== ' ') {
      if(fullNameManager !== 'null null') {
      const managerToOffer = testOceanManager.find(test => (test.attributes.nom + ' ' + test.attributes.prenom) === fullNameManager)
      const managerToOfferIndex = testOceanManager.findIndex(test => (test.attributes.nom + ' ' + test.attributes.prenom) === fullNameManager)
      setManagerFirst(managerToOffer)
      setIndexOfManager(managerToOfferIndex)
      setNameOfManager(fullNameManager)
      } else {
        setManagerFirst('indéfinit')
      }
    } else {
      setManagerFirst('indéfinit')
    }
  }


  const candidatFiche = candidat.attributes

  const dataToMap = (index) => {
  const testManager = testOceanManager !== null && testOceanManager !== undefined && testOceanManager.length > 0 && testOceanManager[index].attributes

  const data = [
    {
      subject: "Ouverture d\'ésprit",
      A: testOcean[0] !== null && Math.round(testOcean[0].attributes.ouverture_esprit / (10 * 5) * 100),
      B: testManager !== undefined && Math.round(testManager.ouverture_esprit / (10 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Conscience",
      A: testOcean[0] !== null && Math.round(testOcean[0].attributes.conscience / (9 * 5) * 100),
      B: testManager !== undefined && Math.round(testManager.conscience / (9 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Extraversion",
      A: testOcean[0] !== null && Math.round(testOcean[0].attributes.extraversion / (8 * 5) * 100),
      B: testManager !== undefined && Math.round(testManager.extraversion / (8 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Agréabilité",
      A: testOcean[0] !== null && Math.round(testOcean[0].attributes.altruisme / (10 * 5) * 100),
      B: testManager !== undefined && Math.round(testManager.altruisme / (10 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Névrosisme",
      A: testOcean[0] !== null && Math.round(testOcean[0].attributes.nevrosisme / (8 * 5) * 100),
      B: testManager !== undefined && Math.round(testManager.nevrosisme / (8 * 5) * 100),
      fullMark: 100
    }
  ];

  return data
  }

  const findScore = () => {
    if (cardScore.length > 0) {
      return cardScore
    } else if (cardScoreG.length > 0) {
      return cardScoreG
    } else {
      return undefined
    }
  }

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  const alreadyContacted = () => {
    const offreContacter = candidat.attributes.offre_emploi_contacters.data
    if (offreContacter !== null) {
      const foundedOffer = offreContacter.find(item => item.id === selectionJobOffer.id)
      if (foundedOffer && Object.keys(foundedOffer).length > 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <CandidatCardStyled bcgColor={alreadyContacted() ? '#e1efe7' : '#fff'} showModalValidation={showModalValidation}>
      {findScore() === undefined ? 
        <img src={userimg} alt="profil candidat" className="img-top"/>
        :
        <>
        {selectionJobOffer.attributes.secteurs.data[0].attributes.nom === 'Assurance' ?
        <div className="circle-wrapper img-top">
        <CircularProgress className="progress-circle" color='success' size={90} variant="determinate" value={findScore()[0].attributes.session_id === 'recrutement' ? (findScore()[0].attributes.score/20*100) : findScore()[0].attributes.score} />
        <div className="score-div">
          <span className='top bold'>{findScore()[0].attributes.score}</span><span className='middle bold'>/</span><span className='bottom bold'>{findScore()[0].attributes.session_id === 'recrutement' ? '20' : '100'}</span>
        </div>
        </div>
        :
          <img src={userimg} alt="profil candidat" className="img-top"/>
        }
        </>
      }
      <p className="level border">Dernier emploi: <span className="bold">{candidatFiche.dernier_emploi}</span></p>
      <p className="level border">Niveau d'étude: <span className="bold">{candidatFiche.niveau}</span></p>
      {alreadyContacted() ? 
      <p className="info-candidat">
        <span className="bold">{candidatFiche.user.data.attributes.nom}</span>
        <span className="bold">{candidatFiche.user.data.attributes.prenom}</span>
        <span className="bold"><a href={`tel:+33${candidatFiche.user.data.attributes.telephone.slice(1)}`} className="bold">{candidatFiche.user.data.attributes.telephone}</a></span>
      </p>
      :
      <p className="level"><span className="line">Ville du candidat </span><span className="bold">{candidatFiche.ville}</span></p>
      }
      {testOcean[0] && testOcean.data !== null ?
      <button 
      className="btn-blue" 
      onClick={() => {
        setShowModalTest(true);
        findFirstManager()
      }}>Personnalité</button>
      :
      <div className="btn-replace"></div>
      }
      {alreadyContacted() ?
        <button 
        className="btn-orange" 
        onClick={() => {
          if (candidat.attributes.cv.data !== null) { savePdf(candidat.attributes.cv.data.attributes.url ,candidat.attributes.cv.data.attributes.name) }
        }}>CV <AiFillFilePdf className="pdf-icon"/></button>
        :
        <button 
        className="btn-orange" 
        onClick={() => {
          fetchContactCandidat(candidat) 
          if (candidat.attributes.cv.data !== null) { savePdf(candidat.attributes.cv.data.attributes.url ,candidat.attributes.cv.data.attributes.name) }
        }}>Voir le profil</button>
      }

      {showModalTest && 
        <div className="modal-result-wrapper">
          <div className="modal-result">
          <section className="dropdown-wrapper">
          <h2 className='bold'>Résultats du candidat au test Big Five</h2>
          {managerFirst !== 'indéfinit' && managerFirst !== undefined && managerFirst.attributes !== undefined ?
            <p className="top-msg">Le manager définit pour ce poste est : {managerFirst.attributes.nom + ' ' + managerFirst.attributes.prenom}</p>
            :
            <p className="top-msg">Vous n'avez pas définit de manager pour cette offre</p>
          }
          <p></p>
          {testOceanManager.length > 0 &&
            <FormControl fullWidth className="input-top-manager">
            <InputLabel id="demo-simple-select-label">Résultat</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={nameOfManager}
                label="Résultat"
                onChange={(event) => {
                  setNameOfManager(event.target.value)
                }}
              >
                {testOceanManager.map((manager, index) => (
                  <MenuItem 
                    key={index} 
                    value={manager.attributes.nom + ' ' + manager.attributes.prenom}
                    onClick={(event) => {
                      setIndexOfManager(index)
                    }}
                  >{selectionJobOffer.attributes.nom_manager === manager.attributes.nom && selectionJobOffer.attributes.prenom_manager === manager.attributes.prenom ?
                  <span style={{marginRight: '10px'}}>Manager définit pour ce poste :</span>
                  :
                  ''
                  }
                    {' ' + manager.attributes.nom} {manager.attributes.prenom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          <RadarChart
            cx={285}
            cy={200}
            outerRadius={150}
            width={572}
            height={450}
            data={dataToMap(indexOfManager)}
            fillOpacity={1}
          >
            <PolarGrid gridType='circle'/>
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis domain={[0, 100]} angle={125} stroke="#4d4d4d"/>
            <Radar
              name="Candidat"
              dataKey="A"
              stroke="#6AAF8B"
              fill="#B4D7C4"
              fillOpacity={0.6}
              
            >
              
            </Radar>
            {nameOfManager !== '' && 
            <Radar
              name="Manager"
              dataKey="B"
              stroke="#6399ce"
              fill="#6399ce"
              fillOpacity={0.6}
              
            ></Radar>
            
          } 
          <Legend />
          </RadarChart>
          <div className="know-more" onClick={() => setShowDropDown(!showDropDown)}>En savoir plus {!showDropDown ? <FaAngleDown /> : <FaAngleUp />}</div>
          {showDropDown && 
          <div className="result-txt-wrapper">
            {(testOcean[0].attributes.ouverture_esprit / (10 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Ouverture, originalité, ouverture d’esprit <span>({Math.round((testOcean[0].attributes.ouverture_esprit / (10 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[0].attributes.bas}</p>
            </>
            }
            {(testOcean[0].attributes.ouverture_esprit / (10 * 5) * 100) >= 31 &&  (testOcean[0].attributes.ouverture_esprit / (10 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Ouverture, originalité, ouverture d’esprit <span>({Math.round((testOcean[0].attributes.ouverture_esprit / (10 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[0].attributes.moyen}</p>
            </>
            }
            {(testOcean[0].attributes.ouverture_esprit / (10 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Ouverture, originalité, ouverture d’esprit <span>({Math.round((testOcean[0].attributes.ouverture_esprit / (10 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[0].attributes.haut}</p>
            </>
            }
            {(testOcean[0].attributes.conscience / (9 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Conscience, contrôle, contrainte <span>({Math.round((testOcean[0].attributes.conscience / (9 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[1].attributes.bas}</p>
            </>
            }
            {(testOcean[0].attributes.conscience / (9 * 5) * 100) >= 31 &&  (testOcean[0].attributes.conscience / (9 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Conscience, contrôle, contrainte <span>({Math.round((testOcean[0].attributes.conscience / (9 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[1].attributes.moyen}</p>
            </>
            }
            {(testOcean[0].attributes.conscience / (9 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Conscience, contrôle, contrainte <span>({Math.round((testOcean[0].attributes.conscience / (9 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[1].attributes.haut}</p>
            </>
            }
            {(testOcean[0].attributes.extraversion / (8 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Extraversion, énergie, enthousiasme <span>({Math.round((testOcean[0].attributes.extraversion / (8 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[2].attributes.bas}</p>
            </>
            }
            {(testOcean[0].attributes.extraversion / (8 * 5) * 100) >= 31 &&  (testOcean[0].attributes.extraversion / (8 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Extraversion, énergie, enthousiasme <span>({Math.round((testOcean[0].attributes.extraversion / (8 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[2].attributes.moyen}</p>
            </>
            }
            {(testOcean[0].attributes.extraversion / (8 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Extraversion, énergie, enthousiasme <span>({Math.round((testOcean[0].attributes.extraversion / (8 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[2].attributes.haut}</p>
            </>
            }
            {(testOcean[0].attributes.altruisme / (10 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Agréabilité, altruisme, affection <span>({Math.round((testOcean[0].attributes.altruisme / (10 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[3].attributes.bas}</p>
            </>
            }
            {(testOcean[0].attributes.altruisme / (10 * 5) * 100) >= 31 &&  (testOcean[0].attributes.altruisme / (10 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Agréabilité, altruisme, affection <span>({Math.round((testOcean[0].attributes.altruisme / (10 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[3].attributes.moyen}</p>
            </>
            }
            {(testOcean[0].attributes.altruisme / (10 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Agréabilité, altruisme, affection <span>({Math.round((testOcean[0].attributes.altruisme / (10 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[3].attributes.haut}</p>
            </>
            }
            {(testOcean[0].attributes.nevrosisme / (8 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Névrosisme, émotions négatives, négativité <span>({Math.round((testOcean[0].attributes.nevrosisme / (8 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[4].attributes.bas}</p>
            </>
            }
            {(testOcean[0].attributes.nevrosisme / (8 * 5) * 100) >= 31 &&  (testOcean[0].attributes.nevrosisme / (8 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Névrosisme, émotions négatives, négativité <span>({Math.round((testOcean[0].attributes.nevrosisme / (8 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[4].attributes.moyen}</p>
            </>
            }
            {(testOcean[0].attributes.nevrosisme / (8 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Névrosisme, émotions négatives, négativité <span>({Math.round((testOcean[0].attributes.nevrosisme / (8 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[4].attributes.haut}</p>
            </>
            }
          </div>
          }
        </section>
          <div className="icon-wrapper">
            <AiOutlineClose 
            className="close-icon"
            onClick={() => setShowModalTest(false)}
            />
            </div>
          </div>
        </div>
      }
    </CandidatCardStyled>
  )
}

CandidatCard.propTypes = {
}

export default CandidatCard