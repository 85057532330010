import { connect } from 'react-redux'

import JobLi from '../../../components/Header/JobLi'

import { setSubmenuJobSecteurActiv, setSubmenuJobDomaineActiv, setSubmenuJobFormationActiv, setFilter } from '../../../actions/menu'

const mapStateToProps = (state) => ({
    submenuJobSecteurActiv: state.menu.submenuJobSecteurActiv,
    submenuJobDomaineActiv: state.menu.submenuJobDomaineActiv,
    submenuJobFormationActiv: state.menu.submenuJobFormationActiv,
    secteurs: state.menu.secteurs,
    currentProfilUser: state.menu.currentProfilUser,
    secteursData: state.menu.secteursData,
})

const mapDispatchToProps = (dispatch) => ({
    setSubmenuJobSecteurActiv: (name) => {
        dispatch(setSubmenuJobSecteurActiv(name));
      },
    setSubmenuJobDomaineActiv: (name) => {
        dispatch(setSubmenuJobDomaineActiv(name));
    },
    setSubmenuJobFormationActiv: (name) => {
        dispatch(setSubmenuJobFormationActiv(name));
    },
    setFilter: (name, title) => {
        dispatch(setFilter(name, title));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(JobLi)