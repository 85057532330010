import { connect } from 'react-redux'

import HeaderMobile from '../../components/HeaderMobile'

import { changeCurrentProfilUser } from '../../actions/menu'
import { changeSigninStep } from '../../actions/signin'
import { openModal } from '../../actions/modal'
import { disconnectUser } from '../../actions/login'

const mapStateToProps = (state) => ({
    currentProfilUser: state.menu.currentProfilUser,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    windowTop : state.actions.windowTop,
    user: state.login.session.user,
})

const mapDispatchToProps = (dispatch) => ({
    changeCurrentProfilUser: (name) => {
        dispatch(changeCurrentProfilUser(name));
    },
    changeSigninStep: (number) => {
        dispatch(changeSigninStep(number));
    },
    openModal: (name) => {
        dispatch(openModal(name));
    },
    disconnectUser: () => {
        dispatch(disconnectUser());
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile)