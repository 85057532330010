import { 
    SAVE_USER_SCORE,
    SAVE_TEMPLATE_LMS,
    SAVE_COURSES_LMS,
    SAVE_USER_LMS_DATA
  } from "../actions/lms";
  
  export const initialState = {
    userScore: [],
    templatesLms: false,
    coursesLms: [],
    stepForModalLMSSession: 1,
    userDataLms: 'loading'
  };
  
  // reducer = traducteur d'une intention/action vers une modification du state
  const lms = (state = initialState, action = {}) => {
    switch (action.type) {
        case SAVE_USER_SCORE:
          return {
            ...state,
            userScore: action.data,
          };
        case SAVE_TEMPLATE_LMS:
          return {
            ...state,
            templatesLms: action.data,
          };
        case SAVE_COURSES_LMS:
          return {
            ...state,
            coursesLms: action.data,
          };
        case SAVE_USER_LMS_DATA:
          return {
            ...state,
            userDataLms: action.data,
          };
      default:
        return state;
    }
  };
  
  export default lms;