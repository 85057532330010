import { connect } from 'react-redux'

import StepOneE from '../../../components/FinancementSteps/StepOneE'
import { fetchUploadEdofFile, changeStepValue, changeFactureStatut } from '../../../actions/course'


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    fetchUploadEdofFile: (file, id, mode) => {
        dispatch(fetchUploadEdofFile(file, id, mode));
    },
    changeStepValue: (number) => {
        dispatch(changeStepValue(number));
    },
    changeFactureStatut: (step, mode, link) => {
        dispatch(changeFactureStatut(step, mode, link));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(StepOneE)