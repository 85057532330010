import styled from 'styled-components';

const CollabsCardUnivoStyled = styled.article`
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	margin: 5px 0 0;
	color: #4d4d4d;
	position: relative;

	.bottom-section {
		width: 100%;

		.infos-session-wrapper {
			display: flex;
			width: 100%;
			flex-wrap: wrap;

			.bottom {
				div {
					height: 100%;
				}

				.btn-action {
					padding: 5px;
					width: 100%;
					min-height: 43px;
					max-height: 43px;
					border: 0;
					background-color: #6aaf8b;
					border-radius : 10px;
					height: 100%;
					color: #fff;
					cursor: pointer;
					transition: all .3s ease;

					&:hover {
						opacity: .7;
					}
				}

				.center {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.no-account {
					background-color: #f8dada;
					border-radius : 10px;
					height: 100%;
					padding: 5px;
					min-height: 43px;
					max-height: 43px;

					&.center {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				.done-task {
					background-color: #e1efe7;
					border-radius : 10px;
					height: 100%;
					padding: 5px;
					min-height: 43px;
					max-height: 43px;

					&.center {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.top, .bottom {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				border-top: 1px solid #4d4d4d;
				border-bottom: 1px solid #4d4d4d;

				div {
					width: calc(100% / 3);
					text-align: center;
					padding: 5px 2px;

					&.border {
						border-right: 1px solid #4d4d4d;
						border-left: 1px solid #4d4d4d;
						margin: 10px 0;
						padding: 0;
					}

					&:first-child {
						border-left: 0;
					}

					&:last-child {
						border-right: 0;
					}
				}

				.btn-univo {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					background-color: #f8e8e3;
					color: #d47558;
					border-radius: 10px;
					cursor: pointer;
					transition: all .3s ease;
					min-height: 43px;
					max-height: 43px;
					margin: 0 0 5px 0;

					&:hover {
						opacity: .7;
					}
				}

				.btn-360 {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					background-color: #dbe8f4;
					color: #6399ce;
					border-radius: 10px;
					cursor: pointer;
					min-height: 43px;
					max-height: 43px;
					transition: all .3s ease;
					margin: 0 0 5px 0;

					&:hover {
						opacity: .7;
					}
				}
			}

			.bottom {
				border: 0;
			}
		}
	}

	.bottom-section {
		width: 100%;
	}

	.MuiTypography-root{
		margin-bottom: 8px !important;
	}

	.action-wrapper {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		border-top: 1px solid #d4d4d4;
		margin: 0 0 5px 0;
		padding: 5px 0 0 0;

		div {
			width: calc(100% / 3);
			text-align: center;
			padding: 5px 2px;

			.btn-univo {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				background-color: #f8e8e3;
				color: #d47558;
				border-radius: 10px;
				cursor: pointer;
				transition: all .3s ease;
				min-height: 43px;
				max-height: 43px;
				margin: 0 0 5px 0;
				border: 0;

				&:hover {
					opacity: .7;
				}
			}

			.btn-action {
				padding: 5px;
				width: 100%;
				min-height: 43px;
				max-height: 43px;
				border: 0;
				background-color: #6aaf8b;
				border-radius : 10px;
				height: 100%;
				color: #fff;
				cursor: pointer;
				transition: all .3s ease;

				&:hover {
					opacity: .7;
				}
			}
		}
	}
`;

export default CollabsCardUnivoStyled;