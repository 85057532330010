/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"

/**** Import des css ****/
import StepThreeStyled from './StepThreeStyled'


const StepThreeE = ({ thisFinancement }) => {
  return (
    <StepThreeStyled>
      {thisFinancement.attributes.formation.data.attributes.lms_formation_id === 'univo' ?
        <a href='https://wefor.cloudplateforme.com/v2-admin/dashboard' target="_blank" rel="noreferrer"><button className='btn-univo bold'>Accès à votre session de formation</button></a>
        :
        <>
        {thisFinancement.attributes.formation.data.attributes.lms_formation_id === 'formapro' ?
          <Link to='/connexion-lms'>Accès à votre session de formation</Link> 
        :
        <a href='https://elearning.wefor.fr/home' target="_blank" rel="noreferrer"><button className='btn-360 bold'>Accès à votre session de formation</button></a>
        }
        </>
      }
    </StepThreeStyled>
  )
}

export default StepThreeE