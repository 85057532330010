import styled from 'styled-components';

const DownloadBookletStyled = styled.main`
    button {
        background-color: #D47558;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 0;
        padding: 10px;
        cursor: pointer;
        transition: all .3s ease;

        svg {
            margin-right: 10px;
        }

        &:hover {
            background-color: #94513d;
        }
    }
`;

export default DownloadBookletStyled;