import { connect } from 'react-redux'

import CourseDoneCard from '../../components/CourseDoneCard'
import { toggleModalSatisfaction } from '../../actions/modal'
import { fetchPostQS } from '../../actions/course'

const mapStateToProps = (state) => ({
    activCourseId: state.course.activCourseId,
    userInfo: state.login.userInfo,
    isModalSatisfactionOpen: state.modal.isModalSatisfactionOpen,
    session: state.login.session,
    getStateQs: state.course.getStateQs,
})

const mapDispatchToProps = (dispatch) => ({
    toggleModalSatisfaction: (bool) => {
        dispatch(toggleModalSatisfaction(bool));
    },
    fetchPostQS: (courseId, factureId) => {
        dispatch(fetchPostQS(courseId, factureId));
      },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(CourseDoneCard)