/**** Import des modules ****/
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md"
import { BiLoaderAlt } from "react-icons/bi"
import { FaCalendarTimes, FaHandPaper, FaBook, FaPlus } from "react-icons/fa"
/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import CourseDoneCard from '../../containers/CourseDoneCard'
import ScoreCourse from '../../containers/ScoreCourse';
import ModalSatisfaction from '../../containers/ModalSatisfaction'

/**** Import des css ****/
import AccountCourseStyled from './AccountCourseStyled'

const AccountCourse = ({
  user,
  userInfo,
  userRole,
  isLogged,
  fetchAccountFormations,
  formationsEnCours,
  formationsTerminee,
  formationsDemande,
  fetchParticuliers,
  particuliers,
  fetchUserScore,
  menuFormation,
  fetchMySalarie,
  setMenuFormation,
  collabCoursesOn,
  collabCoursesOff,
  fetchGetSessionsOnOff,
  factureEStarted,
  fetchGetInscriptionsFromSessions,
  inscriptionsClickedSession,
  isModalSatisfactionOpen,
  toggleModalSatisfaction,
  fetchFactureP,
  openModal,
  setCurrentUrl,
  fetchFactureCourse,
  factureCourse
}) => {

  let location = useLocation().pathname

  useEffect(() => {
    if (!isLogged) {
      openModal('login')
    } else {
      fetchGetSessionsOnOff()
      fetchUserScore()
    }
    setCurrentUrl(location)
  }
  , [])

  useEffect(() => {
    if (isLogged && particuliers.length === 0 && userRole === 'entreprise') {
      fetchParticuliers()
    }
    if(isLogged && userRole !== 'entreprise') {
      fetchFactureCourse()
    }
  }, []);

  const [entrepriseSalarie, setEntrepriseSalarie] = useState('');
  const [sessionChoosed, setSessionChoosed] = useState('');
  const [menuOne, setMenuOne] = useState(false);
  const [menuTwo, setMenuTwo] = useState(false);
  const [menuThree, setMenuThree] = useState(false);

  useEffect(() => {
    if (isLogged && userRole === 'entreprise') {
      fetchMySalarie()
    }
  }, []);


  let yourDate = new Date()

  const endedCourse = (factureCourse) => {
    const tabToRetrun = []
    factureCourse.map((item) => {
      if(item.attributes.step > 1 && yourDate.toISOString().split('T')[0] >= item.attributes.end_session) {
        tabToRetrun.push(item)
      }
    })

    return tabToRetrun
  }

  const onGoingCourse = (factureCourse) => {
    const tabToRetrun = []
    factureCourse.map((item) => {
      if(item.attributes.step > 1 && yourDate.toISOString().split('T')[0] <= item.attributes.end_session && yourDate.toISOString().split('T')[0] >= item.attributes.start_session) {
        tabToRetrun.push(item)
      }
    })

    return tabToRetrun
  }

  const demandeCourse = (factureCourse) => {
    const tabToRetrun = []
    factureCourse.map((item) => {
      if(item.attributes.step === 1) {
        tabToRetrun.push(item)
      } else if (item.attributes.step === 2 && yourDate.toISOString().split('T')[0] <= item.attributes.start_session) {
        tabToRetrun.push(item)
      }

    })

    return tabToRetrun
  }

  return (
    <AccountCourseStyled>
      <Header bcgColor={'#fff'}/>
      
      <main className="page-wrapper">
      <AccountMenu />
      {isLogged &&
      <>
      { factureCourse.length > 0 ?
      <>
      {userRole === 'particulier' &&
        <section className="right-wrapper">
          <div className="secteur-choice">
            <div 
              className='drop-down-1' 
              onClick={() => {
                setMenuOne(!menuOne)
              }}>
              <h4 className="bold">Choisissez un statut</h4>
              <div className="bold info-dyna"></div>
              <div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
                {menuOne ? 
                  <MdArrowDropUp className="arrow-down" />
                :
                  <MdArrowDropDown className="arrow-down"/>
                }
            </div>
            </div>
            {menuOne &&
            <div className='formations-wrapper'>
              <div onClick={() => {
                setMenuFormation('encours')
              }}>
                <p className="list-paragraphe"><BiLoaderAlt /> Formations en cours</p>
                <span className="bold">Afficher</span>
              </div>
              <div onClick={() => {
                setMenuFormation('terminée')
              }}>
                <p className="list-paragraphe"><FaCalendarTimes />Formations terminées</p>
                <span className="bold">Afficher</span>
              </div>
              <div onClick={() => {
                setMenuFormation('demandes')
              }}>
                <p className="list-paragraphe"><FaHandPaper />Demandes de formation</p>
                <span className="bold">Afficher</span>
              </div>
            </div>
            }
          </div>
          {menuFormation === 'encours' &&
          <>
          <h2 className='bold'>Vos formations en cours<span> <div className="separator-blue"></div></span></h2>
          {onGoingCourse(factureCourse) !== undefined && onGoingCourse(factureCourse).length > 0 && onGoingCourse(factureCourse).map((formation) => (
            <CourseDoneCard key={formation.id} courseId={formation.id} {...formation} statut={menuFormation} fromForm='ongoing'/>
          ))}
          {onGoingCourse(factureCourse) !== undefined && onGoingCourse(factureCourse).length === 0 && 
            <p>Vous n'avez aucune formation en cours !<Link to="/formation" className="btn-formation">Voir toutes les formations</Link></p>
          }
          </>
          }
          
          {menuFormation === 'terminée' &&
          <>
          <h2 className="padding-top-title bold">Vos formations terminées<span> <div className="separator-blue"></div></span></h2>
          {endedCourse(factureCourse) !== undefined && endedCourse(factureCourse).length > 0 && endedCourse(factureCourse).map((item) => (
            <CourseDoneCard key={item.id} courseId={item.attributes.formation.data.id} {...item} fromForm='end'/>
          ))}
          {endedCourse(factureCourse) !== undefined && endedCourse(factureCourse).length === 0 && 
            <p>Vous n'avez passé aucune formation.</p>
          }
          </>
          }
          {menuFormation === 'demandes' &&
          <>
          <h2 className="padding-top-title bold">Vos demandes de formation<span> <div className="separator-blue"></div></span></h2>
          {demandeCourse(factureCourse) !== undefined && demandeCourse(factureCourse).length > 0 && demandeCourse(factureCourse).map((formation) => (
            <CourseDoneCard key={formation.id} courseId={formation.id} {...formation} fromForm='ask'/>
          ))}
          {demandeCourse(factureCourse) !== undefined && demandeCourse(factureCourse).length === 0 && 
            <p>Vous n'avez fait aucune demande de formation.</p>
          }
          </>
          }
        </section>
      }
      </>
      :
      <section className="right-wrapper">
        <div className="demandes">
          <Link to="/formation">
          <div className='drop-down-1'>
            <h4 className="bold">Cherchez une formation</h4>
            <div className="bold info-dyna">84 formations disponibles</div>
            <div className='arrow-wrapper'>
              <FaPlus className="plus-orange" />
            </div>
          </div>
          </Link>
        </div>
      </section>
      }
      {userRole === 'entreprise' &&
        <section className="right-wrapper">
        {/*myEntreprisePart.length > 0 &&
        <>
        <div className="collabs">
          <div 
            className='drop-down-1' 
            onClick={() => {
              setMenuTwo(!menuTwo)
            }}>
            <h4 className="bold">Liste de collaborateurs</h4>
            <div className="bold info-dyna">{myEntreprisePart.length} salarié{myEntreprisePart.length > 1 ? 's' : ''}</div>
            <div className='arrow-wrapper' onClick={() => setMenuTwo(!menuTwo)}>
              {menuTwo ? 
                <MdArrowDropUp className="arrow-down" />
              :
                <MdArrowDropDown className="arrow-down"/>
              }
          </div>
          </div>
          {myEntreprisePart.length > 0 && menuTwo &&
          <div className='formations-wrapper'>
            {myEntreprisePart.map((collab, index) => (
              <div 
                key={collab.id} 
                className="bold link"
                onClick={() => {
                  setMenuTwo(false)
                  setSessionChoosed('')
                  setEntrepriseSalarie(collab)
                  fetchGetCollabCourses(collab.id)
                }}
              >
                  <p className="list-paragraphe"><FaBook />{collab.attributes.user.data.attributes.nom} {collab.attributes.user.data.attributes.prenom}</p>
                  <span className="bold">Voir les activités</span>
              </div>
            ))}
          </div>
          }
        </div>
        </>
        */}
        {factureEStarted.length > 0 ?
        <>
        <div className="collabs">
          <div 
            className='drop-down-1' 
            onClick={() => {
              setMenuThree(!menuThree)
            }}>
            <h4 className="bold">Liste des sessions</h4>
            <div className="bold info-dyna">{factureEStarted.length} en cours ou passé</div>
            <div className='arrow-wrapper' onClick={() => setMenuThree(!menuThree)}>
              {menuTwo ? 
                <MdArrowDropUp className="arrow-down" />
              :
                <MdArrowDropDown className="arrow-down"/>
              }
          </div>
          </div>
          {factureEStarted.length > 0 && menuThree &&
          <div className='formations-wrapper'>
            {factureEStarted.map((facture, index) => (
              <div 
                key={facture.id} 
                className="bold link"
                onClick={() => {
                  setMenuThree(false)
                  setEntrepriseSalarie('')
                  setSessionChoosed(facture)
                  fetchGetInscriptionsFromSessions(facture.attributes.createdAt, facture.attributes.formation.data.id, facture.attributes.entreprise.data.id)
                }}
              >
                  <p className="list-paragraphe"><FaBook />{facture.attributes.nom_formation}</p>
                  <span className="bold">{facture.attributes.start_session} / {facture.attributes.end_session}</span>
              </div>
            ))}
          </div>
          }
        </div>
        </>
        :
        <div>Inscrire mes collaborateurs à une formation</div>
        }
        {collabCoursesOn !== 'not loaded' && collabCoursesOff !== 'not loaded' &&
          <div className='section-collab'>
            {entrepriseSalarie !== '' &&
              <>
                <h3 className='collab-name bold'>Formations en cours de : {entrepriseSalarie.attributes.user.data.attributes.nom} {entrepriseSalarie.attributes.user.data.attributes.prenom}</h3>
                <>
              {collabCoursesOn.length > 0 ?
                <div className="wrapper-salarie">
                  {collabCoursesOn.map((formation) => (
                    <div key={formation.id} className="card-wrapper">
                      <CourseDoneCard key={formation.id} salarie={entrepriseSalarie} courseId={formation.id} {...formation} dontShowAccess={true}/>
                    </div>
                  ))}
                </div>
                :
                <p>Ce collaborateur n'a aucune formation en cours</p>
              }
              <h3 className='collab-name bold'>Formations terminées de : {entrepriseSalarie.attributes.user.data.attributes.nom} {entrepriseSalarie.attributes.user.data.attributes.prenom}</h3>
              {collabCoursesOff.length > 0 ?
                <div className="wrapper-salarie">
                  {collabCoursesOff.map((formation) => (
                    <div key={formation.id} className="card-wrapper">
                      <CourseDoneCard key={formation.id} salarie={entrepriseSalarie} courseId={formation.id} {...formation} dontShowAccess={true}/>
                    </div>
                  ))}
                </div>
                :
                <p>Ce collaborateur n'a aucune formation terminée</p>
              }
              </>
            </>
            }
          </div>
        }
        {sessionChoosed !== '' && factureEStarted.length > 0 &&
          <div className='section-collab'>
            {sessionChoosed !== '' &&
              <>
                <h3 className='collab-name bold'>{sessionChoosed.attributes.nom_formation}</h3>
                <h3 className='collab-name bold'>{sessionChoosed.attributes.start_session} / {sessionChoosed.attributes.end_session}</h3>
                <div className="wrapper-collabs">
                <section className='section-top'>
                  <div className='collab bold'>Collaborateur :</div>
                  <div className='bold center'>Score moyen : </div>
                  <div className='bold center'>Avancement : </div>
                  <div className='bold center'>Temps de formation :</div>
                </section>
                  {inscriptionsClickedSession.length > 0 && inscriptionsClickedSession.map((collab) => (
                    <div key={collab.id} className="card-wrapper">
                      <ScoreCourse key={collab.id} collab={collab} startDate={sessionChoosed.attributes.start_session} sessionChoosed={sessionChoosed} />
                    </div>
                  ))}
                </div>
                {new Date(sessionChoosed.attributes.end_session) < new Date() &&
                <> 
                {sessionChoosed.attributes.questionnaire_satisfaction.data === null &&
                <div 
                  className='modal-button'
                  onClick={() => {
                    toggleModalSatisfaction(true)
                  }}
                >
                  Répondre au questionnaire de satisfaction
                </div>
                }
                </>
                }
                {isModalSatisfactionOpen && 
                  <ModalSatisfaction attributes={sessionChoosed.attributes.formation.data.attributes} courseId={sessionChoosed.attributes.formation.data.id} factureCard={sessionChoosed} statut={'entreprise'} />
                }
              </>
            }
          </div>
        }
      </section>
      }
      </>
      }
      </main>
    </AccountCourseStyled>
  )
}

AccountCourse.propTypes = {
}

export default AccountCourse