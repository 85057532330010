import styled from 'styled-components';

const ScoreCourseStyled = styled.article`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 0 10px 20px ; 

    div {
        width: 25%;
        display: flex;
        flex-direction: column;
        height: 100%;
        
        .progress-bar {
            width: 60px;
            margin: 0 auto;
        }

        &.temps {
            width: 25%;
            background-color: #dbe8f4;
            padding: 10px 0;
            border-radius: 10px;
            text-align: center;
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

}
`;

export default ScoreCourseStyled;