/**** Import des modules ****/
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Skeleton } from '@mui/material';


/**** Import des css ****/
import ModalCreateLMSSessionStyled from './ModalCreateLMSSessionStyled'
import StepperComp from './StepperComp'

import urlBackEnd from '../../assets/js/urlBackEnd'


const URL = urlBackEnd

/**** ****/



const ModalCreateLMSSession = ({ stepForModalLMSSession, fetchGetUserLms, userDataLms }) => {
  
  useEffect(() => {
      fetchGetUserLms()
    }, [fetchGetUserLms]);

  return (
      <ModalCreateLMSSessionStyled>
        <section className="modal-content-wrapper">
          <StepperComp stepForModalLMSSession={stepForModalLMSSession} />
          {stepForModalLMSSession === 1 && 
          <div className="course-info-wrapper">
            { Object.keys(userDataLms).length > 0 &&
            <>
              <Skeleton variant="rounded" width={210} height={60} />
              <Skeleton variant="rounded" width={210} height={60} />
              <Skeleton variant="rounded" width={210} height={60} />
              </>
            }
            {userDataLms !== 'error' && userDataLms !== 'loading' && Object.keys(userDataLms).length > 0 &&
              <p>User account</p>
            }
            {userDataLms === 'error' &&
              <p>Erreur</p>
            }
          </div>
          }
          {stepForModalLMSSession === 1 && 
          <div className="course-info-wrapper">
            
          </div>
          }
          {stepForModalLMSSession === 2 && 
          <div className="course-info-wrapper">
            
          </div>
          }
        </section>
      </ModalCreateLMSSessionStyled>
    )
  }

ModalCreateLMSSession.propTypes = {

}

export default ModalCreateLMSSession