import styled from 'styled-components';

const AccountOceanStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

    .right-wrapper {
        width: calc(100% - 250px);
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        padding: 20px 20px 50px;

        .input-top-manager {
            margin: 50px 0;
        }

        .btn-show-test {
            border: 0;
            color: #6aaf8b;
            background-color: #fff;
            border-radius: 10px;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;
            transition: all .3s ease;
            box-shadow: 0px 1px 10px #00000029;
            font-size: 18px;
            line-height: 18px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            padding: 0;

            .show-test-txt {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 10px 10px 20px;
                width: 100%;
                height: 58px;
            }

            .list-managers-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;

                li {
                    font-size: 16px;
                    border-top: 1px solid #e1efe7;
                    cursor: pointer;
                    padding: 10px 10px 10px 20px;
                    display: flex;
                    justify-content: space-between;

                    &:hover {
                        background-color: #e1efe7;
                    }

                    .manager-wrapper {
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 10px;
                        }
                    }

                    .show-more {
                        color: #4d4d4d;
                    }
                }
            }

            button {
                width: 100%;
                border: 0;
                background-color: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #6aaf8b;
                padding: 0;
                height: 58px;
                padding: 20px 10px 20px 20px;
                border-radius: 10px;
                cursor: pointer;
            }

            &:nth-child(2) {
                margin-top: 40px;
            }

            .arrow {
                font-size: 50px;
                margin-top: 0px;
                margin-right: -15px;
                line-height: 18px;
            }
        }

        h1 {
            font-size: 50px;
            display: inline-block;
            margin: 50px auto 100px;
            text-transform: uppercase;
            color: #649ACE;
            text-align: center;

            span {
                position: relative;
            }
        }

        

        h2 {
            font-size: 30px;
            display: inline-block;
            margin: 50px auto 50px;
            text-transform: uppercase;
            color: #202D3C;


            span {
                position: relative;
            }

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
            }
        }

        .intro-txt {
            margin-bottom: 40px;
        }

        .account-form-ocean {
            display: flex;
            flex-direction: column;
            akign-items: flex-start;
            width: 100%;
            color: #4d4d4d;

            .wrapper-name-input {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 20px;

                .input-name-manager {
                    width: 45%;
                }
            }

            label {
                font-size: 20px;
                color: #4d4d4d;
                padding: 0 10px;
            }

            label.MuiFormControlLabel-root {
                font-size: 20px;
                color: #4d4d4d;
                padding: 0 10px;
                margin: 5px 0 10px;
            }

            .labels-q {
                margin: 10px 0;
            }

            .MuiFormGroup-root {
                padding-left: 20px;
                flex-wrap: wrap;
                flex-direction: row;

                #demo-radio-buttons-group-label-2 {
                    margin: 10px 0;
                }

                .MuiFormControlLabel-root {

                    .MuiRadio-root {
                        padding: 2px;
                    }
                }
            }

            .submit-btn {
                width: 300px;
                margin: 0 auto;
                border: 0;
                padding: 10px 0;
                border-radius: 10px;
                background-color :#6AAF8B;
                color: #e1efe7;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    opacity: .7;
                }
            }
        }

        .btn-wrapper {
            max-width: 300px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 20px 0 40px 0;

            button {
                width: 50%;
                max-width: 130px;
                border: 0;
                padding: 10px 0;
                border-radius: 10px;
                color: #4d4d4d;

                &.active-btn {
                    background-color: #6399ce;
                    color: #dbe8f4;
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }
            }
        }

        .start-q {
            font-size: 24px;
            margin: 10px 0 5px;
        }

        .progress-bar {
            width: 100%;
        }

        .result-txt-wrapper {
            h3 {
                font-size: 22px;
                margin: 30px 0 15px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                color: #202D3C;

                span {
                    display: flex;
                    align-items: center;
                    padding-top: 10px;
                    font-size: 20px;

                    svg {
                        color: #6AAF8B;
                        margin:0 10px;
                    }
                }
            }

            p {
                color: #4d4d4d;
                letter-spacing: 1px;
                text-align: justify;
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(96% - 250px);

            h1 {
                font-size: 45px;
            }

            h2 {
                font-size: 35px;
            }
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(96% - 250px);

            h1 {
                font-size: 40px;
            }

            h2 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {
        margin: 60px auto 80px;

        .right-wrapper {
            width: calc(96%);
            padding: 20px 0;
            margin: 0 auto;

            .btn-show-test {
                margin: 0 0 20px 0;
            }

            .intro-txt {
                margin: 10px 0;
            }
            
            .progress-bar {
                margin: 10px 0 20px 0;
            }

            h1 {
                font-size: 24px;

                .separator-blue {
                    width: 100px;
                }
            }

            h2 {
                font-size: 24px;
                margin: 0;
                text-align: center;
                text-transform: inherit;

                .separator-blue {
                    width: 50px;
                    position: static;
                    margin: 10px auto;
                }
            }

            .recharts-surface {
                padding: 40px; 
                box-sizing: border-box; 
                overflow: visible;
            }

            .result-txt-wrapper {
                h3 {
                    font-size: 18px;

                    span {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: 16px;
                    text-align: left;
                }
            }

            .account-form-ocean {
                font-size: 16px;

                .start-q {
                    font-size: 16px;
                }

                label {
                    font-size: 16px;
                }

                label.MuiFormControlLabel-root {
                    font-size: 16px;
                    color: #4d4d4d;
                    padding: 0 10px;
                    margin: 5px 0 5px;

                    .MuiSvgIcon-root {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
}
  
`;

export default AccountOceanStyled;