import React, { useState } from "react";
import AccueilStyled from "./AccueilStyled.js";
import { GoThreeBars, GoX } from "react-icons/go";
import { FaFacebook, FaLinkedin, FaInstagram, FaRegEnvelope, FaPhoneAlt, FaUserGraduate, FaSuitcase, FaCoins, FaBuilding, FaUser, FaArrowUp, FaArrowRight } from "react-icons/fa";
import { HiCursorClick } from "react-icons/hi"
import { RiLogoutBoxLine } from "react-icons/ri"
import { Link, useNavigate } from "react-router-dom"
import logoM from '../../assets/img/logo/logo-wefor.png'
import logoR from '../../assets/img/logo/thumbnail_cpf.png'
import logo from '../../assets/img/illustration/header-homme.png'
import LogoQualiopi from '../../assets/img/logo/LogoQualiopi-bis.png'

const Accueil = ({changeCurrentProfilUser, currentProfilUser, userRole, isLogged, changeSigninStep, windowTop, openModal, user, disconnectUser}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    let navigate = useNavigate();
  
    return (
      <AccueilStyled windowTop={windowTop}>
        <nav className="burger-list">
            <div className="hd-top">
                <img src={logoR} alt='logo wefor carré' className="logo-wefor"/>
                <div>
                    <h2 className="bold">Organisme de formation certifié</h2> 
                    <img src={LogoQualiopi} alt='logo qualiopi' className="logo-qualiopi"/>
                </div>
            </div>
            <img src={logo} alt='dessin homme avec tshirt wefor' className="logo-homme"/>
            <div className="wrapper-menus">
            <div className="empty-div">
                <ul>
                    <li 
                        className={`nav-link bold ${currentProfilUser === 'Salarié' ? 'active' : ''}`}
                        onClick={() => {
                            changeCurrentProfilUser('Salarié')
                            changeSigninStep(2.1)
                        }}
                    >
                        <span className="bold">Salarié</span> 
                    </li>
                    <li 
                        className={`nav-link bold ${currentProfilUser === "Demandeur d'emploi" ? 'active' : ''}`}
                        onClick={() => {
                            changeCurrentProfilUser("Demandeur d'emploi")
                            changeSigninStep(2.1)
                        }}
                    >
                        <span className="bold">Demandeur d'emploi</span> 
                    </li>
                    <li 
                        className={`nav-link bold ${currentProfilUser === 'Entreprise' ? 'active' : ''}`}
                        onClick={() => {
                            changeCurrentProfilUser('Entreprise')
                            changeSigninStep(2.2)
                        }}
                    >
                        <span className="bold">Entreprise</span> 
                    </li>
                </ul>
                {(currentProfilUser !== 'notChoose' || userRole !== "") ?
                    <></>
                    :
                    <div className="choose-statut">
                        <FaArrowUp className="bounce-item"/>
                        <div className="bold text">Choisissez un statut</div>
                    </div>
                }
            </div>
            <ul className="burger-nav">
                <li className="nav-item">
                <Link to="/formation" className="nav-link formation-link bold" onClick={toggleMenu}>
                    {currentProfilUser === 'Entreprise' ?
                        <span className="bold">Former mes collaborateurs</span>
                        :
                        <>
                            {isLogged ?
                                <span className="bold">Se former</span>
                                :
                                <span className="bold">Nos formations</span>
                            }
                        </>
                    }
                </Link>
                </li>
                <li className="nav-item">
                {currentProfilUser === 'Entreprise' ?
                    <Link to="/signin" className="nav-link job-link bold" onClick={toggleMenu}>
                        <span className="bold">Recruter des collaborateurs</span>
                    </Link>
                    :
                    <Link to="/job" className="nav-link job-link bold" onClick={toggleMenu}>
                        <span className="bold">Nos offres d'emploi</span>
                    </Link>
                }
                </li>
                <li className="nav-item">
                <Link to="/financement" className="nav-link financement-link bold" onClick={toggleMenu}>
                    <span className="bold">Les financements</span>
                </Link>
                </li>
                <li className="nav-item">
                <Link to="/qui-sommes-nous" className="nav-link wefor-link bold" onClick={toggleMenu}>
                    <span className="bold">Qui sommes-nous ?</span>
                </Link>
                </li>
            </ul>
            </div>
            <Link to="/contact" className="link-orange bold"><FaArrowRight /> Être recontacté pour plus d'informations</Link>
            <div className="bottom-nav">
                <a href="https://www.facebook.com/weforformationemploi" target='_blank' rel='noreferrer'><FaFacebook className="link-item"/></a>
                <a href="https://www.instagram.com/wefor_formation_emploi/" target='_blank' rel='noreferrer'><FaInstagram className="link-item"/></a>
                <a href="https://www.linkedin.com/company/wefor2022/?viewAsMember=true" target='_blank' rel='noreferrer'><FaLinkedin className="link-item"/></a>
                <Link to="/contact">
                    <FaRegEnvelope className="link-item" alt="picto contact" /> 
                </Link>
                <a href="tel:+33972575262">
                    <FaPhoneAlt className="link-item" alt="picto phone" /> 
                </a>
            </div>
        </nav>
    </AccueilStyled>
  );
};

export default Accueil;