import styled from 'styled-components';

const StepOneStyled = styled.section`

h2 {
	text-align: center;
    margin: 50px 0 20px;
	font-size: 30px;
	color: #202D3C;
}

h3 {
	font-size: 20px;
	padding: 30px 0 20px 0;
}

.info-list {
	width: 100%;

	li {
		padding: 0 0 10px 0;
		display: flex;
		align-items: center;
		
		div {
			width: calc(100% - 20px);
		}

		svg {
			color: #d47558;
			margin: 0 5px 0 0 ;

			&.number {
				font-size: 14px;
				margin: 0 0px 0px 0;
				padding-right: 5px;
				width: 20px;
			}
		}

		span {
			margin: 0 0 0 5px;
		}

		a {
			margin: 0 0 0 5px;
		}
	}
}

.file-upload-btn {
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 360px;
	margin: 10px auto 0;
	border-radius: 10px;
	background-color: #6399ce;
	color: #fff;
	font-size: 18px;
	padding: 15px;
	box-shadow: 0px 1px 10px #00000029;
	cursor: pointer;
	transition: all .3s ease;

	div {
		display: flex;
		align-items: center;
	}

	&:hover {
		opacity: .7;
	}
	
	.link-cpf {
		width: 100%;
		color: #fff;
		line-height: 15px;
		padding: 15px;
		text-align: center;
		transition:all .3s;

		&:hover {
			opcity: .7;
			color: #fff;
		}
	}

	svg {
		font-size: 20px;
	}
	

	input {
		display: none;
	}
}



.edof-link {
	border: 0;
	display: block;
	width: 360px;
	margin: 10px auto 0;
	border-radius: 10px;
	background-color: #6399ce;
	color: #fff;
	font-size: 18px;
	padding: 15px;
	box-shadow: 0px 1px 10px #00000029;
	cursor: pointer;
	transition: all .3s ease;

	&:hover {
		opacity: .7;
	}
}

.paiement-link {
	border: 0;
	display: block;
	width: 360px;
	margin: 10px auto 0;
	border-radius: 10px;
	background-color: #6aaf8b;
	color: #fff;
	font-size: 18px;
	padding: 15px;
	box-shadow: 0px 1px 10px #00000029;
	cursor: pointer;
	transition: all .3s ease;

	&:hover {
		opacity: .7;
	}
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (min-width: 760px) and (max-width: 1079px) {
}

@media only screen and (min-width: 0px) and (max-width: 759px) {
	h2 {
		text-align: center;
		margin: 20px 0 20px;
		font-size: 20px;
		color: #202D3C;
	}
	
	h3 {
		font-size: 18px;
		padding: 0px 0 20px 0;
	}

	.info-list {
		margin: 0 0 20px 0;
		li {
			width: 100%;
			flex-wrap: wrap;
			justify-content: start;

			span {
				
			}

			div {
				width: calc(100% - 21px);
			}

			svg {
				
			}
		}
	}

	.step-fin {
		background-color: #F6FDFF;
		width: 100vw;
		margin: 0 auto 20px -20px;
		padding: 20px;
	}

	.file-upload-btn, .paiement-link {
		width: 100%;
		margin: 10px auto 20px;
		font-size: 16px;
		text-align: center;
		max-width: 340px;
	}
}

`;

export default StepOneStyled;