import { connect } from 'react-redux'

import AccountParrainage from '../../pages/AccountParrainage'

import { fetchGetPreInscriptionGodfather, fetchGetGodfatherInfo } from '../../actions/apprenant'
import { saveGains } from '../../actions/godfather'
import { 
    fetchRibUser
  } from '../../actions/signin'


const mapStateToProps = (state) => ({
    isLogged: state.login.isLogged,
    userRole: state.login.userRole,
    userInfo: state.login.userInfo,
    godfatherList: state.apprenant.godfatherList,
    godfatherInfo: state.apprenant.godfatherInfo,
    jwt: state.login.session.jwt,
    gains: state.godfather.gains
})

const mapDispatchToProps = (dispatch) => ({
    fetchGetPreInscriptionGodfather : () => {
        dispatch(fetchGetPreInscriptionGodfather());
    },
    fetchGetGodfatherInfo : () => {
        dispatch(fetchGetGodfatherInfo());
    },
    saveGains : (data) => {
        dispatch(saveGains(data));
    },
    fetchRibUser: (file, id) => {
        dispatch(fetchRibUser(file, id));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountParrainage)