import { ToastContainer, toast } from 'react-toastify';

import { 
  CHANGE_IS_LOGGED,
  CHANGE_VALUE,
  CHANGE_ACCOUNT_VALUE,
  SAVE_USER,
  SAVE_USER_ROLE,
  SAVE_ACCOUNT_FORMATIONS,
  SAVE_PARTICULIERS,
  SAVE_SIREN_DATA,
  SAVE_CODE_RESET_PASSWORD,
  DISCONNECT_USER,
  SET_LOAD_CONNEXION,
  REDIRECT_TO_ACCOUNT,
  disconnectUser,
  SET_FULL_PAGE_LOADER_PUTACCOUNT,
  SET_TIME_RECRUTEMENT,
  SAVE_SCORE_RECRUTEMENT,
  SAVE_SCORE_RECRUTEMENT_OBJECT,
  SET_FULL_PAGE_LOADER_RECRUTEMENT_OBJ
} from "../actions/login";

export const initialState = {
  isLogged: false,
  prenom: 'Quentin',
  session:[],
  days: [
    {
      label: 'Choisir',
      value: 'Choisir',
    },
    {
      label: 1,
      value: '01'
    },
    {
      label: 2,
      value: '02'
    },
    {
      label: 3,
      value: '03'
    },
    {
      label: 4,
      value: '04'
    },
    {
      label: 5,
      value: '05'
    },
    {
      label: 6,
      value: '06'
    },
    {
      label: 7,
      value: '07'
    },
    {
      label: 8,
      value: '08'
    },
    {
      label: 9,
      value: '09'
    },
    {
      label: 10,
      value: '10'
    },
    {
      label: 11,
      value: '11'
    },
    {
      label: 12,
      value: '12'
    },
    {
      label: 13,
      value: '13'
    },
    {
      label: 14,
      value: '14'
    },
    {
      label: 15,
      value: '15'
    },
    {
      label: 16,
      value: '16'
    },
    {
      label: 17,
      value: '17'
    },
    {
      label: 18,
      value: '18'
    },
    {
      label: 19,
      value: '19'
    },
    {
      label: 20,
      value: '20'
    },
    {
      label: 21,
      value: '21'
    },
    {
      label: 22,
      value: '23'
    },
    {
      label: 24,
      value: '24'
    },
    {
      label: 25,
      value: '25'
    },
    {
      label: 26,
      value: '26'
    },
    {
      label: 27,
      value: '27'
    },
    {
      label: 28,
      value: '28'
    },
    {
      label: 29,
      value: '29'
    },
    {
      label: 30,
      value: '30'
    },
    {
      label: 31,
      value: '31'
    },
  ],
  mounths: [
    {
      label: 'Choisir',
      value: 'Choisir',
    },
    {
      label: 'Janvier',
      value: '01',
    },
    {
      label: 'Février',
      value: '02',
    },
    {
      label: 'Mars',
      value: '03',
    },
    {
      label: "Avril",
      value: '04'
    },
    {
      label: "Mai",
      value: '05'
    },
    {
      label: "Juin",
      value: '06'
    },
    {
      label: "Juillet",
      value: '07'
    },
    {
      label: "Août",
      value: '08'
    },
    {
      label: "Septembre",
      value: '09'
    },
    {
      label: "Octobre",
      value: '10'
    },
    {
      label: "Novembre",
      value: '11'
    },
    {
      label: "Décembre",
      value: '12'
    },
  ],
  accountBdDay: 'Choisir',
  accountBdMounth: 'Choisir',
  userRole:'',
  emailInsCollab:'',
  nomInsCollab:'',
  prenomInsCollab:'',
  newPassword: '',
  newPasswordConfirmation:'',
  email:'',
  password:'',
  particuliers: {},
  formationsEnCours: '',
  formationsTerminee : '',
  formationsDemande : '',
  offre_emplois_postule : '',
  accountAdresseNumStreet: '',
  accountAdresseStreet: '',
  accountAdresseCode: '',
  accountAdresseCity: '',
  accountStatut: '',
  loadConnexion: false,
  redirect: false,
  fullPageLoaderPutAccount: false,
  timeRecrutement: '',
  scoreRecrutementId: '',
  scoreRecrutementObject: [],
  fullPageLoaderRecrutementObj: false,
  userInfo: {}
};

// reducer = traducteur d'une intention/action vers une modification du state
const login = (state = initialState, action = {}) => {
  switch (action.type) {
      case CHANGE_IS_LOGGED:
        return {
          ...state,
          isLogged: action.bool,
        };
      case CHANGE_VALUE:
        return {
          ...state,
          [action.name]: action.value,
        };
      case CHANGE_ACCOUNT_VALUE:
        return {
          ...state,
          [action.name]: action.value,
        };
      case SAVE_USER:
        return {
          ...state,
          session: action.data,
        };
      case SAVE_USER_ROLE:
        return {
          ...state,
          userRole: action.role,
          userInfo: action.data,
          accountStatut: action.data.data[0].attributes.statut,
          loadConnexion: false
        };
      case SAVE_ACCOUNT_FORMATIONS:
        return {
          ...state,
          formationsEnCours: action.data.data[0].attributes.en_cours_formations,
          formationsTerminee : action.data.data[0].attributes.formations_termine,
          formationsDemande : action.data.data[0].attributes.demande_formations,
          offre_emplois_postule : action.data.data[0].attributes.offre_emplois_postule,
        }
      case SAVE_PARTICULIERS:
        return {
          ...state,
          particuliers: action.data
        }
      case SAVE_SIREN_DATA:
        return {
          ...state,
          sirenData: action.data
        }
      case SET_LOAD_CONNEXION:
        return {
          ...state,
          loadConnexion: action.bool
        }
      case SAVE_CODE_RESET_PASSWORD:
        return {
          ...state,
          codeResetPassword: action.code
        }
      case REDIRECT_TO_ACCOUNT:
        return {
          ...state,
          redirect: action.bool,
        }
      case DISCONNECT_USER:
        toast(`Vous êtes déconnecté !`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        return {
          ...state,
          isLogged: false,
          session: {},
          userRole:'',
          email:'',
          password:'',
          userInfo: {},
          particuliers: {},
          formationsEnCours: '',
          formationsTerminee : '',
          formationsDemande : '',
          offre_emplois_postule : '',
        }
      case SET_FULL_PAGE_LOADER_PUTACCOUNT:
        return {
          ...state,
          fullPageLoaderPutAccount: action.bool,
        }
      case SET_TIME_RECRUTEMENT:
        return {
          ...state,
          timeRecrutement: action.time,
        }
      case SAVE_SCORE_RECRUTEMENT:
        return {
          ...state,
          scoreRecrutementId: action.id,
        }
      case SAVE_SCORE_RECRUTEMENT_OBJECT:
        return {
          ...state,
          scoreRecrutementObject: action.data,
          fullPageLoaderRecrutementObj: false
        }
      case SET_FULL_PAGE_LOADER_RECRUTEMENT_OBJ:
        return {
          ...state,
          fullPageLoaderRecrutementObj: action.bool,
        }
    default:
      return state;
  }
};

export default login;