import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import sessionStorage from 'redux-persist/lib/storage/session'
import rootReducer from '../reducers';

/**** import des middleware ****/
import secteursMiddleware from '../api/secteursMiddleware';
import formationsMiddleware from '../api/formationsMiddleware';
import jobsMiddleware from '../api/jobsMiddleware';
import accountMiddleware from '../api/accountMiddleware';
import oceanMiddleware from '../api/oceanMiddleware';
import lmsMiddleware from '../api/lmsMiddleware';
import newsMiddleware from '../api/newsMiddleware';
import apprenantMiddleware from '../api/apprenantMiddleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(
  applyMiddleware(
    secteursMiddleware,
    formationsMiddleware,
    jobsMiddleware,
    accountMiddleware,
    oceanMiddleware,
    lmsMiddleware,
    newsMiddleware,
    apprenantMiddleware
  ),
);


const persistConfig = {
  key: 'root',
  timeout: 5000,
  storage: sessionStorage,
  whitelist: ["login"]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = createStore(
  persistedReducer,
  enhancers,
);


export const persistedStore = persistStore(store);

const clearSession = () => {
  persistedStore.purge()
  console.log('clear')
}

setTimeout(function(){clearSession()}, 1800000)