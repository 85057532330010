import { connect } from 'react-redux'

import SearchJobByKey from '../../components/SearchJobByKey'
import { setMuiJobFilterKeyValue } from '../../actions/job'


const mapStateToProps = (state) => ({
  muiJobFilterKeyValue: state.job.muiJobFilterKeyValue
})

const mapDispatchToProps = (dispatch) => ({
  setMuiJobFilterKeyValue: (value) => {
        dispatch(setMuiJobFilterKeyValue(value));
    },

})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(SearchJobByKey)