/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { FaGenderless } from 'react-icons/fa'
import { RiNumber1, RiNumber2, RiNumber3 } from 'react-icons/ri'

/**** Import des css ****/
import StepTwoStyled from './StepTwoStyled'


const StepTwo = ({ thisFinancement }) => {

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  return (
    <StepTwoStyled>
        <h2>Informations de votre financement CPF</h2>
        <ul className='info-list'>
          <li><FaGenderless /> <div>Nom de la formation : <span className='bold'> {thisFinancement.attributes.nom_formation}</span></div></li>
          <li><FaGenderless /><div> Demande effectuée le :  <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.publishedAt}</Moment></span></div></li>
          <li><FaGenderless /><div> Formation éligible au CPF : <span className='bold'>Oui</span></div></li>
          <li><FaGenderless /><div> Type de formation : <span className='bold'>100% Elearning</span></div></li>
          <li><FaGenderless /><div> Prix de la formation : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix))} TTC</span></div></li>
          <li><FaGenderless /><div> Emplois accessibles après formation : emplois du secteur <span className='bold'>{thisFinancement.attributes.formation.data.attributes.secteurs.data[0].attributes.nom}</span></div></li>
          <li><FaGenderless /><div> Début de la formation : <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.start_session}</Moment></span></div></li>
          <li><FaGenderless /><div> Fin de la formation : <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.end_session}</Moment></span></div></li>
        </ul>
        <h2 className='bold'>Accès à la formation</h2>
        <ul className='info-list'>
          <li><RiNumber1 className='number bold'/><div>Vous allez recevoir, la veille du la formartion, un email avec les détails de connexion à votre session de formation.</div></li>
          <li><RiNumber2 className='number bold'/><div>Connectez vous via le lien de l'email, ou directement via notre site sur cette même page.</div></li>
          <li><RiNumber3 className='number bold'/><div>Vous aurez accès à tout vos resultats et certificats de formation dans l'espace "vos formation" sur ntore site.</div></li>
        </ul>
    </StepTwoStyled>
  )
}

export default StepTwo