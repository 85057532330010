import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({lastPagePathname}) {
  const { pathname } = useLocation()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes('/job')) {
      const lastPathIdMinusOne = lastPagePathname.length - 2 
      if (lastPagePathname[lastPathIdMinusOne].includes('/job')) {
      } else {
        window.scrollTo(0, 0);
      }
    } else if (location.pathname.includes('/formation')) {
      const lastPathIdMinusOne = lastPagePathname.length - 2 
      if (lastPagePathname[lastPathIdMinusOne].includes('/formation')) {
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}