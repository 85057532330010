import { connect } from 'react-redux'

import LoginValid from '../../pages/LoginValid'

import { 
    changeIsLogged,
    changeValue,
    fetchUser,
} from '../../actions/login'

const mapStateToProps = (state) => ({
    isLogged: state.login.isLogged,
})

const mapDispatchToProps = (dispatch) => ({
    changeIsLogged: (bool) => {
        dispatch(changeIsLogged(bool));
    },
    changeValue: (value, name) => {
        dispatch(changeValue(value, name));
      },
    fetchUser: (data) => {
    dispatch(fetchUser(data));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(LoginValid)