import axios from 'axios'
import * as qs from 'qs'
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { 
  FETCH_LMS_APPRENANT,
  saveFormationApprenant,
  FETCH_FACTURE_COLLABS,
  saveFactureCollabs,
  FETCH_GET_PRE_INSCRIPTION,
  savePreInscription,
  FETCH_POST_USER_FROM_PRE_INSCRIPTION,
  FETCH_PUT_PRE_INSC,
  fetchGetPreInscription,
  fetchPutPreInsc,
  FETCH_GET_PRE_INSCRIPTION_GODFATHER,
  savePreInscriptionGodfather,
  FETCH_GET_GODFATHER_INFO,
  saveGodfatherInfo,
  FETCH_DELETE_USER_FROM_PRE_INSCRIPTION,
  FETCH_GET_USERS_P,
  FETCH_GET_USERS_E,
  saveUsersP,
  saveUsersE,
} from '../actions/apprenant';

import urlBackEnd from '../assets/js/urlBackEnd';

const URL = urlBackEnd


const apprenantMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_LMS_APPRENANT: {
      const state = store.getState();
      const nom_formation = action.name

      const queryFilterObj = qs.stringify({
        filters: {
          nom: {
            $eq: nom_formation
          },
        },
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        },
        }, {
          encodeValuesOnly: true,
      });
      
      axios.get(`${URL}formations?${queryFilterObj}`)
      .then((response) => {
        store.dispatch(saveFormationApprenant(response.data.data[0], action.id))
      })
      .catch((error) => {
        console.error(error)
      })

      next(action);
      break;
    }
    case FETCH_FACTURE_COLLABS: {
      const state = store.getState();
      const queryFE = qs.stringify({
        populate: {       
          entreprise: {
            populate: '*',
          },
          formation: {
            populate: '*',
          },
          particulier: {
            populate: '*',
          },
          contrat_formation: {
            populate: '*',
          },
          facture: {
            populate: '*',
          },
          autofinancement: {
            populate: '*',
          },
          opco: {
            populate: '*',
          },
          OPCO: {
            populate: '*',
          },
        },
        filters: {
          entreprise: {
            id: {
              $notNull: true
            }
          },
          step: {
            $eq: 2
          }
        },
        sort: ['publishedAt:desc']
        }, {
          encodeValuesOnly: true,
      });
      axios.get(`${URL}facturation-formations?${queryFE}`,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(saveFactureCollabs(response.data.data))
      })
      .catch((error) => {
        console.error(error);
      });
      
      next(action);
      break;
    }
    case FETCH_GET_PRE_INSCRIPTION: {
      const state = store.getState();

      const queryFilterObj = qs.stringify({
        populate: '*',
        filters: {
          created: {
            $eq: false
          },
        },
        sort: ['publishedAt:desc'],
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        },
        }, {
          encodeValuesOnly: true,
      });
      
      axios.get(`${URL}pre-inscriptions?${queryFilterObj}`,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(savePreInscription(response.data.data))
      })
      .catch((error) => {
        console.error(error)
      })

      next(action);
      break;
    }
    case FETCH_POST_USER_FROM_PRE_INSCRIPTION: {
      const state = store.getState();
      
      axios.post(`${URL}registerUsersFromPre`,{
        data: action.data,
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(fetchPutPreInsc(action.data))
      })
      .catch((error) => {
        console.error(error)
      })

      next(action);
      break;
    }
    case FETCH_PUT_PRE_INSC: {
      const state = store.getState();

      action.data.map((item) => {
        axios.put(`${URL}pre-inscriptions/${item.id}`,{
          data: {
            created: true,
          }
        },
        {
          headers: {
            Authorization:
              `Bearer ${state.login.session.jwt}`,
            },
        })
        .then((response) => {
          store.dispatch(fetchGetPreInscription())
          toast(`Compte(s) créé(s)`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          console.error(error)
          toast(`Une erreur s'est produite`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
      })

      next(action);
      break;
    }
    case FETCH_GET_PRE_INSCRIPTION_GODFATHER: {
      const state = store.getState();

      const queryFilterObj = qs.stringify({
        populate: '*',
        filters: {
          created: {
            $eq: false
          },
          users_permissions_user: {
            id: {
              $eq: state.login.session.user.id
            }
          }
        },
        sort: ['publishedAt:desc'],
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        },
        }, {
          encodeValuesOnly: true,
      });
      
      axios.get(`${URL}pre-inscriptions?${queryFilterObj}`,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(savePreInscriptionGodfather(response.data.data))
      })
      .catch((error) => {
        console.error(error)
      })

      next(action);
      break;
    }
    case FETCH_GET_GODFATHER_INFO: {
      const state = store.getState();

      const queryFilterObj = qs.stringify({
        populate: {
          users_permissions_user: {
            particulier: '*',
          },
          paiement: '*'
        },
        filters: {
          created: {
            $eq: true
          },
          users_permissions_user: {
            id: {
              $eq: state.login.session.user.id
            }
          }
        },
        sort: ['publishedAt:desc'],
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        },
        }, {
          encodeValuesOnly: true,
      });
      
      axios.get(`${URL}pre-inscriptions?${queryFilterObj}`,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(saveGodfatherInfo(response.data.data))
      })
      .catch((error) => {
        console.error(error)
      })

      next(action);
      break;
    }
    case FETCH_DELETE_USER_FROM_PRE_INSCRIPTION: {
      const state = store.getState();
      
      action.data.map((item) => {
        axios.delete(`${URL}pre-inscriptions/${item.id}`,
        {
          headers: {
            Authorization:
              `Bearer ${state.login.session.jwt}`,
            },
        })
        .then((response) => {
          store.dispatch(fetchGetPreInscription())
        })
        .catch((error) => {
          console.error(error)
        })
      })

      next(action);
      break;
    }
    case FETCH_GET_USERS_P: {
      const state = store.getState();

      const queryFilterObj = qs.stringify({
        populate: 'user',
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        },
        }, {
          encodeValuesOnly: true,
      });
      
      axios.get(`${URL}particuliers?${queryFilterObj}`)
      .then((response) => {
        store.dispatch(saveUsersP(response.data.data))
      })
      .catch((error) => {
        console.error(error)
      })


      next(action);
      break;
    }
    case FETCH_GET_USERS_E: {
      const state = store.getState();

      const queryFilterObj = qs.stringify({
        populate: 'user',
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        },
        }, {
          encodeValuesOnly: true,
      });
      
      axios.get(`${URL}entreprises?${queryFilterObj}`)
      .then((response) => {
        store.dispatch(saveUsersE(response.data.data))
      })
      .catch((error) => {
        console.error(error)
      })


      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default apprenantMiddleware;