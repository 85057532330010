import styled from 'styled-components';

const StepThreeStyled = styled.section`
.btn-univo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    background-color: #f8e8e3;
    color: #d47558;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
    min-height: 43px;
    max-height: 43px;
    margin: 20px auto 5px;
    border: 0;
    padding: 0 10px;

    &:hover {
        opacity: .7;
    }
}

.btn-360 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    background-color: #dbe8f4;
    color: #6399ce;
    border-radius: 10px;
    cursor: pointer;
    min-height: 43px;
    max-height: 43px;
    transition: all .3s ease;
    margin: 20px auto 5px;
    border: 0;
    padding: 0 10px;

    &:hover {
        opacity: .7;
    }
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (min-width: 760px) and (max-width: 1079px) {
}

@media only screen and (min-width: 0px) and (max-width: 759px) {
}

`;

export default StepThreeStyled;