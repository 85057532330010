/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as qs from 'qs'
import Moment from 'react-moment'
import urlBackEnd from '../../../assets/js/urlBackEnd'
import Skeleton from '@mui/material/Skeleton'
const URL = urlBackEnd

const GodfatherInfo = ({ 
  user,
  jwt,
  saveGains,
  gains
}) => {

const addIfNotExists = (arr, obj) => {

	const exists = arr.some(item => item.id === obj.id);
	if (exists) {
		return arr;
	} else {
		const newArr = arr.map(item => ({ ...item }));
		obj.paid = user.attributes.paiement
		newArr.push(obj);
		return newArr;
	}
	
}

const [formation, setFormation] = useState('loading')

useEffect(() => {

	const queryFilterObj = qs.stringify({
	populate: {
		facturation_formations: {
			fields: ["id", "nom_formation", 'Prix', "end_session", "step"]
		},
		paiements: '*'
	},
	filters: {
		user: {
			email: {
				$eq: user.attributes.email
			}
		},
	},
	headers: {
		Authorization:
		`Bearer ${jwt}`,
	},
	}, {
		encodeValuesOnly: true,
	});

	// requete axios pour savoir si un collab existe
	axios.get(`${URL}particuliers?${queryFilterObj}`)
	.then((response) => {
		const part = response.data.data
		console.log(part)
		if(part.length > 0){
			if(part[0].attributes.facturation_formations.data.length > 0) {
				const forms = part[0].attributes.facturation_formations.data
				const firstForm = forms.find(item => item.attributes.step >= 2) 
				if (firstForm === undefined) {
					setFormation('aucune')
				} else {
					setFormation(firstForm)
					if (addIfNotExists(gains, firstForm) !== gains) {
						saveGains(addIfNotExists(gains, firstForm))
					}
				}
			} else {
				setFormation('aucune')
			}
		} else {
			setFormation('aucune')
		}
	})
	.catch((error) => {
	console.error(error)
	})
  }, [])

  return (
    <div className='info-wrapper'>
		<div className='row-user'>
			<span>{user.attributes.nom} {user.attributes.prenom}</span>
		</div>
		<div className='row-user'>
			{formation === 'loading' && 
				<Skeleton variant="text" width={'100%'} height={20}/>
			}
			{formation === 'aucune' && 
				<>Aucune formation réalisée</>
			}
			{formation !== 'loading' && formation !== 'aucune' && Object.keys(formation).length > 0 && 
				<>{formation.attributes.nom_formation.slice(0, 20)}{formation.attributes.nom_formation.length > 21 ? '...' : ''}</>
			}
			
		</div>
		<div className='row-user'>
			{formation === 'loading' && 
				<Skeleton variant="text"/>
			}
			{formation === 'aucune' && 
				<div className='align-right bold'>0 €</div>
			}
			{formation !== 'loading' && formation !== 'aucune' && Object.keys(formation).length > 0 && 
				<div className='align-right bold'>{Number(formation.attributes.Prix) * 0.1} €</div>
			}
		</div>
		<div className='row-user'>
		{formation === 'loading' && 
				<Skeleton variant="text"/>
			}
			{formation === 'aucune' && 
				<>-</>
			}
			{formation !== 'loading' && formation !== 'aucune' && Object.keys(formation).length > 0 && 
				<Moment format="DD/MM/YYYY">{formation.attributes.end_session}</Moment>
			}
			
		</div>
    </div>
  )
}

GodfatherInfo.propTypes = {
}

export default GodfatherInfo