/**** import des modules ****/
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineDownload } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';

/**** import des css ****/
import PrivateStyled from './PrivateStyled'
import { fetchPostUserParticulier } from '../../../actions/login'

const Private = ({
  changeValueSignin, 
  mounths, 
  days, 
  accountBdDay, 
  accountBdMounth,
  changeIsLogged,
  changeFormModal,
  closeModal,
  formModal,
  openModal,
  modalName,
  fetchPostUserParticulier,
  accountPasswordSignin,
  accountConfirmationPasswordSignin,
  fetchSearchAdresse,
  accountAdresseSignin,
  adresseList,
  accountAdresseNumStreetSignin,
  accountAdresseStreetSignin,
  accountAdresseCodeSignin,
  accountAdresseCitySignin,
  isLogged,
  changeValue,
  accountCvSignin
}) => {
  let navigate = useNavigate();
  const [modalSearchAdresse, setModalSearchAdresse] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const toggleShowEye = () => {
    setShowPassword(!showPassword)
  }
  const toggleShowEyeC = () => {
    setShowPasswordC(!showPasswordC)
  }
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setModalSearchAdresse(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const isValidSpe = (str) => {
    return /[~`!#$%\^&*+=\-\[\]\\';,./{}|\\":<>\?@€]/g.test(str);
  }

  const isValidMaj = (str) => {
    return /(?=.*[A-Z])/.test(str);
  }

  const isValidChi = (str) => {
    return /^(?=.*[0-9])/.test(str);
  }

  const isValidNum = (str) => {
    return /^.{8,10000}$/.test(str);
  }

  if(isLogged) {
    return (<Navigate to="/account/ocean" replace />)
  }

  return (
    <PrivateStyled>
      <h2 className="bold">Créer votre compte Particulier<span className="separator-blue"></span></h2>
      {formModal === 'signIn' && 
        <p className="connexion-txt">Vous avez déjà un compte ? 
          <span
            className="login-span"
            onClick={() => {
              changeFormModal('login')
            }}
          > Connectez vous</span>
        </p>
      }
      {formModal !== 'signIn' &&
        <p className="connexion-txt">Vous avez déjà un compte ? <Link to="/login">Connectez vous</Link></p>
      }
      <form 
          className="account-form-comp"
          onSubmit={(event) => {
            event.preventDefault();
            if (accountPasswordSignin === accountConfirmationPasswordSignin && isValidChi(accountPasswordSignin) && isValidMaj(accountPasswordSignin) && isValidNum(accountPasswordSignin) && isValidSpe(accountPasswordSignin)) {
              fetchPostUserParticulier();
            } else {
              toast(`Toutes les conditions du mot de passe doivent correspondre`, {
                position: "bottom-right",
                type: "error",
                theme:"colored",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
            }
            
            if (modalName === 'formation') {
              openModal(modalName)
            } else if (modalName === 'postuler') {
              openModal(modalName)
            } else {
              closeModal('');
            }
          }}
        >
          <div className="mdp-wrapper">
          <label>
            Adresse Email
            <input 
              type="email" 
              name="accountEmailSignin" 
              placeholder=""
              required
              onChange={(event) => {
                changeValueSignin(event.target.value, event.target.name);
                changeValue(event.target.value, "email")
              }}
            />
          </label>
          <label>
            Numéro de téléphone
            <input 
              type="tel" 
              name="accountTelSignin" 
              placeholder=""
              required
              onChange={(event) => {
                changeValueSignin(event.target.value, event.target.name);
              }}
            />
          </label>
          </div>
          <div className="mdp-wrapper">
          <label className='password-item'>
              Mot de passe
              <div className='eye-wrapper'>
                {showPassword === true ? 
                  <FaEye onClick={toggleShowEye}/>
                :
                  <FaEyeSlash onClick={toggleShowEye}/>
                }
              </div>
              
              <input 
                type={showPassword ? "text" : "password"}
                name="accountPasswordSignin" 
                value={accountPasswordSignin}
                placeholder=""
                required
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                  changeValue(event.target.value, "password")
                }}
              />
            </label>
            <label className='password-item'>
              Confirmation du mot de passe
              <div className='eye-wrapper'>
                {showPasswordC === true ? 
                  <FaEye onClick={toggleShowEyeC}/>
                :
                  <FaEyeSlash onClick={toggleShowEyeC}/>
                }
              </div>
              <input 
                type={showPasswordC ? "text" : "password"}
                name="accountConfirmationPasswordSignin" 
                value={accountConfirmationPasswordSignin}
                required
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <div className="mdp-cond-wrapper">
              <p>Votre mot de passe doit contenir:</p>
              <ul>
                <li>
                {isValidNum(accountPasswordSignin) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> 8 caractère au minimun</span>
                </li>
                <li>
                {isValidChi(accountPasswordSignin) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> Un chiffre au minimun</span>
                </li>
                <li>
                {isValidMaj(accountPasswordSignin) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> Une majuscule au minimun</span>
                </li>
                <li>
                {isValidSpe(accountPasswordSignin) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> Un caractère spéciale au minimun</span>
                </li>
                <li>
                 {accountPasswordSignin === accountConfirmationPasswordSignin ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>}
                 <span> Les mots de passe doivent correspondre</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="search-adresse-wrapper" ref={wrapperRef}>
            <label  onClick={() => setModalSearchAdresse(true)}>
              Adresse postale
              <input 
                type="text" 
                name="accountAdresseSignin" 
                placeholder=""
                value={accountAdresseSignin}
                required
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                  fetchSearchAdresse(event.target.value);
                }}
              />
            </label>
            {modalSearchAdresse &&
              <div className="result-adresse-wrapper"
              onClick={() => setModalSearchAdresse(false)}
              >
                <ul className="adresse-list">
                  {adresseList.length > 0 && adresseList.map((adresse) => (
                    <li 
                      key={adresse.properties.score} 
                      onClick={() => {
                        changeValueSignin(adresse.properties.label, 'accountAdresseSignin')
                        changeValueSignin(adresse.properties.housenumber, 'accountAdresseNumStreetSignin')
                        changeValueSignin(adresse.properties.street, 'accountAdresseStreetSignin')
                        changeValueSignin(adresse.properties.postcode, 'accountAdresseCodeSignin')
                        changeValueSignin(adresse.properties.city, 'accountAdresseCitySignin')
                        setModalSearchAdresse(false)
                      }}
                    >
                      {adresse.properties.label}
                    </li>
                  ))}
                </ul>
              </div>
             }
          </div>
          <div className="name-wrapper">
          <label className="invisible-front">
              Numéro de rue
              <input 
                type="text" 
                value={accountAdresseNumStreetSignin}
                name="accountAdresseNumStreetSignin" 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <label className="invisible-front">
              Nom de la rue
              <input 
                type="text" 
                value={accountAdresseStreetSignin}
                name="accountAdresseStreetSignin" 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="name-wrapper">
          <label className="invisible-front">
              Code postale
              <input 
                type="text" 
                value={accountAdresseCodeSignin}
                name="accountAdresseCodeSignin" 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <label className="invisible-front">
              Ville
              <input 
                type="text" 
                value={accountAdresseCitySignin}
                name="accountAdresseCitySignin" 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="name-wrapper">
          <label>
              Prénom
              <input 
                type="text" 
                name="accountFirstNameSignin" 
                placeholder=""
                required
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Nom
              <input 
                type="text" 
                name="accountLastNameSignin" 
                placeholder=""
                required
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          {/* 
          <div className="mdp-wrapper">
          <label>
              Niveau d'étude
              <input 
                type="text" 
                name="accountCoursesSignin" 
                required
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Dernier emploi occupé
              <input 
                type="text" 
                name="accountLastJobSignin" 
                required
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          */}
          {/*
          <div className="search-adresse-wrapper">
          <label>
              Curriculum vitae
              <input 
                type="file" 
                className="file-input"
                name="accountCvSignin" 
                required
                placeholder=""
                onChange={(event) => {
                  let file = new FormData();
                  file.append("files", event.target.value);
                  changeValueSignin(file, event.target.name);
                }}
              />
              <div className="file-upload-btn"><span>Déposez votre CV <AiOutlineDownload /> </span><span>{accountCvSignin ? accountCvSignin : 'Aucun document'}</span></div>
            </label>
          </div>
          */}
          {/* 
          <div className="onjob-wrapper">
            <h4>Êtes vous actuellement en poste :</h4>
          <label>
              <input 
                type="radio" 
                name="accountOnJobSignin" 
                required
                value="Salarié"
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
              Oui
            </label>
            <label>
              <input 
                type="radio" 
                name="accountOnJobSignin" 
                required
                value="Demandeur d'emploi"
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
              Non
            </label>
          </div>
          <label className="newsletter">
              <input 
                type="radio" 
                name="accountNewsletter" 
                value="Salarié"
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
              <div>Je souhaite m'inscrire à la newletter</div>
            </label>
            */}
          <label className="newsletter">
            <input 
              type="radio" 
              name="accountMl" 
              value="Yes"
              placeholder=""
              required
              onChange={(event) => {
                changeValueSignin(event.target.value, event.target.name);
              }}
            />
             <div>En cochant cette case, je déclare avoir lu et accepter les <Link to="/legal">Mentions Légales</Link> et les <Link to="/conditions-generales-de-vente"> Conditions Générales de Vente.</Link></div>
          </label>
          <input 
            type="submit" 
            value="Créer un compte" 
            className="lightblue-btn"
            onClick={() => {
              window.dataLayer.push({'event': 'lead'})
            }} 
          />
        </form>
    </PrivateStyled>
  )
}

Private.propTypes = {
  changeValueSignin: PropTypes.func.isRequired,
  days: PropTypes.array.isRequired,
  mounths: PropTypes.array.isRequired,
  changeIsLogged: PropTypes.func.isRequired,
  changeFormModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  formModal: PropTypes.string.isRequired,
}

export default Private