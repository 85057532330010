import React, { useState, useEffect } from 'react';
import PopupKnowMoreStyled from './PopupAxeptaStyled';
import { Button, Icon, TextField, Paper, Typography } from "@material-ui/core";
import SendIcon from '@mui/icons-material/Send';
import { Modal } from 'semantic-ui-react'
//



  function PopupKnowMore() {

  }

  export default PopupKnowMore
