import { 
	SAVE_DATA_APPRENANT_FROM_CSV,
  SAVE_FORMATION_APPRENANT,
  SAVE_FACTURE_COLLABS,
  CHANGE_CLICKED_FACTURE_COLLABS,
  SAVE_PRE_INSCRIPTION,
  SAVE_PRE_INSCRIPTION_GODFATHER,
  SAVE_GODFATHER_INFO,
  SAVE_USERS_P,
  SAVE_USERS_E
  } from "../actions/apprenant"
  
  export const initialState = {
    newreducers: 'bg',
	  apprenantFromCSV: '',
    formationApprenant: [],
    factureCollabs: 'loading',
    clickedFactureCollabs: false,
    preInscription: 'loading',
    godfatherList: 'loading',
    godfatherInfo: 'loading',
    usersFormAdminP: 'loading',
    usersFormAdminE: 'loading'
  };
  
  // reducer = traducteur d'une intention/action vers une modification du state
  const apprenant = (state = initialState, action = {}) => {
    switch (action.type) {
		case SAVE_DATA_APPRENANT_FROM_CSV:
			return {
				...state,
				apprenantFromCSV: action.data,
			}
    case SAVE_FACTURE_COLLABS:
      return {
        ...state,
        factureCollabs: action.data,
      };
    case SAVE_FORMATION_APPRENANT:
      return {
        ...state,
        formationApprenant: [...state.formationApprenant, {
          'formation': action.id,
          'formationData': action.data 
        }],
      };
    case CHANGE_CLICKED_FACTURE_COLLABS:
      return {
        ...state,
        clickedFactureCollabs: action.factureId
      }
    case SAVE_PRE_INSCRIPTION:
      return {
        ...state,
        preInscription: action.data
      }
    case SAVE_PRE_INSCRIPTION_GODFATHER:
      return {
        ...state,
        godfatherList: action.data
      }
    case SAVE_GODFATHER_INFO:
      return {
        ...state,
        godfatherInfo: action.data
      }
    case SAVE_USERS_P:
      return {
        ...state,
        usersFormAdminP: action.data
      }
    case SAVE_USERS_E:
      return {
        ...state,
        usersFormAdminE: action.data
      }
      default:
        return state;
    }
  };
  
  export default apprenant