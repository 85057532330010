import styled from 'styled-components';

const NavUserStyled = styled.div`
   width: 100%;
   position: relative;
   height: 100px;
   max-height: 100px;

   .absolute-wrapper {
      height: 100%;
      width: 100%;
      position: relative;
      max-width: 1300px;
      margin: 0 auto;
   }

   .menu-burger {
      display: none;
   }

   nav.user-choosed {
      width: 100%;
      height: 100%;

      ul.main-menu {
         width: 100%;
         height: 100%;
         display: flex;
         flex-wrap: wrap;
         justify-content: center;
         align-items: center;

         &.mobile {
            display: none;
         }

         li {
            min-width: 200px;
            max-width: 200px;
            height: 65px;
            text-align: center;
            margin: 0px 5px 0;
            padding: 12px 10px;
            font-weight: bold;
            font-size: 18px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content center;
         }

         .ent-btn-width {
            width: 200px;
         }

         .qsm-a {
            color: #D071AB;
         }

         .qsm-btn {
            border: 2px solid #D071AB;
            background-color: #F6ECF3;
            border-radius: 20px;

            a {
               color: #D071AB;
            }
            
            &:hover{
               background-color: #D071AB;
               color: #fff !important;

               a {
                  color: #F6ECF3;
               }
            }
         }

         .job-btn {
            color: #6aaf8b;
            border: 2px solid #6aaf8b;
            background-color: #e1efe7;
            position: relative;
            border-radius: 20px;
         
            a {
               color: #6aaf8b;
            }
         
            &:hover{
               background-color: rgba(106, 175, 139, 0.9);
               color: #fff;
         
               a {
                  color: #fff;
               }
            }
         }

         .financement-btn {
            border: 2px solid #6399ce;
            background-color: #dbe8f4;
            border-radius: 20px;
            color: #6399ce;

            a {
               color: #6399ce;
            }
            
            &:hover{
               background-color: #6399ce;
               color: #fff !important;

               a {
                  color: #fff;
               }
            }
         }
      }
   }

   .yellow {
      background-color: #f5e0b6;
      color: #DF990D;
      border: 2px solid #DF990D !important;
      

      &:hover {
         background-color: #DF990D !important;
         color: #f5e0b6 !important;
      }
   }

   .user-not-choosed {
      width: 100%;
      height: 100%;
      max-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 15;

      .btn-wrapper {
         width: 550px;
         color: #fff;
         background-color: #202c3c;
         cursor: pointer;
         text-align: center;
         font-size: 18px;
         box-sizing: border-box;
         text-transform: uppercase;
         border-radius: 20px;
         position: relative;

         p {
            padding: 15px 0;
            box-sizing: border-box;
         }

         .arrow-down {
            color: #fff;
            font-size: 22px;
            margin:0 0 -5px 10px;
         }

         .list-statut {
            position: absolute;
            left: 0;
            top: 51px;
            width: 550px;
            background-color: #202c3c;
            color: #fff;
            box-sizing: border-box;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            a { color: #fff;}

            .last-child {
               border-bottom-right-radius: 24px;
               border-bottom-left-radius: 24px;
            }

            li {
               padding: 15px 0;
               width: 100%;
               text-transform: capitalize;

               &:hover {
                  background-color: #fff;
                  color: #202c3c;
               }
            }
         }

         &.border-change {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            p {
               border-bottom: 1px solid #fff;
            }
         }
      }

      &.left {
         position: absolute;
         left: 230px;
         top: -75px;
         width: 210px;

         .btn-wrapper {
            width: 210px;
            max-width: 230px;
            font-size: 15px;
            text-transform: capitalize;
   
            .arrow-down {
               font-size: 20px;
            }
   
            .list-statut {
               top: 49px;
               width: 210px;
            }
   
            &.border-change {
               border-bottom-right-radius: 0;
               border-bottom-left-radius: 0;
   
               p {
                  border-bottom: 1px solid #fff;
               }
            }
         }
      }
   }

   .user-not-choosed-first {
      width: 100%;
      height: 100%;
      position: relative;

      .arrow-right {
         font-size: 22px;
         margin:0 0 -5px 10px;
      }

      .list-statut {
         width: 800px;
         height: 100%;
         margin: 0 auto;
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: center;
         position: relative;

         .statut {
            color: #202c3c;
            font-size: 28px;
            display: inline-block;
            position: absolute;
            top: 35px;
            left: -220px;
         }

         .choose-btn {
            width: 200px;
            height: 65px;
            text-align: center;
            padding: 10px 10px;
            margin: 0px 5px 0;
            border-radius: 20px;
            cursor: pointer;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            transition: all .3s ease;

            &.mobile {
               display: none;
            }

            &.orange {
               background-color: #F7EDEA;
               color: #D47558;
               border: 2px solid #D47558;

               &:hover {
                  background-color: #D47558;
                  color: #fff;
               }
            }

            &.green {
               background-color: #ECF3EF;
               color: #6AAF8B;
               border: 2px solid #6AAF8B;
               transition: all .3s ease;

               &:hover {
                  background-color: #6AAF8B;
                  color: #fff;
               }
            }

            &.lightblue {
               background-color: #dbe8f4;
               color: #649ACE;
               border: 2px solid #649ACE;
               transition: all .3s ease;

               &:hover {
                  background-color: #649ACE;
                  color: #fff;
               }
            }

            &.yellow {
               background-color: #f3f5cc;
               color: #c7cf00;
               border: 2px solid #c7cf00;
               transition: all .3s ease;

               &:hover {
                  background-color: #c7cf00;
                  color: #f3f5cc;
               }
            }

            &.pink {
               background-color: #F6ECF3;
               color: #D071AB;
               border: 2px solid #D071AB;
               transition: all .3s ease;

               &:hover {
                  background-color: #D071AB;
                  color: #fff;
               }
            }
         }
      }
   }

   @media only screen and (max-width: 1400px) {
      display: flex;
      justify-content: center;
      
      .user-not-choosed-first {

         .list-statut {
            padding-left: 50px !important;

            .statut {
               color: #202c3c;;
               font-size: 28px;
               display: inline-block;
               position: absolute;
               top: 35px;
               left: -140px;
            }
         }
      }

   }
 
   @media only screen and (max-width: 1079px) {
      height: 75px;

      .user-not-choosed.left {
         left: 20px;
         top: 0;
      }

      .user-not-choosed-first, nav.user-choosed {
         position: relative;
         background-color: ${props => (props.isShowChooseBtn ? "#fff" : "")};
         max-height: 200px;


         ul.list-statut, ul.main-menu {
            position: absolute;
            top: 75px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding-bottom: 10px;
            height: ${props => (props.isShowChooseBtn ? "400px" : "0")};
            max-height: ${props => (props.isShowChooseBtn ? "400px" : "0")};
            padding-left: 0 !important;
            z-index: 12;
            background-color: ${props => (props.isShowChooseBtn ? "rgba(255, 255, 255, 1)" : "")};
            border-top-left-radius: ${props => (props.windowTop ? "" : "0")};
            border-top-right-radius: ${props => (props.windowTop ? "" : "0")};

            .statut {
               left: calc(50% - 200px);
               top: -53px;
               color: #202c3c;;
            }

            .choose-btn.pc {
               display: none;
            }

            .choose-btn.mobile, li.main-menu {
               display: flex;
               margin: 10px auto 10px;
            }
         }

         ul.main-menu {
            &.pc {
               display: none;
            }

            &.mobile {
               display: block;
            }

            li {
               display: block;
               margin: 10px auto 0px;
               width: 50%;
            }
         }

         .menu-burger {
            position: absolute;
            right: 10%;
            top: 17px;
            z-index: 13;
            display:block;

            .burger {
               font-size: 40px;
               color: #202c3c;
            }

            .cross {
               font-size: 40px;
               color: red;
            }
         }
      }

      nav.user-choosed ul.main-menu .ent-btn-width {
         width: auto;
      }     
  }
 
   @media only screen and (min-width: 0px) and (max-width: 759px) {
      position: absolute;
      top: 0;
      .user-not-choosed.left {
         left: 130px;
         width: 180px;

         .btn-wrapper {
            width: 180px;
            
            p {
               font-size: 14px;
               padding: 12px 0;

               svg.arrow-down {
                  font-size: 14px;
                  margin-bottom: -2px;
               }
            }

            .list-statut {
               width: 180px;
               top: 39px;

               li {
                  padding: 12px 0;
                  font-size: 14px;
               }
            }
         }
      }
      .user-not-choosed-first, nav.user-choosed {

         ul.list-statut, ul.main-menu {

            .statut {
               left: calc(10%);
               top: -60px;
               display: none;
            }

            .choose-btn.mobile {
               
            }

            li.main-menu, li {
               display: block;
               margin: 10px auto 0;
               width: 80%;
            }
         }

         .menu-burger {
            position: absolute;
            right: 2%;
            top: 17px;
            z-index: 13;
         }
      }
   }
`;

export default NavUserStyled;