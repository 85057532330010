/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useNavigate, Link, Navigate } from 'react-router-dom'
import axios from 'axios'
import * as qs from 'qs'
import urlBackEnd from '../../assets/js/urlBackEnd'
import CircularProgress from '@mui/material/CircularProgress';

const URL = urlBackEnd

const NbPart = ({
    job
}) => {
  const [candidatJobs, setCandidatJobs] = useState('loading');

  useEffect(() => {
    const queryFilterPart = qs.stringify({
        populate: 'particuliers_demande',
        }, {
          encodeValuesOnly: true,
        });
  
      axios.get(`${URL}offre-emplois/${job.id}?${queryFilterPart}`, {})
        .then((response) => {
          setCandidatJobs(response.data.data.attributes.particuliers_demande.data.length) 
        })
        .catch((error) => {
          console.error(error);
          setCandidatJobs('error')
        });
  }, []);

  return (
    <div className='bold'>
        {candidatJobs === 'loading' && 
            <CircularProgress
                color="success"
            />
        }
        {candidatJobs === 'erreur' && 
            <>Voir candidats</>
        }   
        {candidatJobs !== 'erreur' && candidatJobs !== 'loading' && candidatJobs !== undefined &&
            <>{candidatJobs > 1 ? `${candidatJobs} candidats` : `${candidatJobs} candidat`}</>
        }  
    </div>
  )
}

export default NbPart