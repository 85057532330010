/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import axios from 'axios'
import * as qs from 'qs'

/**** Import de components et containers ****/
/**** Import des images ****/
import progaccount from '../../../assets/img/illustration/progaccount.png'
import logoCPF from '../../../assets/img/logo/WEFOR_CARRE_COULEUR.png'

/**** Import des css ****/
import ModalCourseInscriptionStyled from './ModalCourseInscriptionStyled'
import { Skeleton } from '@mui/material'
import urlBackEnd from '../../../assets/js/urlBackEnd'

const URL = urlBackEnd

const ModalCourseInscription = ({closeModal, formations, activCourseId, fetchDemandeFormation, redirectToMyFinancement, saveFormations, setRedirectToMyFinancement, formationInfo, fetchFormations}) => {

  if(redirectToMyFinancement) {
    closeModal('')
    setRedirectToMyFinancement(false)
    return (<Navigate to="/account/financements" replace />)
  }

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  console.log(formationInfo)

  return (
    <ModalCourseInscriptionStyled>
      <section>
        <h3 className="answer bold">Vous avez sélectionné cette formation</h3>
        {formationInfo !== false && formationInfo !== undefined &&
        <div className="card">
          {formationInfo.attributes.cpf_link === null || formationInfo.attributes.cpf_link === '' ? 
          <img src={progaccount} alt="picto d'un écran vidéo bleu" />
          :
          <img src={logoCPF} alt="logo mon compte de formation" className='cpf-logo'/>
          }
          <div className="text-wrapper">
            <h3 className="title-blue bold">{formationInfo.attributes.nom}</h3>
            {formationInfo.attributes.cpf_link === null || formationInfo.attributes.cpf_link === '' ? 
            <>
              <h4 className=""></h4>
            </>
            :
            <h4 className="">Éligible CPF</h4>
            }
            <div className="bottom-wrapper">
              <p className="bold capitalize">{euroFormat.format(formationInfo.attributes.prix)} TTC - {formationInfo.attributes.duree} H</p>
            </div>
          </div>
        </div>
        }
        <button 
          className="btn-lightblue"
          onClick={() => {
            fetchDemandeFormation(formationInfo.id)
            window.dataLayer.push({'event': "s'inscrire"})
          }}
        >Demande d'inscription</button>
      </section>
    </ModalCourseInscriptionStyled>
  )
}

ModalCourseInscription.propTypes = {
  closeModal: PropTypes.func.isRequired,
  prenom: PropTypes.string.isRequired,
}

export default ModalCourseInscription

