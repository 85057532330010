import styled from 'styled-components';
import bcgHeader from '../../assets/img/header/formation-ill.jpg'

const JobFicheStyled = styled.main`
    padding-top: 0px;

    .illustration-header {
        width: 100vw;
        height: 700px;
        padding-top: 125px;
        z-index: 10;
        position: relative;
        background-color: #fff;
    
        &::before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width:100%;
            background: linear-gradient(180deg, #f7edea 0%, #247558 100%);
            clip-path: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            shape-outside: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            z-index: -10;
        }
    
    
        .opacity-light-section {
            height: 100%;
            top: 100%;
            max-width: 1300px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 0px 0; 
    

            .left {
                width: calc(50%);
                margin-right: 5%;

                h1 {
                    font-size: 40px;
                    
                    text-align: center;
                }

                p {
                    font-size: 25px;
                    color: #fff;
                    line-height: 30px;
                    padding: 30px 0;
                }
            }
            img {
                margin-top: 20px;
                box-sizing: border-box;
                width: 45%;
                box-sizing: border-box;
                height: fit-content;
                }
            }

            .title-wrapper-prog {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
                margin-left: 40px;

                h2 {
                    color: #e1efe7;
                    padding: 0 0 5px;
                    font-size: 35px;
                    text-transform: uppercase;
                    text-align: center;

                    &.border {
                        padding: 0 0 20px;
                        margin: 0 0 20px;
                        border-bottom: 4px solid #6aaf8b;
                    }
                }

                h1 {
                    display: block;
                    font-size: 50px;
                    color: #fff;
                    text-transform: uppercase;
                    color: #4d4d4d;
                }
            }
        }
    }

    .section-job-def {
        display: flex;
        flex-direction: column;
        padding: 50px 0;
        background-color: #fff;

        .title-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .para-mkd {
                width: 96%;
                max-width: 800px;
                margin: 20px auto;
                border-radius: 10px;
                box-shadow: 0px 1px 10px #00000029;
                background-color: #f6faf9;

                .title-wrapper-line {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 15px 0 0;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
    
                    h2 {
                        font-size: 30px;
                        width: auto;
                        display: inline;
                        position: relative;
                        color: #4d4d4d;
                        margin-left: 40px;
                        padding-bottom: 10px;

                        .green {
                            color: #6aaf8b;
                            text-transform: lowercase;
                        }
                    }
                }

                h3 {
                    font-weight: bold;
                    font-size: 20px;
                    padding: 10px 15px;
                    background-color: #6aaf8b;
                    text-align: center;
                    color: #fff;
                    margin: 30px 0;
                }

                p {
                    text-align: justify;
                    line-height: 18px;
                    padding: 0 15px 5px;
                }

                ul {
                    padding: 0 15px 15px;
                    li {
                        &:before {
                            content:"·";
                            font-size: 50px;
                            margin-right: 5px;
                            vertical-align:middle;
                            line-height: 25px;
                        }

                        &.link-offer {
                            padding: 5px 0;
                            transition: all .2s ease;

                            a {
                                transform: scale(1.02);
                            }
                        }
                    }
                }
            }

            .list-job {
                width: 96%;
                max-width: 800px;
                margin: 0 auto;

                .link-pole-title {
                    width: 100%;
                    font-size: 30px;
                    margin: 40px 0 20px;
                }

                ul {
                    li {

                        &.link-offer {

                            a {
                                margin: 10px 0;
                                padding: 10px;
                                border-radius: 5px;
                                cursor: pointer;
                                background-color: #649ACE;
                                color: #fff;
                                transition: all .2s ease;
                                display: inline-block;

                                &:hover {
                                    opacity: .7;
                                }
                            }

                        } 
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .illustration-header {
            .opacity-dark {
    
                .opacity-white {
                    margin: auto 30px;
                    padding: 40px 40px;
                }
    
                .title-wrapper-prog {
                    margin-left: 20px;
    
                    h2 {
                        font-size: 40px;
                    }
    
                    h1 {
                        font-size: 50px;
                    }
                }
            }
        }

        .section-job-def {
    
            .title-wrapper {
    
                img {
                    width: 100px;
                }
    
                h2 {
        
                    .separator-blue {
                    }
                }
    
            }
    
            .def-wrapper {
                width: 96%;
                max-width: 1200px;
                flex-direction: column;
    
                .txt-left {
                    width: 100%;
                }
    
                .salary-wrapper {
                    width: 100%;
                    max-width: 500px;
                    margin: 50px auto 0;
                }
            }
        }

        .section-formation-list {
            padding: 50px 20px;
    
            .card-wrapper {
                justify-content: space-evenly;
                width: 96%;
                max-width: 1200px;
                margin: 50px auto;
            }
        }
    }
    
    @media only screen and (max-width: 1079px) {
        .illustration-header {
            .opacity-light-section {
                height: auto;
                flex-direction: column-reverse;
    
                .left{
                    padding: 50px 0 0 0;
                    width: calc(96%);
                    margin: 0 auto;
                    height: 300px;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                    }

                    .title-wrapper-prog {
                        margin: 0;
                    }

                    h2 {
                        font-size: 30px;
                        margin-top: 20px;
                        color: #fff;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                    }
                }
                img {
                    width: 90%;
                    max-width: 360px;
                    margin-left: 0px;
                    margin: -60px auto 0;
                }
            }
        }

        .section-job-def {
    
            .def-wrapper {
                .salary-wrapper {
                    padding-bottom: 10px;
                    text-align: center;
                }
            }
        }

        .section-formation-list {
            .card-wrapper {
                article {
                    margin: 40px 20px;
                }
            }
        }

        .section-btn-bottom {

            .btn-right, .btn-left {
                font-size: 18px;
                width: 300px;
            }
        }
    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .illustration-header {

            height: 300px;
            padding: 60px 0 0 0;

            &::before {
                clip-path:none;
            }

            .opacity-light-section {
                flex-direction: column-reverse;
                
    
                .left {
                    width: 96%;
                    margin: 0 auto;
                    height: 100%;
                    display: flex;
                    padding: 0;
                    justify-content: center;
                    flex-direction: column;
                    box-sizing: border-box;
    
                    h1 {
                        font-size: 24px;
                        text-align: center;
                    }

                    h2 {
                        font-size: 20px;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                        padding: 20px 0;
                        width: 80%;
                        margin: 0 auto;
                    }
                }
                img {
                    margin: 10px auto 0;
                    max-width:260px;
                    display: none;
                }
            }
        }

        .section-job-def {
            
            padding-top: 20px;

            .title-wrapper .para-mkd .title-wrapper-line {
                margin: 0px 0 0;
                h2 {
                    font-size: 20px !important;
                    margin-left: 0;

                    .separator-blue {
                        width: 100px;
                        right: calc(50% - 40px);
                    }
                }
            }

            .title-wrapper .para-mkd {

                width: 100%;
                box-shadow: none;
                h3 {
                    margin: 10px 0;
                }

                p {
                    text-align: left;
                }
            }
    
            .def-wrapper {
                .salary-wrapper {
                    padding-bottom: 40px;
                    text-align: center;
                }
            }

            .title-wrapper .list-job {
                h2.link-pole-title {
                    margin: 0 0 10px;
                    font-size: 20px;
                }
            }
        }

        .section-formation-list {
            padding: 50px 0px 50px 0px;
            h2 {
                font-size:30px;
                margin: 20px 0;
                width: 100%;
                box-sizing: border-box;

                .separator-blue {
                    width: 100px;
                }
            }
        }

        .section-mission-list {

            .list-wrapper {
                flex-direction: column;

                .separator-blue {
                    display: none;
                }

                .left {
                    width: 100%;
                }
            }
            h2 {
                font-size:30px;
                margin: 20px;
                width: 100%;
                box-sizing: border-box;
                text-align: center;

                .separator-blue {
                    width: 100px;
                    right: calc(50% - 50px);
                }
            }
        }

        .section-evolution-job {

            .evo-list {
                margin-top: 0;

                img {
                    width: 150px;
                }
            }

            h2 {
                font-size:30px;
                margin: 20px;
                width: 100%;
                box-sizing: border-box;
                text-align: center;

                .separator-blue {
                    width: 100px;
                    right: calc(50% - 50px);
                }
            }
        }

        .section-perspective-graph {
            h2 {
                font-size:30px;
                margin: 20px;
                width: 100%;
                box-sizing: border-box;
                text-align: center;

                .separator-blue {
                    width: 100px;
                    right: calc(50% - 50px);
                }
            }
            
            .recharts-wrapper {
                width: 96% !important;
                text-align: center;

                svg {
                    width: 100% !important;
                    max-width: 500px !important;
                }
            }
        }

        .section-btn-bottom {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .btn-right {
                margin: 20px 0 0 0;
            }

            .btn-right, .btn-left {
                font-size: 18px;
                width: 300px;
            }
        }
    }
`;

export default JobFicheStyled;