export const SAVE_DATA_APPRENANT_FROM_CSV = 'SAVE_DATA_APPRENANT_FROM_CSV'
export const FETCH_LMS_APPRENANT = 'FETCH_LMS_APPRENANT'
export const SAVE_FORMATION_APPRENANT = 'SAVE_FORMATION_APPRENANT'
export const FETCH_WEFOR_ACCOUNT = 'FETCH_WEFOR_ACCOUNT'
export const FETCH_FACTURE_COLLABS = 'FETCH_FACTURE_COLLABS'
export const SAVE_FACTURE_COLLABS = 'SAVE_FACTURE_COLLABS'
export const CHANGE_CLICKED_FACTURE_COLLABS = 'CHANGE_CLICKED_FACTURE_COLLABS'
export const FETCH_GET_PRE_INSCRIPTION = 'FETCH_GET_PRE_INSCRIPTION'
export const SAVE_PRE_INSCRIPTION = 'SAVE_PRE_INSCRIPTION'
export const FETCH_POST_USER_FROM_PRE_INSCRIPTION = 'FETCH_POST_USER_FROM_PRE_INSCRIPTION'
export const FETCH_PUT_PRE_INSC = 'FETCH_PUT_PRE_INSC'
export const FETCH_GET_PRE_INSCRIPTION_GODFATHER = 'FETCH_GET_PRE_INSCRIPTION_GODFATHER'
export const SAVE_PRE_INSCRIPTION_GODFATHER = 'SAVE_PRE_INSCRIPTION_GODFATHER'
export const FETCH_GET_GODFATHER_INFO = 'FETCH_GET_GODFATHER_INFO'
export const SAVE_GODFATHER_INFO = 'SAVE_GODFATHER_INFO'
export const FETCH_DELETE_USER_FROM_PRE_INSCRIPTION = 'FETCH_DELETE_USER_FROM_PRE_INSCRIPTION'

//NEW

export const SAVE_USERS_P = 'SAVE_USERS_P'
export const FETCH_GET_USERS_P = 'FETCH_GET_USERS_P'

//NEW
export const SAVE_USERS_E = 'SAVE_USERS_E'
export const FETCH_GET_USERS_E = 'FETCH_GET_USERS_E'

export const saveDataApprenantFromCSV = (data) => ({
    type: SAVE_DATA_APPRENANT_FROM_CSV,
    data,
})

export const fetchLmsApprenant = (name, id) => ({
    type: FETCH_LMS_APPRENANT,
    name,
    id
})


export const saveFormationApprenant = (data, id) => ({
    type: SAVE_FORMATION_APPRENANT,
    data,
    id
})

export const fetchWeforAccount = (email, id) => ({
    type: FETCH_LMS_APPRENANT,
    email,
    id
})

export const fetchFactureCollabs = () => ({
    type: FETCH_FACTURE_COLLABS,
 })

 export const saveFactureCollabs = (data) => ({
    type: SAVE_FACTURE_COLLABS,
    data
})

export const changeClickedFactureCollabs = (factureId) => ({
     type: CHANGE_CLICKED_FACTURE_COLLABS,
     factureId
})

export const fetchGetPreInscription = () => ({
    type: FETCH_GET_PRE_INSCRIPTION,
})

export const savePreInscription = (data) => ({
    type: SAVE_PRE_INSCRIPTION,
    data
})

export const fetchPostUserFromPreInscription = (data) => ({
    type: FETCH_POST_USER_FROM_PRE_INSCRIPTION,
    data
})

export const fetchPutPreInsc = (data) => ({
    type: FETCH_PUT_PRE_INSC,
    data
})

export const fetchGetPreInscriptionGodfather = () => ({
    type: FETCH_GET_PRE_INSCRIPTION_GODFATHER,
})

export const savePreInscriptionGodfather = (data) => ({
    type: SAVE_PRE_INSCRIPTION_GODFATHER,
    data
})

export const fetchGetGodfatherInfo = () => ({
    type: FETCH_GET_GODFATHER_INFO,
})

export const saveGodfatherInfo = (data) => ({
    type: SAVE_GODFATHER_INFO,
    data
})

export const fetchDeleteUserFromPreInscription = (data) => ({
    type: FETCH_DELETE_USER_FROM_PRE_INSCRIPTION,
    data
})

export const fetchGetUsersP = (data) => ({
    type: FETCH_GET_USERS_P,
    data
})

export const saveUsersP = (data) => ({
    type: SAVE_USERS_P,
    data
})

export const fetchGetUsersE = (data) => ({
    type: FETCH_GET_USERS_E,
    data
})

export const saveUsersE = (data) => ({
    type: SAVE_USERS_E,
    data
})