import styled from 'styled-components';

const AccountAdminFactureStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

    .right-wrapper {
        width: calc(100% - 250px);
        
        .full-width-tabs {
            width: 100%;

            .MuiTabs-flexContainer {
                width: 100%;

                button {
                    width: calc(100% / 3)
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(100% - 250px);

            h1 {
                font-size: 45px;
            }

            h2 {
                font-size: 35px;
            }
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(100% - 250px);

            h1 {
                font-size: 40px;
            }

            h2 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {
        margin: 60px auto 80px;
        
        .right-wrapper {
            width: calc(100%);

            h1 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }

            h2 {
                font-size: 25px;
                text-align: center;

                .separator-blue {
                    width: 50px;
                    position: static;
                    margin: 10px auto;
                }
            }
        }
    }
}
  
`;

export default AccountAdminFactureStyled;