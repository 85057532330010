import axios from 'axios'
import * as qs from 'qs'
import { gql } from 'graphql-request';

import urlBackEnd from '../assets/js/urlBackEnd';
import urlGraphQl from '../assets/js/urlGraphQl';

import { FETCH_SECTEURS, saveSecteurs, FETCH_DOMAINES, saveDomaines, fetchFiche, saveFiche, FETCH_FICHE } from '../actions/menu';

//const URL = "http://localhost:1338/api/";

// d9c884135fab4d0a8c8dabf61e6041cf

const URL = urlBackEnd;
const querySections = qs.stringify({
    populate: {
      domaines: {
        fields: ["id", "nom"]
      },
      formations: {
        fields: ["id"],
        filters: {
          active: true,
        }
      },
      offre_emplois: {
        fields: ["id"]
      },
    },
    fields: ["id", "nom", "slug"],
    filters: {
      $or: [
        {
          formations: {
            id: {
              $notNull: true,
            },
            active: true,
          }
        },
        {
          offre_emplois: {
            id: {
              $notNull: true,
            }
          }
        },
      ]
    },
    sort: ['nom:asc'] 
  }, {
    encodeValuesOnly: true,
  });

const queryDomaines = qs.stringify({
  populate: '*'
}, {
  encodeValuesOnly: true,
});

const secteursMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_SECTEURS: {

      /*
      const query = gql`
      query {
        secteurs(pagination: { page: 1, pageSize: 45 }, sort: "nom:asc") {
          data {
            id
            attributes {
              nom,
              formations { 
                data {id}
              },
              offre_emplois { 
                data {id}
              },
              domaines {
                data {
                  id 
                  attributes {
                    nom
                  }
                }
              }
            }
          }
        }
      }
    `;
      axios.get(urlGraphQl, {
        params: {
          query: query,
        },
      })
        .then((response) => {
          store.dispatch(saveSecteurs(response.data.data.secteurs.data));
        })
        .catch((error) => {
          console.error(error);
        });

        */
        axios.get(`${URL}secteurs?${querySections}`)
        .then((response) => {
          store.dispatch(saveSecteurs(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_DOMAINES: {
      axios.get(`${URL}domaines?${queryDomaines}`)
        .then((response) => {
          store.dispatch(saveDomaines(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_FICHE: {
      axios.get(`${URL}fiche-metiers?${queryDomaines}`)
        .then((response) => {
          store.dispatch(saveFiche(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default secteursMiddleware;