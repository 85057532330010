/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { MdDownloadForOffline } from "react-icons/md"
import { RiBallPenFill } from "react-icons/ri";
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios'
import * as qs from 'qs'

import urlBackEndSave from '../../../assets/js/urlBackEndSave'
import urlBackEnd from '../../../assets/js/urlBackEnd'
const URL = urlBackEnd

/**** import des components ****/


/**** Import d'images' ****/


/**** Import des css ****/


const ContratCourseU = ({ userRole, facture, toggleModalSignature, factureEStatut, facturePStatut }) => {

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };
  
  return (
    <>
      {facture.attributes.contrat_formation.data !== null && (userRole === 'entreprise' ? factureEStatut : facture !== false) &&
        <div className="facture-comp-wrapper">
          <div className="info-wrapper pad">
            <div className="top bold">
              n°{facture.id}
            </div>
            <div>
              {moment(facture.attributes.createdAt).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="info-wrapper pad">
            <div className="top bold little">
              {facture.attributes.nom_formation}
            </div>
            <div>
              {facture.attributes.formation.data.attributes.duree + 'h accessible ' + facture.attributes.formation.data.attributes.duree_acces_formation + ' jours'}
            </div>
          </div>
          <div className="info-wrapper">
            <>
              {facture.attributes.contrat_formation.data !== null ?
              <div className="btn-wrapper">
                <button 
                  className="dl-btn"
                  onClick={() => savePdf(facture.attributes.contrat_formation.data.attributes.url, facture.attributes.contrat_formation.data.attributes.name )}
                >
                  <MdDownloadForOffline />
                  Télécharger
                </button>
                <button 
                  className="dl-btn"
                  onClick={() => toggleModalSignature(true, facture.id, 'contrat', facture)}
                >
                  <RiBallPenFill />
                  Signer
                </button>  
              </div>
              :
              <div className="btn-wrapper">
                <button className="dn-btn"><MdDownloadForOffline /> Télécharger</button>
                <button 
                  className="dn-btn"
                >
                  <RiBallPenFill />
                  Signer
                </button>  
              </div>
              }
            </>
          </div>
          <div className={facture.attributes.signature === false ? "statut-wrapper orange" : "statut-wrapper green"}>
            <div>
              {facture.attributes.signature === false ? 
              <div>Non signé</div>
              :
              <div>Signé</div>
            }
            </div>
          </div>
        </div>
      }
    </>
  )
}

ContratCourseU.propTypes = {
}

export default ContratCourseU