import { connect } from 'react-redux'

import StepTwoE from '../../../components/FinancementSteps/StepTwoE'

import { changeFactureStatut } from '../../../actions/course'


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    changeFactureStatut: (step, mode, link) => {
        dispatch(changeFactureStatut(step, mode, link));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(StepTwoE)