/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import { FaFileAlt } from "react-icons/fa"
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md"
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import StepOne from '../../containers/FinancementSteps/StepOne'
import StepTwo from '../../containers/FinancementSteps/StepTwo'
import StepThree from '../../containers/FinancementSteps/StepThree'
import StepOneE from '../../containers/FinancementSteps/StepOneE'
import StepTwoE from '../../containers/FinancementSteps/StepTwoE'
import StepThreeE from '../../containers/FinancementSteps/StepThreeE'


/**** Import des images ****/


/**** Import des css ****/
import AccountFinancementStyled from './AccountFinancementStyled'

const AccountFinancement = ({
	userRole,
	fetchFactureE,
	factureE,
	fetchFactureP,
	factureP,
	changeClickedFacture,
	clickedFacture,
	isLogged,
	fetchFactureToShow,
	factureToShow,
}) => {
useEffect(() => {
	if(isLogged && userRole !== 'entreprise') {
		fetchFactureP()
	}
	if(isLogged && userRole === 'entreprise') {
		fetchFactureE()
	}
}, []);

useEffect(() => {
	if(clickedFacture === false) {
		changeClickedFacture(factureP && factureP.length > 1 ? factureP[0].id : false)
	}
} , []);

const [menuOne, setMenuOne] = useState(false);

const factureToShowE = () => {
	if (factureE.length > 0) {
		const factureFounded = factureE.find(facture => facture.id === clickedFacture)
		return factureFounded
	} else {
		return false
	}
}

const steps = [
	'Faites votre demande de financement CPF',
	'Session de formation en cours de validation OU paiement CB',
	'Démarrage de la formation',
  ];

  const stepsE = [
	'Faites votre demande de financement après de votre OPCO',
	'Session de formation en cours de validation',
	'Démarrage de la formation',
  ];

  let yourDate = new Date()

  const stepFor = () => {
	if (factureToShow !== false) {
		if(factureToShow.attributes.step === 2 && yourDate.toISOString().split('T')[0] >= factureToShow.attributes.start_session) {
			return 3
		} else if ( factureToShow.attributes.step === 2 && factureToShow.attributes.start_session > yourDate.toISOString().split('T')[0]) {
			return 2
		} else if (factureToShow.attributes.step === 3) {
			return 3
		} else {
			return 1
		}
	}
  }


  const stepForE = () => {
	if (factureToShowE() !== false) {
		if ( factureToShowE().attributes.step === 2) {
			return 2
		} else if (factureToShowE().attributes.step === 3) {
			return 3
		} else  if (factureToShowE().attributes.step === 1) {
			return 1
		} else {
			return factureToShowE().attributes.step
		}
	}
  }

  return (
    <AccountFinancementStyled>
      <Header bcgColor={'#fff'}/>
      <main className="page-wrapper">
      <AccountMenu />
	  {userRole !== 'entreprise' && 
		<section className="right-wrapper">
			<div className="financements-list">
				<div 
				className='drop-down-1' 
				onClick={() => {
					setMenuOne(!menuOne)
				}}>
				<h4 className="bold">Vos financements</h4>
				<div className="bold info-dyna">{factureP.length} dossier{factureP.length > 1 ? 's' : ''}</div>
				<div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
					{menuOne ? 
					<MdArrowDropUp className="arrow-down" />
					:
					<MdArrowDropDown className="arrow-down"/>
					}
				</div>
				</div>
				{factureP.length > 0 && menuOne &&
				<div className='formations-wrapper'>
				{factureP.map((facture, index) => (
					<div 
						key={index} 
						onClick={() => {
							fetchFactureToShow(facture.id)
							changeClickedFacture(facture.id)
							setMenuOne(!menuOne)
						}}
						className="links-facture"
					>
						<p className="list-paragraphe"><FaFileAlt />n°{facture.id + " " + facture.attributes.nom_formation}</p>
						<span className="bold"><Moment format="DD/MM/YYYY" className="bold">{facture.attributes.publishedAt}</Moment></span>
					</div>
				))}
				</div>
				}
			</div>
			<div className="choosed-financement">
				{factureToShow !== undefined && <>
					{factureToShow === false ?
						<p>Choisissez un financement pour afficher son statut.</p>
						:
						<div className='step-container'>
							<Stepper activeStep={stepFor() - 1} alternativeLabel>
								{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
								))}
							</Stepper>
							{stepFor() === 1 && 
								<StepOne thisFinancement={factureToShow} />
							}
							{stepFor() === 2 && 
								<StepTwo thisFinancement={factureToShow} />
							}
							{stepFor() === 3 && 
								<StepThree thisFinancement={factureToShow} />
							}
						</div>
					}
				</>}
			</div>
		</section>
	  }
	  {userRole === 'entreprise' && 
		<section className="right-wrapper">
			<div className="financements-list">
				<div 
				className='drop-down-1' 
				onClick={() => {
					setMenuOne(!menuOne)
				}}>
				<h4 className="bold">Vos financements</h4>
				<div className="bold info-dyna">{factureE.length} dossier{factureE.length > 1 ? 's' : ''} disponible{factureP.length > 1 ? 's' : ''}</div>
				<div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
					{menuOne ? 
					<MdArrowDropUp className="arrow-down" />
					:
					<MdArrowDropDown className="arrow-down"/>
					}
				</div>
				</div>
				{factureE.length > 0 && menuOne &&
				<div className='formations-wrapper'>
				{factureE.map((facture, index) => (
					<div 
						key={index} 
						onClick={() => {
							changeClickedFacture(facture.id)
							setMenuOne(!menuOne)
						}}
						className="links-facture"
					>
						<p className="list-paragraphe"><FaFileAlt />n°{facture.id + " " + facture.attributes.nom_formation}</p>
						<span className="bold"><Moment format="DD/MM/YYYY" className="bold">{facture.attributes.publishedAt}</Moment></span>
					</div>
				))}
				</div>
				}
			</div>
			<div className="choosed-financement">
				{factureToShowE() !== undefined && <>
					{factureToShowE() === false ?
						<p>Choisissez un financement pour afficher son statut.</p>
						:
						<div className='step-container'>
							<Stepper activeStep={stepForE() - 1} alternativeLabel>
								{stepsE.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
								))}
							</Stepper>
							{stepForE() === 1 && 
								<StepOneE thisFinancement={factureToShowE()} />
							}
							{stepForE() === 2 && 
								<StepTwoE thisFinancement={factureToShowE()} />
							}
							{stepForE() === 3 && 
								<StepThreeE thisFinancement={factureToShowE()} />
							}
						</div>
					}
				</>}
			</div>
		</section>
	  }
      </main>
    </AccountFinancementStyled>
  )
}

AccountFinancement.propTypes = {
}

export default AccountFinancement