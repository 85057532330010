import { connect } from 'react-redux'

import FormationsByName from '../../pages/FormationsByName'
import { getFormationFromParams, fetchFormations, saveSlugs, saveFormationFromParams,setFullSlugCourse } from '../../actions/course'
import { setFilter, setFilterId } from '../../actions/menu'
import { openModal } from '../../actions/modal'

const mapStateToProps = (state) => ({
	currentProfilUser: state.menu.currentProfilUser,
	formationFromParams: state.course.formationFromParams,
	filterSecteurId: state.menu.filterSecteurId,
	secteurCourseFilter: state.menu.secteurCourseFilter,
	secteurs: state.menu.secteursData,
	cpfCourseFilter: state.menu.cpfCourseFilter,
	filterCpfId: state.menu.filterCpfId,
	fullSlugCourse: state.course.fullSlugCourse,
	activCourseId: state.course.activCourseId,
	isLogged: state.login.isLogged,
	formationsPagination: state.course.formationsPagination,
	userRole: state.login.userRole,
	templatesLms: state.lms.templatesLms,
	coursesLms: state.lms.coursesLms,
	slugSecteur: state.course.slugSecteur,
    slugKeyword: state.course.slugKeyword,
    slugFormation: state.course.slugFormation,
})

const mapDispatchToProps = (dispatch) => ({
	getFormationFromParams: (secteur, name, keyword, paramObj) => {
		dispatch(getFormationFromParams(secteur, name, keyword, paramObj))
	},
	fetchFormations: (paramObj) => {
		dispatch(fetchFormations(paramObj))
	},
	setFilter: (name, title) => {
		dispatch(setFilter(name, title));
	  },
	setFilterId: (name, title) => {
		dispatch(setFilterId(name, title));
	},
	saveSlugs: (name, slug) => {
		dispatch(saveSlugs(name, slug))
	},
	openModal: (name) => {
        dispatch(openModal(name));
    },
	saveFormationFromParams: (status, data) => {
        dispatch(saveFormationFromParams(status, data));
    },
	setFullSlugCourse: (string) => {
        dispatch(setFullSlugCourse(string))
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(FormationsByName)