import hmacSHA256 from 'crypto-js/hmac-sha256'
import Blowfish from 'es6-sladex-blowfish';

const TransId = 245524
const MerchantID = 'BNP_WEFORTEST_ECOM_t'
const Amount = 0.25
const Currenncy = 'EUR'
const HMACPWD = 'Pf5(2!gX?bG8xK6]Y7=peT9_[4jDH*3t'
const blowfishpwd = 'p*9M_Ka6n5Z?2(oH'
const URLNotify = 'http://localhost:1338/api/autofinancements'
const URLSuccess = 'https://wefor.fr/account/factures'
const URLFailure = 'http://localhost:1338/api/autofinancements'
const URLTOSEND = 'https://paymentpage.axepta.bnpparibas/payssl.aspx?'


const cryptoAxepta = () => {
	const HmacData = hmacSHA256(`*${TransId}*${MerchantID}*${Amount}*${Currenncy}`, HMACPWD).toString().toUpperCase()


	const strDatas = `MerchantID=${MerchantID}&MsgVer=2.0&TransID=${TransId}&RefNr=0000000AB123&Amount=${Amount}&Currency=${Currenncy}&URLNotify=${URLNotify}&URLSuccess=${URLSuccess}&URLFailure=${URLFailure}&MAC=${HmacData}&OrderDesc=Essai:0000`

	const encrypted = Blowfish.encrypt(strDatas, blowfishpwd,{cipherMode: 0, outputType: 1});

	const url = `${URLTOSEND}MerchantID=${MerchantID}&Len=${strDatas.length}&Data=${encrypted}`

	const decrypted = Blowfish.decrypt("FCA04961F63BC978C430C802C5F50447AD0909DD7E52BCCA5FA0F47C6723047B98CDBBAAA4091E7E98CDBBAAA4091E7E98CDBBAAA4091E7E1292898728570EF19512AA17C9685A6E5476DF4CB5BD08553C0B7275220426327C9C5F308AD3345DD35E41355FFF1DE53EFCCDB76D23B57212669EBCFBB783D8A7DB085762DA72A6FD5041B5D649AB89B400F1EF71F8F2FDC7A9DDDF0D0251D43EE7BABF63CE8805BFDDFA2571C87E564B4EB7C14D0B4E48B4357BD298773AAD2B10889D917A2EB53B0C356D90A5C550", blowfishpwd,{cipherMode: 0, outputType: 1});


	return url
};

  export default cryptoAxepta;