import styled from 'styled-components';

const JobLiStyled = styled.div`
  background-color: #6399ce;
  padding: 8px;
  color: #fff;
  text-align: center;
  width: 220px;
  z-index: 100;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 100;

  .waves {
      margin: 10px;
      height: 20px;
   }

  .account-form-comp {
     display: flex;
     flex-direction: column;
     margin-bottom: 8px;
     
     input {
        margin-bottom: 8px;
        width: 100%;
        border: 0;
        padding: 15px 10px;
        background-color: #fff;
        box-sizing: border-box;

        &::placeholder {
           font-size: 15px;
           color: #656464;
        }
     }

     .password-item {
         position: relative;

         .eye-wrapper {
            position: absolute;
            top: 16px !important;
            left: 90px !important;
            color: #6399ce;
         }
      }

     .darkblue-btn {
        background-color: #202c3c;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        margin-bottom: 0;
     }
  }

  .link-wrapper {
     display: flex;
     align-items: center;
     flex-direction: column;

     a {
      margin: 15px 0;

         &:hover {
            transform: scale(1) !important;
         }
      }
     span {
        display: flex;
        padding: 10px;
        border-radius: 15px;
        background-color: #fff;
        color: #202c3c;

        svg {
           margin-right: 5px;
        }

        p {
           color: #202c3c;
           font-size: 14px !important;
        }
     }
  }
  a {
     color: #fff !important;
     font-size: 14px !important;
     display: block;
     margin-bottom: 10px;
  }

  .menu-account-nav {

   .btn-deconnexion {
         display: flex;
         align-items: center;
         background-color: #dc4a46;
         color: #fff;
         font-size: 14px;
         border-radius: 25px;
         padding: 10px 0px;
         width: 120px;
         margin-top: 10px;
         margin: 10px 0;
         justify-content: center;
         margin-left: 50px;
         cursor: pointer;
         box-sizing: border-box;
         border: 1px solid #dc4a46;

         &:hover {
            background-color: rgba(220,74,70, .8);
        }
      }

     ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;


        li.li-connected {
           display: flex;
           flex-wrap: nowrap !important;
           flex-direction: row;
           width: auto;
           align-items: center;
           justify-content: start !important;
           padding: 0;
           padding: 10px 0;
           cursor: pointer;
           transition: all .2s ease;

           a { 
              margin: 0;
              justify-content: start !important;

              &:hover {
                 transform: scale(1.05) !important;
                 color: #202c3c;
              }
            }

            svg {
               color: #202c3c;
               margin-right: 10px;
               font-size: 20px;
            }

           img {
              width: 40px;
           }
        }
     }
  }

@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1079px) {

}

@media only screen and (min-width: 0px) and (max-width: 759px) {
   padding: 4px;
   .menu-account-nav {
      
      .btn-deconnexion {
         display: block !important;
         margin: 0 auto;
      }
      ul {

         li.li-connected {
            width: 100% !important;
            text-align: left;

            a {
               width: calc(100% - 30px);
            }

            svg {
               margin: 0;
               padding: 0 5px 0 0;
               width: 30px;
               font-size: 16px;
               text-align: left;
            }
      }
   }
}
`;

export default JobLiStyled;