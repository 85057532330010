/**** import des modules ****/
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**** import des images ****/
import society from '../../../assets/img/illustration/EMPLOYEUR-opti.png'
import privateimg from '../../../assets/img/illustration/CANDIDAT-opti.png'

/**** import des css ****/
import YouAreStyled from './YouAreStyled'

const YouAre = ({changeSigninStep}) => {
  return (
    <YouAreStyled>
      <h2 className="bold">Vous êtes<span className="separator-blue"></span></h2>
      <section className="choices-wrapper">
        <div className="left">
          <img src={privateimg} alt="affiche avec profiles particuliers rouge" />
          <button 
            className="btn-blue bold"
            onClick={() => changeSigninStep(2.1)}
          >
            Un particulier
          </button>
          <p className="bold">Pourquoi créer un compte ?</p>
          <ul>
            <li>- Je souhaite me former rapidement</li>
            <li>- Je veux décrocher le job de mes rêves !</li>
          </ul>
        </div>
        <div className="right">
          <img src={society} alt="affiche avec une loupe et profiles particuliers rouge" />
          <button 
            className="btn-blue bold"
            onClick={() => changeSigninStep(2.2)}
          >
            Une entreprise
          </button>
          <p className="bold">Pourquoi créer un compte ?</p>
          <ul>
            <li>- Je souhaite recruter de nouveaux talents</li>
            <li>- Je souhaite former mes collaborateurs</li>
          </ul>
        </div>
      </section>
    </YouAreStyled>
  )
}

YouAre.propTypes = {
}

export default YouAre