import { connect } from 'react-redux'

import PopupKnowMore from '../../components/PopupKnowMore'

import { changeInputValue, fetchSendEmailContact, setFullPageLoaderContact } from '../../actions/actions'

const mapStateToProps = (state) => ({
    hello: "Hello"
})

const mapDispatchToProps = (dispatch) => ({
    changeInputValue: (value, name) => {
        dispatch(changeInputValue(value, name));
    },
    fetchSendEmailContact: () => {
        dispatch(fetchSendEmailContact());
    },
    setFullPageLoaderContact: (bool) => {
        dispatch(setFullPageLoaderContact(bool));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(PopupKnowMore)