import { connect } from 'react-redux'

import JobFiche from '../../pages/JobFiche'

import { fetchThisJobOffer, fetchThisJobFicheWithSlug } from '../../actions/job'

const mapStateToProps = (state) => ({
    jobFicheId: state.job.jobFicheId,
    currentJobFiche: state.job.currentJobFiche,
    slugFiche: state.job.slugFiche
})

const mapDispatchToProps = (dispatch) => ({
    fetchThisJobOffer: (id) => {
        dispatch(fetchThisJobOffer(id));
    },
    fetchThisJobFicheWithSlug: (slug) => {
        dispatch(fetchThisJobFicheWithSlug(slug));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(JobFiche)