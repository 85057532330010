import { connect } from 'react-redux'

import ModalJobInscription from '../../../components/ModalInscription/ModalJobInscription'

import { 
    closeModal,
} from '../../../actions/modal'

import { 
    fetchDemandeFormation,
    fetchFormations
} from '../../../actions/course'

import { fetchJobPostuler, setFullPageLoaderJobInsc, toggleModalPostulerTuto, setJobIdModalPostulerTuto, setCvPosted, setDidTest } from '../../../actions/job'

import { 
    fetchCvParticulier
  } from '../../../actions/signin'

import { fetchGetScoreRecrutement } from '../../../actions/login'

const mapStateToProps = (state) => ({
    prenom: state.login.session.user.prenom,
    jobs: state.job.jobs,
    jobsHome: state.job.jobsHome,
    activJobId: state.job.activJobId,
    user: state.login.session.user,
    userInfo: state.login.userInfo.data[0],
    redirectToMyFinancement: state.course.redirectToMyFinancement,
    formations: state.course.formations,
    scoreRecrutementObject: state.login.scoreRecrutementObject,
    jobInfo: state.job.jobInfo
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: (name) => {
        dispatch(closeModal(name));
    },
    fetchJobPostuler: (id) => {
        dispatch(fetchJobPostuler(id));
    },
    fetchDemandeFormation: (id) => {
        dispatch(fetchDemandeFormation(id));
    },
    fetchCvParticulier: (file, particulierId) => {
        dispatch(fetchCvParticulier(file, particulierId));
    },
    setFullPageLoaderJobInsc: (bool) => {
        dispatch(setFullPageLoaderJobInsc(bool));
    },
    fetchGetScoreRecrutement: (bool) => {
        dispatch(fetchGetScoreRecrutement(bool));
    },
    toggleModalPostulerTuto: (bool) => {
        dispatch(toggleModalPostulerTuto(bool));
    },
	setJobIdModalPostulerTuto: (id) => {
        dispatch(setJobIdModalPostulerTuto(id));
    },
	setCvPosted: (bool) => {
        dispatch(setCvPosted(bool));
    },
	setDidTest: (bool) => {
        dispatch(setDidTest(bool));
    },
    fetchFormations: (paramObj) => {
        dispatch(fetchFormations(paramObj));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalJobInscription)

