import { 
  CHANGE_ACTIV_JOB_ID,
  CHANGE_VALUE_NEWJOB,
  CHANGE_VALUE_MODIF_JOB,
  SAVE_JOBS,
  SAVE_JOBS_HOME,
  SAVE_JOBS_PAGINATION,
  CHANGE_LOAD_JOBS,
  SAVE_CURRENT_JOB_FICHE,
  SET_JOB_FICHE_ID,
  SAVE_JOBS_ENTREPRISE,
  SAVE_JOB_FICHES,
  SET_SELECTION_JOB_OFFER,
  SAVE_DISTANCE_ON_JOBS,
  SET_SHOW_DISTANCE,
  CHANGE_JOBS_ACCOUNT_LOADING,
  SET_FULL_PAGE_LOADER_DISTANCE,
  SET_FULL_PAGE_LOADER_JOBINSC,
  SET_FULL_PAGE_LOADER_JOB_OFFER_ACTION,
  SAVE_DEMANDES_FROM_JOB_OFFER,
  SAVE_MY_SCORES_FORMATION,
  TOGGLE_MODAL_POSTULER_TUTO,
  SET_JOB_ID_MODAL_POSTULER_TUTO,
  SET_CV_POSTER,
  SET_DID_TEST,
  SET_DID_OCEAN,
  SET_JOB_FOR_MODAL_POSTULER,
  SAVE_JOBS_ENTREPRISE_PAGE_CANDIDAT,
  SAVE_CANDIDATS_FROM_JOB,
  SET_FULL_PAGE_LOADER_CANDIDAT,
  SET_OFFRE_ID_HP,
  SET_MODAL_JOBS_ACCOUNT,
  SET_MUI_JOB_FILTER_KEY_VALUE,
  SAVE_SLUG_FICHE,
  SAVE_JOB_INFO
} from "../actions/job";

export const initialState = {
  activJobId: 1,
  loadJobs: true,
  jobFicheId: 1,
  jobsEntreprise: [],
  jobsEntreprisePagination: {},
  jobsEntreprisePageCandidat: [],
  candidatFromJob: [],
  domaineJob: 1,
  secteurJob: 1,
  ficheJob: '1',
  remunerationjob: '',
  descriptifjob: '',
  missionjob: '',
  profiljob: '',
  selectionJobOffer: '',
  jobs: [],
  jobsPagination: {},
  jobsHome: [],
  jobsFiches: [],
  jobsWithDistances:[],
  showDistance: false,
  jobsAccountLoading: false,
  fullPageLoaderDistance: false,
  fullPageLoaderJobInsc: false,
  fullPageLoaderJobOfferAction: false,
  demandesFromJobOffer: [],
  myScoresFormation: [],
  isModalPostulerTutoOpen: false,
  jobIdModalPostulerTuto: '',
  didTest: false,
  didOcean: false,
  cvPosted: false,
  didCourse: false,
  descriptifjobPut: '',
  nameOfCourse: '',
  missionjobPut: '',
  profiljobPut: '',
  jobForModalPostuler: [],
  candidatFromJobPagination: {},
  fullPageLoaderCandidatFromJob: false,
  offreIdHP: '',
  modalJobsAccount: false,
  muiJobFilterKeyValue: null,
  slugFiche: 'loading',
  jobInfo: false
};

// reducer = traducteur d'une intention/action vers une modification du state
const job = (state = initialState, action = {}) => {
  switch (action.type) {
      case CHANGE_ACTIV_JOB_ID:
        return {
          ...state,
          activJobId: action.id,
        };
      case CHANGE_VALUE_NEWJOB:
        return {
          ...state,
          [action.name]: action.value,
        };
      case CHANGE_VALUE_MODIF_JOB:
        return {
          ...state,
          [action.name]: action.value,
        };
      case SAVE_JOBS:
        return {
          ...state,
          jobs: action.jobs,
        };
      case SAVE_JOBS_PAGINATION:
        return {
          ...state,
          jobsPagination: action.data,
        };
      case SAVE_JOBS_HOME:
        return {
          ...state,
          jobsHome: action.jobs,
        };
      case CHANGE_LOAD_JOBS:
        return {
          ...state,
          loadJobs: action.bool,
        };
      case SAVE_CURRENT_JOB_FICHE:
        return {
          ...state,
          currentJobFiche: action.data,
        };
      case SET_JOB_FICHE_ID:
        return {
          ...state,
          jobFicheId: action.id,
        };
      case SAVE_JOBS_ENTREPRISE:
        return {
          ...state,
          jobsEntreprise: action.data,
          jobsEntreprisePagination: action.dataPage,
          jobsAccountLoading: false
      }
      case SAVE_JOBS_ENTREPRISE_PAGE_CANDIDAT: 
      return {
        ...state,
        jobsEntreprisePageCandidat: action.data,
        jobsAccountLoading: false
      }
      case SAVE_JOB_FICHES:
        return {
          ...state,
          jobFiches: action.data
      };
      case SET_SELECTION_JOB_OFFER:
        return {
          ...state,
          selectionJobOffer: action.data
      };
      case SAVE_DISTANCE_ON_JOBS:
        return {
          ...state,
          jobsWithDistances: action.array
      };
      case SET_SHOW_DISTANCE:
        return {
          ...state,
          showDistance: action.bool
      };
      case CHANGE_JOBS_ACCOUNT_LOADING:
        return {
          ...state,
          jobsAccountLoading: action.bool
      };
      case SET_FULL_PAGE_LOADER_DISTANCE:
        return {
          ...state,
          fullPageLoaderDistance: action.bool
      };
      case SET_FULL_PAGE_LOADER_JOBINSC:
        return {
          ...state,
          fullPageLoaderJobInsc: action.bool
      };
      case SET_FULL_PAGE_LOADER_JOB_OFFER_ACTION:
        return {
          ...state,
          fullPageLoaderJobOfferAction: action.bool
      };
      case SAVE_DEMANDES_FROM_JOB_OFFER:
        return {
          ...state,
          demandesFromJobOffer: state.demandesFromJobOffer.concat(action.data)
      };
      case SAVE_MY_SCORES_FORMATION:
        return {
          ...state,
          myScoresFormation: action.data
      };
      case TOGGLE_MODAL_POSTULER_TUTO:
        return {
          ...state,
          isModalPostulerTutoOpen: action.bool
        };
      case SET_JOB_ID_MODAL_POSTULER_TUTO:
        return {
          ...state,
          jobIdModalPostulerTuto: action.id,
          nameOfCourse: action.name
        };
      case SET_CV_POSTER:
        return {
          ...state,
          cvPosted: action.bool
        };
      case SET_DID_TEST:
        return {
          ...state,
          didTest: action.bool
        };
      case SET_DID_OCEAN:
      return {
        ...state,
        didOcean: action.bool
      };
      case SET_JOB_FOR_MODAL_POSTULER:
        return {
          ...state,
          jobForModalPostuler: action.data
        };
      case SAVE_CANDIDATS_FROM_JOB:
        return {
          ...state,
          candidatFromJob: action.data,
          candidatFromJobPagination: action.dataPage
        };
      case SET_FULL_PAGE_LOADER_CANDIDAT:
        return {
          ...state,
          fullPageLoaderCandidatFromJob: action.bool
        };
      case SET_OFFRE_ID_HP:
        return {
          ...state,
          offreIdHP: action.id
        };
      case SET_MODAL_JOBS_ACCOUNT:
        return {
          ...state,
          modalJobsAccount: action.bool
        };
      case SET_MUI_JOB_FILTER_KEY_VALUE: 
        return {
          ...state,
          muiJobFilterKeyValue: action.value
      }
      case SAVE_SLUG_FICHE: 
        return {
          ...state,
          slugFiche: action.data
        }
      case SAVE_JOB_INFO:
        return {
          ...state,
          jobInfo: action.data,
        }
    default:
      return state;
  }
};

export default job;