/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'
import { HashLink } from 'react-router-hash-link'
import PropTypes from 'prop-types'
import { useNavigate, Link, Navigate } from 'react-router-dom'
import { FaPenAlt, FaFileDownload, FaFilter , FaInfoCircle , FaChevronCircleDown, FaChevronCircleUp} from "react-icons/fa"
import { MdLooksOne, MdLooksTwo, MdLooks3, MdLooks4, MdWarning, MdInfo, MdDownload } from "react-icons/md" 
import { GoArrowDown, GoCreditCard } from "react-icons/go"  
import { VscCircleFilled} from "react-icons/vsc"
import { BsCheck2Circle } from "react-icons/bs"
import Moment from 'react-moment'
import { saveAs } from 'file-saver'
import PaginationMui from '@mui/material/Pagination'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Switch from '@mui/material/Switch'
import ReactLoading from 'react-loading'
import moment from 'moment'
import cryptyAxepta from '../../assets/js/cryptoAxepta'
import Skeleton from '@mui/material/Skeleton'
import urlBackEndSave from '../../assets/js/urlBackEndSave'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import Stack from '@mui/material/Stack'
import dayjs from 'dayjs'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import ModalMessage from '../../containers/ModalMessage'
import FactureCourse from '../../containers/FactureCourse'
import ContratCourse from '../../containers/ContratCourse'
import ModalCreateLMSSession from '../../containers/ModalCreateLMSSession'


/**** Import des images ****/
import pdfPng from '../../assets/img/logo/pdf.png'
import documentM from '../../assets/img/illustration/document.png'
import cpfLogo from '../../assets/img/logo/thumbnail_cpf.png'
import cbLogo from '../../assets/img/logo/logo-cartes-paiements.jpg'
import atlasLogo from '../../assets/img/logo/atlas.svg'



/**** Import des css ****/
import AccountFactureStyled from './AccountFactureStyled'
import cryptoAxepta from '../../assets/js/cryptoAxepta'

const MERCHANTIDAXEPTA = 'BNP_WEFORTEST_ECOM_t'

const AccountFacture = ({
  userRole,
  isLogged,
  fetchFactureE,
  factureE,
  fetchFactureP,
  factureP,
  userStatut,
  changeClickedFacture,
  clickedFacture,
  fetchPutModeFinancement,
  changeStepValue,
  fetchUploadEdofFile,
  menuDocument,
  fetchInscriptionUserLms,
  fetchCreateSessionLms,
  fetchStatutFormation,
  redirectToMyFinancement,
  fetchPutCpfToZero,
  toggleModalMessage,
  modalMessageContent,
  modalMessageIsOpen,
  fetchUserScore,
  userInfo,
  facturePStatut,
  factureEStatut,
  fetchPutOpcoToZero,
  setFullPageLoaderFacturation
}) => {

  useEffect(fetchFactureE, []);
  useEffect(fetchFactureP, []);
  useEffect(fetchUserScore, []);
  useEffect(() => {
    if(clickedFacture === false) {
      changeClickedFacture(factureP && factureP.length > 1 ? factureP[0].id : false)
   }
  } , []);

  const [page, setPage] = useState(1)
  const [cardPerPage, setCardPerPage] = useState(5)
  const [indexOfLastCard, setIndexOfLastCard] = useState(cardPerPage)
  const [indexOfFirstCard, setIndexOfFirstCard] = useState(0)
  const [date, setDate] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [formation, setFormation] = React.useState('');
  const [statutFacture, setStatutFacture] = React.useState('perso');
  const [statutForNewFin, setStatutForNewFin] = React.useState(userStatut);
  const [files, setFiles] = useState(false)
  const [filesPe, setFilesPe] = useState(false)
  const [value, setValue] = React.useState('one');


  redirectToMyFinancement(false)

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  if(!isLogged) {
    return (<Navigate to="/" replace />)
  }

  const handleChange = (event) => {
    setPrice('')
    setDate(event.target.value)
  };

  const handleChangePrice = (event) => {
    setDate('')
    setPrice(event.target.value)
  };

  const handleChangeFormation = (event) => {
    setFormation(event.target.value)
  };

  const changePagesCards = (value) => {
    setIndexOfLastCard(value * cardPerPage)
    setIndexOfFirstCard(value * cardPerPage - cardPerPage)
  }
 
   const handleChangePagination = (event, value) => {   
     setPage(value)
     changePagesCards(value)
   };

   const factureFilters = () => {
     if (factureE.length > 0) {
      let factureEFiltered = factureE.filter((factureItem) => factureItem.attributes.nom_formation.toLowerCase().includes(formation.toLowerCase()))
      if (price !== '' && price === 'asc') {
        factureEFiltered.sort(function(a, b){
          return a.attributes.Prix - b.attributes.Prix
        })
        return factureEFiltered
      } else if (price !== '' && price === 'desc'){
        factureEFiltered.sort(function(a, b){
          return b.attributes.Prix - a.attributes.Prix
        })
        return factureEFiltered
      } else if (date !== '' && date === 'asc'){
        factureEFiltered.sort(function(a, b){
          return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
        })
        return factureEFiltered
      } else if (date !== '' && date === 'desc'){
        factureEFiltered.sort(function(a, b){
          return new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt)
        })
        return factureEFiltered
      } else {
        return factureEFiltered
      }
    }
   }

   const facturePFilters = () => {
    if (factureP.length > 0) {
     let facturePFiltered = factureP.filter((factureItem) => factureItem.attributes.nom_formation.toLowerCase().includes(formation.toLowerCase()))
     if (price !== '' && price === 'asc') {
       facturePFiltered.sort(function(a, b){
         return a.attributes.Prix - b.attributes.Prix
       })
       return facturePFiltered
     } else if (price !== '' && price === 'desc'){
       facturePFiltered.sort(function(a, b){
         return b.attributes.Prix - a.attributes.Prix
       })
       return facturePFiltered
     } else if (date !== '' && date === 'asc'){
       facturePFiltered.sort(function(a, b){
         return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
       })
       return facturePFiltered
     } else if (date !== '' && date === 'desc'){
       facturePFiltered.sort(function(a, b){
         return new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt)
       })
       return facturePFiltered
     } else {
       return facturePFiltered
     }
   }
  }

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  const facturePbyP = () => {
    let facturePbyParray = facturePFilters().filter(facture => facture.attributes.mode_financement !== 'Entreprise')
    return facturePbyParray
  }

  const facturePbyE = () => {
    let facturePbyEarray = facturePFilters().filter(facture => facture.attributes.mode_financement === 'Entreprise')
    return facturePbyEarray
  }

  const clickedFactureOjb = () => {
    let factureToShow = factureP.find( facture => facture.id === clickedFacture)
    return factureToShow  
  }

  const clickedFactureOjbE = () => {
    let factureToShow = factureE.find( facture => facture.id === clickedFacture)
    return factureToShow  
  }

  const isItTrueMode = () => {
    if (clickedFactureOjb().attributes.mode_financement === 'Salarié' || clickedFactureOjb().attributes.mode_financement === 'Demandeur d\'emploi') {
      return false
    } else {
      return true
    }
  }

  const isItTrueModeE = () => {
    if (clickedFactureOjbE().attributes.mode_financement === 'Entreprise') {
      return false
    } else {
      return true
    }
  }

  const differentStep = (step) => {
    if (step === 1) {
      return 2
    }
    if (step === 2) {
      return 1
    }
  }

  const calcRest = (coursePrice, cpf, pe) => {
    const rest = coursePrice - cpf - pe;
    return rest
  }

  let euroFormat = new Intl.NumberFormat(["ban", "id"]);

  return (
    <AccountFactureStyled>
      {isLogged &&
      <>
      {/*<ModalCreateLMSSession />*/}
      {modalMessageIsOpen && 
        <ModalMessage />
      }
      <Header bcgColor={'#fff'}/>
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
      {/** Affichage financements **/}
      <>
        {/** Affichage pour une entreprise **/}
        {userRole === 'entreprise' &&
        <section className="facture-wrapper">
          <div className="filter-wrapper">
            <div className="nbf-wrapper">
              <p>Filtres</p>
            </div>
            <div className="date-wrapper">
              <FormControl fullWidth>
                <InputLabel id="date-tri">Date</InputLabel>
                <Select
                  labelId="date-tri"
                  id=""
                  value={date}
                  label="Date"
                  onChange={handleChange}
                >
                  <MenuItem value={'asc'}>La plus recente</MenuItem>
                  <MenuItem value={'desc'}>La moins recente</MenuItem>
                </Select>
            </FormControl>
            </div>
            <div className="nomf-wrapper">
              <TextField id="nomF" value={formation} label="Formation" variant="outlined" onChange={handleChangeFormation}/>
            </div>
            <div className="prix-wrapper">
              <FormControl fullWidth>
                <InputLabel id="price-tri">Prix</InputLabel>
                <Select
                  labelId="price-tri"
                  id=""
                  value={price}
                  label="Date"
                  onChange={handleChangePrice}
                >
                  <MenuItem value={'asc'}>Prix croissant</MenuItem>
                  <MenuItem value={'desc'}>Prix décroissant</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="filter-icon-wrapper">
              <FaFilter />
            </div>
          </div>
          {factureE < 1 && !factureEStatut &&
            <section className="loader-section">
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={100}/>
            </section>
          }
          {factureE < 1 && factureEStatut &&
            <section className="loader-section">
            <p>Vous n'avez fait aucune demande de formation.</p>
            </section>
          }
          {factureE.length > 0 && factureFilters().slice(indexOfFirstCard, indexOfLastCard).map((factureItem) => (
            <div className={"facture-card " + (clickedFacture === factureItem.id ? 'active-facture' : '')} key={factureItem.id} >
              <section className="top-left-info">
                <p className="facture-id bold">Facture-{factureItem.id}</p>
                <p className="grey-txt"> Créée le {" "}
                  <Moment format="DD/MM/YYYY" className="bold">
                    {factureItem.attributes.publishedAt}
                  </Moment>
                </p>
                </section>
                <section className="top-right-info">
                  <p>€ TTC</p>
              </section>
              <section className='info-remake-wrapper green-bcg'>
                  <div className="picto-wrapper">
                  <p className="bold">{factureItem.attributes.nom_formation}</p>
                  </div>
                  <div className="info-facture-wrapper">
                    <p>Démarrez votre financement</p>
                    {factureItem.id === clickedFacture ? 
                    <FaChevronCircleUp className="picto-drop-down" onClick={() => changeClickedFacture((factureItem.id === clickedFacture ? 0 : factureItem.id))} />
                    :
                    <FaChevronCircleDown className="picto-drop-down" onClick={() => changeClickedFacture((factureItem.id === clickedFacture ? 0 : factureItem.id))} />
                    }
                  </div>
                  <div className="info-price-wrapper">
                    <p className="green-txt bold">{euroFormat.format(Number(factureItem.attributes.nombre_particulier) * Number(factureItem.attributes.Prix))}</p>
                  </div>
              </section>
              { clickedFacture === factureItem.id &&
              <section id="facturation-section" className="facturation-wrapper">
                { clickedFactureOjbE() && Object.keys(clickedFactureOjbE()).length > 0 && 
                  <>
                  {(factureItem.attributes.step >= 0) &&
                      <>
                        <div className={factureItem.attributes.opco.data !== null && factureItem.attributes.opco.data.attributes.montant_financement !== null ? 'financement-cpf-wrapper green-bcg' : 'financement-cpf-wrapper'}>
                          {/*** Parcours document pour Entreprise ***/}
                          <div className="left">
                          <img src={atlasLogo} alt='logo mon compte de formation' />
                            <div className="info-text">Financement <span className="bold"> OPCO</span><FaInfoCircle />
                              <span className="popupi-on-hover">                            
                                <div className="info-msg"><div><MdInfo /></div> Si vos droits OPCO sont insuffisant vous pouvez:</div>
                                <ul className="option-list">
                                  <li><VscCircleFilled /> Financer vous mêmes la somme manquante*</li>
                                </ul>
                                <p className="disclaimer">* sur le site: </p>
                              </span>
                            </div>
                            <a href="https://www.opco-atlas.fr/" target='_blank' rel='noreferrer'>opco-atlas.fr</a>
                          </div>
                          <div className="center">
                            <>
                            <div className={factureItem.attributes.opco.data !== null && factureItem.attributes.opco.data.attributes.montant_financement !== null ? 'cash-div' : 'cash-div none'}></div>
                            <form 
                              onSubmit={(e) => {
                              e.preventDefault();
                              }}
                              className="drop-file-wrapper"
                              >
                            <label htmlFor="file-upload" className="file-upload-btn">
                            { factureItem.attributes.opco.data && factureItem.attributes.opco.data.attributes.preuve_financement.data === null ?
                            <></>
                            :
                            <p>{factureItem.attributes.opco.data && factureItem.attributes.opco.data.attributes.preuve_financement.data.attributes.name}</p>
                            }
                              <div><MdDownload /> Déposez votre prise en charge</div>
                              <input 
                                id="file-upload"
                                type="file" 
                                name="upload" 
                                accept="application/pdf,application/vnd.ms-excel" 
                                onChange={(e)=> { 
                                  setFiles(e.target.files)
                                  setFullPageLoaderFacturation(true)
                                  fetchUploadEdofFile(e.target.files, factureItem.attributes.opco.data.id, 'opco')
                                }}
                                style={{
                                  backgroundColor: "#fff !important"
                                }}
                              />
                            </label>
                            { (factureItem.attributes.step < 2) &&
                              <div className="next-step-btn bold" onClick={() => {
                                toggleModalMessage(
                                  `<p>Vous ne disposez pas de financement CPF.</p><p>Merci de bien vouloir procéder au réglement de ${factureItem.attributes.Prix} € par carte bleue.</p> <a href=${cryptoAxepta()} target='_blank' rel='noreferrer' class='paiment-cb bold'>Paiement</a>`
                                  , true)
                                setFullPageLoaderFacturation(true)
                                fetchPutOpcoToZero(factureItem.attributes.opco.data.id)
                              }}>Je ne dispose pas de financement</div>
                            }
                            </form>
                            </>
                          </div>
                          <div className="right">
                            <>
                            <div className="wrapper-cell">
                              <div></div> 
                              <div className="green bold">{euroFormat.format(factureItem.attributes.opco.data !== null && Number(factureItem.attributes.opco.data.attributes.montant_financement) + 0)}</div>
                            </div>
                            </>
                          </div>
                        </div>
                        <div className={factureItem.attributes.autofinancement.data !== null && factureItem.attributes.autofinancement.data.attributes.montant_financement !== null ? 'financement-cpf-wrapper green-bcg' : 'financement-cpf-wrapper'}>
                          <div className="left">
                          <img src={cbLogo} alt='logos de cartes bleues' />
                            <div className="info-text">Paiement <span className="bold"> CB</span> <FaInfoCircle />
                              <span className="popupi-on-hover">                            
                                Vous pouvez payer l'intégralité ou une partie de la facture par carte bleue.
                              </span>
                            </div>
                          </div>
                          <div className="center">
                            <>
                            <a href={cryptoAxepta()} target='_blank' rel='noreferrer' className='paiment-cb bold'>Payez le solde par carte bleue</a>
                            </>
                          </div>
                          <div className="right">
                            <>
                            <div className="wrapper-cell">
                              <div></div> 
                              <div className="green bold">{euroFormat.format(factureItem.attributes.autofinancement.data !== null && Number(factureItem.attributes.autofinancement.data.attributes.montant_financement) + 0)}</div>
                            </div>
                            </>
                          </div>
                        </div>
                        {/*** Btn + total financement ***/}
                        {
                        <div className="total-wrapper">
                          <div className='left'>
                          <div className='bold'>SOLDE FACTURE</div> 
                          </div>
                          <div className='total'>
                          <div className="wrapper-cell">
                              <div className="green bold">{factureItem.attributes.opco.data !== null ? euroFormat.format(Number(factureItem.attributes.Prix * factureItem.attributes.nombre_particulier) - Number(factureItem.attributes.opco.data.attributes.montant_financement)) : euroFormat.format(factureItem.attributes.Prix * factureItem.attributes.nombre_particulier)}</div>
                            </div>
                            <div className="btn-learning-wrapper">
                            {factureItem.attributes.opco.data !== null && Number(factureItem.attributes.Prix * factureItem.attributes.nombre_particulier) === Number(factureItem.attributes.opco.data.attributes.montant_financement) ?
                              <>          
                              <button 
                              className="can-click bold" 
                              onClick={() => {
                                changeStepValue(2)
                                //fetchInscriptionUserLms()
                                //fetchStatutFormation(factureItem.attributes.formation.data.id)
                                }}>
                                Inscrire mes collaborateurs
                              </button>
                            </>
                              :
                              <button className="cannot-click">Inscrire mes collaborateurs</button>
                            }
                            </div>
                          </div>
                        </div>
                        }
                      </>
                      }
                      {/* factureItem.attributes.step > 2 &&
                      <div className="loading-section">
                        <div className="loader-wrapper">Consultez vos email, créez votre compte puis cliquez sur 
                        { (factureItem.attributes.step === 3) &&
                          <span className="validate-btn" onClick={(() => {
                            fetchInscriptionUserLms()
                          })}>Valider</span>
                        }
                        { (factureItem.attributes.step > 3) ?
                          <BsCheck2Circle className="icon-check"/>
                          :
                          <ReactLoading type={'spinningBubbles'} color={"#649ace"} height={25} width={25} />
                        }
                        </div>
                        <div className="loader-wrapper" onClick={(() => {
                          fetchInscriptionUserLms()
                        })}>Création de votre session 
                        { (factureItem.attributes.step > 4) ?
                          <BsCheck2Circle className="icon-check" />
                          :
                          <ReactLoading type={'spinningBubbles'} color={"#649ace"} height={25} width={25} />
                        }
                        </div>
                        <div className="loader-wrapper">Ajout de la formation à votre compte 
                        { (factureItem.attributes.step > 5) ?
                          <BsCheck2Circle className="icon-check" />
                          :
                          <ReactLoading type={'spinningBubbles'} color={"#649ace"} height={25} width={25} />
                        }
                        </div>
                        { (factureItem.attributes.step === 6) &&
                          <div className="btn-go-wrapper"><a href="https://app.360learning.com/" target='_blank' rel='noreferrer' className="formation-btn bold">Formation</a></div>
                        }
                      </div>
                      */}
                  {/*factureItem.attributes.step === 3 &&
                  <>
                    <p>Voici vos informations de connexion</p>
                    <p>lien</p>
                  </>
                    */}
                  </>
                }
              </section>
              }
            </div>
          ))}
          <PaginationMui page={page} className="pagination-pc" count={Math.ceil(factureE.length / cardPerPage)} variant="outlined" color="primary" onChange={handleChangePagination}/>
        </section>
        }
        {/** Affichage pour un particulier **/}
        {userRole === 'particulier' &&
        <section className="facture-wrapper">
          <div className="filter-wrapper">
            <div className="nbf-wrapper">
              <p>Filtres</p>
            </div>
            <div className="date-wrapper">
              <FormControl fullWidth>
                <InputLabel id="date-tri">Date</InputLabel>
                <Select
                  labelId="date-tri"
                  id=""
                  value={date}
                  label="Date"
                  onChange={handleChange}
                >
                  <MenuItem value={'asc'}>La plus recente</MenuItem>
                  <MenuItem value={'desc'}>La moins recente</MenuItem>
                </Select>
            </FormControl>
            </div>
            <div className="nomf-wrapper">
              <TextField id="nomF" value={formation} label="Formation" variant="outlined" onChange={handleChangeFormation}/>
            </div>
            <div className="prix-wrapper">
              <FormControl fullWidth>
                <InputLabel id="price-tri">Prix</InputLabel>
                <Select
                  labelId="price-tri"
                  id=""
                  value={price}
                  label="Date"
                  onChange={handleChangePrice}
                >
                  <MenuItem value={'asc'}>Prix croissant</MenuItem>
                  <MenuItem value={'desc'}>Prix décroissant</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="filter-icon-wrapper">
              <FaFilter />
            </div>
          </div>
          {/* userStatut === 'Salarié' &&
          <ToggleButtonGroup
              value={statutFacture}
              exclusive
              name="accountStatut"
              onChange={(event) => {
                setStatutFacture(event.target.value);
              }}
              aria-label="text alignment"
              className='toggle-button-statut'
            >
              <ToggleButton value="perso" aria-label="left aligned">
                Financement personnel
              </ToggleButton>
              <ToggleButton value="entr" aria-label="right aligned">
                Financement entreprise
              </ToggleButton>
            </ToggleButtonGroup>
            */}
            {statutFacture === 'perso' &&
            <>
            {factureP < 1 && !facturePStatut &&
            <section className="loader-section">
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={100}/>
            </section>
            }
            {factureP < 1 && facturePStatut &&
            <section className="loader-section">
            <p>Vous n'avez fait aucune demande de formation.</p>
            </section>
            }
            {factureP.length > 0 && facturePbyP().slice(indexOfFirstCard, indexOfLastCard).map((factureItem) => (
              <div className={"facture-card " + (clickedFacture === factureItem.id ? 'active-facture' : '')} key={factureItem.id} >
                { statutFacture === 'perso' && clickedFacture !== factureItem.id &&
                <>
                  <section className="top-left-info">
                  <p className="facture-id bold">Facture-{factureItem.id}</p>
                  <p className="grey-txt"> Créée le {" "}
                    <Moment format="DD/MM/YYYY" className="bold">
                      {factureItem.attributes.publishedAt}
                    </Moment>
                  </p>
                  </section>
                  <section className="top-right-info">
                    <p>€ TTC</p>
                  </section>
                  <section className={factureItem.attributes.step > 0 ? 'info-remake-wrapper green-bcg' : 'info-remake-wrapper'}>
                  <div className="picto-wrapper">
                  <p className="bold">{factureItem.attributes.nom_formation}</p>
                  </div>
                  <div className="info-facture-wrapper">
                    <p>Démarrez votre financement</p>
                    {factureItem.id === clickedFacture ? 
                    <FaChevronCircleUp className="picto-drop-down" onClick={() => changeClickedFacture((factureItem.id === clickedFacture ? 0 : factureItem.id))} />
                    :
                    <FaChevronCircleDown className="picto-drop-down" onClick={() => changeClickedFacture((factureItem.id === clickedFacture ? 0 : factureItem.id))} />
                    }
                  </div>
                  <div className="info-price-wrapper">
                    <p className="green-txt bold">{euroFormat.format(Number(factureItem.attributes.nombre_particulier) * Number(factureItem.attributes.Prix))}</p>
                  </div>
                  </section>
                </>
                }
                { statutFacture === 'perso' && clickedFacture === factureItem.id &&
                  <section id="facturation-section" className="facturation-wrapper">
                    { clickedFactureOjb() && Object.keys(clickedFactureOjb()).length > 0 && 
                      <>
                      { (factureItem.attributes.step < 3) && isItTrueMode() &&
                      <>
                        <div className={!isItTrueMode() ? 'green-bcg facturation-choice' : 'facturation-choice'} >
                          <p className="choice-txt">Choix de votre statut <FaInfoCircle /><span className="popupi-on-hover">Attention, le choix de votre statut est important et non modifiable. Avec le statut salarié, vous aurez accès au financement de votre entreprise en plus du CPF. Avec le statut demandeur d'emploi, vous aurez accès au financement de pôle emploi en plus de votre CPF. Les deux cas donnent accès à l'autofinancement complet ou partiel.</span></p>
                          <div className="btn-wrapper">
                          { isItTrueMode() ?
                          <>
                            <button 
                              className={statutForNewFin === 'Salarié' ? 'active-btn bold' : 'bold'}
                              onClick={() => setStatutForNewFin('Salarié')}
                            >Salarié</button>
                            <button 
                              className={statutForNewFin === 'Demandeur d\'emploi' ? 'active-btn bold' : 'bold'}
                              onClick={() => setStatutForNewFin('Demandeur d\'emploi')}
                            >Demandeur d'emploi</button>
                          </>
                          :
                          <>
                            <div>Vous avez choisi ce statut:</div>
                          </>
                          }
                          </div>
                          { isItTrueMode() ?
                          <button className="confirmation-statut-btn bold" onClick={() => {
                            fetchPutModeFinancement(clickedFactureOjb().id, statutForNewFin)
                            setFullPageLoaderFacturation(true)
                          }}>Commencer</button>
                          :
                          <div className="choosed-statut"><div>{factureItem.attributes.mode_financement}</div></div>
                          }
                        </div>
                      </>
                        }
                      <section className="top-left-info">
                        <p className="facture-id bold">Facture-{factureItem.id}</p>
                        <p className="grey-txt"> Créée le {" "}
                          <Moment format="DD/MM/YYYY" className="bold">
                            {factureItem.attributes.publishedAt}
                          </Moment>
                        </p>
                      </section>
                      <section className="top-right-info">
                        <p>€ TTC</p>
                      </section>
                      <section className={factureItem.attributes.step > 0 ? 'info-remake-wrapper green-bcg' : 'info-remake-wrapper'}>
                      <div className="picto-wrapper">
                      <p className="bold">{factureItem.attributes.nom_formation}</p>
                      </div>
                      <div className="info-facture-wrapper">
                        <p>Démarrez votre financement</p>
                        {factureItem.id === clickedFacture ? 
                        <FaChevronCircleUp className="picto-drop-down" onClick={() => changeClickedFacture((factureItem.id === clickedFacture ? 0 : factureItem.id))} />
                        :
                        <FaChevronCircleDown className="picto-drop-down" onClick={() => changeClickedFacture((factureItem.id === clickedFacture ? 0 : factureItem.id))} />
                        }
                      </div>
                      <div className="info-price-wrapper">
                        <p className="green-txt bold">{euroFormat.format(Number(factureItem.attributes.nombre_particulier) * Number(factureItem.attributes.Prix))}</p>
                      </div>
                      </section>
                      { (factureItem.attributes.step < 3) && (factureItem.attributes.step !== 0) &&
                      <>
                        <div className={factureItem.attributes.cpf_edof.data !== null && factureItem.attributes.cpf_edof.data.attributes.montant_financement !== null ? 'financement-cpf-wrapper green-bcg' : 'financement-cpf-wrapper'}>
                          {/*** Parcours document pour Salarié ***/}
                          <div className="left">
                            <img src={cpfLogo} alt='logo mon compte de formation' />
                            <div className="info-text">FINANCEMENT <span className="bold"> CPF</span><FaInfoCircle />
                            <span className="popupi-on-hover">                            
                              <div className="info-msg"><div><MdInfo /></div> Si vos droits CPF sont insuffisant vous pouvez:</div>
                              <ul className="option-list">
                                <li><div><VscCircleFilled /></div><div>Demander à votre employeur de compléter la somme manquante*</div></li>
                                <li><VscCircleFilled /> Financer vous mêmes la somme manquante*</li>
                              </ul>
                              
                            </span>
                            </div>
                          </div>
                          <div className="center">
                            <>
                            <div className={factureItem.attributes.cpf_edof.data !== null && factureItem.attributes.cpf_edof.data.attributes.montant_financement !== null ? 'cash-div' : 'cash-div none'}></div>
                            <form 
                              onSubmit={(e) => {
                              e.preventDefault();
                              }}
                              className="drop-file-wrapper"
                              >
                            <label htmlFor="file-upload" className="file-upload-btn">
                            {/* 
                            { factureItem.attributes.cpf_edof.data.attributes.preuve_financement.data === null ?
                            <p></p>
                            :
                            <p>Document actuel: {factureItem.attributes.cpf_edof.data.attributes.preuve_financement.data.attributes.name}</p>
                            }
                            <div className="bold">
                              <MdDownload /> Déposez votre prise en charge
                              <input 
                                id="file-upload"
                                type="file" 
                                name="upload" 
                                accept="application/pdf,application/vnd.ms-excel" 
                                onChange={(e)=> { 
                                  setFiles(e.target.files)
                                  setFullPageLoaderFacturation(true)
                                  fetchUploadEdofFile(e.target.files, factureItem.attributes.cpf_edof.data.id, 'cpf')
                                }}
                                style={{
                                  backgroundColor: "#fff !important"
                                }}
                              />
                            </div>
                            */}
                            <a href={factureItem.attributes.formation.data.attributes.cpf_link} target='_blank' rel='noreferrer' className="link-cpf bold">Financez votre projet avec le CPF</a>
                            </label>
                            { (factureItem.attributes.step < 2) &&
                              <div className="next-step-btn bold" onClick={() => {
                                toggleModalMessage(
                                  `<p>Vous ne disposez pas de financement CPF.</p><p>Merci de bien vouloir procéder au réglement de ${factureItem.attributes.Prix} € par carte bleue.</p> <a href=${cryptoAxepta()} target='_blank' rel='noreferrer' class='paiment-cb bold'>Paiement</a>`
                                  , true)
                                setFullPageLoaderFacturation(true)
                                fetchPutCpfToZero(factureItem.attributes.cpf_edof.data.id)
                              }}>Je ne dispose pas de financement</div>
                            }
                            </form>
                            </>
                          </div>
                          <div className="right">
                            {/*** Parcours document pour Salarié ***/}
                            <>
                            <div className="wrapper-cell">
                              <div></div> 
                              <div className="green bold">- {euroFormat.format(factureItem.attributes.cpf_edof.data !== null && Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) + 0)}</div>
                            </div>
                            </>
                          {/*** Parcours document pour Demandeur d'emploi ***/}
                          {/*factureItem.attributes.mode_financement === 'Demandeur d\'emploi' &&
                            <>
                            <div className="wrapper-cell">
                              <div>Financement CPF:</div> 
                              <div className="green">{euroFormat.format(factureItem.attributes.cpf_edof.data !== null && Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) + 0)}</div>
                            </div>
                            <div className="wrapper-cell">
                              <div>Financement Pôle emploi</div> 
                              <div className="green">{euroFormat.format(factureItem.attributes.pole_emploi_edof.data !== null && Number(factureItem.attributes.pole_emploi_edof.data.attributes.montant_financement) + 0)}</div>
                            </div>
                            <div className="wrapper-cell">
                              <div>Reste à charge:</div> 
                              <div className="green">
                                {euroFormat.format(
                                  calcRest(
                                    Number(factureItem.attributes.Prix),
                                    factureItem.attributes.cpf_edof.data !== null ? Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) : 0,
                                    factureItem.attributes.pole_emploi_edof.data !== null ? Number(factureItem.attributes.pole_emploi_edof.data.attributes.montant_financement) : 0,
                                  )
                                )}
                                </div>
                            </div>
                            {factureItem.attributes.cpf_edof.data !== null && Number(0) === Number(calcRest(
                                    Number(factureItem.attributes.Prix),
                                    factureItem.attributes.cpf_edof.data !== null ? Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) : 0,
                                    factureItem.attributes.pole_emploi_edof.data !== null ? Number(factureItem.attributes.pole_emploi_edof.data.attributes.montant_financement) : 0,
                                  )) ?
                              <button className="can-click" onClick={() => changeStepValue(3)}>Accéder à la formation</button>
                              :
                              <button className="cannot-click">Accéder à la formation</button>
                            }
                            </>
                          */}
                          </div>
                        </div>
                        {factureItem.attributes.mode_financement === 'nada' &&
                        <div className={factureItem.attributes.autofinancement.data !== null && factureItem.attributes.autofinancement.data.attributes.montant_financement !== null ? 'financement-cpf-wrapper green-bcg' : 'financement-cpf-wrapper'}>
                          <div className="left">
                            <div className="info-text">Financement <span className="bold"> Pôle emploi</span> sur EDOF <FaInfoCircle />
                              <span className="popupi-on-hover">                            
                                <div className="info-msg"><div><MdInfo /></div> Si vos droits CPF sont insuffisant vous pouvez:</div>
                                <ul className="option-list">
                                  <li><div><VscCircleFilled /></div><div>Faire une demande auprès de pôle emplois sur EDOF*</div></li>
                                  <li><VscCircleFilled /> Financer vous mêmes la somme manquante*</li>
                                </ul>
                                <p className="disclaimer">* sur le site moncomptedeformation</p><a href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/" target='_blank' rel='noreferrer'>moncompteformation.gouv.fr</a>
                              </span>
                            </div>
                          </div>
                          <div className="center">
                            <>
                            <form 
                              onSubmit={(e) => {
                              e.preventDefault();
                              }}
                              className="drop-file-wrapper"
                              >
                            <label htmlFor="file-upload-pe" className="file-upload-btn">
                            { factureItem.attributes.pole_emploi_edof.data.attributes.preuve_financement.data === null ?
                            <p></p>
                            :
                            <p>Document actuel: {factureItem.attributes.pole_emploi_edof.data.attributes.preuve_financement.data.attributes.name}</p>
                            }
                            <div>
                              <MdDownload /> Déposez votre prise en charge
                              <input 
                                id="file-upload-pe"
                                type="file" 
                                name="upload" 
                                accept="application/pdf,application/vnd.ms-excel" 
                                onChange={(e)=> { 
                                  setFilesPe(e.target.files)
                                  setFullPageLoaderFacturation(true)
                                  fetchUploadEdofFile(e.target.files, factureItem.attributes.pole_emploi_edof.data.id, 'pe')
                                }}
                                style={{
                                  backgroundColor: "#fff !important"
                                }}
                              />
                            </div>
                            </label>
                            </form>
                            </>
                            {/*** Parcours document pour Demandeur d'emploi***/}
                            {/*factureItem.attributes.mode_financement === 'Demandeur d\'emploi' &&
                            <>
                            <p className="info-msg"><div><MdInfo /></div>Si vos droits CPF sont insuffisant vous pouvez:</p>
                            <ul className="option-list">
                              <li><div><VscCircleFilled /></div><div>Demander au <span className="bold">pôle emploi</span> de compléter la somme manquante*</div></li>
                              <li><VscCircleFilled /> <div>Financer <span className="bold"> vous mêmes </span> la somme manquante*</div>s</li>
                            </ul>
                            <p className="disclaimer">* sur le site <span className="bold">moncomptedeformation</span></p>
                            <form 
                              onSubmit={(e) => {
                              e.preventDefault();
                              fetchUploadEdofFile(files, factureItem.attributes.cpf_edof.data.id, 'cpf')
                              }}
                              className="drop-file-wrapper"
                              >
                            <label htmlFor="file-upload" className="file-upload-btn">
                              <MdDownload /> Télécharger la prise en charge EDOF
                              <input 
                                id="file-upload"
                                type="file" 
                                name="upload" 
                                accept="application/pdf,application/vnd.ms-excel" 
                                onChange={(e)=>setFiles(e.target.files)}
                                style={{
                                  backgroundColor: "#fff !important"
                                }}
                              />
                            </label>
                            <p className="file-name">{files[0] && files[0].name}</p>
                            <input type="submit" value="Envoyer" className="send-file-btn"/>
                            </form>
                            <form 
                              onSubmit={(e) => {
                              e.preventDefault();
                              fetchUploadEdofFile(filesPe, factureItem.attributes.pole_emploi_edof.data.id, 'pe')
                              }}
                              className="drop-file-wrapper"
                              >
                            <label htmlFor="file-upload-pe" className="file-upload-btn">
                              <MdDownload /> Télécharger la prise en charge Pôle emploi
                              <input 
                                id="file-upload-pe"
                                type="file" 
                                name="upload" 
                                accept="application/pdf,application/vnd.ms-excel" 
                                onChange={(e)=>setFilesPe(e.target.files)}
                                style={{
                                  backgroundColor: "#fff !important"
                                }}
                              />
                            </label>
                            <p className="file-name">{filesPe[0] && filesPe[0].name}</p>
                            <input type="submit" value="Envoyer" className="send-file-btn"/>
                            </form>
                            </>
                              */}
                          </div>
                          <div className="right">
                            {/*** Parcours document pour Salarié ***/}
                            <>
                            <div className="wrapper-cell">
                              <div></div> 
                              <div className="green bold">- {euroFormat.format(factureItem.attributes.pole_emploi_edof.data !== null && Number(factureItem.attributes.pole_emploi_edof.data.attributes.montant_financement) + 0)}</div>
                            </div>
                            </>
                          {/*** Parcours document pour Demandeur d'emploi ***/}
                          {/*factureItem.attributes.mode_financement === 'Demandeur d\'emploi' &&
                            <>
                            <div className="wrapper-cell">
                              <div>Financement CPF:</div> 
                              <div className="green">{euroFormat.format(factureItem.attributes.cpf_edof.data !== null && Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) + 0)}</div>
                            </div>
                            <div className="wrapper-cell">
                              <div>Financement Pôle emploi</div> 
                              <div className="green">{euroFormat.format(factureItem.attributes.pole_emploi_edof.data !== null && Number(factureItem.attributes.pole_emploi_edof.data.attributes.montant_financement) + 0)}</div>
                            </div>
                            <div className="wrapper-cell">
                              <div>Reste à charge:</div> 
                              <div className="green">
                                {euroFormat.format(
                                  calcRest(
                                    Number(factureItem.attributes.Prix),
                                    factureItem.attributes.cpf_edof.data !== null ? Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) : 0,
                                    factureItem.attributes.pole_emploi_edof.data !== null ? Number(factureItem.attributes.pole_emploi_edof.data.attributes.montant_financement) : 0,
                                  )
                                )}
                                </div>
                            </div>
                            {factureItem.attributes.cpf_edof.data !== null && Number(0) === Number(calcRest(
                                    Number(factureItem.attributes.Prix),
                                    factureItem.attributes.cpf_edof.data !== null ? Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) : 0,
                                    factureItem.attributes.pole_emploi_edof.data !== null ? Number(factureItem.attributes.pole_emploi_edof.data.attributes.montant_financement) : 0,
                                  )) ?
                              <button className="can-click" onClick={() => changeStepValue(3)}>Accéder à la formation</button>
                              :
                              <button className="cannot-click">Accéder à la formation</button>
                            }
                            </>
                          */}
                          </div>
                        </div>
                        }
                        <div className={factureItem.attributes.autofinancement.data !== null && factureItem.attributes.autofinancement.data.attributes.montant_financement !== null ? 'financement-cpf-wrapper green-bcg' : 'financement-cpf-wrapper'}>
                          {/*** Parcours PAIEMENT pour Salarié ***/}
                          <div className="left">
                            <img src={cbLogo} alt='logos de cartes bleues' />
                            <div className="info-text"><span className="bold uppercase">Reste à charge</span> <FaInfoCircle />
                              <span className="popupi-on-hover">                            
                                Vous pouvez payer l'intégralité de votre formation par carte bleue. Attention ! Si vous avez déjà une partie du financement via EDOF vous devez compléter celui-ci directement sur la plateforme EDOF. 
                              </span>
                            </div>
                          </div>
                          <div className="center">
                            
                            <>
                            <a href={cryptoAxepta()} target='_blank' rel='noreferrer' className='paiment-cb bold'>Payez le solde par carte bleue</a>
                            </>
                            
                            {/*** Parcours document pour Demandeur d'emploi***/}
                            {factureItem.attributes.mode_financement === 'Demandeur d\'emploi' &&
                            <>
                            </>
                            }
                          </div>
                          <div className="right">
                            {/*** Parcours document pour Salarié ***/}
                            <>
                            <div className="wrapper-cell">
                              <div></div> 
                              <div className="green bold">- {euroFormat.format(factureItem.attributes.autofinancement.data !== null && Number(factureItem.attributes.autofinancement.data.attributes.montant_financement) + 0)}</div>
                            </div>
                            </>
                          </div>
                        </div>
                        {/*** Btn + total financement ***/}
                        {/*factureItem.attributes.mode_financement === 'Salarié' &&
                        <div className="total-wrapper">
                          <div className='left'></div>
                          <div className='total'>
                          <div className="wrapper-cell">
                              <div>Reste à charge:</div> 
                              <div className="green">= {factureItem.attributes.cpf_edof.data !== null ? euroFormat.format(Number(factureItem.attributes.Prix) - Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement)) : euroFormat.format(factureItem.attributes.Prix)}</div>
                            </div>
                            {factureItem.attributes.cpf_edof.data !== null && Number(factureItem.attributes.Prix) === Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) ?
                              <a href="https://elearning.wefor.fr/session/628269cdc77518936b6a315a">
                              <button 
                              className="can-click" 
                              onClick={() => {
                                
                                changeStepValue(3)
                                fetchInscriptionUserLms()
                                fetchStatutFormation(factureItem.attributes.formation.data.id)
                                
                                }}>
                                Accéder à la formation
                              </button>
                              </a>
                              :
                              <button className="cannot-click">Accéder à la formation</button>
                            }
                          </div>
                        </div>
                        */}
                        {factureItem.attributes.mode_financement &&
                        <div className="total-wrapper">
                          <div className='left'>
                          <div className='bold'>SOLDE FACTURE</div> 
                          </div>
                          <div className='total'>
                          <div className="wrapper-cell">
                              <div className="green bold">
                                {euroFormat.format(
                                  calcRest(
                                    Number(factureItem.attributes.Prix),
                                    factureItem.attributes.cpf_edof.data !== null ? Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) : 0,
                                    factureItem.attributes.autofinancement.data !== null ? Number(factureItem.attributes.autofinancement.data.attributes.montant_financement) : 0,
                                  )
                                )}
                                </div>
                            </div>
                            <div className="btn-learning-wrapper">
                            {factureItem.attributes.cpf_edof.data !== null && Number(0) === Number(calcRest(
                                    Number(factureItem.attributes.Prix),
                                    factureItem.attributes.cpf_edof.data !== null ? Number(factureItem.attributes.cpf_edof.data.attributes.montant_financement) : 0,
                                    factureItem.attributes.autofinancement.data !== null ? Number(factureItem.attributes.autofinancement.data.attributes.montant_financement) : 0,
                                  )) 
                                  
                              ?
                              <>
                              {userInfo && userInfo.data[0].attributes.score_formations &&
                              userInfo.data[0].attributes.score_formations.data !== null && 
                              userInfo.data[0].attributes.score_formations.data.find(score => score.attributes.formation.data !== null && score.attributes.formation.data.attributes.lms_formation_id === factureItem.attributes.formation.data.attributes.lms_formation_id) 
                              ?
                              <a href={`https://elearning.wefor.fr/session/${ userInfo.data[0].attributes.score_formations.data.find(score => score.attributes.formation.data.attributes.lms_formation_id === factureItem.attributes.formation.data.attributes.lms_formation_id).attributes.session_id}`}>
                                 
                                  <button className="can-click bold" onClick={() => {/* changeStepValue(3) */}}>Accéder à la formation</button>
                                </a>
                              :
                              <button className="can-click bold" onClick={() => {
                                fetchCreateSessionLms(factureItem.attributes.formation.data.attributes.duree_acces_formation, factureItem.attributes.formation.data.attributes.lms_formation_id)
                                fetchStatutFormation(factureItem.attributes.formation.data.id)
                              }}>Démarrer ma session de formation</button>
                             
                              }
                              </>
                              :
                                <button className="cannot-click bold">Démarrer ma session</button>
                            }
                            </div>
                          </div>
                        </div>
                        }
                      </>
                      }
                      {factureItem.attributes.step > 2 &&
                      <div className="loading-section">
                        <div className="loader-wrapper">Consultez vos email, créez votre compte puis cliquez sur 
                        { (factureItem.attributes.step === 3) &&
                          <span className="validate-btn" onClick={(() => {
                            fetchInscriptionUserLms()
                          })}>Valider</span>
                        }
                        { (factureItem.attributes.step > 3) ?
                          <BsCheck2Circle className="icon-check"/>
                          :
                          <ReactLoading type={'spinningBubbles'} color={"#649ace"} height={25} width={25} />
                        }
                        </div>
                        <div className="loader-wrapper" onClick={(() => {
                          fetchInscriptionUserLms()
                        })}>Création de votre session 
                        { (factureItem.attributes.step > 4) ?
                          <BsCheck2Circle className="icon-check" />
                          :
                          <ReactLoading type={'spinningBubbles'} color={"#649ace"} height={25} width={25} />
                        }
                        </div>
                        <div className="loader-wrapper">Ajout de la formation à votre compte 
                        { (factureItem.attributes.step > 5) ?
                          <BsCheck2Circle className="icon-check" />
                          :
                          <ReactLoading type={'spinningBubbles'} color={"#649ace"} height={25} width={25} />
                        }
                        </div>
                        { (factureItem.attributes.step === 6) &&
                          <div className="btn-go-wrapper"><a href="https://app.360learning.com/" target='_blank' rel='noreferrer' className="formation-btn bold">Formation</a></div>
                        }
                      </div>
                      }
                      </>
                    }
                  </section>
                }
              </div>
            ))}
            <PaginationMui id="pagination-facture" page={page} className="pagination-pc" count={Math.ceil(factureP.length / cardPerPage)} variant="outlined" color="primary" onChange={handleChangePagination}/>
            </>
          } 
          {statutFacture === 'entr' &&
          <>
            {factureP.length > 0 && facturePbyE().slice(indexOfFirstCard, indexOfLastCard).map((factureItem) => (
              <div className="facture-card" key={factureItem.id}>
                <div className="picto-wrapper">
                  <img src={documentM} alt="facture illustration" />
                </div>
                <div className="info-facture-wrapper">
                  <p className="blue-txt bold">Facture id-{factureItem.id}</p>
                  <p className="grey-txt"> Créée le {" "}
                    <Moment format="DD/MM/YYYY" className="bold">
                      {factureItem.attributes.publishedAt}
                    </Moment>
                  </p>
                </div>
                <div className="info-course-wrapper">
                  <p className="blue-txt bold"></p>
                  <p className="grey-txt">Nombre de salarié: <span className="bold">{factureItem.attributes.nombre_particulier}</span></p>
                </div>
                <div className="info-price-wrapper">
                  <p className="green-txt bold">{Number(factureItem.attributes.nombre_particulier) * Number(factureItem.attributes.Prix)} €</p>
                </div>
                <div className="pdf-wrapper">
                  {/* 
                  <img src={pdfPng} alt="facture illustration" onClick={() => savePdf(factureItem.attributes.facture.data.attributes.url, factureItem.attributes.facture.data.attributes.name )}/>
                  <div className="download-wrapper">
                    <FaPenAlt className="signature-svg"/>
                  </div>
                  */}
                </div>
              </div>
            ))}
            <PaginationMui page={page} className="pagination-pc" count={Math.ceil(factureP.length / cardPerPage)} variant="outlined" color="primary" onChange={handleChangePagination}/>
          </>
          }
        </section>
        }
      </>
 
      </section>
      </main>
      </>
      }
    </AccountFactureStyled>
  )
}

AccountFacture.propTypes = {
}

export default AccountFacture