/**** Import des modules ****/
import React from 'react'
import "aos/dist/aos.css"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'


/**** Import des images ****/
import logo from '../../assets/img/illustration/header-homme.png'

/**** Import des css ****/
import ReglementStyled from './ReglementStyled'

const Reglement = ({}) => {
  return (
    <ReglementStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      <section className="illustration-header">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Règlement intérieur des formations à distance</h1>
          </div>
        </div>
      </section>
      <section className="full-page">
        <p className='bold'>Ce règlement intérieur s'adresse aux participants des formations à distance dispensées par WEFOR. Dernière mise à jour : 05 Avril 2024</p>

        <h3 className="bold">Article 1 : Bases du règlement intérieur </h3>
        <p>Le présent règlement est établi conformément aux dispositions des articles L.6352-3 et L.6352-4 et R.6352-1 à R.6352-15 du Code du travail. Ce Règlement Intérieur est envoyé et consultable par tout stagiaire avant son entrée en formation.</p>

        <h3 className="bold">Article 2 : Personnel assujetti </h3>
        <p>Le présent règlement s’applique à tous les stagiaires, et ce pour la durée de la formation suivie. Chaque stagiaire accepte les termes du présent règlement lorsqu’il signe la convention de formation. </p>

        <h3 className="bold">Article 3 : Conditions générales </h3>
        <p>Toute personne suivant une formation proposée par WEFOR doit respecter le présent règlement pour toutes les questions relatives à l'application de la réglementation, ainsi que les règles générales et permanentes relatives à la discipline. Le non-respect de ces consignes expose la personne à des sanctions disciplinaires. </p>

        <h3 className="bold">Article 4 : Informations remises au stagiaire ou à l’apprenti avant son inscription définitive (selon les dispositions de l’article L6353.8 du Code du Travail, modifié par la Loi 2018-771 du 05 septembre 2018) </h3>
        
        <p>✓ Les objectifs (objectif professionnel et objectifs de développement des compétences professionnelles) et le contenu de la formation.</p>
        <p>✓ La liste des formateurs et des enseignants. </p>
        <p>✓ Les horaires. </p>
        <p>✓ Les modalités d’évaluation de la formation. </p>
        <p>✓ Les coordonnées de la personne chargée des relations avec les stagiaires. </p>
        <p>✓ Le règlement intérieur applicable à la formation. </p>
        <p>✓ Pour les contrats conclus par des personnes physiques, avant inscription définitive et tout règlement de frais, les informations mentionnées précédemment sont délivrées, ainsi que : </p>
        <p>✓ Les tarifs. </p>
        <p>✓ Les modalités de règlement et les conditions financières prévues en cas de cessation anticipée de la formation ou d’abandon en cours de stage. </p>

        <h3 className="bold">Article 5 : Informations demandées au stagiaire (selon les dispositions de l’article L6353.9 du Code du Travail, modifié par la Loi 2018-771 du 05 septembre 2018) </h3>
        <p>Les informations demandées, sous quelque forme que ce soit, par un organisme de formation au candidat à une action telle que définie à l’article L6313-1 du Code du Travail, à un stagiaire ne peuvent avoir comme finalité que d’apprécier son aptitude à suivre l’action de formation, qu’elle soit sollicitée, proposée ou poursuivie. Ces informations doivent présenter un lien direct et nécessaire avec l’action de formation, et il doit y être répondu de bonne foi. </p>

        <h3 className="bold">Article 6 : Assiduité, ponctualité, absences Les stagiaires sont tenus de suivre toutes les séquences programmées par le prestataire de formation, sans abandon sauf cas de force majeure.</h3>
        <p>Toute absence prévisible du stagiaire, qu’il soit également ou non le client, et ce quelle qu’en soit la cause, doit être annoncée et déclarée par écrit, par mail. Selon le contexte, les dispositions des Conditions Générales de Vente de l’organisme de formation, de la Convention ou du Contrat de Formation, du devis, et plus généralement de l’article L6354-1 s’appliqueront En cas d’accident de travail ou de trajet, les circonstances doivent être communiquées par écrit dans les 48 heures. </p>

        <h3 className="bold">Article 7 : Participation, comportement </h3>
        <p>La présence de chacun des stagiaires doit s’accompagner d’une participation active et de l’accomplissement d’efforts personnels, y compris en intersessions dans le cas de travaux en sous-groupes si un travail de conception et/ou des exercices sont nécessaires et/ou indispensables au bon déroulement de la formation. Il est demandé à tout stagiaire d’avoir un comportement garantissant le respect des règles élémentaires de savoir vivre, de savoir-être en collectivité et le bon déroulement des formations. Tout propos inapproprié (harcèlement, racisme ...) et tenu par le stagiaire sur quelconque support de la formation est passible d’exclusion définitive de la formation.</p>

        <h3 className="bold">Article 8 : Accès aux formations </h3>
        <p>L’accès aux formations se fait via la plateforme WEFOR avec les identifiants remis au stagiaire en début de formation. Ces identifiants sont personnels et ne peuvent être cédés, ni prêtés. </p>

        <h3 className="bold">Article 9 : Propriété des formations </h3>
        <p>Chaque enseignement présenté, que ce soit sous forme de vidéos, de support de cours, ou d’atelier pratique, est la propriété de l’organisme de formation. Le stagiaire s’engage sur l’honneur à ne les utiliser que pour sa formation personnelle dans un but exclusivement professionnalisant et à ne pas les exploiter d’une façon qui pourrait nuire ou causer préjudice ou concurrence à l’organisme de formation. </p>

        <h3 className="bold">Article 10 : Formalisme attaché au suivi de la formation </h3>
        <p>A l’issue de l’action de formation, le stagiaire se voit remettre une attestation de fin de formation. Toute attestation remise au stagiaire à l’issue de la formation est strictement personnelle et individuelle. </p>

        <h3 className="bold">Article 11 : Réclamation et médiation </h3>
        <p>WEFOR et ses formateurs ont pour objectif de vous fournir au quotidien des actions de formation de qualité. Si malgré nos efforts, il s’avérait que votre formation ne vous ait pas satisfait, nous vous proposons de nous envoyer vos réclamations par écrit (mail ou courrier). Notre responsable formation, Cyril ZIRAH, sera votre relais-qualité et vous contactera dans les 72 heures ouvrées pour faire un point avec vous, écouter vos remarques et vous proposer une solution pour compenser les défauts de qualité. </p>

        <h3 className="bold">Article 12 : Sanctions </h3>
        <p>Tout manquement du stagiaire à l’une des prescriptions du présent règlement intérieur pourra faire l’objet d’une sanction prononcée par le responsable de l’organisme de formation ou son représentant. Tout agissement considéré comme fautif pourra, en fonction de sa nature et de sa gravité, faire l’objet de l’une ou l’autre des sanctions suivantes : - rappel à l’ordre ; - avertissement écrit ; - blâme ; - exclusion temporaire de la formation ; - exclusion définitive de la formation. </p>

        <h3 className="bold">Article 13 : Entrée en vigueur </h3>
        <p>Le présent règlement intérieur entre en application à compter du : 22 Avril 2022</p>

       
      </section>
      <Footer />
    </ReglementStyled>
  )
}

export default Reglement