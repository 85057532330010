/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Progress } from 'semantic-ui-react'
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Label,
  ResponsiveContainer
} from "recharts";
import { FaLongArrowAltDown, FaLongArrowAltUp, FaArrowsAltH, FaPlus, FaMinus, FaUserTie } from "react-icons/fa";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import axios from 'axios'
import * as qs from 'qs'

import urlBackEnd from '../../assets/js/urlBackEnd'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'


/**** Import des images ****/


/**** Import des css ****/
import AccountOceanStyled from './AccountOceanStyled'

const URL = urlBackEnd

const AccountOcean = ({
  userInfo,
  userRole,
  isLogged,
  fetchQuestionOcean,
  fetchReponseOcean,
  questionOcean,
  reponseOcean,
  valueInputs,
  changeValueOceanQ,
  fetchPostOceanResult,
  fetchCategorieOcean,
  categorieOcean,
  changeManagerName,
  managerNom,
  managerPrenom,
  setFullPageLoaderOcean,
  setDidOcean
}) => {

  useEffect(() => {
    if(questionOcean.length === 0) {
      if (userRole !== 'entreprise') {
        if (userInfo.data[0].attributes.test_ocean.data === null) {
          fetchQuestionOcean()
        }
      } else {
        fetchQuestionOcean()
      }
    }
    if(reponseOcean.length === 0) {
      if (userRole !== 'entreprise') {
        if (userInfo.data[0].attributes.test_ocean.data === null) {
          fetchReponseOcean()
        }
      } else {
        fetchReponseOcean()
      }
    }
  }, []);

  useEffect(() => {
    if(categorieOcean.length === 0) {
      fetchCategorieOcean()
    }
  }, []);

  const [page, setPage] = useState(1)
  const [questionPerPage, setQuestionPerPage] = useState(3)
  const [indexOfLastQuestion, setIndexOfLastQuestion] = useState(questionPerPage)
  const [indexOfFirstQuestion, setIndexOfFirstQuestion] = useState(0)
  const [indexOfManager, setIndexOfManager] = useState('')
  const [nameOfManager, setNameOfManager] = useState('')
  const [showTest, setShowTest] = useState(false)
  const [showManager, setShowManager] = useState(false)
  const [thisTestOcean, setThisTestOcean] = useState([])

  useEffect(() => {

    if(isLogged && userRole !== 'entreprise') {
    } else if(isLogged && userRole === 'entreprise') {
      const queryFilterPart = qs.stringify({
        filters: {
          entreprise: {
            id: userInfo.data[0].id
          },
        }
        }, {
          encodeValuesOnly: true,
        });
  
      if(thisTestOcean.length === 0) {
      axios.get(`${URL}test-oceans?${queryFilterPart}`, {})
        .then((response) => {
          setThisTestOcean(response.data.data)
        })
        .catch((error) => {
          console.error(error);
        });
      }
    }
  }, [thisTestOcean])

  const changePagesQuestion = (value) => {
    setIndexOfLastQuestion(value * questionPerPage)
    setIndexOfFirstQuestion(value * questionPerPage - questionPerPage)
  }

  const handleChangePagination = (value) => {   
    setPage(value)
    changePagesQuestion(value)
  };

  if(!isLogged) {
    return (<Navigate to="/" replace />)
  }

  const changeResultForm = (score, categorie, type) => {
    if (type === 'normal') {
      return score
    }  
    if (type === 'reverse') {
      if (score === 1) {
        return 5
      }
      if (score === 2) {
        return 4
      }
      if (score === 3) {
        return 3
      }
      if (score === 4) {
        return 2
      }
      if (score ===5) {
        return 1
      }
    }
  }

  const dataParticulier = () => {
  const data = [
    {
      subject: "Ouverture d\'ésprit",
      A: userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Conscience",
      A: userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Extraversion",
      A: userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Agréabilité",
      A: userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Névrosisme",
      A: userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100),
      fullMark: 100
    }
  ];

  return data
  }

  const selectedData = (index) => {
    const dataToReturn = [
      {
        subject: "Ouverture d\'ésprit",
        A: thisTestOcean.length > 0 && Math.round(thisTestOcean[index].attributes.ouverture_esprit / (10 * 5) * 100),
        fullMark: 100
      },
      {
        subject: "Conscience",
        A: thisTestOcean.length > 0 && Math.round(thisTestOcean[index].attributes.conscience / (9 * 5) * 100),
        fullMark: 100
      },
      {
        subject: "Extraversion",
        A: thisTestOcean.length > 0 && Math.round(thisTestOcean[index].attributes.extraversion / (8 * 5) * 100),
        fullMark: 100
      },
      {
        subject: "Agréabilité",
        A: thisTestOcean.length > 0 && Math.round(thisTestOcean[index].attributes.altruisme / (10 * 5) * 100),
        fullMark: 100
      },
      {
        subject: "Névrosisme",
        A: thisTestOcean.length > 0 && Math.round(thisTestOcean[index].attributes.nevrosisme / (8 * 5) * 100),
        fullMark: 100
      }
    ];

    return dataToReturn
  }

  if(!isLogged) {
    return (<Navigate to="/" replace />)
  }

  const upgradeTest = () => {
    let upgrade = 0;
    valueInputs.map((input) => {
      if(input.score !== 0) {
        upgrade ++
      } 
    })
    return (upgrade / valueInputs.length) * 100
  }

  const getScreenWidth = () => {
    const screenWidth = window.innerWidth;
    return Number(screenWidth);
  };
  
  return (
    <AccountOceanStyled>
      <Header bcgColor={'#fff'}/>
      {isLogged && userRole !== 'entreprise' &&
      <main className="page-wrapper">
      <AccountMenu />
      {userInfo.data[0].attributes.test_ocean.data === null ?
        <section className="right-wrapper">
        <h2 className='bold'>Test de personnalité</h2>
        <p className="intro-txt">Augmentez vos chances d'être recruté en répondant au questionnaire pour tester votre personnalité. Seules les entreprises pour lesquelles vous avez postulé pourront accéder aux résultats. (environ 5 minutes)</p>
        {questionOcean &&
        <>
        <Progress percent={Math.round(upgradeTest())} indicating progress className="progress-bar" />
        <p className='start-q'>Je me vois comme quelqu’un qui …</p>
        <form
          className="account-form-ocean"
          onSubmit={(event) => {
            event.preventDefault();
            setFullPageLoaderOcean(true)
            fetchPostOceanResult()
            setDidOcean(true)
          }}
        >
          {questionOcean.slice(indexOfFirstQuestion, indexOfLastQuestion).map((question) => (
            <FormControl key={question.id}>
              <FormLabel id={`demo-radio-buttons-group-label-${question.id}`}>{question.id}- ...{question.attributes.contenu}</FormLabel>
              <RadioGroup
                aria-labelledby={`demo-radio-buttons-group-label-${question.id}`}
                defaultValue="female"
                name={`demo-radio-buttons-group-${question.id}`}
                value={valueInputs[question.id - 1].input}
                onChange={(event) => {
                  changeValueOceanQ(
                    Number(event.target.value), 
                    question.id - 1, 
                    Number(changeResultForm(Number(event.target.value), question.attributes.categorie_ocean.data.id, question.attributes.type)),
                    question.attributes.categorie_ocean.data.id
                  )
                }}
              >
                {reponseOcean.map((reponse) => (
                  <FormControlLabel key={reponse.id}
                    value={reponse.attributes.score} 
                    control={<Radio required/>} 
                    label={reponse.attributes.contenu} 
                    className="labels-q"
                    />
                ))}
              </RadioGroup>
            </FormControl>
          ))}
        {valueInputs[44].score !== 0 && valueInputs[43].score !== 0 && valueInputs[42].score !== 0 &&
        <input 
          type="submit" 
          className="submit-btn" 
          value="Obtenir mon resultat" 
        />
        }
        </form>
        <div className='btn-wrapper'>
        {page === 1 &&
          <button>Pécédent</button>
        }
          {page !== 1 &&
          <button 
            className='active-btn prev-btn'
            onClick={() => {
              handleChangePagination(page - 1)
            }}
            >Précédent</button>
          }
          {valueInputs[indexOfFirstQuestion].score !== 0 && valueInputs[indexOfFirstQuestion + 1].score !== 0 && valueInputs[indexOfFirstQuestion + 2].score !== 0 && page !== 15 ?
          <button 
            className='active-btn next-btn'
            onClick={() => {
              handleChangePagination(page + 1)
            }}
            >Suivant</button>
            :
            <>
              {page === 15 ?
              <></>
              :
              <button>Suivant</button>
              }
            </>
          }
        </div>
        </>
        }
        </section>
        :
        <section className="right-wrapper">
          <h2 className='bold'>Vos résultats</h2>
          <ResponsiveContainer width={getScreenWidth() < 759 ? 350 : "100%"} height={getScreenWidth() < 759 ? 350 : "60%"}>
          <RadarChart
            cx={"50%"}
            cy={"60%"}
            outerRadius={150}
            data={dataParticulier()}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis domain={[0, 100]} angle={125} stroke="#4d4d4d"/>
            <Radar
              name="Mike"
              dataKey="A"
              stroke="#6AAF8B"
              fill="#B4D7C4"
              fillOpacity={0.6}
            >
            </Radar>
          </RadarChart>
          </ResponsiveContainer>

          {categorieOcean.length > 1 &&
          <div className="result-txt-wrapper">
            {(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Ouverture, originalité, ouverture d’esprit <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[0].attributes.bas}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Ouverture, originalité, ouverture d’esprit <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[0].attributes.moyen}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Ouverture, originalité, ouverture d’esprit <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[0].attributes.haut}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Conscience, contrôle, contrainte <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[1].attributes.bas}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Conscience, contrôle, contrainte <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[1].attributes.moyen}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Conscience, contrôle, contrainte <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[1].attributes.haut}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Extraversion, énergie, enthousiasme <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[2].attributes.bas}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Extraversion, énergie, enthousiasme <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[2].attributes.moyen}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Extraversion, énergie, enthousiasme <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[2].attributes.haut}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Agréabilité, altruisme, affection <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[3].attributes.bas}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Agréabilité, altruisme, affection <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[3].attributes.moyen}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Agréabilité, altruisme, affection <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[3].attributes.haut}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Névrosisme, émotions négatives, négativité <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[4].attributes.bas}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Névrosisme, émotions négatives, négativité <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[4].attributes.moyen}</p>
            </>
            }
            {(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Névrosisme, émotions négatives, négativité <span>({Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[4].attributes.haut}</p>
            </>
            }
          </div>
          }
        </section>
      }
      </main>
      }
      {isLogged && userRole === 'entreprise' &&
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
      {showTest ?
      <>
        <button className='btn-show-test bold' onClick={() => setShowTest(false)}><span className='show-test-txt bold'>Refermer le test <FaMinus /></span></button>
        <h2 className='bold margin-top'>Test de personnalité</h2>
        <p className="intro-txt">Testez vos manager pour pouvoir comparer leur personnalité avec celles de vos candidats. (environ 5 minutes)</p>
        {questionOcean &&
        <>
        <Progress percent={Math.round(upgradeTest())} indicating progress className="progress-bar" />
        <form
          className="account-form-ocean"
          onSubmit={(event) => {
            event.preventDefault();
            setFullPageLoaderOcean(true)
            fetchPostOceanResult();
            setShowTest(false)
            setThisTestOcean([])
          }}
        >
        <div className='wrapper-name-input'>
        <TextField
          id="nom-field"
          className='input-name-manager'
          label="Nom"
          name= 'managerNom'
          value={managerNom}
          required
          onChange={(event) => changeManagerName(event.target.name, event.target.value)}
        />
        <TextField
          id="prenom-field"
          className='input-name-manager'
          label="Prénom"
          name= 'managerPrenom'
          value={managerPrenom}
          required
          onChange={(event) => changeManagerName(event.target.name, event.target.value)}
        />
        </div>
        <p className='start-q'>Je me vois comme quelqu’un qui …</p>
          {questionOcean.slice(indexOfFirstQuestion, indexOfLastQuestion).map((question) => (
            <FormControl key={question.id}>
              <FormLabel id={`demo-radio-buttons-group-label-${question.id}`}>{question.id}- ...{question.attributes.contenu}</FormLabel>
              <RadioGroup
                aria-labelledby={`demo-radio-buttons-group-label-${question.id}`}
                defaultValue="female"
                name={`demo-radio-buttons-group-${question.id}`}
                value={valueInputs[question.id - 1].input}
                onChange={(event) => {
                  changeValueOceanQ(
                    Number(event.target.value), 
                    question.id - 1, 
                    Number(changeResultForm(Number(event.target.value), question.attributes.categorie_ocean.data.id, question.attributes.type)),
                    question.attributes.categorie_ocean.data.id
                  )
                }}
              >
                {reponseOcean.map((reponse) => (
                  <FormControlLabel key={reponse.id}
                    value={reponse.attributes.score} 
                    control={<Radio required/>} 
                    label={reponse.attributes.contenu} 
                    />
                ))}
              </RadioGroup>
            </FormControl>
          ))}
        {valueInputs[44].score !== 0 && valueInputs[43].score !== 0 && valueInputs[42].score !== 0 &&
        <input 
          type="submit" 
          className="submit-btn" 
          value="Obtenir mon resultat" 
        />
        }
        </form>
        <div className='btn-wrapper'>
        {page === 1 &&
          <button>Pécédent</button>
        }
          {page !== 1 &&
          <button 
            className='active-btn prev-btn'
            onClick={() => {
              handleChangePagination(page - 1)
            }}
            >Précédent</button>
          }
          {valueInputs[indexOfFirstQuestion].score !== 0 && valueInputs[indexOfFirstQuestion + 1].score !== 0 && valueInputs[indexOfFirstQuestion + 2].score !== 0 && page !== 15 ?
          <button 
            className='active-btn next-btn'
            onClick={() => {
              handleChangePagination(page + 1)
            }}
            >Suivant</button>
            :
            <>
              {page === 15 ?
              <></>
              :
              <button>Suivant</button>
              }
            </>
          }
        </div>
        </>
        }
      </>
      : 
      <>
       <button className='btn-show-test bold' onClick={() => setShowTest(true)}><span className="show-test-txt bold">Faire passer le test à un manager <FaPlus /></span></button>
      </>
      }
      {thisTestOcean.length > 0 &&
        <>
        <div className='btn-show-test bold'>
          <button className='bold' onClick={() => setShowManager(!showManager)}>Liste de vos managers {showManager ? <MdArrowDropUp className='arrow'/> : <MdArrowDropDown className='arrow'/>}</button>
          {showManager &&
            <ul className="list-managers-wrapper">
              {thisTestOcean.map((manager, index) => (
                <li
                  key={index} 
                  value={manager.attributes.nom + ' ' + manager.attributes.prenom}
                  onClick={(event) => {
                    setIndexOfManager(index)
                    setNameOfManager(manager.attributes.nom + ' ' + manager.attributes.prenom)
                  }}
                >
                  <span className="manager-wrapper"><FaUserTie />{manager.attributes.nom} {manager.attributes.prenom}</span>
                  <span className="show-more bold">Voir les résultats</span>
                </li>
              ))}
            </ul>
          }
        </div>
          {indexOfManager !== '' &&
          <>
          <h2 className='bold'>Résultats de {nameOfManager}</h2>
          <RadarChart
            data={selectedData(indexOfManager)}
            cx={getScreenWidth() / 2}
            cy={getScreenWidth() / 2}
            outerRadius={150}
            width={getScreenWidth()}
            height={getScreenWidth()}
            fillOpacity={1}
          >
            <PolarGrid gridType='circle'/>
            <PolarAngleAxis dataKey="subject"/>
            <PolarRadiusAxis domain={[0, 100]} angle={125} stroke="#4d4d4d"/>
            <Radar
              name="Mike"
              dataKey="A"
              stroke="#6AAF8B"
              fill="#B4D7C4"
              fillOpacity={0.6}
              
            >
              <Label position="insideTop" dataKey="A" angle="0"/>
            </Radar>
          </RadarChart>
          {categorieOcean.length > 1 && 
          <div className="result-txt-wrapper">
            {(thisTestOcean[indexOfManager].attributes.ouverture_esprit / (10 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Ouverture, originalité, ouverture d’esprit <span>({Math.round((thisTestOcean[indexOfManager].attributes.ouverture_esprit / (10 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[0].attributes.bas}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.ouverture_esprit / (10 * 5) * 100) >= 31 &&  (thisTestOcean[indexOfManager].attributes.ouverture_esprit / (10 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Ouverture, originalité, ouverture d’esprit <span>({Math.round((thisTestOcean[indexOfManager].attributes.ouverture_esprit / (10 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[0].attributes.moyen}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.ouverture_esprit / (10 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Ouverture, originalité, ouverture d’esprit <span>({Math.round((thisTestOcean[indexOfManager].attributes.ouverture_esprit / (10 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[0].attributes.haut}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.conscience / (9 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Conscience, contrôle, contrainte <span>({Math.round((thisTestOcean[indexOfManager].attributes.conscience / (9 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[1].attributes.bas}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.conscience / (9 * 5) * 100) >= 31 &&  (thisTestOcean[indexOfManager].attributes.conscience / (9 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Conscience, contrôle, contrainte <span>({Math.round((thisTestOcean[indexOfManager].attributes.conscience / (9 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[1].attributes.moyen}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.conscience / (9 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Conscience, contrôle, contrainte <span>({Math.round((thisTestOcean[indexOfManager].attributes.conscience / (9 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[1].attributes.haut}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.extraversion / (8 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Extraversion, énergie, enthousiasme <span>({Math.round((thisTestOcean[indexOfManager].attributes.extraversion / (8 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[2].attributes.bas}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.extraversion / (8 * 5) * 100) >= 31 &&  (thisTestOcean[indexOfManager].attributes.extraversion / (8 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Extraversion, énergie, enthousiasme <span>({Math.round((thisTestOcean[indexOfManager].attributes.extraversion / (8 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[2].attributes.moyen}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.extraversion / (8 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Extraversion, énergie, enthousiasme <span>({Math.round((thisTestOcean[indexOfManager].attributes.extraversion / (8 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[2].attributes.haut}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.altruisme / (10 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Agréabilité, altruisme, affection <span>({Math.round((thisTestOcean[indexOfManager].attributes.altruisme / (10 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[3].attributes.bas}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.altruisme / (10 * 5) * 100) >= 31 &&  (thisTestOcean[indexOfManager].attributes.altruisme / (10 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Agréabilité, altruisme, affection <span>({Math.round((thisTestOcean[indexOfManager].attributes.altruisme / (10 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[3].attributes.moyen}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.altruisme / (10 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Agréabilité, altruisme, affection <span>({Math.round((thisTestOcean[indexOfManager].attributes.altruisme / (10 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[3].attributes.haut}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.nevrosisme / (8 * 5) * 100) < 31 && 
            <>
              <h3 className="bold">Névrosisme, émotions négatives, négativité <span>({Math.round((thisTestOcean[indexOfManager].attributes.nevrosisme / (8 * 5) * 100))}<FaLongArrowAltDown /> bas)</span></h3>
              <p>{categorieOcean[4].attributes.bas}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.nevrosisme / (8 * 5) * 100) >= 31 &&  (thisTestOcean[indexOfManager].attributes.nevrosisme / (10 * 5) * 100) < 71 && 
            <>
              <h3 className="bold">Névrosisme, émotions négatives, négativité <span>({Math.round((thisTestOcean[indexOfManager].attributes.nevrosisme / (8 * 5) * 100))}<FaArrowsAltH /> moyen)</span></h3>
              <p>{categorieOcean[4].attributes.moyen}</p>
            </>
            }
            {(thisTestOcean[indexOfManager].attributes.nevrosisme / (8 * 5) * 100) >= 71 && 
            <>
              <h3 className="bold">Névrosisme, émotions négatives, négativité <span>({Math.round((thisTestOcean[indexOfManager].attributes.nevrosisme / (8 * 5) * 100))}<FaLongArrowAltUp /> haut)</span></h3>
              <p>{categorieOcean[4].attributes.haut}</p>
            </>
            }
          </div>
          }
        </>
        }
        </>
      }
      </section>
      </main>
      }
    </AccountOceanStyled>
  )
}

AccountOcean.propTypes = {
  currentProfilUser: PropTypes.string.isRequired
}

export default AccountOcean