export const CHANGE_ACTIV_COURSE_ID = 'CHANGE_ACTIV_COURSE_ID'
export const FETCH_FORMATIONS = 'FETCH_FORMATIONS'
export const SAVE_FORMATIONS = 'SAVE_FORMATIONS'
export const SAVE_FORMATIONS_HOME = 'SAVE_FORMATIONS_HOME'
export const SAVE_FORMATIONS_PAGINATION = 'SAVE_FORMATIONS_PAGINATION'
export const CHANGE_LOAD_FORMATION = 'CHANGE_LOAD_FORMATION'
export const FETCH_DEMANDE_FORMATION = 'FETCH_DEMANDE_FORMATION'
export const FETCH_DEMANDE_FORMATION_COLLAB = 'FETCH_DEMANDE_FORMATION_COLLAB'
export const SEND_EMAIL_FOR_ENTREPRISE_COLLAB = 'SEND_EMAIL_FOR_ENTREPRISE_COLLAB'
export const SET_MODAL_INSC_LOADER_OPEN = 'SET_MODAL_INSC_LOADER_OPEN'
export const SET_INSC_COLLAB_STEP_ONE = 'SET_INSC_COLLAB_STEP_ONE'
export const SET_INSC_COLLAB_STEP_TWO = 'SET_INSC_COLLAB_STEP_TWO'
export const SET_INSC_COLLAB_STEP_THREE = 'SET_INSC_COLLAB_STEP_THREE'
export const SET_INSC_COLLAB_STEP_FOUR = 'SET_INSC_COLLAB_STEP_FOUR'
export const SET_INSC_COLLAB_ERROR = 'SET_INSC_COLLAB_ERROR'
export const FETCH_POST_NEW_FACTURATION = 'FETCH_POST_NEW_FACTURATION'
export const FETCH_POST_NEW_FACTURATION_P = 'FETCH_POST_NEW_FACTURATION_P'
export const CREATE_NEW_FACTURE_PDF = 'CREATE_NEW_FACTURE_PDF'
export const CREATE_NEW_FACTURE_P_PDF = 'CREATE_NEW_FACTURE_P_PDF'
export const UPDATE_FACTURE_WITH_NEW_FILE = 'UPDATE_FACTURE_WITH_NEW_FILE'
export const UPDATE_FACTURE_P_WITH_NEW_FILE = 'UPDATE_FACTURE_P_WITH_NEW_FILE'
export const FETCH_FACTURE_E = 'FETCH_FACTURE_E'
export const SAVE_FACTURE_E = 'SAVE_FACTURE_E'
export const FETCH_FACTURE_P = 'FETCH_FACTURE_P'
export const SAVE_FACTURE_P = 'SAVE_FACTURE_P'
export const CHANGE_CLICKED_FACTURE = 'CHANGE_CLICKED_FACTURE'
export const FETCH_PUT_MODE_FINANCEMENT = 'FETCH_PUT_MODE_FINANCEMENT'
export const CHANGE_STEP_VALUE = 'CHANGE_STEP_VALUE'
export const FETCH_UPLOAD_EDOF_FILE = 'FETCH_UPLOAD_EDOF_FILE'
export const FETCH_UPLOAD_OPCO_FILE = 'FETCH_UPLOAD_OPCO_FILE'
export const FETCH_SEND_EMAIL_DOWNLOAD_EDOF_DOCUMENT = 'FETCH_SEND_EMAIL_DOWNLOAD_EDOF_DOCUMENT'
export const FETCH_STATUT_FORMATION = 'FETCH_STATUT_FORMATION'
export const FETCH_CREATE_NEW_CONTRAT_PARTICULIER = 'FETCH_CREATE_NEW_CONTRAT_PARTICULIER'
export const UPDATE_CONTRAT_P_WITH_NEW_FILE = 'UPDATE_CONTRAT_P_WITH_NEW_FILE'
export const FETCH_CREATE_NEW_CONTRAT_ENTREPRISE = 'FETCH_CREATE_NEW_CONTRAT_ENTREPRISE'
export const UPDATE_CONTRAT_E_WITH_NEW_FILE = 'UPDATE_CONTRAT_E_WITH_NEW_FILE'
export const FETCH_GET_ONE_PROGRAM = 'FETCH_GET_ONE_PROGRAM'
export const REDIRECT_TO_MY_FINANCEMENT = 'REDIRECT_TO_MY_FINANCEMENT'
export const FETCH_PUT_CPF_TO_ZERO = 'FETCH_PUT_CPF_TO_ZERO'
export const FETCH_PUT_OPCO_TO_ZERO = 'FETCH_PUT_OPCO_TO_ZERO'
export const FACTURE_P_CHARGED = 'FACTURE_P_CHARGED'
export const FACTURE_E_CHARGED = 'FACTURE_E_CHARGED'
export const SIGN_CONTRAT_PART = 'SIGN_CONTRAT_PART'
export const FETCH_PUT_SIGNED_FACTURATION = 'FETCH_PUT_SIGNED_FACTURATION'
export const FETCH_POST_FORMULAIRE_FORMATION = 'FETCH_POST_FORMULAIRE_FORMATION'
export const FETCH_MY_SALARIE = 'FETCH_MY_SALARIE'
export const SAVE_MY_ENTREPRISE_PART = 'SAVE_MY_ENTREPRISE_PART'
export const SET_SALARIE_CLICKED = 'SET_SALARIE_CLICKED'
export const FETCH_POST_QS = 'FETCH_POST_QS'
export const CHANGE_QS_INPUT = 'CHANGE_QS_INPUT'
export const CHANGE_GET_QS_STATE = 'CHANGE_GET_QS_STATE'
export const FETCH_GET_COLLAB_COURSES = 'FETCH_GET_COLLAB_COURSES'
export const SAVE_COLLAB_COURSES_ON = 'SAVE_COLLAB_COURSES_ON'
export const FETCH_GET_SESSIONS_ON_OFF = 'FETCH_GET_SESSIONS_ON_OFF'
export const SAVE_FACTURE_E_STARTED = 'SAVE_FACTURE_E_STARTED'
export const FETCH_GET_INSCRIPTIONS_FROM_SESSIONS = 'FETCH_GET_INSCRIPTIONS_FROM_SESSIONS'
export const SAVE_INSCRIPTIONS_CLICKED_SESSION = 'SAVE_INSCRIPTIONS_CLICKED_SESSION'
export const FETCH_GET_FACTURE_CLIENT = 'FETCH_GET_FACTURE_CLIENT'
export const SAVE_FACTURE_CLIENT = 'SAVE_FACTURE_CLIENT'
export const SET_MUI_COURSE_FILTER_KEY_VALUE = 'SET_MUI_COURSE_FILTER_KEY_VALUE'
export const CHANGE_FACTURE_STATUT = 'CHANGE_FACTURE_STATUT'
export const GET_FORMATIONS_FROM_PARAMS = 'GET_FORMATIONS_FROM_PARAMS'
export const SAVE_FORMATIONS_FROM_PARAMS = 'SAVE_FORMATIONS_FROM_PARAMS'
export const SAVE_SLUGS = 'SAVE_SLUGS'
export const SET_FULL_SLUG_COURSE = 'SET_FULL_SLUG_COURSE'
export const FETCH_FORMATIONS_GRAPH = 'FETCH_FORMATIONS_GRAPH'
export const FETCH_FORMATIONS_INFOS = "FETCH_FORMATIONS_INFOS"
export const SAVE_FORMATION_INFO = "SAVE_FORMATION_INFO"
export const FETCH_FACTURE_TO_SHOW = "FETCH_FACTURE_TO_SHOW"
export const SAVE_FACTURE_TO_SHOW = "SAVE_FACTURE_TO_SHOW"
export const FETCH_FACTURE_P_WITH_DOCS = "FETCH_FACTURE_P_WITH_DOCS"
export const SAVE_FACTURE_P_WITH_DOCS = "SAVE_FACTURE_P_WITH_DOCS"
export const FETCH_FACTURE_COURSE = "FETCH_FACTURE_COURSE"
export const SAVE_FACTURE_COURSE = "SAVE_FACTURE_COURSE"

export const changeActivCourseId = (id) => ({
    type: CHANGE_ACTIV_COURSE_ID,
    id
})

export const fetchFormations = (paramObj) => ({
    type: FETCH_FORMATIONS,
    paramObj
});

export const fetchFormationsGraph = (paramObj) => ({
    type: FETCH_FORMATIONS_GRAPH,
    paramObj
});
  
export const saveFormations = (formations) => ({
    type: SAVE_FORMATIONS,
    formations,
});

export const saveFormationsHome = (formations) => ({
    type: SAVE_FORMATIONS_HOME,
    formations,
});

export const saveFormationsPagination = (data) => ({
    type: SAVE_FORMATIONS_PAGINATION,
    data,
});

export const changeLoadFormation = (bool) => ({
    type: CHANGE_LOAD_FORMATION,
    bool,
});

export const fetchDemandeFormation = (id, nom, duree) => ({
    type: FETCH_DEMANDE_FORMATION,
    id,
    nom,
    duree
});

export const fetchDemandeFormationCollab = (id, array) => ({
    type: FETCH_DEMANDE_FORMATION_COLLAB,
    id,
    array
});

export const sendEmailForEntrepriseCollab = ( id, collabs) => ({
    type: SEND_EMAIL_FOR_ENTREPRISE_COLLAB,
    id,
    collabs
});

export const setModalInscLoaderOpen = (bool) => ({
    type: SET_MODAL_INSC_LOADER_OPEN,
    bool
})

export const setInscCollabStepOne = (string) => ({
    type: SET_INSC_COLLAB_STEP_ONE,
    string
})
export const setInscCollabStepTwo = (string) => ({
    type: SET_INSC_COLLAB_STEP_TWO,
    string
})
export const setInscCollabStepThree = (string) => ({
    type: SET_INSC_COLLAB_STEP_THREE,
    string
})
export const setInscCollabStepFour = (string) => ({
    type: SET_INSC_COLLAB_STEP_FOUR,
    string
})
export const setInscCollabError = (bool) => ({
    type: SET_INSC_COLLAB_ERROR,
    bool
})

 export const fetchPostNewFacturation = (collabNumber, collabs) => ({
     type: FETCH_POST_NEW_FACTURATION,
     collabNumber,
     collabs
 })

 export const fetchPostNewFacturationP = () => ({
    type: FETCH_POST_NEW_FACTURATION_P,
})

 export const createNewFacturePdf = (coursePrice, formationName, collabNumber, factureId) => ({
     type: CREATE_NEW_FACTURE_PDF,
     coursePrice,
     formationName,
     collabNumber,
     factureId
 })

 export const createNewFacturePPdf = (coursePrice, formationName, collabNumber, factureId) => ({
    type: CREATE_NEW_FACTURE_P_PDF,
    coursePrice,
    formationName,
    collabNumber,
    factureId
})

 export const updateFactureWithNewFile = (factureId) => ({
    type: UPDATE_FACTURE_WITH_NEW_FILE,
    factureId
 })

 export const updateFacturePWithNewFile = (factureId) => ({
    type: UPDATE_FACTURE_P_WITH_NEW_FILE,
    factureId
 })

 export const fetchFactureE = () => ({
    type: FETCH_FACTURE_E,
 })

 export const fetchFactureP = () => ({
    type: FETCH_FACTURE_P,
 })

 export const saveFactureE = (data) => ({
    type: SAVE_FACTURE_E,
    data
})

export const saveFactureP = (data) => ({
     type: SAVE_FACTURE_P,
     data
})

export const changeClickedFacture = (factureId) => ({
     type: CHANGE_CLICKED_FACTURE,
     factureId
})

export const fetchPutModeFinancement = (factureId, statut) => ({
     type: FETCH_PUT_MODE_FINANCEMENT,
     factureId,
     statut
})

export const changeStepValue = (number) => ({
    type: CHANGE_STEP_VALUE,
    number
})

export const fetchUploadEdofFile = (file, id, mode) => ({
    type: FETCH_UPLOAD_EDOF_FILE,
    file,
    id,
    mode
})

export const fetchUploadOpcoFile = (file, id, mode) => ({
    type: FETCH_UPLOAD_OPCO_FILE,
    file,
    id,
    mode
})

export const fetchSendEmailDownloadEdofDocument = (data) => ({
    type: FETCH_SEND_EMAIL_DOWNLOAD_EDOF_DOCUMENT,
    data
})

export const fetchStatutFormation = (courseId) => ({
    type: FETCH_STATUT_FORMATION,
    courseId
})

export const fetchCreateNewContratParticulier = (factureId) => ({
    type: FETCH_CREATE_NEW_CONTRAT_PARTICULIER,
    factureId
})

export const updateContratPWithNewFile = (factureId) => ({
    type: UPDATE_CONTRAT_P_WITH_NEW_FILE,
    factureId
})

export const fetchCreateNewContratEntreprise = (factureId, collabs) => ({
    type: FETCH_CREATE_NEW_CONTRAT_ENTREPRISE,
    factureId,
    collabs
})

export const updateContratEWithNewFile = (factureId) => ({
    type: UPDATE_CONTRAT_E_WITH_NEW_FILE,
    factureId,
})

export const fetchGetOneProgram = (id) => ({
    type: FETCH_GET_ONE_PROGRAM,
    id
})

export const redirectToMyFinancement = (bool) => ({
    type: REDIRECT_TO_MY_FINANCEMENT,
    bool
})


export const fetchPutCpfToZero = (factureId) => ({
    type: FETCH_PUT_CPF_TO_ZERO,
    factureId
})

export const fetchPutOpcoToZero = (factureId) => ({
    type: FETCH_PUT_OPCO_TO_ZERO,
    factureId
})

export const facturePCharged = (bool) => ({
    type: FACTURE_P_CHARGED,
    bool
})
export const factureECharged = (bool) => ({
    type: FACTURE_E_CHARGED,
    bool
})

export const signContratPart = (contratId, urlImage, fileName) => ({
    type: SIGN_CONTRAT_PART,
    contratId,
    urlImage,
    fileName
})

export const fetchPutSignedFacturation = (id) => ({
    type: FETCH_PUT_SIGNED_FACTURATION,
    id
})

export const fetchPostFormulaireFormation = () => ({
    type: FETCH_POST_FORMULAIRE_FORMATION,
})

export const fetchMySalarie = () => ({
    type: FETCH_MY_SALARIE,
})

export const saveMyEntreprisePart = (data) => ({
    type: SAVE_MY_ENTREPRISE_PART,
    data
})

export const setSalarieClicked = (name, id) => ({
    type: SET_SALARIE_CLICKED,
    name, 
    id
})

export const fetchPostQS = (courseId, factureId) => ({
    type: FETCH_POST_QS,
    courseId, 
    factureId
})

export const changeQsInput = (value, name) => ({
    type: CHANGE_QS_INPUT,
    value,
    name
})

export const changeGetQSState = (bool) => ({
    type: CHANGE_GET_QS_STATE,
    bool
})

export const fetchGetCollabCourses = (collabId) => ({
    type: FETCH_GET_COLLAB_COURSES,
    collabId
})

export const saveCollabCoursesOn = (data) => ({
    type: SAVE_COLLAB_COURSES_ON,
    data
})

export const fetchGetSessionsOnOff = () => ({
    type: FETCH_GET_SESSIONS_ON_OFF,
})

 export const saveFactureEStarted = (data) => ({
    type: SAVE_FACTURE_E_STARTED,
    data
 })

 export const fetchGetInscriptionsFromSessions = (date, courseId, entrepriseId) => ({
    type: FETCH_GET_INSCRIPTIONS_FROM_SESSIONS,
    date, 
    courseId, 
    entrepriseId
 })

 export const saveInscriptionsClickedSession = (data) => ({
    type: SAVE_INSCRIPTIONS_CLICKED_SESSION,
    data
 })

 export const fetchGetFactureClient = () => ({
    type: FETCH_GET_FACTURE_CLIENT
 })

 export const saveFactureClient = (data) => ({
    type: SAVE_FACTURE_CLIENT,
    data
 })

 export const setMuiCourseFilterKeyValue = (value) => ({
    type: SET_MUI_COURSE_FILTER_KEY_VALUE,
    value
})

export const changeFactureStatut = (step, mode, link) => ({
    type: CHANGE_FACTURE_STATUT,
    step,
    mode,
    link
})

export const getFormationFromParams = (secteur, name, keyword, paramObj) => ({
    type: GET_FORMATIONS_FROM_PARAMS,
    secteur,
    name,
    keyword,
    paramObj
})

export const saveFormationFromParams = (status, data) => ({
    type: SAVE_FORMATIONS_FROM_PARAMS,
    status,
    data
})

export const saveSlugs = (name, slug) => ({
    type: SAVE_SLUGS,
    name,
    slug
})

export const setFullSlugCourse = (string) => ({
    type: SET_FULL_SLUG_COURSE,
    string
})

export const fetchFormationsInfos = (id) => ({
    type: FETCH_FORMATIONS_INFOS,
    id
})

export const saveFormationInfo = (data) => ({
    type: SAVE_FORMATION_INFO,
    data
})

export const fetchFactureToShow = (id) => ({
    type: FETCH_FACTURE_TO_SHOW,
    id
})

export const saveFactureToShow = (data) => ({
    type: SAVE_FACTURE_TO_SHOW,
    data
})

export const fetchFacturePWithDocs = () => ({
    type: FETCH_FACTURE_P_WITH_DOCS,
})

export const saveFacturePWithDocs = (data) => ({
    type: SAVE_FACTURE_P_WITH_DOCS,
    data
})

export const fetchFactureCourse = () => ({
    type: FETCH_FACTURE_COURSE
})

export const saveFactureCourse = (data) => ({
    type: SAVE_FACTURE_COURSE,
    data
})