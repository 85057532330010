import { 
  CHANGE_ACTIV_COURSE_ID,
  SAVE_FORMATIONS,
  SAVE_FORMATIONS_HOME,
  CHANGE_LOAD_FORMATION,
  SET_MODAL_INSC_LOADER_OPEN,
  SET_INSC_COLLAB_STEP_ONE,
  SET_INSC_COLLAB_STEP_TWO,
  SET_INSC_COLLAB_STEP_THREE,
  SET_INSC_COLLAB_STEP_FOUR,
  SET_INSC_COLLAB_ERROR,
  SAVE_FACTURE_E,
  SAVE_FACTURE_P,
  CHANGE_CLICKED_FACTURE,
  REDIRECT_TO_MY_FINANCEMENT,
  FACTURE_P_CHARGED,
  FACTURE_E_CHARGED,
  SAVE_MY_ENTREPRISE_PART,
  SET_SALARIE_CLICKED,
  SAVE_FORMATIONS_PAGINATION,
  CHANGE_QS_INPUT,
  CHANGE_GET_QS_STATE,
  SAVE_COLLAB_COURSES_ON,
  SAVE_FACTURE_E_STARTED,
  SAVE_INSCRIPTIONS_CLICKED_SESSION,
  SAVE_FACTURE_CLIENT,
  SET_MUI_COURSE_FILTER_KEY_VALUE,
  SAVE_FORMATIONS_FROM_PARAMS,
  SAVE_SLUGS,
  SET_FULL_SLUG_COURSE,
  SAVE_FORMATION_INFO,
  SAVE_FACTURE_TO_SHOW,
  SAVE_FACTURE_P_WITH_DOCS,
  SAVE_FACTURE_COURSE,
} from "../actions/course";

export const initialState = {
  activCourseId: 1,
  loadFormation: true,
  formations: [],
  formationsHome: [],
  formationsPagination: [],
  modalInscLoaderOpen: false,
  inscCollabStepOne: '',
  inscCollabStepTwo: '',
  inscCollabStepThree: '',
  inscCollabStepFour: '',
  inscCollabError: false,
  factureE: [],
  factureEStarted: [],
  factureP: [],
  clickedFacture: false,
  redirectToMyFinancement: false,
  facturePStatut: false,
  factureEStatut: false,
  myEntreprisePart: [],
  salarieClickedName: '',
  salarieClickedId: '',
  qsOne: '',
  qsTwo: '',
  qsThree: '',
  qsFour: '',
  qsOneScore: 0,
  qsTwoScore: 0,
  qsThreeScore: 0,
  getStateQs: false,
  collabCoursesOn: 'not loaded',
  collabCoursesOff: 'not loaded',
  inscriptionsClickedSession: [],
  clientFacture: 'loading',
  muiCourseFilterKeyValue: null,
  formationFromParams: {
    status: 'loading',
    data: []
  },
  slugSecteur: '',
  slugKeyword: '',
  slugFormation: '',
  fullSlugCourse: '',
  formationInfo: false,
  factureToShow: false,
  facturePWithDocs: false,
  factureCourse: false
};

// reducer = traducteur d'une intention/action vers une modification du state
const course = (state = initialState, action = {}) => {
  switch (action.type) {
      case CHANGE_ACTIV_COURSE_ID:
        return {
          ...state,
          activCourseId: action.id,
        };
      case SAVE_FORMATIONS:
        return {
          ...state,
          formations: action.formations,
        };
      case SAVE_FORMATIONS_PAGINATION:
        return {
          ...state,
          formationsPagination: action.data,
        };
      case SAVE_FORMATIONS_HOME:
        return {
          ...state,
          formationsHome: action.formations,
        };
      case CHANGE_LOAD_FORMATION:
        return {
          ...state,
          loadFormation: action.bool,
        };
      case SET_MODAL_INSC_LOADER_OPEN:
        return {
          ...state,
          modalInscLoaderOpen: action.bool
        }
      case SET_INSC_COLLAB_STEP_ONE:
        return {
          ...state,
          inscCollabStepOne: action.string
        }
      case SET_INSC_COLLAB_STEP_TWO:
        return {
          ...state,
          inscCollabStepTwo: action.string
        }
      case SET_INSC_COLLAB_STEP_THREE:
        return {
          ...state,
          inscCollabStepThree: action.string
        }
      case SET_INSC_COLLAB_STEP_FOUR:
        return {
          ...state,
          inscCollabStepFour: action.string
        }
      case SET_INSC_COLLAB_ERROR:
        return {
          ...state,
          inscCollabError: action.bool
        }
      case SAVE_FACTURE_E:
        return {
          ...state,
          factureE: action.data
        }
      case SAVE_FACTURE_P:
        return {
          ...state,
          factureP: action.data
        }
      case CHANGE_CLICKED_FACTURE:
        return {
          ...state,
          clickedFacture: action.factureId
        }
      case REDIRECT_TO_MY_FINANCEMENT:
        return {
          ...state,
          redirectToMyFinancement: action.bool
        }
      case FACTURE_P_CHARGED:
        return {
          ...state,
        facturePStatut: action.bool
        }
      case FACTURE_E_CHARGED:
        return {
          ...state,
        factureEStatut: action.bool
        }
      case SAVE_MY_ENTREPRISE_PART:
        return {
          ...state,
          myEntreprisePart: action.data
        }
      case SET_SALARIE_CLICKED:
        return {
          ...state,
          salarieClickedId: action.id,
          salarieClickedName: action.name
        }
      case CHANGE_QS_INPUT:
        return {
          ...state,
          [action.name]: action.value,
        };
      case CHANGE_GET_QS_STATE:
        return {
          ...state,
          getStateQs: action.bool
        }
      case SAVE_COLLAB_COURSES_ON:
        return {
          ...state,
          collabCoursesOn: action.data.attributes.en_cours_formations.data,
          collabCoursesOff: action.data.attributes.formations_termine.data,
        }
      case SAVE_FACTURE_E_STARTED:
        return {
          ...state,
          factureEStarted: action.data,
        }
      case SAVE_INSCRIPTIONS_CLICKED_SESSION:
        return {
        ...state,
        inscriptionsClickedSession: action.data,
      }
      case SAVE_FACTURE_CLIENT:
        return {
          ...state,
          clientFacture: action.data,
        }
      case SET_MUI_COURSE_FILTER_KEY_VALUE: 
        return {
          ...state,
          muiCourseFilterKeyValue: action.value
      }
      case SAVE_FORMATIONS_FROM_PARAMS: 
      return {
        ...state,
        formationFromParams: {
          ...state.formationFromParams,
          data: action.data,
          status: action.status
        }
      }
      case SAVE_SLUGS:
        return {
          ...state,
          [action.name]: action.slug,
        }
      case SET_FULL_SLUG_COURSE:
        return {
          ...state,
          fullSlugCourse: action.string,
        }
      case SAVE_FORMATION_INFO:
        return {
          ...state,
          formationInfo: action.data,
        }
      case SAVE_FACTURE_TO_SHOW:
        return {
          ...state,
          factureToShow: action.data
        }
      case SAVE_FACTURE_P_WITH_DOCS:
        return {
          ...state,
          facturePWithDocs: action.data
        }
      case SAVE_FACTURE_COURSE: 
        return {
          ...state,
          factureCourse: action.data
        }
    default:
      return state;
  }
};

export default course;