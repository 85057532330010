/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import { AiFillCaretDown, AiOutlineDoubleRight } from "react-icons/ai"
import { MdDeleteForever } from "react-icons/md"
import { FaList } from "react-icons/fa"
import { BsFillGridFill } from "react-icons/bs"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import PaginationMui from '@mui/material/Pagination'
import { Link, useParams, useNavigate } from "react-router-dom"
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import AOS from 'aos'
import "aos/dist/aos.css"
import { StickyContainer, Sticky } from 'react-sticky'
import ReactMarkdown from 'react-markdown'
import { Helmet } from "react-helmet"


/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import JobCard from '../../containers/JobCard'
import ModalJobFiche from '../../containers/ModalJobFiche';
import ModalCourseProg from '../../containers/ModalCourseProg';
import CourseCardLoading from '../../components/CourseCardLoading'
import CourseCardLoadingList from '../../components/CourseCardLoadingList'
import SearchJobByKey from '../../containers/SearchJobByKey'

/**** Import des images ****/
import suitcase from '../../assets/img/illustration/suitcase.png'
import suitcaseGreen from '../../assets/img/picto/suitcase-green.png'
import logo from '../../assets/img/illustration/offre_emploi.png'
import timeyellow from '../../assets/img/illustration/timeyellow.png'
import euroblue from '../../assets/img/logo/WEFOR_CARRE_COULEUR.png'
import shield from '../../assets/img/picto/shield.png'

/**** Import des css ****/
import JobStyled from './JobStyled'
import "swiper/css"
import "swiper/css/pagination"
import { mockComponent } from 'react-dom/cjs/react-dom-test-utils.production.min';
import JobCardListLayout from '../../containers/JobCardListLayout';
import axios from 'axios'
import * as qs from 'qs'

import urlBackEnd from '../../assets/js/urlBackEnd'
import { Skeleton } from '@mui/material';
import { changeActivJobId } from '../../actions/job'

const URL = urlBackEnd

const Job = ({ openModal, jobs, activJobId, loadJobs, setJobFicheId, secteurs, domaines, setFilter, secteurJobFilter, domaineJobFilter, kmJobFilter, fetchJobs, userRole, currentProfilUser, changeActivCourseId, formationJobFilter, isLogged, jobsWithDistances, fetchGetDirection, userInfo, fullPageLoaderDistance, setFullPageLoaderDistance, toggleModalPostulerTuto, setJobIdModalPostulerTuto, setCvPosted, setDidTest, jobsPagination, setJobForModalPostuler, secteursData, fetchSecteurs, fetchJobsInfos, jobInfo }) => {

  const hasWindow = typeof window !== 'undefined';
  const [btnFilterSecteurOnOff, setBtnFilterSecteurOnOff] = useState(false)
  const [btnFilterJobOnOff, setBtnFilterJobOnOff] = useState(false)
  const [btnFilterKMOnOff, setBtnFilterKMOnOff] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [modalJobFicheOpen, setModalJobFicheOpen] = useState(false)
  const [modalCourseProgOpen, setModalCourseProgOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [activLayout, setActivLayout] = useState('list')
  const [cardPerPage, setCardPerPage] = useState(10)
  const [indexOfLastCard, setIndexOfLastCard] = useState(cardPerPage)
  const [indexOfFirstCard, setIndexOfFirstCard] = useState(0)
  const [filterName, setFilterName] = useState('')
  const [age, setAge] = React.useState('');
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const navigate = useNavigate();
  const handleChangeKeyFilter = (event) => {
    setAge(event.target.value);
  };

  let { idjob } = useParams()

  useEffect(() => {
    if (secteursData.length === 0) {
      fetchSecteurs()
    }
  }, []);

  useEffect(() => {
    if(jobs.length === 0) {
    const paramObj = {page: 'jobs' ,intemNb: 10, pageNb: 1, jobId : idjob === undefined ? false : Number(idjob)}
      fetchJobs(paramObj)
    } else {
      navigate(`/job/${activJobId}`)
    }
  }, [isLogged, activJobId]); 

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (activJobId !== 1) {
      fetchJobsInfos(activJobId)
    }
  }, [activJobId]);

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const handleChildElementClick = (e) => {
    e.stopPropagation()
    // Do other stuff here
 }

 const top100Films = [
  { label: 'The Godfather', id: 1 },
  { label: 'Pulp Fiction', id: 2 },
];

const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) =>
    `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${
      index % 2 === 0 ? '00' : '30'
    }`,
);

 const jobSorted = (debug) => {
   if (isLogged === true && jobs !== undefined) {
   if (kmJobFilter !== '' && jobs[jobs.length - 1].direction !== undefined && jobs[jobs.length - 1].direction !== undefined) {
     let jobSortedc = jobs.sort(function(a, b) {
       return a.direction.time.value === undefined && b.direction.time.value === undefined ? a.direction.time.value - b.direction.time.value : ''
     })
     return jobSortedc
    } else {
     return jobs
   }
  } else {
    return jobs
  }
 }

 const jobSortedWithoutDist = () => {
  if (isLogged === true && jobs !== undefined) { 
    return jobs
  } else {
   return jobs
 }
}


 const changePagesCards = (value) => {
   setIndexOfLastCard(value * cardPerPage)
   setIndexOfFirstCard(value * cardPerPage - cardPerPage)
 }

  const handleChangePagination = (event, value) => {   
    setPage(value)
    changePagesCards(value)
    const paramObj = {page: 'jobs' ,intemNb: 10, pageNb: value}
    fetchJobs(paramObj)
    window.scrollTo(0, 250);
  };

  let job = () => {
    if (loadJobs === false) {
      let job = jobs.find(item => item.id === activJobId);
      return job;
    }
  }

  let nbOfCard = Math.round(windowDimensions.width / 450 + 0);

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });


  const verifsModalPostulerTuto = () => {
    toggleModalPostulerTuto(true)
    setJobIdModalPostulerTuto(activJobId, jobInfo.attributes.nom)
    if(isLogged) {
      if(userInfo.data[0].attributes.cv.data !== null){
        setCvPosted(true)
      }
    }
  }

  return (
    <JobStyled
    activLayout={activLayout}
      onClick={() => {
        if (btnFilterJobOnOff === true) {
          setBtnFilterJobOnOff(false)
        }
        if (btnFilterSecteurOnOff === true) {
          setBtnFilterSecteurOnOff(false)
        }
        if (btnFilterKMOnOff === true) {
          setBtnFilterKMOnOff(false)
        }
      }}
    >
      {modalJobFicheOpen &&
        <ModalJobFiche modalJobFicheOpen={modalJobFicheOpen} setModalJobFicheOpen={setModalJobFicheOpen} />
      }
      {modalCourseProgOpen &&
        <ModalCourseProg modalCourseProgOpen={modalCourseProgOpen} setModalCourseProgOpen={setModalCourseProgOpen} />
      }
      <Header bcgColor={'#247558'}/>
      <Helmet>
          <title>WEFOR - JOB</title>
          <meta name='description' content="Liste des offres d'emploi WEFOR. Trouvez le job qui vous corresponds"/>
          <meta name='keywords' content="Wefor,job , offre d'emploi"/>
      </Helmet>
      <section className="top-illustration">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold title-green">Nos offres d'emploi</h1>
            {currentProfilUser === 'notChoose'&& <></> }
            {currentProfilUser === "Demandeur d'emploi" &&
            <>
              <p>Vous voulez rebondir dans l’emploi rapidement ? Découvrez les offres d’emploi accessibles aux <span className="bold">postulants Wefor !</span></p>
              <p>Pour garantir à nos entreprises partenaires des recrutements de qualité, les profils que nous leur proposons ont tous passé un test de personnalité et attestés de leurs compétences professionnelles. Nous formons des apprenants afin de les aider dans leur recherche d'emploi. 
              </p>
            </>
            }
            {currentProfilUser === "Salarié" &&
            <>
              <p>Vous avez un projet de reconversion professionnelle ? Découvrez les offres d’emploi accessibles aux <span className="bold">diplômés Wefor !</span></p>
              <p>Pour garantir à nos entreprises partenaires des recrutements de qualité, les profils que nous leur proposons ont tous passé un test de personnalité et attestés de leurs compétences professionnelles. Nous formons des apprenants afin de les aider dans leur recherche d'emploi. 
              </p>
            </>
            }
            {currentProfilUser === "Entreprise" &&
            <>
              <p>Vous souhaitez recruter des <span className="bold">collaborateurs bien formés et opérationnels</span> dès leur prise de poste ? Découvrez les profils des diplômés Wefor !</p>
              <p>Pour garantir la qualité de vos recrutements, les profils que nous vous proposons ont tous suivi une formation Wefor et validé leur certification.</p>
            </>
            }
            {currentProfilUser === "Autres" &&
            <>
              <p>Vous avez un projet de reconversion ou d’évolution professionnelle ? Découvrez les offres d’emploi accessibles aux <span className="bold">diplômés Wefor !</span></p>
              <p>Pour garantir à nos entreprises partenaires des recrutements de qualité, les profils que nous leur proposons ont tous suivi une formation Wefor et validé leur certification.</p>
            </>
            }
            {currentProfilUser === "notChoose" &&
            <>
              <p>Pour garantir à nos entreprises partenaires des recrutements de qualité, les profils que nous leur proposons ont tous passé un test de personnalité et attestés de leurs compétences professionnelles. Dans le cadre de reconversions professionnelles, nous formons des apprenants afin de les aider dans leur recherche d'emploi. 
              </p>
            </>
            }
          </div>
        </div>
      </section>
      <section className="filter-formation">
        <div className="wrapper-filter">
          <div 
            className={btnFilterSecteurOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
            onClick={() => setBtnFilterSecteurOnOff(!btnFilterSecteurOnOff)}
          >
          {filterName !== "" ?
            <span className='filter'>
              {filterName} <MdDeleteForever onClick={(e) => {
                setFilter('', 'secteurJob') 
                setFilterName('')
                handleChildElementClick(e)
                fetchJobs()
                }} />
            </span>
            :
            <>
            Secteurs <AiFillCaretDown/>
            </>
          }
            <ul className={btnFilterSecteurOnOff ? "show-menu" : ""}>
            {secteurs.map((secteur) => (
                <li 
                key={secteur.id + 1000}
                onClick={() => {
                  setFilter(secteur.id, 'secteurJob') 
                  setFilterName(secteur.attributes.nom)
                  fetchJobs()
              }}
                >
                  {secteur.attributes.nom}
                </li>
              ))}
            </ul>
          </div>
          <SearchJobByKey className="btn-key" fetchJobs={fetchJobs} secteurJobFilter={secteurJobFilter}/>
         {/*  <div 
            className={btnFilterJobOnOff ? "border-r-off btn-filter" : "btn-filter"}
            onClick={() => setBtnFilterJobOnOff(!btnFilterJobOnOff)}
          >Domaines <AiFillCaretDown/>
            <ul className={btnFilterJobOnOff ? "show-menu" : ""}>
            {domaines.map((domaine) => (
                <li 
                  key={domaine.id}
                  onClick={() => setFilter(domaine.attributes.nom, 'domaineJob') }
                >
                  {domaine.attributes.nom}
                </li>
              ))}
            </ul>
          </div>
          */}
          {/* 
          { isLogged && userRole !== 'entreprise' && userInfo.data[0].attributes.en_cours_formations.data.length > 0 | userInfo.data[0].attributes.formations_termine.data.length > 0 ?
          <div 
            className={btnFilterKMOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
            onClick={() => setBtnFilterKMOnOff(!btnFilterKMOnOff)}
          >
          {kmJobFilter !== "" ?
            <span className='filter'>
              {kmJobFilter} <MdDeleteForever onClick={(e) => {
                setFilter('', 'kmJob') 
                handleChildElementClick(e)
                }} />
            </span>
            :
            <>
            Trier par <AiFillCaretDown/>
            </>
          }
          
            <ul className={btnFilterKMOnOff ? "show-menu" : ""}>
              <li 
                onClick={() => {
                  setFullPageLoaderDistance(true)
                  setFilter('Temps à vélo', 'kmJob')
                  fetchGetDirection('bicycling')
                }
              }>Temps à vélo</li>
              <li 
                onClick={() => {
                  setFullPageLoaderDistance(true)
                  setFilter('Temps en voiture', 'kmJob')
                  fetchGetDirection('driving')
                }
              }>Temps en voiture</li>
              <li 
                onClick={() => {
                  setFullPageLoaderDistance(true)
                  setFilter('Temps en transport', 'kmJob')
                  fetchGetDirection('transit')
                }
              }>Temps en transport en commun</li>
            </ul>
            
          </div>
          : ''
          }

          */}
        </div>
      </section>
      <section className="user-filter-section">
        <BsFillGridFill 
          className={`layout-btn ${activLayout === 'grid' ? 'active-layout' : ''}`} 
          onClick={() => {
            setActivLayout('grid')
            setCardPerPage(10)
            setIndexOfLastCard(10)
            setIndexOfFirstCard(0)
            setPage(1)
          }}
        />
        <FaList 
          className={`layout-btn ${activLayout === 'list' ? 'active-layout' : ''}`}
          onClick={() => {
            setActivLayout('list')
            setCardPerPage(10)
            setIndexOfLastCard(10)
            setIndexOfFirstCard(0)
            setPage(1)
          }}
        />
        {domaineJobFilter !== "" &&
          <span className='filter'>
            {domaineJobFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'domaineJob') 
              handleChildElementClick(e)
              }} />
          </span>
        }
        {formationJobFilter !== "" &&
          <span className='filter'>
            {formationJobFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'formationJob') 
              handleChildElementClick(e)
              }} />
          </span>
        }
      </section>
      <main className="course-main">
        <section className="course-list-left mobile">
        <Swiper
          slidesPerView={nbOfCard}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper slider-mobile"
          watchSlidesProgress={true}
        >
        {!loadJobs && !fullPageLoaderDistance && jobSorted('1').length === 0 && 
          <p>Votre recherche ne comporte aucun resultat, merci de changer les filtres</p>
        }
        {!fullPageLoaderDistance ? 
        <>
        {!loadJobs && jobSorted('2').map((job) => (
          <SwiperSlide key={job.id}>
            {({ isActive }) => (
              <>
                <JobCard jobId={job.id} {...job} isActive={isActive} test='jobSorted mobile'/>
              </>
            )}
          </SwiperSlide>
        ))}
        </>
        :
        <>
        {!loadJobs && jobSortedWithoutDist().map((job) => (
          <SwiperSlide key={job.id}>
            {({ isActive }) => (
              <>
                <JobCard jobId={job.id} {...job} isActive={isActive} test='jobSortedWithoutDist mobile'/>
              </>
            )}
          </SwiperSlide>
        ))}
        </>
        }
        </Swiper>
        </section>
        <section className={`course-list-left computer ${activLayout === 'list' ? 'column' : ''}`}>
        {loadJobs && activLayout === 'grid' && 
            <>
              <CourseCardLoading typeCard='jobs'/>
              <CourseCardLoading typeCard='jobs'/>
              <CourseCardLoading typeCard='jobs'/>
              <CourseCardLoading typeCard='jobs'/>
              <CourseCardLoading typeCard='jobs'/>
              <CourseCardLoading typeCard='jobs'/>
              <CourseCardLoading typeCard='jobs'/>
              <CourseCardLoading typeCard='jobs'/>
              <CourseCardLoading typeCard='jobs'/>
              <CourseCardLoading typeCard='jobs'/>
            </>
          }
          {loadJobs && activLayout === 'list' && 
            <>
              <CourseCardLoadingList typeCard='jobs'/>
              <CourseCardLoadingList typeCard='jobs'/>
              <CourseCardLoadingList typeCard='jobs'/>
              <CourseCardLoadingList typeCard='jobs'/>
              <CourseCardLoadingList typeCard='jobs'/>
              <CourseCardLoadingList typeCard='jobs'/>
              <CourseCardLoadingList typeCard='jobs'/>
              <CourseCardLoadingList typeCard='jobs'/>
              <CourseCardLoadingList typeCard='jobs'/>
              <CourseCardLoadingList typeCard='jobs'/>
            </>
          }
        {!loadJobs && !fullPageLoaderDistance && jobSorted('3').length === 0 && 
          <p>Votre recherche ne comporte aucun resultat, merci de changer les filtres</p>
        }
        {!fullPageLoaderDistance ? 
        <>
        {!loadJobs && jobSorted('4').map((job) => (
            <div key={job.id} className={`card-wrapper ` + (activLayout === 'list' ? 'fullwidth' : '')}>
            {activLayout === 'list' && 
              <JobCardListLayout key={job.id} jobId={job.id} distanceV={job.distanceV} {...job}/>
            }
            {activLayout === 'grid' && 
              <JobCard key={job.id} jobId={job.id} {...job} test='jobSorted'/>
            }
          </div>
          ))
        }
        </>
        :
        <>
        {!loadJobs && jobs.length > 0 && jobSortedWithoutDist().map((job) => (
            <div key={job.id} className={`card-wrapper ` + (activLayout === 'list' ? 'fullwidth' : '')}>
            {activLayout === 'list' && 
              <JobCardListLayout key={job.id} jobId={job.id} distanceV={job.distanceV} {...job}/>
            }
            {activLayout === 'grid' && 
              <JobCard key={job.id} jobId={job.id} {...job} test='jobSortedWithoutDist' />
            }
          </div>
          ))
        }
        </>
        }
        {!loadJobs && !fullPageLoaderDistance && jobInfo &&
          <PaginationMui page={jobsPagination.page} className="pagination-pc" count={Math.ceil(jobsPagination.pageCount)} variant="outlined" color="primary" onChange={handleChangePagination}/>
        }
        </section>
        <section className="course-info-right">
        {!loadJobs  && jobs.length > 0 && jobInfo !== undefined && jobInfo &&
            <div className="sticky-container">
          <div className="info-wrapper">
            <div className="title-wrapper">
            <div className="right">
                <h3 className="bold">{jobInfo.attributes.nom}</h3>
              </div>
              <div className='separator-green'></div>
              <div className="left">
                <h5 className="bold">{jobInfo.attributes.secteurs.data[0].attributes.nom}</h5>
              </div>
            </div>
            <div className="card-green">
              <div className="row">
                <p>Publiée: <span><Moment format="DD/MM/YYYY"  className="bold">{jobInfo.attributes.createdAt}</Moment></span></p>
                <p>Type d'emploi: <span className="bold uppercase">{jobInfo.attributes.type_emploi}</span></p>
                <p>Lieu: <span className="bold">{jobInfo.attributes.lieu}</span></p>
                {jobInfo.attributes.remuneration === 0 ? 
                  <p>Rémunération: <span className="bold">à déterminer</span></p>
                :
                  <p>Rémunération: <span className="bold">{jobInfo.attributes.remuneration} K€ brut/an</span></p>
                }
                {/*
                <p>Pré-requis: <span className="bold">Formation: {jobInfo.attributes.formation.data !== null && jobInfo.attributes.formation.data !== undefined && jobInfo.attributes.formation.data.attributes.nom}</span></p>
                */}
              </div>
              <div className="row">
                <Link 
                  to={``} 
                  className=""
                >
                {userRole === "entreprise" ? "" :
                <button 
                  className="btn-blue"
                  onClick={() => {
                    openModal('postuler')
                    verifsModalPostulerTuto()
                    let jobObj = {
                      id: jobInfo.id,
                      attributes: jobInfo.attributes
                    }
                    setJobForModalPostuler(jobObj)
                  }}
                >Postuler</button>
                }
                </Link>
              </div>
            </div>
            <h2 className="title-section-h2 bold">Prérequis pour postuler</h2>
            {/*
            <div className="card-orange">
              <div className="row">
                < img src={shield} className="shield-picto" alt="mallette verte" />
              </div>
              <div className="row">
                <h3 className="bold">Formation - {jobInfo.attributes.formation.data.attributes.nom}</h3>
              </div>
            </div>
                        <div className="price-time-wrapper">
              <div className="time-wrapper">
                <img src={timeyellow} alt="picto euro bleu" />
                <div className="paragraphe-wrapper">
                  <p className="bold txt">Durée de la formation :</p>
                  <p className="bold time">{jobInfo.attributes.formation.data.attributes.duree}h</p>
                </div>
              </div>
              <div className="separator-orange"></div>
              <div className="price-wrapper">
                <img src={euroblue} alt="picto euro bleu" />
                <p className="bold">{euroFormat.format(jobInfo.attributes.formation.data.attributes.prix)} TTC</p>
              </div>
            </div>

            {userRole === "entreprise" ? 
            <button 
            className="btn-orange-fullwidth bold"
              onClick={() => {
                changeActivCourseId(jobInfo.attributes.formation.data.id)
                openModal("inscription-collab")
              }}
            >Inscrire un collaborateur</button>
            :
            <button 
            className="btn-orange-fullwidth bold"
              onClick={() => {
                changeActivCourseId(jobInfo.attributes.formation.data.id)
                openModal("formation")
              }}
            >S'inscrire</button>
            }
            */}
            {jobInfo.attributes.formation.data !== null &&
            <>
            <p className='para-pre'>- Avoir suivi une formation en lien avec l'offre d'emploi. Pour tout complément d'information, se référer à l'offre.</p>
            <p className='para-pre orange bold'>- En cas de reconvertion professionnelle nous vous proposons la formation suivante pour postuler : </p>
              <div className="card-orange">
              <div className="row">
                < img src={shield} className="shield-picto" alt="mallette verte" />
              </div>
              <div className="row">
                <h3 className="bold">Formation - {jobInfo.attributes.formation.data.attributes.nom}</h3>
              </div>
              </div>
              <div className="price-time-wrapper">
              <div className="time-wrapper">
                <img src={timeyellow} alt="picto euro bleu" />
                <div className="paragraphe-wrapper">
                  <p className="bold txt">Durée de la formation :</p>
                  <p className="bold time">{jobInfo.attributes.formation.data.attributes.duree}h</p>
                </div>
              </div>
              <div className="separator-orange"></div>
              <div className="price-wrapper">
                <img src={euroblue} alt="picto euro bleu" />
                <p className="bold">{euroFormat.format(jobInfo.attributes.formation.data.attributes.prix)} TTC</p>
              </div>
              </div>
              {userRole === "entreprise" ? 
              <button 
              className="btn-orange-fullwidth bold"
                onClick={() => {
                  changeActivCourseId(jobInfo.attributes.formation.data.id)
                  openModal("inscription-collab")
                }}
              >Inscrire un collaborateur</button>
              :
              <button 
              className="btn-orange-fullwidth bold"
                onClick={() => {
                  changeActivCourseId(jobInfo.attributes.formation.data.id)
                  openModal("formation")
                }}
              >S'inscrire à la formation</button>
              }
            </>
            }
            {jobInfo.attributes.formation.data === null && 
              <p className='para-pre'>- Avoir suivi une formation en lien avec l'offre d'emploi. Pour tout complément d'information, se référer à l'offre.</p>
            }
            <h2 className="title-section bold">Détail du poste</h2>
            <ReactMarkdown className="modules-wrapper txt-info">{jobInfo.attributes.description}</ReactMarkdown>
            <h5 className="title-info bold">Vos missions :</h5>
            <ReactMarkdown className="modules-wrapper txt-info">{jobInfo.attributes.missions}</ReactMarkdown>
            <h5 className="title-info bold">Votre profil :</h5>
            <ReactMarkdown className="modules-wrapper txt-info">{jobInfo.attributes.profil}</ReactMarkdown>
            {/* 
            <div className="full-width">
              <p className="bold">Formation nécessaire pour ce poste : {jobInfo.attributes.formation.data.attributes.nom}</p>
                <button className="btn-blue" onClick={() => setModalCourseProgOpen(true)}>Voir le programme de formation</button>
            </div>
            */}
            <div className="suitcase-wrapper">
              <img src={suitcaseGreen} className="suitcase-picto" alt="sac a main vert" />
            </div>
          </div>
          </div>
          }
        </section>
      </main>
      <Footer />
    </JobStyled>
  )
}

Job.propTypes = {
  openModal: PropTypes.func.isRequired,
}

export default Job