import React, { useState } from 'react';
import { Button, Modal, Icon } from 'semantic-ui-react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FaFilePdf, FaDownload } from "react-icons/fa";
import urlBackEndSave from '../../../assets/js/urlBackEndSave';
import { saveAs } from 'file-saver'

const PdfProgramme = ({ programme_pdf }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  const renderPDF = () => {
    const pages = [];

    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
      pages.push(
        <Page key={pageNumber} pageNumber={pageNumber} width={window.innerWidth > 1000 ? 800 : window.innerWidth * 0.83} style={{paddingTop: '10px'}}/>,
      );
    }

    return (
      <div style={{ width: '100%', height: '60vh', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '900px', transform: "scale(1.1)" }}>
        {pages}
      </div>
    );
  };

  return (
    <div>
      <Button 
        onClick={() => {
          openModal()
          window.dataLayer.push({'event': 'show_pdf'})
        }} 
        color="blue" 
        style={{ padding: '20px 20px'}}
      >
        <FaFilePdf style={{margin: '0 5px -2px 0'}}/>
        Programme de la formation
      </Button>

      <Button 
        onClick={() => {
          savePdf(programme_pdf.url, programme_pdf.name)
          window.dataLayer.push({'event': 'dl_pdf'})
        }} 
        color="blue" 
        style={{ padding: '20px 20px'}}
      >
        <FaDownload style={{margin: '0 0px -2px 0'}}/>
      </Button>

      <Modal open={isOpen} onClose={closeModal} >
        <Modal.Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '65vh', paddingLeft: '1rem!important' }} scrolling>
          <Document
            file={urlBackEndSave + programme_pdf.url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {renderPDF()}
          </Document>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} negative>
            Fermer
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};



export default PdfProgramme;

