import styled from 'styled-components';

const AccountParrainageStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

    .right-wrapper {
        width: calc(96% - 250px);
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        padding: 50px 0;

        h2 {
            text-align: left;
            margin: 20px 0 20px;
            font-size: 22px;
            color: #202D3C;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;

            .btn-modal {
                margin: 0 0 0 10px;
                padding: 10px;
                border-radius: 5px;
                font-size: 20px;
                background-color: #6399ce;
                color: #fff;
                transition: all ease .2s;
                cursor: pointer; 
                &:hover {
                    opacity: .7;
                }
            }

            svg {
                color: #fff; 
                margin-bottom: -3px;
                margin-left: 10px;
                transition: all .2s ease;
                cursor: pointer;
            }
        }

        .gain-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;
            width: 100%;

            .potentiel, .win, .total {
                width: 140px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                padding: 20px 0;
                text-align: center;
                margin: 0 20px 0 0;
                border-radius: 5px;
            }

            .potentiel {
                background-color: #fcf5f3;
                color: #d47558;
            }

            .win {
                background-color: #f3f8f5;
                color: #6aaf8b;
            }

            .total {
                background-color: #f0f5fa;
                color: #6399ce;
            }
        }

        .pre-list-wrapper {
            width: 100%;
            box-sizing: border-box;
            box-shadow: 0px 1px 10px #00000029;
            border-radius: 10px;
            background-color: #fff;
            display: flex;
            flex-wrap: wrap;
            margin: 30px 0 30px;
            position: relative;

            .title-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid #4d4d4d;

                .row-title {
                    width: 25%;
                    text-align: center;
                    font-size: 16px;
                    padding: 10px 0;

                    &.orange {
                        color: #D47558;
                    }
    
                    &.green {
                        color: #6AAF8B;
                    }
                }
            }

            .info-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                &:nth-child(odd) {
                    background-color: #eaf3ee;
                }

                .row-user {
                    width: 25%;
                    text-align: center;
                    font-size: 16px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 0;
                    word-break: break-word;


                    .align-right {
                        width: 60px;
                        text-align: right;
                    }

                    &.left {
                        justify-content: start;
                    }

                    .info-svg {
                        margin: 0 0 0 5px;
                    }
                }
            }
        }

        .rib-wrapper {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            .no-rib {
                width: 100%;
            }

            #file-upload {
                display: none;
            }
    
            .drop-file-wrapper {
                margin: 20px 0 30px;
                width: 150px;
                text-align: left;
    
                .file-upload-btn {
                    background-color: #649ACE;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    transition: all .3s ease;
                    cursor: pointer;
                    width: auto;
                    padding: 10px 0;
    
                    &:hover {
                        opacity: .7;
                    }
        
                    div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }    
        }
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(96% - 250px);

            h1 {
                font-size: 45px;
            }

            h2 {

            }
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(96% - 250px);

            h1 {
                font-size: 40px;
            }

            h2 {


                .separator-blue {
                    width: 100px;
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {
        margin: 60px auto 80px;
        
        .right-wrapper {
            width: calc(96%);
            padding: 20px 0;

            h1 {
                font-size: 24px;

                .separator-blue {
                    width: 100px;
                }
            }

            h2 {
                font-size: 24px;
                text-align: center;
                margin: 0;
                width: 100%;
                justify-content: center;

                .separator-blue {
                    width: 50px;
                    position: static;
                    margin: 10px auto;
                }

                .btn-modal {
                    display: inline-block;
                    margin: 20px auto 20px;
                    font-size: 16px;
                }
            }

            .gain-wrapper {
                justify-content: space-between;
                align-items: stretch;
                margin-top: 20px;
    
                .potentiel, .win, .total {
                    width: 30%;
                    margin: 0 0px 0 0;
                }
            }

            .pre-list-wrapper {
    
                .title-wrapper {
    
                    .row-title {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
    
                .info-wrapper {
    
                    .row-user {
                        font-size: 12px;
                    }
                }
    
                .action-wrapper {
    
                    .row-action {
                        font-size: 12px;
                    }
                }

                .insc-btn {
                    padding: 10px;
                    border-radius: 5px;
                    font-size: 12px;
                }
            }
        }
    }
}
  
`;

export default AccountParrainageStyled;