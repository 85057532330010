/**** Import des modules ****/
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Rating from '@mui/material/Rating'
import axios from 'axios'
import * as qs from 'qs'
import { toast } from 'react-toastify'


/**** Import des css ****/
import ModalSatisfactionStyled from './ModalSatisfactionStyled'

/**** ****/
import urlBackEnd from '../../assets/js/urlBackEnd';
const URL = urlBackEnd

const ModalSatisfaction = ({toggleModalSatisfaction, userRole, changeQsInput, attributes, courseId, fetchPostQS, userInfo, fetchGetQSStatut, session, qsOne, qsTwo, qsThree, qsFour, qsOneScore, qsTwoScore, qsThreeScore, statut, factureId }) => {

  const [value, setValue] = React.useState(0);
  const [valueTwo, setValueTwo] = React.useState(0);
  const [valueThree, setValueThree] = React.useState(0);
  const [facture, setFacture] = React.useState('');

  const verifyForm = () => {
    if(qsOneScore === 0 || qsTwoScore === 0 || qsThreeScore === 0) {
      return "noter les questions à l'aide des étoiles"
    } else if(qsTwo ===  '' || qsThree === '') {
      return "répondre aux questions à l'aide des formulaires"
    } else {
      return false
    }
  }

  return (
    <ModalSatisfactionStyled>
    {statut === 'particulier' &&
      <section className="modal-content-container">
        <div className="page-wrapper-message">
          <div className="scroll-zone">
            <div className="top">
              <div className="title-container">
                <h3 className="bold dark">FORMATION</h3>
                <h3 className="blue">{attributes.nom}</h3>
              </div>
              {facture !== '' && facture !== undefined &&
              <div className='dates'>
                {facture.attributes.start_session !== null && facture.attributes.start_session} / {facture.attributes.end_session !== null && facture.attributes.end_session}
              </div>
              }
            </div>
            <h2 className='bold'>Questionnaire de satisfaction</h2>
            {userRole === 'particulier' &&
              <form 
              className="form-wrapper"
              onSubmit={(event) => {
                event.preventDefault();
                if(verifyForm() === false) {
                  fetchPostQS(courseId, factureId);
                  toggleModalSatisfaction(false)
                  window.location.reload();
                } else {
                  toast(`Merci de ${verifyForm()}`, {
                    position: "bottom-right",
                    type: "error",
                    theme:"colored",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  })
                }
              }}
              >
                <div className='text-area'>
                  <div>
                  <label>La formation a-t-elle correspondu a vos attentes ?</label>
                    <Rating
                      name="qsOneScore"
                      value={qsOneScore}
                      required
                      onChange={(event, newValue) => {
                        changeQsInput(Number(event.target.value), event.target.name);
                      }}
                    />
                  </div>
                </div>
                <div className='text-area'>
                <div>
                <label htmlFor="qsTwo">Dans quelle mesure êtes vous satisfait de la formation suivie ?</label>
                <Rating
                    name="qsTwoScore"
                    value={qsTwoScore}
                    onChange={(event, newValue) => {
                      changeQsInput(Number(event.target.value), event.target.name);
                    }}
                  />
                </div>
                  <textarea 
                  id="qsTwo" 
                  name="qsTwo" 
                  placeholder='Réponse...'
                  onChange={(event) => {
                    changeQsInput(event.target.value, event.target.name);
                  }}
                >
                  </textarea>
                </div>
                <div className='text-area'>
                <div>
                <label htmlFor="qsThree">Pensez-vous que la formation suivie vous a aidé à combler des lacunes ?</label>
                <Rating
                    name="qsThreeScore"
                    value={qsThreeScore}
                    onChange={(event, newValue) => {
                      changeQsInput(Number(event.target.value), event.target.name);
                    }}
                  />
                </div>
                  <textarea 
                    id="qsThree" 
                    name="qsThree" 
                    placeholder='Réponse...'
                    onChange={(event) => {
                      changeQsInput(event.target.value, event.target.name);
                    }}
                  >
                  </textarea>
                </div>
                <div className='text-area'>
                <label htmlFor="qsFour">Autres commentaires :</label>
                  <textarea 
                    id="qsFour" 
                    name="qsFour" 
                    placeholder='Réponse...'
                    onChange={(event) => {
                      changeQsInput(event.target.value, event.target.name);
                    }}
                  >
                  </textarea>
                </div>
                <div>
                  <div className='align-center'><Rating value={1} readOnly /> Très peu satisfait</div>
                  <div className='align-center'><Rating value={2} readOnly /> Peu satisfait</div>
                  <div className='align-center'><Rating value={3} readOnly /> Moyennement satisfait</div>
                  <div className='align-center'><Rating value={4} readOnly /> Satisfait</div>
                  <div className='align-center'><Rating value={5} readOnly /> Très satisfait</div>
                </div>
                <div className='btn-w'>
                  <input type='submit' value="Envoyer" className='green-btn'/>
                </div>
              </form>
            }
          </div>
        </div>
        <div className="icon-wrapper">
        <AiOutlineClose 
        className="close-icon"
        onClick={() => toggleModalSatisfaction(false)}
        />
      </div>
      </section>
    }
    {statut === 'entreprise' &&
      <section className="modal-content-container">
        <div className="page-wrapper-message">
          <div className="scroll-zone">
            <div className="top">
              <div className="title-container">
                <h3 className="bold dark">FORMATION</h3>
                <h3 className="blue">{attributes.nom}</h3>
              </div>
              {facture !== '' && facture !== undefined &&
              <div className='dates'>
                {attributes.start_session !== null && attributes.start_session} / {attributes.end_session !== null && attributes.end_session}
              </div>
              }
            </div>
            <h2 className='bold'>Questionnaire de satisfaction</h2>
            {userRole === 'entreprise' &&
              <form 
              className="form-wrapper"
              onSubmit={(event) => {
                event.preventDefault();
                if(verifyForm() === false) {
                  fetchPostQS(courseId, factureId);
                  toggleModalSatisfaction(false)
                  window.location.reload();
                } else {
                  toast(`Merci de ${verifyForm()}`, {
                    position: "bottom-right",
                    type: "error",
                    theme:"colored",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  })
                }
              }}
              >
                <div className='text-area'>
                  <div>
                  <label>Avez-vous été satisfait de votre relation avec l’organisme de formation ?</label>
                    <Rating
                      name="qsOneScore"
                      value={qsOneScore}
                      required
                      onChange={(event, newValue) => {
                        changeQsInput(Number(event.target.value), event.target.name);
                      }}
                    />
                  </div>
                </div>
                <div className='text-area'>
                <div>
                <label htmlFor="qsTwo">Quelles améliorations souhaiteriez-vous apporter à la plateforme ?</label>
                <Rating
                    name="qsTwoScore"
                    value={qsTwoScore}
                    onChange={(event, newValue) => {
                      changeQsInput(Number(event.target.value), event.target.name);
                    }}
                  />
                </div>
                  <textarea 
                  id="qsTwo" 
                  name="qsTwo" 
                  placeholder='Réponse...'
                  onChange={(event) => {
                    changeQsInput(event.target.value, event.target.name);
                  }}
                >
                  </textarea>
                </div>
                <div className='text-area'>
                <div>
                <label htmlFor="qsThree">Recommanderiez-vous notre plateforme ?</label>
                <Rating
                    name="qsThreeScore"
                    value={qsThreeScore}
                    onChange={(event, newValue) => {
                      changeQsInput(Number(event.target.value), event.target.name);
                    }}
                  />
                </div>
                  <textarea 
                    id="qsThree" 
                    name="qsThree" 
                    placeholder='Réponse...'
                    onChange={(event) => {
                      changeQsInput(event.target.value, event.target.name);
                    }}
                  >
                  </textarea>
                </div>
                <div className='text-area'>
                <label htmlFor="qsFour">Autres commentaires :</label>
                  <textarea 
                    id="qsFour" 
                    name="qsFour" 
                    placeholder='Réponse...'
                    onChange={(event) => {
                      changeQsInput(event.target.value, event.target.name);
                    }}
                  >
                  </textarea>
                </div>
                <div>
                  <div className='align-center'><Rating value={1} readOnly /> Très peu satisfait</div>
                  <div className='align-center'><Rating value={2} readOnly /> Peu satisfait</div>
                  <div className='align-center'><Rating value={3} readOnly /> Moyennement satisfait</div>
                  <div className='align-center'><Rating value={4} readOnly /> Satisfait</div>
                  <div className='align-center'><Rating value={5} readOnly /> Très satisfait</div>
                </div>
                <div className='btn-w'>
                  <input type='submit' value="Envoyer" className='green-btn'/>
                </div>
              </form>
            }
          </div>
        </div>
        <div className="icon-wrapper">
        <AiOutlineClose 
        className="close-icon"
        onClick={() => toggleModalSatisfaction(false)}
        />
      </div>
      </section>
    }
    </ModalSatisfactionStyled>
  )
}

ModalSatisfaction.propTypes = {

}

export default ModalSatisfaction