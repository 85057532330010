/**** Import des modules ****/
import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper";
import { AiFillCaretDown, AiOutlineDoubleRight } from "react-icons/ai"
import { HiOutlineCursorClick } from "react-icons/hi"
import { MdDeleteForever, MdOutlineArrowBackIosNew } from "react-icons/md"
import { FaList, FaHandPointRight, FaRedo } from "react-icons/fa"
import { BsFillGridFill } from "react-icons/bs"
import PaginationMui from '@mui/material/Pagination'
import PropTypes from 'prop-types'
import AOS from 'aos';
import "aos/dist/aos.css"
import { StickyContainer, Sticky } from 'react-sticky'
import ReactMarkdown from 'react-markdown'
import { Helmet } from "react-helmet"
import { Button } from 'semantic-ui-react'
import { Icon } from 'semantic-ui-react'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import CourseCard from '../../containers/CourseCard'
import CourseCardListLayout from '../../containers/CourseCardListLayout'
import ModalCourseProg from '../../containers/ModalCourseProg'
import ModalJobFiche from '../../containers/ModalJobFiche'
import CourseCardLoading from '../../components/CourseCardLoading'
import CourseCardLoadingList from '../../components/CourseCardLoadingList'
import shield from '../../assets/img/picto/shield.png'
import SearchCourseByKey from '../../containers/SearchCourseByKey'

/**** Import des images ****/
import poucegreen from '../../assets/img/illustration/poucegreen.png'
import timeyellow from '../../assets/img/illustration/timeyellow.png'
import euroblue from '../../assets/img/illustration/euroblue.png'
import logo from '../../assets/img/illustration/femme-desk.png'
import cpficon from '../../assets/img/logo/WEFOR_CARRE_COULEUR.png'

/**** Import des css ****/
import FormationStyled from './FormationStyled'
import axios from 'axios'
import * as qs from 'qs'

import urlBackEnd from '../../assets/js/urlBackEnd'
import { Skeleton } from '@mui/material';
import PdfProgramme from './pdfProgamme';

const URL = urlBackEnd

const Formation = ({ openModal, formations, activCourseId, loadFormation, secteurs, domaines, setFilter, secteurCourseFilter, domaineCourseFilter, dureeCourseFilter, userRole, currentProfilUser, changeActivCourseId, formationCourseFilter, fetchGetOneProgram, templatesLms, coursesLms, isLogged, jobFiches, fetchFormations, formationsPagination, filterSecteurId, setFilterId, filterDomaineId, cpfCourseFilter, filterCpfId, secteursData, fetchSecteurs, fetchFormationsGraph, fetchGetCoursesLms, fetchFormationsInfos, formationInfo}) => {

  const hasWindow = typeof window !== 'undefined';
  const navigate = useNavigate()
  let location = useLocation()

  let { idcourse } = useParams()
  const swiperRef = useRef(null);

  useEffect(() => {
    if (secteursData.length === 0) {
      fetchSecteurs()
    }
  }, []);

  useEffect(() => {
    if (formations.length === 0) {
      const paramObj = {page: 'app' ,intemNb: 10, pageNb: 1, courseId : idcourse === undefined ? false : Number(idcourse)}
      fetchFormations(paramObj)
    } else {
      if(location.search.includes('?dda')) {
        setFilter(3, 'domaineCourse') 
        const paramObj = {page: 'app' ,intemNb: 10, pageNb: 1, courseId : idcourse === undefined ? false : Number(idcourse)}
        fetchFormations(paramObj)
      }
      navigate(`/formation/${activCourseId}`)
    }
  }, [isLogged, activCourseId]);

  useEffect(() => {
    if (activCourseId !== 1) {
      fetchFormationsInfos(activCourseId)
    }
  }, [activCourseId]);

  useEffect(() => {
    if (formationInfo && formationInfo.attributes.lms_formation_id !== 'none' && formationInfo.attributes.lms_formation_id !== 'univo' && formationInfo.attributes.lms_formation_id !== null && formationInfo.attributes.lms_formation_id !== 'formapro') {
      fetchGetOneProgram(formationInfo.attributes.lms_formation_id)
      fetchGetCoursesLms()
    }
  }, [formationInfo]);

  const [page, setPage] = useState(1)
  const [activLayout, setActivLayout] = useState('list')
  const [cardPerPage, setCardPerPage] = useState(10)
  const [indexOfLastCard, setIndexOfLastCard] = useState(cardPerPage)
  const [indexOfFirstCard, setIndexOfFirstCard] = useState(0)

  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])

  const [modalJobFicheOpen, setModalJobFicheOpen] = useState(false);
  const [modalCourseProgOpen, setModalCourseProgOpen] = useState(false);
  
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const handleChildElementClick = (e) => {
    e.stopPropagation()
    // Do other stuff here
 }
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  let formation = () => {
    if (loadFormation === false) {
      let formation = formations.find(item => item.id === activCourseId);
      return formation;
    }
  }

  const changePagesCards = (value) => {
    setIndexOfLastCard(value * cardPerPage)
    setIndexOfFirstCard(value * cardPerPage - cardPerPage)
  }
 
  const handleChangePagination = (event, value) => {   
    setPage(value)
    changePagesCards(value)
    const paramObj = {page: 'formations' ,intemNb: 10, pageNb: value}
    fetchFormations(paramObj)
    window.scrollTo(0, 250);
  };

  const goToFirstSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(0);
    }
  };

  const handleLoadMore = (event, value) => {   
    const pageNb = formationsPagination.page + 1
    setSwiper(1);
    goToFirstSlide()
    const paramObj = {page: 'formations' ,intemNb: 10, pageNb: pageNb}
    fetchFormations(paramObj)
  };

  const  handleLoadMinus = (event, value) => {   
    const pageNb = formationsPagination.page - 1
    setSwiper(1);
    goToFirstSlide()
    const paramObj = {page: 'formations' ,intemNb: 10, pageNb: pageNb}
    fetchFormations(paramObj)
  };

  
  
  
  let nbOfCard = Math.round(windowDimensions.width / 450 + 0);

  const [btnFilterSecteurOnOff, setBtnFilterSecteurOnOff] = useState(false);
  const [btnFilterDomaineOnOff, setBtnFilterDomaineOnOff] = useState(false);
  const [btnFilterFormationOnOff, setBtnFilterFormationOnOff] = useState(false);
  const [btnFilterNiveauOnOff, setBtnFilterNiveauOnOff] = useState(false);
  const [btnFilterCPFOnOff, setBtnFilterCPFOnOff] = useState(false);
  const [swiper, setSwiper] = useState(null);

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const cpfData = [
    {
      id: 1,
      nom: 'Éligible CPF'
    },
    {
      id: 2,
      nom: 'Non éligible CPF'
    }
  ]

  return (
    <FormationStyled
    onClick={() => {
      if (btnFilterSecteurOnOff === true) {
        setBtnFilterSecteurOnOff(false)
      }
      if (btnFilterDomaineOnOff === true) {
        setBtnFilterDomaineOnOff(false)
      }
      if (btnFilterFormationOnOff === true) {
        setBtnFilterFormationOnOff(false)
      }
      if (btnFilterNiveauOnOff === true) {
        setBtnFilterNiveauOnOff(false)
      }
      if (btnFilterCPFOnOff === true) {
        setBtnFilterCPFOnOff(false)
      }
    }}
    >
      {modalCourseProgOpen &&
        <ModalCourseProg modalCourseProgOpen={modalCourseProgOpen} setModalCourseProgOpen={setModalCourseProgOpen} />
      }
      {modalJobFicheOpen &&
        <ModalJobFiche modalJobFicheOpen={modalJobFicheOpen} setModalJobFicheOpen={setModalJobFicheOpen} />
      }
      <Header bcgColor={'#d47558'}/>
      <Helmet>
          <title>WEFOR</title>
          <meta name='description' content="Liste des formations WEFOR. Montez en compétence."/>
          <meta name='keywords' content='Wefor, formation, cabinet de recrutement'/>
      </Helmet>
      <section className="top-illustration">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Nos formations</h1>
            {currentProfilUser === "Demandeur d'emploi" && 
            <>
              <p>Vous recherchez une formation très opérationnelle avec des <span className="bold">offres d’emploi à la clé</span> ? Découvrez les formations Wefor !</p>
              <p>Wefor vous propose des formations en ligne, accessibles 7 jours sur 7 et 24h sur 24, sur une <span className="bold">plateforme pédagogique</span> ludique et performante.
              </p>
            </>
            }
            {currentProfilUser === "Salarié" && 
            <>
              <p>Vous visez une promotion, une mobilité interne ou une reconversion ? Découvrez les formations Wefor !</p>
              <p>Wefor s’adapte aux contraintes des salariés en leur proposant des <span className="bold">formations flexibles</span>, accessibles 7 jours sur 7 et 24h sur 24, sur une plateforme pédagogique ludique et performante.</p>
            </>
            }
            {currentProfilUser === "Entreprise" && 
            <>
              <p>Vous souhaitez former vos collaborateurs ? Découvrez les formations Wefor. Régulièrement adaptées pour être en phase avec le marché et vos besoins en compétences, elles vous permettent de <span className="bold">booster l’efficacité de vos collaborateurs</span> rapidement !</p>
              <p>Pour la garantie d’une prestation de qualité, nos formations sont éligibles aux dispositifs de financement.</p>
            </>
            }
            {currentProfilUser === "Autres" && 
            <>
              <p>Vous recherchez une formation très opérationnelle pour vous reconvertir ou <span className="bold">évoluer professionnellement</span> ? Découvrez les formations Wefor !</p>
              <p>Wefor vous propose des formations compatibles avec une activité professionnelle, accessibles 7 jours sur 7 et 24h sur 24, sur une <span className="bold">plateforme pédagogique</span> ludique et performante.</p>
            </>
            }
            {currentProfilUser === "notChoose" &&
            <>
              <p>Vous recherchez une formation très opérationnelle ou bien une reconversion professionnelle avec des <span className="bold">offres d’emploi à la clé</span> ? Découvrez les formations Wefor !</p>
              <p>Wefor vous propose des formations en ligne, accessibles 7 jours sur 7 et 24h sur 24, sur une <span className="bold">plateforme pédagogique</span> ludique et performante.
              </p>
            </>
            }
          </div>
        </div>
      </section>
      <h2 className='title-page-mobile bold'>Nos formations</h2>
      <section className="filter-formation">
        <div className="wrapper-filter">
          {/*!isLogged &&
          <div 
            className={btnFilterDomaineOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
            onClick={() => setBtnFilterDomaineOnOff(!btnFilterDomaineOnOff)}
          >          
          {domaineCourseFilter !== "" ?
          <span className='filter'>
            {filterDomaineId} <MdDeleteForever onClick={(e) => {
              setFilter('', 'domaineCourse') 
              setFilterId('', 'filterDomaineId')
              handleChildElementClick(e)
              fetchFormations()
              }} />
          </span>
          :
          <>
          Vous êtes ?  
          <AiFillCaretDown/>
          </>
        }
            <ul className={btnFilterDomaineOnOff ? "show-menu" : ""}>
            {domaines.map((domaine) => (
              <li 
                key={domaine.id}
                onClick={() => {
                  setFilter(domaine.id, 'domaineCourse') 
                  setFilterId(domaine.attributes.nom, 'filterDomaineId')
                  fetchFormations()
                }}
                >
                  {domaine.attributes.nom}
                </li>
              ))}
            </ul>
          </div>
          */}
          <div 
            className={btnFilterSecteurOnOff ? "border-r-off btn-filter bold" : secteurCourseFilter === '' ? "btn-filter bold" : "btn-filter bold selected-state"}
            onClick={() => setBtnFilterSecteurOnOff(!btnFilterSecteurOnOff)}
          >
          {secteurCourseFilter !== "" ?
            <span className='filter bold'>
              {filterSecteurId} <MdDeleteForever onClick={(e) => {
                setFilter('', 'secteurCourse') 
                setFilterId('', 'filterSecteurId')
                handleChildElementClick(e)
                fetchFormations()
                }} />
            </span>
            :
            <>
            Secteurs 
            <AiFillCaretDown/>
            </>
          }
            <ul className={btnFilterSecteurOnOff ? "show-menu" : ""}>
              {secteurs.filter(secteur => secteur.attributes.formations.data.length > 0).map((secteur) => (
                <li 
                  key={secteur.id}
                  onClick={() => {
                    setFilter(secteur.id, 'secteurCourse') 
                    setFilterId(secteur.attributes.nom, 'filterSecteurId')
                    fetchFormations()
                  }}
                >
                  {secteur.attributes.nom}
                  <span className='fixed-number bold'>{secteur.attributes.formations.data.length}</span>
                </li>
              ))}
            </ul>
          </div>
          <div 
            className={btnFilterCPFOnOff ? "border-r-off btn-filter bold" : cpfCourseFilter === '' ? "btn-filter bold" : "btn-filter bold selected-state"}
            onClick={() => setBtnFilterCPFOnOff(!btnFilterCPFOnOff)}
          >
          {cpfCourseFilter !== "" ?
            <span className='filter bold'>
              {filterCpfId} <MdDeleteForever onClick={(e) => {
                setFilter('', 'cpfCourse') 
                setFilterId('', 'filterCpfId')
                handleChildElementClick(e)
                fetchFormations()
                }} />
            </span>
            :
            <>
            Financement
            <AiFillCaretDown/>
            </>
          }
            <ul className={btnFilterCPFOnOff ? "show-menu" : ""}>
              {cpfData.map((item) => (
                <li 
                  key={item.id}
                  onClick={() => {
                    setFilter(item.id, 'cpfCourse') 
                    setFilterId(item.nom, 'filterCpfId')
                    fetchFormations()
                  }}
                >
                  {item.nom}
                </li>
              ))}
            </ul>
          </div>
          {/*
          <button onClick={() => {
              setFilter(3, 'domaineCourse') 
              const paramObj = {page: 'app' ,intemNb: 10, pageNb: 1, courseId : false}
              fetchFormations(paramObj)
            }} 
            className="btn-dda bold"
          >
              DDA
          </button>
           */}
          <SearchCourseByKey className="btn-key" fetchFormations={fetchFormationsGraph} secteurCourseFilter={secteurCourseFilter}/>
          {/*secteurCourseFilter !== "" &&
          <div
            className={btnFilterNiveauOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
            onClick={() => setBtnFilterNiveauOnOff(!btnFilterNiveauOnOff)}
          >          
          {dureeCourseFilter !== "" ?
          <span className='filter'>
            {dureeCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'dureeCourse') 
              handleChildElementClick(e)
              }} />
          </span>
          :
          <>
          Métiers 
          <AiFillCaretDown/>
          </>
        }
            <ul className={btnFilterNiveauOnOff ? "show-menu" : ""}>
            {isLogged && jobFiches && jobFiches.filter(fiche => fiche.attributes.domaine.data.attributes.nom === (isLogged ? capitalizeFirstLetter(userRole) : '')).map((fiche) => (
                <li 
                  key={fiche.id}
                  onClick={() => setFilter(fiche.attributes.nom, 'dureeCourse') }
                >
                  {fiche.attributes.nom}
                </li>
              ))}
            {!isLogged && jobFiches && jobFiches.filter(fiche => fiche.attributes.domaine.data.attributes.nom === (currentProfilUser === 'Entreprise' ? capitalizeFirstLetter(currentProfilUser) : 'Particulier')).map((fiche) => (
              <li 
                key={fiche.id}
                onClick={() => setFilter(fiche.attributes.nom, 'dureeCourse') }
              >
                {fiche.attributes.nom}
              </li>
            ))}
            </ul>
            </div>
            */}
        </div>
      </section>
      <section className="user-filter-section">
        <BsFillGridFill 
          className={`layout-btn ${activLayout === 'grid' ? 'active-layout' : ''}`} 
          onClick={() => {
            setActivLayout('grid')
            setCardPerPage(10)
            setIndexOfLastCard(10)
            setIndexOfFirstCard(0)
            setPage(1)
          }}
        />
        <FaList 
          className={`layout-btn ${activLayout === 'list' ? 'active-layout' : ''}`}
          onClick={() => {
            setActivLayout('list')
            setCardPerPage(10)
            setIndexOfLastCard(10)
            setIndexOfFirstCard(0)
            setPage(1)
          }}
        />
        {/* 
        {secteurCourseFilter !== "" &&
          <span className='filter'>
            {secteurCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'secteurCourse') 
              handleChildElementClick(e)
              }} />
          </span>
        }
        {domaineCourseFilter !== "" &&
          <span className='filter'>
            {domaineCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'domaineCourse') 
              handleChildElementClick(e)
              }} />
          </span>
        }
        {formationCourseFilter !== "" &&
          <span className='filter'>
            {formationCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'formationCourse') 
              handleChildElementClick(e)
              }} />
          </span>
        }
        {dureeCourseFilter !== "" &&
          <span className='filter'>
            {dureeCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'dureeCourse') 
              handleChildElementClick(e)
              }} />
          </span>
        }
        */}
      </section>
      <main className="course-main">
      <section className="course-list-left mobile">
        <Swiper
          ref={swiperRef}
          slidesPerView={nbOfCard}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper slider-mobile"
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          onActiveIndexChange={(swiper) => {
            console.log("active index is", swiper.activeIndex);
          }}
        >
        {!loadFormation && formations.length === 0 && 
          <p className='not-found'>Votre recherche ne comporte aucun resultat, merci de changer les filtres</p>
        }
          {!loadFormation && formations.map((formation) => (
            <SwiperSlide key={formation.id}>
              {({ isActive }) => (
                <>
                  <CourseCard className="display-buttons" isActive={isActive} courseId={formation.id} {...formation}/>
                </>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className='nav-swipper-wrapper'>
          {!loadFormation && formationsPagination.page && formationsPagination.page > 1 && 
            <Button color='grey' className='load-less' onClick={handleLoadMinus}><MdOutlineArrowBackIosNew /></Button>
          }
          {!loadFormation && formationsPagination.page && formationsPagination.page !== formationsPagination.pageCount && 
            <Button 
              color='grey'
              className='load-more' 
              onClick={() => {
                handleLoadMore()
                window.dataLayer.push({'event': 'load_more'})
              }}
            >
            <FaRedo /> Charger plus</Button>
          }
        </div>
        </section>
        <section className={`course-list-left computer ${activLayout === 'list' ? 'column' : ''}`}>
          {loadFormation && activLayout === 'grid' && 
            <>
              <CourseCardLoading />
              <CourseCardLoading />
              <CourseCardLoading />
              <CourseCardLoading />
            </>
          }
          {loadFormation && activLayout === 'list' && 
            <>
              <CourseCardLoadingList />
              <CourseCardLoadingList />
              <CourseCardLoadingList />
              <CourseCardLoadingList />
            </>
          }
        {!loadFormation && formations.length === 0 && 
          <p className='not-found'>Votre recherche ne comporte aucun resultat, merci de changer les filtres</p>
        }
        {!loadFormation && formations.map((formation) => (
          <div key={formation.id} className={`card-wrapper ` + (activLayout === 'list' ? 'fullwidth' : '')}>
            {activLayout === 'list' && 
              <CourseCardListLayout key={formation.id} courseId={formation.id} {...formation}/>
            }
            {activLayout === 'grid' && 
              <CourseCard key={formation.id} courseId={formation.id} {...formation}/>
            }
          </div>
        ))
        }
        {!loadFormation && formationInfo &&
          <PaginationMui page={formationsPagination.page} className="pagination-pc" count={Math.ceil(formationsPagination.pageCount)} variant="outlined" color="primary" onChange={handleChangePagination}/>
        }
        </section>
        {formationInfo && formations.length > 0 && 
          <div className="course-info-right">
          <div className="info-wrapper">
            <div className="card-orange">
              <div className="row bold">
              E-LEARNING
              </div>
              <div className="row">
                <h3 className="bold">{formationInfo.attributes.nom}</h3>
              </div>
            </div>
            {formationInfo.attributes.objectifs !== null && formationInfo.attributes.objectifs !== '' && 
              <div className="programme-disclaimer">
                <h6 className="bold">Objectif :</h6>
              <ReactMarkdown>
                {formationInfo.attributes.objectifs}
              </ReactMarkdown>
            </div>
            }
            {formationInfo.attributes.competences_acquises !== null && formationInfo.attributes.competences_acquises !== '' && 
              <h6 className="bold">{formationInfo.attributes.competences_acquises}</h6>
            }
              <div className="fiche">
                {formationInfo.attributes.le_travail !== null && formationInfo.attributes.le_travail !== '' &&
                  <a href={formationInfo.attributes.le_travail} target='_blank' rel='noreferrer'><Button>Voir la fiche France Compétences</Button></a>
                }
              </div>
            <div className="price-time-wrapper">
              <div className='time-wrapper'>
              <div className="programme-disclaimer">
                {formationInfo.attributes.programme_pdf.data !== null &&
                  <><PdfProgramme programme_pdf={formationInfo.attributes.programme_pdf.data.attributes }/></>
                }
              </div>
              </div>
              <div className="price-wrapper">
                <div className='financement-card'>
                  <h3 className='bold'>Nos mécanismes de financement</h3>
                  <p className="bold price-dotted-cont">
                    <span className='net'>Prix : </span> <span className={formationInfo.attributes.nom.includes('DDA') ? 'bold' : 'bold'}>{euroFormat.format(formationInfo.attributes.prix)}</span>
                      <span className='net'> net de TVA*</span>
                      <span className='disclaimer'>(* TVA non applicable selon l’article 293 B du Code Général des Impôts)</span>
                      {/*formationInfo.attributes.nom.includes('DDA') && 
                        <span className='prix-dotted bold'> 990 € </span>
                      */}
                  </p>
                  <div className='btn-wrapper'>

                <div onClick={() => openModal("formation")} className={`opco ${(formationInfo.attributes.cpf_link === null || formationInfo.attributes.cpf_link === '') && 'two'}`}>
                  < HiOutlineCursorClick />
                    <button 
                      className='btn-cpf bold'
                      onClick={() => {window.dataLayer.push({'event': 'opco'})}}
                    >
                      OPCO
                    </button>
                </div>
                {formationInfo.attributes.cpf_link !== null && formationInfo.attributes.cpf_link !== '' ? 
                <a href={formationInfo.attributes.cpf_link} target='_blank' rel='noreferrer' className='cpf'>
                  < HiOutlineCursorClick />
                  <button className='btn-cpf bold' onClick={() => {window.dataLayer.push({'event': 'click-cpf'})}}>CPF</button>
                </a>
                :
                <></>
                }
                <div onClick={() => openModal("formation")} className={`banque ${(formationInfo.attributes.cpf_link === null || formationInfo.attributes.cpf_link === '') && 'two'}`}>
                  < HiOutlineCursorClick />
                  <button 
                    className='btn-cpf bold'
                    onClick={() => {window.dataLayer.push({'event': 'banque'})}}
                  >
                    Prélèvement ou CB
                  </button>
                </div>
                </div>
                </div>
              </div>
              <div className="duree-wrapper">
               Durée de la formation : <span className='bold'>{formationInfo.attributes.duree}H</span>
              </div>
            </div>
            {/*userRole === "entreprise" ? 
            <button 
            className="btn-orange-fullwidth bold"
              onClick={() => openModal("inscription-collab")}
            >Inscrire un collaborateur</button>
            :
            <button 
            className="btn-orange-fullwidth bold"
              onClick={() => openModal("formation")}
            >S'inscrire</button>
            */}
            {/* ici les modales */}
            <h3 className="title-modalite bold">Méthodes mobilisées</h3>
            <div className="programme-disclaimer">Vous allez découvrir au travers de notre plateforme pédagogique des pages comportant des apports de connaissances, qui seront suivies, page après page, d'activités pédagogiques.
            <div>Formation 100% en distanciel asynchrone proposée sur notre plateforme LMS. Accès possible au démarrage de la formation à J+15 dès votre demande d'inscription pour les formations finançables par le CPF. (Pour les autres types de financement, date de démarrage à definir avec WEFOR)</div>
            <h3 className="title-modalite bold">Modalités d'évaluation</h3>
            <div className="bold">Modalités d'évaluation des objectifs : quizz, tests, études de cas, travaux à réaliser.</div>
            <div className="bold">Obtenir 70% de taux de succès aux différents tests ou épreuves d'évaluation durant le parcours pédagogique dans la plateforme.</div>
            {formationInfo.attributes.modalites_evaluation !== null && formationInfo.attributes.modalites_evaluation !== '' && formationInfo.attributes.modalites_evaluation.includes('%') &&
            <div className="reussite"><span className="bold">Taux de réussite : </span><span className='bold'>{formationInfo.attributes.modalites_evaluation}</span>. Obtenu {formationInfo.attributes.cpf_link === null ? "depuis 2022" : "en 2023"} pour les apprenants ayant suivi intégralement la formation et ayant obtenu au moins 70% de réussite aux différentes épreuves d'évaluation.</div>
            }
                        <h3 className="title-modalite bold">Prérequis</h3>
            <div>Pas de prérequis de niveau.</div>
            <h3 className="title-modalite bold">Matériel nécessaire</h3>
            <div>              <ReactMarkdown>{formationInfo.attributes.prerequis}</ReactMarkdown></div>
            </div>

            <div className="full-width">
              { /*
            <button 
              className="btn-blue-orange bold"
              onClick={() => {
                changeActivCourseId(formationInfo.id)
                setModalCourseProgOpen(true)
              }}
            >Voir le programme de formation</button>
            */}
            </div>
            <div className="button-wrapper">
            <button className="btn-bluelight" onClick={() => setModalJobFicheOpen(true)}>Fiche métier</button>
            </div>
          </div>
          </div>
        }

      </main>
      <Footer />
    </FormationStyled>
  )
}

Formation.propTypes = {
  openModal: PropTypes.func.isRequired
}

export default Formation