/**** Import de modules ****/
import React, {useEffect, useState} from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import axios from 'axios'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



import urlBackEnd from '../../assets/js/urlBackEnd';
import "react-circular-progressbar/dist/styles.css";


/**** Import d'images ****/

/**** Import des css ****/
import ScoreCourseStyled from './ScoreCourseStyled'

const URL = urlBackEnd
const URL360LEARNING = "https://app.360learning.com/api/v1/";
const API360LEARNING = "f96ac09c72064626beb2a2fce44f30b3"
const ID360LEARNING = "62457cdc53413c65bea4c62c"

const ScoreCourse = ({collab, startDate, sessionChoosed}) => {
    
  const [thisScoreOnLMS, setThisScoreOnLMS] = useState('');

  useEffect(() => {
    const options = {
			method: 'GET',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			url: `${URL360LEARNING}users/${collab.attributes.email}/programs?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
		  };

    axios(options)
      .then((response) => {
       // OBJ Du jeudi 24
        const scoreArrayFromLms = response.data
       // On doit MAP dans ce truc et matcher avec la startdate
       const findGoodScore = () => {
        if(scoreArrayFromLms.length > 0) {
          const goodScore = scoreArrayFromLms.find(score => score.startDate.includes(startDate))
          if(goodScore === undefined) {
            return ''
          } else {
            return goodScore
          }
        } else {
          return ''
        }
       }

       setThisScoreOnLMS(findGoodScore())
      })
      .catch((error) => {
        console.error(error);
      });
  }
  , [])

  function secondsToDhms(seconds) {
    seconds = Number(seconds / 1000);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d > 0 ? d + (d === 1 ? " j : " : " j : ") : "";
    var hDisplay = h > 0 ? h + (h === 1 ? " h : " : "h : ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " m : " : " m : ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " s" : " s") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
    }

  return (
    <ScoreCourseStyled>
      <div className='bold col-m'>
        {collab.attributes.nom} {collab.attributes.prenom}
      </div>
      <div className='score col-s'>
        {thisScoreOnLMS !== '' && 
          <CircularProgressbar
            value={thisScoreOnLMS.stats.score}
            text={`${thisScoreOnLMS.stats.score}%`}
            className="progress-bar bold"
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              rotation: 0,
              width: '100px',
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'round',

              // Text size
              textSize: '30px',

              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,

              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',

              // Colors
              pathColor: `#6aaf8b`,
              textColor: '#4d4d4d',
              trailColor: '#e1efe7',
              backgroundColor: '#e1efe7',
            })}
          />
        }
      </div>
      <div className='progress col-s'>
        {thisScoreOnLMS !== '' && 
          <CircularProgressbar
            value={thisScoreOnLMS.stats.progress}
            text={`${thisScoreOnLMS.stats.progress}%`}
            className="progress-bar bold"
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              rotation: 0,
              width: '100px',
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'round',

              // Text size
              textSize: '30px',

              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,

              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',

              // Colors
              pathColor: `#d47558`,
              textColor: '#4d4d4d',
              trailColor: '#f8e8e3',
              backgroundColor: '#f8e8e3',
            })}
          />
        }
      </div>
      <div className='temps bold'>
        {thisScoreOnLMS !== '' && secondsToDhms(thisScoreOnLMS.stats.globalTime)}
      </div>
    </ScoreCourseStyled>
  )
}

ScoreCourse.propTypes = {
}

export default ScoreCourse