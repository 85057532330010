import styled from 'styled-components';

const ModalAccountCreationStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px;

   .section {
       width: 100%;
   }

    @media only screen and (max-width: 1079px) {
        .choices-wrapper {
            padding: 0;
            margin-left: 0;

            .left, .right {
                img {
                    max-width: 250px;
                }

                button.btn-blue {
                    font-size: 25px;
                    width: 250px;
                }
            }

            .left {
                img {
                    max-width: 343px;
                }
            }
        }
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        padding: 20px 0;
        .choices-wrapper {
            flex-direction: column;
            width: 100%;
            .left, .right {
                width: 100%;
            }
        }
    }
`;

export default ModalAccountCreationStyled;