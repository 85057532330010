/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import "aos/dist/aos.css"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'


/**** Import des images ****/
import contract from '../../assets/img/illustration/contratcheck.png'
import logo from '../../assets/img/illustration/header-homme.png'

/**** Import des css ****/
import LegalStyled from './LegalStyled'

const Legal = ({}) => {

  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])
  return (
    <LegalStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      <section className="illustration-header">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Mentions légales</h1>
          </div>
        </div>
      </section>
      <section className="full-page">
        <p className='bold'>WEFOR - Société par actions simplifiées au capital de 1000 €.</p>
        <p>239 Ter, Avenue de la division leclerc</p>
        <p>92160 Antony</p>
        <p>SIRET : 912 733 458 00018</p>
        <p>Code NAF : 8559A</p>
        <p>Tel : 06 65 90 10 12</p>
        <p>Mail : contact@wefor.fr</p>
        <p>Directeur de la publication</p>
        <p>Cyril ZIRAH</p>
        <p className='bold margin-top'>Hébergeur</p>
        <p>OVH</p>
        <p>SAS au capital de 10 174 560 €</p>
        <p>RCS Lille Métropole 424 761 419 00045</p>
        <p>Code APE 2620Z</p>
        <p>N° TVA : FR 22 424 761 419</p>
        <p>Siège social : 2 rue Kellermann - 59100 Roubaix - France</p>
        <h3>Profession réglementée</h3>
        <p>- Récépissé de déclaration d’activité d’un prestataire de formation, déclaration d’activité de prestation de formation n° 11 92 25133 92, Direction régionale des entreprises, de la concurrence, de la consommation, du travail et de l’emploi (DIRECCTE), Ile de France, Service régional de contrôle : 19 rue Madeleine Vionnet 93300 Aubervilliers.</p>

        <h2 className="bold">CONDITIONS GENERALES D’UTILISATION DU SITE</h2>
        <h3 className="bold">I. Périmètre</h3>
        <p>Les présentes mentions légales s’appliquent à toute personne physique qui visite les sites internet, qui utilise les applications mises à disposition et d’une façon générale pour tout évènement auquel la personne participe dans le cadre des services proposés par le Groupe WEFOR.</p>
        <h3 className="bold">II. Informations contenues dans le site</h3>
        <p>Les informations fournies sur les sites du Groupe WEFOR* le sont à titre informatif. Le Groupe WEFOR ne saurait garantir l'exactitude, la complétude, l'actualité des informations diffusées sur le site. Le Groupe WEFOR met tout en œuvre pour offrir aux utilisateurs des informations et des outils disponibles et vérifiés, mais ne saurait être tenu pour responsable des erreurs, des omissions et/ou d'une absence de disponibilité des informations et des services.</p>
        <p>En conséquence, l'utilisateur reconnaît utiliser ces informations sous sa responsabilité exclusive. 
        D’une manière générale, le Groupe WEFOR ne pourra être tenu responsable de tous les dommages directs ou indirects résultant de l’utilisation d’un des sites du Groupe WEFOR*. </p>
        <p>Les sites extérieurs ayant un lien hypertexte avec le présent site ne sont pas sous son contrôle et le Groupe WEFOR décline par conséquent toute responsabilité quant à leur contenu. L'utilisateur est seul responsable de leur utilisation.</p>
        <h3 className="bold">III. Modifications du contenu du site</h3>
        <p>Le Groupe WEFOR est propriétaire de ses sites et se réserve le droit d’en modifier le contenu à tout moment et sans préavis sans pouvoir être tenu responsable des conséquences de telles modifications.</p>
        <p>En outre, le Groupe WEFOR se réserve le droit d’interrompre ou de suspendre, tout ou partie des fonctionnalités des sites et/ou de leur publication, à tout moment et sans préavis.</p>
        <h3 className="bold">IV. Cookies </h3>
        <p>Dans le cadre de l’utilisation des sites, des informations relatives à la navigation de l’utilisateur sont susceptibles d’être enregistrées dans des fichiers textes appelés « Cookies » installés sur le terminal de l’utilisateur.</p>
        <h4 className="bold">1. Généralités sur les cookies</h4>
        <p>Un cookie est un petit fichier texte, souvent crypté, stocké dans le navigateur internet. Lors de la visite d’un site Web, ce dernier envoie des informations au navigateur qui crée alors un fichier texte sur le terminal de réception (PC, téléphone, tablette, ou tout autre appareil), et qui contient des informations de visite sur ledit site. Les cookies sont nécessaires pour faciliter la navigation et la rendre plus conviviale, et ils n'endommagent pas l’ordinateur.</p>
        <h4 className="bold">2. Cookies utilisés par le site web WEFOR</h4>
        <p>Les cookies utilisés par <a href="https://wefor.fr/">www.wefor.fr</a>  permettent de personnaliser le contenu du site afin d’optimiser l’expérience du visiteur ou du client. Ils permettent également d’offrir des fonctionnalités relatives aux médias sociaux et d'analyser le trafic. Des informations sur l'utilisation du site WEFOR sont partagées avec des partenaires de médias sociaux, de publicité et d'analyse, qui peuvent combiner celles-ci avec d'autres informations qui leur ont été fournies ou qu'ils ont collectées lors de l’utilisation de leurs services.</p>
        <p>Plusieurs types de cookies peuvent être installés :</p>
        <p>(i) Les cookies techniques de navigation</p>
        <p>Les cookies techniques sont ceux utilisés tout au long de la navigation qui sont soit strictement nécessaires à la fourniture du site, soit permettant de faciliter et d’exécuter certaines fonctions sur le site. Un cookie technique permet par exemple d’enregistrer le type et la version de votre navigateur, les dates et heures de visite, les identifiants de connexion, l’historique et le contenu des commandes y compris en cas d’achat non finalisé. Ainsi, lors de ses prochaines connexions, il n’est plus nécessaire de saisir certaines informations une nouvelle fois.</p>
        <p>(ii) Les cookies de mesure d’audience</p>
        <p>Les cookies de mesure d’audience permettent d’établir des statistiques de fréquentation, évaluer l’ergonomie du site mais également de détecter des problèmes de navigation dans le site ou encore d’organiser certains contenus.</p>
        <p>(iii) Les cookies publicitaires</p>
        <p>Les cookies publicitaires permettent de reconnaitre un utilisateur qui revient sur le site ou consulte un autre site internet. Grâce à ces cookies, il est possible d’effectuer le suivi des visiteurs au travers du site Web. Le but est d'afficher des publicités qui sont pertinentes et intéressantes.</p>
        <p>Des tiers (notamment des régies publicitaires, des réseaux sociaux, des prestataires de services tels que des services de retargeting ou encore d’analyse comportementale) peuvent également utiliser des cookies sur lesquels il n’est pas possible d’avoir le contrôle. Ces cookies peuvent avoir pour finalité d’analyser les performances ou de permettre un ciblage adapté des publicités qui s’affichent sur le navigateur internet.</p>
        <p>Pour bénéficier de l’ensemble des fonctionnalités du site, il est préférable que l’utilisateur accepte tous les cookies. Toutefois, l'utilisateur peut s'opposer à l'enregistrement de ces cookies en paramétrant directement son navigateur Interne
        La durée de conservation de ces informations n’excède pas un an.</p>

        <h3 className="bold">V. Propriété intellectuelle</h3>
        <p>Toute la structure générale, ainsi que les informations au sens large, les logiciels, textes, images animées ou fixes, sons, savoir-faire, dessins, graphismes, marques, logos, noms de domaine, et tout autre élément composant ces sites sont la propriété exclusive du Groupe WEFOR, sous réserve des droits de tiers.</p>
        <p>L’utilisateur ne peut en aucun cas utiliser, distribuer, copier, reproduire, modifier, dénaturer et/ou transmettre le site ou des éléments d’un des sites tels que, sans que cette liste soit limitative, les textes, images, marques et logos, sans l'autorisation écrite et préalable du Groupe WEFOR. </p>
        <p>Le non-respect de cette interdiction peut constituer une contrefaçon des droits de propriété intellectuelle et peut à ce titre engager la responsabilité de l’utilisateur du site, y compris dans le cadre d'une action pénale.</p>

        <h3 className="bold">VI. Protection des données personnelles</h3>
        <p>En application des dispositions légales et réglementaires en vigueur relatives à la protection des données à caractère personnel, le Groupe WEFOR est responsable des données personnelles récoltées en sa qualité de sous-traitant vis-à-vis du responsable du traitement (donneur d’ordre) et est responsable de traitement vis-à-vis de ses propres consultants et sous-traitants.</p>
        <p>L’accès à l’une des applications du Groupe WEFOR peut requérir la fourniture de données personnelles, tel que les formulaires d’enregistrement. La personne concernée peut choisir de ne pas soumettre les données personnelles demandées, mais cela pourra limiter l’accès à ladite application.</p>
        <p>Les traitements des données personnelles effectués par le Groupe WEFOR s’appuient sur les principes fondamentaux suivants.</p>

        <h4 className="bold">1. Les données personnelles collectées</h4>
        <p>Les données sont collectées pour des finalités déterminées, explicites et légitimes et peuvent concernées le co-contractant en charge de l’achat de la prestation de formation professionnelle pour son besoin propre ou au profit de ses salariés apprenants et lesdits salariées apprenants.</p>
        <p>Ces données peuvent être fournies directement par les personnes morales en charge de l’achat de la prestation de formation professionnelle, la personne concernée bénéficiaire de la prestation de formation, peuvent être fournies par des sources tierces et/ou être fournies également par le biais de l’usage que la personne concernée fait des applications mises à disposition. </p>

        <h4 className="bold">2. Catégories de données traitées</h4>
        <p>Données d’identification des utilisateurs (nom, prénom, identifiant, mot de passe et adresse électronique).</p>
        <p>Données d’utilisation du service (adresse IP ; navigateur utilisé ; provenance ; résolution de l’écran ; système d’exploitation ; dates, horaires et temps de connexion sur les sites ; données d’authentification collectées au moyen d’un cookie de session ; dates, horaires, temps de suivi et progression relatifs aux différents modules (vidéo, unité, évaluation).</p>
        <p>Données nécessaires à la délivrance de l’attestation de suivi (identité de l’utilisateur, date de l’attestation, taux de réussite).</p>

        <h4 className="bold">3. Finalité</h4>
        <p>La collecte des données peut s’avérer nécessaire pour les besoins et la fourniture de la prestation de formation attendue.</p>
        <p>La collecte des données personnelles est réalisée principalement pour les besoins de l’enregistrement et le traitement de la prestation de formation, à savoir :</p>
        <p>• Fournir, activer et gérer les accès aux applications mises à disposition et l’utilisation de celles-ci ;</p>
        <p>• Assurer les inscriptions et l’authentification, l’envoi des confirmations d’inscription et des documents relatifs à la formation professionnelle, l’élaboration des listes d’émargement, des attestations de présence et de formation, l’émargement des apprenants pour les formations en présentiel ;</p>
        <p>• La mise en relation de l’apprenant avec des recruteurs éventuels par le biais d’offres d’emplois directement accessibles sur le site <a href="https://wefor.fr/">www.wefor.fr</a>, auquel cas les informations de type curriculum vitae, lettre de motivation, profil du candidat, test de personnalité du candidat seront transmis à des recruteurs éventuels pour les candidats en ayant fait la demande par le biais du site ;</p>
        <p>• Fournir un support technique, un service ou produit complémentaire et permettre ainsi le bon fonctionnement des applications mises à disposition ;</p>
        <p>• Traiter et répondre à une demande, une commande, un téléchargement, une souscription à un abonnement ou à tout autre transaction ;</p>
        <p>• Offrir un contenu personnalisé et d’autres personnalisations pour rendre les applications mises à disposition plus pertinentes ;</p>
        <p>• Répondre aux éventuelles interrogations soulevées par les personnes concernées ;</p>
        <p>• Notifier les modifications, mises à jour et autres annonces relatives aux applications mises à disposition ;</p>
        <p>• Fournir des publicités ciblées, des messages promotionnels, des notifications et autres informations relatives aux prestations de formation ;</p>
        <p>• Fournir des messages promotionnels et autres informations sur les produits du Groupe WEFOR ;</p>
        <p>• Inviter à participer à des enquêtes ;</p>
        <p>• Identifier les tendances d’utilisation et développer l’analyse de données, y compris pour la recherche, l’audit, le reporting et d’autres opérations commerciales, qui comprennent la détermination de l’efficacité des campagnes promotionnelles du Groupe WEFOR et l’évaluation de la performance commerciale ; et/ou</p>
        <p>• Pour se conformer aux obligations légales, résoudre d’éventuels litiges et faire respecter les engagements contractuels.</p>
        <p>• Pour prévenir la fraude, permettre aux co-contractant du Groupe WEFOR de se conformer à leurs obligations légales ;</p>
        <p>• Permettre la navigation des utilisateurs sur les sites et applications mis à disposition.</p>

        <h4 className="bold">4. Durée de conservation</h4>
        <p>Les données personnelles collectées et traitées par le Groupe WEFOR sont conservées pendant la durée nécessaire à l’organisation et à la dispense de la prestation de formation et des services associés dont le retour potentiel à l’emploi en mettant en relation des candidats avec des entreprises. </p>
        <p>Ces données sont ensuite conservées pour répondre à un intérêt administratif, notamment en cas de contentieux, justifiant de les conserver le temps des règles de prescription/forclusion applicables, notamment en matière commerciale, civile et fiscale.</p>
        <p>Les données collectées à des fins de prospection commerciale sont conservées pendant une durée maximale de trois (3) ans à compter de la fin de la relation commerciale. </p>
        <p>Les données collectées pour naviguer sur le site sont conservées pendant une durée maximale d’un (1) an.</p>

        <h4 className="bold">5. Traitement des données personnelles par un tiers</h4>
        <p>Les données personnelles, récoltées dans le cadre des finalités ci-avant exposé, peuvent être divulguées à un tiers dans les cas suivants :</p>
        <p>• Pour la réalisation de la formation professionnelle, en communiquant par exemple les données aux consultants en charge de la formation;</p>
        <p>• Afin de permettre à une entreprise qui recrute de pouvoir contacter les candidats ayant postulés aux offres d’emploi ;</p>
        <p>• Pour se conformer à toute loi en vigueur, réglementation, procédure juridique ou autre obligation légale ;</p>
        <p>• Pour détecter, enquêter et permettre de prévenir des problèmes de sécurité, de fraude ou des problèmes techniques ;</p>
        <p>• Pour protéger les droits, la propriété ou la sécurité du Groupe WEFOR, ses utilisateurs, employés ou autres ; et</p>
        <p>• Pour réaliser une transaction générale, telle qu’un transfert d’avoirs ou une acquisition par le biais d’une fusion avec une autre société.</p>

        <h4 className="bold">6. Contact </h4>
        <p>Toute personne concernée qui justifie de son identité peut demander au DPO : dpo@wefor.fr l’accès à ses données personnelles, la rectification, l’effacement de celles-ci, limiter le traitement, s’opposer au traitement et/ou demander la portabilité de ses données personnelles.  </p>
        <p>Toute personne concernée a également le droit d’introduire une réclamation auprès d’une autorité de contrôle.</p>
      </section>
      <Footer />
    </LegalStyled>
  )
}

Legal.propTypes = {
}

export default Legal