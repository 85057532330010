/**** import des modules ****/
import React, { useEffect, useState, useRef } from 'react'
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import randomString from '../../../assets/js/randomString';

/**** import des images ****/
import society from '../../../assets/img/illustration/EMPLOYEUR-opti.png'

/**** import des css ****/
import SocietyStyled from './SocietyStyled'

const Society = ({  
  changeValueSignin, 
  changeIsLogged,
  JobSectors,
  accountJobSector,
  changeFormModal,
  closeModal,
  formModal,
  openModal,
  modalName,
  fetchPostUserEntreprise,
  accountSecteurSignin,
  fetchSiren,
  sirenData,
  accountEntrepriseSignin,
  accountsirenSignin,
  accountAdresseSignin,
  accountPasswordSignin,
  accountConfirmationPasswordSignin,
  adresseList,
  fetchSearchAdresse,
  accountAdresseNumStreetSignin,
  accountAdresseStreetSignin,
  accountAdresseCodeSignin,
  accountAdresseCitySignin,
  changeValue,
  fetchSecteurs
}) => {
  let navigate = useNavigate();
  const [modalSearchAdresse, setModalSearchAdresse] = useState(false);
  const [generatedPw, setGeneratedPw] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const toggleShowEye = () => {
    setShowPassword(!showPassword)
  }
  const toggleShowEyeC = () => {
    setShowPasswordC(!showPasswordC)
  }

  useEffect(() => {
    if (JobSectors.length === 0) {
      fetchSecteurs()
    }
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setModalSearchAdresse(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const isValidSpe = (str) => {
    return /[~`!#$%\^&*+=\-\[\]\\';,./{}|\\":<>\?@€]/g.test(str);
  }

  const isValidMaj = (str) => {
    return /(?=.*[A-Z])/.test(str);
  }

  const isValidChi = (str) => {
    return /^(?=.*[0-9])/.test(str);
  }

  const isValidNum = (str) => {
    return /^.{8,10000}$/.test(str);
  }
  useEffect(() => {
    if (sirenData !== undefined && sirenData.results.length > 0) {
    changeValueSignin(sirenData.results[0].nom_complet , 'accountEntrepriseSignin')
    changeValueSignin(`${sirenData.results[0].siege.adresse_complete}`, 'accountAdresseSignin')
    changeValueSignin(`${sirenData.results[0].siege.numero_voie}`, 'accountAdresseNumStreetSignin')
    changeValueSignin(`${sirenData.results[0].siege.type_voie} ${sirenData.results[0].siege.libelle_voie}`, 'accountAdresseStreetSignin')
    changeValueSignin(`${sirenData.results[0].siege.code_postal}`, 'accountAdresseCodeSignin')
    changeValueSignin(`${sirenData.results[0].siege.libelle_commune}`, 'accountAdresseCitySignin')
  }
}, [sirenData]);

const setNewPw = () => {
  const newPw = randomString(20)
  setGeneratedPw(newPw)
  changeValueSignin(newPw, 'accountPasswordSignin')
  changeValueSignin(newPw, 'accountConfirmationPasswordSignin')
}

  return (
    <SocietyStyled>
      <h2 className="bold">Vos informations d'entreprise<span className="separator-blue"></span></h2>
      {formModal === 'signIn' && 
        <p className="connexion-txt">Vous avez déjà un compte ? 
          <span
            className="login-span"
            onClick={() => {
              changeFormModal('login')
            }}
          > Connectez vous</span>
        </p>
      }
      {formModal !== 'signIn' &&
        <p className="connexion-txt">Vous avez déjà un compte ? <Link to="/login">Connectez vous</Link></p>
      }
      <form 
          className="account-form-comp"
          onSubmit={(event) => {
            event.preventDefault();
            if (accountPasswordSignin === accountConfirmationPasswordSignin && isValidChi(accountPasswordSignin) && isValidMaj(accountPasswordSignin) && isValidNum(accountPasswordSignin) && isValidSpe(accountPasswordSignin)) {
              fetchPostUserEntreprise();
            } else {
              toast(`Toutes les conditions du mot de passe doivent correspondre`, {
                position: "bottom-right",
                type: "error",
                theme:"colored",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
            }
            if (modalName === 'formation') {
              openModal(modalName)
            } else if (modalName === 'postuler') {
              openModal(modalName)
            } else {
              closeModal('');
            }
          }}
        >
            <label>
              Numéro de SIREN
              <input 
                type="text" 
                name="accountSirenSignin" 
                placeholder="Renseigner votre numéro de SIREN (9 chiffres) permet de remplir automatiquement certains champs."
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                  if (event.target.value.length === 9) {
                    fetchSiren(event.target.value)
                  }
                }}
              />
            </label>
          <label>
            Entreprise <span className="blue">*</span>
            <input 
              type="text" 
              name="accountEntrepriseSignin" 
              placeholder=""
              value={accountEntrepriseSignin}
              onChange={(event) => {
                changeValueSignin(event.target.value, event.target.name);
              }}
            />
          </label>
          <div className="search-adresse-wrapper" ref={wrapperRef}
          >
            <label
              onClick={() => setModalSearchAdresse(true)}>
              Adresse postale <span className="blue">*</span>
              <input 
                type="text" 
                name="accountAdresseSignin" 
                placeholder=""
                value={accountAdresseSignin}
                required
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);

                  fetchSearchAdresse(event.target.value);
                }}
              />
            </label>
            {modalSearchAdresse &&
              <div className="result-adresse-wrapper"
              onClick={() => setModalSearchAdresse(false)}>
                <ul className="adresse-list">
                  {adresseList.length > 0 && adresseList.map((adresse) => (
                    <li 
                      key={adresse.properties.score} 
                      onClick={() => {
                        changeValueSignin(adresse.properties.label, 'accountAdresseSignin')
                        changeValueSignin(adresse.properties.housenumber, 'accountAdresseNumStreetSignin')
                        changeValueSignin(adresse.properties.street, 'accountAdresseStreetSignin')
                        changeValueSignin(adresse.properties.postcode, 'accountAdresseCodeSignin')
                        changeValueSignin(adresse.properties.city, 'accountAdresseCitySignin')
                        setModalSearchAdresse(false)
                      }}
                    >
                      {adresse.properties.label}
                    </li>
                  ))}
                </ul>
              </div>
             }
          </div>
          <div className="name-wrapper">
          <label className="invisible-front">
              Numéro de rue
              <input 
                type="text" 
                value={accountAdresseNumStreetSignin}
                name="accountAdresseNumStreetSignin" 
                placeholder=""

                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <label className="invisible-front">
              Nom de la rue
              <input 
                type="text" 
                value={accountAdresseStreetSignin}
                name="accountAdresseStreetSignin" 
                placeholder=""
 
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="name-wrapper">
          <label className="invisible-front">
              Code postale
              <input 
                type="text" 
                value={accountAdresseCodeSignin}
                name="accountAdresseCodeSignin" 
                placeholder=""

                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <label className="invisible-front">
              Ville
              <input 
                type="text" 
                value={accountAdresseCitySignin}
                name="accountAdresseCitySignin" 
                placeholder=""

                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="contact-wrapper">
          <label>
            Email <span className="blue">*</span>
              <input 
                type="email" 
                name="accountEmailSignin" 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                  changeValue(event.target.value, "email")
                }}
              />
            </label>
          </div>
          <h3 className="bold">Contact de l'entreprise</h3>
          <div className="name-wrapper">
          <label>
              Nom 
              <input 
                type="text" 
                name="accountLastNameSignin" 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Fonction
              <input 
                type="text" 
                name="accountFonctionSignin" 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="contact-wrapper align-left">
          <label>
              Prénom
              <input 
                type="text" 
                name="accountFirstNameSignin" 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
          <label>
            Téléphone <span className="blue">*</span>
              <input 
                type="tel" 
                name="accountTelSignin" 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <img src={society} alt="affiche avec une loupe et profiles particuliers rouge" />
          </div>
          <h3 className="bold">Secteur(s) d’activité de votre entreprise <span className="blue">*</span></h3>
          <div className="secteur-wrapper">
          <label className="select-box">
              <select
                name="accountSecteurSignin" 
                value={accountSecteurSignin}
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              >
                {JobSectors.map((secteur) => (
                  <option key={secteur.id} value={secteur.attributes.nom}>
                    {secteur.attributes.nom}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <h3 className="bold">Choisissez vos informations de connexion <span className="blue">*</span></h3>
          <div className="generator-pw-wrapper">
            <div className='btn-generator bold' onClick={() => {
              setNewPw()
             }}>Générer un mot de passe complexe</div>
             {generatedPw !== '' && 
              <div className="pw-wrapper bold">
                <p className="bold">{generatedPw}</p>
                <p>Gardez ce mot de passe dans un dossier sécurisé</p>
              </div>
              }
          </div>
          <div className="mdp-wrapper">
          <label className='password-item'>
              Mot de passe
              <div className='eye-wrapper'>
                {showPassword === true ? 
                  <FaEye onClick={toggleShowEye}/>
                :
                  <FaEyeSlash onClick={toggleShowEye}/>
                }
              </div>
              
              <input 
                type={showPassword ? "text" : "password"}
                name="accountPasswordSignin" 
                value={accountPasswordSignin}
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                  changeValue(event.target.value, "password")
                }}
              />
            </label>
            <label className='password-item'>
              Confirmation du mot de passe
              <div className='eye-wrapper'>
                {showPasswordC === true ? 
                  <FaEye onClick={toggleShowEyeC}/>
                :
                  <FaEyeSlash onClick={toggleShowEyeC}/>
                }
              </div>
              <input 
                type={showPasswordC ? "text" : "password"}
                name="accountConfirmationPasswordSignin"
                value={accountConfirmationPasswordSignin} 
                placeholder=""
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                }}
              />
            </label>
            <div className="mdp-cond-wrapper">
              <p>Votre mot de passe doit contenir:</p>
              <ul>
                <li>
                {isValidNum(accountPasswordSignin) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> 8 caractère au minimun</span>
                </li>
                <li>
                {isValidChi(accountPasswordSignin) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> Un chiffre au minimun</span>
                </li>
                <li>
                {isValidMaj(accountPasswordSignin) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> Une majuscule au minimun</span>
                </li>
                <li>
                {isValidSpe(accountPasswordSignin) ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>} 
                <span> Un caractère spéciale au minimun</span>
                </li>
                <li>
                 {accountPasswordSignin === accountConfirmationPasswordSignin ? <AiFillCheckCircle className="check-picto"/> : <AiFillCloseCircle className="close-picto"/>}
                 <span> Les mots de passe doivent correspondre</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="confirmation-wrapper">
          <label className="newsletter">
            <input 
              type="radio" 
              name="accountMl" 
              value="Yes"
              placeholder=""
              required
              onChange={(event) => {
                changeValueSignin(event.target.value, event.target.name);
              }}
            />
             <div>En cochant cette case, je déclare avoir lu et accepter les <Link to="/legal">Mentions Légales</Link> et les <Link to="/conditions-generales-de-vente"> Conditions Générales de Vente.</Link></div>
          </label>
          <input 
            type="submit" 
            value="Créer un compte" 
            className="lightblue-btn"
            onClick={() => {
              window.dataLayer.push({'event': 'lead'})
            }} 
          />
          </div>
        </form>
    </SocietyStyled>
  )
}

Society.propTypes = {
  changeValueSignin: PropTypes.func.isRequired,
  changeIsLogged: PropTypes.func.isRequired,
  changeFormModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  formModal: PropTypes.string.isRequired,
}

export default Society