import { connect } from 'react-redux'

import AccountFinancement from '../../pages/AccountFinancement'

import { fetchFactureE, fetchFactureP, changeClickedFacture, fetchPutModeFinancement, changeStepValue, fetchUploadEdofFile, fetchStatutFormation, redirectToMyFinancement, fetchPutCpfToZero, fetchPutOpcoToZero, fetchFactureToShow } from '../../actions/course'

import { fetchInscriptionUserLms, fetchCreateSessionLms, fetchUserScore } from '../../actions/lms'

import { 
    toggleModalMessage
} from '../../actions/modal'

import { 
    setFullPageLoaderFacturation
} from '../../actions/actions'

const mapStateToProps = (state) => ({
	userRole: state.login.userRole,
    userInfo: state.login.userInfo,
    isLogged: state.login.isLogged,
    factureE: state.course.factureE,
    factureP: state.course.factureP,
    userStatut: (state.login.isLogged ? state.login.userInfo.data[0].attributes.statut : ''),
    clickedFacture: state.course.clickedFacture,
    menuDocument: state.menu.menuDocument,
    modalMessageContent: state.modal.modalMessageContent,
    modalMessageIsOpen: state.modal.modalMessageIsOpen,
    facturePStatut: state.course.facturePStatut,
    factureEStatut: state.course.factureEStatut,
    factureToShow: state.course.factureToShow
})

const mapDispatchToProps = (dispatch) => ({
    fetchFactureE: () => {
        dispatch(fetchFactureE());
    },
    fetchFactureP: () => {
        dispatch(fetchFactureP());
    },
    changeClickedFacture: (factureId) => {
        dispatch(changeClickedFacture(factureId));
    },
    fetchPutModeFinancement: (factureId, statut) => {
        dispatch(fetchPutModeFinancement(factureId, statut))
    },
    changeStepValue: (number) => {
        dispatch(changeStepValue(number));
    },
    fetchUploadEdofFile: (file, id, mode) => {
        dispatch(fetchUploadEdofFile(file, id, mode));
    },
    fetchInscriptionUserLms: () => {
        dispatch(fetchInscriptionUserLms());
    },
    fetchCreateSessionLms: (duree, templateId) => {
        dispatch(fetchCreateSessionLms(duree, templateId));
    },
    fetchStatutFormation: (courseId) => {
        dispatch(fetchStatutFormation(courseId));
    },
    redirectToMyFinancement: (bool) => {
        dispatch(redirectToMyFinancement(bool));
    },
    fetchPutCpfToZero: (factureId) => {
        dispatch(fetchPutCpfToZero(factureId));
    },
    fetchPutOpcoToZero: (factureId) => {
        dispatch(fetchPutOpcoToZero(factureId));
    },
    toggleModalMessage: (message, bool) => {
        dispatch(toggleModalMessage(message, bool));
    },
    fetchUserScore: () => {
        dispatch(fetchUserScore());
    },
    setFullPageLoaderFacturation: (bool) => {
        dispatch(setFullPageLoaderFacturation(bool));
    },
    fetchFactureToShow: (id) => {
        dispatch(fetchFactureToShow(id));
    }
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountFinancement)