/**** Import des modules ****/
import React, {useEffect, useRef, useState} from 'react'
import { useNavigate, Navigate} from 'react-router-dom'
import PropTypes from 'prop-types'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { MdDownload } from "react-icons/md" 
import { saveAs } from 'file-saver'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import BannerAccount from '../../components/Header/BannerAccount'
import AccountMenu from '../../containers/AccountMenu'

/**** Import des images ****/
import menaccount from '../../assets/img/illustration/menaccount.png'
import society from '../../assets/img/illustration/EMPLOYEUR-opti.png'


/**** Import des css ****/
import AccountProfilStyled from './AccountProfilStyled'
import urlBackEndSave from '../../assets/js/urlBackEndSave'

const AccountProfil = ({
  changeAccountValue, 
  days, 
  mounths, 
  accountBdMounth, 
  accountBdDay,
  currentProfilUser,
  accountJobSector,
  accountSecteur,
  JobSectors,
  user,
  userInfo,
  userRole,
  isLogged,
  fetchPutAccountP,
  fetchPutAccountE,
  accountAdresseNumStreet,
  accountAdresseStreet,
  accountAdresseCode,
  accountAdresseCity,
  accountStatut,
  fetchSearchAdresse,
  adresseList,
  accountAdresseSignin,
  changeValueSignin,
  fetchCvParticulier,
  setFullPageLoaderPutAccount
}) => {
  let navigate = useNavigate();
  const [modalSearchAdresse, setModalSearchAdresse] = useState(false);
  const [files, setFiles] = useState(false)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setModalSearchAdresse(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  if(!isLogged) {
    return (<Navigate to="/" replace />)
  }

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  return (
    <AccountProfilStyled>
      <Header bcgColor={'#fff'} />
      {isLogged &&
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
        
        { userRole === 'particulier' && 
        <>
          {userInfo.data[0].attributes.cv && userInfo.data[0].attributes.cv.data ? 
          <div className="cv-title">Votre CV actuel : {userInfo.data[0].attributes.cv.data.attributes.name} - <span className="dl-link" onClick={()=>{savePdf(userInfo.data[0].attributes.cv.data.attributes.url ,userInfo.data[0].attributes.cv.data.attributes.name)}}>Télécharger</span></div>
          :
          <div className="cv-title">Vous n'avez pas encore déposé de CV</div>
          }
          <form 
          onSubmit={(e) => {
          e.preventDefault();
          }}
          className="drop-file-wrapper"
          >
        <label htmlFor="file-upload" className="file-upload-btn">
        { /*factureItem.attributes.pole_emploi_edof.data.attributes.preuve_financement.data === null ?
        <p></p>
        :
        <p>Votre CV: {factureItem.attributes.pole_emploi_edof.data.attributes.preuve_financement.data.attributes.name}</p>
        */}
        <div>
          <MdDownload /> Déposer un CV
          <input 
            id="file-upload"
            type="file" 
            name="upload" 
            accept="application/pdf,application/vnd.ms-excel" 
            onChange={(e)=> { 
              setFiles(e.target.files)
              fetchCvParticulier(e.target.files, userInfo.data[0].id)
            }}
            style={{
              backgroundColor: "#fff !important"
            }}
          />
        </div>
        </label>
        </form>
        <form 
          className="account-form-comp"
          onSubmit={(event) => {
            event.preventDefault();
            fetchPutAccountP();
          }}
        >
          <label>Votre statut</label>
          <ToggleButtonGroup
            value={accountStatut}
            exclusive
            name="accountStatut"
            onChange={(event) => {
              changeAccountValue(event.target.value, 'accountStatut');
            }}
            aria-label="text alignment"
            className='toggle-button-statut'
          >
            <ToggleButton value="Salarié" aria-label="left aligned">
              salarié
            </ToggleButton>
            <ToggleButton value="Demandeur d'emploi" aria-label="right aligned">
              Demandeur d'emploi
            </ToggleButton>
          </ToggleButtonGroup>
          <div className="name-wrapper">
          <label>
              Prénom
              <input 
                type="text" 
                name="accountFirstName" 
                placeholder={user.prenom}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Nom
              <input 
                type="text" 
                name="accountLastName" 
                placeholder={user.nom}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="mdp-wrapper">
          <label>
            Adresse Email
            <input 
              type="email" 
              name="accountEmail" 
              placeholder={user.email}
              onChange={(event) => {
                changeAccountValue(event.target.value, event.target.name);
              }}
            />
          </label>
          <label>
            Numéro de téléphone
            <input 
              type="tel" 
              name="accountTel" 
              placeholder={user.telephone}
              onChange={(event) => {
                changeAccountValue(event.target.value, event.target.name);
              }}
            />
          </label>
          </div>
          {/**
          <div className="mdp-wrapper">  
          <label>
              Mot de passe
              <input 
                type="password" 
                name="accountPassword" 
                placeholder=""
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Confirmation du mot de passe
              <input 
                type="password" 
                name="accountConfirmationPassword" 
                placeholder=""
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          **/}
          <div className="search-adresse-wrapper" ref={wrapperRef}>
            <label  onClick={() => setModalSearchAdresse(true)}>
              Adresse postale
              <input 
                type="text" 
                name="accountAdresseSignin" 
                placeholder={userInfo.data[0].attributes.adresse_postale}
                value={accountAdresseSignin}
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                  fetchSearchAdresse(event.target.value);
                }}
              />
            </label>
            {modalSearchAdresse &&
              <div className="result-adresse-wrapper"
              onClick={() => setModalSearchAdresse(false)}
              >
                <ul className="adresse-list">
                  {adresseList.length > 0 && adresseList.map((adresse) => (
                    <li 
                      key={adresse.properties.score} 
                      onClick={() => {
                        changeValueSignin(adresse.properties.label, 'accountAdresseSignin')
                        changeAccountValue(adresse.properties.housenumber, 'accountAdresseNumStreet')
                        changeAccountValue(adresse.properties.street, 'accountAdresseStreet')
                        changeAccountValue(adresse.properties.postcode, 'accountAdresseCode')
                        changeAccountValue(adresse.properties.city, 'accountAdresseCity')
                        setModalSearchAdresse(false)
                      }}
                    >
                      {adresse.properties.label}
                    </li>
                  ))}
                </ul>
              </div>
             }
          </div>
          <div className="name-wrapper">
          <label className="invisible-front">
              Numéro de rue
              <input 
                type="text" 
                value={accountAdresseNumStreet}
                name="accountAdresseNumStreet" 
                placeholder={userInfo.data[0].attributes.numero_voie ? userInfo.data[0].attributes.numero_voie : ''}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label className="invisible-front">
              Nom de la rue
              <input 
                type="text" 
                value={accountAdresseStreet}
                name="accountAdresseStreet" 
                placeholder={userInfo.data[0].attributes.nom_voie ? userInfo.data[0].attributes.nom_voie : ''}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="name-wrapper">
          <label className="invisible-front">
              Code postale
              <input 
                type="text" 
                value={accountAdresseCode}
                name="accountAdresseCode" 
                placeholder={userInfo.data[0].attributes.code_postal ? userInfo.data[0].attributes.code_postal : ''}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label className="invisible-front">
              Ville
              <input 
                type="text" 
                value={accountAdresseCity}
                name="accountAdresseCity" 
                placeholder={userInfo.data[0].attributes.ville ? userInfo.data[0].attributes.ville : ''}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <input 
            type="submit" 
            value="Mettre à jour" 
            className="lightblue-btn bold"
          />
        </form>
        </>
        }
        {userRole === 'entreprise' && 
          <form 
          className="account-form-comp-ent"
          onSubmit={(event) => {
            event.preventDefault();
            
            fetchPutAccountE();
          }}
        >
          <label>
            Entreprise <span className="blue">*</span>
            <input 
              type="text" 
              name="accountEntreprise" 
              placeholder={userInfo.data[0].attributes.Nom_entreprise}
              onChange={(event) => {
                changeAccountValue(event.target.value, event.target.name);
              }}
            />
          </label>
          <div className="contact-wrapper">
          <label>
            Email <span className="blue">*</span>
              <input 
                type="email" 
                name="accountEmailSignin" 
                placeholder={user.email}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Numéro de SIREN
              <input 
                type="text" 
                name="accountSiren" 
                placeholder={userInfo.data[0].attributes.siren}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="search-adresse-wrapper" ref={wrapperRef}>
            <label  onClick={() => setModalSearchAdresse(true)}>
              Adresse postale
              <input 
                type="text" 
                name="accountAdresseSignin" 
                placeholder={userInfo.data[0].attributes.adresse}
                value={accountAdresseSignin}
                onChange={(event) => {
                  changeValueSignin(event.target.value, event.target.name);
                  fetchSearchAdresse(event.target.value);
                }}
              />
            </label>
            {modalSearchAdresse &&
              <div className="result-adresse-wrapper"
              onClick={() => setModalSearchAdresse(false)}
              >
                <ul className="adresse-list">
                  {adresseList.length > 0 && adresseList.map((adresse) => (
                    <li 
                      key={adresse.properties.score} 
                      onClick={() => {
                        changeValueSignin(adresse.properties.label, 'accountAdresseSignin')
                        changeAccountValue(adresse.properties.housenumber, 'accountAdresseNumStreet')
                        changeAccountValue(adresse.properties.street, 'accountAdresseStreet')
                        changeAccountValue(adresse.properties.postcode, 'accountAdresseCode')
                        changeAccountValue(adresse.properties.city, 'accountAdresseCity')
                        setModalSearchAdresse(false)
                      }}
                    >
                      {adresse.properties.label}
                    </li>
                  ))}
                </ul>
              </div>
             }
          </div>
          <div className="name-wrapper">
          <label className="invisible-front">
              Numéro de rue
              <input 
                type="text" 
                value={accountAdresseNumStreet}
                name="accountAdresseNumStreet" 
                placeholder={userInfo.data[0].attributes.numero_voie ? userInfo.data[0].attributes.numero_voie : ''}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label className="invisible-front">
              Nom de la rue
              <input 
                type="text" 
                value={accountAdresseStreet}
                name="accountAdresseStreet" 
                placeholder={userInfo.data[0].attributes.nom_voie ? userInfo.data[0].attributes.nom_voie : ''}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="name-wrapper">
          <label className="invisible-front">
              Code postale
              <input 
                type="text" 
                value={accountAdresseCode}
                name="accountAdresseCode" 
                placeholder={userInfo.data[0].attributes.code_postal ? userInfo.data[0].attributes.code_postal : ''}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label className="invisible-front">
              Ville
              <input 
                type="text" 
                value={accountAdresseCity}
                name="accountAdresseCity" 
                placeholder={userInfo.data[0].attributes.ville ? userInfo.data[0].attributes.ville : ''}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <h3 className="bold">Contact de l'entreprise</h3>
          <div className="name-wrapper">
          <label>
              Nom
              <input 
                type="text" 
                name="accountLastName" 
                placeholder={user.nom}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Fonction
              <input 
                type="text" 
                name="accountFonction" 
                placeholder={userInfo.data[0].attributes.fonction_dans_entreprise}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="contact-wrapper align-left">
            <label>
              Prénom
              <input 
                type="text" 
                name="accountFirstName" 
                placeholder={user.prenom}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          <label>
            Téléphone <span className="blue">*</span>
              <input 
                type="tel" 
                name="accountTel" 
                placeholder={user.telephone}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <img src={society} alt="affiche avec une loupe et profiles particuliers rouge" className="img-none-mobile"/>
          </div>
          <h3 className="bold">Secteur(s) d’activité de votre entreprise <span className="blue">*</span></h3>
          <div className="secteur-wrapper">
          <label className="select-box">
              <select
                name="accountSecteur" 
                value={accountSecteur !== undefined ? accountSecteur : ''}
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              >
                {JobSectors.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </label>
          </div>
          {/*** 
          <h3 className="bold">Choisissez vos informations de connexion <span className="blue">*</span></h3>
          <div className="mdp-wrapper">
          <label>
              Mot de passe
              <input 
                type="password" 
                name="accountPassword" 
                placeholder=""
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Confirmation du mot de passe
              <input 
                type="password" 
                name="accountConfirmationPassword" 
                placeholder=""
                onChange={(event) => {
                  changeAccountValue(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          ***/}
          <div className="confirmation-wrapper">
          <div className="btn-wrapper">
          <input 
            type="submit" 
            value="Modifier mon compte" 
            className="lightblue-btn bold"
          />
          </div>
          </div>
        </form>
        }
      </section>
      </main>
    }
    </AccountProfilStyled>
  )

}

AccountProfil.propTypes = {
  changeAccountValue: PropTypes.func.isRequired,
  days: PropTypes.array.isRequired,
  mounths: PropTypes.array.isRequired,
  accountBdDay: PropTypes.string.isRequired,
  accountBdMounth: PropTypes.string.isRequired,
}

export default AccountProfil