import { connect } from 'react-redux'

import Job from '../../pages/Job'
import { openModal } from '../../actions/modal'
import { setJobFicheId, fetchJobs, fetchGetDirection, setFullPageLoaderDistance, toggleModalPostulerTuto, setJobIdModalPostulerTuto, setCvPosted, setDidTest, setJobForModalPostuler, fetchJobsInfos } from '../../actions/job'
import { setFilter, setFilterId, fetchSecteurs } from '../../actions/menu'
import { changeActivCourseId } from '../../actions/course'

const mapStateToProps = (state) => ({
  jobs: state.job.jobs,
  activJobId: state.job.activJobId,
  loadJobs: state.job.loadJobs,
  secteurs: state.menu.secteursData,
  domaines: state.menu.domainesData,
  secteurJobFilter: state.menu.secteurJobFilter,
  domaineJobFilter: state.menu.domaineJobFilter,
  formationJobFilter: state.menu.formationJobFilter,
  kmJobFilter: state.menu.kmJobFilter,
  userRole: state.login.userRole,
  currentProfilUser: state.menu.currentProfilUser,
  isLogged: state.login.isLogged,
  jobsWithDistances: state.job.jobsWithDistances,
  userInfo: state.login.userInfo,
  fullPageLoaderDistance: state.job.fullPageLoaderDistance,
  jobsPagination: state.job.jobsPagination,
  secteursData: state.menu.secteursData,
  jobInfo: state.job.jobInfo
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => {
        dispatch(openModal(name));
    },
    setJobFicheId: (id) => {
      dispatch(setJobFicheId(id))
    },
    setFilter: (name, title) => {
      dispatch(setFilter(name, title));
    },
    setFilterId: (name, title) => {
      dispatch(setFilterId(name, title));
    },
    fetchJobs: (paramObj) => {
      dispatch(fetchJobs(paramObj));
    },
    changeActivCourseId: (id) => {
      dispatch(changeActivCourseId(id));
    },
    fetchGetDirection: (mode) => {
      dispatch(fetchGetDirection(mode))
    },
    setFullPageLoaderDistance: (bool) => {
      dispatch(setFullPageLoaderDistance(bool))
    },
    toggleModalPostulerTuto: (bool) => {
      dispatch(toggleModalPostulerTuto(bool));
    },
    setJobIdModalPostulerTuto: (id) => {
          dispatch(setJobIdModalPostulerTuto(id));
      },
    setCvPosted: (bool) => {
          dispatch(setCvPosted(bool));
      },
    setDidTest: (bool) => {
          dispatch(setDidTest(bool));
      },
    setJobForModalPostuler: (data) => {
      dispatch(setJobForModalPostuler(data));
    },
    fetchSecteurs: () => {
      dispatch(fetchSecteurs());
    },
    fetchJobsInfos: (id) => {
      dispatch(fetchJobsInfos(id))
    }
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Job)