import { connect } from 'react-redux'

import NavUser from '../../../components/Header/NavUser'

import { changeCurrentProfilUser, setIsShowMenu } from '../../../actions/menu'
import { 
    changeSigninStep,
} from '../../../actions/signin'

const mapStateToProps = (state) => ({
    currentProfilUser: state.menu.currentProfilUser,
    windowTop: state.actions.windowTop,
    isShowMenu: state.menu.isShowMenu,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged
})

const mapDispatchToProps = (dispatch) => ({
    changeCurrentProfilUser: (name) => {
        dispatch(changeCurrentProfilUser(name));
    },
    setIsShowMenu: (bool) => {
        dispatch(setIsShowMenu(bool));
    },
    changeSigninStep: (number) => {
        dispatch(changeSigninStep(number));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(NavUser)