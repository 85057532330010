import { connect } from 'react-redux'

import Legal from '../../pages/Legal'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Legal)