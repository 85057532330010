import styled from 'styled-components';
import bcgHeader from '../../assets/img/header/formation-ill.jpg'

const CourseProgStyled = styled.main`
    padding-top: 0px;


    .illustration-header {
        width: 100vw;
        height: 700px;
        padding-top: 125px;
        z-index: 10;
        position: relative;
    
        &::before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width:100%;
            background: linear-gradient(180deg, #f7edea 0%, #d47558 100%);
            clip-path: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            shape-outside: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            z-index: -10;
        }
    
    
        .opacity-light-section {
            height: 100%;
            top: 100%;
            max-width: 1300px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0px 0;
            margin: 0 auto;
    

            .left {
                width: calc(60%);

                h1 {
                    font-size: 50px;
                    text-align: center;
                }

                p {
                    font-size: 25px;
                    color: #fff;
                    line-height: 30px;
                    padding: 30px 0;
                }
            }
            img {
                    max-width: 40%;
                }
            }

            .title-wrapper-prog {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
                margin-left: 40px;

                h2 {
                    color: #fff;
                    padding: 0 0 5px;
                    font-size: 40px;
                    text-transform: uppercase;
                    text-align: center;

                    &.border {
                        padding: 0 0 20px;
                        margin: 0 0 20px;
                        border-bottom: 4px solid #6aaf8b;
                    }
                }
                h3 {
                    color: #d47558;
                    text-transform: uppercase;
                    font-size: 40px;
                    text-align: center;

                    &.top {
                        padding: 10px 0 0 0;
                    }

                    &.bottom {
                        padding: 0 0 10px 0;
                    }
                }

                h1 {
                    display: block;
                    font-size: 50px;
                    color: #fff;
                    text-transform: uppercase;
                    color: #4d4d4d;
                }
            }
        }
    }

    .section-programme-formation {
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px 0;

        h2 {
            font-size: 45px;
            width: auto;
            display: inline;
            margin: 0 auto;
            position: relative;
            color: #4d4d4d;
            padding-bottom: 10px;
            margin-bottom: 40px;
            text-transform: uppercase;

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .no-margin {
            margin-bottom: 0;
        }

        .tarif-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: stretch;
            margin-bottom: 60px;

            .tarif-formation {
                border: 2px solid #649ACE;
                padding: 10px 40px; 
                margin-right: 20px;
                font-size: 35px; 
                color: #4d4d4d;
                border-radius: 12px;
            }

            .btn-lightblue {
                border: 2px solid #639ACD;
                padding: 10px 80px; 
                font-size: 35px; 
                color: #fff;
                border-radius: 12px;
                background-color: #639ACD;
                cursor: pointer;

                &:hover {
                    color: #639ACD;
                    background-color: #fff;
                }
            }
        }

        .article-programme-description {
            color: #707070;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            margin-top: 20px;

            img {
                width: 10%;
            }

            .article-list-wrapper {
                width: 85%;

                h3 {
                    padding-bottom: 20px;
                    font-size: 22px;
                }

                p {
                    text-align: justify;
                }

                ul li {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
    }

    .section-module-formation {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;
        background: linear-gradient(180deg,rgba(248, 232, 227,0) 0%,rgba(248, 232, 227,0.51782) 48.22%,#f8e8e3 100%);

        .design-green-top {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .separator-green {
                width: 160px;
                height: 6px;
                background-color: #C6D458;
            }

            img {
                margin: 0 20px;
            }
        }

        .design-blue-bottom {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 20px;

            .separator-blue {
                width: 160px;
                height: 6px;
                background-color: #325076;
            }

            img {
                margin: 0 20px;
            }
        }

        .module-formation-list {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: center;
            margin-top: 50px;
            color: #707070;
            width: 96%;
            max-width: 1200px;

            .left-prog, .right-info {
                width: 100%;
                padding-left: 13.333333333%;
                padding-right: 1.66666666%;
                box-sizing: border-box;
                max-width: 1300px;

                h3 {
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 10px;
                    margin-left: -13.333%;
                }

                h4 {
                    padding: 20px 0 5px;
                }

                p {
                    text-align: justify;
                }

                line-height: 22px;
            }

            .separator-vertical-blue {
                width: 2px;
                background-color: #639ACD;
                margin-top: 55px;
            }
        }

        .arrow-bottom {
            font-size: 40px;
            color: #639ACD;
            margin-top: 40px;
        }

        .pct-completion {
            color: #4d4d4d;
            font-size: 30px;
            padding-top: 20px;
        }
    }

    .section-modalité-formation {
        display: flex; 
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;

        .time-prog-course {
            width: 96%;
            max-width: 800px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            img {
                width: 150px;
            }

            .time-wrapper {
                border: 1px solid #707070;
                border-radius: 27px;
                padding: 20px 30px 50px 30px;
                text-align: center;
                width: 560px;
                box-sizing: border-box;

                h3 {
                    color: #649ACE;
                    text-transform: uppercase;
                    padding-bottom: 20px;
                    font-size: 22px;
                }

                p.date {
                    color: #649ACE;
                    text-transform: uppercase;
                    padding-bottom: 30px;
                    font-size: 40px;
                }

                .txt {
                    color: #4d4d4d;
                    line-height: 20px;
                    text-align: justify;
                }
            }
        }

        .modalite-prog-course {
            width: 96%;
            max-width: 800px;
            margin: 30px auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            img {
                width: 150px;
            }

            .evaluation-wrapper {
                padding: 20px;
                text-align: center;
                width: 560px;
                box-sizing: border-box;

                h3 {
                    font-size: 22px;
                    color: #707070;
                    text-align: left;
                }

                .list-eval {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: column;
                    color: #649ACE;
                    margin-top: 50px;
                }
            }
        }

        .formateur-prog-course {
            width: 96%;
            max-width: 1200px;
            padding: 30px 15px 10px;
            display: flex;
            flex-direction: column;
            background-color: #E1EDF7;
            border-radius: 27px;
            align-items: center;
            box-sizing: border-box;

            h3 {
                font-size: 22px;
                color: #707070;
            }

            .formateur-wrapper {
                display:flex;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: 20px;

                .img-wrapper {
                    background-color: #fff;
                    width: 160px;
                    height: 160px;
                    border-radius: 80px;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 120px;
                        
                    }
                }
    
                .txt-formateur {
                    max-width: 950px;
                    width: 70%;
                    color: #707070;
                    line-height: 20px;

                    h4 {
                        padding-bottom: 10px;
                        font-size: 18px;
                    }

                    p {
                        display: inline-block;
                        width: 100%;
                    }
                }
            }

        }
    }

    @media only screen and (max-width: 1400px) {
        .illustration-header {
    
            .opacity-dark {
    
                .opacity-white {
                    margin: 0px 50px;
                }
    
                .title-wrapper-prog {
                    margin-left: 20px;
    
                    h2 {
                        font-size: 40px;
                    }
    
                    h3 {
                        font-size: 32px;
                    }
    
                    h1 {
                        font-size: 45px;
                    }
                }

                img {
                    width: 150px;
                }
            }
        }

        section-module-formation {
            
            .module-formation-list {
                padding: 50px 50px;
            }
        }
    }
        
    @media only screen and (max-width: 1079px) {

        .illustration-header {
    
            .opacity-light-section {
                height: auto;
                flex-direction: column-reverse;
    
                .left {
                    padding: 50px 0 0 0;
                    width: calc(96%);
                    margin: 0 auto;
                    height: 300px;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                        margin-top: 20px;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: center;
                    }

                    .title-wrapper-prog {
                        margin: 0;
                    }

                    h2 {
                        font-size: 30px;
                        margin-top: 20px;
                    }

                    h3 {
                        font-size: 30px;
                    }
                }
                img {
                    width: 90%;
                    max-width: 300px;
                    margin-left: 0px;
                    margin: 0 auto;
                }
            }
       }

        .section-modalité-formation {
            .time-prog-course, .modalite-prog-course, .formateur-prog-course {
                img {
                    width: 150px;
                }
            }
        }
    }


    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .illustration-header {

            height: 450px;

            &::before {
                clip-path:none;
            }
    
            .opacity-light-section {
                flex-direction: column-reverse;
    
                .left {
                    width: 96%;
                    margin: 0 auto;
                    height: 250px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 0;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                        padding: 20px 0;
                        width: 80%;
                        margin: 0 auto;
                    }
                }
                img {
                    margin-top: -50px;
                    max-width: 280px;
                }
            }    
        }

        
        .section-programme-formation {

            margin-top: 100px;

            h2 {
                font-size: 35px;
                text-align: center;

                .separator-blue {
                    width: 100px;
                    right: calc(50% - 50px);
                }
            }

            .tarif-wrapper {
                flex-direction: column;
                align-items: center;
                .tarif-formation {
                    font-size: 25px;
                    margin: 0 0 20px;
                }

                .btn-lightblue {
                    padding: 10px 40px;
                    font-size: 25px;
                }
            }

            .article-programme-description {
                flex-direction: column;

                img {
                    width: 150px;
                }
            }
        }

        .section-module-formation {
            .design-green-top {
                .separator-green {
                    width: 50px;
                }

                img {
                    width: 150px;
                }
            }

            .module-formation-list {
                flex-direction: column;

                .left-prog, .right-info {
                    width: 100%;
                }
            }

            .design-blue-bottom {
                .separator-blue {
                    width: 50px;
                }

                img {
                    width: 150px;
                }
            }
        }

        .section-modalité-formation {
            .time-prog-course, .modalite-prog-course, .formateur-prog-course {
                justify-content: center;

                .formateur-wrapper {
                    justify-content: center;

                    .txt-formateur {
                        width: 100%;
                    }

                    .img-wrapper {
                        margin: 20px 0;
                    }
                }
            }
        }
    }
`;

export default CourseProgStyled;      