import styled from 'styled-components';

const ModalMessageStyled = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content-container {
        width: 92%;
        max-width: 1000px;
        min-height: 300px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 5px 15px 10px #00000066;
        position: relative;
        box-sizing: border-box;

        .icon-wrapper {
            position: absolute;
            top: -35px;
            right: -35px;
            width: 40px;
            height: 40px;
            background-color: #649ACE;
            border-radius: 17.5px;
            overflow: hidden;
            display:flex;
            align-items: center;
            justify-content: center;

            .close-icon {
                color: #fff;
                font-size: 30px;
                cursor: pointer;
                border-radius: 20px;
            }
        }

        .page-wrapper-message {
           display: flex;
           align-items: center;
           justify-content: center;
           text-align: center;
           height: 300px;
           max-height: 300px;
           width: 100%;
           box-sizing: border-box;
           font-size: 18px;

           .message-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            height: 100%;
            max-height: 300px;
            width: 100%;
           }

           p {
            padding-bottom: 20px;
           }

           a {
            padding: 15px 20px;
            background-color: #649ACE;
            color: #fff; 
            border-radius: 5px;
            margin: 0 auto;
            display: inline-block;
            transition: all .3s ease;
            
            &:hover {
                opacity: .7;
            }
           }
        }
    }

    @media only screen and (max-width: 1079px) {
        .modal-content-container {
            .icon-wrapper {
                position: absolute;
                top: -20px;
                right: -8px;
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .modal-content-container {
            width: 96%;

            .icon-wrapper {
                position: absolute;
                top: -20px;
                right: -8px;
            }
        }
    }
`;

export default ModalMessageStyled;