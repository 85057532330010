/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import "aos/dist/aos.css"
import Button from '@mui/material/Button'
import URL from '../../assets/js/urlBackEnd'
import axios from 'axios'
import { toast } from 'react-toastify'
import randomString from '../../assets/js/randomString'
import { AiOutlineDownload } from "react-icons/ai"
import * as XLSX from 'xlsx/xlsx.mjs'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'

/**** Import des images ****/

/**** Import des css ****/
import PreInscriptionStyled from './PreInscriptionStyled'
import { isRefObject } from '@fluentui/react-component-ref'

const PreInscription = ({    
  user,
  userInfo,
  userRole,
  isLogged,
  jwt,
  fetchGetPreInscription,
  fetchGetPreInscriptionGodfather,
  title
}) => {
  const [nom, setNom] = useState('')
  const [prenom, setPrenom] = useState('')
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')

  const fetchCreateDraftAccount = () => {
    axios.post(`${URL}pre-inscriptions`, {
      data: {
        email: email,
        nom: nom,
        telephone: tel,
        prenom: prenom,
        users_permissions_user: user.id,
        created: false,
      }
    },
    {
      headers: {
        Authorization:
          `Bearer ${jwt}`,
        },
    })
      .then((response) => {
        fetchGetPreInscription()
        fetchGetPreInscriptionGodfather()
        toast(`Pre inscription enregistrée`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        toast(`Erreur: le formulaire est incomplet`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });
  }

  return (
    <PreInscriptionStyled>
      <h1 className='bold'>
        {title === undefined ? 'Formulaire de pré-inscription WEFOR' : title}
      </h1>
      <section className='illustration-header'>
        <TextField
          required
          id="nom"
          label="Nom"
          value={nom}
          onChange={(e) => setNom(e.target.value)}
        />
        <TextField
          required
          id="prenom"
          label="Prenom"
          value={prenom}
          onChange={(e) => setPrenom(e.target.value)}
        />
        <TextField
          required
          id="email"
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          required
          id="tel"
          type='number'
          label="Téléphone"
          value={tel}
          onChange={(e) => setTel(e.target.value)}
        />
        <div className='btn-container'>
        <Button variant="contained" className="button" onClick={() => fetchCreateDraftAccount()}>Valider</Button>
        </div>
      </section>
    </PreInscriptionStyled>
  )
}

PreInscription.propTypes = {
}

export default PreInscription