/**** Import de modules ****/
import React, {useEffect, useState} from 'react'
import DownloadBookletStyled from './DownloadBookletStyled'
import { saveAs } from 'file-saver'
import urlBackEndSave from '../../assets/js/urlBackEndSave';
import { FaDownload } from "react-icons/fa";

const DownloadBooklet = () => {

    const savePdf = (url, name) => {
        saveAs(
          `${urlBackEndSave}/uploads/Brochure_Wefor_2023_699861af76.pdf?updated_at=2023-06-09T13:02:35.143Z`,
          `Brochure Wefor 2023`
        );
      };
  
  return (
    <DownloadBookletStyled>
        <button onClick={savePdf}>
            <FaDownload />
            <span>Brochure entreprise</span>
        </button>
    </DownloadBookletStyled>
  )
}


export default DownloadBooklet