import { connect } from 'react-redux'

import AccountDoc from '../../pages/AccountDoc'

import { fetchFactureE, fetchFactureP, changeClickedFacture, fetchPutModeFinancement, changeStepValue, fetchUploadEdofFile, fetchStatutFormation, fetchFacturePWithDocs } from '../../actions/course'

import { fetchInscriptionUserLms, fetchCreateSessionLms } from '../../actions/lms'

import { toggleModalSignature } from '../../actions/modal'

const mapStateToProps = (state) => ({
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    factureE: state.course.factureE,
    factureP: state.course.factureP,
    userStatut: (state.login.isLogged ? state.login.userInfo.data[0].attributes.statut : ''),
    clickedFacture: state.course.clickedFacture,
    menuDocument: state.menu.menuDocument,
    modalSignatureIsOpen: state.modal.modalSignatureIsOpen,
    modalConventionIsOpen: state.modal.modalConventionIsOpen
})

const mapDispatchToProps = (dispatch) => ({
    fetchFactureE: () => {
        dispatch(fetchFactureE());
    },
    fetchFactureP: () => {
        dispatch(fetchFactureP());
    },
    changeClickedFacture: (factureId) => {
        dispatch(changeClickedFacture(factureId));
    },
    fetchPutModeFinancement: (factureId, statut) => {
        dispatch(fetchPutModeFinancement(factureId, statut))
    },
    changeStepValue: (number) => {
        dispatch(changeStepValue(number));
    },
    fetchUploadEdofFile: (file, id, mode) => {
        dispatch(fetchUploadEdofFile(file, id, mode));
    },
    fetchInscriptionUserLms: () => {
        dispatch(fetchInscriptionUserLms());
    },
    fetchCreateSessionLms: (duree, templateId) => {
        dispatch(fetchCreateSessionLms(duree, templateId));
    },
    fetchStatutFormation: (courseId) => {
        dispatch(fetchStatutFormation(courseId));
    },
    toggleModalSignature: (bool, docId, docType) => {
        dispatch(toggleModalSignature(bool, docId, docType));
    },
    fetchFacturePWithDocs: () => {
        dispatch(fetchFacturePWithDocs())
    }
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountDoc)