import { connect } from 'react-redux'

import ApprenantCard from '../../components/ApprenantCard'
import { fetchLmsApprenant, fetchWeforAccount } from '../../actions/apprenant' 
import { fetchPutUserOnSession } from '../../actions/lms' 

const mapStateToProps = (state) => ({
    formationApprenant: state.apprenant.formationApprenant,
    session: state.login.session,
})

const mapDispatchToProps = (dispatch) => ({
	fetchLmsApprenant : (name, id) => {
        dispatch(fetchLmsApprenant(name, id));
    },
    fetchWeforAccount: (email, id) => {
        dispatch(fetchWeforAccount(email, id));
    },
    fetchPutUserOnSession: (sessionId) => {
        dispatch(fetchPutUserOnSession(sessionId));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ApprenantCard)