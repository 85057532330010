/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import Skeleton from '@mui/material/Skeleton'
import Moment from 'react-moment'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import axios from 'axios'
import * as qs from 'qs'
import { toast } from 'react-toastify';
import URL from '../../../assets/js/urlBackEnd'
import FullPageLoader from '../../../containers/FullPageLoader'


/**** Import des images ****/

/**** Import des css ****/

const ParticuliersUser = ({
  usersFormAdminP,
  jwt
}) => {

  const [open, setOpen] = React.useState(false)
  const [infos, setInfos] = React.useState(false)
  
  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpen = (id) => {
    setInfos('loading')
    setOpen(true)

    const queryFilterObj = qs.stringify({
      populate: '*',
      headers: {
        Authorization:
        `Bearer ${jwt}`,
      },
      }, {
        encodeValuesOnly: true,
    });
    
    axios.get(`${URL}particuliers/${id}?${queryFilterObj}`)
    .then((response) => {
      setInfos(response.data.data)
    })
    .catch((error) => {
      console.error(error)
    })
  }

  const sortCourseEnded = (array) => {
    let result = array.filter(x => x.attributes.step > 2)
    return result
  }


  return (
    <section>
      <div className="pre-list-wrapper">
        {usersFormAdminP === 'loading' &&
          <Skeleton variant="text" width={'100%'} height={20}/>
        }
         {usersFormAdminP !== 'loading' && usersFormAdminP.length > 0 &&
          <>
          <div className='title-wrapper'>
            <div className='row-title bold'>Nom complet</div>
            <div className='row-title bold'>Téléphone</div>
            <div className='row-title bold'>Email</div>
            <div className='row-title bold'>Créé le</div>
            <div className='row-title bold'>Informations</div>
          </div>
          {usersFormAdminP.map((user, index) => (
            <>
            {user.attributes.user.data !== null && 
              <div key={index} className='info-wrapper'>
                <div className='row-user modif-height'>{user.attributes.user.data.attributes.nom} {user.attributes.user.data.attributes.prenom}</div>
                <div className='row-user modif-height'>{user.attributes.user.data.attributes.telephone}</div>
                <div className='row-user modif-height'>{user.attributes.user.data.attributes.email}</div>
                <div className='row-user modif-height'><Moment format="DD/MM/YYYY">{user.attributes.user.data.attributes.createdAt}</Moment></div>
                <div className='row-user modif-height more-link'  onClick={() => handleClickOpen(user.id)}>En savoir plus</div>
              </div>
            }
            </>
          ))}
          </>
        }
      </div>
      {infos === false ?
      <></>
      :
      <>
      {infos === 'loading' ?
        <FullPageLoader />
        :
        <Dialog
          open={open}
          maxWidth='sm'
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ul>
              <li className='row-infos'>
                <span className='column-title'>Nom complet : </span>
                {infos.attributes.user.data.attributes.prenom !== null && infos.attributes.user.data.attributes.nom !== null && infos.attributes.user.data.attributes.prenom !== '' && infos.attributes.user.data.attributes.prenom !== '' ?
                  <span className='column-info'>{infos.attributes.user.data.attributes.prenom} {infos.attributes.user.data.attributes.nom}</span>
                  :
                  <span className='column-info'>Non renseigné</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Email : </span>
                {infos.attributes.user.data.attributes.email !== null && infos.attributes.user.data.attributes.email !== '' ?
                  <span className='column-info'>{infos.attributes.user.data.attributes.email}</span>
                  :
                  <span className='column-info'>Non renseigné</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Téléphone : </span>
                {infos.attributes.user.data.attributes.telephone !== null && infos.attributes.user.data.attributes.telephone !== '' ?
                  <span className='column-info'>{infos.attributes.user.data.attributes.telephone}</span>
                  :
                  <span className='column-info'>Non renseigné</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Date de création : </span>
                {infos.attributes.user.data.attributes.telephone !== null && infos.attributes.user.data.attributes.telephone !== '' ?
                  <span className='column-info'><Moment format="DD/MM/YYYY">{infos.attributes.user.data.attributes.createdAt}</Moment></span>
                  :
                  <span className='column-info'>Non renseigné</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Adresse : </span>
                {infos.attributes.adresse_postale !== null && infos.attributes.adresse_postale !== '' ?
                  <span className='column-info'>{infos.attributes.adresse_postale}</span>
                  :
                  <span className='column-info'>Non renseigné</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Entreprise : </span>
                {infos.attributes.entreprise.data !== null ?
                  <span className='column-info'>{infos.attributes.entreprise.data.attributes.Nom_entreprise}</span>
                  :
                  <span className='column-info'>Aucune entreprise</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Demande(s) de formation(s) : </span>
                {infos.attributes.facturation_formations.data !== null ?
                  <span className='column-info'>{infos.attributes.facturation_formations.data.length}</span>
                  :
                  <span className='column-info'>Aucune demande</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Formation(s) terminée(s) : </span>
                {infos.attributes.facturation_formations.data !== null ?
                  <span className='column-info'>{sortCourseEnded(infos.attributes.facturation_formations.data).length}</span>
                  :
                  <span className='column-info'>Aucune</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Nombre de candidature : </span>
                {infos.attributes.offre_emplois_postule.data !== null ?
                  <span className='column-info'>{infos.attributes.offre_emplois_postule.data.length}</span>
                  :
                  <span className='column-info'>Aucune</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Test OCEAN : </span>
                {infos.attributes.test_ocean.data !== null ?
                  <span className='column-info'>
                    Overture d'esprit = {Math.round(infos.attributes.test_ocean.data.attributes.ouverture_esprit) / (10 * 5) * 100}% 
                    Conscience = {Math.round(infos.attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100)}% - 
                    Extraversion = {Math.round(infos.attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100)}% - 
                    Altruisme = {Math.round(infos.attributes.test_ocean.data.attributes.altruisme  / (10 * 5) * 100)}% - 
                    Nevrosisme = {Math.round(infos.attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100)}% - 
                    </span>
                  :
                  <span className='column-info'>Aucune donnée</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Souhait : </span>
                {infos.attributes.souhait !== null && infos.attributes.souhait === '' ?
                  <span className='column-info'>
                    {infos.attributes.souhait}
                  </span>
                  :
                  <span className='column-info'>Non renseigné</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Niveau : </span>
                {infos.attributes.niveau !== null && infos.attributes.niveau === '' ?
                  <span className='column-info'>
                    {infos.attributes.niveau}
                  </span>
                  :
                  <span className='column-info'>Non renseigné</span>
                }
              </li>
              <li className='row-infos'>
                <span className='column-title'>Dernier emploi : </span>
                {infos.attributes.dernier_emploi !== null && infos.attributes.dernier_emploi === '' ?
                  <span className='column-info'>
                    {infos.attributes.dernier_emploi}
                  </span>
                  :
                  <span className='column-info'>Non renseigné</span>
                }
              </li>
              {/* last job, cv, qs */}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{padding: '10px', borderRadius: '5px'}}>
            Terminé
          </Button>
        </DialogActions>
        </Dialog>
      }</>
      }

    </section>
  )
}

ParticuliersUser.propTypes = {
}

export default ParticuliersUser