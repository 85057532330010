import styled from 'styled-components';

const ModalCourseInscriptionStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    max-width: 700px;

    section {
        margin: 40px 0px 40px 40px;
        padding: 0 40px 0 0;
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        text-align: center;
        width: 100%;

        .txt-skeleton {
            font-size: 20px;
            width: 100px;
        }

        .prenom {
            text-transform: uppercase;
            font-size: 35px;
            color: #649ACE;
            margin-bottom: 30px;
        }

        .answer {
            text-transform: uppercase;
            font-size: 30px;
            color: #649ACE;
        }

        .card {
            width: 100%;
            max-width: 450px;
            background-color: #F5F5F5;
            border-radius: 30px;
            padding: 10px 30px 10px 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            box-shadow: 0px 3px 6px #00000029;
            margin: 50px auto 20px;
            text-align: left;
        
            img {
                width: 100px;
            }
        
            .text-wrapper {
                width: calc(98% - 120px);
        
                h3 {
                    color: #649ACE;
                    font-size: 20px;
                }

                h4 {
                    color: #649ACE;
                    border-bottom: 1px solid #202D3C;
                    font-size: 16px;
                    padding: 5px 0px;
                    margin-bottom: 5px;
                }
        
                .bottom-wrapper {
        
                    p {
                        display: inline-block;
                        color: #202D3C;
                        padding-top: 5px;
                        font-size: 18px;
                    }
                }
            }
        }
        
        .disclaimer {
            max-width: 420px;
            margin: 0 auto;
            text-align: justify;
            color: #202D3C;
            line-height: 22px;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            a {
                color: #202D3C;
            }
        }

        .btn-lightblue {
            color: #fff;
            background-color: #649ACE;
            border-radius: 12px;
            border: 0;
            padding: 10px 0px;
            cursor: pointer;
            font-size: 20px;
            width: 250px;
            margin-top: 50px;
    
            &:hover {
                background-color: #202D3C;
            }
        }
    }
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        width: 100%;
        section {
            padding: 40px 10px 20px;
            margin: 0;

            h3.answer {
                font-size: 24px;
                text-transform: inherit;
            }

            .card {
                border-radius: 10px;
                background-color: #fff;
                padding: 10px 10px 10px 0px;

                .cpf-logo {
                    padding: 10px;
                }

                .text-wrapper {
                    width: 100%;

                    h3.title-blue {
                        color: #4d4d4d;
                    }

                    h4 {
                        border-bottom: 1px solid #d47558;
                        color: #4d4d4d;
                    }

                    .bottom-wrapper {
                        p {
                            color: #d47558;
                        }
                    }
                }
            }

            .btn-lightblue {
                margin: 20px auto 0;
                border-radius: 5px;
                font-size: 18px;
            }
        }
    }
`;

export default ModalCourseInscriptionStyled;