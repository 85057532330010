/**** Import de modules ****/
import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { Progress } from 'semantic-ui-react'
import axios from 'axios'
import * as qs from 'qs'
import { Skeleton } from '@mui/material'


import ModalSatisfaction from '../../containers/ModalSatisfaction'

import urlBackEnd from '../../assets/js/urlBackEnd';


/**** Import d'images ****/
import videoblue from '../../assets/img/illustration/videoblue.png'


/**** Import des css ****/
import CourseDoneCardStyled from './CourseDoneCardStyled'

const URL = urlBackEnd

const CourseDoneCard = ({ attributes, userInfo, courseId, salarie, statut, fromForm, toggleModalSatisfaction, isModalSatisfactionOpen, session, id}) => {

  const [cardScore, setCardScore] = useState([])

  const [satisfactionQ, setSatisfactionQ] = useState('not load')
  const [rerender, setRerender] = useState(false);
  const [page, setPage] = useState(1)
  useEffect(()=>{
      setRerender(!rerender);
  }, [toggleModalSatisfaction]);

  useEffect(() => {
    fetchGetQSStatut()
  }, [])

  const fetchGetQSStatut = () => {
    const queryFilterPart = qs.stringify({
      filters: {  
        formation: {
          id: attributes.formation.data.id
        },
        particuliers: {
          id: userInfo.data[0].id
        },
      }}, 
      {
        encodeValuesOnly: true,
      },
      );
      axios.get(`${URL}questionnaire-satisfactions?${queryFilterPart}`, {
        headers: {
          Authorization:
            `Bearer ${session.jwt}`,
          },
      })
      .then((response) => {
        setSatisfactionQ(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  useEffect(() => {
    const queryFilterPart = qs.stringify({
      filters: {  
        formation: {
          id: attributes.formation.data.id
        },
        particulier: {
          id: userInfo.data[0].id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}score-formations?${queryFilterPart}`, {})
      .then((response) => {
       setCardScore(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }
  , [])
  
  return (
    <CourseDoneCardStyled>
      {cardScore.length === 0 ? 
      <img src={videoblue} alt="picto d'un écran vidéo bleu" />
      :
      <>
      {statut === 'encours' ? 
      <>
        {cardScore.length > 0 ?
          <div className="circle-wrapper">
           <CircularProgress className="progress-circle" color='success' size={90} variant="determinate" value={cardScore[0].attributes.score} />
           <div className="score-div"><span className='top bold'>{cardScore[0].attributes.score}</span><span className='middle bold'>/</span><span className='bottom bold'>100</span></div>
          </div>
        :
          <img src={videoblue} alt="picto d'un écran vidéo bleu" />
        }
      </>
      
      :
      <div className="circle-wrapper">
      <CircularProgress className="progress-circle" color='success' size={90} variant="determinate" value={cardScore[0].attributes.score} />
      <div className="score-div"><span className='top bold'>{cardScore[0].attributes.score}</span><span className='middle bold'>/</span><span className='bottom bold'>100</span></div>
      </div>
      }
      </>
    }
      <div className="text-wrapper">
      {attributes.formation.data.attributes.secteurs.data.length > 0 ?
      <div className="title-wrapper">
        <h3 className="title-orange bold">{attributes.formation.data.attributes.secteurs.data[0].attributes.nom}</h3>
        <h4 className="bold">Formation {attributes.nom_formation}</h4>
      </div>
        
      :  
        <Skeleton variant="text" className='txt-skeleton' />
      }
        <div className="bottom-wrapper">
          <div className="flex-bottom">
            <p>Durée : {attributes.formation.data.attributes.duree}h</p>
            {fromForm !== undefined && fromForm === 'ongoing' &&
            <>
            {attributes.formation.data.attributes.lms_formation_id === 'formapro' ?
            <Link to='/connexion-lms' className="bold">
              <button className="btn-lightblue">Accéder à la formation</button>
            </Link>
            :
            <a href={attributes.formation.data.attributes.lms_formation_id === 'univo' ? 'https://wefor.cloudplateforme.com/auth/login' : `https://elearning.wefor.fr/`} className="bold">
              <button className="btn-lightblue">Accéder à la formation</button>
            </a>
            }
            </>
            }
            {fromForm !== undefined && fromForm === 'end' &&
            <>
              {satisfactionQ === 'not load' &&
                <Skeleton variant="rectangular" className='txt-skeleton' />
              }

              {satisfactionQ.length > 0 ?
                <div className='questionnaire-ok'>Questionnaire de satisfaction effectué</div>
                :
                <button 
                  className="btn-lightblue"
                  onClick={() => {
                    toggleModalSatisfaction(true)
                  }}
                >
                  Répondez au questionnaire de satisfaction
                </button>
              }
            </>
            }
          </div>
          {cardScore.length > 0 ? 
          <Progress percent={cardScore[0].attributes.progress} indicating progress size='small'/>
          :
          ''
          }
        </div>
      </div>
      {isModalSatisfactionOpen && 
        <ModalSatisfaction attributes={attributes} courseId={courseId} statut={'particulier'} factureId={id} />
      }
    </CourseDoneCardStyled>
  )
}

CourseDoneCard.propTypes = {
}

export default CourseDoneCard