import styled from 'styled-components';

const AppStyled = styled.div`
    overflow: hidden;
    scroll-behavior: smooth;

    .bottom-right-item {
        position fixed;
        right: 120px;
        bottom: 30px;
        z-index: 250;
    }

    .btn-orange {
        background-color: #d47558 !important;
        color: #fff !important;
        border: 0 !important;
        border-radius: 10px !important;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .toast-container {
        z-index: 1000000;
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .bottom-right-item {
            position fixed;
            right: inherit;
            left: 20px;
            bottom: 20px;
            z-index: 250;
        }
    }
`;

export default AppStyled;