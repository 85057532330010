import { connect } from 'react-redux'

import Homebis from '../../pages/Home'

import { changeCurrentDomaineClicked, changeCurrentProfilUser } from '../../actions/menu'
import { setWindowTop } from '../../actions/actions'
import { fetchFormations } from '../../actions/course'
import { 
    changeSigninStep,
} from '../../actions/signin'
import { fetchJobs } from '../../actions/job'

const mapStateToProps = (state) => ({
    windowTop: state.actions.windowTop,
    formations: state.course.formationsHome,
    loadFormation: state.course.loadFormation,
    jobs: state.job.jobsHome,
    loadJobs: state.job.loadJobs,
    currentProfilUser: state.menu.currentProfilUser,
})

const mapDispatchToProps = (dispatch) => ({
    changeCurrentDomaineClicked: (id) => {
        dispatch(changeCurrentDomaineClicked(id));
    },
    setWindowTop: (bool) => {
        dispatch(setWindowTop(bool));
    },
    fetchFormations: (paramObj) => {
        dispatch(fetchFormations(paramObj));
    },
    changeSigninStep: (number) => {
        dispatch(changeSigninStep(number));
    },
    changeCurrentProfilUser: (name) => {
        dispatch(changeCurrentProfilUser(name));
    },
    fetchJobs: (paramObj) => {
        dispatch(fetchJobs(paramObj));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Homebis)