/**** Import des modules ****/
import React, {useState, useEffect} from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { MdPhoneCallback, MdOutlineMarkEmailRead, MdOutlineTimer } from "react-icons/md";
import { BsPlusSquareFill, BsDashSquareFill } from "react-icons/bs";
import { ImUsers } from "react-icons/im";
import axios from 'axios'
import * as qs from 'qs'

/**** Import de components et containers ****/
/**** Import des images ****/
import progaccount from '../../../assets/img/illustration/progaccount.png'

/**** Import des css ****/
import ModalCourseInscriptionCollabStyled from './ModalCourseInscriptionCollabStyled'
import { Skeleton } from '@mui/material'
import urlBackEnd from '../../../assets/js/urlBackEnd'

const URL = urlBackEnd

const ModalCourseInscriptionCollab = ({prenom, closeModal, formations, activCourseId, fetchDemandeFormation, emailInsCollab, nomInsCollab, prenomInsCollab, changeValue, fetchDemandeFormationCollab, setModalInscLoaderOpen,setInscCollabStepOne, redirectToMyFinancement}) => {

  const [nbFormInsc, setNbFormInsc] = useState([{'id': 1}]);
  const [rerender, setRerender] = useState(false);
  const [secteurCart, setSecteurCart] = useState([]);

  useEffect(() => {
    const queryFilterPart = qs.stringify({
      filters: {
        formations: {
          id: activCourseId
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}secteurs?${queryFilterPart}`, {})
      .then((response) => {
       setSecteurCart(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }
  , [])

  useEffect(()=>{
      setRerender(!rerender);
  }, []);

  useEffect(() =>  {
    setNbFormInsc(nbFormInsc)
  }, []);

  const filterdFormation = () => {
    if (formations !== undefined) {
      const findFormation = formations.find(formation => formation.id === activCourseId)
      return findFormation
    } else {
      return false
    }   
  }

  if(redirectToMyFinancement) {
    closeModal('')
    return (<Navigate to="/account/financements" replace />)
  }

  const addItemOnFormInsc = () => {
    let objectToPush = {"id" : Number(nbFormInsc.length) + 1}
    nbFormInsc.push(objectToPush)
    setNbFormInsc(nbFormInsc)
    setRerender(!rerender);
  }

  const removeItemOnFormInsc = () => {
    if(nbFormInsc.length > 1 ) {
      nbFormInsc.splice(-1)
      setNbFormInsc(nbFormInsc)
      setRerender(!rerender)
    }
  }

  return (
    <ModalCourseInscriptionCollabStyled>
      <section>
        <h2 className="prenom bold">{prenom}</h2>
        <h3 className="answer bold">VOUS AVEZ SÉLECTIONNÉ CETTE FORMATION</h3>
        {filterdFormation() !== false && 
        <div className="card">
          <img src={progaccount} alt="picto d'un écran vidéo bleu" />
          <div className="text-wrapper">
            <h3 className="title-blue bold">{filterdFormation().attributes.nom}</h3>
            {secteurCart.length > 0 ? 
            <>
              <h4 className="">Secteur: {secteurCart[0].attributes.nom}</h4>
            </>
            :
              <Skeleton variant="text" className='txt-skeleton' />
            }
            <div className="bottom-wrapper">
              <p className="bold capitalize">{filterdFormation().attributes.prix}€</p>
            </div>
          </div>
        </div>
        }
        <form
            className="account-add-collab"
            onSubmit={(event) => {
              event.preventDefault();
              fetchDemandeFormationCollab(filterdFormation().id, nbFormInsc)
              changeValue("","emailInsCollab" )
              changeValue("","prenomInsCollab" )
              changeValue("","nomInsCollab" )
            }}
          >
          <h2 className="bold">Merci de renseigner quelques informations sur {nbFormInsc.length > 1 ? "vos" : "votre"} collaborteur</h2>
          {nbFormInsc.map((form) => (
          <div className="wrapper-collab-form" key={form.name}>
          <label>
            Nom
            <input 
              type="text" 
              name={`nomInsCollab${form.id}`}
              value={nbFormInsc[form.id - 1].name}
              required
              placeholder="Nom du collaborateur"
              onChange={(event) => {
                changeValue(event.target.value, event.target.name);
                const name = event.target.value
                nbFormInsc[form.id - 1].name = name
              }}
            />
          </label>
          <label>
            Prénom
            <input 
              type="text" 
              name={`prenomInsCollab${form.id}`}
              value={nbFormInsc[form.id - 1].prenom}
              required
              placeholder="Prenom du collaborateur"
              onChange={(event) => {
                changeValue(event.target.value, event.target.name);
                const prenom = event.target.value
                nbFormInsc[form.id - 1].prenom = prenom
              }}
            />
          </label>
          <label>
            Adresse Email
            <input 
              type="email" 
              name={`emailInsCollab${form.id}`}
              value={nbFormInsc[form.id - 1].email}
              required
              placeholder="Email du collaborateur"
              onChange={(event) => {
                changeValue(event.target.value, event.target.name)
                let email = event.target.value
                nbFormInsc[form.id - 1].email = email
              }}
            />
          </label>
          </div>
          ))}
          <div className="add-remove-wrapper">
            <BsPlusSquareFill className="picto-green" onClick={() => addItemOnFormInsc()} />
            <BsDashSquareFill className="picto-red" onClick={removeItemOnFormInsc}/>
          </div>
          <p className="message-and-icons"><MdPhoneCallback /> <span>Nous allons vous contacter rapidemment.</span></p>
          <p className="message-and-icons"><MdOutlineMarkEmailRead /> <span>Un email va être envoyé à votre collaborateur pour qu'il créé un compte.</span></p>
          <p className="message-and-icons"><MdOutlineTimer /><span>Vous pourrez suivre en direct l'avancement de sa formation.</span></p>
          <p className="message-and-icons"><ImUsers /><span>Vous pouvez inscrire autant de collaborateurs que vous voulez.</span></p>
          <label>
            <input 
              type="submit" 
              value="Demande d'inscription" 
              className="btn-lightblue"
            />
          </label>
        </form>
      </section>
    </ModalCourseInscriptionCollabStyled>
  )
}

ModalCourseInscriptionCollab.propTypes = {
  closeModal: PropTypes.func.isRequired,
  prenom: PropTypes.string.isRequired,
}

export default ModalCourseInscriptionCollab

