import { connect } from 'react-redux'

import AccountAdminFacture from '../../pages/AccountAdminFacture'

import { fetchGetFactureClient } from '../../actions/course'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
	fetchGetFactureClient: () => {
        dispatch(fetchGetFactureClient());
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountAdminFacture)