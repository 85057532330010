import styled from 'styled-components';

const CourseCardListLayoutStyled = styled.article`
    width: 100%;
    color: #d47558;
    border-radius: 30px; 
    padding: 10px;
    box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    position: relative;
    margin: 50px 0 0 0px;
    text-align: center;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background-color: #F7EDEA;
    }

    &.pointer {
        cursor: pointer;
    }

    &.active-comp {
        border: 3px solid #d47558;
        background-color: #F7EDEA;
        cursor: pointer;
    }

    .title-wrapper {
        text-align: center;
        text-transform: Capitalize;
        font-size: 14px;
        display: inline-block;
        height: 90px;
        padding-top: 25.5px;
        padding-right: 5px;
        width: calc(60% - 90px);

        .first-title {
            font-weight: bold;
            color: #4d4d4d;
        }

        .separator-orange {
            margin: 5px 0;
            height: 1px;
            background-color: #4d4d4d;
        }
    }

    .duree-wrapper {
        text-align: center;
        text-transform: Capitalize;
        font-size: 14px;
        display: inline-block;
        width: 8%;

        .duree {
            color: #4d4d4d;
        }

        .separator-orange {
            margin: 5px 0;
            height: 1px;
            background-color: #4d4d4d;
        }
    } 
    
    .salary-wrapper {
        text-align: center;
        text-transform: Capitalize;
        font-size: 14px;
        display: inline-block;
        width: 8%;

        .small-txt {
            color: #4d4d4d;
        }

        .separator-green {
            margin: 5px 0;
            height: 1px;
            background-color: #4d4d4d;
        }
    }

    .btn-skeleton {
        width: 100px;
        border-radius: 10px;
        height: 28px;
        margin: 0 auto 5px;
    }

    .txt-skeleton {
        font-size: 18px;
    }

    .btn-lightorange {
        width: 100px;
        margin: 5px auto 0;
        display: block;
        padding: 5px;
        border: 1px solid #d47558;
        border-radius: 10px;
        margin-bottom: 5px;
        color: #d47558;
        background-color: #fff;
        cursor: pointer;
        font-size: 14px;
        z-index: 2;

        &:hover {
            color: #fff;
            background-color: #d47558;
        }
    }

    .btn-orange {
        width: 100px;
        margin: 0 auto;
        display: block;
        padding: 5px;
        border: 1px solid #d47558;
        border-radius: 10px;
        color: #fff;
        background-color: #d47558;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            color: #d47558;
            background-color: #fff;
        }
    }

    .shield-wrapper {
        height: 80px;
        width: 80px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo-list {

            .picto-opco {
                width: 30px;
            }
        }

        &.orange {

        }

        &.blue {
            color: #6399ce;
            border: 1px solid #6399ce;
            height: 70px;
            width: 70px;
            font-size: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .green {
                background-color: #6399ce;
                color: #fff;
                font-size: 12px; 
                padding: 5px 0;
                width: calc(100% + 6px);
            }
        }

        img {
            width: 70px;
        }
    }

    .picto-opco {
        width: 30px;
    }

    .cpf-wrapper {
        display: none;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        position: absolute;
        bottom: -15px;
        left: calc(50% - 75px);
        width: 150px;
        background-color: #dbe8f4;
        border-radius: 10px;

        img {
            width: 70px;
        }

        div {
            color: #6399ce;
        }
    }

    @media only screen and (max-width: 1400px) {
        margin: 50px 0 0 10px;
    }

    @media only screen and (min-width: 760px) and (max-width: 1079px) {
        .mobile-none {
            display: none;
        }
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
      
    }
`;

export default CourseCardListLayoutStyled;