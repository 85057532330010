import styled from 'styled-components';

const JobCardStyled = styled.article`
    width: 270px;
    height: 350px;
    color: #d47558;
    border-radius: 30px; 
    padding: 15px;
    box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    position: relative;
    margin: 50px 17.5px 0 17.5px;
    text-align: right;
    box-sizing: border-box;
    background-color: #fff;

    &.pointer:hover {
        background-color: #F7EDEA;
    }

    &.pointer {
        cursor: pointer;
    }

    &.active-comp {
        border: 3px solid #d47558;
        background-color: #F7EDEA;
        cursor: pointer;
    }

    .black {
        color: #000;
    }

    h4.second-title {
        font-size: 14px;
    }

    .title-wrapper {
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        display: inline-block;
        margin-right: 20px;

        &.align-center {

        }

        .separator-orange {
            margin: 5px 0;
            height: 1px;
            background-color: #d47558;
        }
    }

    .job-cat {
        width: 100%;
        text-align: center;
        margin: 30px 0 20px;
        height: 60px;
        font-size: 20px;
        color: #4d4d4d;
    }

    h3 {
        text-align: center;
        font-weight: bold;
        width: 100%;
        height: 30px;
        font-size: 20px;
    }  
    
    .salary-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px 0 20px;
        font-weight: bold;
        color: #4d4d4d;
        font-size: 22px;
    }

    .btn-lightorange {
        width: 150px;
        margin: 0 auto 20px;
        display: block;
        padding: 5px;
        border: 1px solid #d47558;
        border-radius: 10px;
        color: #d47558;
        background-color: #fff;
        cursor: pointer;
        font-size: 14px;
        text-align: center;

        &:hover {
            color: #fff;
            background-color: #d47558;
        }
    }

    .btn-skeleton {
        width: 150px;
        border-radius: 10px;
        height: 28px;
        margin: 0 auto;
    }

    .btn-orange {
        width: 150px;
        margin: 0 auto;
        display: block;
        padding: 5px;
        border: 1px solid #d47558;
        border-radius: 10px;
        color: #fff;
        background-color: #d47558;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            color: #d47558;
            background-color: #fff;
        }
    }

    .shield-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        position: absolute;
        top: -25px;
        left: -25px;
        border-radius: 50%;
        background-color: #fff;

        &.blue {
            text-align: center;
            color: #6399ce;
            border: 1px solid #6399ce;
            height: 70px;
            width: 70px;
            font-size: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            border-radius: 5px;

            .green {
                background-color: #6399ce;
                color: #fff;
                font-size: 12px; 
                padding: 5px 0;
                width: calc(100% + 6px);
            }
        }

        &.orange {
            
        }

        img {
            width: 70px;
        }
    }

    .cpf-wrapper {
        display: none;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        position: absolute;
        bottom: -15px;
        left: calc(50% - 75px);
        width: 150px;
        background-color: #dbe8f4;
        border-radius: 10px;

        img {
            width: 30px;
        }

        div {
            color: #6399ce;
        }
    }

    @media only screen and (max-width: 1400px) {
        width: 270px;
        border-radius: 30px; 
        padding: 15px;
        margin: 40px 10px 0 10px;

        .title-wrapper {
            font-size: 18px;
            margin-right: 20px;
        }

        .job-cat {
            margin: 30px 0 20px;
            height: 60px;
            font-size: 20px;
            text-align: center;
        }

        h3 {
            height: 30px;
            font-size: 20px;
        } 

        .salary-wrapper {
            padding: 10px 0 20px;
            font-size: 22px;
            align-items: center;
        }

        .shield-wrapper {
            border-radius: 50%;
            top: -25px;
            left: -25px;
    
            img {
                width: 70px;
            }
        }
    }

    @media only screen and (min-width: 760px) and (max-width: 1079px) {
        .mobile-none {
            display: none;
        }
    }
  
@media only screen and (min-width: 0px) and (max-width: 759px) {
    margin: 10px;
    padding: 10px;
    box-shadow: 0px 8px 20px 5px rgba(219,219,219,1);
    height: ${props => props.location === 'course' ? '300px' : '250px'};
    border-radius: 20px;
    text-align: center;

    .title-wrapper {
        width: calc(100% - 70px);
        margin: 0 0 0 70px;

        .first-title {
            font-size: 16px;
        }
    }

    .btn-orange, .btn-lightorange {
        display: inline-block;
        width: 80%;
        padding: 10px 0;
    }

    .btn-orange {
        width: 80%;
        
    }

    .btn-lightorange {
        margin: 0 auto 5px;
        margin-left: 1%;
    }

    .job-cat {
        margin: 30px 0 10px;
        height: auto;
        font-size: 18px;
    }

    h3, .salary-wrapper {
        font-size: 18px;
    }

    .salary-wrapper {
        padding-bottom: 10px;
    }

    h3 {
        height: 22px;
    }
    
    .shield-wrapper {
        top: 5px;
        left: 5px;
    }
}
`;

export default JobCardStyled;