import { connect } from 'react-redux'

import JobCardListLayout from '../../components/JobCardListLayout'
import { changeActivJobId, setJobFicheId, saveDistanceOnJobs, toggleModalPostulerTuto, setJobIdModalPostulerTuto, setCvPosted, setDidTest, setJobForModalPostuler } from '../../actions/job'
import { changeActivCourseId } from '../../actions/course'
import { setFilter } from '../../actions/menu'
import { openModal } from '../../actions/modal'

const mapStateToProps = (state) => ({
    activJobId: state.job.activJobId,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    jobs: state.job.jobs,
    jobsWithDistances: state.job.jobsWithDistances,
    userInfo: state.login.userInfo,
    kmJobFilter: state.menu.kmJobFilter,
    showDistance: state.job.showDistance,
    fullPageLoaderDistance: state.job.fullPageLoaderDistance
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => {
      dispatch(openModal(name));
    },
    changeActivJobId: (id) => {
        dispatch(changeActivJobId(id));
      },
    setJobFicheId: (id) => {
        dispatch(setJobFicheId(id));
      },
    changeActivCourseId: (id) => {
      dispatch(changeActivCourseId(id));
    },
    saveDistanceOnJobs: () => {
      dispatch(saveDistanceOnJobs());
    },
    setFilter: (name, title) => {
      dispatch(setFilter(name, title));
    },
    toggleModalPostulerTuto: (bool) => {
      dispatch(toggleModalPostulerTuto(bool));
    },
  setJobIdModalPostulerTuto: (id, name) => {
        dispatch(setJobIdModalPostulerTuto(id, name));
    },
  setCvPosted: (bool) => {
        dispatch(setCvPosted(bool));
    },
  setDidTest: (bool) => {
        dispatch(setDidTest(bool));
    },
  setJobForModalPostuler: (data) => {
    dispatch(setJobForModalPostuler(data));
  },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(JobCardListLayout)