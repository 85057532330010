import { connect } from 'react-redux'

import NewsFlux from '../../pages/NewsFlux'

import { 
    fetchGetNews,
	changeSearchNews
} from '../../actions/news'

const mapStateToProps = (state) => ({
	search: state.news.search,
	newsFlux: state.news.newsFlux
})

const mapDispatchToProps = (dispatch) => ({
	fetchGetNews: (search) => {
		dispatch(fetchGetNews(search));
	},
	changeSearchNews: (value) => {
		dispatch(changeSearchNews(value));
	},
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(NewsFlux)