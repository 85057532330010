import { connect } from 'react-redux'

import SearchCourseByKeyBis from '../../components/SearchCourseByKeyBis'
import { setMuiCourseFilterKeyValue, changeActivCourseId, saveSlugs, saveFormationFromParams } from '../../actions/course'


const mapStateToProps = (state) => ({
  muiCourseFilterKeyValue: state.course.muiCourseFilterKeyValue,
  activCourseId: state.course.activCourseId,
})

const mapDispatchToProps = (dispatch) => ({
  setMuiCourseFilterKeyValue: (value) => {
        dispatch(setMuiCourseFilterKeyValue(value));
  },
  changeActivCourseId: (id) => {
    dispatch(changeActivCourseId(id));
  },
  saveSlugs: (name, slug) => {
    dispatch(saveSlugs(name, slug))
  },
  saveFormationFromParams: (status, data) => {
    dispatch(saveFormationFromParams(status, data));
},
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(SearchCourseByKeyBis)