import styled from 'styled-components';

const ContratCourseStyled = styled.article`
    .not-signed-section {
        width: 96%;
        margin: 50px auto;

        .top {
            ul {
                margin: 20px 0;

                li {
                    list-style: inside;
                }
            }
        }

        .bottom {
            .dl-btn {
                border: 0;
                padding: 15px 20px;
                border-radius: 10px;
                color: #fff;
                background-color: #202c3c;
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: all .3s ease;

                &:hover {
                    opacity: .7;
                }

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    .signed-section {
        width: 96%;
        margin: 50px auto;

        .bottom {
            
            .dl-btn {
                border: 0;
                margin: 0 auto;
                padding: 15px 20px;
                border-radius: 10px;
                color: #fff;
                background-color: #202c3c;
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: all .3s ease;

                &:hover {
                    opacity: .7;
                }

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    @media only screen and (max-width: 1400px) {
    }

    @media only screen and (min-width: 760px) and (max-width: 1079px) {
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
    }
`;

export default ContratCourseStyled;