import styled from 'styled-components';

const AccountFinancementStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

	.right-wrapper {
		width: calc(100% - 250px);
		padding: 20px;
	}

	.financements-list {
		width: 100%;
		padding: 0px 0 0px;
		box-sizing: border-box;
		position: relative;
		box-shadow: 0px 1px 10px #00000029;
		border-radius: 10px;

		&:nth-child(1) {
			margin-bottom: 40px;
			font-size: 16px;
		}

		.drop-down-1 {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px;
			cursor: pointer;
			font-size: 18px;

			h4, .info-dyna {
				width: calc(50% - 25px);
				text-align: left;
			}

			.arrow-wrapper {
				max-height: 20px;
				.arrow-down {
					font-size: 50px;
					color: #D47558;
					margin-top: -15px;
					margin-right: -20px;
				}
			}
		}

		h4 {
			color: #D47558;
			text-align: center;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
		}

		.link-cards {
			margin: 0px 0px;
			text-align: center;
			background-color: #fff;
			transition: font-size .2s ease;
			cursor: pointer;
			color: #D47558;

			p {
				height: 30px;
				margin: 50px 0 0 0px;
			}

			&:hover {
				font-size: 17px;
			}
		}

		.formations-wrapper {
			.links-facture {
				color: #4d4d4d;
				display: flex;
				flex-wrap: wrap;
				align-items:center;
				justify-content: space-between;
				font-size: 16px;
				border-top: 1px solid #f8e8e3;
				cursor: pointer;

				span {
					padding-right: 10px;
				}

				&:hover {
					background-color: #f8e8e3;
				}
			}
		}

		.list-paragraphe {
			text-align: left;
			padding: 10px;
			display: flex;
			align-items: center;
			text-transform: capitalize;

			a {
				color: #D47558;
				margin-left: 20px;
				transition: all .3s ease;

				&:hover {
					opacity: .7;
				}
			}

			svg {
				color: #D47558;
				margin:0 10px 0 0;
			}
		}

		.separator {
			margin: 0 auto;
			font-size: 30px;
			color: #D47558;
			width: 100%;
		}
	}
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(100% - 250px);
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(100% - 250px);
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {
		margin: 60px auto 80px;

        .right-wrapper {
            width: calc(100%);
			padding: 20px 20px;

			.financements-list {
				
				.drop-down-1 {
					padding: 15px;
					font-size: 16px;

					h4 {
						width: calc(50% - 15px);
					}

					.info-dyna {
						width: calc(50% - 15px);
						text-align: center;
					}
				}

				.formations-wrapper {
					.links-facture {
						.list-paragraphe {
							width: calc(100% - 100px);
							box-sizing: border-box;

							svg {
								display: none;
							}
						}
					}
				}
			}
        }
    }
}
`;

export default AccountFinancementStyled;