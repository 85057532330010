import { connect } from 'react-redux'

import ModalCourseInscription from '../../../components/ModalInscription/ModalCourseInscription'

import { 
    closeModal,
} from '../../../actions/modal'

import { 
    fetchDemandeFormation,
    setInscCollabStepOne,
    setModalInscLoaderOpen,
    saveFormations,
    redirectToMyFinancement,
    fetchFormations
} from '../../../actions/course'

const mapStateToProps = (state) => ({
    prenom: state.login.session.user.prenom,
    formations: state.course.formations,
    activCourseId: state.course.activCourseId,
    redirectToMyFinancement: state.course.redirectToMyFinancement,
    formationInfo: state.course.formationInfo
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: (name) => {
        dispatch(closeModal(name));
    },
    fetchDemandeFormation: (id, nom, duree) => {
        dispatch(fetchDemandeFormation(id, nom, duree));
    },
    setModalInscLoaderOpen: (bool) => {
        dispatch(setModalInscLoaderOpen(bool));
    },
    setInscCollabStepOne: (string) => {
        dispatch(setInscCollabStepOne(string));
    },
    saveFormations: (formation) => {
        dispatch(saveFormations(formation));
    },
    setRedirectToMyFinancement: (bool) => {
        dispatch(redirectToMyFinancement(bool));
    },
    fetchFormations: (paramObj) => {
        dispatch(fetchFormations(paramObj));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalCourseInscription)

