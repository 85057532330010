import { connect } from 'react-redux'

import ExcelFacture from '../../components/ExcelFacture'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ExcelFacture)