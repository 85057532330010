import { connect } from 'react-redux'

import AccountAdminCollabs from '../../pages/AccountAdminCollabs'

import { fetchFactureCollabs, changeClickedFactureCollabs } from '../../actions/apprenant'
import { fetchGetInscriptionsFromSessions } from '../../actions/course'

const mapStateToProps = (state) => ({
	factureCollabs: state.apprenant.factureCollabs,
	clickedFactureCollabs: state.apprenant.clickedFactureCollabs,
    session: state.login.session,
    inscriptionsClickedSession: state.course.inscriptionsClickedSession,
})

const mapDispatchToProps = (dispatch) => ({
    fetchFactureCollabs: () => {
        dispatch(fetchFactureCollabs());
    },
    changeClickedFactureCollabs: (factureId) => {
        dispatch(changeClickedFactureCollabs(factureId));
    },
    fetchGetInscriptionsFromSessions: (date, courseId, entrepriseId) => {
        dispatch(fetchGetInscriptionsFromSessions(date, courseId, entrepriseId));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountAdminCollabs)