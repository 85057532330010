import styled from 'styled-components';

const ModalCreateLMSSessionStyled = styled.div`
   position: fixed;
   z-index: 100000;
   background-color: #fff;
   padding: 15px;
   width: 100vw;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: rgba(0, 0, 0, .4);
   margin-top: -175px;

   .modal-content-wrapper {
      background-color: #fff;
      box-shadow: 5px 15px 10px #00000066;
      min-width: 315px; 
      width: 96%;
      max-width: 1250px;
      border-radius: 20px;
      min-height: 300px;
      padding: 20px 10px;

      .course-info-wrapper {
         margin-top: 20px;
      }
   }
`;

export default ModalCreateLMSSessionStyled;