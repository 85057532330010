/**** Import de modules ****/
import React, { useState, useEffect } from "react";
import useScrollPosition from "@react-hook/window-scroll";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  FaPhoneAlt,
  FaRegEnvelope,
  FaUserAlt,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

/**** Import d'images' ****/
import logo from "../../assets/img/logo/WEFOR_COULEUR.png";
import logoM from "../../assets/img/logo/logo-wefor.png";
import logoW from "../../assets/img/logo/WEFOR_COULEUR_BLANC-ai.png";
import phonePicto from "../../assets/img/picto/phone.png";
import contactPicto from "../../assets/img/picto/contact.png";
import accountPicto from "../../assets/img/picto/compte.png";
import accountwhite from "../../assets/img/picto/accountwhite.png";

/**** Import des components ou containers ****/
import JobLi from "../../containers/Header/JobLi";
import CourseLi from "../../containers/Header/CourseLi";
import AccountForm from "../../containers/Header/AccountForm";
import NavUser from "../../containers/Header/NavUser";

/**** Import des css ****/
import HeaderStyled from "./HeaderStyled";

const dialogApporteur = {
  width: "90%",
  maxWidth: "1300px",
};

const Header = ({
  isLogged,
  prenom,
  windowTop,
  user,
  isShowAccountFormMenu,
  setIsShowAccountFormMenu,
  changeCurrentProfilUser,
  userRole,
  bcgColor,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let locationAccount = useLocation().pathname.includes("/account");
  let comboHeaderColor = windowTop && !locationAccount;
  return (
    <HeaderStyled
      windowTop={windowTop}
      comboHeaderColor={comboHeaderColor}
      bcgColor={bcgColor}
    >
      <div className="wrapper-header-top">
        <div className="header-top">
          {userRole === "entreprise" ? (
            <Link to="/">
              <img
                src={comboHeaderColor ? logo : logo}
                className="header-logo pc"
                alt="logo carré wefor"
              />
              <img
                src={comboHeaderColor ? logoM : logoM}
                className="header-logo mobile"
                alt="logo carré wefor"
              />
            </Link>
          ) : (
            <Link to="/" onClick={() => changeCurrentProfilUser("notChoose")}>
              <img
                src={comboHeaderColor ? logo : logo}
                className="header-logo pc"
                alt="logo carré wefor"
              />
              <img
                src={comboHeaderColor ? logoM : logoM}
                className="header-logo mobile"
                alt="logo carré wefor"
              />
            </Link>
          )}
          <div className="menu-wrapper">
            <ul className="pictos-ul">
              <li className="rs-picto">
                <a
                  href="https://www.facebook.com/weforformationemploi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook />
                </a>
              </li>
              <li className="rs-picto">
                <a
                  href="https://www.instagram.com/wefor_formation_emploi/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              </li>
              <li className="rs-picto">
                <a
                  href="https://www.linkedin.com/company/wefor2022/?viewAsMember=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li className="mobile-none">
                <Link to="/contact">
                  <FaRegEnvelope className="" alt="picto contact" />
                  {/*<span className="mobile-none">Nous contacter</span>*/}
                </Link>
              </li>
              <li className="margin-left-20">
                <a
                  href="tel:+33185540178"
                  onClick={() => {
                    window.dataLayer.push({ event: "phone" });
                  }}
                >
                  <FaPhoneAlt className="menu-picto-phone" alt="picto phone" />
                  {/*<span className="bold phone">09 72 57 52 62</span>*/}
                </a>
              </li>
              {isShowAccountFormMenu && (
                <li className="account-li actif-class">
                  {isLogged && (
                    <>
                      <FaUserAlt
                        onClick={() => setIsShowAccountFormMenu(false)}
                        className="menu-picto"
                        alt="picto compte"
                      />
                      <span
                        className="bold prenom-header"
                        onClick={() => setIsShowAccountFormMenu(false)}
                      >
                        {user && user.prenom && user.prenom}
                      </span>
                      <span
                        className="apporteur-btn bold"
                        onClick={handleClickOpen}
                      >
                        Devenir apporteur
                      </span>
                    </>
                  )}
                  {!isLogged && (
                    <>
                      <FaUserAlt
                        onClick={() => setIsShowAccountFormMenu(false)}
                        className="menu-picto"
                        alt="picto compte"
                      />
                      <span onClick={() => setIsShowAccountFormMenu(false)}>
                        Mon compte
                      </span>
                      <span
                        className="apporteur-btn bold"
                        onClick={handleClickOpen}
                      >
                        Devenir apporteur
                      </span>
                    </>
                  )}
                  {isShowAccountFormMenu && <AccountForm />}
                </li>
              )}
              {!isShowAccountFormMenu && (
                <li
                  className="account-li"
                  onClick={() => setIsShowAccountFormMenu(true)}
                >
                  {isLogged && (
                    <>
                      <FaUserAlt className="menu-picto" alt="picto compte" />
                      <span className="bold capitalize prenom-header">
                        {user && user.prenom && user.prenom}
                      </span>
                      <span
                        className="apporteur-btn bold"
                        onClick={handleClickOpen}
                      >
                        Devenir apporteur
                      </span>
                    </>
                  )}
                  {!isLogged && (
                    <>
                      <FaUserAlt className="menu-picto" alt="picto compte" />
                      <span className="">Mon compte</span>
                      <span
                        className="apporteur-btn bold"
                        onClick={handleClickOpen}
                      >
                        Devenir apporteur
                      </span>
                    </>
                  )}
                  {isShowAccountFormMenu && <AccountForm />}
                </li>
              )}
            </ul>
            <div></div>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
        <DialogTitle>Devenir apporteur d'affaire WEFOR</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <NavUser />
    </HeaderStyled>
  );
};

Header.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  prenom: PropTypes.string.isRequired,
};

export default Header;
