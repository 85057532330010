/**** Import des modules ****/
import React, {useEffect} from 'react'
import TextField from '@mui/material/TextField';

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import ModalConnexion from '../../components/ModalInscription/ModalConnexion';

/**** Import des css ****/
import FormCourseStyled from './FormCourseStyled'

const FormCourse= ({isLogged}) => {

  return (
    <FormCourseStyled>
      <Header />
      {isLogged &&
        <form 
          className="form-wrapper"
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
        <h3 className="title">Merci de remplir notre questionnaire de satisfaction</h3>
        <p>Question 1</p>
        <TextField id="sat-one" className='textField' label="" variant="filled" />
        <p>Question 2</p>
        <TextField id="sat-two" className='textField' label="" variant="filled" />
        <p>Question 3</p>
        <TextField id="sat-three" className='textField' label="" variant="filled" />
        <p>Question 4</p>
        <TextField id="sat-four" className='textField' label="" variant="filled" />
        <input 
          type="submit" 
          value="Envoyer mes réponses" 
          className="btn-submit"
        />
      </form>
      }
      {!isLogged &&
      <div className="form-wrapper">
        <p className='center'>Pour remplir le formulaire merci de vous connecter</p>
        <ModalConnexion />
      </div>
      }
      <Footer />
    </FormCourseStyled>
  )
}

FormCourse.propTypes = {
}

export default FormCourse