import { connect } from 'react-redux'

import ModalCourseProg from '../../components/ModalCourseProg'

import { fetchThisJobOffer } from '../../actions/job'
import { openModal } from '../../actions/modal'
import { fetchGetCoursesLms } from '../../actions/lms'
import { fetchGetOneProgram } from '../../actions/course'

const mapStateToProps = (state) => ({
    formations: state.course.formations,
    activCourseId: state.course.activCourseId,
    userRole: state.login.userRole,
    templatesLms: state.lms.templatesLms,
    coursesLms: state.lms.coursesLms,
    jobInfo: state.job.jobInfo,
    activJobId: state.job.activJobId,
})

const mapDispatchToProps = (dispatch) => ({
    fetchThisJobOffer: (id) => {
        dispatch(fetchThisJobOffer(id));
    },
    openModal: (name) => {
        dispatch(openModal(name));
    },
    fetchGetCoursesLms: () => {
        dispatch(fetchGetCoursesLms());
    },
    fetchGetOneProgram: (id) => {
        dispatch(fetchGetOneProgram(id));
      },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalCourseProg)

