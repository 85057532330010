import { connect } from 'react-redux'

import FormCourse from '../../pages/FormCourse'

const mapStateToProps = (state) => ({
    isLogged: state.login.isLogged,
})

const mapDispatchToProps = (dispatch) => ({
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(FormCourse)