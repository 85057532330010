/**** Import de modules ****/
import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import PropTypes from "prop-types";

/**** Import d'images ****/
import logo from "../../assets/img/logo/WEFOR_COULEUR_BLANC-ai.png";
import certificat from "../../assets/pdf/certificat-qualiopi.pdf";

/**** Import des css ****/
import FooterStyled from "./FooterStyled";
import PdfProgramme from "../../pages/Home/pdfProgamme";

const Footer = ({
  isLogged,
  changeInputValue,
  fetchPostNewsletter,
  openModal,
}) => {
  return (
    <FooterStyled>
      <section className="footer-top">
        <div>
          <h3>Navigation</h3>
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            {!isLogged && (
              <li onClick={() => openModal("connexion")}>Connexion</li>
            )}
            {isLogged && (
              <li>
                <Link to="/account/home">Connexion</Link>
              </li>
            )}
            <li>
              <Link to="/contact">Nous contacter</Link>
            </li>
            <li>
              <Link to="/legal">Mentions légales</Link>
            </li>
            <li>
              <Link to="/conditions-generales-de-vente">
                Conditions Générales de Vente
              </Link>
            </li>
            <li>
              <Link to="/politique-de-confidentialite">
                Politique de confidentialité
              </Link>
            </li>
            <li>
              <Link to="/reglement-interieur">Règlement intérieur</Link>
            </li>
            <li>
              <PdfProgramme programme_pdf={certificat} />
            </li>
          </ul>
        </div>
        <div className="center-top">
          <h3>S'inscrire à la newsletter</h3>
          <p>
            Retrouvez toutes nos offres et notre actualité en vous inscrivant à
            notre newsletter
          </p>
          <form
            className="contact-form"
            onSubmit={(event) => {
              event.preventDefault();
              fetchPostNewsletter();
            }}
          >
            <input
              type="text"
              placeholder="Entrer votre Email"
              className="newsletter-txt"
              required
              name="emailnewsletter"
              onChange={(event) => {
                changeInputValue(event.target.value, event.target.name);
              }}
            />
            <input type="submit" value="Envoyer" className="newsletter-btn" />
          </form>
          <div className="rs-wrapper">
            <a
              href="https://www.facebook.com/weforformationemploi"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.instagram.com/wefor_formation_emploi/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/wefor2022/?viewAsMember=true"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>
        <div>
          <Link to="/">
            <img src={logo} className="footer-logo" alt="logo carré wefor" />
          </Link>
          <h3 className="border-top">Nous contacter</h3>
          <h4>Téléphone</h4>
          <p>Service commercial</p>
          <a
            href="tel:+33185540178"
            onClick={() => {
              window.dataLayer.push({ event: "phone" });
            }}
          >
            01 85 54 01 78
          </a>
          <p></p>
          <p>Du Lundi au Vendredi de 9h à 18h</p>
          <h4>Adresse</h4>
          <p>239 Ter, Avenue de la Division Leclerc</p>
          <p>92160 Antony</p>
        </div>
      </section>
      <section className="footer-bottom">
        Copyright ©2022 All rights reserved
      </section>
    </FooterStyled>
  );
};

Footer.propTypes = {
  isLogged: PropTypes.bool.isRequired,
};

export default Footer;
