export const SET_SUBMENU_JOB_SECTEUR_ACTIV = 'SET_SUBMENU_JOB_SECTEUR_ACTIV'
export const SET_SUBMENU_JOB_DOMAINE_ACTIV = 'SET_SUBMENU_JOB_DOMAINE_ACTIV'
export const SET_SUBMENU_JOB_FORMATION_ACTIV = 'SET_SUBMENU_JOB_FORMATION_ACTIV'
export const SET_SUBMENU_COURSE_SECTEUR_ACTIV = 'SET_SUBMENU_COURSE_SECTEUR_ACTIV'
export const SET_SUBMENU_COURSE_DOMAINE_ACTIV = 'SET_SUBMENU_COURSE_DOMAINE_ACTIV'
export const SET_SUBMENU_COURSE_FORMATION_ACTIV = 'SET_SUBMENU_COURSE_FORMATION_ACTIV'
export const CHANGE_CURRENT_DOMAINE_CLICKED = 'CHANGE_CURRENT_DOMAINE_CLICKED'
export const CHANGE_CURRENT_PROFIL_USER = 'CHANGE_CURRENT_PROFIL_USER'
export const FETCH_SECTEURS = 'FETCH_SECTEURS';
export const SAVE_SECTEURS = 'SAVE_SECTEURS';
export const FETCH_DOMAINES = 'FETCH_DOMAINES';
export const SAVE_DOMAINES = 'SAVE_DOMAINES';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_ID = 'SET_FILTER_ID';
export const SET_IS_SHOW_MENU = 'SET_IS_SHOW_MENU'
export const SET_IS_SHOW_ACCOUNT_FORM_MENU = 'SET_IS_SHOW_ACCOUNT_FORM_MENU'
export const SET_MENU_DOCUMENT = 'SET_MENU_DOCUMENT';
export const SET_MENU_FORMATION = 'SET_MENU_FORMATION';
export const FETCH_FICHE = 'FETCH_FICHE';
export const SAVE_FICHE = 'SAVE_FICHE';
export const SET_CURRENT_URL = 'SET_CURRENT_URL'


export const setSubmenuJobSecteurActiv = (name) => ({
    type: SET_SUBMENU_JOB_SECTEUR_ACTIV,
    name
})

export const setSubmenuJobDomaineActiv = (name) => ({
    type: SET_SUBMENU_JOB_DOMAINE_ACTIV,
    name
})

export const setSubmenuJobFormationActiv = (name) => ({
    type: SET_SUBMENU_JOB_FORMATION_ACTIV,
    name
})

export const setSubmenuCourseSecteurActiv = (name) => ({
    type: SET_SUBMENU_COURSE_SECTEUR_ACTIV,
    name
})

export const setSubmenuCourseDomaineActiv = (name) => ({
    type: SET_SUBMENU_COURSE_DOMAINE_ACTIV,
    name
})

export const setSubmenuCourseFormationActiv = (name) => ({
    type: SET_SUBMENU_COURSE_FORMATION_ACTIV,
    name
})

export const changeCurrentDomaineClicked = (id) => ({
    type: CHANGE_CURRENT_DOMAINE_CLICKED,
    id
})

export const changeCurrentProfilUser = (name) => ({
    type: CHANGE_CURRENT_PROFIL_USER,
    name
})

export const fetchSecteurs = () => ({
    type: FETCH_SECTEURS,
});
  
export const saveSecteurs = (secteurs) => ({
    type: SAVE_SECTEURS,
    secteurs,
});

export const fetchDomaines = () => ({
    type: FETCH_DOMAINES,
});
  
export const saveDomaines = (domaines) => ({
    type: SAVE_DOMAINES,
    domaines,
});

export const setFilter = (name, title) => ({
    type: SET_FILTER,
    name,
    title,
});

export const setFilterId = (name, title) => ({
    type: SET_FILTER_ID,
    name,
    title,
});

export const setIsShowMenu = (bool) => ({
    type: SET_IS_SHOW_MENU,
    bool,
});

export const setIsShowAccountFormMenu = (bool) => ({
    type: SET_IS_SHOW_ACCOUNT_FORM_MENU,
    bool,
});

export const setMenuDocument = (value) => ({
    type: SET_MENU_DOCUMENT,
    value,
});

export const setMenuFormation = (value) => ({
    type: SET_MENU_FORMATION,
    value,
});

export const fetchFiche = () => ({
    type: FETCH_FICHE,
});
  
export const saveFiche = (data) => ({
    type: SAVE_FICHE,
    data,
});

export const setCurrentUrl = (string) => ({
    type: SET_CURRENT_URL,
    string,
});