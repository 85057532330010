import axios from 'axios'
import * as qs from 'qs'
import { toast } from 'react-toastify';
import moment from 'moment'

import urlBackEnd from '../assets/js/urlBackEnd';

import 'react-toastify/dist/ReactToastify.css';

import { 
  FETCH_INSCRIPTION_USER_LMS,
  FETCH_CREATE_SESSION_LMS,
  fetchCreateSessionLms,
  FETCH_PUT_USER_ON_SESSION,
  fetchPutUserOnSession,
  FETCH_USER_SCORE,
  saveUserScore,
  FetchPostOrPutScore,
  FETCH_POST_OR_PUT_SCORE,
  saveTemplatesLms,
  FETCH_GET_COURSES_LMS,
  saveCoursesLms,
  fetchUserScore,
  FETCH_GET_USER_LMS,
  saveUserLmsData
} from '../actions/lms';

import { changeStepValue, FETCH_GET_ONE_PROGRAM } from '../actions/course';
import { setFullPageLoader } from '../actions/actions';

import { 
  fetchUser,
  setLoadConnexion
} from '../actions/login';

// const URL = "http://localhost:1338/api/";
const URL360LEARNING = "https://app.360learning.com/api/v1/";
const API360LEARNING = "f96ac09c72064626beb2a2fce44f30b3"
const ID360LEARNING = "62457cdc53413c65bea4c62c"
const GROUP306LEARNING = "62457cdc53413c65bea4c62e"
const URL = urlBackEnd;

const lmsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    /*case FETCH_INSCRIPTION_USER_LMS: {
		
		const state = store.getState();
		const formationLmsId = state.course.factureP.find( f => f.id === state.course.clickedFacture).attributes.formation.data.attributes.lms_formation_id


		const optionsGetUser = {
			method: 'GET',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			url: `${URL360LEARNING}users/${state.login.session.user.email}?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
		  };
        axios(optionsGetUser)
        .then((response) => {
			store.dispatch(fetchCreateSessionLms(1, formationLmsId))
			store.dispatch(changeStepValue(4))
          	store.dispatch(fetchUser())
        })
        .catch((error) => {
          console.error(error);
		  const data = { 
			company: ID360LEARNING,
			apiKey: API360LEARNING,
			firstName: state.login.session.user.prenom,
			lastName: state.login.session.user.nom,
			mail: state.login.session.user.email,
			lang: 'fr',
			groups: [GROUP306LEARNING], 
			};
			const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
				data: qs.stringify(data),
				url: `${URL360LEARNING}users?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
			};
			axios(options)
			.then((response) => {
			})
			.catch((error) => {
			console.error(error);
			});
			});
      next(action);
      break;
    }
	case FETCH_CREATE_SESSION_LMS: {
		const state = store.getState();
		
		const formationLmsName = state.course.factureP.find( f => f.id === state.course.clickedFacture).attributes.formation.data.attributes.nom
		const data = { 
			"startDate": moment().format(),
			"endDate": moment().add((action.duree / 30), 'months').format(),
			"tutors": [
				"contact@wefor.fr"
			],
			"name": `${formationLmsName}`,
			"userLimit": 1,
			"sendAutomatedReminders": true,
		};
		const options = {
			method: 'POST',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data: qs.stringify(data),
			url: `${URL360LEARNING}programs/${action.templateId}/sessions/?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
		  };
        axios(options)
        .then((response) => {
          store.dispatch(fetchPutUserOnSession(response.data._id))
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }*/
	case FETCH_PUT_USER_ON_SESSION: {
		const state = store.getState();
		const options = {
			method: 'PUT',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			url: `${URL360LEARNING}programs/sessions/${action.sessionId}/users/contact@wefor.fr?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
		  };
        axios(options)
        .then((response) => {
			store.dispatch(fetchUserScore())
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
	case FETCH_USER_SCORE: {
		const state = store.getState();
		const email = state.login.session.user.email
		const options = {
			method: 'GET',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			url: `${URL360LEARNING}users/${email}/programs?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
		  };
        axios(options)
        .then((response) => {
			store.dispatch(saveUserScore(response.data))
			store.dispatch(FetchPostOrPutScore())
			store.dispatch(fetchUser())
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
	case FETCH_GET_ONE_PROGRAM: {
		store.dispatch(saveTemplatesLms(false))
		const state = store.getState();
		let templatesForm = []

		const options = {
			method: 'GET',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			url: `${URL360LEARNING}programs/templates/${action.id}?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
		  };
        axios(options)
        .then((response) => {
			store.dispatch(saveTemplatesLms(response.data))
        })
        .catch((error) => {
			store.dispatch(saveTemplatesLms(false))
          	console.error(error);
        });
      next(action);
      break;
    }
	case FETCH_GET_COURSES_LMS: {
		store.dispatch(saveCoursesLms(false))
		
		const options = {
			method: 'GET',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			url: `${URL360LEARNING}courses?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
		  };
        axios(options)
        .then((response) => {
			store.dispatch(saveCoursesLms(response.data))
        })
        .catch((error) => {
          	console.error(error);
        });
      next(action);
      break;
    }
	case FETCH_POST_OR_PUT_SCORE: {
		const state = store.getState();
		const onCoursesArray = state.login.userInfo.data[0].attributes.en_cours_formations.data
		const userScoresArray = state.lms.userScore
		const scoreFormationData = state.login.userInfo.data[0].attributes.score_formations.data
		const scoreMatchingArray = []		
		onCoursesArray.map((course) => {
			const score = userScoresArray.slice().reverse().find(score => score.programTemplate === course.attributes.lms_formation_id)

			if (score !== undefined) {
				scoreMatchingArray.push({id: course.id, score: score})
			}
		})
		scoreMatchingArray.map((score) => {
			const dataScore = {
				data: {
					progress: score.score.stats.progress,
					score: score.score.stats.score,
					global_time: score.score.stats.globalTime,
					particulier: state.login.userInfo.data[0].id,
					formation: score.id,
					lastActivity: score.score.modifiedAt,
					firstActivity: score.score.startDate,
					session_id: score.score._id
				}
			}
			if (scoreFormationData === null) {
				const options = {
					method: 'POST',
					headers: {
						 'content-type': 'application/x-www-form-urlencoded',
						 Authorization:`Bearer ${state.login.session.jwt}`
						},
					data: qs.stringify(dataScore),
					url: `${URL}score-formations`,
				};
				axios(options)
				.then((response) => {
					

				})
				.catch((error) => {
					console.error(error);
				  })
			} else {
				const alreadyExist = scoreFormationData.find(scoreData => scoreData.attributes.formation.data.id === score.id)
				if (alreadyExist === undefined) {
					const options = {
						method: 'POST',
						headers: {
							 'content-type': 'application/x-www-form-urlencoded',
							 'Authorization':`Bearer ${state.login.session.jwt}`
							},
						data: qs.stringify(dataScore),
						url: `${URL}score-formations`,
					};
					axios(options)
					.then((response) => {
						
	
					})
					.catch((error) => {
						console.error(error);
					  })
				} else {

					const options = {
						method: 'PUT',
						headers: {
							 'content-type': 'application/x-www-form-urlencoded',
							 'Authorization':`Bearer ${state.login.session.jwt}`
							},
						data: qs.stringify(dataScore),
						url: `${URL}score-formations/${alreadyExist.id}`,
					};
					axios(options)
					.then((response) => {
						
	
					})
					.catch((error) => {
						console.error(error);
					  })
				}
			}
		})
		/*
		const options = {
			method: 'GET',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			url: `${URL360LEARNING}users/${email}/programs?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
		  };
        axios(options)
        .then((response) => {
			store.dispatch(saveUserScore(response.data))
			store.dispatch(FetchPostOrPutScore())
        })
        .catch((error) => {
          console.error(error);
        })*/
      next(action);
      break;
    }
	case FETCH_GET_USER_LMS: {
		const state = store.getState();
		if (state.login.isLogged) {
			const userEmail = state.login.session.user.email
			const options = {
				method: 'GET',
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
				url: `${URL360LEARNING}users/${userEmail}?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
			};
			axios(options)
			.then((response) => {
				store.dispatch(saveUserLmsData(response.data))
			})
			.catch((error) => {
				store.dispatch(saveUserLmsData('error'))
			});
		}
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default lmsMiddleware;