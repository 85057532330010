export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CHANGE_FORM_MODAL = 'CHANGE_FORM_MODAL'
export const TOGGLE_MODAL_MESSAGE = 'TOGGLE_MODAL_MESSAGE'
export const TOGGLE_MODAL_SIGNATURE = 'TOGGLE_MODAL_SIGNATURE'
export const TOGGLE_MODAL_CONVENTION = 'TOGGLE_MODAL_CONVENTION'
export const TOGGLE_MODAL_RECRUTEMENT = 'TOGGLE_MODAL_RECRUTEMENT'
export const TOGGLE_MODAL_SATISFACTION = 'TOGGLE_MODAL_SATISFACTION'

export const openModal = (name) => ({
    type: OPEN_MODAL,
    name
})

export const closeModal = (name) => ({
    type: CLOSE_MODAL,
    name
})

export const changeFormModal = (string) => ({
    type:CHANGE_FORM_MODAL,
    string
})

export const toggleModalMessage = (message, bool) => ({
    type: TOGGLE_MODAL_MESSAGE,
    message,
    bool
})

export const toggleModalSignature = (bool, docId, docType, docObject) => ({
    type: TOGGLE_MODAL_SIGNATURE,
    bool,
    docId,
    docType,
    docObject
})

export const toggleModalConvention = (bool, entrepriseId, docType) => ({
    type: TOGGLE_MODAL_CONVENTION,
    bool,
    entrepriseId,
    docType,
})

export const toggleModalRecrutement = (bool) => ({
    type: TOGGLE_MODAL_RECRUTEMENT,
    bool,
})

export const toggleModalSatisfaction = (bool) => ({
    type: TOGGLE_MODAL_SATISFACTION,
    bool,
})