/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate, useLocation, Navigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import { Skeleton } from '@mui/material'
import { AiFillCaretDown } from "react-icons/ai"
import { MdDeleteForever } from "react-icons/md"
import PropTypes from 'prop-types'
import { BsFillGridFill } from "react-icons/bs"
import { FaList, FaHandPointRight } from "react-icons/fa"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import CourseCardListLayout from '../../containers/CourseCardListLayout'
import PaginationMui from '@mui/material/Pagination'
import ReactMarkdown from 'react-markdown'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import SeoHelmet from '../../components/SeoHelmet'
import SearchCourseByKeyBis from '../../containers/SearchCourseByKeyBis'
import CourseCard from '../../containers/CourseCard'

/**** Import des images ****/
import logo from '../../assets/img/illustration/femme-desk.png'
import poucegreen from '../../assets/img/illustration/poucegreen.png'
import timeyellow from '../../assets/img/illustration/timeyellow.png'
import cpficon from '../../assets/img/logo/WEFOR_CARRE_COULEUR.png'


/**** Import des css ****/
import FormationsByKeyStyled from './FormationsByKeyStyled'

const FormationsByKey = ({currentProfilUser, getFormationFromParams, formationFromParams, fetchFormations, secteurCourseFilter, filterSecteurId, setFilter, setFilterId, secteurs, cpfCourseFilter, filterCpfId, saveSlugs, fullSlugCourse, isLogged, activCourseId, formationsPagination, userRole, openModal, templatesLms, coursesLms, slugSecteur, slugKeyword, slugFormation, setMuiCourseFilterKeyValue, saveFormationFromParams, setFullSlugCourse}) => {

	let { keyword } = useParams()
	const navigate = useNavigate()
	let location = useLocation().pathname
	let locationSearch = useLocation()
	useEffect(()=> {
		saveSlugs('slugKeyword', `/${keyword}`)
	}, [])

	useEffect(() => {

		if (location.includes('/formations') && slugSecteur !== '' && slugKeyword === '' && slugFormation === '') {
		  setFullSlugCourse(`/formations${slugSecteur}`)
		  navigate(`/formations${slugSecteur}`)
	
		  // ok
		}
		if (location.includes('/formations') && slugSecteur === '' && slugKeyword !== '' && slugFormation === '') {
		  setFullSlugCourse(`/formations/mot-cle${slugKeyword}`)
		  navigate(`/formations/mot-cle${slugKeyword}`)
	
		  // ok
		}
		if (location.includes('/formations') && slugSecteur !== '' && slugKeyword !== '' && slugFormation === '') {
		  setFullSlugCourse(`/formations${slugSecteur}/mot-cle${slugKeyword}`)
		  navigate(`/formations${slugSecteur}/mot-cle${slugKeyword}`)
	
		  // ok
		}
		if (location.includes('/formations') && slugSecteur !== '' && slugKeyword === '' && slugFormation !== '') {
		  setFullSlugCourse(`/formations${slugSecteur}${slugFormation}`)
		  navigate(`/formations${slugSecteur}${slugFormation}`)
	
		  // ok
		}
		if (location.includes('/formations') && slugSecteur === '' && slugKeyword === '' && slugFormation === ''/* && !location.includes('/formations/')*/) {
		  setFullSlugCourse('/formations')
		  navigate('/formations')
		  // ok
		}
		
	  }, [slugSecteur, slugKeyword, slugFormation]);

	useEffect(()=> {
		setMuiCourseFilterKeyValue({title: keyword})
	}, [keyword])

	let { idcourse } = useParams()
	const hasWindow = typeof window !== 'undefined';

	function getWindowDimensions() {
		const width = hasWindow ? window.innerWidth : null;
		const height = hasWindow ? window.innerHeight : null;
		return {
		width,
		height,
		}
	}

	const [modalJobFicheOpen, setModalJobFicheOpen] = useState(false);
	const [btnFilterSecteurOnOff, setBtnFilterSecteurOnOff] = useState(false);
	const [btnFilterDomaineOnOff, setBtnFilterDomaineOnOff] = useState(false);
	const [btnFilterFormationOnOff, setBtnFilterFormationOnOff] = useState(false);
	const [btnFilterNiveauOnOff, setBtnFilterNiveauOnOff] = useState(false);
	const [btnFilterCPFOnOff, setBtnFilterCPFOnOff] = useState(false);
	const [activLayout, setActivLayout] = useState('list')
	const [cardPerPage, setCardPerPage] = useState(10)
	const [indexOfLastCard, setIndexOfLastCard] = useState(cardPerPage)
	const [indexOfFirstCard, setIndexOfFirstCard] = useState(0)
	const [page, setPage] = useState(1)
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	let nbOfCard = Math.round(windowDimensions.width / 450 + 0)

	const handleChangePagination = (event, value) => {   
		setPage(value)
		changePagesCards(value)
		const paramObj = {page: 'formations' ,intemNb: 10, pageNb: value}
		fetchFormations(paramObj)
		window.scrollTo(0, 250);
	};

	const changePagesCards = (value) => {
		setIndexOfLastCard(value * cardPerPage)
		setIndexOfFirstCard(value * cardPerPage - cardPerPage)
	}

	useEffect(()=> {
		if (formationFromParams.data.length === 0) {
			const paramObj = {page: 'formations' ,intemNb: 10, pageNb: 1, courseId : idcourse === undefined ? false : Number(idcourse)}
			getFormationFromParams(false, false, keyword, paramObj)
		} else {
			if(locationSearch.search.includes('?dda')) {
			setFilter(3, 'domaineCourse') 
			const paramObj = {page: 'formations' ,intemNb: 10, pageNb: 1, courseId : idcourse === undefined ? false : Number(idcourse)}
			getFormationFromParams(false, false, keyword, paramObj)
			}
		}
	}, [isLogged, activCourseId, slugSecteur, slugKeyword, slugFormation])

	const handleChildElementClick = (e) => {
		e.stopPropagation()
		// Do other stuff here
	}

	const cpfData = [
		{
		id: 1,
		nom: 'Éligible CPF'
		},
		{
		id: 2,
		nom: 'Non éligible CPF'
		}
	]

	let formation = () => {
		if (formationFromParams.status === 'success' && activCourseId) {
		let formation = formationFromParams.data.find(item => item.id === activCourseId);
		return formation;
		}
	}

	let euroFormat = new Intl.NumberFormat('fr-FR', {
		style: 'currency',
		currency: 'EUR',
		maximumFractionDigits: 0,
	});

	return (
		<FormationsByKeyStyled>
      	<Header bcgColor={'#d47558'}/>
				{/* Condition à helmet */
			<SeoHelmet 
			title={`Recherche formation : ${keyword}`}
			description={`Retrouvez toutes nos formations en lien avec le mot clé suivant: ${slugKeyword}`}
			keywords={`Wefor, formations, cpf, opco, reconvertion, liste de formation, mot clé, ${keyword}`}
			canonical={`https://www.wefor.fr/formations/mot-cle${slugKeyword}`}
			jsonSchema={{
				"@context": "https://schema.org/",
				"@type": "WebPage",
				"mainEntity": {
				  "@type": "Keyword",
				  "name": "Mot-clé principal"
				},
				"potentialAction": {
				  "@type": "SearchAction",
				  "target": `https://www.wefor.fr/formations/mot-cle${slugKeyword}`,
				  "query-input": "required keyword=search_term_string"
				}
			  }}
			/>
		}
		<section className="top-illustration">
			<div className="opacity-light-section">
			<img src={logo} alt='logo wefor'/>
			<div className="left">
				<h1 className="bold">Nos formations</h1>
				{currentProfilUser === "Demandeur d'emploi" && 
				<>
				<p>Vous recherchez une formation très opérationnelle avec des <span className="bold">offres d’emploi à la clé</span> ? Découvrez les formations Wefor !</p>
				<p>Wefor vous propose des formations en ligne, accessibles 7 jours sur 7 et 24h sur 24, sur une <span className="bold">plateforme pédagogique</span> ludique et performante.
				</p>
				</>
				}
				{currentProfilUser === "Salarié" && 
				<>
				<p>Vous visez une promotion, une mobilité interne ou une reconversion ? Découvrez les formations Wefor !</p>
				<p>Wefor s’adapte aux contraintes des salariés en leur proposant des <span className="bold">formations flexibles</span>, accessibles 7 jours sur 7 et 24h sur 24, sur une plateforme pédagogique ludique et performante.</p>
				</>
				}
				{currentProfilUser === "Entreprise" && 
				<>
				<p>Vous souhaitez former vos collaborateurs ? Découvrez les formations Wefor. Régulièrement adaptées pour être en phase avec le marché et vos besoins en compétences, elles vous permettent de <span className="bold">booster l’efficacité de vos collaborateurs</span> rapidement !</p>
				<p>Pour la garantie d’une prestation de qualité, nos formations sont éligibles aux dispositifs de financement.</p>
				</>
				}
				{currentProfilUser === "Autres" && 
				<>
				<p>Vous recherchez une formation très opérationnelle pour vous reconvertir ou <span className="bold">évoluer professionnellement</span> ? Découvrez les formations Wefor !</p>
				<p>Wefor vous propose des formations compatibles avec une activité professionnelle, accessibles 7 jours sur 7 et 24h sur 24, sur une <span className="bold">plateforme pédagogique</span> ludique et performante.</p>
				</>
				}
				{currentProfilUser === "notChoose" &&
				<>
				<p>Vous recherchez une formation très opérationnelle ou bien une reconversion professionnelle avec des <span className="bold">offres d’emploi à la clé</span> ? Découvrez les formations Wefor !</p>
				<p>Wefor vous propose des formations en ligne, accessibles 7 jours sur 7 et 24h sur 24, sur une <span className="bold">plateforme pédagogique</span> ludique et performante.
				</p>
				</>
				}
			</div>
			</div>
		</section>
		{formationFromParams.status === 'loading' && 
			<section className='skeleton-page'>
				<div className='filter'>
					<Skeleton variant="rectangular" className='filter-item' width={250} height={40} sx={{borderRadius: '8px'}}/>
					<Skeleton variant="rectangular" className='filter-item' width={250} height={40} sx={{borderRadius: '8px'}}/>
					<Skeleton variant="rectangular" className='filter-item' width={250} height={40} sx={{borderRadius: '8px'}}/>
				</div>
				<div className='carre'>
					<Skeleton variant="rectangular" width={25} height={25} sx={{marginRight: '10px', borderRadius: '5px'}}/>
					<Skeleton variant="rectangular" width={25} height={25} sx={{marginRight: '10px', borderRadius: '5px'}}/>
				</div>
				<div className='page'>
					<div className='left'>
						<Skeleton variant="rectangular" className='cards' sx={{margin: '50px 0 0 10px', borderRadius: '30px'}} />
						<Skeleton variant="rectangular" className='cards' sx={{margin: '50px 0 0 10px', borderRadius: '30px'}} />
						<Skeleton variant="rectangular" className='cards' sx={{margin: '50px 0 0 10px', borderRadius: '30px'}} />
						<Skeleton variant="rectangular" className='cards' sx={{margin: '50px 0 0 10px', borderRadius: '30px'}} />
						<Skeleton variant="rectangular" className='cards' sx={{margin: '50px 0 0 10px', borderRadius: '30px'}} />
					</div>
					<div className='right'>
						<Skeleton className="test" variant="rectangular" />
						<Skeleton variant="rectangular" className='content' />
					</div>
				</div>
			</section>
		}
		{formationFromParams.status === 'error' && 
			<Navigate replace to="*" />
		}
		{formationFromParams.status === 'success' && formation() !== undefined &&
			<>
			<section className="filter-formation">
				<div className="wrapper-filter">
				<div 
					className={btnFilterSecteurOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
					onClick={() => setBtnFilterSecteurOnOff(!btnFilterSecteurOnOff)}
				>
				{secteurCourseFilter !== "" ?
					<span className='filter'>
					{filterSecteurId} <MdDeleteForever onClick={(e) => {
						saveFormationFromParams('loading', [])
						setFilter('', 'secteurCourse') 
						setFilterId('', 'filterSecteurId')
						saveSlugs('slugSecteur', '')
						handleChildElementClick(e)
						}} />
					</span>
					:
					<>
					Secteurs 
					<AiFillCaretDown/>
					</>
				}
					<ul className={btnFilterSecteurOnOff ? "show-menu" : ""}>
					{secteurs.filter(secteur => secteur.attributes.formations.data.length > 0).map((secteur) => (
						<li 
						key={secteur.id}
						onClick={() => {
							saveFormationFromParams('loading', [])
							saveSlugs('slugSecteur', `/${secteur.attributes.slug}`)
							saveSlugs('slugFormation', '')
							setFilter(secteur.id, 'secteurCourse') 
							setFilterId(secteur.attributes.nom, 'filterSecteurId')
						}}
						>
						{secteur.attributes.nom}
						</li>
					))}
					</ul>
				</div>
				<div 
					className={btnFilterCPFOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
					onClick={() => setBtnFilterCPFOnOff(!btnFilterCPFOnOff)}
				>
				{cpfCourseFilter !== "" ?
					<span className='filter'>
					{filterCpfId} <MdDeleteForever onClick={(e) => {
						saveFormationFromParams('loading', [])
						saveSlugs('slugFormation', '')
						setFilter('', 'cpfCourse') 
						setFilterId('', 'filterCpfId')
						handleChildElementClick(e)
						}} />
					</span>
					:
					<>
					Financement
					<AiFillCaretDown/>
					</>
				}
					<ul className={btnFilterCPFOnOff ? "show-menu" : ""}>
					{cpfData.map((item) => (
						<li 
						key={item.id}
						onClick={() => {
							saveFormationFromParams('loading', [])
							saveSlugs('slugFormation', '')
							setFilter(item.id, 'cpfCourse') 
							setFilterId(item.nom, 'filterCpfId')
						}}
						>
						{item.nom}
						</li>
					))}
					</ul>
				</div>
				<SearchCourseByKeyBis className="btn-key" fetchFormations={fetchFormations} secteurCourseFilter={secteurCourseFilter}/>
				</div>
			</section>
		
			<section className="user-filter-section">
			<BsFillGridFill 
			className={`layout-btn ${activLayout === 'grid' ? 'active-layout' : ''}`} 
			onClick={() => {
				setActivLayout('grid')
				setCardPerPage(10)
				setIndexOfLastCard(10)
				setIndexOfFirstCard(0)
				setPage(1)
			}}
			/>
			<FaList 
			className={`layout-btn ${activLayout === 'list' ? 'active-layout' : ''}`}
			onClick={() => {
				setActivLayout('list')
				setCardPerPage(10)
				setIndexOfLastCard(10)
				setIndexOfFirstCard(0)
				setPage(1)
			}}
			/>
			</section>
			<main className="course-main">
			<section className="course-list-left mobile">
				<Swiper
				slidesPerView={nbOfCard}
				pagination={{
					clickable: true,
				}}
				modules={[Pagination]}
				className="mySwiper slider-mobile"
				>
				{formationFromParams.status === 'success' && formationFromParams.data.map((formation) => (
					<SwiperSlide key={formation.id}>
					{({ isActive }) => (
						<>
						<CourseCard className="display-buttons" isActive={isActive} courseId={formation.id} {...formation}/>
						</>
					)}
					</SwiperSlide>
				))}
				</Swiper>
				</section>
				<section className={`course-list-left computer ${activLayout === 'list' ? 'column' : ''}`}>
				{formationFromParams.status === 'success' && formationFromParams.data.map((formation) => (
				<div key={formation.id} className={`card-wrapper ` + (activLayout === 'list' ? 'fullwidth' : '')}>
					{activLayout === 'list' && 
					<CourseCardListLayout key={formation.id} courseId={formation.id} {...formation}/>
					}
					{activLayout === 'grid' && 
					<CourseCard key={formation.id} courseId={formation.id} {...formation}/>
					}
				</div>
				))
				}
				{formationFromParams.status === 'success' && Object.keys(formationsPagination).length > 0 && 
				<PaginationMui page={formationsPagination.page} className="pagination-pc" count={Math.ceil(formationsPagination.pageCount)} variant="outlined" color="primary" onChange={handleChangePagination}/>
				}
				</section>
				{formationFromParams.status === 'success' && formationFromParams.data.length > 0 &&
				<div className="course-info-right">
				<div className="info-wrapper">
					<div className="card-orange">
					<div className="row bold">
					E-LEARNING
					</div>
					<div className="row">
						<h3 className="bold">{formation().attributes.nom}</h3>
						<h6 className="bold">{formation().attributes.objectifs}</h6>
					</div>
					</div>
					<div className="comp-wrapper">
					<img src={poucegreen} alt="picto euro bleu" />
					<div className="txt-wrapper">
						<h3 className="bold">Prérequis</h3>
						<ReactMarkdown>{formation().attributes.prerequis}</ReactMarkdown>
					</div>
					</div>
					<div className="price-time-wrapper">
					<div className="time-wrapper">
						<img src={timeyellow} alt="picto euro bleu" />
						<div className="paragraphe-wrapper">
						<p className="bold txt">Durée de la formation :</p>
						<p className="bold time">{formation().attributes.duree}h</p>
						</div>
					</div>
					<div className="separator-orange"></div>
					<div className="price-wrapper">
						{formation().attributes.cpf_link !== null && formation().attributes.cpf_link !== '' && 
						<a href={formation().attributes.cpf_link} target='_blank' rel='noreferrer'>
						<img src={cpficon} alt="picto mon compte de formation cpf" />
						<span className='link-arrow bold'>Lien CPF <FaHandPointRight /></span>
						</a>
						}
						<p className="bold price-dotted-cont">
						<span className={formation().attributes.nom.includes('DDA') ? 'bold' : 'bold'}>{euroFormat.format(formation().attributes.prix)}</span>
						<span className='net'> net de TVA*</span>
						<span className='disclaimer'>(* TVA non applicable selon l’article 293 B du Code Général des Impôts)</span>
						{/*formation().attributes.nom.includes('DDA') && 
							<span className='prix-dotted bold'> 990 € </span>
						*/ }
						</p>
					</div>
					</div>
					{userRole === "entreprise" ? 
					<button 
					className="btn-orange-fullwidth bold"
					onClick={() => openModal("inscription-collab")}
					>Inscrire un collaborateur</button>
					:
					<button 
					className="btn-orange-fullwidth bold"
					onClick={() => openModal("formation")}
					>S'inscrire</button>
					}
					{/* ici les modales */}
					{formation().attributes.modalites_evaluation !== null ?
					<div className="programme-disclaimer">
					<ReactMarkdown>{formation().attributes.modalites_evaluation}</ReactMarkdown>
					</div>
					:
					<div className="programme-disclaimer">Vous allez découvrir des pages comportant des apports de connaissances, qui seront suivies, page après page, d'activités pédagogiques de validation de vos acquis (une page de contenu, suivie d'une activité pédagogique, suivie d'une nouvelle page de contenu, suivi d'une autre activité pédagogique, etc.)<div className="bold">Modalités d'évaluation des objectifs : quizz, vidéo de présentation, études de cas avec questionnaires, examen final.</div></div>
				}
				{formation().attributes.competences_acquises !== null &&
					<div className="programme-disclaimer">
					<h4 className="bold">Compétences acquises</h4>
					<ReactMarkdown>{formation().attributes.competences_acquises}</ReactMarkdown>
					</div>
				}
				{formation().attributes.definition !== null &&
					<div className="programme-disclaimer">
					<h4 className="bold">Programme</h4>
					<ReactMarkdown className="def-markdown">
						{formation().attributes.definition}
					</ReactMarkdown>
					</div>
				}
					{templatesLms.length > 0 && templatesLms.find(template => template._id === formation().attributes.lms_formation_id) &&templatesLms.find(template => template._id === formation().attributes.lms_formation_id).elements.map((module, index) => (
					<div key={index}>
					{ !module.type.includes('certification') && !module.title.includes('Examen') && !module.title.includes('entraînement') && !module.title.includes('approfondissement') && !module.title.includes('Validation') && !module.title.includes('Exercices pratiques') && !module.title.includes(formation().attributes.nom.toLowerCase().substring(1, 20).toLowerCase()) &&
					<div>
						<h4 className="module-title bold">{module.title.substr(9)}</h4>
						<div className="modules-wrapper" dangerouslySetInnerHTML={ {__html :coursesLms.find(moduleLms => module._id === moduleLms._id) !== undefined ? coursesLms.find(moduleLms => module._id === moduleLms._id).description : ''} }></div>
					</div>
					}
					</div>
					))}
					<div className="full-width">
					</div>
					<div className="button-wrapper">
					{userRole === "entreprise" ? 
					<button 
					className="btn-orange"
					onClick={() => openModal("inscription-collab")}
					>Inscrire un collaborateur</button>
					:
					<button 
					className="btn-orange"
					onClick={() => openModal("formation")}
					>S'inscrire</button>
					}
					<button className="btn-bluelight" onClick={() => setModalJobFicheOpen(true)}>Fiche métier</button>
					</div>
				</div>
				</div>
				}

			</main>
			</>
		}
		{formationFromParams.status === 'success' && formationFromParams.data.length === 0 &&
			<>
				<section className="filter-formation">
				<div className="wrapper-filter">
				<div 
					className={btnFilterSecteurOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
					onClick={() => setBtnFilterSecteurOnOff(!btnFilterSecteurOnOff)}
				>
				{secteurCourseFilter !== "" ?
					<span className='filter'>
					{filterSecteurId} <MdDeleteForever onClick={(e) => {
						saveFormationFromParams('loading', [])
						setFilter('', 'secteurCourse') 
						setFilterId('', 'filterSecteurId')
						saveSlugs('slugSecteur', '')
						handleChildElementClick(e)
						}} />
					</span>
					:
					<>
					Secteurs 
					<AiFillCaretDown/>
					</>
				}
					<ul className={btnFilterSecteurOnOff ? "show-menu" : ""}>
					{secteurs.filter(secteur => secteur.attributes.formations.data.length > 0).map((secteur) => (
						<li 
						key={secteur.id}
						onClick={() => {
							saveFormationFromParams('loading', [])
							saveSlugs('slugSecteur', secteur.attributes.slug)
							setFilter(secteur.id, 'secteurCourse') 
							setFilterId(secteur.attributes.nom, 'filterSecteurId')
						}}
						>
						{secteur.attributes.nom}
						</li>
					))}
					</ul>
				</div>
				<div 
					className={btnFilterCPFOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
					onClick={() => setBtnFilterCPFOnOff(!btnFilterCPFOnOff)}
				>
				{cpfCourseFilter !== "" ?
					<span className='filter'>
					{filterCpfId} <MdDeleteForever onClick={(e) => {
						saveFormationFromParams('loading', [])
						setFilter('', 'cpfCourse') 
						setFilterId('', 'filterCpfId')
						handleChildElementClick(e)
						}} />
					</span>
					:
					<>
					Financement
					<AiFillCaretDown/>
					</>
				}
					<ul className={btnFilterCPFOnOff ? "show-menu" : ""}>
					{cpfData.map((item) => (
						<li 
						key={item.id}
						onClick={() => {
							saveFormationFromParams('loading', [])
							setFilter(item.id, 'cpfCourse') 
							setFilterId(item.nom, 'filterCpfId')
						}}
						>
						{item.nom}
						</li>
					))}
					</ul>
				</div>
				<SearchCourseByKeyBis className="btn-key" fetchFormations={fetchFormations} secteurCourseFilter={secteurCourseFilter}/>
				</div>
				</section>
			
				<section className="user-filter-section">
				<BsFillGridFill 
				className={`layout-btn ${activLayout === 'grid' ? 'active-layout' : ''}`} 
				onClick={() => {
					setActivLayout('grid')
					setCardPerPage(10)
					setIndexOfLastCard(10)
					setIndexOfFirstCard(0)
					setPage(1)
				}}
				/>
				<FaList 
				className={`layout-btn ${activLayout === 'list' ? 'active-layout' : ''}`}
				onClick={() => {
					setActivLayout('list')
					setCardPerPage(10)
					setIndexOfLastCard(10)
					setIndexOfFirstCard(0)
					setPage(1)
				}}
				/>
				</section>
				<main className="course-main">
					<p className='no-result bold'>Aucun résultat pour cette recherche.</p>
				</main>
			</>
		}
		<Footer />
    </FormationsByKeyStyled>
  )
}

FormationsByKey.propTypes = {
}

export default FormationsByKey