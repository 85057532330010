/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import { FaUserCircle } from "react-icons/fa"
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import * as qs from 'qs'
import Moment from 'react-moment'
import * as XLSX from 'xlsx/xlsx.mjs'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { DataGrid } from '@mui/x-data-grid'

import urlBackEnd from '../../assets/js/urlBackEnd'

/**** Import d'images' ****/


/**** Import des css ****/
import CollabsCardUnivoStyled from './CollabsCardUnivoStyled'
import { EditAttributes } from '@material-ui/icons'

const URL = urlBackEnd
const URL360LEARNING = "https://app.360learning.com/api/v1/";
const API360LEARNING = "f96ac09c72064626beb2a2fce44f30b3"
const ID360LEARNING = "62457cdc53413c65bea4c62c"
const GROUP306LEARNING = "62457cdc53413c65bea4c62e"

const CollabsCardUnivo = ({ 
  collabsFromState,
  fetchLmsApprenant,
  index,
  formationApprenant,
  session,
  fetchPutUserOnSession,
  entreprise,
  dateF
}) => {

  const [collabArray, setCollabArray] = useState([])
  const [jsonToCsv, setJsonToCsv] = useState([])
  const [openUpdateGender, setOpenUpdateGender] = React.useState(false);
  const [openUpdateCollabs, setOpenUpdateCollabs] = React.useState(false);

  useEffect(() => {

    collabsFromState.map((collab, index) => {

      // objet de la requete
      const queryFilterObj = qs.stringify({
        filters: {
          username: {
            $eq: collab.attributes.email
          },
        },
        headers: {
          Authorization:
          `Bearer ${session.jwt}`,
        },
        }, {
          encodeValuesOnly: true,
      });

      // requete axios pour savoir si un collab existe
      axios.get(`${URL}users?${queryFilterObj}`)
      .then((response) => {
        const arrayToPush = collabsFromState
        if (response.data.length > 0) {
          arrayToPush[index] = {
            ...arrayToPush[index], 
            wefor: response.data.data
          }
          setCollabArray([...arrayToPush])
        } else {
          arrayToPush[index] = {
            ...arrayToPush[index], 
            wefor: false
          }
          setCollabArray([...arrayToPush])
        }
    
      })
      .catch((error) => {
        console.error(error)
      })
    })
  }, [])

  const handleOpenUpdateGender = () => {
    setOpenUpdateGender(true);
  };
  
  const handleCloseUpdateGender = () => {
    setOpenUpdateGender(false);
  };

  const handleOpenUpdateCollabs = () => {
    setOpenUpdateCollabs(true);
  };
  
  const handleCloseUpdateCollabs = () => {
    setOpenUpdateCollabs(false);
  };
  
  const changeGender= (string, index) => {
    const arrayToPush = jsonToCsv
      arrayToPush[index] = {
        ...arrayToPush[index], 
        Gender: string
      }
      setJsonToCsv(arrayToPush)
  }

  const fetchCreateWeforaccount = () => {
    /*
    const queryFilterObj = qs.stringify({
      headers: {
        Authorization:
        `Bearer ${session.jwt}`,
      },
      }, {
        encodeValuesOnly: true,
    });
    
    axios.post(`${URL}userParticulierWithEmail`, {
      data : {
        email: emailCompteWefor,
        prenom: prenomCompteWefor,
        nom: nomCompteWefor,
        password: passwordCompteWefor,
        courseName: formationApprenantObj.formationData === undefined ? null : formationApprenantObj.formationData.attributes.nom,
        courseId: formationApprenantObj.formationData === undefined ? null : formationApprenantObj.formationData.id
      }
    },
    queryFilterObj
    ).then((response) => {
      setAccountApprenantExist(true)
      setAccountApprenant(response.data)
      fetchParticulier(response.data.id)
      toast(`Compte créé`, {
        position: "bottom-right",
        type: "success",
        theme:"colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    })
    .catch((error) => {
      console.error(error)
      toast(`Une erreur s'est produite, contactez le service informatique`, {
        position: "bottom-right",
        type: "error",
        theme:"colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    })
    */
  }

  useEffect(() => {
    let array = []
    collabArray.map((collab, index) => {
      array.push(
        {
          Username: `${collab.attributes.nom}-${collab.attributes.prenom}`,
          Email: collab.attributes.email,
          'First Name': collab.attributes.prenom,
          'Last Name': collab.attributes.nom,
          Gender: 'non binaire',
          'Phone Number': '123456789',
        }
      )
    })
    setJsonToCsv(array)
  }, [])

  const downloadxls = (e, data) => {
    e.preventDefault();

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "test");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `import-${entreprise.data.attributes.Nom_entreprise}-collabs-${dateF}.csv`);
    setOpenUpdateGender(false)
  };

  return (
    <CollabsCardUnivoStyled>
      <div className='bottom-section'>
        <div className='infos-session-wrapper'>
        {collabArray.length > 0 && collabArray.map((collab, index) => (
          <div className='bottom' key={index}>
            {/* NOM */}
            <div className='info-account center'>
            <FaUserCircle style={{color: '#6399ce', margin: '0px 5px 1px 0', fontSize: '18px'}}/> {collab.attributes.nom} {collab.attributes.prenom}
            </div>
            {/* COMPTE WEFOR */}
            <div>
            {collab.wefor === undefined  &&
              <CircularProgress
                color="success"
              />
            }
            {collab.wefor === false  && 
              <p className='no-account center'>Pas de compte</p>
            }
            {collab.wefor !== false  && Object.keys(collab).length > 0 &&
              <p className='done-task'>Compte créé le <Moment format="DD/MM/YYYY" className="bold">{collab.attributes.createdAt}</Moment></p>
            }
            </div>
            {/* COMPTE LMS */}
            <div>
              <a className='btn-univo bold' href='https://wefor.cloudplateforme.com/v2-admin/dashboard' target="_blank" rel="noreferrer">univo</a>
            </div>
          </div>
        ))}
        </div>
      </div>
      {/* Modal création de compte wefor */}
      <div className="action-wrapper">
        <div>
        </div>
        <div>
          <button 
            className='btn-action bold'
            onClick={handleOpenUpdateCollabs}
          >Créer Les comptes</button>
        </div>
        <div>
          <button 
            className='btn-univo bold'
            onClick={handleOpenUpdateGender}
          >
            Importer un fichier CSV
          </button>
        </div>
      </div>
      <Dialog open={openUpdateGender} onClose={handleCloseUpdateGender}>
        <DialogTitle>Choisir les genre des collaborateurs</DialogTitle>
        <DialogContent>
          {jsonToCsv.map((collab, index) => (
            <DialogContentText key={index}>
              Genre de : {collab.Username}
              <FormControl
              style={{
                width: '100%',
              }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <FormControlLabel value="Femme" control={<Radio />} label="Femme" onClick={() => changeGender('f', index)}/>
                  <FormControlLabel value="Homme" control={<Radio />} label="Homme" onClick={() => changeGender('m', index)}/>
                </RadioGroup>
              </FormControl>
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateGender}>Annuler</Button>
          <Button             
            onClick={(e) => {
            downloadxls(e, jsonToCsv)
          }}>Valider</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openUpdateCollabs} onClose={handleCloseUpdateCollabs}>
        <DialogTitle>Création de compte WEFOR</DialogTitle>
        <DialogContent>
        <DialogContentText>
            Merci de verifier les informations relatives aux collaborateurs de l'entreprise <span className='bold'>{entreprise.data.attributes.Nom_entreprise}</span> avant de valider 
          </DialogContentText>
          {collabArray.map((collab, index) => (
            <DialogContentText 
              key={index} 
              style={{
                borderTop: '1px dashed #4d4d4d',
                padding: '5px 0',
                margin: '5px 0',
              }}
            >
              <p>Id : <span className='bold'>{index}</span></p>
              <p>Nom complet : <span className='bold'>{collab.attributes.nom} {collab.attributes.prenom}</span></p>
              <p>Email : <span className='bold'>{collab.attributes.email}</span></p>
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateCollabs}>Annuler</Button>
          <Button             
            onClick={(e) => {
            //launch the thing you need to do
            handleCloseUpdateCollabs()
          }}>Valider</Button>
        </DialogActions>
      </Dialog>
    </CollabsCardUnivoStyled>
  )
}

CollabsCardUnivo.propTypes = {
}

export default CollabsCardUnivo