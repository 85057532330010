/**** Import des modules ****/
import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { BsFillExclamationTriangleFill } from "react-icons/bs";

/**** Import d'images ****/

/**** Import des css ****/
import ModalConnexionStyled from './ModalConnexionStyled'
import { useEffect } from 'react';

const ModalConnexion = ({redirect, changeIsLogged, changeValue, openModal, closeModal, changeFormModal, modalName, fetchUser, isLogged, modalMessageContent, modalMessageIsOpen, toggleModalMessage, redirectToAccount }) => {
  
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowEye = () => {
    setShowPassword(!showPassword)
  }

  return (
    <ModalConnexionStyled>
      {/*isLogged && 
      <Navigate to='/account/home' replace={true} />
      */}
      {!isLogged &&
      <>
      {modalMessageIsOpen && 
        <p className='message-important'><BsFillExclamationTriangleFill /> <span>{modalMessageContent}</span></p>
      }
      <h2 className="bold">Se connecter<span className="separator-blue"></span></h2>
      <form 
        className="account-form-comp"
        onSubmit={(event) => {
          event.preventDefault();
          fetchUser();
          toggleModalMessage('', false)
        }}
      >
        <label className='bold'>
          Adresse Email
          <input 
            type="email" 
            name="email" 
            placeholder=""
            onChange={(event) => {
              changeValue(event.target.value, event.target.name);
            }}
          />
        </label>
        <label className='password-item bold'>
              Mot de passe
              <div className='eye-wrapper'>
                {showPassword === true ? 
                  <FaEye onClick={toggleShowEye}/>
                :
                  <FaEyeSlash onClick={toggleShowEye}/>
                }
              </div>
              
          <input 
            type={showPassword ? "text" : "password"}
            name="password" 
            placeholder=""
            onChange={(event) => {
              changeValue(event.target.value, event.target.name);
            }}
          />
        </label>
        <label>
          <input 
            type="submit" 
            value="Connexion" 
            className="lightblue-btn"
            onClick={() => window.dataLayer.push({'event': "login"})}
          />
        </label>
      </form>
      <section className="link-wrapper">
      <p className="password-lost" onClick={() => changeFormModal('loginLost')}>Mot de passe oublié ?</p>
        <p 
          className="account-creation bold gg-tag-create-account"
          onClick={() => {
            changeFormModal('signIn')
            window.dataLayer.push({'event': "signin"})
          }}
        >Créer un compte</p>
      </section>
      </>
    }
    </ModalConnexionStyled>
  )
}

ModalConnexion.propTypes = {
  openModal: PropTypes.func.isRequired,
  changeIsLogged: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
}

export default ModalConnexion

