import React, { useEffect, useState } from 'react'
import { AiOutlineArrowUp } from "react-icons/ai";

import PropTypes from 'prop-types'

import ArrowStyled from './ArrowStyled'

const Arrow = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [scroll]);
  return (
    <ArrowStyled>
      <AiOutlineArrowUp 
        className="arrow-up"
        onClick={()=> {setScroll(!scroll);}}
      />
    </ArrowStyled>
  )
}

Arrow.propTypes = {
}

export default Arrow