/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'
import axios from 'axios'
import * as qs from 'qs'
import { Skeleton } from '@mui/material'
import slugify from '../../assets/js/slugify'

/**** import des components ****/
import ModalJobFiche from '../../containers/ModalJobFiche';

/**** Import d'images' ****/
import shield from '../../assets/img/picto/shield.png'
import shieldWhite from '../../assets/img/picto/shield-white.png'
import logoCPF from '../../assets/img/logo/WEFOR_CARRE_COULEUR.png'
import atlas from '../../assets/img/logo/atlas.svg'
import afdas from '../../assets/img/logo/afdas.svg'
import ocapiat from '../../assets/img/logo/ocapiat.png'
import uniformation from '../../assets/img/logo/uniformation.svg'
import constructys from '../../assets/img/logo/constructys.png'
import opcommerce from '../../assets/img/logo/opcommerce.svg'
import akto from '../../assets/img/logo/akto.png'
import opco2i from '../../assets/img/logo/opco2i.svg'
import opcoMobilites from '../../assets/img/logo/opco-mobilites.svg'
import opcoep from '../../assets/img/logo/opcoep.svg'
import opcoSante from '../../assets/img/logo/opco-sante.png'

/**** Import des css ****/
import CourseCardListLayoutStyled from './CourseCardListLayoutStyled'
import urlBackEnd from '../../assets/js/urlBackEnd'

const URL = urlBackEnd

const CourseCardListLayout = ({activCourseId, changeActivCourseId, courseId, openModal, formations, id, attributes, userRole, setJobFicheId, currentProfilUser}) => {
  const [modalJobFicheOpen, setModalJobFicheOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [navigateFiche, setNavigateFiche] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  

  let location = useLocation()
  const navigate = useNavigate();
  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  

  return (
    <CourseCardListLayoutStyled
      className={` ${activCourseId === courseId & location.pathname.includes('/formation') ? "active-comp" : ""} ${location.pathname.includes('/formation') ? "pointer" : ""}`}
      onClick={() => {
        if (location.pathname.includes('/formation')) {
          changeActivCourseId(courseId); navigate(`/formation/${courseId}`)
        }
      }}
    >
      {modalJobFicheOpen &&
        <ModalJobFiche modalJobFicheOpen={modalJobFicheOpen} setModalJobFicheOpen={setModalJobFicheOpen} />
      }
        {activCourseId !== courseId &&
        <div className="shield-wrapper">
          {attributes.cpf_link !== null && attributes.cpf_link !== '' ?
            <img src={logoCPF} className="shield-picto" alt="bouclier orange" />
            :
            <div className="shield-wrapper blue bold">
              <div>Non éligible CPF</div>
              {/* Ici hover avec les logos des opco */}
              <div className='bold green'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
              >
                Finançable par OPCO
              </div>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <div
                  style={{
                    overflow: 'hidden',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    maxWidth: '350px',
                  }}
                >
                  <a href="https://www.afdas.com/"><img src={afdas} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opco2i.fr/"><img src={opco2i} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opco-atlas.fr/"><img src={atlas} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.ocapiat.fr/"><img src={ocapiat} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.uniformation.fr/"><img src={uniformation} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.constructys.fr/"><img src={constructys} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.lopcommerce.com/"><img src={opcommerce} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.akto.fr/"><img src={akto} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opcomobilites.fr/"><img src={opcoMobilites} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opcoep.fr/"><img src={opcoep} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opco-sante.fr/"><img src={opcoSante} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                </div>
              </Popover>
            </div>
          }
        </div>
        }
        {(activCourseId === courseId) && ( location.pathname.includes('/formation')) && 
        <div className="shield-wrapper orange">
          {attributes.cpf_link !== null && attributes.cpf_link !== '' ?
            <img src={logoCPF} className="shield-picto" alt="bouclier orange" />
            :
            <div className="shield-wrapper blue bold">
              <div>Non éligible CPF</div>
              <div className='bold green'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
              >
                Finançable par OPCO
              </div>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <div
                  style={{
                    overflow: 'hidden',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    maxWidth: '350px',
                  }}
                >
                  <a href="https://www.afdas.com/"><img src={afdas} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opco2i.fr/"><img src={opco2i} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opco-atlas.fr/"><img src={atlas} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.ocapiat.fr/"><img src={ocapiat} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.uniformation.fr/"><img src={uniformation} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.constructys.fr/"><img src={constructys} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.lopcommerce.com/"><img src={opcommerce} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.akto.fr/"><img src={akto} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opcomobilites.fr/"><img src={opcoMobilites} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opcoep.fr/"><img src={opcoep} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                  <a href="https://www.opco-sante.fr/"><img src={opcoSante} alt="logo cpf bleu carré" style={{height : '40px', padding: '5px'}}/></a>
                </div>
              </Popover>
            </div>
          }
        </div>
        }
        <div className={`${attributes.cpf_link !== null && attributes.cpf_link !== '' ? 'title-wrapper' : 'title-wrapper align-center' }`}>
            <h4 className="bold">{attributes.secteurs.data[0].attributes.nom}</h4>
            <div className="separator-orange"></div>
            <h4 className="bold first-title">{attributes.nom}</h4>
        </div>
        <div className="duree-wrapper">
          <p className="bold">Durée</p>
          <div className="separator-orange"></div>
          <p className="duree bold">{attributes.duree}h</p>
        </div>
        <div className="salary-wrapper">
            <span className="bold">Prix:</span>
            <div className="separator-green"></div>
            <span className="bold"><span className="bold small-txt">{euroFormat.format(attributes.prix)}</span></span> 
        </div>
        <div className="btn-wrapper">
        {attributes.fiche_metier.data !== null &&
        <>
        {/*location.pathname === "/formation" &&
        <button 
          className="btn-lightorange mobile-none"
          onClick={() => {
            setJobFicheId(attributes.fiche_metier.data.id)
            setModalJobFicheOpen(true)
          }}
        >Fiche métiers</button>
        */}
        </>
        }
        {userRole === "entreprise" ? 
        <button 
          className="btn-orange"
          onClick={() => openModal("inscription-collab")}
        >Inscrire un collaborateur</button>
        :
        <button 
          className="btn-orange"
          onClick={() => {
            openModal("formation")
            window.dataLayer.push({'event': "s'inscrire"})
          }}
        >S'inscrire</button>
        }
        {attributes.fiche_metier.data !== null ? 
          <div 
            className="btn-lightorange"
            onClick={(e ) => {
              e.stopPropagation()
              navigate(`/fiche-metier/${slugify(attributes.fiche_metier.data.attributes.prerequis)}`)
            }}
          >Fiche métier</div>
        :
        <></>
        }
        </div>
        {attributes.cpf_link !== null && attributes.cpf_link !== '' ? 
          <div className="cpf-wrapper">
            <img src={logoCPF} alt="logo bleu cpf" />
            <div className="bold">Éligible CPF</div>
          </div>
          :
          <div className="cpf-wrapper">
            Non éligible CPF
          </div>
        }
    </CourseCardListLayoutStyled>
  )
}

CourseCardListLayout.propTypes = {
  activCourseId: PropTypes.string.isRequired, 
  changeActivCourseId: PropTypes.func.isRequired, 
  openModal: PropTypes.func.isRequired,
}

export default CourseCardListLayout