import styled from 'styled-components';

const CandidatCardListStyled = styled.article`
    border-radius: 15px;
    padding: 10px 10px 10px 10px;
    background-color: ${props => (props.bcgColor)};
    box-shadow: 0px 1px 10px #00000029;
    width: 100%;
    box-sizing: border-box;
    margin: 30px 20px 0 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    .recharts-surface {
        overflow: visible;
        

        tspan {
            font-size: 22px;
        }

        .recharts-layer {
            font-size: 22px;
        }
    }

    .recharts-wrapper {
        width: 572px !important;
        height: 450px !important;
    }

    .circle-wrapper {
        width: 90px !important;
        height: 90px;
        position: relative;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        box-sizing: border-box;

        .score-div {
            position: absolute;
            top: 0;
            text-align: center;
            width: 100%;
            height: 100%;
            color: #6AAF8B;
            font-size: 19px; 

            .top {
                margin-top: 25px;
                margin-left: -30px;
                display: block;
            }
            .middle {
                margin-top: -10px;
                margin-left: -5px;
                display: block;
                font-size: 22px;
            }
            .bottom {
                margin-top: -10px;
                margin-left: 30px;
                display: block;
            }
        }
    }

    .modal-validation {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0,0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        display: ${props => (props.showModalValidation ? "flex" : "none")};

        .wrapper-bcg {
            padding: 50px;
            background-color: #fff;
            font-size: 20px;
            color: #202c3c; 
            border-radius: 30px;
            width: 96%;

            .btn-wrapper {
                margin-top: 20px;
                
                .btn-yes {
                    border: 0;
                    border-radius: 30px;
                    padding: 15px 30px;
                    font-size: 20px;
                    background-color: #6aaf8b;
                    color: #fff;
                    margin-right: 20px;
                    width: 100px;
                    cursor: pointer;

                    &:hover {
                        opacity: .8;
                    }
                }

                .btn-no {
                    border: 0;
                    border-radius: 30px;
                    padding: 15px 30px;
                    font-size: 20px;
                    background-color: #dc4a46;
                    color: #fff;
                    margin-right: 20px;
                    width: 100px;
                    cursor: pointer;

                    &:hover {
                        opacity: .8;
                    }
                }
            }
        }
    }

    .modal-result-wrapper {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0,0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-result {
            overflow: visible;
            position: relative;


            .dropdown-wrapper {
                padding: 50px;
                background-color: #fff;
                font-size: 20px;
                color: #202c3c; 
                border-radius: 30px;
                height: 100%;
                max-height: calc(96vh - 200px);
                margin-top: 200px;
                width: 96%;
                max-width: 700px;
                overflow-y: auto;
                overflow-x: hidden;

                .top-msg {
                    margin-bottom: 20px;
                    padding: 10px;
                    border-radius: 5px;
                    width: 100%;
                    background-color: rgb(99, 153, 206);
                    color: #fff;
                }

                .btn-compare {
                    background-color: #6399ce;
                    border-radius: 5px;
                    color: #fff;
                    transition: all .3s ease; 
                    border: 0;
                    padding: 10px;
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }
            }

            .icon-wrapper {
                position: absolute;
                top: 180px;
                right: 10px;
                width: 40px;
                height: 40px;
                background-color: #649ACE;
                border-radius: 17.5px;
                overflow: hidden;
                display:flex;
                align-items: center;
                justify-content: center;
                z-index: 10;
    
                .close-icon {
                    color: #fff;
                    font-size: 30px;
                    cursor: pointer;
                    border-radius: 20px;
                }
            }

            .know-more {
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .2s;
                width: 200px;
                margin: 0 auto;
                cursor: pointer;

                &:hover {
                    opacity: .7;
                }

                svg {
                    margin-left: 10px;
                }
            }

            .result-txt-wrapper {
                text-align: left;
                width: 100%;
                h3 {
                    font-size: 22px;
                    margin: 30px 0 15px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    color: #202D3C;
    
                    span {
                        display: flex;
                        align-items: center;
                        padding-top: 10px;
                        font-size: 20px;
    
                        svg {
                            color: #6AAF8B;
                            margin:0 10px;
                        }
                    }
                }
    
                p {
                    color: #4d4d4d;
                    letter-spacing: 1px;
                }
            }
        }
    }

    .img-top {
        width: 90px;
        border-radius: 50%;
        box-shadow: 0px 3px 6px #00000029;
    }

    .formation {
        color: #4d4d4d;
        font-size: 18px;
        padding: 10px 0 10px;
        border-bottom: 1px solid #649ACE;
        box-sizing: border-box;
        height: 40px;
    }

    .sous-domaine {
        color: #202D3C;
        font-size: 16px;
        padding: 10px 0;
        border-bottom: 1px solid #649ACE;
    }

    .level {
        color: #4d4d4d;
        font-size: 18px;
        padding: 5px 0;
        height: 56px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        width: calc(25% - 22.5px);
        padding: 0 5px;
        box-sizing: border-box;
        
        .line {
            border-bottom: 1px solid #649ACE;
        }

        span {
            padding: 10px 0;
            width: 100%;
        }

        span {
            height: 50%;
            display: flex;
            
            justify-content: center;
        }

        span:nth-child(1) {
            padding-top: 0;
        }

        span:nth-child(2) {
            padding-bottom: 0;
            align-items: start;
        }
    }

    .info-candidat {
        height: 100%;
        font-size: 18px;
        width: calc(22% - 22.5px);
        margin-left: 3%;
        background-color: #fff;
        border-radius: 10px;
        color: #4d4d4d;
        box-shadow: 0px 1px 10px #00000029;

        span {
            height: 33.33%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .btn-replace {
        height: calc(34.39px + 8px);
        display: none;
    }

    .form-wrapper {
        width:calc(25% - 22.5px);
        padding: 0 5px;
        box-sizing: border-box;
    }

    .statut {
        color: #79C89D;
        font-size: 18px;
        padding: 10px 0;

        &.orange {
            color: #d47558;
        }
    }

    .note {
        color: #202D3C;
        text-align: left;
        padding: 20px 0 30px;
        font-size: 18px;

        span {
            color: #649ACE;
        }
    }

    .btn-wrapper-right {
        width: calc(25% - 22.5px);
    }

    .btn-blue {
        color: #fff;
        background-color: #649ACE;
        width: 150px;
        padding: 8px 0;
        border-radius: 10px;
        border: 0;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover {
            background-color: #202D3C;
        }
    }

    .btn-orange {
        color: #fff;
        background-color: #d47558;
        width: 150px;
        padding: 8px 0;
        border-radius: 7px;
        border: 0;
        cursor: pointer;
        box-shadow: 0px 10px 13px -7px #d47558;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        .pdf-icon {
            margin-left: 10px;
        }

        &:hover {
            opacity: .7;
        }
    }


    @media only screen and (max-width: 1400px) {
        
    }

    @media only screen and (max-width: 1079px) {

        margin: 30px 10px 0 10px;

        .img-top {
            width: 60px;
            border-radius: 50%;
            box-shadow: 0px 3px 6px #00000029;
        }

        .form-wrapper {
            width: calc(25% - 15px);
            font-size: 15px;
            height: 100%;

            .no-test {
                height: 100%;
                display: flex;
                align-items: center;
            }

            .formation {
                font-size: 15px;
                padding-top: 0;
                height: 50%;
            }

            .statut {
                font-size: 15px;
                padding-bottom: 0;
                height: 50%;
            }
        }

        .level {
            height: 100%;
            font-size: 15px;
            width: calc(25% - 15px);

            span {
                height: 50%;
                display: flex;
                
                justify-content: center;
            }

            span:nth-child(1) {
                padding-top: 0;
            }

            span:nth-child(2) {
                padding-bottom: 0;
                align-items: start;
            }
        }

        .info-candidat {
            height: 100%;
            font-size: 15px;
            width: calc(25% - 15px);
            margin-left: 0;
        }


        .btn-wrapper-right {
            width: calc(25% - 15px);

            .btn-blue {
                padding: 5px;
                width: 95px;
                font-size: 15px
            }

            .btn-orange {
                padding: 5px;
                width: 95px;
                font-size: 15px
            }
        }

        .circle-wrapper {
            width: 60px !important;
            height: 60px;

            .MuiCircularProgress-root {
                width: 60px !important;
                height: 60px !important;
            }

            .score-div {
                position: absolute;
                top: 0;
                text-align: center;
                width: 100%;
                height: 100%;
                color: #6AAF8B;
                font-size: 15px;

                .top {
                    margin-top: 15px;
                    margin-left: -20px;
                    display: block;
                }

                .bottom {
                    margin-top: -10px;
                    margin-left: 20px;
                    display: block;
                }
            }
        } 
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        margin: 30px 10px 0 10px;

        .img-top {
            width: 60px;
            border-radius: 50%;
            box-shadow: 0px 3px 6px #00000029;
        }

        .form-wrapper {
            width: calc(25% - 15px);
            font-size: 11px;
            height: 100%;

            .no-test {
                height: 100%;
                display: flex;
                align-items: center;
            }

            .formation {
                font-size: 11px;
                padding-top: 0;
                height: 50%;
            }

            .statut {
                font-size: 11px;
                padding-bottom: 0;
                height: 50%;
            }
        }

        .level {
            height: 100%;
            font-size: 11px;
            width: calc(25% - 15px);

            span {
                height: 50%;
                display: flex;
                
                justify-content: center;
            }

            span:nth-child(1) {
                padding-top: 0;
            }

            span:nth-child(2) {
                padding-bottom: 0;
                align-items: start;
            }
        }


        .info-candidat {
            height: 100%;
            font-size: 11px;
            width: calc(25% - 15px);
        }

        .btn-wrapper-right {

            .btn-blue {
                width: 75px;
                font-size: 11px
            }

            .btn-orange {
                width: 75px;
                font-size: 11px
            }
        }
        .modal-result-wrapper {

    
            .modal-result {
    
                .dropdown-wrapper {
                    padding: 30px 10px;
                    margin: 200px auto 0;
                    max-width: 360px;
                    box-sizing: border-box;


                    .recharts-surface {
                        overflow: visible;
                        width: 340px;
                        height: 340px;
            
                        tspan {
                            font-size: 22px;
                        }
            
                        .recharts-layer {
                            font-size: 22px;
                        }
                    }
            
                    .recharts-wrapper {
                        width: 340px !important;
                        height: 340px !important;

                        .recharts-legend-wrapper {
                            width: 340px !important;
                            .recharts-default-legend {
                                width: 340px !important;
                            }
                        }

                        .recharts-legend-item {
                            .recharts-surface {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }

        .modal-result-wrapper .modal-result .icon-wrapper {

            right: 0px;
        }
    }
`;

export default CandidatCardListStyled;