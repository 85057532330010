import { 
    SET_WINDOW_TOP,
    CHANGE_INPUT_VALUE,
    SET_FULL_PAGE_LOADER,
    SET_FULL_PAGE_LOADER_CONNEXION,
    SET_FULL_PAGE_LOADER_JOBOFFER,
    SET_FULL_PAGE_LOADER_CONTACT,
    SET_FULL_PAGE_LOADER_FACTURATION,
    SET_LAST_PAGE_PATHNAME,
    SAVE_AXEPTA_RESPONSE
  } from "../actions/actions";
  
  export const initialState = {
    windowTop: true,
    fullPageLoader: false,
    fullPageLoaderConnexion: false,
    fullPageLoaderJobOffer: false,
    fullPageLoaderContact: false,
    fullPageLoaderFacturation: false,
    lastPagePathname: ['/', '/'],
    axeptaResponse: false,
    htmlResponse: "<div>Une erreur s'est produite</div>"
  };
  
  // reducer = traducteur d'une intention/action vers une modification du state
  const actions = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_WINDOW_TOP:
          return {
            ...state,
            windowTop: action.bool,
          };
        case CHANGE_INPUT_VALUE:
          return {
            ...state,
            [action.name]: action.value,
          };
        case SET_FULL_PAGE_LOADER:
          return {
            ...state,
            fullPageLoader: action.bool
          }
        case SET_FULL_PAGE_LOADER_CONNEXION:
          return {
            ...state,
            fullPageLoaderConnexion: action.bool
          }
        case SET_FULL_PAGE_LOADER_JOBOFFER:
          return {
            ...state,
            fullPageLoaderJobOffer: action.bool
          }
        case SET_FULL_PAGE_LOADER_CONTACT:
          return {
            ...state,
            fullPageLoaderContact: action.bool
          }
        case SET_FULL_PAGE_LOADER_FACTURATION:
          return {
            ...state,
            fullPageLoaderFacturation: action.bool
          }
        case SET_LAST_PAGE_PATHNAME:
          return {
            ...state,
            lastPagePathname: [...state.lastPagePathname, action.value]
          }
        case SAVE_AXEPTA_RESPONSE:
          return {
            ...state,
            axeptaResponse: action.bool,
            htmlResponse: action.data
          }
      default:
        return state;
    }
  };
  
  export default actions;