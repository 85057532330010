import styled from 'styled-components';

const ArrowStyledStyled = styled.div`
    position: fixed;
    right: 37px;
    bottom: 100px;
    z-index: 250;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #649ACE;
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow-up {
        color: #fff;
        font-size: 25px;
        cursor: pointer;
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        right: 23px;
        bottom: 80px;
    }
`;

export default ArrowStyledStyled;