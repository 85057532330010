import { connect } from 'react-redux'

import ModalAccountCreation from '../../../components/ModalInscription/ModalAccountCreation'

import { 
    openModal,
    closeModal,
} from '../../../actions/modal'

const mapStateToProps = (state) => ({
    signInStep: state.signin.signInStep
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => {
        dispatch(openModal(name));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalAccountCreation)

