/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'

/**** Import des css ****/
import CourseLiStyled from './CourseLiStyled'

const CourseLi = ({
  setSubmenuCourseSecteurActiv, 
  submenuCourseSecteurActiv, 
  setSubmenuCourseDomaineActiv, 
  submenuCourseDomaineActiv,
  setSubmenuCourseFormationActiv, 
  submenuCourseFormationActiv,
  secteurs,
  changeCurrentDomaineClicked,
  currentProfilUser,
  secteursData,
  setFilter
}) => {

  const [isShowCourseMenu, setIsShowCourseMenu] = useState(false);

  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <CourseLiStyled
    currentProfilUser={currentProfilUser}
      className="assurance-btn bold"
      onClick={() => { /*
        if (windowDimensions.width > 1079) {
        setIsShowCourseMenu(true)
        }*/
      }}
      onMouseOver={() => { /*
        if (windowDimensions.width > 1079) {
        setIsShowCourseMenu(true)
        }
      */}}
       // onMouseLeave={() => setIsShowCourseMenu(false)}
      windowWidth={windowDimensions.width}
    >
      {currentProfilUser === 'Entreprise' ? "Former mes collaborateurs" : "Se former"}
      {isShowCourseMenu && (
        <div className="job-submenu-wrapper">
          <div className="secteurs column-submenu">
            <h4 className="bold">Secteurs</h4>
            <ul>
              {secteursData.map((secteur) => (
                <Link to="/formation" className="">
                <li
                  key={secteur.id}
                  className={submenuCourseSecteurActiv === secteur.id ? "active" : ""}
                  onClick={() => {
                    setFilter(secteur.attributes.nom, 'secteurCourse') 
                    setFilter('', 'domaineCourse') 
                    setFilter('', 'formationCourse') 
                  }}
                  onMouseEnter={() => {
                    setSubmenuCourseFormationActiv("")
                    setSubmenuCourseDomaineActiv("")
                    setSubmenuCourseSecteurActiv(secteur.id)
                  }}
                >
                    {secteur.attributes.nom}
                </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="domaines column-submenu">
            <h4 className="bold">Domaines</h4>
            <ul>
            {submenuCourseSecteurActiv !== "" && secteursData.find(item => item.id === submenuCourseSecteurActiv).attributes.domaines.data.map((domaine) => (
              <Link to="/formation" className="">
                <li
                  key={domaine.id}
                  className={submenuCourseDomaineActiv === domaine.id ? "active" : ""}
                  onMouseEnter={() => {
                    setSubmenuCourseDomaineActiv("")
                    setSubmenuCourseDomaineActiv(domaine.id)
                  }}
                  onClick={() => {
                    changeCurrentDomaineClicked(domaine.id)
                    setFilter(domaine.attributes.nom, 'domaineCourse')
                    setFilter('', 'secteurCourse') 
                    setFilter('', 'formationCourse') 
                  }}
                >
                  {domaine.attributes.nom}
                </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="formations column-submenu">
            <h4 className="bold">Formations</h4>
            <ul>
            {submenuCourseDomaineActiv !== "" && secteursData.find(item => item.id === submenuCourseSecteurActiv).attributes.domaines.data.find(item => item.id === submenuCourseDomaineActiv).attributes.formations.data.map((formation) => (
              <Link to="/formation" className="">
                <li
                  key={formation.id}
                  className={submenuCourseFormationActiv === formation.id ? "active" : ""}
                  onClick={() => {
                    setFilter(formation.attributes.nom, 'formationCourse') 
                    setFilter('', 'domaineCourse') 
                    setFilter('', 'secteurCourse') 
                  }}
                  onMouseEnter={() => {
                    setSubmenuCourseFormationActiv("")
                    setSubmenuCourseFormationActiv(formation.id)
                  }}
                >
                    {formation.attributes.nom} ({formation.attributes.niveau})
                </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
    )}
    </CourseLiStyled>
  )
}

CourseLi.propTypes = {
  setSubmenuCourseSecteurActiv: PropTypes.func.isRequired, 
  submenuCourseSecteurActiv: PropTypes.string.isRequired, 
  setSubmenuCourseDomaineActiv: PropTypes.func.isRequired, 
  submenuCourseDomaineActiv: PropTypes.string.isRequired,
  setSubmenuCourseFormationActiv: PropTypes.func.isRequired, 
  submenuCourseFormationActiv: PropTypes.string.isRequired,
}

export default CourseLi