/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import * as CryptoJS from "crypto-js"
import { toast } from 'react-toastify'

import { FaFileAlt, FaBriefcase, FaPlus, FaUserTie, FaLightbulb, FaArrowsAlt, FaHandsHelping, FaAngry, FaSearch, FaBrain } from "react-icons/fa";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import Skeleton from '@mui/material/Skeleton';
import urlBackEnd from '../../assets/js/urlBackEnd'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import DialogTitle from '@mui/material/DialogTitle'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import NbPart from '../../components/NbPart'

/**** Import des images ****/

/**** Import des css ****/
import AccountHomeStyled from './AccountHomeStyled'


const AccountHome = ({
  userInfo,
  userRole,
  isLogged,
  fetchAccountFormations,
  redirectToAccount,
  fetchFactureE,
  factureE,
  setMenuDocument,
  fetchGetScoreRecrutement,
  scoreRecrutementObject,
  setOffreIdHP,
  changeClickedFacture,
  setModalJobsAccount,
  fetchUser,
  openModal,
  changeAccountValue,
  fetchPutAccountP,
  fetchPutAccountE,
  accountAdresseNumStreet,
  accountAdresseStreet,
  accountAdresseCode,
  accountAdresseCity,
  changeValue
}) => {

  useEffect(() => {
    redirectToAccount(false)
    if (!isLogged) {
      openModal('login')
    }
  }
  , [])

  const [menuOne, setMenuOne] = useState(false);
  const [menuTwo, setMenuTwo] = useState(false);
  const [menuThree, setMenuThree] = useState(false);
  const [menuFourth, setMenuFourth] = useState(false);
  const [menuFifth, setMenuFifth] = useState(false);
  const [menuSixth, setMenuSixth] = useState(false);
  const [menuSeventh, setMenuSeventh] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
      const login = params.get('login')
      const password = params.get('password')

      if (login !== null && password !== null) {
        const decrypted = CryptoJS.AES.decrypt(
          password.replace(/ /g, '+'),
          "salut c'est nico",
        ).toString(CryptoJS.enc.Utf8)
        changeValue(login, 'email')
        changeValue(decrypted, 'password')
        fetchUser(login, decrypted)
      }
  }, []);

  useEffect(() => {
    if(factureE.length === 0 && userRole === 'entreprise') {
      fetchFactureE()
    }
  }, []);

  useEffect(() => {
    if(isLogged && scoreRecrutementObject.length === 0 && userRole !== 'entreprise') {
      fetchGetScoreRecrutement()
    }
  }, []);

  const demandeFormation = (array) => {
    const aujourdHui = new Date();
    return array.filter((objet) => objet.attributes.start_session === null || new Date(objet.attributes.start_session) > aujourdHui);
  };

  const enCoursFormation = (array) => {
    const aujourdHui = new Date();
    return array.filter((objet) =>
      objet.attributes.step > 1 &&
      new Date(objet.attributes.start_session) <= aujourdHui &&
      new Date(objet.attributes.end_session) >= aujourdHui
    );
  };

  return (
    <AccountHomeStyled>
      <Header bcgColor={'#fff'}/>
      <main className="page-wrapper">
      <AccountMenu />
      {isLogged &&
      <>
      {(userRole === 'entreprise' ? userInfo.data[0].attributes.adresse : userInfo.data[0].attributes.adresse_postale) === null || (userRole === 'entreprise' ? userInfo.data[0].attributes.adresse : userInfo.data[0].attributes.adresse_postale) === '' ?
        <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className='bold'>Merci de compléter les informations suivantes pour finaliser la création de votre compte :</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <TextField
            required
            style={{width: '100%' , margin: '10px 0'}}
            id="outlined-required"
            label="Numéro de rue"
            defaultValue=""
            name="accountAdresseNumStreet" 
            onChange={(event) => {
              changeAccountValue(event.target.value, event.target.name);
            }}
          />
          <TextField
            required
            style={{width: '100%' , margin: '10px 0'}}
            id="outlined-required"
            label="Nom de rue"
            defaultValue=""
            name="accountAdresseStreet" 
            onChange={(event) => {
              changeAccountValue(event.target.value, event.target.name);
            }}
          />
          <TextField
            required
            style={{width: '100%' , margin: '10px 0'}}
            id="outlined-required"
            label="Code postal"
            defaultValue=""
            name="accountAdresseCode" 
            onChange={(event) => {
              changeAccountValue(event.target.value, event.target.name);
            }}
          />
          <TextField
            required
            style={{width: '100%' , margin: '10px 0'}}
            id="outlined-required"
            label="Ville"
            defaultValue=""
            name="accountAdresseCity" 
            onChange={(event) => {
              changeAccountValue(event.target.value, event.target.name);
            }}
          />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            style={{padding: '10px', borderRadius: '5px'}}
            onClick={() => {
              if (accountAdresseNumStreet !== '' && accountAdresseStreet !== '' && accountAdresseCode !== '' && accountAdresseCity !== '') {
                if(userRole === 'particulier') {
                  fetchPutAccountP()
                } else if (userRole === 'entreprise') {
                  fetchPutAccountE()
                }
              } else {
                toast(`Merci de remplir tous les champs`, {
                  position: "bottom-right",
                  type: "error",
                  theme:"colored",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                })
              }
            }}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      :
      <section className="right-wrapper">
        {/** Affichage pour un particulier **/}
        { userRole === 'particulier' && 
        <>
        { userInfo !== {} && userInfo.data.length > 0 ?
        <>
        {userRole === 'particulier' &&
          <section className="features-wrapper">
            <div className="course-wrapper">
              <h3 className="bold uppercase">
                Espace formation
                <Link to="/formation" className='link-course'><button className=""><span className='bold'>Rechercher</span> <FaSearch /></button></Link>
              </h3>
              <div className="course-count">
                <div className="demandes">
                <div 
                  className='drop-down-1' 
                  onClick={() => {
                    setMenuOne(!menuOne)
                  }}>
                  <h4 className="bold">Demandes de formation</h4>
                  <div className="bold info-dyna">{demandeFormation(userInfo.data[0].attributes.facturation_formations.data).length} en cours</div>
                  <div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
                    {menuOne ? 
                      <MdArrowDropUp className="arrow-down" />
                    :
                      <MdArrowDropDown className="arrow-down"/>
                    }
                </div>
                </div>
                  {demandeFormation(userInfo.data[0].attributes.facturation_formations.data).length > 0 && menuOne &&
                  <div className='formations-wrapper'>
                    {demandeFormation(userInfo.data[0].attributes.facturation_formations.data).map((course, index) => ( 
                      <div  key={index} onClick={() => changeClickedFacture(course.id)}>
                        <Link to="/account/financements">
                          <p className="list-paragraphe"><FaFileAlt />{course.attributes.nom_formation}</p>
                          <span className="bold">Mon  financement</span>
                        </Link>
                      </div>
                    ))}
                  </div>
                  }
                </div>
                
                <div className="en-cours">
                <div className='drop-down-1' onClick={() => setMenuTwo(!menuTwo)}>
                <h4 className="bold">Vos sessions en cours</h4>
                <div className="bold info-dyna">{enCoursFormation(userInfo.data[0].attributes.facturation_formations.data).length} formation{enCoursFormation(userInfo.data[0].attributes.facturation_formations.data).length > 1 ? 's':''}</div>
                  <div className='arrow-wrapper' onClick={() => setMenuTwo(!menuTwo)}>
                    {menuTwo ? 
                      <MdArrowDropUp className="arrow-down" />
                    :
                      <MdArrowDropDown className="arrow-down"/>
                    }
                </div>
                </div>
                  {enCoursFormation(userInfo.data[0].attributes.facturation_formations.data).length > 0 && menuTwo &&
                  <div className='formations-wrapper'>
                    {enCoursFormation(userInfo.data[0].attributes.facturation_formations.data).map((course, index) => (
                      <div key={index}>
                        {course.attributes.formation.data.attributes.lms_formation_id === 'formapro' ?
                        <Link to='/connexion-lms'>Accéder à la formation</Link>
                        :
                        <a href={course.attributes.formation.data.attributes.lms_formation_id === 'univo' ? 'https://wefor.cloudplateforme.com/auth/login' : `https://elearning.wefor.fr/`} className="bold">
                        <p className="list-paragraphe"><FaFileAlt />{course.attributes.nom_formation}</p>
                        <span className="bold">
                          Accéder à la formation
                        </span>
                        </a>
                        }
                      </div>
                    ))}
                  </div>
                  }
                </div>
              </div>
            </div>
            <div className="job-wrapper">
              <h3 className="bold uppercase">Espace emploi <Link to="/job" className='link-job-bis'><button className=""><span className='bold'>Rechercher</span> <FaSearch /></button></Link></h3>
              <div className="job-count">
                <div className="offres">
                  <div 
                    className='drop-down-2' 
                    onClick={() =>   setMenuThree(!menuThree)}>
                    <h4 className="bold">Vos demandes </h4>
                    <div className="bold info-dyna">{userInfo.data[0].attributes.offre_emplois_postule.data === null ? '0' : userInfo.data[0].attributes.offre_emplois_postule.data.length} offre{userInfo.data[0].attributes.offre_emplois_postule.data !== null && userInfo.data[0].attributes.offre_emplois_postule.data.length > 1 ? 's' : ''} d'emploi{userInfo.data[0].attributes.offre_emplois_postule.data !== null && userInfo.data[0].attributes.offre_emplois_postule.data.length > 1 ? 's' : ''}</div>
                    <div className='arrow-wrapper' onClick={() => setMenuThree(!menuThree)}>
                      {menuThree ? 
                        <MdArrowDropUp className="arrow-down" />
                      :
                        <MdArrowDropDown className="arrow-down"/>
                      }
                  </div>
                  </div>
                  {userInfo.data[0].attributes.offre_emplois_postule.data.length > 0 && menuThree &&
                  <div className='formations-wrapper'>
                    {userInfo.data[0].attributes.offre_emplois_postule.data.map((course, index) => (
                      <Link to="/job" className='single'>
                      <p className="list-paragraphe" key={index}><FaBriefcase /> {course.attributes.nom}</p>
                      <span className="bold">Plus d'offres</span>
                      </Link>
                    ))}
                  </div>
                  }
                </div>
                <div className="test">
                  {userInfo.data[0].attributes.test_ocean.data !== null ?
                  <>
                    <div className='drop-down-2' onClick={() => setMenuFourth(!menuFourth)}>
                      <h4 className="bold">Votre test de personnalité</h4>
                      <div className="bold info-dyna">résultat</div>
                        <div className='arrow-wrapper' onClick={() => setMenuFourth(!menuFourth)}>
                          {menuFourth ? 
                            <MdArrowDropUp className="arrow-down" />
                          :
                            <MdArrowDropDown className="arrow-down"/>
                          }
                      </div>
                    </div>
                    <div className='formations-wrapper'>
                    {menuFourth &&
                      <div className="result-txt-wrapper">
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) < 31 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaBrain />Ouverture d’esprit</p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) < 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaBrain />Ouverture d’esprit </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) >= 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaBrain />Ouverture d’esprit </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) < 31 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaLightbulb/>Conscience </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) < 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaLightbulb />Conscience</p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) >= 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaLightbulb />Conscience </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) < 31 &&
                      <Link to="/account/ocean">
                      <div>
                        <p><FaArrowsAlt/>Extraversion</p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) < 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaArrowsAlt/> Extraversion </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) >= 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaArrowsAlt/> Extraversion </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) < 31 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaHandsHelping />Altruisme </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) < 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaHandsHelping />Altruisme </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) >= 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaHandsHelping />Altruisme </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) < 31 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaAngry />Névrosisme </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (10 * 5) * 100) < 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaAngry />Névrosisme </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      {(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) >= 71 && 
                      <Link to="/account/ocean">
                      <div>
                        <p><FaAngry />Névrosisme </p>
                        <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100))} / 100</span>
                      </div>
                      </Link>
                      }
                      </div>
                    }
                    </div>
                  </>
                  :
                  <Link to="/account/ocean">
                    <div className='drop-down-2'>
                      <h4 className="bold">Passez le test OCEAN</h4>
                      <div className="bold info-dyna"></div>
                      <div className='arrow-wrapper'>
                        <FaPlus className="plus" />
                      </div>
                    </div>
                  </Link>
                  }
                </div>
              </div>
            </div>
          </section>
        }
        </>
        :
        <>
        <section className="skeleton-section">
          <Skeleton variant="rectangular" height={62} className="title-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        </section>
        <section className="skeleton-section">
          <Skeleton variant="rectangular" height={62} className="title-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        </section>
        </>
        }
        </>
        }
        {/** Affichage pour une entreprise **/}
        {userRole === 'entreprise' &&
        <>
        { userInfo !== {} && userInfo.data.length > 0 ?
          <>
        {userRole === 'entreprise' &&
          <section className="features-wrapper">
          <div className="course-wrapper">
            <h3 className="bold uppercase">Espace formation <Link to="/formation" className='link-course'><button className=""><span className='bold'>Rechercher</span> <FaSearch /></button></Link></h3>
            <div className="course-count">
              <div className="demandes">
                <Link to="/formation">
                <div className='drop-down-1'>
                  <h4 className="bold">Formez vos collaborateurs</h4>
                  <div className="bold info-dyna">84 formations disponibles</div>
                  <div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
                  <FaPlus className="plus-orange" />
                </div>
                </div>
                </Link>
              </div>
              <div className="en-cours">
              <div className='drop-down-1' onClick={() => setMenuTwo(!menuTwo)}>
                <h4 className="bold">Vos collaborateurs</h4>
                  <div className="bold info-dyna">{userInfo.data[0].attributes.particuliers.data.length} collaborateur{userInfo.data[0].attributes.particuliers.data.length > 1 ? 's':''}</div>
                  <div className='arrow-wrapper' onClick={() => setMenuTwo(!menuTwo)}>
                    {menuTwo ? 
                      <MdArrowDropUp className="arrow-down" />
                    :
                      <MdArrowDropDown className="arrow-down"/>
                    }
                </div>
              </div>
                {userInfo.data[0].attributes.particuliers.data.length > 0 && menuTwo &&
                <div className='formations-wrapper'>
                  {userInfo.data[0].attributes.particuliers.data.map((part, index) => (
                    <div key={index}>
                      <Link to="/account/course">
                        <p className="list-paragraphe"><FaFileAlt />{part.attributes.user.data.attributes.prenom} {part.attributes.user.data.attributes.nom}</p>
                        <span className="bold">Plus de details</span>
                      </Link>
                    </div>
                  ))}
                </div>
                }
              </div>
            </div>
          </div>
          <div className="job-wrapper">
            <h3 className="bold uppercase">Espace emploi</h3>
            <div className="job-count">
              <Link to="/account/jobs" className="offres" onClick={() => setModalJobsAccount(true)}>
              <div className="">
              <div className='drop-down-2'>
                <h4 className="bold">Créez une offre d'emploi</h4>
                  <div className="bold info-dyna"></div>
                  <div className='arrow-wrapper'>
                    <FaPlus className="plus" />
                </div>
              </div>
              </div>
              </Link>
              <div className="offres">
              <div className='drop-down-2' onClick={() => setMenuThree(!menuThree)}>
                <h4 className="bold">Voir mes candidats</h4>
                  <div className="bold info-dyna">{userInfo.data[0].attributes.offre_emplois.data === null ? '0' : userInfo.data[0].attributes.offre_emplois.data.length} offre{userInfo.data[0].attributes.offre_emplois.data !== null && userInfo.data[0].attributes.offre_emplois.data.length > 1 ? 's' : ''} d'emploi{userInfo.data[0].attributes.offre_emplois.data !== null && userInfo.data[0].attributes.offre_emplois.data.length > 1 ? 's' : ''}</div>
                  <div className='arrow-wrapper' onClick={() => setMenuThree(!menuThree)}>
                    {menuThree ? 
                      <MdArrowDropUp className="arrow-down" />
                    :
                      <MdArrowDropDown className="arrow-down"/>
                    }
                </div>
              </div>
                {userInfo.data[0].attributes.offre_emplois.data !== null && userInfo.data[0].attributes.offre_emplois.data.length > 0 && menuThree &&
                <div className='formations-wrapper'>
                  {userInfo.data[0].attributes.offre_emplois.data.map((job, index) => (
                    <div key={index} onClick={() => setOffreIdHP(job.id) }>
                      <Link to="/account/candidats">
                        <p className="list-paragraphe"><FaBriefcase /> {job.attributes.nom}</p>
                        <span className="bold"><NbPart job={job} /></span>
                      </Link>
                    </div>
                  ))}
                </div>
                }
              </div>
              <div className="test">
                {userInfo.data[0].attributes.test_oceans !== undefined && userInfo.data[0].attributes.test_oceans.data !== null &&
                <>
                <div className='drop-down-2' onClick={() => setMenuFourth(!menuFourth)}>
                  <h4 className="bold">Test de personnalité de vos managers</h4>
                    <div className="bold info-dyna">{userInfo.data[0].attributes.test_oceans.data.length} test{userInfo.data[0].attributes.test_oceans.data.length > 1 ? 's' : ''} réalisé{userInfo.data[0].attributes.test_oceans.data.length > 1 ? 's' : ''}</div>
                    <div className='arrow-wrapper' onClick={() => setMenuFourth(!menuFourth)}>
                      {menuFourth ? 
                        <MdArrowDropUp className="arrow-down" />
                      :
                        <MdArrowDropDown className="arrow-down"/>
                      }
                  </div>
                </div>
                <div className='formations-wrapper'>
                  {menuFourth && userInfo.data[0].attributes.test_oceans.data.map((manager, index) => (
                    <div key={index}>
                      <Link to="/account/ocean">
                        <p className="list-paragraphe"><FaUserTie /> {manager.attributes.nom} {manager.attributes.prenom}</p>
                        <span className="bold">Voir les résultats</span>
                      </Link>
                    </div>
                    ))}
                </div>
                </>
                }
              </div>
            </div>
          </div>
          <div className="doc-wrapper">
            <h3 className="bold uppercase">Espace documentaire</h3>
            <div className="test">
              {userInfo.data[0].attributes.convention.data !== undefined &&
              <>
              <div className='drop-down-2' onClick={() => setMenuFifth(!menuFifth)}>
                <h4 className="bold">Convention de recrutement</h4>
                  <div className="bold info-dyna">1 document</div>
                  <div className='arrow-wrapper' onClick={() => setMenuFifth(!menuFifth)}>
                    {menuFifth ? 
                      <MdArrowDropUp className="arrow-down" />
                    :
                      <MdArrowDropDown className="arrow-down"/>
                    }
                </div>
              </div>
              {menuFifth &&
              <div className='formations-wrapper'>
                <Link 
                    to="/account/documents"
                    onClick={() => setMenuDocument('recrutement')}
                  >
                  <p className="list-paragraphe"><FaFileAlt /> Convention WEFOR</p>
                  <span className={userInfo.data[0].attributes.convention.data !== null ? 'statut green' : 'statut orange'}>{userInfo.data[0].attributes.convention.data !== null ? 'Signée' : 'Non signée'}</span>
                </Link>
                </div>
                }
              </>
              }
            </div>
            <div className="test">
              {factureE !== undefined && factureE.length > 0 &&
              <>
              <div className='drop-down-2' onClick={() => setMenuSixth(!menuSixth)}>
                <h4 className="bold">Vos contrats</h4>
                  <div className="bold info-dyna">{factureE.length} document{factureE.length > 1 ?"s" : ""}</div>
                  <div className='arrow-wrapper' onClick={() => setMenuSixth(!menuSixth)}>
                    {menuSixth? 
                      <MdArrowDropUp className="arrow-down" />
                    :
                      <MdArrowDropDown className="arrow-down"/>
                    }
                </div>
              </div>
              {menuSixth &&
              <div className='formations-wrapper'>
                {factureE.map((facture, index) => (
                  <Link 
                  to="/account/documents"
                  onClick={() => setMenuDocument('contrats')}
                >
                  <p key={index} className="list-paragraphe"><FaFileAlt /> contrat n°{facture.id}</p>
                  <span className={facture.attributes.signature ? 'statut green' : 'statut orange'}>{facture.attributes.signature ? 'Signé' : 'Non signé'}</span>
                  </Link>
                ))}
                </div>
                }
              </>
              }
            </div>
            <div className="test">
              {factureE !== undefined && factureE.length > 0 &&
              <>
              <div className='drop-down-2' onClick={() => setMenuSeventh(!menuSeventh)}>
                <h4 className="bold">Vos factures</h4>
                  <div className="bold info-dyna">{factureE.length} document{factureE.length > 1 ?"s" : ""}</div>
                  <div className='arrow-wrapper' onClick={() => setMenuSeventh(!menuSeventh)}>
                    {menuSeventh ? 
                      <MdArrowDropUp className="arrow-down" />
                    :
                      <MdArrowDropDown className="arrow-down"/>
                    }
                </div>
              </div>
              {menuSeventh &&
              <div className='formations-wrapper'>
                {factureE.map((facture, index) => (
                  <Link 
                  to="/account/documents"
                  onClick={() => setMenuDocument('factures')}
                >
                  <p key={index} className="list-paragraphe"><FaFileAlt /> Facture n°{facture.id}</p>
                  </Link>
                ))}
                </div>
                }
              </>
              }
            </div>
          </div>
        </section>
        }
        </>
        :
        <>
        <section className="skeleton-section">
          <Skeleton variant="rectangular" height={62} className="title-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        </section>
        <section className="skeleton-section">
          <Skeleton variant="rectangular" height={62} className="title-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        </section>
        <section className="skeleton-section">
          <Skeleton variant="rectangular" height={62} className="title-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
          <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        </section>
        </>
        }
        </>
        }
        </section>
      }
        
      </>
      }
      </main>
    </AccountHomeStyled>
  )
}

AccountHome.propTypes = {
  currentProfilUser: PropTypes.string.isRequired
}

export default AccountHome