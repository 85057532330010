import { 
  SET_SUBMENU_JOB_SECTEUR_ACTIV,
  SET_SUBMENU_JOB_DOMAINE_ACTIV,
  SET_SUBMENU_JOB_FORMATION_ACTIV,
  SET_SUBMENU_COURSE_SECTEUR_ACTIV,
  SET_SUBMENU_COURSE_DOMAINE_ACTIV,
  SET_SUBMENU_COURSE_FORMATION_ACTIV,
  CHANGE_CURRENT_DOMAINE_CLICKED,
  CHANGE_CURRENT_PROFIL_USER,
  SAVE_SECTEURS,
  SAVE_DOMAINES,
  SAVE_FICHE,
  SET_FILTER,
  SET_IS_SHOW_MENU,
  SET_IS_SHOW_ACCOUNT_FORM_MENU,
  SET_MENU_DOCUMENT,
  SET_MENU_FORMATION,
  SET_FILTER_ID,
  SET_CURRENT_URL
} from "../actions/menu";
import { DISCONNECT_USER } from "../actions/login";


export const initialState = {
  submenuJobSecteurActiv: "",
  submenuJobDomaineActiv: "",
  submenuJobFormationActiv: "",
  submenuCourseSecteurActiv: "",
  submenuCourseDomaineActiv: "",
  submenuCourseFormationActiv: "",
  secteursData: [],
  domainesData: [],
  ficheData: [],
  niveauxData: [],
  currentDomaineClicked: 1,
  currentProfilUser: 'notChoose',
  secteurJobFilter: '',
  filterSecteurId: '',
  domaineJobFilter: '',
  filterDomaineId: '',
  cpfCourseFilter: '',
  filterCpfId: '',
  formationJobFilter: '',
  kmJobFilter: '',
  secteurCourseFilter: '',
  domaineCourseFilter: '',
  formationCourseFilter:'',
  dureeCourseFilter: '',
  isShowMenu: false,
  isShowAccountFormMenu: false,
  menuDocument: 'contrats',
  menuFormation: 'terminée',
  currentUrl:'/'
};

// reducer = traducteur d'une intention/action vers une modification du state
const menu = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SUBMENU_JOB_SECTEUR_ACTIV:
      return {
        ...state,
        submenuJobSecteurActiv: action.name,
      };
    case SET_SUBMENU_JOB_DOMAINE_ACTIV:
      return {
        ...state,
        submenuJobDomaineActiv: action.name,
      };
    case SET_SUBMENU_JOB_FORMATION_ACTIV:
      return {
        ...state,
        submenuJobFormationActiv: action.name,
      };
    case SET_SUBMENU_COURSE_SECTEUR_ACTIV:
      return {
        ...state,
        submenuCourseSecteurActiv: action.name,
      };
    case SET_SUBMENU_COURSE_DOMAINE_ACTIV:
      return {
        ...state,
        submenuCourseDomaineActiv: action.name,
      };
    case SET_SUBMENU_COURSE_FORMATION_ACTIV:
      return {
        ...state,
        submenuCourseFormationActiv: action.name,
      };
    case CHANGE_CURRENT_DOMAINE_CLICKED:
      return {
        ...state,
        currentDomaineClicked: action.id,
      };
    case CHANGE_CURRENT_PROFIL_USER:
      return {
        ...state,
        currentProfilUser: action.name,
      };
    case SAVE_SECTEURS:
      return {
        ...state,
        secteursData: action.secteurs,
      }
    case SAVE_DOMAINES:
      return {
        ...state,
        domainesData: action.domaines,
      }
    case SAVE_FICHE:
      return {
        ...state,
        ficheData: action.data,
      }
    case SET_FILTER:
      return {
        ...state,
        [action.title + 'Filter']: action.name,
      }
    case SET_FILTER_ID:
      return {
        ...state,
        [action.title]: action.name,
      }
    case SET_IS_SHOW_MENU:
      return {
        ...state,
        isShowMenu: action.bool,
      }
    case SET_IS_SHOW_ACCOUNT_FORM_MENU:
      return {
        ...state,
        isShowAccountFormMenu: action.bool,
      }
    case DISCONNECT_USER:
      return {
        ...state,
        currentProfilUser: 'notChoose',
      }
    case SET_MENU_DOCUMENT:
      return {
        ...state,
        menuDocument: action.value
      }
    case SET_MENU_FORMATION:
      return {
        ...state,
        menuFormation: action.value
      }
    case SET_CURRENT_URL:
      return {
        ...state,
        currentUrl: action.string
      }
    default:
      return state;
  }
};

export default menu;