/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import * as XLSX from 'xlsx/xlsx.mjs'
import { MdOutlineFileUpload, MdDeleteOutline } from "react-icons/md"

import urlBackEndSave from '../../assets/js/urlBackEndSave'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import ApprenantCard from '../../containers/ApprenantCard'

/**** Import des images ****/

/**** Import des css ****/
import AccountAdminApprenantStyled from './AccountAdminApprenantStyled'

const AccountAdminApprenant = ({
  saveDataApprenantFromCSV,
  apprenantFromCSV
}) => {

  const [fileName, setFileName] = useState('not loaded');

  const readUploadFile = (e) => {
    e.preventDefault();
    const arrayC = ['Accepté', 'Accept']
    function contains(target, pattern){
      var value = 0;
      pattern.forEach(function(word){
        value = value + target.includes(word);
      });
      return (value === 1)
  }
    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { 
              type: "array",
              cellDates: true,
            });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const json = XLSX.utils.sheet_to_json(worksheet);

            const jsonFiltered = json.filter(obj => contains(obj.STATUT_DOSSIER, arrayC))
            saveDataApprenantFromCSV(jsonFiltered);

        };
        setFileName(e.target.files[0].name)
        reader.readAsArrayBuffer(e.target.files[0]);
    }
  }
  
  return (
    <AccountAdminApprenantStyled>
      <Header bcgColor={'#fff'}/>
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
      {fileName === 'not loaded' ? 
      <form className='excel-form'>
        <label htmlFor="upload" className="excel-label">
          <p className='bold'>Importer les données D'EDOF</p>
          <MdOutlineFileUpload />
        </label>
        <input
            type="file"
            name="upload"
            id="upload"
            onChange={readUploadFile}
        />
      </form>
      :
      <div
        className="excel-delete"
        onClick={() => {
        setFileName('not loaded')
        saveDataApprenantFromCSV('')
      }}>
        <p className='bold'>{fileName}</p>
        <MdDeleteOutline />
      </div>
      }

      {apprenantFromCSV !== '' && apprenantFromCSV.length > 0 && 
        <div className='cards-wrapper'>
          {apprenantFromCSV.map((apprenant, index) => (
            <ApprenantCard key={index} apprenantFromState={apprenant} index={index} />
          ))}
        </div>
      }
      </section>
      </main>
    </AccountAdminApprenantStyled>
  )
}

AccountAdminApprenant.propTypes = {
}

export default AccountAdminApprenant