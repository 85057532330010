import styled from 'styled-components';

const HeaderMobileStyled = styled.div`
  display: none;

  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 759px) {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    
    .top-wrapper {
        width: 100vw;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${props => props.windowTop ? props.bcgColor === '#fff' ? '#fff' : '' : '#fff'};

        .btn-connexion {
            font-size: 16px;
            color: #fff;
            background-color: #6399ce;
            border-radius: 5px;
            padding: 8px 15px;
            width: 150px;
            text-align: center;
            
            svg {
                font-size: 14px;
                margin-right: 5px;
            }
        }

        .btn-logout {
            font-size: 16px;
            color: #fff;
            background-color: #dc4a46;
            border-radius: 5px;
            padding: 8px 15px;
            width: 150px;
            text-align: center;
            
            svg {
                font-size: 16px;
                margin: 0 5px -3px 0;
            }
        }

        img {
            margin-left: 15px;
            max-width: 100px;
            height: auto;
        }
    }

    .burger-icon {
      font-size: 40px;
      color: #202c3c;
      cursor: pointer;
      margin: 10px 10px 0 0;
      transform: ${({ isOpen }) => (!isOpen ? 'translateX(0px)' : 'translateX(70px)')};
      transition: transform 0.5s ease-in-out;
    }

    .burger-list {
      position: fixed;
      top: 0px;
      left: 0px;
      height: 100vh;
      width: 100vw;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
      transition: transform 0.5s ease-in-out;

      .wrapper-menus {
        width: 100%;
      }

      .title-list {
        width: 100%;
        font-size: 20px;
        padding: 20px 0;
        text-align: center;
        background-color: #fff;
        color: #202c3c;

        &.border-bottom {
          border-bottom: 1px solid #202c3c;
        }

        &.borders {
          border-bottom: 1px solid #202c3c;
          border-top: 1px solid #202c3c;
        }
      }

      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 40px;
        color: #202c3c;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
      }

      .burger-nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;

        .title-list {
          background-color: #202c3c;
          color: #fff;
        }

        .nav-item {
            width: 100%;
        }

        .nav-link {
            font-size: 18px;
            width: 100%;
            height: 100%;
            padding: 15px 0 15px 10px;
            font-weight: 700;
            display: flex;
            align-items: end;
            text-decoration: none;
            transition: color 0.3s ease-in-out;

            span {
                margin-left: 20px;
                color: #202c3c;
                border-bottom: 1px solid #202c3c;
                padding-bottom: 4px;
            }

            svg {
                font-size: 26px;
            }

            &:hover {
                color: #d11a2a;
            }

            &.formation-link {
                color: #d47558;
            }

            &.job-link {
                color: #6aaf8b;
            }

            &.financement-link {
                color: #6399ce;
            }

            &.wefor-link {
                color: #D071AB;
            }
        }
      }

      .empty-div {
        width: 100%;

        ul {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          align-items: stretch;
          justify-content: space-evenly;
          margin: 0 0 20px 0;
        }
        

        .nav-link {
          font-size: 17px;
          height: 100%;
          width: 32%;
          padding: 15px 5px 15px 5px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          color: #202c3c;
          text-align: center;
          border: 2px solid #202c3c;
          border-radius: 5px;
          min-height: 70px;
          cursor: pointer;

          &:nth-child(1) {
            background-color: #ECF3EF;
            color: #6AAF8B;
            border: 2px solid #6AAF8B;

            &.active {
              color: #fff;
              background-color: #6AAF8B;
  
            }
          }
          &:nth-child(2) {
            background-color: #F7EDEA;
            color: #D47558;
            border: 2px solid #D47558;

            &.active {
              color: #fff;
              background-color: #D47558;
  
            }
          }
          &:nth-child(3) {
            background-color: #dbe8f4;
            color: #649ACE;
            border: 2px solid #649ACE;

            &.active {
              color: #fff;
              background-color: #649ACE;
  
            }
          }

          svg {
              font-size: 24px;
              color: #39a5d6;
          }

          span {
            width: 100%;
          }

          &.active {
            color: #fff;
            background-color: #202c3c;

          }
        }


        .choose-statut { 
          text-align: center;
          margin-top: 20px;
          font-size: 26px;
          color: #39a5d6;

          .bounce-item {
            animation-name: bounce;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-iteration-count: infinite;
          }

          svg {
            font-size: 26px;
          }
        }
      }

      .access-dashbord {
        background-color: #39a5d6;
        padding: 15px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 20px;
        color: #fff;
      }

      .bottom-nav {
        display: flex;
        justify-content: space-evenly;
        padding: 10px 0;
        width: 100%;

        .link-item {
          font-size: 22px;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          color: #202c3c;

          &:hover {
            color: #d11a2a;
          }
        }
      }
    }
  }
`;

export default HeaderMobileStyled;