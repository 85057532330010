import styled from 'styled-components';

const AttestationCourseStyled = styled.article`
.tab-wrapper {
    margin: 20px auto;
    width: 96%;
    box-shadow: 0px 3px 10px #00000059;

    .legend {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #d4d4d4;
        background-color: #dbe8f4;

        .row {
            width: 33.333%;
            padding: 10px 0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-right: 1px solid #d4d4d4;

            &.last {
                width: 33.333%;
            }
        }
    }

    .full-width-w {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .wrapper-skeleton {
        width: 33.333%;
        box-sizing: border-box;
        color: #4d4d4d;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-right: 1px solid #d4d4d4;
        text-align: center;
        padding: 5px;

        .skeleton-txt {
            width: 100%;
            height: 20px;
        }

        .skeleton-statut {
            width: 100%;
            height: 30px;
            margin-top: 5px;
            border-radius: 5px;
        }
    }
}

.facture-comp-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    border-bottom: 0;

    .info-wrapper {
        width: 33.333%;
        box-sizing: border-box;
        color: #4d4d4d;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-right: 1px solid #d4d4d4;
        text-align: center;

        &.pad {
            padding: 10px 5px;
        }

        .top {
            padding-bottom: 5px;
            text-align: center;
        }

        .btn-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .btn-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .dl-btn {
            border: 0;
            background-color: #202c3c;
            color: #fff;
            width: 100%;
            height: 100%;
            padding: 5px 0;
            cursor: pointer;
            transition: all .2s ease;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-right: 10px;
            }

            &:hover {
                opacity: .8;
            }
        }

        .dn-btn {
            border: 0;
            background-color: #dfdfdf;
            color: #fff;
            width: 100%;
            height: 100%;
            padding: 5px 0;
        }
    }

    .statut-wrapper {
        width: 33.333%;
        padding: 10px 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.orange {
            background-color: #d47558;
        }

        &.green {
            background-color: #6aaf8b; 
        }
    }
}

    @media only screen and (max-width: 1400px) {
    }

    @media only screen and (min-width: 760px) and (max-width: 1079px) {
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
      .info-wrapper {
        font-size: 14px;

        .little {
            font-size: 12px;
        }
      }

      .dl-btn, .dn-btn {
        font-size: 12px;

        svg {
            display: none;
        }
      }
    }
`;

export default AttestationCourseStyled;