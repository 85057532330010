import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { Document, Page } from "react-pdf";
import { FaFilePdf } from "react-icons/fa";
import urlBackEndSave from "../../../assets/js/urlBackEndSave";

const PdfProgramme = ({ programme_pdf }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderPDF = () => {
    const pages = [];

    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
      pages.push(
        <Page
          key={pageNumber}
          pageNumber={pageNumber}
          width={window.innerWidth > 1000 ? 800 : window.innerWidth * 0.83}
          style={{ paddingTop: "10px" }}
        />
      );
    }

    return (
      <div
        style={{
          width: "100%",
          height: "60vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "900px",
          transform: "scale(1.1)",
        }}
      >
        {pages}
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <p
        onClick={openModal}
        style={{
          cursor: "pointer",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        Certificat Qualiopi
      </p>

      <Modal open={isOpen} onClose={closeModal}>
        <Modal.Content
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "65vh",
            paddingLeft: "1rem!important",
          }}
          scrolling
        >
          <Document file={programme_pdf} onLoadSuccess={onDocumentLoadSuccess}>
            {renderPDF()}
          </Document>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} negative>
            Fermer
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default PdfProgramme;
