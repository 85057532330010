import { 
    SAVE_GAINS
  } from "../actions/godfather"
  
  export const initialState = {
    gains: []
  };
  
  // reducer = traducteur d'une intention/action vers une modification du state
  const godfather = (state = initialState, action = {}) => {
    switch (action.type) {
      case SAVE_GAINS:
        return {
          ...state,
          gains: [ 
            ...state.gains,
            action.data[0]
          ]
        };
      default:
        return state;
    }
  };
  
  export default godfather