import { connect } from 'react-redux'

import AccountCourse from '../../pages/AccountCourse'

import { fetchAccountFormations, fetchParticuliers, addParticulierOnEntreprise } from '../../actions/login'
import { fetchUserScore } from '../../actions/lms'
import { fetchMySalarie, setSalarieClicked, fetchGetCollabCourses, fetchGetSessionsOnOff, fetchGetInscriptionsFromSessions, fetchFactureP, fetchFactureCourse } from '../../actions/course'
import { setMenuFormation, setCurrentUrl } from '../../actions/menu'
import { toggleModalSatisfaction, openModal } from '../../actions/modal'

const mapStateToProps = (state) => ({
    user: state.login.session.user,
    userInfo: state.login.userInfo,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    formationsEnCours: state.login.formationsEnCours,
    formationsTerminee: state.login.formationsTerminee,
    formationsDemande: state.login.formationsDemande,
    particuliers: state.login.particuliers,
    userScore: state.lms.userScore,
    menuFormation: state.menu.menuFormation,
    myEntreprisePart: state.course.myEntreprisePart,
    salarieClickedId: state.course.salarieClickedId,
    salarieClickedName:  state.course.salarieClickedName,
    collabCoursesOn: state.course.collabCoursesOn,
    collabCoursesOff: state.course.collabCoursesOff,
    factureEStarted: state.course.factureEStarted,
    inscriptionsClickedSession: state.course.inscriptionsClickedSession,
    isModalSatisfactionOpen: state.modal.isModalSatisfactionOpen,
    factureP: state.course.factureP,
    factureCourse: state.course.factureCourse
})

const mapDispatchToProps = (dispatch) => ({
    fetchAccountFormations: () => {
        dispatch(fetchAccountFormations());
    },
    fetchFactureP: () => {
        dispatch(fetchFactureP());
    },
    fetchParticuliers: () => {
        dispatch(fetchParticuliers());
    },
    addParticulierOnEntreprise: (id) => {
        dispatch(addParticulierOnEntreprise(id));
    },
    addParticulierOnEntreprise: (id) => {
        dispatch(addParticulierOnEntreprise(id));
    },
    fetchUserScore: () => {
        dispatch(fetchUserScore());
    },
    fetchMySalarie: () => {
        dispatch(fetchMySalarie());
    },
    setSalarieClicked: (name, id) => {
        dispatch(setSalarieClicked(name, id));
    },
    setMenuFormation: (value) => {
        dispatch(setMenuFormation(value));
    },
    fetchGetCollabCourses: (collabId) => {
        dispatch(fetchGetCollabCourses(collabId));
    },
    fetchGetSessionsOnOff: () => {
        dispatch(fetchGetSessionsOnOff());
    },
    fetchGetInscriptionsFromSessions: (date, courseId, entrepriseId) => {
        dispatch(fetchGetInscriptionsFromSessions(date, courseId, entrepriseId));
    },
    toggleModalSatisfaction: (bool) => {
        dispatch(toggleModalSatisfaction(bool));
    },
    openModal: (name) => {
        dispatch(openModal(name));
    },
    setCurrentUrl: (string) => {
        dispatch(setCurrentUrl(string));
    },
    fetchFactureCourse: () => {
        dispatch(fetchFactureCourse())
    }
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountCourse)