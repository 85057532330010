
  export const initialState = {
    maintenance: false
  };
  
  // reducer = traducteur d'une intention/action vers une modification du state
  const maintenance = (state = initialState, action = {}) => {
    switch (action.type) {
      default:
        return state;
    }
  };
  
  export default maintenance;