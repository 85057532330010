/**** Import des modules ****/
import React, {useEffect} from 'react'
import { Link, useLocation } from "react-router-dom"
import ReactMarkdown from 'react-markdown'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import FullPageLoader from '../../containers/FullPageLoader'



/**** Import des images ****/

import contrat from '../../assets/img/illustration/contrat.png'

import logo from '../../assets/img/illustration/offre_emploi.png'

/**** Import des datas JS ****/
import datagraph from '../../assets/js/datagraph'

/**** Import des css ****/
import JobFicheStyled from './JobFicheStyled'

import replaceHyphensWithSpaces from '../../assets/js/replaceHyphensWithSpaces'

const JobFiche = ({ fetchThisJobFicheWithSlug, slugFiche }) => {

  const location = useLocation();
  
  useEffect((() => {
    fetchThisJobFicheWithSlug(replaceHyphensWithSpaces(location.pathname))
  }), [])

  const separateTxt = (str) => {
    const array = str.split('/\n')

    return array
  }

  const separateCode = (str, index) => {
    const array = str.split('-')

    return array[index]
  }


  return (
    <JobFicheStyled>
      <Header />
      {slugFiche === 'loading' ?
        <section className="illustration-header">
          <FullPageLoader />
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
          <div className="title-wrapper-prog">
              <h2 className="bold border">Fiche métier</h2>
              <h1 className="bold"></h1>
          </div>
        </div>
        </div>
      </section>
      :
      <>
      {slugFiche[0] !== undefined &&
        <>
          <section className="illustration-header">
            <div className="opacity-light-section">
            <img src={logo} alt='logo wefor'/>
              <div className="left">
              <div className="title-wrapper-prog">
                  <h2 className="bold border">Fiche métier</h2>
                  <h1 className="bold">{slugFiche[0].attributes.nom}</h1>
              </div>
            </div>
            </div>
          </section>
          <main>
            <section className="section-job-def">
              <div className="title-wrapper">
                <div className='para-mkd'>
                  <div className='title-wrapper-line'>
                    <h2>Le métier de <span className="green bold">{slugFiche[0].attributes.nom}</span></h2>
                  </div>
                  <ReactMarkdown>{slugFiche[0].attributes.description}</ReactMarkdown>
                </div>

                <div className='list-job'>
                <div className='title-wrapper-line'>
                  <h2 className='bold link-pole-title'>Liens vers les offres Pôle Emploi</h2>
                </div>
                <ul>
                  {separateTxt(slugFiche[0].attributes.perspectives_emploi).map((jobItem, index) => (
                    <li key={index} className='link-offer'><a href={`https://candidat.pole-emploi.fr/offres/recherche?motsCles=${separateCode(slugFiche[0].attributes.evolution, index)}&offresPartenaires=true&rayon=10&tri=0`} target="_blank" rel="noopener noreferrer">{jobItem}</a></li>
                  ))}
                </ul>
              </div>
              </div>
            </section>
          </main>
        </>
      }
      </>
      
      }
      <Footer />
    </JobFicheStyled>
  )
}

JobFiche.propTypes = {
}

export default JobFiche