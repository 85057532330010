import React, { useState, useEffect } from 'react';
import PopupKnowMoreStyled from './PopupKnowMoreStyled';
import { Button, Icon, TextField, Paper, Typography } from "@material-ui/core";
import { GoX } from "react-icons/go";
import SendIcon from '@mui/icons-material/Send';
import { Modal } from 'semantic-ui-react'
//

const PopupKnowMore = ({
	changeInputValue,
	fetchSendEmailContact,
	setFullPageLoaderContact,
	deviceWidth
}) => {
  const [open, setOpen] = useState(deviceWidth < 1080 ? false : true);
  const [openB, setOpenB] = useState(false);
  const [alreadyOpen, setAlreadyOpen] = useState(false);
  const closeModal = () => { setOpen(false); setOpenB(true)}

  useEffect(() => {
	changeInputValue("Demande d'informations prospect", 'objet');
	changeInputValue("Un prospect veut nous contacter via la modal contact wefor", 'message');
	if (deviceWidth < 1080 && openB === false && !alreadyOpen) {
		/*
		setTimeout(() => {
			setOpen(true)
		}, 30000)
		*/
	}
  }, [])

  const handleSubmit = evt => {
    evt.preventDefault()
	fetchSendEmailContact()
	closeModal()
  };

  return (
      <PopupKnowMoreStyled>
		<Modal
			open={open}
			onClose={closeModal}
     	 >
		<Modal.Content image scrolling>
        <div className="modal-enter">
		<div style={{color: '#dc4a46', position: 'absolute', top: '5px', right: '8px', fontSize: '30px', cursor: 'pointer'}} onClick={() => {setOpen(false); setAlreadyOpen(true)}} className="close-btn bold"><GoX /></div>
		<div className="header"><h3 className='bold'>Vous voulez en savoir plus ?</h3></div>
		<div className="content">
			<p>Remplissez notre formulaire de contact, nous vous contacterons dans les plus brefs délais.</p>
			<form onSubmit={handleSubmit}>
				<TextField
					label="Nom"
					name="nom"
					required
					helperText="ex: Doe"
					onChange={(event) => {
						changeInputValue(event.target.value, event.target.name);
					  }}
				/>
				<TextField
					label="Prenom"
					name="prenom"
					required
					helperText="ex: John"
					onChange={(event) => {
						changeInputValue(event.target.value, event.target.name);
					  }}
				/>
				<TextField
					label="Téléphone"
					name="tel"
					required
					helperText="ex: 0610101010"
					onChange={(event) => {
						changeInputValue(event.target.value, event.target.name);
					}}
				/>
				<TextField
					label="Nom d'entreprise"
					name="email"
					helperText="ex: wefor (pas obligatoire)"
					onChange={(event) => {
						changeInputValue(`(nom d'entreprise) ${event.target.value}`, event.target.name);
					}}
				/>
				<div className='btn-container'>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						endIcon={<SendIcon />}
					>
						Envoyer
					</Button>
				</div>
        	</form>
		</div>
        </div>
		</Modal.Content>
		</Modal>
      </PopupKnowMoreStyled>
  );
}


export default PopupKnowMore
