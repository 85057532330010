import { connect } from 'react-redux'

import Header from '../../components/Header'

import { setIsShowAccountFormMenu, changeCurrentProfilUser } from '../../actions/menu'

const mapStateToProps = (state) => ({
    isLogged: state.login.isLogged,
    prenom: state.login.prenom,
    windowTop : state.actions.windowTop,
    user: state.login.session.user,
    isShowAccountFormMenu: state.menu.isShowAccountFormMenu,
    userRole: state.login.userRole
})

const mapDispatchToProps = (dispatch) => ({
    setIsShowAccountFormMenu: (bool) => {
        dispatch(setIsShowAccountFormMenu(bool));
    },
    changeCurrentProfilUser: (name) => {
        dispatch(changeCurrentProfilUser(name));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Header)