import styled from 'styled-components';

const AccountAdminCollabsStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

    .right-wrapper {
        width: calc(96% - 250px);
        margin: 20px auto;

        .skeleton-candidat {
            width: 100%;
            height: 104px;
            margin: -23px auto 0;
            border-radius: 10px;
        }

        h2 {
            text-align: center;
            margin: 50px 0 20px;
            font-size: 30px;
            color: #202D3C;
        }
        
        h3 {
            font-size: 20px;
            padding: 30px 0 20px 0;
        }

        .btn-date {
            cursor: pointer;
            margin: 0 5px;
            padding: 5px;
            background-color: #d47558;
            color: #fff;
            border-radius: 5px;

            &:hover {
                opacity: .7;
            }
        }
        
        .info-list {
            width: 100%;
        
            li {
                padding: 0 0 10px 0;
                display: flex;
                align-items: center;
        
                svg {
                    color: #d47558;
                    margin: 0 5px 0 0 ;
                    font-size: 12px;
        
                    &.number {
                        margin: 0 5px 2px 0;
                    }
                }
        
                span {
                    margin: 0 0 0 5px;
                }
        
                a {
                    margin: 0 0 0 5px;
                }
            }
        }

        .financements-list {
            width: 100%;
            padding: 0px 0 0px;
            box-sizing: border-box;
            position: relative;
            box-shadow: 0px 1px 10px #00000029;
            border-radius: 10px;
    
            &:nth-child(1) {
                margin-bottom: 40px;
                font-size: 16px;
            }
    
            .drop-down-1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                cursor: pointer;
                font-size: 18px;
    
                h4, .info-dyna {
                    width: calc(50% - 25px);
                    text-align: left;
                }
    
                .arrow-wrapper {
                    max-height: 20px;
                    .arrow-down {
                        font-size: 50px;
                        color: #D47558;
                        margin-top: -15px;
                        margin-right: -20px;
                    }
                }
            }
    
            h4 {
                color: #D47558;
                text-align: center;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }
    
            .link-cards {
                margin: 0px 0px;
                text-align: center;
                background-color: #fff;
                transition: font-size .2s ease;
                cursor: pointer;
                color: #D47558;
    
                p {
                    height: 30px;
                    margin: 50px 0 0 0px;
                }
    
                &:hover {
                    font-size: 17px;
                }
            }
    
            .formations-wrapper {
                .links-facture {
                    color: #4d4d4d;
                    display: flex;
                    flex-wrap: wrap;
                    align-items:center;
                    justify-content: space-between;
                    font-size: 16px;
                    border-top: 1px solid #f8e8e3;
                    cursor: pointer;
    
                    span {
                        padding-right: 10px;
                    }
    
                    &:hover {
                        background-color: #f8e8e3;
                    }
                }
            }
    
            .list-paragraphe {
                text-align: left;
                padding: 10px;
                display: flex;
                align-items: center;
                text-transform: capitalize;
    
                a {
                    color: #D47558;
                    margin-left: 20px;
                    transition: all .3s ease;
    
                    &:hover {
                        opacity: .7;
                    }
                }
    
                svg {
                    color: #D47558;
                    margin:0 10px 0 0;
                }
            }
    
            .separator {
                margin: 0 auto;
                font-size: 30px;
                color: #D47558;
                width: 100%;
            }
        }

        .wrapper-collabs {
            width: 100%;
            box-sizing: border-box;
            box-shadow: 0px 1px 10px #00000029;
            border-radius: 10px;
            background-color: #fff;
            display: flex;
            flex-wrap: wrap;
            margin: 30px 0 30px;
            position: relative;

            .card-wrapper {
                width: 100%;
            }

            .lms-title {
                position: absolute;
                width: calc(100% / 3 - 40px);
                padding: 5px 0;
                text-align: center;
                border-radius: 10px;
                left: 20px;
                top: -13px;
                color: #fff;

                &.orange {
                    background-color: #d47558;
                }

                &.blue {
                    background-color: #6399ce;
                }
            }

            .days-remain {
                position: absolute;
                width: 80px;
                height: 26px;
                background-color: #dc4a46;
                color: #fff;
                font-size: 18px;
                left: calc( 50% - 40px);
                top: -13px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .section-top {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #d4d4d4;
                margin: 0 0 0px 0;
                padding: 30px 0px 20px;

                .center {
                    text-align: center;
                }

                div {
                    width: calc(100% / 3);
                    
                }

                .collab, .course {
                    span {
                        color: #D47558;
                    }
                }
            }
        }
    }

    .title-modal {
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(96% - 250px);
        margin: 20px auto;

            h1 {
                font-size: 45px;
            }

            h2 {
                font-size: 35px;
            }
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(96% - 250px);
        margin: 20px auto;

            h1 {
                font-size: 40px;
            }

            h2 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {
        margin: 60px auto 80px;
    
        .right-wrapper {
            width: calc(96%);
            margin: 20px auto;

            h1 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }

            h2 {
                font-size: 25px;
                text-align: center;

                .separator-blue {
                    width: 50px;
                    position: static;
                    margin: 10px auto;
                }
            }
        }
    }
}
  
`;

export default AccountAdminCollabsStyled;