import { connect } from 'react-redux'

import AccountMenu from '../../components/AccountMenu'

import {saveUser, changeIsLogged, disconnectUser} from '../../actions/login'
import { setMenuDocument, setMenuFormation } from '../../actions/menu'

const mapStateToProps = (state) => ({
    currentProfilUser: state.menu.currentProfilUser,
    user: state.login.session.user,
    userInfo: state.login.userInfo,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    menuDocument: state.menu.menuDocument,
    menuFormation: state.menu.menuFormation,
    session: state.login.session,
    login: state.login.email,
    password: state.login.password
})

const mapDispatchToProps = (dispatch) => ({
    saveUser: (data) => {
        dispatch(saveUser(data));
      },
      changeIsLogged: (bool) => {
        dispatch(changeIsLogged(bool));
      },
      disconnectUser: () => {
        dispatch(disconnectUser());
      },
      setMenuDocument: (value) => {
        dispatch(setMenuDocument(value));
      },
      setMenuFormation: (value) => {
        dispatch(setMenuFormation(value));
      },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu)