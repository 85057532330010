import { connect } from 'react-redux'

import JobCard from '../../components/JobCard'
import { changeActivJobId, setJobFicheId, toggleModalPostulerTuto, setJobIdModalPostulerTuto, setCvPosted, setDidTest, setJobForModalPostuler } from '../../actions/job'
import { changeActivCourseId } from '../../actions/course'
import { openModal } from '../../actions/modal'

const mapStateToProps = (state) => ({
    activJobId: state.job.activJobId,
    userRole: state.login.userRole,
    userInfo: state.login.userInfo,
    isLogged: state.login.isLogged,
    jobs: state.job.jobs,
    jobsWithDistances: state.job.jobsWithDistances,
    kmJobFilter: state.menu.kmJobFilter,
    showDistance: state.job.showDistance,
    fullPageLoaderDistance: state.job.fullPageLoaderDistance
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => {
      dispatch(openModal(name));
    },
    changeActivJobId: (id) => {
      dispatch(changeActivJobId(id));
     },
    setJobFicheId: (id) => {
      dispatch(setJobFicheId(id));
    },
    changeActivCourseId: (id) => {
      dispatch(changeActivCourseId(id));
    },
    toggleModalPostulerTuto: (bool) => {
      dispatch(toggleModalPostulerTuto(bool));
    },
    setJobIdModalPostulerTuto: (id, name) => {
      dispatch(setJobIdModalPostulerTuto(id, name));
    },
    setCvPosted: (bool) => {
      dispatch(setCvPosted(bool));
    },
    setDidTest: (bool) => {
      dispatch(setDidTest(bool));
    },
    setJobForModalPostuler: (data) => {
      dispatch(setJobForModalPostuler(data));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(JobCard)