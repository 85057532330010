/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import { FaPlusCircle } from "react-icons/fa"
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import DialogTitle from '@mui/material/DialogTitle'
import URL from '../../assets/js/urlBackEnd'
import axios from 'axios'
import { toast } from 'react-toastify'
import randomString from '../../assets/js/randomString'
import { AiOutlineDownload } from "react-icons/ai"
import { FaInfoCircle } from "react-icons/fa"
import * as XLSX from 'xlsx/xlsx.mjs'
import Moment from 'react-moment'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import * as CryptoJS from "crypto-js"
import DeleteIcon from '@mui/icons-material/Delete'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import PreInscription from '../../containers/PreInscription'
import ParticuliersUser from './ParticuliersUser'
import EntreprisesUser from './EntreprisesUsers'

/**** Import des images ****/

/**** Import des css ****/
import AccountAdminUsersStyled from './AccountAdminUsersStyled'

const AccountAdminUsers = ({ 
  fetchGetPreInscription, 
  jwt,
  user,
  isLogged,
  preInscription,
  fetchPostUserFromPreInscription,
  fetchDeleteUserFromPreInscription,
  fetchGetUsersP,
  fetchGetUsersE,
  usersFormAdminP,
  usersFormAdminE
}) => {

  useEffect(() => {
    fetchGetPreInscription()
    fetchGetUsersP()
    fetchGetUsersE()
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false)
  const [checkboxState, setCheckboxState] = React.useState([])
  const [active, setActive] = useState(false);
  const [checkedState, setCheckedState] = useState(
    new Array(preInscription.length).fill(false)
  );

  const handleSwitch = (e) => {
    if (e.target.checked) {
      setActive(true);
      setCheckedState(new Array(preInscription.length).fill(true));
    } else {
      setActive(false);
      setCheckedState(new Array(preInscription.length).fill(false));
    }
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) => {
      return index === position ? !item : item;
    });

    setCheckedState(updatedCheckedState);

    if (updatedCheckedState.includes(false)) {
      setActive(false);
    } else {
      setActive(true);
    }
  };

  useEffect(() => {
    if(preInscription !== 'loading' && preInscription.length > 0) {
      let array = []
      preInscription.map((user, index) => {
        let userObj = {
          id : index, 
          checked: false,
        }
        array.push(userObj)
    })
    setCheckboxState(array)
    }
  }, [preInscription])

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => { 
    setAnchorEl(null);
  };

  const openP = Boolean(anchorEl);

  const changeDate = (date) => {
    const [year, month, day] = date.split('-')
    const result = [day, month, year].join('/')
    return result
  }

  const transformDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
  };
  const fetchGetListe = () => {
    // ici on lance la requête 
    let dataArray = []
    const dataR = preInscription

    dataR.map((item) => {
      const dataObj = {
        Id: item.id,
        Nom: item.attributes.nom,
        Prenom: item.attributes.prenom,
        Email: item.attributes.email,
        'Téléphone': '0' + item.attributes.telephone,
        'Publié': transformDate(item.attributes.createdAt),
      }
      dataArray.push(dataObj)
    })
    // on recup la liste et on upload les datas avec downloadxls(e, data)
    downloadxls(dataArray)
  }

  const downloadxls = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "test");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `pre-inscription${changeDate(new Date().toLocaleDateString('fr-FR'))}.csv`);
  }

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };

  const handleCreateObject = () => {
    let array = []
    preInscription.map((item, index) => {
      const password = randomString(8)
      const dataObj = {
        id: item.id,
        nom: item.attributes.nom,
        prenom: item.attributes.prenom,
        email: item.attributes.email,
        telephone: '0' + item.attributes.telephone,
        password: password,
        encryptedString: CryptoJS.AES.encrypt(password, "salut c'est nico").toString()
      }
      if (checkedState[index] === true) {
        array.push(dataObj)
      }
    })
    fetchPostUserFromPreInscription(array)
  }

  const handleCreateObjectToDelete = () => {
    let array = []
    preInscription.map((item, index) => {
      const dataObj = {
        id: item.id,
        nom: item.attributes.nom,
        prenom: item.attributes.prenom,
        email: item.attributes.email,
        telephone: '0' + item.attributes.telephone,
        password: randomString(8)
      }
      if (checkedState[index] === true) {
        array.push(dataObj)
      }
    })
    fetchDeleteUserFromPreInscription(array)
  }

  return (
    <AccountAdminUsersStyled>
      <Header bcgColor={'#fff'}/>
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
        <div className='pre-insc-wrapper'>
          <h2 className='bold'>Utilisateurs pré-inscrits <span onClick={handleClickOpen}><FaPlusCircle /></span></h2>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <PreInscription />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{padding: '10px', borderRadius: '5px'}}>
                Terminé
              </Button>
            </DialogActions>
          </Dialog>
          {isLogged && user.admin !== null && preInscription !== 'loading' && preInscription.length > 0 ?
            <div className="pre-list-wrapper">
              <div className='title-wrapper'>
                <div className='row-title bold'>Nom complet</div>
                <div className='row-title bold'>Téléphone</div>
                <div className='row-title bold'>Email</div>
                <div className='row-title bold'>Créé le</div>
                <div className='row-title bold'>Parrain</div>
              </div>
              {preInscription.map((user, index) => (
                <div key={index} className='info-wrapper'>
                  <div className='row-user left'>
                    {checkboxState.length + 2 > index && checkboxState.some(checkItem => checkItem.id === index) ?
                    <>
                    {checkboxState.map((item, indexBox) => (
                      <>
                      {item.id === index &&
                        <div key={item.id}>
                          <Checkbox
                            name={item}
                            value={item}
                            checked={checkedState[indexBox]}
                            onChange={() => handleOnChange(indexBox)}
                          />
                        </div>
                      }
                      </>
                    ))}
                      </>
                      :
                      <Checkbox
                        color="default"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                      />
                    }
                    <span>{user.attributes.nom} {user.attributes.prenom}</span>
                  </div>
                  <div className='row-user'>0{user.attributes.telephone}</div>
                  <div className='row-user'>{user.attributes.email}</div>
                  <div className='row-user'><Moment format="DD/MM/YYYY">{user.attributes.createdAt}</Moment></div>
                  <div className='row-user'>
                    <span>
                    {user.attributes.users_permissions_user.data.attributes.email}
                    </span> 
                    <FaInfoCircle className='info-svg' 
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    />
                  </div>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={openP}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1 }}>
                      <p>Id : {user.attributes.users_permissions_user.data.id}</p>
                      <p>Nom complet : {user.attributes.users_permissions_user.data.attributes.nom} {user.attributes.users_permissions_user.data.attributes.prenom}</p>
                      <p>Email : {user.attributes.users_permissions_user.data.attributes.email}</p>
                    </Typography>
                  </Popover>
                </div>
              ))}
              <div className='action-wrapper'>
                <div className='row-action left'>
                <span>
                  <Checkbox
                    color="primary"
                    checked={active}
                    onChange={handleSwitch}
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                  /> Tous
                </span>
                </div>
                <div className='row-action'></div>
                <div className='row-action center'>
                  <span className='dl-btn' onClick={() => fetchGetListe()}><AiOutlineDownload /></span>
                </div>
                <div className='row-action center'>
                  <Button color="error" className='insc-btn delete red' startIcon={<DeleteIcon />} onClick={() => {handleCreateObjectToDelete()}}>Supprimer</Button>
                </div>
                <div className='row-action center'>
                  <Button className='insc-btn' onClick={() => {handleCreateObject()}}>Inscription</Button>
                </div>
              </div>
            </div>
            :
            <p>Aucun utilisateur en cours d'inscription</p>
          }
          {/* 
            - ici on map dans la liste des pré-inscrit avec created en "false" 
            - Affichage visuel des apprenants avec leur infos + infos du parrain 
          */}
          <h2 className='bold'>Liste des particuliers</h2>
          <ParticuliersUser usersFormAdminP={usersFormAdminP} jwt={jwt} />
          {/* 
            - Filtre qui relance une requete par : id user, nom user (... ?)
          */}
          {/* 
            - ici on map dans la liste des particuliers 
            - Affichage visuel des particuliers avec possibilité de check toutes les infos de leur compte
          */}
          <h2 className='bold'>Liste des entreprises</h2>
          <EntreprisesUser usersFormAdminE={usersFormAdminE} jwt={jwt} />
          {/* 
            - Filtre qui relance une requete par : id user, nom entreprise ou nom referant (... ?)
          */}
          {/* 
            - ici on map dans la liste des entreprise 
            - Affichage visuel des particuliers avec possibilité de check toutes les infos de leur compte
          */}
        </div>
      </section>
      </main>
    </AccountAdminUsersStyled>
  )
}

AccountAdminUsers.propTypes = {
}

export default AccountAdminUsers