import { connect } from 'react-redux'

import Footer from '../../components/Footer'

import { changeInputValue, fetchPostNewsletter } from '../../actions/actions'
import { openModal } from '../../actions/modal'

const mapStateToProps = (state) => ({
    isLogged: state.login.isLogged
})

const mapDispatchToProps = (dispatch) => ({
    changeInputValue: (value, name) => {
        dispatch(changeInputValue(value, name));
    },
    fetchPostNewsletter: () => {
        dispatch(fetchPostNewsletter());
    },
    openModal: (name) => {
        dispatch(openModal(name));
    }
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Footer)