import styled from 'styled-components';

const ModalInscriptionStyled = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 100000;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content-container {
        width: auto;
        max-width: 96%;
        min-height: 300px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 5px 15px 10px #00000066;
        position: relative;

        .icon-wrapper {
            position: absolute;
            top: -35px;
            right: -35px;
            width: 40px;
            height: 40px;
            background-color: #649ACE;
            border-radius: 17.5px;
            overflow: hidden;
            display:flex;
            align-items: center;
            justify-content: center;

            .close-icon {
                color: #fff;
                font-size: 30px;
                cursor: pointer;
                border-radius: 20px;
            }
        }
    }

    @media only screen and (max-width: 1079px) {
        .modal-content-container {
            .icon-wrapper {
                position: absolute;
                top: -20px;
                right: -8px;
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .modal-content-container {
            width: 96%;
            border-radius: 10px;

            .choices-wrapper {
                width: 100%;
            }

            .icon-wrapper {
                position: absolute;
                top: 5px;
                right: 5px;
                width:30px;
                height: 30px;
                background-color: #dc4a46;

                .close-icon {
                    font-size: 20px;
                }
            }
        }
    }
`;

export default ModalInscriptionStyled;