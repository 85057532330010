import styled from 'styled-components';

const CourseLiStyled = styled.li` 
   color: #d47558;
   border: 2px solid #d47558;
   background-color: #f8e8e3;
   position: relative;
   border-radius: 20px;

   a {
      color: #d47558;
   }

   &:hover{
      background-color: rgba(212, 127, 88, 0.9);
      color: #fff;

      a {
         color: #fff;
      }
   }

   .job-submenu-wrapper {
      position: absolute;
      width: max-content;
      height: max-content;
      top: 63px;
      left: -285px;
      background-color: rgba(212, 127, 88, 0.9);
      padding: 20px 0;
      border-radius: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      h4 {
         font-size: 22px;
         text-transform: uppercase;
         padding-bottom: 20px;
      }

      .active {
         background-color: #fff;
         color: #d47558;
         border-radius: 0;
         border-radius: 20px;

         a {
            color: #d47558;
         }
      }

      .column-submenu {
         width: 333px;
         box-sizing: border-box;
      }

      .column-submenu ul {
         display: flex;
         flex-direction: column;
         align-items: center;
         height: max-content;
         box-sizing: border-box;

         li { 
            width: 90%;
            height: auto !important;
            padding: 10px 5px;
            cursor: pointer;
         }

         li:hover {
            background-color: #fff;
            color: #d47558;
            border-radius: 0;
            border-radius: 20px;
         }
      }

      .secteurs, .domaines {
         border-right: 2px solid #fff;
      }
   }
`;

export default CourseLiStyled;