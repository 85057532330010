/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineFieldNumber, AiOutlineUser, AiOutlineMail, AiOutlineBook } from "react-icons/ai"
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import * as qs from 'qs'
import Moment from 'react-moment'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import randomString from '../../assets/js/randomString'
import { toast } from 'react-toastify'
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs'
import moment from 'moment'




import urlBackEnd from '../../assets/js/urlBackEnd';


/**** Import d'images' ****/


/**** Import des css ****/
import ApprenantCardStyled from './ApprenantCardStyled'

const URL = urlBackEnd
const URL360LEARNING = "https://app.360learning.com/api/v1/";
const API360LEARNING = "f96ac09c72064626beb2a2fce44f30b3"
const ID360LEARNING = "62457cdc53413c65bea4c62c"
const GROUP306LEARNING = "62457cdc53413c65bea4c62e"

const ApprenantCard = ({ 
  apprenantFromState,
  fetchLmsApprenant,
  index,
  formationApprenant,
  session,
  fetchPutUserOnSession
}) => {

function changeFormat(dateString) {
  // Séparer la chaîne de date en trois parties: jour, mois et année
  var parts = dateString.split('/');
  var day = parts[0];
  var month = parts[1];
  var year = parts[2];

  // Réassembler les parties dans l'ordre "aaaa/mm/jj"
  return year + '-' + month + '-' + day;
}

useEffect(() => {
    fetchLmsApprenant(apprenantFromState.INTITULE_FORMATION.replace(//g, 'é'), index)
}, [])

useEffect(() => {
  fetchWeforAccount(apprenantFromState.Email, index)
}, [])

useEffect(() => {
  function isValidDate(dateString) {
    var regEx = /^\d{2}\/\d{2}\/\d{4}$/;
    if (typeof dateString === 'string') {
      return dateString.match(regEx) != null;
    } else {
      return false
    }
    
  }

  function convertDateFormat(dateString) {
    const [month, day, year] = dateString.split('/');
    return `${day}/${month}/${year}`;
  }


  if (isValidDate(apprenantFromState.DATE_DEBUT_SESSION) === false) {

  
  if (apprenantFromState.modified === undefined ) {
    
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  const isISO8601debut = regex.test(apprenantFromState.DATE_DEBUT_SESSION.toISOString());
  const isISO8601fin = regex.test(apprenantFromState.DATE_FIN_SESSION.toISOString());
  if (isISO8601debut) {
    const date = new Date(apprenantFromState.DATE_DEBUT_SESSION.toISOString());
    const dateString = date.toLocaleDateString('fr-FR', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    updateValue(apprenantFromState, 'DATE_DEBUT_SESSION', convertDateFormat(dateString));
    apprenantFromState['modified'] = true
    const today = new Date();

    // Déclarez une variable qui représente la date future en utilisant l'objet Date et en passant la date en tant que chaîne au format "mm/dd/yyyy"
    const futureDate = new Date(dateString);

    // Calculez le nombre de millisecondes entre la date actuelle et la date future en utilisant la méthode getTime()
    const differenceInTime = futureDate.getTime() - today.getTime();

    // Convertissez le nombre de millisecondes en jours en divisant par la durée d'un jour en millisecondes (86400000)
    const differenceInDays = differenceInTime / 86400000;
    setApprenantDaysRemain(differenceInDays)
  } 
  if (isISO8601fin) {
    const date = new Date(apprenantFromState.DATE_FIN_SESSION.toISOString());
    const dateString = date.toLocaleDateString('fr-FR', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    updateValue(apprenantFromState, 'DATE_FIN_SESSION', convertDateFormat(dateString));
    apprenantFromState['modified'] = true

  } 
  }
  } else {
    if (apprenantFromState.modified === undefined ) {
    function daysUntil(dateString) {
      function changeFormat(dateString) {
        // Séparer la chaîne de date en trois parties: jour, mois et année
        var parts = dateString.split('/');
        var day = parts[0];
        var month = parts[1];
        var year = parts[2];
      
        // Réassembler les parties dans l'ordre "aaaa/mm/jj"
        return year + '/' + month + '/' + day;
      }

      let newDate = changeFormat(dateString)
      // Remplacer les barres obliques par des tirets dans la chaîne de date
      newDate = newDate.replace(/\//g, '-');
      // Parser la chaîne de date en timestamp
      var targetTimestamp = Date.parse(newDate);
      // Obtenir la date d'aujourd'hui en timestamp
      var currentTimestamp = Date.now();
    
      // Calculer la différence entre les deux timestamps en milisecondes
      var timeDifference = targetTimestamp - currentTimestamp;
    
      // Calculer le nombre de jours restants en divisant la différence par la durée d'un jour en milisecondes
      // et en arrondissant au nombre entier inférieur
      return Math.floor(timeDifference / 86400000);
    }
    setApprenantDaysRemain(daysUntil(apprenantFromState.DATE_DEBUT_SESSION))
  }
  }
  setApprenant(apprenantFromState)
  }, [])

const [formationApprenantObj, setFormationApprenantObj] = useState(false)
const [apprenant, setApprenant] = useState(false)
const [apprenantDaysRemain, setApprenantDaysRemain] = useState(false)
const [accountApprenantExist, setAccountApprenantExist] = useState('loading')
const [accountApprenant, setAccountApprenant] = useState('')
const [particulier, setParticulier] = useState('')

const [accountApprenantExist360, setAccountApprenantExist360] = useState('loading')
const [accountApprenant360, setAccountApprenant360] = useState('')

const [sessionApprenantExist360, setSessionApprenantExist360] = useState('loading')
const [sessionApprenant360, setSessionApprenant360] = useState('')

const [factureApprenantExist, setFactureApprenantExist] = useState('loading')
const [factureApprenant, setFactureApprenant] = useState('')
const [openWeforAccount, setOpenWeforAccount] = React.useState(false);
const [open360Account, setOpen360Account] = React.useState(false);
const [openDelete360, setOpenDelete360] = React.useState(false);
const [open360Session, setOpen360Session] = React.useState(false);
const [openWeforFacturation, setOpenWeforFacturation] = React.useState(false);
const [openWeforUpdateFacturation, setOpenWeforUpdateFacturation] = React.useState(false);

// INFORMATIONS DU FORMULAIRE COMPTE WEFOR
const [emailCompteWefor, setEmailCompteWefor] = useState(apprenantFromState.Email)
const [prenomCompteWefor, setPrenomCompteWefor] = useState(apprenantFromState.PRENOM)
const [nomCompteWefor, setNomCompteWefor] = useState(apprenantFromState.NOM)
const [passwordCompteWefor, setPasswordCompteWefor] = useState(randomString(8))

// INFORMATIONS DU FORMULAIRE COMPTE WEFOR
const [emailCompte360, setEmailCompte360] = useState(apprenantFromState.Email)
const [prenomCompte360, setPrenomCompte360] = useState(apprenantFromState.PRENOM)
const [nomCompte360, setNomCompte360] = useState(apprenantFromState.NOM)
const [passwordCompte360, setPasswordCompte360] = useState(randomString(8))

// DATE PICKER 

const [valueDate, setValueDate] = React.useState(dayjs('2014-08-18T21:11:54'));

function updateValue(obj, key, value) {
  obj[key] = value;
}

const handleChangeDate = (newValue) => {
  setValueDate(newValue);
};

const handleClickOpenWeforAccount = () => {
  setOpenWeforAccount(true);
};

const handleCloseWeforAccount = () => {
  setOpenWeforAccount(false);
};

const handleClickOpen360Account = () => {
  setOpen360Account(true);
};

const handleClose360Account = () => {
  setOpen360Account(false);
};

const handleClickOpenDelete360 = () => {
  setOpenDelete360(true);
};

const handleCloseDelete360 = () => {
  setOpenDelete360(false);
};

const handleClickOpen360Session = () => {
  setOpen360Session(true);
};

const handleClose360Session = () => {
  setOpen360Session(false);
};

const handleClickOpenWeforFacturation = () => {
  setOpenWeforFacturation(true);
};

const handleCloseWeforFacturation = () => {
  setOpenWeforFacturation(false);
};

const handleClickOpenWeforUpdateFacturation = () => {
  setOpenWeforUpdateFacturation(true);
};

const handleCloseWeforUpdateFacturation = () => {
  setOpenWeforUpdateFacturation(false);
};

useEffect(() => {
  if (formationApprenantObj === false) {
    formationApprenantThis()
  } 
  if (formationApprenantObj === undefined) {
    formationApprenantThis()
  } 
}, [formationApprenant])

useEffect(() => {
  if (formationApprenantObj === false) {
    sessionApprenantThis()
  } 
  if (formationApprenantObj === undefined) {
    sessionApprenantThis()
  } 
}, [formationApprenant])

useEffect(() => {
  fetch360Session(formationApprenantObj)
}, [apprenant])

const fetchWeforAccount = (app) => {
  const queryFilterObj = qs.stringify({
    filters: {
      username: {
        $eq: apprenantFromState.Email
      },
    },
    headers: {
      Authorization:
      `Bearer ${session.jwt}`,
    },
    }, {
      encodeValuesOnly: true,
  });
  
  axios.get(`${URL}users?${queryFilterObj}`)
  .then((response) => {
    if (response.data.length > 0) {
      setAccountApprenantExist(true)
      setAccountApprenant(response.data[0])
      fetchParticulier(response.data[0].id)
    } else {
      setAccountApprenantExist(false)
    }

  })
  .catch((error) => {
    setAccountApprenantExist(false)
    console.error(error)
  })
}

const fetchParticulier = (userId) => {
  const queryFilterObj = qs.stringify({
    filters: {
      user: {
        id: {
          $eq: userId
        }
      },
    },
    populate: 'facturation_formations',
    headers: {
      Authorization:
      `Bearer ${session.jwt}`,
    },
    }, {
      encodeValuesOnly: true,
  });
  
  axios.get(`${URL}particuliers?${queryFilterObj}`)
  .then((response) => {
    if (response.data.data.length > 0) {
      const factures = response.data.data[0].attributes.facturation_formations.data
      setParticulier(response.data.data)
      if ( factures === null  ) {
        setFactureApprenantExist(false)
      } else {
        const factureExist = factures.find(facture => facture.attributes.nom_formation === apprenantFromState.INTITULE_FORMATION.replace(//g, 'é'))
        if (factureExist === undefined) {
          setFactureApprenantExist(false)
        } else {
          setFactureApprenant(factureExist)
          setFactureApprenantExist(true)
        }
      }
      
    } else {
      setFactureApprenantExist(false)
    }

  })
  .catch((error) => {
    console.error(error)
    setFactureApprenantExist(false)
  })
}

const fetch360Account = () => {
  const optionsGetUser = {
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: `${URL360LEARNING}users/${apprenantFromState.Email}?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
    };
      axios(optionsGetUser)
      .then((response) => {
        setAccountApprenantExist360(true)
        setAccountApprenant360(response.data)
  })
  .catch((error) => {
    setAccountApprenantExist360(false)
    console.error(error)
  })
}

const fetchUpdate360 = () => {
  const valueDateFormat = changeFormat(valueDate.$d.toLocaleDateString('fr-FR', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }))
  const data = { 
    toDeactivateAt: valueDateFormat
  };
  const optionsGetUser = {
    method: 'PUT',
    data: qs.stringify(data),
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: `${URL360LEARNING}users/${apprenantFromState.Email}?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
    };
    axios(optionsGetUser)
    .then((response) => {
      fetch360Account()
      toast(`Date de suppression du compte enregistrée`, {
        position: "bottom-right",
        type: "success",
        theme:"colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
  })
  .catch((error) => {
    console.error(error)
    toast(`Une erreur s'est produite, contactez le service informatique`, {
      position: "bottom-right",
      type: "error",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  })
}

const fetch360Session = (obj) => {
  if(apprenant !== false) {
  const optionsGetUser = {
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: `${URL360LEARNING}users/${apprenantFromState.Email}/programs?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
    };
      axios(optionsGetUser)
      .then((response) => {

        const debutSession = apprenant.DATE_DEBUT_SESSION === undefined ? '' : apprenant.DATE_DEBUT_SESSION
        const finSession = apprenant.DATE_FIN_SESSION === undefined ? '' : apprenant.DATE_FIN_SESSION
        const [dayS, monthS, yearS] = debutSession.split('/');
        const [ dayE, monthE, yearE] = finSession.split('/');
        const resultS = [yearS, monthS, dayS].join('-');
        const resultE = [yearE, monthE, dayE].join('-');

        const findGoodSession = response.data.find(session => session.programTemplate === obj.formationData.attributes.lms_formation_id && session.startDate.includes(resultS) && session.endDate.includes(resultE))

        if (findGoodSession === undefined) {
          setSessionApprenantExist360(false)
        } else {
          setSessionApprenantExist360(true)
          setSessionApprenant360(findGoodSession)
        }

  })
  .catch((error) => {
    setSessionApprenantExist360(false)
    console.error(error)
  })
}
}

const fetchCreateWeforaccount = () => {
  const queryFilterObj = qs.stringify({
    headers: {
      Authorization:
      `Bearer ${session.jwt}`,
    },
    }, {
      encodeValuesOnly: true,
  });
  
  axios.post(`${URL}userParticulierWithEmail`, {
    data : {
      email: emailCompteWefor,
      prenom: prenomCompteWefor,
      nom: nomCompteWefor,
      password: passwordCompteWefor,
      courseName: formationApprenantObj.formationData === undefined ? null : formationApprenantObj.formationData.attributes.nom,
      courseId: formationApprenantObj.formationData === undefined ? null : formationApprenantObj.formationData.id
    }
  },
  queryFilterObj
  ).then((response) => {
    setAccountApprenantExist(true)
    setAccountApprenant(response.data)
    fetchParticulier(response.data.id)
    toast(`Compte créé`, {
      position: "bottom-right",
      type: "success",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  })
  .catch((error) => {
    console.error(error)
    toast(`Une erreur s'est produite, contactez le service informatique`, {
      position: "bottom-right",
      type: "error",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  })
}

const fetchUserEmail360Datas = () => {
  const queryFilterObj = qs.stringify({
    headers: {
      Authorization:
      `Bearer ${session.jwt}`,
    },
    }, {
      encodeValuesOnly: true,
  });
  
  axios.post(`${URL}userEmail360Datas`, {
    data : {
      email: emailCompte360,
      prenom: prenomCompte360,
      nom: nomCompte360,
      password: passwordCompte360,
      courseName: formationApprenantObj.formationData === undefined ? null : formationApprenantObj.formationData.attributes.nom,
    }
  },
  queryFilterObj
  ).then((response) => {
    toast(`Email de création de compte envoyé`, {
      position: "bottom-right",
      type: "success",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  })
  .catch((error) => {
    console.error(error)
    toast(`Une erreur s'est produite, contactez le service informatique`, {
      position: "bottom-right",
      type: "error",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  })
}

const fetchCreate360account = () => {
  const data = { 
    company: ID360LEARNING,
    apiKey: API360LEARNING,
    firstName: prenomCompte360,
    lastName: nomCompte360,
    mail: emailCompte360,
    lang: 'fr',
    groups: [GROUP306LEARNING],
    password: passwordCompte360
    };
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${URL360LEARNING}users?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
    };
    axios(options)
    .then((response) => {
      fetch360Account()
      fetchUserEmail360Datas()
      toast(`Compte créé`, {
        position: "bottom-right",
        type: "success",
        theme:"colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    })
    .catch((error) => {
    console.error(error);
    toast(`Une erreur s'est produite, contactez le service informatique`, {
      position: "bottom-right",
      type: "error",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
    });
}

const fetchCreate360Session = () => {
  
  const data = { 
    "startDate": `${changeFormat(apprenant.DATE_DEBUT_SESSION)}T09:30:00+01:00`,
    "endDate": `${changeFormat(apprenant.DATE_FIN_SESSION)}T23:00:00+01:00`,
    "tutors": [
      "contact@wefor.fr"
    ],
    "name": `${apprenant.INTITULE_FORMATION.replace(//g, 'é')}`,
    "userLimit": 1,
    "sendAutomatedReminders": true,
  };
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${URL360LEARNING}programs/${formationApprenantObj.formationData.attributes.lms_formation_id}/sessions/?company=${ID360LEARNING}&apiKey=${API360LEARNING}`,
    };
      axios(options)
      .then((response) => {
        fetchPutUserOnSession(accountApprenant360._id)
        fetch360Session(formationApprenantObj)
        toast(`Session crée`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error);
        toast(`Une erreur s'est produite, contactez le service informatique`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });
}

const formationApprenantThis = () => {
  if (formationApprenant.length > 0) {
    if(formationApprenantObj === false) { 
      
      const obj = formationApprenant.find(obj => obj.formation === index)
      if (obj !== undefined) {
        setFormationApprenantObj(obj)
        if(obj.formationData === undefined) {
          return false
        } else {
          if (obj.formationData.attributes.lms_formation_id === 'univo') {
            // do nothing
          } else if (obj.formationData.attributes.lms_formation_id === 'formapro') {
            // do nothing
          } else {
            fetch360Account()
          }
          return true
        }
      } else {
        return false
      }

    } else if(formationApprenantObj === undefined) { 
      const obj = formationApprenant.find(obj => obj.formation === index)
      setFormationApprenantObj(obj)
      if (obj.formationData.attributes.lms_formation_id === 'univo') {
        // do nothing
      } else if (obj.formationData.attributes.lms_formation_id === 'formapro') {
        // do nothing
      } else {
        fetch360Account()
      }
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const fetchCreateWeforFacturation = () => {
  const formationApprenantData = formationApprenantObj.formationData
  const formationApprenantAtt = formationApprenantObj.formationData.attributes
  const debutSession = apprenant.DATE_DEBUT_SESSION === undefined ? '' : apprenant.DATE_DEBUT_SESSION
  const finSession = apprenant.DATE_FIN_SESSION === undefined ? '' : apprenant.DATE_FIN_SESSION
  const [dayS, monthS, yearS] = debutSession.split('/');
  const [dayE, monthE, yearE] = finSession.split('/');
  const resultS = [yearS, monthS, dayS].join('-');
  const resultE = [yearE, monthE, dayE].join('-');
  axios.post(`${URL}facturation-formations`, {
    data: {
      Prix: formationApprenantAtt.prix,
      formation: formationApprenantData.id,
      particulier: particulier[0].id,
      nom_formation: formationApprenantAtt.nom,
      nombre_particulier: 1,
      mode_financement: `EDOF : ${apprenant.NUMERO_DOSSIER}`,
      start_session: resultS,
      end_session: resultE,
      step: 2,
      lms_nom: formationApprenantObj.formationData.attributes.lms_formation_id === 'formapro' ? 'formapro' : '360 Learning',
      session_created: true
    },
  },
  {
    headers: {
      Authorization:
        `Bearer ${session.jwt}`,
      },
  })
  .then((response) => {
    fetchParticulier(accountApprenant.id)
    toast(`Facturation créée`, {
      position: "bottom-right",
      type: "success",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  })
  .catch((error) => {
    console.error(error);
    toast(`Une erreur s'est produite ! ${error.message}`, {
      position: "bottom-right",
      type: "error",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  })
}

const fetchCreateWeforUpdateFacturation = () => {
  const formationApprenantData = formationApprenantObj.formationData
  const formationApprenantAtt = formationApprenantObj.formationData.attributes
  const debutSession = apprenant.DATE_DEBUT_SESSION === undefined ? '' : apprenant.DATE_DEBUT_SESSION
  const finSession = apprenant.DATE_FIN_SESSION === undefined ? '' : apprenant.DATE_FIN_SESSION
  const [dayS, monthS, yearS] = debutSession.split('/');
  const [dayE, monthE, yearE] = finSession.split('/');
  const resultS = [yearS, monthS, dayS].join('-');
  const resultE = [yearE, monthE, dayE].join('-');
  axios.put(`${URL}facturation-formations/${factureApprenant.id}`, {
    data: {
      start_session: resultS,
      end_session: resultE,
      step: 2,
      mode_financement: `EDOF : ${apprenant.NUMERO_DOSSIER}`,
      lms_nom: formationApprenantObj.formationData.attributes.lms_formation_id === 'formapro' ? 'formapro' : '360 Learning',
      session_created: true
    },
  },
  {
    headers: {
      Authorization:
        `Bearer ${session.jwt}`,
      },
  })
  .then((response) => {
    fetchParticulier(accountApprenant.id)
    toast(`Facturation modifiée`, {
      position: "bottom-right",
      type: "success",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  })
  .catch((error) => {
    console.error(error);
    toast(`Une erreur s'est produite ! ${error.message}`, {
      position: "bottom-right",
      type: "error",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  })
}

const sessionApprenantThis = () => {
  if (formationApprenant.length > 0) {
    if(formationApprenantObj === false) { 
      
      const obj = formationApprenant.find(obj => obj.formation === index)
      if (obj !== undefined) {
        setFormationApprenantObj(obj)

        if(obj.formationData === undefined) {
          return false 
        } else {
          if (obj.formationData.attributes.lms_formation_id === 'univo') {
            // do nothing
          } else if (obj.formationData.attributes.lms_formation_id === 'formapro') {
            // do nothing
          }else {
            fetch360Session(obj)
          }
          return true
        }
      } else {
        return false
      }

    } else if(formationApprenantObj === undefined) { 
      const obj = formationApprenant.find(obj => obj.formation === index)
      setFormationApprenantObj(obj)
      if (obj.formationData.attributes.lms_formation_id === 'univo') {
        // do nothing
      } else if (obj.formationData.attributes.lms_formation_id === 'formapro') {
        // do nothing
      }else {
        fetch360Session(obj)
      }
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const isValidSpe = (str) => {
  return /[~`!#$%\^&*+=\-\[\]\\';,./{}|\\":<>\?]/g.test(str);
}

const isValidMaj = (str) => {
  return /(?=.*[A-Z])/.test(str);
}

const isValidChi = (str) => {
  return /^(?=.*[0-9])/.test(str);
}

const isValidNum = (str) => {
  return /^.{8,10000}$/.test(str);
}

const isValidPassword = (str) => {
  return isValidChi(str) && isValidMaj(str) && isValidNum(str) && isValidSpe(str)
}

  return (
    <ApprenantCardStyled className={apprenantDaysRemain < 2 ? 'bcg-red' : ''}>
      {apprenantDaysRemain !== false &&
        <span className='days-remain bold'>
          {Math.ceil(apprenantDaysRemain) <= 0 ?
          <>
          J
          </>
          :
          <>
          J - {Math.ceil(apprenantDaysRemain)}
          </>
          }
        </span>
      }
      <div className='top-section'>
        <div className='infos-apprenant-wrapper'>
          <div className='info-apprenant'><AiOutlineFieldNumber /> <span className="bold">{apprenant.NUMERO_DOSSIER}</span></div>
          <div className='info-apprenant'><AiOutlineUser /><span className="bold">{apprenant.NOM} {apprenant.PRENOM}</span></div>
          <div className='info-apprenant'><AiOutlineMail /><span className="bold">{apprenant.Email}</span></div>
          <div className='info-apprenant'><AiOutlineBook /><span className="bold">{apprenant.INTITULE_FORMATION !== undefined && apprenant.INTITULE_FORMATION.replace(//g, 'é')}</span></div>
        </div>
      </div>
      <div className='bottom-section'>
      <div className='infos-session-wrapper'>
          <div className='top'>
            <div className="bold border">LMS</div>
            <div className="bold border">Compte wefor</div>
            <div className="bold border">Compte LMS</div>
            <div className="bold border">Session</div>
            <div className="bold border">Facturation</div>
          </div>
          <div className='bottom'>
            {/* LMS */}
            <div>{ formationApprenantObj === false || formationApprenantObj === undefined ?
            <CircularProgress
            color="success"
            />
            :
            <>
            {formationApprenantObj !== undefined && formationApprenantObj.formationData !== undefined &&
            <>
            {formationApprenantObj.formationData.attributes.lms_formation_id === 'univo' ? 
            <a className='btn-univo bold' href='https://wefor.cloudplateforme.com/v2-admin/dashboard' target="_blank" rel="noreferrer">univo</a>
            : 
            <>
            {formationApprenantObj.formationData.attributes.lms_formation_id === 'formapro' ? 
              <Link className='btn-univo bold' to='/connexion-lms'>formapro</Link>
              :
              <a className='btn-360 bold' href='https://elearning.wefor.fr/home' target="_blank" rel="noreferrer">360 learning</a>
            }
            </>
            }
            </>
            }
            </>
            }</div>
            {/* COMPTE WEFOR */}
            <div>
              { accountApprenantExist === 'loading'  &&
                <CircularProgress
                color="success"
                />
              }
              { accountApprenantExist === false  && 
                <button className='btn-action' onClick={handleClickOpenWeforAccount}>Créer un compte</button>
              }
              { accountApprenantExist === true  && Object.keys(accountApprenant).length > 0 &&
                <p className='done-task'>Compte créé le <Moment format="DD/MM/YYYY" className="bold">{accountApprenant.createdAt}</Moment></p>
              }
            </div>
            {/* COMPTE LMS */}
            <div>
            { formationApprenantObj === false || formationApprenantObj === undefined ?
            <CircularProgress
            color="success"
            />
            :
            <>
            {formationApprenantObj !== undefined && formationApprenantObj.formationData !== undefined &&
            <>
            {formationApprenantObj.formationData.attributes.lms_formation_id === 'univo' ? 
            <a className='btn-univo bold' href='https://wefor.cloudplateforme.com/v2-admin/dashboard' target="_blank" rel="noreferrer">univo</a>
            :
            <>
            {formationApprenantObj.formationData.attributes.lms_formation_id === 'formapro' ? 
              <Link className='btn-univo bold' to='/connexion-lms'>formapro</Link>
              :
            <>
              { accountApprenantExist360 === false  && 
                <button className='btn-action' onClick={handleClickOpen360Account}>Créer un compte</button>
              }
              { accountApprenantExist360 === true  && Object.keys(accountApprenant360).length > 0 &&
                <>
                  <p className='done-task'>Compte créé le <Moment format="DD/MM/YYYY" className="bold">{accountApprenant360.createdAt}</Moment></p>
                  { accountApprenant360.toDeactivateAt === '' ? 
                    <button className='btn-action' onClick={handleClickOpenDelete360}>Date de suppression</button>
                    :
                    <p className='done-task'>Suppression le <Moment format="DD/MM/YYYY" className="bold">{accountApprenant360.toDeactivateAt}</Moment></p>
                  }
                </>
              }
            </>
            }
            </>
            }
            </>
            }
            </>
            }
            </div>
            {/* SESSIONS */}
            <div>{ formationApprenantObj === false || formationApprenantObj === undefined ?
            <CircularProgress
            color="success"
            />
            :
            <>
            {formationApprenantObj !== undefined && formationApprenantObj.formationData !== undefined &&
            <>
            {formationApprenantObj.formationData.attributes.lms_formation_id === 'univo' ? 
            <a className='btn-univo bold' href='https://wefor.cloudplateforme.com/v2-admin/dashboard' target="_blank" rel="noreferrer">univo</a>
            : 
            <>
            {formationApprenantObj.formationData.attributes.lms_formation_id === 'formapro' ? 
              <Link className='btn-univo bold' to='/connexion-lms'>formapro</Link>
              :
            <>
              { sessionApprenantExist360 === false  && 
                <button className='btn-action' onClick={handleClickOpen360Session}>Créer une session</button>
              }
              { sessionApprenantExist360 === true  && Object.keys(sessionApprenant360).length > 0 &&
                <p className='done-task'>
                  <span>Session du </span>
                  <Moment format="DD/MM/YYYY" className="bold">{sessionApprenant360.startDate}</Moment>
                  <span> au </span>
                  <Moment format="DD/MM/YYYY" className="bold">{sessionApprenant360.endDate}</Moment>
                </p>
              }
            </>
            }
            </>
            }
            </>
            }
            </>
            }</div>
             {/* STATUT FACTURATION */}
            <div>
              { accountApprenantExist === 'loading'  &&
                <CircularProgress
                color="success"
                />
              }
              { accountApprenantExist === false  && 
                <button className='btn-action'>Compte Wefor non créé</button>
              }
              { accountApprenantExist === true  &&
                <>
                {factureApprenantExist === false && 
                  <button className='btn-action' onClick={handleClickOpenWeforFacturation}>Créer la facturation</button>
                }
                {factureApprenantExist === true && 
                  <>
                  {factureApprenant.attributes.start_session === null && factureApprenant.attributes.end_session === null &&
                    <button className='btn-action' onClick={handleClickOpenWeforUpdateFacturation}>Attribuer les dates de session</button>
                  }
                  {factureApprenant.attributes.start_session !== null && factureApprenant.attributes.end_session !== null &&
                    <>
                      {factureApprenant.attributes.step === 1 && 
                        <p className='done-task center'>Demande en cours</p>
                      }
                      {factureApprenant.attributes.step === 2 && 
                        <p className='done-task center'>Financement en cours</p>
                      }
                      {factureApprenant.attributes.step === 3 && 
                        <p className='done-task center'>Formation en cours</p>
                      }
                      {factureApprenant.attributes.step === 4 && 
                        <p className='done-task center'>Formation terminée</p>
                      }
                    </>
                  }
                  </>
                }
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {/* Modal création de compte wefor */}
      <Dialog open={openWeforAccount} onClose={handleCloseWeforAccount}>
        <DialogTitle>Création d'un compte wefor</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous êtes en train de créer un compte Wefor pour l'utilisateur suivant: {apprenant.NOM} {apprenant.PRENOM} , formation "{apprenant.INTITULE_FORMATION !== undefined && apprenant.INTITULE_FORMATION.replace(//g, 'é')}"
          </DialogContentText>
          <TextField
            margin="dense"
            id="nom"
            label="Nom"
            type="text"
            fullWidth
            variant="standard"
            value={nomCompteWefor}
            onChange={(event) => setNomCompteWefor(event.target.value)}
          />
          <TextField
            margin="dense"
            id="prenom"
            label="Prenom"
            type="text"
            fullWidth
            variant="standard"
            value={prenomCompteWefor}
            onChange={(event) => setPrenomCompteWefor(event.target.value)}
          />
          <TextField
            margin="dense"
            id="email"
            label="Address Email"
            type="email"
            fullWidth
            variant="standard"
            value={emailCompteWefor}
            onChange={(event) => setEmailCompteWefor(event.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Mot de passe"
            type="password"
            fullWidth
            variant="standard"
            value={passwordCompteWefor}
            onChange={(event) => setPasswordCompteWefor(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWeforAccount}>Annuler</Button>
          {isValidPassword(passwordCompteWefor) ? 
          <Button onClick={() => {
            handleCloseWeforAccount()
            fetchCreateWeforaccount()
          }}>Valider</Button>
          :
          <Button onClick={() =>  setPasswordCompteWefor(randomString(8))}>Générer un autre mot de passe</Button>
          }
        </DialogActions>
      </Dialog>
      {/* Modal création de compte 360 learning */}
      <Dialog open={open360Account} onClose={handleClose360Account}>
        <DialogTitle>Création d'un compte 360Learning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous êtes en train de créer un compte 360Learning pour l'utilisateur suivant: {apprenant.NOM} {apprenant.PRENOM} , formation "{apprenant.INTITULE_FORMATION !== undefined && apprenant.INTITULE_FORMATION.replace(//g, 'é')}"
          </DialogContentText>
          <TextField
            margin="dense"
            id="nom"
            label="Nom"
            type="text"
            fullWidth
            variant="standard"
            value={nomCompte360}
            onChange={(event) => setNomCompte360(event.target.value)}
          />
          <TextField
            margin="dense"
            id="prenom"
            label="Prenom"
            type="text"
            fullWidth
            variant="standard"
            value={prenomCompte360}
            onChange={(event) => setPrenomCompte360(event.target.value)}
          />
          <TextField
            margin="dense"
            id="email"
            label="Address Email"
            type="email"
            fullWidth
            variant="standard"
            value={emailCompte360}
            onChange={(event) => setEmailCompte360(event.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Mot de passe"
            type="password"
            fullWidth
            variant="standard"
            value={passwordCompte360}
            onChange={(event) => setPasswordCompte360(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWeforAccount}>Annuler</Button>
          {isValidPassword(passwordCompte360) ? 
          <Button onClick={() => {
            handleClose360Account()
            fetchCreate360account()
          }}>Valider</Button>
          :
          <Button onClick={() =>  setPasswordCompte360(randomString(8))}>Générer un autre mot de passe</Button>
          }
        </DialogActions>
      </Dialog>
      {/* Modal suppréssion de compte 360 learning */}
      <Dialog open={openDelete360} onClose={handleCloseDelete360}>
        <DialogTitle>Suppression programmée d'un compte 360Learning</DialogTitle>
        <DialogContent>
          <DialogContentText style={{marginBottom: 10}}>
            Vous êtes en train de créer un compte 360Learning pour l'utilisateur suivant: {apprenant.NOM} {apprenant.PRENOM}. Fin de formation prévu le {apprenant.DATE_FIN_SESSION}. Merci de choisir date et heure.
          </DialogContentText>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack>
              <DateTimePicker
                label="Date&Time picker"
                value={valueDate}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete360}>Annuler</Button>
          <Button onClick={() => {
            handleCloseDelete360()
            fetchUpdate360()
          }}>Valider</Button>
        </DialogActions>
      </Dialog>
      {/* Modal création de session 360 learning */}
      <Dialog open={open360Session} onClose={handleClose360Session}>
        <DialogTitle>Création d'une session 360Learning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous êtes en train de créer une session 360Learning pour l'utilisateur suivant: {apprenant.NOM} {apprenant.PRENOM} , formation "{apprenant.INTITULE_FORMATION !== undefined && apprenant.INTITULE_FORMATION.replace(//g, 'é')}"
          </DialogContentText>
          <DialogContentText>
            Les dates de session sont les suivantes : {apprenant.DATE_DEBUT_SESSION} - {apprenant.DATE_FIN_SESSION}
          </DialogContentText>
          <DialogContentText>
            L'utilisateur sera automatiquement ajouté à la session après la validation de création. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose360Session}>Annuler</Button>
          <Button onClick={() => {
            handleClose360Session()
            fetchCreate360Session()
          }}>Valider</Button>
        </DialogActions>
      </Dialog>
      {/* Modal création facturation */}
      <Dialog open={openWeforFacturation} onClose={handleCloseWeforFacturation}>
        <DialogTitle>Création d'une facturation wefor</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Vous êtes en train de créer une facturation. La facturation est le moyen pour l'apprenant de suivre ses étapes de financement, de relier la formation à une date de session et de créer ses contrats et factures si nécessaire.
          </DialogContentText>
          <DialogContentText>
            Merci de verifier les informations suivantes avant de valider la création !
          </DialogContentText>
          <DialogContentText>
            Merci de verifier que toute les étapes de création ont été effectuée !
          </DialogContentText>
          <DialogContentText>
            Apprenant : {apprenant.NOM} {apprenant.PRENOM}
          </DialogContentText>
          <DialogContentText>
            Formation : {apprenant.INTITULE_FORMATION !== undefined && apprenant.INTITULE_FORMATION.replace(//g, 'é')}
          </DialogContentText>
          <DialogContentText>
            Dates : {apprenant.DATE_DEBUT_SESSION} - {apprenant.DATE_FIN_SESSION}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWeforFacturation}>Annuler</Button>
          <Button onClick={() => {
            handleCloseWeforFacturation()
            fetchCreateWeforFacturation()
          }}>Valider</Button>
        </DialogActions>
      </Dialog>
      {/* Modal modification facturation */}
      <Dialog open={openWeforUpdateFacturation} onClose={handleCloseWeforUpdateFacturation}>
        <DialogTitle>Création d'une facturation wefor</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Vous êtes en train de modifier une facturation. La facturation est le moyen pour l'apprenant de suivre ses étapes de financement, de relier la formation à une date de session et de créer ses contrats et factures si nécessaire.
          </DialogContentText>
          <DialogContentText>
            Merci de verifier les informations suivantes avant de valider la modification !
          </DialogContentText>
          <DialogContentText>
            Merci de verifier que toute les étapes de création ont été effectuée !
          </DialogContentText>
          <DialogContentText>
            Apprenant : {apprenant.NOM} {apprenant.PRENOM}
          </DialogContentText>
          <DialogContentText>
            Formation : {apprenant.INTITULE_FORMATION !== undefined && apprenant.INTITULE_FORMATION.replace(//g, 'é')}
          </DialogContentText>
          <DialogContentText>
            Dates : {apprenant.DATE_DEBUT_SESSION} - {apprenant.DATE_FIN_SESSION}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWeforUpdateFacturation}>Annuler</Button>
          <Button onClick={() => {
            handleCloseWeforUpdateFacturation()
            fetchCreateWeforUpdateFacturation()
          }}>Valider</Button>
        </DialogActions>
      </Dialog>
    </ApprenantCardStyled>
  )
}

ApprenantCard.propTypes = {
}

export default ApprenantCard