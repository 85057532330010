import styled from 'styled-components';

const AccountAdminApprenantStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

    .right-wrapper {
        width: calc(96% - 250px);
        margin: 20px auto;

        .excel-form {

            .excel-label {
                width: 100%;
                max-height: 60px;
                box-sizing: border-box;
                position: relative;
                border-radius: 15px;
                box-shadow: 0px 1px 10px #00000029;
                border-radius: 10px;
                font-size: 18px;
                margin-bottom: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                cursor: pointer;
                font-size: 18px;

                p {
                    width: calc(75% - 25px);
                    text-align: left;
                    color: #6399ce;
                }

                svg {
                    font-size: 30px;
                    color: #6399ce;
                    margin-top: -5px;
                }
            }

            #upload {
                display: none;
            }
        }

        .excel-delete {
            width: 100%;
            max-height: 60px;
            box-sizing: border-box;
            position: relative;
            border-radius: 15px;
            box-shadow: 0px 1px 10px #00000029;
            border-radius: 10px;
            font-size: 18px;
            margin-bottom: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            cursor: pointer;
            font-size: 18px;

            p {
                width: calc(75% - 25px);
                text-align: left;
                color: #6399ce;
            }

            svg {
                font-size: 25px;
                color: #dc4a46;
                margin-top: -0px;
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(96% - 250px);
        margin: 20px auto;

            h1 {
                font-size: 45px;
            }

            h2 {
                font-size: 35px;
            }
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(96% - 250px);
        margin: 20px auto;

            h1 {
                font-size: 40px;
            }

            h2 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {
        margin: 60px auto 80px;
        .right-wrapper {
            width: calc(96%);
        margin: 20px auto;

            h1 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }

            h2 {
                font-size: 25px;
                text-align: center;

                .separator-blue {
                    width: 50px;
                    position: static;
                    margin: 10px auto;
                }
            }
        }
    }
}
  
`;

export default AccountAdminApprenantStyled;