import { connect } from 'react-redux'

import CourseLi from '../../../components/Header/CourseLi'

import { 
    setSubmenuCourseSecteurActiv, 
    setSubmenuCourseDomaineActiv, 
    setSubmenuCourseFormationActiv,
    changeCurrentDomaineClicked,
    setFilter
} from '../../../actions/menu'

const mapStateToProps = (state) => ({
    submenuCourseSecteurActiv: state.menu.submenuCourseSecteurActiv,
    submenuCourseDomaineActiv: state.menu.submenuCourseDomaineActiv,
    submenuCourseFormationActiv: state.menu.submenuCourseFormationActiv,
    secteurs: state.menu.secteurs,
    currentProfilUser: state.menu.currentProfilUser,
    secteursData: state.menu.secteursData,
})

const mapDispatchToProps = (dispatch) => ({
    setSubmenuCourseSecteurActiv: (name) => {
        dispatch(setSubmenuCourseSecteurActiv(name));
      },
    setSubmenuCourseDomaineActiv: (name) => {
        dispatch(setSubmenuCourseDomaineActiv(name));
    },
    setSubmenuCourseFormationActiv: (name) => {
        dispatch(setSubmenuCourseFormationActiv(name));
    },
    changeCurrentDomaineClicked: (id) => {
        dispatch(changeCurrentDomaineClicked(id));
    },
    setFilter: (name, title) => {
        dispatch(setFilter(name, title));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(CourseLi)