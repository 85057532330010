import { connect } from 'react-redux'

import ModalSatisfaction from '../../components/ModalSatisfaction'

import { toggleModalSatisfaction } from '../../actions/modal';
import { changeQsInput, fetchPostQS  } from '../../actions/course'

const mapStateToProps = (state) => ({
  userRole: state.login.userRole,
  userInfo: state.login.userInfo,
  session: state.login.session,
  qsOne: state.course.qsOne,
  qsTwo: state.course.qsTwo,
  qsThree: state.course.qsThree,
  qsFour: state.course.qsFour,
  qsOneScore: state.course.qsOneScore,
  qsTwoScore: state.course.qsTwoScore,
  qsThreeScore: state.course.qsThreeScore,
})

const mapDispatchToProps = (dispatch) => ({
  toggleModalSatisfaction: (bool) => {
    dispatch(toggleModalSatisfaction(bool));
  },
  changeQsInput: (value, name) => {
    dispatch(changeQsInput(value, name));
  },
  fetchPostQS: (courseId, factureId) => {
    dispatch(fetchPostQS(courseId, factureId));
  },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalSatisfaction)

