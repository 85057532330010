import React, { useState } from "react";
import HeaderMobileStyled from "./HeaderMobileStyled.js";
import { GoThreeBars, GoX } from "react-icons/go";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaRegEnvelope,
  FaPhoneAlt,
  FaUserGraduate,
  FaSuitcase,
  FaCoins,
  FaBuilding,
  FaUser,
  FaArrowUp,
} from "react-icons/fa";
import { HiCursorClick } from "react-icons/hi";
import { RiLogoutBoxLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import logoM from "../../assets/img/logo/logo-wefor.png";

const HeaderMobile = ({
  changeCurrentProfilUser,
  currentProfilUser,
  userRole,
  isLogged,
  changeSigninStep,
  windowTop,
  openModal,
  user,
  disconnectUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  let navigate = useNavigate();

  return (
    <HeaderMobileStyled isOpen={isOpen} windowTop={windowTop}>
      <div className="top-wrapper">
        <Link to="/">
          <img
            src={logoM}
            className="header-logo mobile"
            alt="logo carré wefor"
          />
        </Link>
        {isLogged && user.prenom !== undefined ? (
          <div
            className="btn-logout bold"
            onClick={() => {
              navigate("/");
              disconnectUser();
            }}
          >
            <RiLogoutBoxLine /> {user.prenom}
          </div>
        ) : (
          <div
            className="btn-connexion bold"
            onClick={() => openModal("connexion")}
          >
            <FaUser /> Connexion
          </div>
        )}
        <div className="burger-icon" onClick={toggleMenu}>
          <GoThreeBars />
        </div>
      </div>

      <nav className="burger-list">
        <div className="close-button" onClick={toggleMenu}>
          <GoX />
        </div>
        <div className="wrapper-menus">
          {!isLogged ? (
            <div className="empty-div">
              <h4 className="title-list bold">Accès</h4>
              <ul>
                <li
                  className={`nav-link bold ${
                    currentProfilUser === "Salarié" ? "active" : ""
                  }`}
                  onClick={() => {
                    changeCurrentProfilUser("Salarié");
                    changeSigninStep(2.1);
                  }}
                >
                  <span className="bold">Salarié</span>
                </li>
                <li
                  className={`nav-link bold ${
                    currentProfilUser === "Demandeur d'emploi" ? "active" : ""
                  }`}
                  onClick={() => {
                    changeCurrentProfilUser("Demandeur d'emploi");
                    changeSigninStep(2.1);
                  }}
                >
                  <span className="bold">Demandeur d'emploi</span>
                </li>
                <li
                  className={`nav-link bold ${
                    currentProfilUser === "Entreprise" ? "active" : ""
                  }`}
                  onClick={() => {
                    changeCurrentProfilUser("Entreprise");
                    changeSigninStep(2.2);
                  }}
                >
                  <span className="bold">Entreprise</span>
                </li>
              </ul>
              {currentProfilUser !== "notChoose" || userRole !== "" ? (
                <></>
              ) : (
                <div className="choose-statut">
                  <FaArrowUp className="bounce-item" />
                  <div className="bold">Choisissez un statut</div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          {(currentProfilUser !== "notChoose" || userRole !== "") && (
            <ul className="burger-nav">
              <h4
                className={`title-list bold ${
                  isLogged ? "border-bottom" : "borders"
                }`}
              >
                Menu
              </h4>
              <li className="nav-item">
                <Link
                  to="/formation"
                  className="nav-link formation-link bold"
                  onClick={toggleMenu}
                >
                  <FaUserGraduate />
                  {currentProfilUser === "Entreprise" ? (
                    <span>Former mes collaborateurs</span>
                  ) : (
                    <>
                      {isLogged ? (
                        <span>Se former</span>
                      ) : (
                        <span>Nos formations</span>
                      )}
                    </>
                  )}
                </Link>
              </li>
              <li className="nav-item">
                {currentProfilUser === "Entreprise" ? (
                  <Link
                    to="/signin"
                    className="nav-link job-link bold"
                    onClick={toggleMenu}
                  >
                    <FaSuitcase />
                    <span>Recruter des collaborateurs</span>
                  </Link>
                ) : (
                  <Link
                    to="/job"
                    className="nav-link job-link bold"
                    onClick={toggleMenu}
                  >
                    <FaSuitcase />
                    <span>Nos offres d'emploi</span>
                  </Link>
                )}
              </li>
              <li className="nav-item">
                <Link
                  to="/financement"
                  className="nav-link financement-link bold"
                  onClick={toggleMenu}
                >
                  <FaCoins />
                  <span>Les financements</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/qui-sommes-nous"
                  className="nav-link wefor-link bold"
                  onClick={toggleMenu}
                >
                  <FaBuilding />
                  <span>Qui sommes-nous ?</span>
                </Link>
              </li>
            </ul>
          )}
        </div>
        {!isLogged ? (
          <></>
        ) : (
          <Link
            to="account/home"
            className="access-dashbord bold"
            onClick={toggleMenu}
          >
            Accedez à l'application
          </Link>
        )}
        <div className="bottom-nav">
          <a
            href="https://www.facebook.com/weforformationemploi"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook className="link-item" />
          </a>
          <a
            href="https://www.instagram.com/wefor_formation_emploi/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="link-item" />
          </a>
          <a
            href="https://www.linkedin.com/company/wefor2022/?viewAsMember=true"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin className="link-item" />
          </a>
          <Link to="/contact">
            <FaRegEnvelope className="link-item" alt="picto contact" />
          </Link>
          <a
            href="tel:+33185540178"
            onClick={() => {
              window.dataLayer.push({ event: "phone" });
            }}
          >
            <FaPhoneAlt className="link-item" alt="picto phone" />
          </a>
        </div>
      </nav>
    </HeaderMobileStyled>
  );
};

export default HeaderMobile;
