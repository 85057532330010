import styled from 'styled-components';
import bcgHeader from '../../assets/img/header/formation-ill.jpg'

const JobStyled = styled.main`
    padding-top: 0px;

    .top-illustration {
        width: 100vw;
        height: 700px;
        padding-top: 125px;
        z-index: 10;
        position: relative;
    
        &::before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width:100%;
            background: linear-gradient(180deg, #f7edea 0%, #247558 100%);
            clip-path: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            shape-outside: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            z-index: -10;
        }
    
    
        .opacity-light-section {
            height: 100%;
            top: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0px 0;
            max-width: 1300px;
            margin: 0 auto;
    
            .left {
                width: calc(50%);
                margin-left: 5%;
                margin-top: 100px;
    
                h1 {
                    font-size: 50px;
                    color: #4d4d4d;
                    text-align: left;
                    padding-bottom: 15px;
                }
    
                p {
                    font-size: 25px;
                    color: #fff;
                    line-height: 30px;
                    padding: 0px 0 0 0;
                    text-align: justify;
                }
            }
            img {
                margin-top: 20px;
                box-sizing: border-box;
                width: 45%;
                box-sizing: border-box;
                height: fit-content;
            }
        }
    }

    .filter-formation {
        width: 100%;

        .wrapper-filter {
            max-width: 900px;
            padding: 30px 0;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;

            .Mui-focused .MuiInputLabel-outlined {
                color: #6AAF8B;
                font-weight: bold;
            }

            .MuiInputLabel-root, .MuiInputBase-input {
                top: -7px;
                color: #6AAF8B !important;
                font-weight: bold;
                padding-top: 0;
            }

            .MuiInputBase-root {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                height: 40px;
                font-weight: bold;

                &.MuiOutlinedInput-notchedOutline {
                    border-color: #6AAF8B;
                }

                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: #6AAF8B;
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: #6AAF8B;
                    color: #6AAF8B !important;
                    font-weight: bold;
                }

                &.Mui-focused .MuiOutlinedInput-notchedOutline {
                    border-color: #6AAF8B;
                }
            }


            .btn-filter {
                width: 250px;
                background-color: #6AAF8B;
                text-align: center;
                padding: 10px 0px;
                border: 1px solid #6AAF8B;
                border-radius: 8px;
                color: #fff;
                position: relative;
                cursor: pointer;
                z-index: 10;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .filter {
                    color: #fff;
                }


                &.border-r-off {
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    border-bottom: 0;
                }

                ul {
                    display: none;
                }

                .show-menu {
                    display: block;
                    z-index: 50;
                    position: absolute;
                    top: 38px;
                    left: -1px;
                    width: 250px;
                    background-color: #6AAF8B;
                    text-align: center;
                    padding: 0px 0px;
                    border: 1px solid #6AAF8B;
                    border-radius: 8px;
                    border-top: 0;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;

                    li {
                        padding: 10px 0;

                        &:hover {
                            background-color: #fff;
                            color: #6AAF8B;
                        }

                        &:last-child {
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                    }
                }

                svg {
                    margin-bottom: -2px;
                }
            }
        }
    }

    .user-filter-section {
        width: 96%;
        max-width: 1300px;
        margin: 0 auto;
        padding-left: 70px;
        display: flex;
        flex-wrap: wrap;

        .layout-btn {
            font-size: 25px;
            margin-right: 10px;
            color: #9dcab3;
            cursor: pointer;

            &.active-layout {
                color: #40775a;
            }
        }

        .filter {
            color: #6aaf8b;
            display: flex;
            align-items: center;
            margin-left: 20px;

            svg {
                color: #dc4a46;
                cursor: pointer;
            }
        }
    }

    .course-main {
        max-width: 1300px;
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        margin: 0 auto;

        .course-list-left {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: stretch;
            width: 50%;
            padding-bottom: 50px;

            &.column {
                flex-direction: column;
                justify-content: start;
            }

            .fullwidth {
                width: 98%;
            }

            .pagination-pc {
                width: 100%;
                margin-top: 30px;

                .MuiPagination-ul {
                    justify-content: center;
                }
            }

            &.mobile {
                display: none;
            }

            article {
                
            }

            .pagination {
                width: 100%;
                display:flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: end;
                align-items: center;
                margin: 50px 8% 0 0;

                span {
                    margin-right: 30px;
                    padding: 10px;
                    color: #656464;
                    cursor: pointer;
                }

                svg {
                    color: #656464;
                    cursor: pointer;
                }

                .active {
                    box-sizing: border-box;
                    background-color: #649ACE;
                    color: #fff;
                }
            }
        }
        .course-info-right {
            width: 48%;
            padding: 50px 0px 50px 0px;
            margin-bottom: 260px;
            box-sizing: border-box;


            .info-wrapper {
                border: 1px solid #6AAF8B;
                border-radius: 43px;
                width: 100%;
                margin: 0 auto;
                padding: 30px 20px 30px 20px;
                position: relative;
                box-sizing: border-box;

                .title-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;

                    .left {
                        width: 100%;
                        color: #6AAF8B;
                        font-size: 15.5px;
                        text-transform: uppercase;
                        padding-bottom: 5px;
                        text-align: center;

                        h5 {
                            font-size: 19px;
                            
                            display: inline-block;
                            padding: 0 0 0px 0;
                            margin: 0 0 0px 0;
                        }
                    }

                    .separator-green {
                        height: 1px;
                        width: 40%;
                        background-color: #6aaf8b;
                        margin: 5px 0;
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;

                        h3 {
                            color: #4d4d4d;
                            font-size: 24px;
                            display: block;
                            text-align: center;
                        }

                        h4 {
                            color: #6AAF8B;
                            display: inline-block;
                        }
                    }
                }

                .para-pre {
                    margin: 0 0 10px 0;
                }

                .orange {
                    color: #D47558,
                }

                .card-orange {
                    width: 100%;
                    box-shadow: 0px 3px 6px #00000029;
                    background-color: #fff;
                    border: 1px solid #D47558;
                    border-radius: 27px;
                    padding: 10px;
                    background-color: #fff;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    
                    h3 {
                        text-align: center;
                        color: #4d4d4d;
                        font-size: 20px;
                    }


                    .row {
                        width: 10%;
                        color: #D47558;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 40px;
                        }

                        &:nth-child(2) {
                            width: 85%;
                            box-sizing: border-box;
                        }

                        h5 {
                            color: #6399ce;
                            text-align: center;
                            font-size: 20px;
                        }

                        &:nth-child(3) {
                            width: 85%;
                            
                        }
                    }
                }

                .margin-bottom-txt {
                    margin-bottom: 20px;
                }

                h2.title-section {
                    font-size: 22px;
                    color: #6AAF8B;
                    margin-bottom: 10px;
                }

                h2.title-section-h2 {
                    font-size: 18px;
                    background-color: #6AAF8B;
                    color: #fff;
                    margin-bottom: 10px;
                    padding: 10px 0;
                    text-align: center;
                }

                .txt-prerequis {
                    color: #656464;
                    font-size: 14px;
                    line-height: 18px;
                    margin: 0 0 20px;
                }

                .card-green {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 10px;
                    background-color: #ECF3EF;
                    border-radius: 12px;
                    margin: 20px 0;
                    font-size: 14px;
                    color: #4d4d4d;
                    box-sizing: border-box;

                    .row {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        &:nth-child(1) {
                            max-width: 70%;
                        }

                        a {
                            height: 100%;
                        }

                        .btn-blue {
                            width: 130px;
                            padding: 5px 0;
                            background-color: #6aaf8b;
                            border-radius: 8px;
                            color: #fff;
                            cursor: pointer;
                            height: 100%;
                            border: 0;
                            transition: all .3s ease;

                            &:hover {
                                opacity: .7;
                            }
                        }
        
                        .btn-green {
                            width: 130px;
                            padding: 5px 0;
                            border: 1px solid #6AAF8B;
                            background-color: #6AAF8B;
                            border-radius: 8px;
                            color: #fff;
                            cursor: pointer;
                        }

                        .btn-lightgreen {
                            width: 130px;
                            padding: 5px 0;
                            border: 1px solid #6AAF8B;
                            background-color: #ECF3EF;
                            border-radius: 8px;
                            color: #6AAF8B;
                            cursor: pointer;
                            margin: 2px 0;
                        }
                    }
                }

                .price-time-wrapper {
                    width: 95%;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: stretch;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    .time-wrapper, .price-wrapper {
                        width: 48%;
                    }

                    .time-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-between;

                        img {
                            width: 100px;
                            height: 100px;
                        }

                        .paragraphe-wrapper {
                            width: calc(100% - 100px);

                            .txt {
                                color: #707070;
                                text-transform: uppercase;
                                font-size: 17px;
                            }

                            .time {
                                color: #D47558;
                                font-size: 22px;
                                padding-top: 5px;
                            }
                        }
                    }

                    .separator-orange {
                        width: 1px;
                        background-color: #D47558;
                    }

                    .price-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-between;

                        img {
                            margin-top: 10px;
                            width: 90px;
                            height: 90px;
                        }

                        p {
                            width: calc(100% - 100px);
                            font-size: 25px;
                            color: #4d4d4d;
                        }
                    }
                }

                
                .btn-orange-fullwidth {
                    width: 100%;
                    text-align: center;
                    padding: 10px 0;
                    font-size: 20px;
                    color: #fff;
                    background-color: #D47558;
                    border: 0;
                    cursor: pointer;
                    margin-bottom: 20px;
                }

                .txt-info {
                    color: #656464;
                    font-size: 14px;
                    line-height: 18px;

                    li {
                        list-style: inside;
                    }
                }

                .title-info {
                    color: #656464;
                    font-size: 16px;
                    margin: 20px 0;
                }


                .full-width {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin: 40px 40px 20px 0;
                    p {
                        color: #656464;
                        font-size: 16px;
                    }

                    .btn-blue {
                        padding: 10px 20px;
                        font-size: 16px;
                        background-color: #4d4d4d;
                        border-radius: 8px;
                        border: 0;
                        color: #fff;
                        cursor: pointer;
                        margin: 20px auto 0;
                    }
                }

                .suitcase-wrapper {
                    
                    height: 120px;
                    width: 120px;
                    border-radius: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    background-color: #fff;
                    top: -25px;
                    left: -25px;
                    box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
                    -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
                    -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
                    display: none;
            
                    img {
                        width: 80px;
                    }
                }
            }

            .button-wrapper {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 50px;

                .btn-blue {
                    margin-right: 10px;
                    width: 130px;
                    padding: 10px 0;
                    background-color: #4d4d4d;
                    border-radius: 8px;
                    border: 0;
                    color: #fff;
                    cursor: pointer;
                }

                .btn-green {
                    margin-left: 10px;
                    width: 130px;
                    padding: 10px 0;
                    background-color: #6AAF8B;
                    border-radius: 8px;
                    border: 0;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .top-illustration {
    
            .opacity-light-section {

                .left {
                    margin-left: 2%;
                    p {
                        font-size: 20px;
                        color: #fff;
                        line-height: 30px;
                        padding: 0px 0 0 0;
                        text-align: justify;
                    }

                    h1 {
                        font-size: 45px;
                        padding-bottom: 15px;
                    }
                }
            }
        }

        .course-main {
    
            .course-list-left {
                align-items: flex-start;
            }

            .course-info-right {
                padding: 50px 20px 50px 0px;
    
                .info-wrapper {
    
                    .card-green {
    
                        .row {
    
                            &:nth-child(1) {
                                max-width: 65%;
                            }
                        }
                    }

                    .price-time-wrapper {
                        padding-top: 20px;

                        .price-wrapper, .time-wrapper {
                            img {
                                width: 70px;
                                height: 70px;
                            }

                            .paragraphe-wrapper {
                                width: calc(100% - 70px);
                            }
                        }
                    }
    
                    .full-width {
                        flex-direction: column;
                        align-items: center;

                        p {
                            margin-bottom: 20px;
                        }
                    }
    
                    .suitcase-wrapper {
                        height: 90px;
                        width: 90px;
                        border-radius: 45px;
                        top: -35px;
                        left: -35px;
                
                        img {
                            width: 60px;
                        }
                    }
                }
            }
        }
    }
        
    @media only screen and (max-width: 1079px) {
        .top-illustration {
    
            .opacity-light-section {
                height: auto;
                flex-direction: column-reverse;
    
                .left{
                    padding: 50px 0 0 0;
                    width: calc(96%);
                    margin: 0 auto;
                    height: 300px;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                        margin-top: 20px;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: center;
                    }
                }
                img {
                    width: 90%;
                    max-width: 360px;
                    margin-left: 0px;
                    margin: -60px auto 0;
                }
            }
       }
        .course-main {
            flex-direction: column;

            .course-info-right {
                padding: 50px 20px 50px 20px;
            }

            .course-list-left, .course-info-right {
                width: 100%;
            }

            .course-list-left.computer {
                display: none;
            }

            .course-list-left.mobile {
                display: block;
                padding: 0;

                article {
                    margin: 50px auto 0 auto;
                }
                .swiper {
                    padding: 50px 0;

                    .swiper-slide {
                        .btn-darkblue {
                            display: none;
                        }
                    }
                }
            }
        }
    }


    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .top-illustration {

            height: 60px;
            padding: 60px 0 0 0;

            &::before {
                clip-path:none;
            }
    
            .opacity-light-section {
                flex-direction: column-reverse;
                display: none;
    
                .left {
                    width: 96%;
                    margin: 0 auto;
                    height:  auto;
                    display: flex;
                    padding-top: 0;
                    justify-content: center;
                    flex-direction: column;
    
                    h1 {
                        font-size: 24px;
                        text-align: center;
                        padding-bottom: 0px;
                        margin: 10px 0 0 0;
                    }
    
                    p {
                        font-size: 16px;
                        line-height: 18px;
                        text-align: justify;
                        padding: 10px 0 0 0;
                        width: 100%;
                    }
                }
                img {
                    margin: 10px auto 0;
                    max-width:260px;
                    display: none;
                }
            }
       }

       .filter-formation {
            .wrapper-filter {
                margin-top: 20px;
                padding: 0;
                flex-direction: column;
                align-items:center;
                justify-content: space-between;

                .btn-filter:nth-child(1), .btn-filter:nth-child(2) {
                    margin-bottom: 20px;                    
                }

                .btn-filter {
                    z-index: 0;
                    position: relative;

                    .show-menu {
                        z-index: 50px;
                    }
                }

                .btn-filter:nth-child(1) {
                    z-index: 10;
                }

                .btn-filter:nth-child(2) {
                    z-index: 9;
                }

                .btn-filter:nth-child(3) {
                    z-index: 8;
                }
            }
       }

       .user-filter-section {
        display: none;
       }

        
        .course-main {
            margin-bottom: 20px;

            .course-list-left.mobile {
                .swiper {
                    padding: 0 0 50px 0;
                }
            }

            .course-info-right {
                width: 96%;
                padding: 20px 0;
                margin: 0 auto;

                .info-wrapper {
                    padding: 20px 10px 20px;

                    .title-wrapper {
                        text-align: center;
                        align-items: center;

                        .left {
                            width: 100%;
                        }
                    }

                    .card-green {
                        box-sizing: border-box;
                        font-size: 16px;

                        .row {
                            max-width: 100% !important;
                            width: 100%;
                            text-align: center;

                            &:nth-child(2) {
                                margin-top: 10px;
                            }
                        }
                    }

                    .suitcase-wrapper {
                        left: calc(50% - 45px);
                    }

                    .btn-orange-fullwidth {
                        font-size: 18px;
                        border-radius: 5px;
                    }

                    .card-orange {
                        h3 {
                            font-size: 16px;
                        }
                    }

                    .price-time-wrapper {
                        .time-wrapper, .price-wrapper {
                            flex-direction: column;
                            justify-content: flex-start;

                            p, .paragraphe-wrapper {
                                width: 100%;
                                text-align: center;
                            }
                        }

                        .price-wrapper {

                            p {
                                font-size: 20px;
                                margin: 20px 0 0 0;
                            }
                        }
                    }

                    h2.title-section {
                        font-size: 20px;
                    }

                    h5.title-info {
                        font-size: 18px;
                        margin: 20px 0 10px;
                    }

                    .modules-wrapper {
                        font-size: 16px;

                        p {
                            padding: 0 0 10px 0;
                        }

                        ul li {
                            padding: 0 0 5px 0;
                        }

                        ul li p {
                            width: calc(100% - 25px);
                            display: inline-block;
                            padding: 0;
                        }
                    }

                    .full-width {
                        margin: 30px auto 0;

                        p {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    
`;

export default JobStyled;