import styled from 'styled-components';


const NotFoundStyled = styled.main`

.page-wrapper {
    margin-top: 175px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1300px;
    margin: 225px auto 50px;

    .intro-notfound {
        width: 50%;

        img {
            max-width: 500px;
        }
    }

    .txt-wrapper {
        width: 50%;

        .intro-txt {
            font-size: 22px;
            color: #6399ce;
            padding-bottom: 40px;
            text-align: center;
        }

        .job-search {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px 0 0;
            border-radius: 0px 30px 30px 0;
            box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
            margin-bottom: 30px;

            img {
                width: 150px;
            }

            p {
                color: #6aaf8b;
                font-size: 20px;
            }

            a {
                color: #6aaf8b;
                padding: 10px;
                border: 2px solid #6aaf8b;
                background-color: #e1efe7;
                position: relative;
                border-radius: 30px;

                &:hover {
                    color: #e1efe7;
                    background-color: #6aaf8b;
                }
            }
        }
        
        .course-search {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 0px 0 10px;
            border-radius: 30px 0px 0px 30px;
            box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
            margin-bottom: 30px;

            img {
                width: 150px;
            }

            p {
                color: #d47558;
                font-size: 20px;
            }

            a {
                color: #d47558;
                padding: 10px;
                border: 2px solid #d47558;
                background-color: #f8e8e3;
                position: relative;
                border-radius: 20px;

                &:hover {
                    color: #f8e8e3;
                    background-color: #d47558;
                }
            }
        }

        .account-search {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px 0 0;
            border-radius: 0px 30px 30px 0;
            box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
            margin-bottom: 20px;

            img {
                width: 63px;
            }

            p {
                color: #6399ce;
                font-size: 20px;
            }

            a {
                color: #6399ce;
                padding: 10px;
                border: 2px solid #6399ce;
                background-color: #dbe8f4;
                position: relative;
                border-radius: 20px;
                width: 130px;
                text-align: center;

                &:hover {
                    color: #dbe8f4
                    background-color: #6399ce;
                }
            }
        }
    }
}
    @media only screen and (max-width: 1079px) {
        .page-wrapper {
            flex-direction: column;
        
            .intro-notfound {
                width: 96%;
                margin: 0 auto;
                text-align: center;
            }
        
            .txt-wrapper {
                width: 96%;
                margin: 0 auto;
                max-width: 650px;

                section, .job-search, .course-search, .account-search {
                    height: 125px;
                    padding: 0px 10px 0px 10px;
                    border-radius: 30px;
                }

                img {
                    display: none;
                }
            }
        }

    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {

    }
`;

export default NotFoundStyled;