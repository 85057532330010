import { connect } from 'react-redux'

import SearchCourseByKey from '../../components/SearchCourseByKey'
import { setMuiCourseFilterKeyValue, changeActivCourseId } from '../../actions/course'


const mapStateToProps = (state) => ({
  muiCourseFilterKeyValue: state.job.muiCourseFilterKeyValue,
  activCourseId: state.course.activCourseId,
})

const mapDispatchToProps = (dispatch) => ({
  setMuiCourseFilterKeyValue: (value) => {
        dispatch(setMuiCourseFilterKeyValue(value));
    },
    changeActivCourseId: (id) => {
      dispatch(changeActivCourseId(id));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(SearchCourseByKey)