import styled from 'styled-components';

const ModalConnexionStyled = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 50px 10px;
    box-sizing: border-box;

   .section {
       width: 100%;
   }


    h2 {
        font-size: 35px;
        width: auto;
        display: inline;
        margin: 0 auto 30px;
        position: relative;
        color: #649ACE;
        padding-bottom: 10px;
        text-transform: uppercase;

        .separator-blue {
            background-color: #649ACE;
            height: 4px;
            width: 80px;
            margin: 10px 0 0 auto;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .message-important {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        max-width: 500px;
        margin: 0 auto 50px;
        background-color: #ECF3EF;
        padding: 10px;
        border-radius: 5px;

        span {
            margin-left: 5px;
            text-align: justify;
            line-height: 20px;
        }

        svg {
            color: #6AAF8B;
            font-size: 40px;

            margin-bottom: 10px;
        }
    }

    .account-form-comp {
        width: 500px;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        color: #707070;

        label {
            width: 100%;
        }

        .password-item {
            position: relative;

            .eye-wrapper {
                position: absolute;
                right: 15px;
                bottom: 32px;
            }
        }

        input {
            width: 100%;
            border: 0;
            border-radius: 10px;
            background-color: #F2F2F2;
            padding: 20px;
            margin: 5px 0px 10px;
            box-sizing: border-box;
        }

        .lightblue-btn {
            color: #fff;
            background-color: #649ACE;
            border: 2px solid #87B7E5;
            padding: 15px;
            font-size: 18px;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                color: #649ACE;
                background-color: #fff;
            }
        }
    }

    .link-wrapper {
        color: #649ACE;
        text-align: center;
        display: flex;
        text-decoration: underline;
        flex-direction: column;

        p {
            color: #649ACE;
            font-size: 14px;
            padding: 0;
            cursor: pointer;

            &.account-creation {
                font-size: 20px;
                margin-top: 20px;
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        padding: 20px 10px;

        h2 {
            font-size: 24px;
            margin: 0 auto 20px;
            text-transform: inherit;
            color: #202c3c;

            .separator-blue{
                width: 50px;
                display: none;
            }
        }

        .account-form-comp {
            padding: 0px 0 0px;
            margin: 0;
            max-width: 100%;

            label {
                padding-bottom: 10px;

                input {
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
                    background-color: #fff;
                    margin: 10px 0 5px 0;
                }

                input:-internal-autofill-selected {
                    background-color: #fff !important;
                }

                .lightblue-btn {
                    border: 0;
                }
            }

            .password-item {
                position: relative;
    
                .eye-wrapper {
                    position: absolute;
                    right: 15px;
                    bottom: 32px;
                }
            }
        }

        .link-wrapper {
            color: #4d4d4d;
            text-decoration: none;
            .password-lost {
                font-size: 16px;
                color: #4d4d4d;
                text-decoration: underline;
            }

            .account-creation {
                color: #649ACE;
                text-decoration: underline;
            }
        }
    }
`;

export default ModalConnexionStyled;