import styled from 'styled-components';

const StepTwoStyled = styled.section`

h2 {
	text-align: center;
    margin: 50px 0 20px;
	font-size: 30px;
	color: #202D3C;
}

h3 {
	font-size: 20px;
	padding: 30px 0 20px 0;
}

.info-list {
	width: 100%;

	li {
		padding: 0 0 10px 0;
		display: flex;
		align-items: center;

		svg {
			color: #d47558;
			margin: 0 5px 0 0 ;

			&.number {
				font-size: 14px;
				margin: 0 5px 2px 0;
			}
		}

		span {
			margin: 0 0 0 5px;
		}

		a {
			margin: 0 0 0 5px;
		}
	}
}


@media only screen and (max-width: 1400px) {
}

@media only screen and (min-width: 760px) and (max-width: 1079px) {
}

@media only screen and (min-width: 0px) and (max-width: 759px) {
	h2 {
		text-align: center;
		margin: 20px 0 20px;
		font-size: 20px;
		color: #202D3C;
	}
	
	h3 {
		font-size: 18px;
		padding: 0px 0 20px 0;
	}

	.info-list {
		margin: 0 0 20px 0;
		li {
			width: 100%;
			flex-wrap: wrap;
			justify-content: start;

			span {
				
			}

			div {
				width: calc(100% - 21px);
			}

			svg {
				
			}
		}
	}

	.step-fin {
		background-color: #F6FDFF;
		width: 100vw;
		margin: 0 auto 20px -20px;
		padding: 20px;
	}

	.edof-link, .paiement-link {
		width: 100%;
		margin: 10px auto 20px;
		font-size: 16px;
		text-align: center;
		max-width: 340px;
	}
}

`;

export default StepTwoStyled;