import { connect } from 'react-redux'

import MyTimer from '../../components/MyTimer'

import { setTimeRecrutement, fetchScoreRecrutementParticulier, fetchGetScoreRecrutement } from '../../actions/login'
import { toggleModalRecrutement } from '../../actions/modal'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    setTimeRecrutement: (time) => {
        dispatch(setTimeRecrutement(time));
    },
    toggleModalRecrutement: (bool) => {
        dispatch(toggleModalRecrutement(bool));
    },
    fetchScoreRecrutementParticulier: (score,time) => {
        dispatch(fetchScoreRecrutementParticulier(score, time));
    },
    fetchGetScoreRecrutement: () => {
        dispatch(fetchGetScoreRecrutement());
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(MyTimer)