import React from 'react'
import { Helmet } from "react-helmet"

const SeoHelmet = ({
	title, // titre de la page 
	description, // description de la page
	keywords, // mot clé présent dans le html
	canonical, // url de la page UNIQUE qu'on veut pointer avec cette page (duplication de même page)
	jsonSchema, // json qu'on reprend des autres sites
}) => {

  return (
	<Helmet>
		<title>{title}</title>
		<meta name='description' content={description}/>
		<meta name='keywords' content={keywords}/>
		<link rel="canonical" href={canonical} />

		{/* Schema.org pour les sites de formation en ligne */}
		<script type="application/ld+json">
			{JSON.stringify(jsonSchema)}
		</script>
	</Helmet>
  )
}

export default SeoHelmet