import styled from 'styled-components';

const ModalAccountCreationStyled = styled.div`
padding: 50px 20px;
display: flex;
flex-direction: column;
align-items: center;

h2 {
    font-size: 35px;
    width: auto;
    display: inline;
    margin: 0 auto 30px;
    position: relative;
    color: #649ACE;
    padding-bottom: 10px;
    text-transform: uppercase;

    .separator-blue {
        background-color: #649ACE;
        height: 4px;
        width: 80px;
        margin: 10px 0 0 auto;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

.account-form-comp {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    color: #707070;

    label {
        width: 100%;
    }

    input {
        width: 100%;
        border: 0;
        border-radius: 10px;
        background-color: #F2F2F2;
        padding: 20px;
        margin: 25px 0px 00px;
        box-sizing: border-box;
    }

    .lightblue-btn {
        color: #fff;
        background-color: #649ACE;
        border: 2px solid #87B7E5;
        padding: 15px;
        font-size: 18px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
            color: #649ACE;
            background-color: #fff;
        }
    }
    }

    @media only screen and (max-width: 1079px) {
        .choices-wrapper {
            padding: 0;
            margin-left: 0;

            .left, .right {
                img {
                    max-width: 250px;
                }

                button.btn-blue {
                    font-size: 25px;
                    width: 250px;
                }
            }

            .left {
                img {
                    max-width: 343px;
                }
            }
        }
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .choices-wrapper {
            flex-direction: column;
            width: 100%;
            .left, .right {
                width: 100%;
            }
        }

        padding: 20px 10px;

        h2 {
            font-size: 24px;
            margin: 0 auto 20px;
            width: calc(100% - 30px);
            display: inline-block;
            text-transform: inherit;
            color: #202c3c;

            .separator-blue{
                width: 50px;
                display: none;
            }
        }

        .account-form-comp {
            padding: 0px 0 0px;
            margin: 0;
            max-width: 100%;
            width: 100%;

            label {
                padding-bottom: 10px;

                input {
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
                    background-color: #fff;
                    margin: 10px 0 5px 0;
                }

                input:-internal-autofill-selected {
                    background-color: #fff !important;
                }

                .lightblue-btn {
                    border: 0;
                }
            }
        }
    }
`;

export default ModalAccountCreationStyled;