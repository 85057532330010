/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import Moment from 'react-moment'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { FaPlusCircle } from "react-icons/fa"
import { MdDownload } from "react-icons/md"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import PreInscription from '../../containers/PreInscription'
import GodfatherInfo from './GodfatherInfo'

/**** Import des images ****/


/**** Import des css ****/
import AccountParrainageStyled from './AccountParrainageStyled'


const AccountParrainage = ({
  isLogged, 
  userRole,
  userInfo,
  fetchGetPreInscriptionGodfather,
  godfatherList,
  fetchGetGodfatherInfo,
  godfatherInfo,
  jwt,
  saveGains,
  gains,
  fetchRibUser
}) => {

  const calculateTotalAll = (arr) => {
    const result = arr.reduce((acc, obj) => {
      acc.total += parseFloat(obj.attributes.Prix);
      if (obj.paid.data !== null) {
        acc.totalPaid += parseFloat(obj.paid.data.attributes.montant);
      } else {
        acc.totalNotPaid += parseFloat(obj.attributes.Prix);
      }
      return acc;
    }, { total: 0, totalPaid: 0, totalNotPaid: 0 });
    return { total: result.total / 10, totalPaid: result.totalPaid, totalNotPaid: result.totalNotPaid / 10 };
  };

  useEffect(() => {
    fetchGetPreInscriptionGodfather()
  }, [])

  useEffect(() => {
    fetchGetGodfatherInfo()
  }, [])

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };

  
  return (
    <AccountParrainageStyled>
      <Header bcgColor={'#fff'}/>
      {isLogged &&
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
      <h2 className='bold'>Parrainage en cours de validation <span onClick={handleClickOpen} className="btn-modal">Ajouter<FaPlusCircle /></span></h2>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <PreInscription title={"Inscription du nouveau parrainé"} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{padding: '10px', borderRadius: '5px'}}>
                Terminé
              </Button>
            </DialogActions>
          </Dialog>
      {isLogged && godfatherList !== 'loading' && godfatherList.length > 0 &&
        <div className="pre-list-wrapper">
          <div className='title-wrapper'>
            <div className='row-title bold orange'>Nom complet</div>
            <div className='row-title bold orange'>Téléphone</div>
            <div className='row-title bold orange'>Email</div>
            <div className='row-title bold orange'>Créé le</div>
          </div>
          {godfatherList.map((user, index) => (
            <div key={index} className='info-wrapper'>
              <div className='row-user'>
                <span>{user.attributes.nom} {user.attributes.prenom}</span>
              </div>
              <div className='row-user'>{user.attributes.telephone}</div>
              <div className='row-user'>{user.attributes.email}</div>
              <div className='row-user'><Moment format="DD/MM/YYYY">{user.attributes.createdAt}</Moment></div>
            </div>
          ))}
        </div>
      }
      <h2 className='bold'>Liste des utilisateurs parrainés</h2>
      <div className='gain-wrapper'>
        <div className='potentiel bold'> En cours : {gains.length > 0 ? (calculateTotalAll(gains).totalNotPaid) : '0'} €</div>
        <div className='win bold'>Payé : {gains.length > 0 ? (calculateTotalAll(gains).totalPaid) : '0'} €</div>
        <div className='total bold'>Total : {gains.length > 0 ? (calculateTotalAll(gains).total) : '0'} €</div>
      </div>
      {isLogged && godfatherInfo !== 'loading' && godfatherInfo.length > 0 &&
        <div className="pre-list-wrapper">
          <div className='title-wrapper'>
            <div className='row-title bold green'>Nom complet</div>
            <div className='row-title bold green'>Formation</div>
            <div className='row-title bold green'>Gain potentiel en €</div>
            <div className='row-title bold green'>Date d'envoi</div>
          </div>
          {godfatherInfo.map((user, index) => (
            <GodfatherInfo key={index} user={user} jwt={jwt} saveGains={saveGains} gains={gains}/>
          ))}
        </div>
      }
      <h2 className='bold'>Informations de paiement</h2>
      <div className='rib-wrapper'>
        {userInfo.data[0].attributes.rib.data === null ? 
          <div className='no-rib'>
            <p>Vous n'avez déposé aucun RIB. Merci de nous le faire parvenir à l'aide du bouton ci-dessous : </p>
            <form 
              onSubmit={(e) => {
              e.preventDefault();
              }}
              className="drop-file-wrapper"
            >
              <label htmlFor="file-upload" className="file-upload-btn">
                <div>
                  <MdDownload /> Déposer un RIB
                  <input 
                    id="file-upload"
                    type="file" 
                    name="upload" 
                    accept="application/pdf,application/vnd.ms-excel" 
                    onChange={(e)=> { 
                      fetchRibUser(e.target.files, userInfo.data[0].id)
                    }}
                    style={{
                      backgroundColor: "#fff !important"
                    }}
                  />
                </div>
              </label>
            </form>
          </div>
          :
          <div className='no-ok'>
          <p>Votre RIB actuel : <a href={userInfo.data[0].attributes.rib.data.attributes.url} target="_blank" rel="noreferrer">{userInfo.data[0].attributes.rib.data.attributes.name}</a></p>
          <form 
            onSubmit={(e) => {
            e.preventDefault();
            }}
            className="drop-file-wrapper"
          >
            <label htmlFor="file-upload" className="file-upload-btn">
              <div>
                <MdDownload /> Changer votre RIB
                <input 
                  id="file-upload"
                  type="file" 
                  name="upload" 
                  accept="application/pdf,application/vnd.ms-excel" 
                  onChange={(e)=> { 
                    fetchRibUser(e.target.files, userInfo.data[0].id)
                  }}
                  style={{
                    backgroundColor: "#fff !important"
                  }}
                />
              </div>
            </label>
          </form>
        </div>
        }
      </div>
      </section>
      </main>
      }
    </AccountParrainageStyled>
  )
}

AccountParrainage.propTypes = {
}

export default AccountParrainage