/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { FaGenderless } from 'react-icons/fa'
import { RiNumber1, RiNumber2, RiNumber3, RiNumber4 } from 'react-icons/ri'
import { MdDownload } from "react-icons/md" 
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { IoIosCopy } from "react-icons/io"
import { toast } from 'react-toastify'

/**** Import des css ****/
import StepOneStyled from './StepOneStyled'
import cryptoAxepta from '../../../assets/js/cryptoAxepta'

/**** Import des images ****/

import imgCb from '../../../assets/img/illustration/banque-cb.png'
import imgVirement from '../../../assets/img/illustration/virement-bancaire.png'


const StepOneE = ({ thisFinancement, fetchUploadEdofFile, changeFactureStatut }) => {

  const [files, setFiles] = useState(false)
  const [hoverCB, setHoverCB] = useState(false)
  const [hoverB, setHoverB] = useState(false)
  const [showSEPA, setShowSEPA] = useState(false)
  const [choice, setChoice] = useState(0)
  const [openBanque, setOpenBanque] = React.useState(false)

  const handleMouseEnterCB = () => {
    setHoverCB(true)
  }

  const handleMouseLeaveCB = () => {
    setHoverCB(false)
  }

  const handleMouseEnterB = () => {
    setHoverB(true)
  }

  const handleMouseLeaveB = () => {
    setHoverB(false)
  }

  const handleClickOpenBanque= () => {
    setOpenBanque(true);
  }
  
  const handleCloseBanque = () => {
    setOpenBanque(false);
  }

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  const copyElement = (string) => {
    navigator.clipboard.writeText(string)
    toast(`${string} : copié dans le presse-papier`, {
      position: "bottom-right",
      type: "success",
      theme:"colored",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }


  /* STYLING DIALOG */

  const imgWrapper = {
    height: '200px',
    padding: '10px 0',
    boxSizing: 'border-box',
  }

  const imgCss = {
    width: '50%',
    transition: 'transform .2s',
    margin: '0 auto',
    display: 'block',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: '5px',
  }

  const h3img = {
    padding: '10px 0',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
  }

  const sepaWrapper = {
    border: '2px solid #6aaf8b',
    borderRadius: '8px',
    padding: '10px',
    margin: '10px 0',
  }

  const sepaPara = {
    padding: '5px 0',
  }

  const h4sepa = {
    padding: '0 0 10px 0'
  }
  
  let CB = {
    transform: hoverCB ? 'scale(1.05)' : 'scale(1.0)',
    border: hoverCB ? '2px solid #6aaf8b' : '0',
    borderRadius: '8px'
  }

  let Ba = {
    transform: hoverB ? 'scale(1.05)' : 'scale(1.0)',
    border: hoverB ? '2px solid #6aaf8b' : '0',
    borderRadius: '8px',
  }

  let activeC = {
    transform: 'scale(1.05)',
    border: '2px solid #6aaf8b',
    borderRadius: '8px',
  }

  const copyCSS = {
    color: '#6aaf8b',
    marginLeft: '10px',
    cursor: 'pointer',
    marginBottom: '-2px',
  }

  return (
    <StepOneStyled>
        <h2 className='bold'>Demande de financement n°{thisFinancement.id}</h2>
        <h3 className='bold'>Informations disponibles pour cette formation</h3>
        <ul className='info-list'>
          <li><FaGenderless /> Nom de la formation : <span className='bold'> {thisFinancement.attributes.nom_formation}</span></li>
          <li><FaGenderless /> Demande effectuée le :  <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.publishedAt}</Moment></span></li>
          <li><FaGenderless /> Nombre d'inscrits : <span className='bold'>{thisFinancement.attributes.nombre_particulier}</span></li>
          <li><FaGenderless /> Type de formation : <span className='bold'>100% Elearning</span></li>
          <li><FaGenderless /> Prix de la formation : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix))} TTC</span></li>
          <li><FaGenderless /> Total facturé : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix * thisFinancement.attributes.nombre_particulier))} TTC</span></li>
        </ul>
          <section className='step-fin'>
            <h2 className='bold'>Suivez les étapes de financement : OPCO</h2>
            <ul className='info-list'>
              <li><RiNumber1 className='number bold'/> <div>Faites votre demande de financement auprès de votre OPCO <a href='https://travail-emploi.gouv.fr/ministere/acteurs/partenaires/opco#Liste-des-operateurs-de-competences-OPCO' target="_blank" rel='noreferrer'className='bold'> en savoir plus</a>.</div></li>
              <li><RiNumber2 className='number bold'/><div>Une fois votre demande effectuée et validée par l'OPCO, merci de nous faire parvenir le document qui certifie votre prise en charge.</div></li>
              <li><RiNumber3 className='number bold'/><div>Nous vérifions votre document. Si celui-ci est valide, nous inscrivons vos collaborateurs à une session de formation sur notre plateforme pédagogique.</div></li>
              <li><RiNumber4 className='number bold'/><div>Suivez l'évolution de vos collaborateurs en direct dans votre espace WEFOR</div></li>
            </ul>
            <form 
              onSubmit={(e) => {
              e.preventDefault();
              }}
              className="drop-file-wrapper"
            >
            <label htmlFor="file-upload" className="file-upload-btn">
              <div><MdDownload /> Déposez votre prise en charge</div>
              <input 
                id="file-upload"
                type="file" 
                name="upload" 
                accept="application/pdf,application/vnd.ms-excel" 
                onChange={(e)=> { 
                  setFiles(e.target.files)
                  fetchUploadEdofFile(e.target.files, thisFinancement.attributes.opco.data.id, 'opco')
                }}
                style={{
                  backgroundColor: "#fff !important"
                }}
              />
            </label>
            </form>
          </section>
          <h3 className='bold'>Si vous n'avez pas de financement OPCO, vous pouvez payer par virement ou carte bancaire.</h3>
          {/* en cas de virement merci de noter que toute formation ne pourra démarrer qu'après le paiement complet de la dite formation, avant la date de démarrage de la formation 
          + proposer le virement bancaire. 
          Noter dans le virement le numero de la demande.
          */ }
        <button className='paiement-link' onClick={handleClickOpenBanque}>Payer</button>
        {/*<a href={cryptoAxepta()} target='_blank' rel='noreferrer' class='paiment-cb bold'></a>*/}
        <Dialog open={openBanque} onClose={handleCloseBanque}>
          {showSEPA === false ?
          <DialogTitle>Choisissez votre moyen de paiement</DialogTitle>
          :
          <DialogTitle>Paiement par virement bancaire</DialogTitle>
          }
          {showSEPA === false && 
          <DialogContent>
            {/*
            <div style={{...imgWrapper}}>
              <h3 style={{...h3img}}>Payez par carte bleue</h3>
              <img 
                src={imgCb} 
                alt='Cartes bleus disponilbes pour un paiement CB' 
                style={choice === 1 ? {...imgCss, ...CB, ...activeC} : {...imgCss, ...CB}}
                onMouseEnter={handleMouseEnterCB}
                onMouseLeave={handleMouseLeaveCB}
                onClick={() => {
                  setChoice(1)
                }}
              />
            </div>
            */}
            <div style={{...imgWrapper}}>
              <h3 style={{...h3img}} >Payez par virement bancaire</h3>
              <img 
                src={imgVirement} 
                alt='Cartes bleus disponilbes pour un paiement CB' 
                style={choice === 2 ? {...imgCss, ...Ba, ...activeC} : {...imgCss, ...Ba}}
                onMouseEnter={handleMouseEnterB}
                onMouseLeave={handleMouseLeaveB}
                onClick={() => {
                  setChoice(2)
                }}
              />
            </div>
          </DialogContent>
          }
          {showSEPA === true && 
          <DialogContent>
            <div style={{...imgWrapper}}>
              <h4
                className='bold' 
                style={{...h4sepa}}
              >
                Merci de renseigner le numéro de la demande dans le virement : {thisFinancement.id} 
                <IoIosCopy 
                  style={{...copyCSS}} 
                  onClick={() => {copyElement(thisFinancement.id)}}
                />
              </h4>
              <h4
                className='bold' 
                style={{...h4sepa}}
              >
                Montant du virement : {euroFormat.format(Number(thisFinancement.attributes.Prix * thisFinancement.attributes.nombre_particulier))} 
                <IoIosCopy 
                  style={{...copyCSS}} 
                  onClick={() => {copyElement(Number(thisFinancement.attributes.Prix * thisFinancement.attributes.nombre_particulier))}}
                />
              </h4>
              <div style={{...sepaWrapper}}>
                <p style={{...sepaPara}}>
                  Référence bancaire : <span className='bold'>BNP ANTONY</span>
                  <IoIosCopy style={{...copyCSS}} onClick={() => {copyElement('BNP ANTONY')}}/>
                </p>
                <p style={{...sepaPara}}>
                  IBAN : <span className='bold'>FR76 3000 4001 8300 0104 2509 368</span>
                  <IoIosCopy style={{...copyCSS}} onClick={() => {copyElement('FR76 3000 4001 8300 0104 2509 368')}}/>
                </p>
                <p style={{...sepaPara}}>
                  BIC : <span className='bold'>BNPAFRPPXXX</span>
                  <IoIosCopy style={{...copyCSS}} onClick={() => {copyElement('BNPAFRPPXXX')}} />
                </p>
              </div>
              <p>Merci de valider seulement si vous avez effectué le virement.</p>
            </div>
          </DialogContent>
          }
          {showSEPA === false ?
          <DialogActions>
            <Button onClick={handleCloseBanque}>Annuler</Button>
            <Button 
              onClick={() => {
                if(choice === 2) {
                  setShowSEPA(true)
                }
                if(choice === 1) {
                  handleCloseBanque()
                }
              }}
              disabled={choice === 0 ? true : false}
            >Valider</Button>
          </DialogActions>
          :
          <DialogActions>
            <Button 
              onClick={() => {
                handleCloseBanque() 
                setShowSEPA(false)
                setChoice(0)
              }}>
                Annuler
            </Button>
            <Button onClick={() => {
              if(choice === 2) {
                changeFactureStatut(2, 'Banque', 'autofinancement')
                handleCloseBanque()
                setShowSEPA(false)
              }
              if(choice === 1) {
                handleCloseBanque()
                setShowSEPA(false)
              }
            }}>Virement effectué</Button>
          </DialogActions>
          }
        </Dialog>
    </StepOneStyled>
  )
}

export default StepOneE