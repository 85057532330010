/**** Import de modules ****/
import React from 'react'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'

/**** Import d'images' ****/
import contrat from '../../assets/img/illustration/contrat.png'
import grue from '../../assets/img/picto/grue.png'
import pelleteuse from '../../assets/img/picto/pelleteuse.png'

/**** Import de css ****/
import CourseCardDomaineStyled from './CourseCardDomaineStyled'

const CourseCardDomaine = ({name, title, color, icon}) => {
  return (
    <CourseCardDomaineStyled
    style={{border: `1px solid ${color}`}}
    className={icon}
    >
        {title && 
        <>
          <h4 className="bold">{title}</h4>
          <div className="separator"></div>
        </>
        }
        <h3>{`Formation en ${name}`}</h3>
        <div className="level-wrapper">
            <p className="bold">Débutant</p>
            <p className="bold">Intérmediaire</p>
            <p className="bold">Expert</p>  
        </div>
        <Link to="/job/1">
          <button className="btn-blue">Fiche métiers</button>
        </Link>
        <div className="shield-wrapper">
          {icon === "grue" &&
            <img src={grue} className="shield-picto" alt="bouclier orange" />
          }
          {icon === "pelleteuse" &&
            <img src={pelleteuse} className="shield-picto" alt="bouclier orange" />
          }
          {!icon &&
            <img src={contrat} className="shield-picto" alt="bouclier orange" />
          }
        </div>
    </CourseCardDomaineStyled>
  )
}

CourseCardDomaine.propTypes = {
  name: PropTypes.string.isRequired, 
  title: PropTypes.string.isRequired, 
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default CourseCardDomaine