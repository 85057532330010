import styled from 'styled-components';

const ModalJobInscriptionStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    max-width: 700px;
    max-height: 80vh;

    .prenom {
        text-transform: uppercase;
        font-size: 35px;
        color: #649ACE;
        margin-bottom: 30px;
    }

    .scroll {
        margin: 20px 0px 20px 40px;
        padding: 0 40px 0 0;
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    section {

        box-sizing: border-box;
        text-align: center;
        width: 100%;

        .txt-skeleton {
            font-size: 20px;
            width: 100px;
        }


        .MuiFormControl-root {
            width: 100%;
            text-align: left;
            margin: 20px 0 0 0;
        }
        .MuiFormGroup-root {
            flex-direction: row;

         }

        .answer {
            margin-top: 20px;
            font-size: 18px;
            color: #649ACE;
        }

        .txt-left {
            text-align: left;
        }

        .card {
            width: 100%;
            background-color: #F5F5F5;
            border-radius: 93px;
            padding: 10px 30px 10px 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            box-shadow: 0px 3px 6px #00000029;
            margin: 20px auto 20px;
            text-align: left;
        
            img {
                width: 100px;
            }
        
            .text-wrapper {
                width: calc(98% - 120px);
        
                h3 {
                    color: #649ACE;
                    font-size: 20px;
                    border-bottom: 1px solid #202D3C;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }
        
                .bottom-wrapper {
        
                    h4 {
                        color: #202D3C;
                    }

                    .padding-bottom {
                        padding-bottom: 5px;
                    }
        
                    p {
                        display: inline-block;
                        color: #649ACE;
                        padding-top: 5px;
                    }
                }
            }
        }
        
        .disclaimer {
            margin: 0 auto;
            text-align: justify;
            color: #202D3C;
            line-height: 22px;
        }

        .disclaimer-a {
            margin: 0 auto;
            text-align: justify;
            color: #202D3C;
            line-height: 22px;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            a {
                color: #202D3C;
            }
        }

        .btn-lightblue {
            color: #fff;
            background-color: #649ACE;
            border-radius: 12px;
            border: 0;
            padding: 10px 0px;
            cursor: pointer;
            font-size: 20px;
            width: 250px;
            margin-top: 50px;
    
            &:hover {
                background-color: #202D3C;
            }
        }

        #file-upload {
            display: none;
        }

        .cv-title {
            width: 96%;
            max-width: 600px;
            margin: 0 auto 10px;
            color: #707070;
        }

        .dl-link {
            color: #649ACE;
        }

        .drop-file-wrapper {
            width: 96%;
            max-width: 600px;
            margin: 50px auto 30px;
            height: 60px;

            .file-upload-btn {
                background-color: #649ACE;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                border-radius: 10px;
                cursor: pointer;

                &:hover {
                    opacity: .7;
                }
    
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .test-perso-modal {

        h3 {
            font-size: 20px;
            color: #4d4d4d;
        }
        .btn-test {
            width: 350px;
            margin: 60px auto 30px;
            display: block;
            padding: 10px;
            border: 0;
            font-size: 20px;
            border-radius: 10px;
            color: #fff;
            background-color: #6aaf8b;
            cursor: pointer;
            transition: all .3s ease;
    
            &:hover {
                opacity: .7;
            }
        }
    }
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        width: 100%;

        section.scroll {
            padding:0 10px 0;
            margin: 0;

            h2.prenom {
                font-size: 24px;
                text-transform: inherit;
                margin-bottom: 20px;
            }

            section {
                .answer, .txt-left {
                    font-size: 16px;
                    text-align: left;
                    color: #4d4d4d;
                }
            }
            .card {
                padding: 10px;
                margin: 20px auto 0;
                border-radius: 10px;
                background-color: #fff;

                img {
                    display: none;
                }
                .text-wrapper {
                    width: 100%;

                    h3.title-blue {
                        text-align: center;
                        font-size: 18px;
                        color: #4d4d4d;
                        border-bottom: 1px solid #d47558;
                    }

                    .bottom-wrapper {
                        text-align: center;
                        color: #4d4d4d;
                        .padding-bottom {
                            color: #d47558;
                        }

                        h4 {
                            color: #4d4d4d;
                        }
                    }
                }
            }

            .disclaimer {
                text-align: left;
                margin-top: 20px;
            }

            .btn-lightblue {
                margin: 20px auto;
                font-size: 16px;
                border-radius: 5px;
            }
        }
    }
`;

export default ModalJobInscriptionStyled;