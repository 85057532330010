import axios from 'axios'
import * as qs from 'qs'
import { toast } from 'react-toastify';

import urlBackEnd from '../assets/js/urlBackEnd';

import 'react-toastify/dist/ReactToastify.css';

import { 
  FETCH_QUESTION_OCEAN,
  FETCH_REPONSE_OCEAN,
  FETCH_CATEGORIE_OCEAN,
  saveQuestionOcean,
  saveReponseOcean,
  saveCategorieOcean,
  FETCH_POST_OCEAN_RESULT,
  setFullPageLoaderOcean,
  FETCH_GET_MY_TESTS_OCEAN,
  saveMyTestsOcean
} from '../actions/ocean';

import { 
  fetchUser,
  fetchUserRole
} from '../actions/login';

// const URL = "http://localhost:1338/api/";
const URL = urlBackEnd;

const oceanMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_QUESTION_OCEAN: {
      const state = store.getState();
      const queryQuestion = qs.stringify({
        populate: {
          reponse_ocean: {
            fields: ["id"]
          },
          categorie_ocean: {
            fields: ["id"]
          },
        },
        sort: ['id:asc'],
        pagination: {
          pageSize: 50,
          page: 1,
        },
      }, {
        }, {
          encodeValuesOnly: true,
        });
      axios.get(`${URL}question-oceans?${queryQuestion}`)
        .then((response) => {
          store.dispatch(saveQuestionOcean(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_REPONSE_OCEAN: {
      const state = store.getState();
      const queryReponse = qs.stringify({
        populate: {
          categorie_ocean: {
            fields: ["id"],
          },
        } 
        }, {
          encodeValuesOnly: true,
        });
      axios.get(`${URL}reponse-oceans?${queryReponse}`)
        .then((response) => {
          store.dispatch(saveReponseOcean(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_CATEGORIE_OCEAN: {
      const state = store.getState();
      const queryCat = qs.stringify({
        sort: ['id:asc']
      }, {
        }, {
          encodeValuesOnly: true,
        });
      axios.get(`${URL}categorie-oceans?${queryCat}`)
        .then((response) => {
          store.dispatch(saveCategorieOcean(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_POST_OCEAN_RESULT: {
      const state = store.getState();
      const inputsOcean = state.ocean.valueInputs
 
      let oResult = 0;
      let cResult = 0;
      let eResult = 0;
      let aResult = 0;
      let nResult = 0;

      inputsOcean.map((item) => {
        if(item.categorie === 1) {
          oResult += item.score
        }
        if(item.categorie === 2) {
          cResult += item.score
        }
        if(item.categorie === 3) {
          eResult += item.score
        }
        if(item.categorie === 4) {
          aResult += item.score
        }
        if(item.categorie === 5) {
          nResult += item.score
        }
      })

      state.login.userRole === 'entreprise' ?
        axios.post(`${URL}test-oceans`, {
          data: {
            ouverture_esprit: oResult,
            conscience: cResult,
            extraversion: eResult,
            altruisme: aResult,
            nevrosisme: nResult,
            entreprise: state.login.userInfo.data[0].id,
            nom: state.ocean.managerNom,
            prenom: state.ocean.managerPrenom
          },
          headers: {
            Authorization:
              `Bearer ${state.login.session.jwt}`,
            },
        }) 
        .then((response) => {
          store.dispatch(setFullPageLoaderOcean(false))
          store.dispatch(fetchUserRole())
          toast(`Vous pouvez désormais voir les résultats !`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          store.dispatch(setFullPageLoaderOcean(false))
          toast(`Une erreur s'est produite, merci de réessayer plus tard`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        :
        axios.post(`${URL}test-oceans`, {
          data: {
            ouverture_esprit: oResult,
            conscience: cResult,
            extraversion: eResult,
            altruisme: aResult,
            nevrosisme: nResult,
            particulier: state.login.userInfo.data[0].id
          },
          headers: {
            Authorization:
              `Bearer ${state.login.session.jwt}`,
            },
        }) 
        .then((response) => {
          store.dispatch(setFullPageLoaderOcean(false))
          store.dispatch(fetchUserRole())
          toast(`Vous pouvez désormais voir vos résultats !`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          store.dispatch(setFullPageLoaderOcean(false))
          toast(`Une erreur s'est produite, merci de réessayer plus tard`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
      next(action);
      break;
    }
    case FETCH_GET_MY_TESTS_OCEAN: {
      const state = store.getState();
      const queryQuestion = qs.stringify({
        filters: {
          entreprise: {
            id: action.id
          }
        }
      }, {
        }, {
          encodeValuesOnly: true,
        });
      axios.get(`${URL}test-oceans?${queryQuestion}`)
        .then((response) => {
          store.dispatch(saveMyTestsOcean(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default oceanMiddleware;