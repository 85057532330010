import { connect } from 'react-redux'

import ModalInscription from '../../components/ModalInscription'

import { 
    openModal,
    closeModal,
    toggleModalMessage
} from '../../actions/modal'

const mapStateToProps = (state) => ({
    isLogged: state.login.isLogged,
    modalName: state.modal.modalName,
    formModal: state.modal.formModal,
    userRole: state.login.userRole,
    redirect: state.login.redirect
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => {
        dispatch(openModal(name));
    },
    closeModal: (name) => {
      dispatch(closeModal(name));
  },
  toggleModalMessage: (message, bool) => {
    dispatch(toggleModalMessage(message, bool));
},
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalInscription)

