import styled from 'styled-components';


const PopupAxeptaStyled = styled.div`
width: 100%;
height: 100%;

.modal-enter {
margin: auto;
background: rgb(255, 255, 255);
width: 96% !important;
padding: 15px !important;
border-radius: 10px;
max-width: 800px;

.header {
	h3 {
		font-size: 22px;
		color: #202c3c;
		padding: 0 0 30px;
	}
}

form {
	width: 100%;
	display: flex; 
	flex-wrap: wrap; 
	justify-content: space-between;
	margin-top: 20px;

	.MuiTextField-root {
		width: 48%;
		margin: 0 0 20px 0;
	}

	.btn-container {
		width: 100%;
		text-align: right;
	}

	.MuiButton-root {
		margin-right: 0;
	}
}

.close {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 30px;
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 40px;
	background-color: #649ace;
	color: #fff;
	border-radius: 50%;
	cursor: pointer; 
}
}

@media only screen and (max-width: 1079px) {

	.modal-enter {
		form {
			.MuiTextField-root {
				width: 100%;
			}
		}
	}

}
`;

export default PopupAxeptaStyled;