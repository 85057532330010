import { connect } from 'react-redux'

import PreInscription from '../../components/PreInscription'

import { fetchGetPreInscription, fetchGetPreInscriptionGodfather } from '../../actions/apprenant'

const mapStateToProps = (state) => ({
    user: state.login.session.user,
    jwt: state.login.session.jwt,
    userInfo: state.login.userInfo,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
})

const mapDispatchToProps = (dispatch) => ({
    fetchGetPreInscription: () => {
        dispatch(fetchGetPreInscription());
    },
    fetchGetPreInscriptionGodfather : () => {
        dispatch(fetchGetPreInscriptionGodfather());
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(PreInscription)