import { 
    CHANGE_SEARCH_NEWS,
    SAVE_NEWS,
    TOGGLE_FULL_PAGE_LOADER_NEWS
  } from "../actions/news";
  
  export const initialState = {
    newsFlux : {
      articles: []
    },
    search: "assurance",
    fullPageLoaderNews: false
  };
  
  // reducer = traducteur d'une intention/action vers une modification du state
  const news = (state = initialState, action = {}) => {
    switch (action.type) {
      case CHANGE_SEARCH_NEWS:
        return {
          ...state,
          search: action.value,
        };
      case SAVE_NEWS:
        return {
          ...state,
          newsFlux: action.data
        };
      case SAVE_NEWS:
        return {
          ...state,
          fullPageLoaderNews: action.bool
        };
      default:
        return state;
    }
  };
  
  export default news;